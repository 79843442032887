import { useQuery } from "@tanstack/react-query"
import { type Patient, type User, getResource } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"

import { patientsQueryKeys } from "../query-keys"

const usePatient = (patientId: string | undefined, enabled = true) => {
  const { search } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = patientsQueryKeys.details(patientId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: `${patientId}`,
        "active:not": "false",
        _revinclude: "User:link:Patient",
        _elements: "User.identifier, User.link",
      })

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Patient`, filters, signal })
      const patient = getResource<Patient>(bundle, "Patient")
      const user = getResource<User>(bundle, "User")

      if (!patient) {
        throw new Error("Not found", { cause: { name: "404", message: "Not patient found" } })
      }
      const hasB2CIdentifier = user?.identifier?.some(({ system }) => system === SYSTEM_VALUES.B2C)

      return { patient, hasB2CIdentifier }
    },
    enabled: enabled && !!patientId,
    meta: { context: { queryKey, patientId } },
  })

  return {
    patient: data?.patient as Patient,
    hasB2CIdentifier: data?.hasB2CIdentifier ?? false,
    isLoading,
    reloadPatient: refetch,
  }
}

export { usePatient }
