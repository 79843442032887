import { type Bundle, type BundleEntryArray, type Device, asBundleEntry, getBundleEntryReference } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys, useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

import type { RoomData } from "../types"

const useCreateRoom = (onSuccess?: () => void, onSettled?: () => void) => {
  const { devicesByRoom } = useOrganizationContext()
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const newRoom = async ({ devices, ...room }: RoomData) => {
    const roomEntry = asBundleEntry(room)
    const devicesEntries = devices.reduce<BundleEntryArray[]>(
      (acc, { display, ...device }) => [
        ...acc,
        asBundleEntry(device as Device, { location: getBundleEntryReference(roomEntry) }, "PATCH"),
      ],
      [],
    )
    const clearDevices =
      devicesByRoom[room.id as string]
        ?.filter((device) => !devices.some((d) => d.id === device.id))
        ?.reduce<BundleEntryArray[]>(
          (acc, { display, ...device }) => [
            ...acc,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            asBundleEntry(device as Device, { location: null as any }, "PATCH"),
          ],
          [],
        ) ?? []

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [roomEntry, ...devicesEntries, ...clearDevices],
    }
    return transaction(bundle)
  }

  const { mutate: createRoom, isPending } = useMutation({
    mutationFn: newRoom,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { id, managingOrganization }) => {
      queryClient.refetchQueries({
        queryKey: managingOrganization?.id
          ? organizationQueryKeys.currentOrganization.current(managingOrganization?.id)
          : organizationQueryKeys.currentOrganization.all,
        type: managingOrganization?.id ? "all" : undefined,
      })
      const message = id ? "Room updated successfully!" : "Room added successfully!"
      displayNotificationSuccess(message)
      // datadogLogs.logger.info(message, { org: managingOrganization?.id })
      onSuccess?.()
    },
    onSettled,
  })

  return { createRoom, isCreating: isPending }
}

export { useCreateRoom }
