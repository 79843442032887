import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Consent, codeableConceptAsString } from "fhir"

import { type StackedListItemProps, MenuStyles } from "commons"
import { formatsByTypes } from "data"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { formatDate, getBadgeColor } from "utils"

const consentModelBuilder = ({
  consent,
  download,
  isLoading,
  showAsExternalAction,
  linetItemClassname,
}: ConsentModelBuilderArgs): StackedListItemProps => {
  const createdAt = consent.meta?.createdAt && formatDate(new Date(consent.meta.createdAt), formatsByTypes.SHORT_DATE)
  const category = codeableConceptAsString(consent.category?.[0], "No category")

  const showConset = () => {
    window.open(consent.policy?.[0].uri as string, "_blank", "noopener")
  }

  return {
    isLoading,
    leftData: [
      {
        lineItems: [
          { name: "Category", value: category },
          { name: "Date", value: createdAt ?? "Unspecified date" },
        ],
      },
    ],
    badge: {
      text: consent.status === "draft" ? "pending" : consent.status,
      colorStyle: getBadgeColor(consent.status).colorStyle,
    },
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    menu: [
      ...(showAsExternalAction
        ? [
            {
              label: "Show consent",
              command: showConset,
              disabled: !consent.policy?.[0].uri,
            },
          ]
        : [
            {
              template: () => {
                return (
                  <>
                    <Tooltip target=".view-button" position="left" />
                    <div
                      className={classNames(
                        "flex px-3 py-2 view-button items-center gap-2 hover:bg-slate-200",
                        consent.status !== "active" ? "opacity-60" : "cursor-pointer",
                      )}
                      onClick={consent.status !== "active" ? undefined : download}
                      data-pr-tooltip="Only signed consent can be shown"
                      data-pr-disabled={consent.status === "active"}
                      data-pr-showdelay={1000}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      <span>View</span>
                    </div>
                  </>
                )
              },
              disabled: consent.status !== "active",
            },
          ]),
    ],
    lineItemClassname: linetItemClassname,
  }
}

interface ConsentModelBuilderArgs {
  consent: Consent
  download: () => void
  resend?: () => void
  isLoading?: boolean
  showAsExternalAction?: boolean
  linetItemClassname?: string
}

export { consentModelBuilder }
