const patientAptsQueryKeys = {
  all: ["patient/appointments"] as const,
  list: (patientId: string, appointmentId?: string) => [
    ...patientAptsQueryKeys.all,
    patientId,
    ...(appointmentId ? [appointmentId] : []),
  ],
  scheduled: (patientId: string) => ["scheduledAppointment", patientId],
  availableSlots: (patientId?: string, appointmentTypeId?: string, start?: Date, end?: Date) => [
    "appointments/available-slots",
    patientId,
    appointmentTypeId,
    start,
    end,
  ],
  availableParticipants: (appointmentTypeId?: string, start?: Date, end?: Date) => [
    "appointments/available-participants",
    appointmentTypeId,
    start,
    end,
  ],
}

const practitionerAptsQueryKeys = {
  all: (currentOrganizationId: string) => ["practitioner/appointments", currentOrganizationId] as const,
  list: (
    currentOrganizationId: string,
    filters?: {
      start?: Date
      end?: Date
      patientId?: string[]
      practitionerId?: string[]
      appointmentType?: string[]
    },
  ) => [...practitionerAptsQueryKeys.all(currentOrganizationId), filters],
  questionnaires: ({ appointmentTypeId }: { appointmentTypeId?: string }) => [
    "appointment/available-questionnaires",
    appointmentTypeId,
  ],
}

export { patientAptsQueryKeys, practitionerAptsQueryKeys }
