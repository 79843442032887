import { faCakeCandles, faCircleUser, faLocationDot, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Person, humanNameAsString } from "fhir"
import { format, parseISO } from "date-fns"

import { formatsByTypes } from "data"
import { calculateAge, getStringAddress } from "utils"

const Patient360SearchDetails = ({ name, address, birthDate, gender, totalRecords }: Props) => (
  <div className="left-menu border-r min-w-[12rem] w-1/3 mr-1 content-start overflow-y-auto h-full">
    <div className="bg-white flex-1 justify-around flex flex-col mb-5">
      <div className="items-baseline flex justify-start bg-slate-100 font-bold px-1">
        <FontAwesomeIcon icon={faUser} size="1x" className="mr-1" />
        <span className="font-bold">{humanNameAsString(name?.[0])}</span>
      </div>

      <div className="text-zinc-500 font-normal text-sm tracking-tight pt-2.5 flex flex-col">
        <span>
          <FontAwesomeIcon icon={faCircleUser} className="text-sm" />
          <span className="px-1" title="Age">
            {calculateAge(birthDate)}
          </span>

          <span className="capitalize px-1" title="Biological Sex">
            {gender}
          </span>
        </span>
        {birthDate && (
          <span title="Birthdate" className="whitespace-nowrap">
            <FontAwesomeIcon icon={faCakeCandles} className="text-sm" />
            <span className="px-1" title="Birth date">
              {format(parseISO(birthDate), formatsByTypes.LONG_DATE)}
            </span>
          </span>
        )}
      </div>
    </div>
    <div className="flex flex-col">
      <div className="bg-slate-100 justify-start font-bold px-1 mb-2">Request Details</div>

      <span className="font-semibold">Addresses searched</span>
      <div className="flex flex-1 flex-col">
        {address?.length
          ? address.map((item, index) => (
              <span key={`${item.id}_${index}`} className="text-sm">
                <FontAwesomeIcon icon={faLocationDot} size="sm" className="mr-1" />
                {getStringAddress(item)}
              </span>
            ))
          : "Unspecified address"}
      </div>

      {/*   TODO: Data unavailable from operation */}
      {/* <span className="font-semibold mt-3">Organizations searched</span>
        <span className="text-sm">{organizationsSearched ?? 0}</span> */}
      {/*   TODO: Data unavailable from operation */}
      {/*  <span className="font-semibold mt-3">New records to add</span>
        <span className="text-sm">{newRecords ?? 0}</span> */}
      <span className="font-semibold mt-3">Total records found</span>
      <span className="text-sm">{totalRecords ?? 0}</span>
    </div>
  </div>
)

type Props = {
  totalRecords: number
  newRecords?: number
  organizationsSearched?: number
} & Person

export { Patient360SearchDetails }
