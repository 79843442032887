import { useChartContext } from "chart-view"
import { CardMenu } from "commons"
import { useAppModuleContext } from "internals"

import "./Intake.css"

const Intake: React.FC = () => {
  const { isModuleActive, appSubModules } = useAppModuleContext()

  if (!isModuleActive("intake"))
    throw new Error("Not found", {
      cause: { name: "404", message: `Resource not available` },
    })

  const { showModule, showSubModule, activeSubModule } = useChartContext()

  const updateSubview = (subviewId?: string) => {
    if (subviewId) {
      showSubModule({ subModule: appSubModules["intake"][subviewId] })
    } else {
      showModule({ module: "intake" })
    }
  }

  return !activeSubModule ? (
    <div className="px-3 pt-3 overflow-y-auto">
      <CardMenu
        menuItems={Object.values(appSubModules["intake"])}
        itemCommand={(item) => updateSubview(item?.getId() as string)}
      />
    </div>
  ) : (
    <div className="pt-3 flex flex-col h-full overflow-hidden">{activeSubModule.renderContent()}</div>
  )
}

export { Intake }
