import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers, FormikValues } from "formik"
import { classNames } from "primereact/utils"
import { type ReactNode, useEffect, useState } from "react"
import type { ObjectSchema } from "yup"

import { FormContainer } from "../forms"
import { Button } from "./Buttons"
import { InfoRow } from "./InfoRow"
import { Slideover } from "./Slideover"

const InformationCard = <T extends FormikValues>({
  id,
  title,
  data,
  isUpdating,
  lineEditData,
  children,
  onSubmit,
  initialValue,
  validationSchema,
  className,
}: Props<T>) => {
  const [showForm, setShowForm] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formTitle, setFormTitle] = useState(title)
  const [formValue, setFormValue] = useState(initialValue)
  const [currentActiveItem, setCurrentActiveItem] = useState<string | undefined>()

  const submit = (data: T, formikHelpers?: FormikHelpers<T>) => {
    onSubmit?.(data, formikHelpers, currentActiveItem)
    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting && !isUpdating) setShowForm(false)
  }, [isUpdating])

  return (
    <div className={classNames("border rounded-lg shadow-sm scroll-mt-4", className)} id={id}>
      <div className="flex justify-between items-start mb-1">
        <h3 className="flex font-semibold text-lg text-gray-900">{title}</h3>
        {!lineEditData && !!initialValue && (
          <Button buttonStyle="default" label="Edit" icon={faEdit} onClick={() => setShowForm(true)} />
        )}
      </div>

      {Object.entries(data).map(([itemTitle, data], index) => (
        <InfoRow
          key={index}
          title={itemTitle}
          content={data}
          loading={isUpdating}
          editable={!!lineEditData}
          onEdit={() => {
            setFormTitle(`${title} - ${itemTitle}`)
            setFormValue(lineEditData?.[index] ?? initialValue)
            setCurrentActiveItem(itemTitle)
            setShowForm(true)
          }}
        />
      ))}
      {!!initialValue && !!formValue && (
        <Slideover showSlide={showForm} onHide={() => setShowForm(false)}>
          <FormContainer
            onCancel={() => setShowForm(false)}
            onSubmit={submit}
            initialValue={!lineEditData ? initialValue : formValue}
            validationSchema={validationSchema}
            title={formTitle}
          >
            {typeof children === "function" ? children({ currentActiveItem }) : children}
          </FormContainer>
        </Slideover>
      )}
    </div>
  )
}

type Props<T extends FormikValues> = {
  id: string
  title: string
  data: Record<string, string | JSX.Element>
  className?: string
  children?: ReactNode | ((props: { currentActiveItem?: string }) => ReactNode)
} & EditableCard<T>

type EditableCard<T> =
  | {
      initialValue: T
      isUpdating: boolean
      lineEditData?: T[]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationSchema?: ObjectSchema<any>
      onSubmit(data: T, formikHelpers?: FormikHelpers<T>, activeItem?: string): void
    }
  | {
      initialValue?: undefined
      isUpdating?: undefined
      lineEditData?: undefined
      validationSchema?: undefined
      onSubmit?: undefined
    }

export { InformationCard }
