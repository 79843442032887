import type { FC } from "react"

// Custom function to parse and convert markdown to HTML
const parseMarkdown = (text: string) => {
  let html = text

  html = html.replace(/^### (.*$)/gim, "<h3>$1</h3>")
  html = html.replace(/^## (.*$)/gim, "<h2>$1</h2>")
  html = html.replace(/^# (.*$)/gim, "<h1>$1</h1>")

  html = html.replace(/\*\*(.*?)\*\*/gim, `<span class="text-gray-600 font-semibold">$1</span>`)

  html = html.replace(/\*(.*?)\*/gim, "<em>$1</em>")

  html = html.replace(/^> (.*$)/gim, "<blockquote>$1</blockquote>")

  html = html.replace(/^\s*[-*] (.*$)/gim, "<li>$1</li>")
  html = html.replace(/<\/li>\s*<li>/gim, "</li><li>")
  html = html.replace(/(<li>.*<\/li>)/gim, "<ul>$1</ul>")

  html = html.replace(/`(.*?)`/gim, "<code>$1</code>")

  html = html.replace(/```([\s\S]*?)```/gim, "<pre><code>$1</code></pre>")

  html = html.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2" target="_blank">$1</a>')

  html = html.replace(/\n/gim, "<br>")

  return html.trim()
}

const MarkdownTextDisplay: FC<{
  markdown: string
}> = ({ markdown }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(markdown),
      }}
    ></div>
  )
}

export { MarkdownTextDisplay }
