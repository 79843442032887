import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Medication, codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"

import { type StackedListItemProps, Button, GenericFieldArray } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import type { ConfigurationItem, ProcedureData } from "../../types"

const ProcedureConfigItems = ({ readOnly, isMassage, onEditMed }: Props) => {
  const {
    values: { procedure },
  } = useFormikContext<ProcedureData>()

  return (
    <div className="flex flex-col gap-5">
      <div className="font-medium text-gray-900 text-lg">{isMassage ? "Massages" : "Medications"}</div>
      <Button
        icon={faEdit}
        label="Configure"
        className="w-fit"
        buttonStyle="default"
        onClick={() => onEditMed(0)}
        disabled={!procedure.code}
      />
      <GenericFieldArray
        field="configurationItem"
        label={isMassage ? "Massages" : "Medications"}
        fieldLabelVisible={false}
        addButtonVisible={false}
        readOnly={readOnly}
        itemModelBuilder={(item: ConfigurationItem, index) =>
          medicationItemModel(item, readOnly, isMassage, index, () => onEditMed(index))
        }
        onRemoveItem={(item, { form: { values, setFieldValue } }) =>
          setFieldValue("deletedMedications", [
            ...values.deletedMedications,
            { mrId: item.medicationRequest?.id, maId: item.medicationAdministration?.id },
          ])
        }
      />
    </div>
  )
}

const getMedicationValueStr = (configuration: ConfigurationItem) => {
  const mrCC = codeableConceptAsString(configuration.medicationRequest?.medication?.CodeableConcept)
  const maDoseQty = configuration.medicationAdministration?.dosage?.dose
  const mrDispenseQty = configuration.medicationRequest?.dispenseRequest?.quantity?.value

  return `${mrCC} (${mrDispenseQty}) | ${maDoseQty?.value} ${maDoseQty?.unit}`
}

const medicationItemModel = (
  configuration: ConfigurationItem,
  readOnly: boolean,
  isMassage: boolean,
  index: number,
  onEdit: () => void,
): StackedListItemProps => {
  const med = configuration.medicationAdministration?.contained?.[0] as Medication
  return {
    image: !configuration.bodySite?.coding?.[0]?.code && <span className="rounded-full w-2 h-2 bg-yellow-500"></span>,
    leftData: [
      {
        lineItems: [
          {
            name: isMassage
              ? "Massage"
              : codeableConceptAsString(configuration.medicationRequest?.medication?.CodeableConcept),
            value: isMassage ? `Massage ${index + 1}` : getMedicationValueStr(configuration),
          },
        ],
      },
      ...(med?.batch && configuration.bodySite
        ? [
            {
              lineItems: [
                {
                  name: "Batch",
                  value: `L: ${med.batch.lotNumber} E: ${
                    med.batch.expirationDate
                      ? formatDate(new Date(med.batch.expirationDate), formatsByTypes.ISO_8601_DATE)
                      : "N/A"
                  }`,
                },
                {
                  name: "Administration site",
                  value: codeableConceptAsString(configuration.bodySite),
                },
              ],
            },
          ]
        : []),
    ],
    menu: readOnly
      ? undefined
      : [
          {
            label: isMassage ? "Edit massage" : "Edit medication",
            className: "text-red-500",
            icon: (
              <FontAwesomeIcon
                icon={faEdit}
                size="sm"
                className={classNames("mr-1", { "text-red-500": !med?.batch && !isMassage })}
              />
            ),
            command: onEdit,
          },
        ],
  }
}

type Props = {
  readOnly: boolean
  isMassage: boolean
  onEditMed(medIndex: number): void
}

export { ProcedureConfigItems }
