import { useInfiniteQuery } from "@tanstack/react-query"
import { type Coding, type InventoryItem, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useOrganizationContext } from "organization"
import { getCommonCode } from "utils"

import { inventoryQueryKeys } from "./query-keys"

const useInventory = ({ textFilter }: { textFilter?: string }) => {
  const { search } = useClient()
  const { location } = useOrganizationContext()
  const queryKey = inventoryQueryKeys.list(location?.id as string, textFilter)

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        location: location?.id as string,
        ...(textFilter ? { "medication-code": textFilter } : {}),
        _sort: "code",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: "InventoryItem", filters, signal })
      const inventoryItems = getResources<InventoryItem>(bundle, "InventoryItem")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        next,
        inventoryItems,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
  })

  const { inventoryItems, mkSkuCodes } = useMemo(() => {
    const inventoryItems = data?.pages?.flatMap((page) => page.inventoryItems) ?? []

    const mkSkuCodes = inventoryItems.reduce((acc, { code }) => {
      const sku = getCommonCode({
        codes: code?.reduce((codes, { coding }) => [...codes, ...(coding ?? [])], Array<Coding>()),
        fallback: "",
      })
      return [...acc, ...(sku ? [sku] : [])]
    }, Array<string>())

    return { inventoryItems, mkSkuCodes }
  }, [data?.pages])

  return {
    inventoryItems,
    mkSkuCodes,
    isLoading: isLoading,
    hasNextPage,
    fetchNextPage,
  }
}

export { useInventory }
