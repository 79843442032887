import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import type { Practitioner } from "fhir"
import type { FC } from "react"

import { InformationCard } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { formatsByTypes } from "data"
import { IdentifierContainer } from "identifier"
import { useOrganizationContext } from "organization"
import { useLoginContext } from "security"

import { ProfileSection } from "../data"
import { useUpdatePractitioner } from "../hooks"
import { sanitize } from "./validations"

const IdentifierInformation: FC = () => {
  const {
    loggedInPractitioner: { identifier },
    loggedInPractitionerId,
    reloadPractitioner,
  } = useLoginContext()

  const { isAdmin } = useOrganizationContext()

  const { patchPractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const onSubmit = (data: Partial<Practitioner>) => {
    patchPractitioner(sanitize({ id: loggedInPractitionerId, ...data }))
  }

  return (
    <ReplaceFormProvider>
      <InformationCard
        id={ProfileSection.IDENTIFIER}
        title="Identifier Information"
        className="profile-card-section p-4"
        data={{
          Identifiers: (
            <>
              {!identifier?.length ? (
                "No identifiers"
              ) : (
                <div className="flex flex-col space-y-2">
                  {identifier?.map(({ period, value, system }, index) => (
                    <div key={`${value}_${index}`} className="flex flex-wrap">
                      <div className="font-bold">
                        <span title="Identifier">{value}</span>
                      </div>
                      {system && (
                        <>
                          <span className="mx-1">|</span>
                          <span title="System" className="whitespace-nowrap text-ellipsis overflow-hidden max-w-xs">
                            {system}
                          </span>
                        </>
                      )}
                      {period && (
                        <>
                          <span className="mx-1">|</span>
                          <span title="Period">
                            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-1" />
                            {period.start && format(parseISO(period?.start), formatsByTypes.LONG_DATE)}
                            {period.end && (
                              <> - {period?.end && format(parseISO(period?.end), formatsByTypes.LONG_DATE)}</>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          ),
        }}
        initialValue={{ identifier }}
        isUpdating={isUpdating}
        onSubmit={onSubmit}
      >
        <IdentifierContainer field="identifier" label="Identifiers" hideAdd={!isAdmin} />
      </InformationCard>
    </ReplaceFormProvider>
  )
}

export { IdentifierInformation }
