import type { CodeableConcept, Identifier, Reference } from "fhir"
import * as Yup from "yup"

import { IDENTIFIER_CODE } from "data"
import { isValidNPIValue } from "identifier/utils"

const identifierInitialValues = {
  value: "",
  system: "",
  type: {},
  assigner: {
    display: "",
    id: "",
    resourceType: "Organization",
  },
}

const sanitizeAssigner = ({ ...identifier }: Partial<Identifier>) => {
  const sanitizedAssigner = identifier.assigner

  if (sanitizedAssigner?.id !== "") {
    return { ...identifier, assigner: sanitizedAssigner }
  }

  delete identifier.assigner

  return identifier
}

const sanitize = (identifier: Identifier) => {
  identifier = sanitizeAssigner(identifier)
  return {
    ...identifier,
    value:
      identifier.type?.coding?.[0].code === IDENTIFIER_CODE.HEALTH_GORILLA
        ? `${IDENTIFIER_CODE.HEALTH_GORILLA}:${identifier.value}`
        : identifier.value,
  }
}

const getIdentifierValidationSchema = (isType: boolean = false) => {
  return Yup.object().shape({
    value: Yup.string()
      .when("type", {
        is: (type: CodeableConcept) => type && type.coding?.[0].code === IDENTIFIER_CODE.NPI,
        then: Yup.string().test("ValidNPI", "The NPI provided is not valid", (value: string | undefined) =>
          isValidNPIValue(value as string),
        ),
      })
      .required("Value is required"),
    assigner: Yup.object().when("type", {
      is: (type: CodeableConcept) => type && type.coding?.[0].code === IDENTIFIER_CODE.AN,
      then: Yup.object()
        .test("test-id", "Asigner is required", (assigner: Reference) => !!assigner.id)
        .required("Asigner is required"),
    }),
    type: Yup.object().test("test-type", "Type is required", (type: CodeableConcept) =>
      isType ? !!type.coding?.[0].code : true,
    ),
    system: Yup.string().test("system-type", "System is required", (system: string | undefined) =>
      !isType ? !!system : true,
    ),
  })
}

export { getIdentifierValidationSchema, identifierInitialValues, sanitize }
