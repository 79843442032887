import type { LabPanel } from "../../../types"

const labPanelModelBuilder = (item: LabPanel) => ({
  leftData: [
    {
      lineItems: [{ name: "Code", value: item.display ?? item.planDefinition.title }],
    },
    {
      lineItems: [
        {
          name: "Laboratory and identifier",
          value: `${item.planDefinition.publisher} - ${item.planDefinition.identifier?.find((id) => id.system?.includes("sku"))?.value}`,
        },
      ],
    },
  ],
  rightData: [
    {
      lineItems: [
        {
          name: "Price",
          value: item?.price ? `$${item.price}` : "No price",
        },
      ],
    },
  ],
})

export { labPanelModelBuilder }
