import type { FC } from "react"

import type { TrackingData } from "../types"
import { TrackingCodeItem } from "./TrackingCodeItem"

const MedicationOrderTrackingInfo: FC<Props> = ({ trackingCodes }) =>
  !!trackingCodes?.length && (
    <div className="flex flex-col pr-1 border rounded-lg p-4">
      <div className="font-semibold mb-2 ">Tracking code: </div>
      {trackingCodes?.map((data, index) => <TrackingCodeItem key={index} trackingCode={data} />)}
    </div>
  )

type Props = {
  trackingCodes?: TrackingData[]
}

export { MedicationOrderTrackingInfo }
