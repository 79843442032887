import { useQuery } from "@tanstack/react-query"
import {
  type CarePlan,
  type PlanDefinition,
  type QuestionnaireResponse,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
} from "fhir"

import { useClient } from "api"
import { usePatientContext } from "patients"

import { proceduresQueryKeys } from "../query-keys"

const useProcedurePDAndQRFromPlan = (carePlanId?: string | null) => {
  const { patientId } = usePatientContext()
  const { search } = useClient()
  const queryKey = proceduresQueryKeys.pd.pdAndQr(carePlanId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: carePlanId as string,
        _include:
          "instantiates-canonical:PlanDefinition,PlanDefinition:definition:PlanDefinition,outcome-reference:QuestionnaireResponse",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })
      const carePlan = getResource<CarePlan>(bundle, "CarePlan")
      const planDefinitions = getResourcesByTypeAsIndex<PlanDefinition>(
        bundle,
        "PlanDefinition",
        ({ url, version }) => `${url}|${version}`,
      )
      const cpPlanDefinition = planDefinitions?.[carePlan.instantiatesCanonical?.[0] as string]

      const procedureAction = cpPlanDefinition?.action?.find(
        ({ code }) => code?.[0]?.coding?.[0]?.code === "request-procedure",
      )

      const procedurePlanDefinition = planDefinitions?.[procedureAction?.definition?.canonical as string]

      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")

      const sendQuestionnaireAction = procedurePlanDefinition?.action?.find(
        ({ code }) => code?.[0]?.coding?.[0]?.code === "send-questionnaire",
      )
      const procedureQR = questionnaireResponses.find(
        ({ questionnaire }) => questionnaire === sendQuestionnaireAction?.definition?.canonical,
      )

      return {
        procedurePlanDefinition,
        procedureQR,
      }
    },

    enabled: !!carePlanId,
    meta: { context: { queryKey } },
  })

  return {
    procedurePlanDefinition: data?.procedurePlanDefinition,
    procedureQR: data?.procedureQR,
    isLoading,
  }
}

export { useProcedurePDAndQRFromPlan }
