import type { SettingItemArray } from "fhir"
import { type FC, type PropsWithChildren, createContext, useCallback, useMemo } from "react"

import { useModulesVisibility } from "administration"
import { type AppModule, type AppSubModulesTypes, ModuleRenderSideType } from "commons"
import { useAuth } from "security"

const AppModuleContext = createContext<State | undefined>(undefined)
AppModuleContext.displayName = "AppModuleContext"

const AppModuleProvider: FC<PropsWithChildren<Props>> = ({ children, orgId }) => {
  const { AppModules, AppSubModules } = useAuth()
  const { modulesSettings } = useModulesVisibility(orgId)

  const isModuleActive = useCallback(
    (module: string) =>
      !modulesSettings?.[`${module}-enabled`] || modulesSettings?.[`${module}-enabled`]?.value?.boolean === true,
    [modulesSettings],
  )

  const { activeKpModules, activeWorkspaceModules, appModules, activeDynamicModules } = useMemo(
    () =>
      AppModules.reduce(
        (prev, module) => {
          let isActive = true

          if (module.allowHideBySetting()) {
            const id = module.getParent() || module.getId()

            isActive = isModuleActive(id)
          }

          if (isActive) {
            switch (module.getRenderSideType()) {
              case ModuleRenderSideType.KP:
                return {
                  ...prev,
                  activeKpModules: [...prev.activeKpModules, module],
                  appModules: { ...prev.appModules, [module.getId()]: module },
                }

              case ModuleRenderSideType.WORKSPACE:
                return {
                  ...prev,
                  activeWorkspaceModules: [...prev.activeWorkspaceModules, module],
                  appModules: { ...prev.appModules, [module.getId()]: module },
                }

              case ModuleRenderSideType.DYNAMIC_WORKSPACE:
                return {
                  ...prev,
                  activeDynamicModules: [...prev.activeDynamicModules, module],
                  appModules: { ...prev.appModules, [module.getId()]: module },
                }

              case ModuleRenderSideType.DYNAMIC_WORKSPACE_WITH_KP:
                return {
                  ...prev,
                  activeKpModules: [...prev.activeKpModules, module],
                  activeDynamicModules: [...prev.activeDynamicModules, module],
                  appModules: { ...prev.appModules, [module.getId()]: module },
                }

              default:
                return {
                  ...prev,
                  activeKpModules: [...prev.activeKpModules, module],
                  activeWorkspaceModules: [...prev.activeWorkspaceModules, module],
                  appModules: { ...prev.appModules, [module.getId()]: module },
                }
            }
          }

          return { ...prev, appModules: { ...prev.appModules, [module.getId()]: module } }
        },
        {
          activeKpModules: new Array<AppModule>(),
          activeWorkspaceModules: new Array<AppModule>(),
          activeDynamicModules: new Array<AppModule>(),
          appModules: {},
        },
      ),
    [isModuleActive],
  )

  const values = {
    activeKpModules,
    activeWorkspaceModules,
    appModules,
    appSubModules: AppSubModules,
    modulesSettings,
    activeDynamicModules,
    isModuleActive,
  }
  return <AppModuleContext.Provider value={values}>{children}</AppModuleContext.Provider>
}

type State = {
  activeKpModules: AppModule[]
  activeDynamicModules: AppModule[]
  activeWorkspaceModules: AppModule[]
  appModules: Record<string, AppModule>
  appSubModules: AppSubModulesTypes
  modulesSettings?: Record<string, SettingItemArray>
  isModuleActive(module: string): boolean
}

type Props = {
  orgId: string
}

export { AppModuleContext, AppModuleProvider }
