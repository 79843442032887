import { useQuery } from "@tanstack/react-query"
import type { InventoryReport } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { proceduresQueryKeys } from "../query-keys"
import type { InventoryData } from "../types"

const useMEInventory = (catalogId?: string) => {
  const getMEInventory = useMEInventoryQueryFunction()
  const queryKey = proceduresQueryKeys.medInventory(catalogId)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => getMEInventory(catalogId as string),
    enabled: catalogId !== undefined,
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, catalogId } },
  })

  return {
    isLoading: isLoading || isFetching,
    inventoryData: data?.inventoryData ?? new Map<string, InventoryData[]>(),
    invMedCodes: data?.invMedCodes ?? [],
  }
}

const useMEInventoryQueryFunction = () => {
  const { operationRequest } = useClient()
  const { location } = useOrganizationContext()

  return async (catalogId: string) => {
    const initialMap = new Map<string, InventoryData[]>()
    if (!location?.id) return { inventoryData: initialMap, invMedCodes: [] }

    const filters = new URLSearchParams({
      "catalog-id": catalogId as string,
    })

    const inventory = await operationRequest<InventoryReport>({
      endpoint: `Location/${location.id}`,
      method: "GET",
      operation: "inventory",
      filters,
    })

    const inventoryData =
      inventory?.inventoryListing?.reduce((acc, inv) => {
        inv.items?.forEach((item) => {
          const medCode = item.item.coding?.[0].code as string
          const invData: InventoryData = {
            medCode,
            lotNumber: item.lot as string,
            expirationDate: item.expiry as string,
            quantity: item.quantity.value as number,
          }

          const data = acc.get(medCode)
          if (data) {
            data.push(invData)
          } else {
            acc.set(medCode, [invData])
          }
        })
        return acc
      }, initialMap) ?? initialMap

    return {
      inventoryData,
      invMedCodes: Array.from(inventoryData.keys()),
    }
  }
}

export { useMEInventory, useMEInventoryQueryFunction }
