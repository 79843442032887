import type { Account, AccountBETACreditCardArray } from "fhir"
import type { FC } from "react"

import { ConfirmDialog, DataContainerSlideoverForm, ModulesId, StackedListContainer, useCrudReducer } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"
import { getAddressByType } from "utils"

import { useCreateCreditCard, useDeleteCreditCard, useUpdateCreditCard, useUpdateDefaultCreditCard } from "../hooks"
import { creditCardModelBuilder, isExpired } from "../transformers"
import { CreditCardForm } from "./CreditCardForm"
import { creditCardValidationSchema, getInitialValues } from "./validations"

const CreditCardContainer: FC = () => {
  const { patientId, patient, account, creditCards, preferedCreditCard } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { showSlide, initialValue, isNew, editIndex, deleteIndex, reset, add, editWithIndex, setDeleteIndex } =
    useCrudReducer({ defaultEntity: getInitialValues(patient) })

  const { createCreditCard } = useCreateCreditCard(reset)
  const { updateCreditCard } = useUpdateCreditCard(reset)
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => setDeleteIndex())
  const { updateDefaultCreditCard, isUpdating } = useUpdateDefaultCreditCard({ onSettled: reset })

  const onSubmit = (creditCard: AccountBETACreditCardArray) => {
    isNew
      ? createCreditCard({
          creditCard,
          account: account as Account,
          creditCardList: creditCards,
          patientId,
        })
      : updateCreditCard({
          creditCard,
          account: account as Account,
          creditCardList: creditCards,
          index: editIndex as number,
        })
  }

  const getCCid = (creditCard: AccountBETACreditCardArray) => `${creditCard.type}|${creditCard.last4Digits}`
  const defaultCreditCardId = preferedCreditCard && getCCid(preferedCreditCard)

  return (
    <DataContainerSlideoverForm
      hasData={!!creditCards?.length}
      showSlide={showSlide}
      formTitle={"Credit Card"}
      formInitialValue={initialValue}
      validationSchema={creditCardValidationSchema}
      subMessageDataNotFound={false}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<CreditCardForm isEditing={!isNew} shippingAddress={getAddressByType("postal", patient.address)} />}
      customAddButtonText="Add Credit Card"
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["patient"][ModulesId.CREDIT_CARDS].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={creditCards}
          itemModelBuilder={(item, index) =>
            creditCardModelBuilder(
              item,
              defaultCreditCardId === getCCid(item),
              isUpdating,
              isExpired(item),
              () => {
                updateDefaultCreditCard({
                  creditCardId: getCCid(item),
                  account: account as Account,
                })
              },
              () => editWithIndex(item, index),
              () => setDeleteIndex(index),
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this credit card?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() =>
          removeCreditCard({
            index: deleteIndex as number,
            accountId: account?.id as string,
            creditCardList: creditCards,
            defaultCreditCardId,
            patientId,
          })
        }
        hideDialog={() => setDeleteIndex()}
      />
    </DataContainerSlideoverForm>
  )
}

export { CreditCardContainer }
