import { type FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import { ConfirmDialog } from "primereact/confirmdialog"

import { usePatientContext } from "patients"
import { Button, ModulesId } from "commons"
import { CPOE_STEPS, useCPOEContext } from "orders"

import { useOpenEncounter } from "../hooks"
import { EncounterFinishButton } from "./EncounterFinishButton"
import { EncounterConfig } from "./EncounterConfig"

const EncounterActions: FC = () => {
  const { patientId, patientRef } = usePatientContext()
  const { openEncounter, isLoading } = useOpenEncounter(patientId)
  const { ordersCount, showCpoeOverlay } = useCPOEContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showCheckoutConfirm, setShowCheckoutConfirm] = useState(false)
  const [startEncounter, setStartEncounter] = useState(false)

  const returnToChart = () => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
    })

    setSearchParams(params)
  }

  const showEncounterDetails = () => {
    if (ordersCount) {
      setShowCheckoutConfirm(true)
    } else {
      const params = new URLSearchParams({
        ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
        view: ModulesId.ENCOUNTER,
      })

      setSearchParams(params)
    }
  }

  const configureEncounter = () => {
    setStartEncounter(true)
  }

  return (
    <div>
      {!openEncounter ? (
        <>
          <Button
            label="Start encounter"
            loading={isLoading}
            onClick={configureEncounter}
            icon={faPlay}
            font="font-normal"
            className="button-banner button-primary"
          />

          <EncounterConfig
            visible={startEncounter}
            patientId={patientId}
            patientRef={patientRef}
            onHide={() => setStartEncounter(false)}
          />
        </>
      ) : (
        <>
          <EncounterFinishButton
            patientId={patientId}
            patientRef={patientRef}
            encounter={openEncounter}
            onEncounterFinished={returnToChart}
            buttonClassName="button-banner bg-transparent rounded-lg"
            onStopClicked={showEncounterDetails}
          />

          <ConfirmDialog
            id="checkout-confirm"
            header="Confirmation"
            className="w-fit max-w-96"
            visible={showCheckoutConfirm}
            onHide={() => setShowCheckoutConfirm(false)}
            acceptLabel="Checkout"
            rejectLabel="Resume"
            rejectClassName="button-default p-button-sm"
            acceptClassName="button-primary p-button-sm"
            message="You have items on the cart pending to checkout before closing the encounter. Do you want to go to checkout page?"
            accept={() => showCpoeOverlay(CPOE_STEPS.SUMMARY)}
            reject={returnToChart}
          />
        </>
      )}
    </div>
  )
}

export { EncounterActions }
