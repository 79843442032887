import { useQuery } from "@tanstack/react-query"
import {
  type AuditEvent,
  type Device,
  type Invoice,
  type MedicationDispense,
  type MedicationRequest,
  type Patient,
  type PaymentReconciliation,
  type Practitioner,
  type PractitionerRole,
  type RelatedPerson,
  type ServiceRequest,
  type Task,
  getResources,
  humanNameAsString,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { auditEventsQueryKeys } from "../query-keys"
import type { ParticipantData } from "../types"

const useAuditEvent = (organizationId: string, eventId: string) => {
  const { search } = useClient()
  const queryKey = auditEventsQueryKeys.details(organizationId, eventId)

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: eventId,
        _include: "entity, agent",
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/AuditEvent`, filters, signal })

      const events = getResources<AuditEvent>(bundle, "AuditEvent")

      // Participants
      const patients = getResources<Patient>(bundle, "Patient")
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const devices = getResources<Device>(bundle, "Device")
      const practitionerRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")
      const relatedPersons = getResources<RelatedPerson>(bundle, "RelatedPerson")

      const invoices = getResources<Invoice>(bundle, "Invoice")
      const paymentReconciliations = getResources<PaymentReconciliation>(bundle, "PaymentReconciliation")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const tasks = getResources<Task>(bundle, "Task")

      return {
        event: events?.[0],
        patients,
        practitioners,
        devices,
        practitionerRoles,
        relatedPersons,
        invoices,
        medicationRequests,
        medicationDispenses,
        paymentReconciliations,
        serviceRequests,
        tasks,
      }
    },
    meta: { context: { queryKey, organizationId, eventId } },
  })

  const participants = useMemo(() => {
    const participants = data?.event.agent.reduce((acc, agent) => {
      let result

      if (agent.who?.resourceType === "Patient") {
        const patient = data?.patients.find((pat) => pat.id === agent.who?.id)
        result = { name: humanNameAsString(patient?.name?.[0]), type: "Patient", requestor: agent.requestor }
      }

      if (agent.who?.resourceType === "Practitioner") {
        const practitioner = data?.practitioners.find((pract) => pract.id === agent.who?.id)
        result = { name: humanNameAsString(practitioner?.name?.[0]), type: "Practitioner", requestor: agent.requestor }
      }

      if (agent.who?.resourceType === "PractitionerRole") {
        const practitionerRole = data.practitionerRoles.find((pract) => pract.id === agent.who?.id)
        result = {
          name: practitionerRole?.code?.[0].coding?.[0].display,
          type: "PractitionerRole",
          requestor: agent.requestor,
        }
      }

      if (agent.who?.resourceType === "Device") {
        const device = data?.devices?.find((d) => d.id === agent.who?.id)
        result = { name: device?.deviceName?.[0].name, type: "Device", requestor: agent.requestor }
      }

      if (agent.who?.resourceType === "RelatedPerson") {
        const relatedPerson = data?.relatedPersons?.find((rp) => rp.id === agent.who?.id)
        result = {
          name: humanNameAsString(relatedPerson?.name?.[0]) ?? agent.name,
          type: "RelatedPerson",
          requestor: agent.requestor,
        }
      }

      if (agent.who?.type === "Client") {
        result = { name: agent.who.display, type: "Client", requestor: agent.requestor }
      }

      if (!agent.who?.type && !agent.who?.resourceType) {
        result = { name: agent?.who?.display, type: "", requestor: agent.requestor }
      }

      if (result) {
        if (!result.name) {
          result.name = agent.name ?? agent.who?.display
        }
        return [...acc, result]
      }

      return acc
    }, new Array<ParticipantData>())

    return participants?.sort((a) => (a.requestor ? -1 : 1))
  }, [data])

  if (isError) {
    throw error
  }

  return {
    auditEvent: data?.event,
    participants,
    tasks: data?.tasks,
    invoices: data?.invoices,
    medicationRequests: data?.medicationRequests,
    medicationDispenses: data?.medicationDispenses,
    paymentReconciliations: data?.paymentReconciliations,
    serviceRequests: data?.serviceRequests,
    isLoading,
  }
}

export { useAuditEvent }
