import type { Observation } from "fhir"
import type { CarePlanWithDR } from "./types"
import { compareDesc } from "date-fns"

export const hasObservationValue = (observation: Observation) =>
  !!(observation.value?.Quantity?.value || observation.value?.string)

export const renameSRStatus = (status: string) =>
  ({ completed: "ready", "entered-in-error": "archived", active: "pending" })[status] ?? status

export const getSRStatusOrder = (status: string = "") =>
  ({
    draft: 0,
    pending: 1,
    archived: 2,
    ready: 3,
  })[status] ?? Infinity

const getDateToBeCompared = (item: CarePlanWithDR, status: string) =>
  status === "draft" ? (item.order.meta?.createdAt as Date) : (item.plan?.meta?.createdAt as Date)

export const sortCarePlanItems = (items: CarePlanWithDR[], status: string): CarePlanWithDR[] =>
  items.toSorted((item1, item2) => compareDesc(getDateToBeCompared(item1, status), getDateToBeCompared(item2, status)))

export const planStatusOptions = (isOrder = false) => [
  { display: "Draft", code: "draft" },
  { display: isOrder ? "Pending" : "Pending", code: "active" },
  { display: isOrder ? "Completed" : "Ready", code: "completed" },
  { display: isOrder ? "Cancelled" : "Archived", code: isOrder ? "revoked" : "entered-in-error" },
]
