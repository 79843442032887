import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { AllergyIntolerance } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { allergiesQueryKeys } from "allergies"
import { plansQueryKeys } from "../query-keys"

const useAddAllergy = (onSettled: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const newAllergy = async ({ planId, allergy }: { patientId: string; planId: string; allergy: AllergyIntolerance }) =>
    allergy.id
      ? update("AllergyIntolerance", allergy.id as string, allergy)
      : create(`CarePlan/${planId}/$add-resource`, allergy)

  const { mutate: addAllergy, isPending: isAdding } = useMutation({
    mutationFn: newAllergy,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patientId, planId, allergy }) => {
      queryClient.refetchQueries({
        queryKey: plansQueryKeys.manualAllergies(patientId, planId),
        type: "all",
      })
      await queryClient.invalidateQueries({
        queryKey: allergiesQueryKeys.list(patientId, "KP"),
        refetchType: "active",
      })
      displayNotificationSuccess(`Allergy ${allergy.id ? "updated" : "added"} successfully!`)
      // datadogLogs.logger.info(`Manual allergy of plan ${planId} ${condition.id ? "updated" : "added"} successfully!`)
    },
    onSettled,
  })

  return { addAllergy, isAdding }
}

export { useAddAllergy }
