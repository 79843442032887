import { faEye, faPlayCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledge, MedicationRequest, Reference } from "fhir"
import { type FC, useState } from "react"

import { MEDICATION_CATALOG } from "data"
import { prescriptionItemModel } from "eprescribe"
import { MedicationRequestListItem } from "medication-requests"
import { usePatientContext } from "patients"
import { getCommonCode } from "utils"

import { Button } from "../../../components/Buttons"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { StackedListContainer } from "../../../components/StackedListContainer"
import { useChargeItemDefinitions } from "../../../hooks"
import { MedicationDetails, useMedicationKnowledge, useMedicationRequestDataBind } from "../../../meds"
import { getMedCodes } from "../../../utils"
import { useResumeCarePlanMRs } from "../../hooks"

const CPMedicationsDetails: FC<Props> = ({
  medicationRequests,
  organizationId,
  category,
  className,
  shouldResumeMedications,
  planId,
}) => {
  const medCodes = getMedCodes({ meds: medicationRequests })
  const sku = medCodes?.map((coding) => getCommonCode({ codes: [coding] })) ?? []
  const { patientId } = usePatientContext()
  const showResumeAllButton = shouldResumeMedications && medicationRequests.some(({ doNotPerform }) => doNotPerform)

  const [selectedMed, setSelectedMed] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const [selectedMRId, setSelectedMRId] = useState<string | null>(null)

  const { isLoading, mksBySku } = useMedicationKnowledge({
    enabled: !!sku.length,
    medCodes: sku.join(","),
    hasCIDInOrg: organizationId,
    category,
  })

  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: organizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges: mksBySku,
    medicationsCIDs: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs,
  })

  const { resumeMedicationRequests, isResuming } = useResumeCarePlanMRs(patientId)
  const isResumingAll = isResuming && !selectedMRId

  const handleResumeAll = () => {
    setSelectedMRId(null)
    resumeMedicationRequests({
      mrIds: medicationRequests.reduce<string[]>(
        (prev, { doNotPerform, id }) => (doNotPerform ? [...prev, id!] : prev),
        [],
      ),
      planId: planId as string,
    })
  }

  const handleResumeMR = (mrId: string) => {
    setSelectedMRId(mrId)
    resumeMedicationRequests({ mrIds: [mrId], planId: planId as string })
  }

  if (isLoading) return <SkeletonLoader repeats={sku.length} loaderType="two-lines" />

  return (
    <div className="flex p-3 flex-col">
      <div className="flex justify-between">
        {showResumeAllButton && (
          <Button
            label="Resume all"
            buttonStyle="text"
            className="underline"
            onClick={handleResumeAll}
            loading={isResumingAll}
          />
        )}
      </div>
      {category === MEDICATION_CATALOG.NUTRA ? (
        <div className="flex flex-col">
          {medicationRequestData.map((item, index) => (
            <MedicationRequestListItem
              key={item.medicationRequestInfo.id ?? index}
              medicationData={item}
              onSelectMK={() => setSelectedMed({ mk: item.medicationKnowledge, mr: item.medicationRequestInfo })}
              medicationRequestActions={{ resume: handleResumeMR }}
              shouldResume={shouldResumeMedications}
              isLoading={isResuming && selectedMRId === item.medicationRequestInfo.id}
              showActions={shouldResumeMedications}
            />
          ))}
        </div>
      ) : (
        <StackedListContainer
          data={medicationRequestData}
          itemModelBuilder={(data) =>
            prescriptionItemModel({
              mrData: data,
              showAsExternalAction: !data.medicationRequestInfo.doNotPerform,
              preview: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
              externalAction: [
                {
                  label: "Preview",
                  icon: <FontAwesomeIcon icon={faEye} />,
                  command: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
                },
                ...(shouldResumeMedications && data.medicationRequestInfo.doNotPerform
                  ? [
                      {
                        icon: <FontAwesomeIcon icon={faPlayCircle} />,
                        label: "Resume",
                        command: () => handleResumeMR(data.medicationRequestInfo.id ?? ""),
                        disabled: !data.medicationRequestInfo.doNotPerform,
                      },
                    ]
                  : []),
              ],
              loading: isResuming && selectedMRId === data.medicationRequestInfo.id,
            })
          }
          className={className}
        />
      )}

      <MedicationDetails
        medication={
          selectedMed
            ? {
                ...selectedMed?.mr,
                medicationKnowledge: selectedMed?.mk,
                intent: selectedMed.mr?.intent as string,
                status: selectedMed.mr?.status as string,
                subject: selectedMed.mr?.subject as Reference,
              }
            : undefined
        }
        onHide={() => setSelectedMed(undefined)}
      />
    </div>
  )
}

type Props = {
  medicationRequests: MedicationRequest[]
  organizationId: string
  category: MEDICATION_CATALOG
  className?: string
  shouldResumeMedications?: boolean
  planId?: string
}

export { CPMedicationsDetails }
