import { faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { type Duration, isCarePlan, isPatient } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"

import { Button, CardListItem } from "commons"
import { type DispenseIntervalOption, allDispenseInterval } from "commons/meds"
import { useOrganizationContext } from "organization"
import { getMoneyCurrencyAlt, strCapitalize, unitToDays } from "utils"

import type { CpoeRequest } from "../types"
import "./OrderListItem.css"

const OrderListItem = ({ medRequest, onChange, editable, isDeleting, readonly, onDelete }: Props) => {
  const { isExemptPaymentPellet } = useOrganizationContext()
  const dispenseTemplate = (option: DispenseIntervalOption) => `${strCapitalize(option?.label)}`
  const {
    medicationRequest,
    manufacturer,
    doseForm,
    packaging,
    isDfo,
    dispenseRequest,
    isProcedure,
    isMedication,
    maxDaysSupplyLimit,
  } = medRequest.medicationData ?? {}

  const planBased = medicationRequest?.basedOn?.some(isCarePlan) ?? false

  const effectiveDispenseIntervals = useMemo(
    () =>
      isMedication
        ? allDispenseInterval.filter((dispenseIntervalOption) => {
            const specifiedDurationInDays =
              (dispenseIntervalOption?.value?.value as number) * unitToDays(dispenseIntervalOption?.value?.code)

            return (
              specifiedDurationInDays <= (maxDaysSupplyLimit || 0) &&
              specifiedDurationInDays * (dispenseRequest?.numberOfRepeatsAllowed || 1) <= (maxDaysSupplyLimit || 0)
            )
          })
        : allDispenseInterval,
    [dispenseRequest, isMedication, maxDaysSupplyLimit],
  )

  const [deletePressed, setDeletePressed] = useState(false)

  const head = (
    <div className="flex justify-between w-full items-center gap-1 h-fit">
      <div className="flex flex-1 items-center gap-2">
        {editable && !readonly && (
          <Checkbox
            name="status"
            checked={medRequest.resource.code?.[0].coding?.[0].code === "activate"}
            onChange={(e) => onChange?.(e.target.name, !!e.checked)}
          />
        )}
        <div className="flex flex-1 flex-col">
          <div className="font-semibold text-gray-600 flex line-clamp-2 text-wrap text-ellipsis">
            {medRequest.display}
          </div>
          <div className="line-clamp-1 space-x-1 divide-x text-gray-400 text-sm text-ellipsis">
            {!!manufacturer && <span title="Manufacturer">{manufacturer}</span>}
            {!!doseForm && (
              <span title="Drug Form" className="first:pl-0 pl-1">
                {doseForm}
              </span>
            )}
            {!!packaging && (
              <span title="Packaging" className="first:pl-0 pl-1">
                Pkg: {packaging}
              </span>
            )}
            {!!isDfo && (
              <span title="Dispense from office" className="first:pl-0 pl-1">
                DFO
              </span>
            )}
          </div>

          {isPatient(medicationRequest?.recorder) && (
            <span className="text-orange-700 text-xs truncate">Requested by patient</span>
          )}
        </div>
      </div>
      <div className="flex min-w-[35%] max-w-[50%] items-center justify-end gap-3">
        <div className="flex flex-wrap-reverse items-center gap-3 justify-end">
          {!isDfo && (
            <span
              className="inline-flex text-gray-400"
              title={editable && !readonly ? "Click to change dispense interval" : "Dispense"}
            >
              <Dropdown
                name="dispenseInterval"
                options={effectiveDispenseIntervals}
                optionLabel="label"
                optionValue="value"
                className="p-inputtext-sm order"
                valueTemplate={dispenseTemplate}
                value={dispenseRequest?.dispenseInterval}
                disabled={!editable || readonly}
                onChange={(e) => onChange?.(e.target.name, e.target.value)}
              />
            </span>
          )}
          <span className="font-bold text-gray-600 text-right flex-1">
            {getMoneyCurrencyAlt(medRequest?.unitPrice?.currency)}
            {(isExemptPaymentPellet && isProcedure ? 0 : medRequest?.unitPrice?.value ?? 0).toFixed(2)}
          </span>
        </div>
        <div className={classNames("flex justify-end items-center", { "min-w-9": !readonly })}>
          {!!onDelete && !planBased && !readonly && (
            <Button
              icon={faTrashCan}
              loading={isDeleting && deletePressed}
              buttonStyle="text"
              onClick={() => {
                setDeletePressed(true)
                onDelete(medRequest.resource.resource?.id as string)
              }}
              title="Delete"
            />
          )}
        </div>
      </div>
    </div>
  )

  return <CardListItem className="px-2 border-none order-item" contentHeader={head} />
}

type Props = {
  medRequest: CpoeRequest
  onChange?(field: string, value: boolean | Duration): void
  editable: boolean
  onDelete?(itemId: string): void
  isDeleting?: boolean
  readonly?: boolean
}

export { OrderListItem }
