import * as Yup from "yup"

import type { AppointmentType } from "../../types"

const getInitialValues = (): AppointmentType => ({
  withLabs: false,
  withQuestionnaires: false,
  duration: 15,
  daysInAdvance: undefined,
  practitioners: [],
  questionnaires: [],
  provider: undefined,
  requester: undefined,
  patientCanBook: false,
  telemedicine: false,
  name: undefined,
})

const validationSchema = Yup.object({
  withLabs: Yup.boolean().nullable(),
  provider: Yup.object()
    .nullable()
    .when("withLabs", (withLabs: boolean, schema) => {
      return withLabs ? schema.required("Laboratory is required") : schema
    }),
  requester: Yup.object()
    .nullable()
    .when("withLabs", (withLabs: boolean, schema) => {
      return withLabs ? schema.required("Practitioner is required") : schema
    }),
  withQuestionnaires: Yup.boolean().nullable(),
  questionnaires: Yup.array(
    Yup.object({
      id: Yup.string(),
    }),
  )
    .nullable()
    .when("withQuestionnaires", (withQuestionnaires: boolean, schema) => {
      return withQuestionnaires ? schema.min(1, "At least one questionnaire is required") : schema
    }),
  patientCanBook: Yup.boolean().nullable(),
  daysInAdvance: Yup.number()
    .when("patientCanBook", (patientCanBook: boolean, schema) => {
      return patientCanBook ? schema.required("Please specify a value") : schema
    })
    .nullable(),
  practitioners: Yup.array().min(1, "Please specify at least one practitioner"),
})

export { getInitialValues, validationSchema }
