import type { TabPanel } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { Children } from "react"

const VerticalTabView = ({ children, selectedPanel, panelChange, tabsFooter }: Props) => (
  <div className="flex p-2 h-full overflow-hidden">
    <div className="flex flex-col max-w-lg min-w-fit p-1 pr-0">
      <div className="grow overflow-y-auto">
        {Children.map(children, (child, index) =>
          child?.props.visible ?? true ? (
            <div
              className={classNames(
                "transition ease-in-out delay-75 p-3 py-4 border-b border-b-slate-100 border-r border-r-slate-100 cursor-pointer flex items-center hover:bg-slate-50",
                {
                  "bg-slate-100": index === selectedPanel,
                },
              )}
              onClick={() => panelChange(index)}
            >
              {child?.props.headerTemplate ? child?.props.headerTemplate : child?.props.header}
            </div>
          ) : (
            <></>
          ),
        )}
      </div>
      {tabsFooter}
    </div>
    <div className="pb-0 overflow-hidden flex flex-col w-full">
      {Children.map(children, (child, index) => (index === selectedPanel ? child.props.children : <></>))}
    </div>
  </div>
)

type Props = {
  selectedPanel?: number
  panelChange(index: number): void
  children: TabPanel | TabPanel[] | JSX.Element | JSX.Element[]
  tabsFooter?: JSX.Element
}

export { VerticalTabView }
