import { type Bundle, type Patient, type User, getResources, getResourcesByTypeAsIndex } from "fhir"
import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { messagesQueryKeys } from "../query-keys"
import { useUnreadMessages } from "./useUnreadMessages"
import type { PatientWithLinkedUser, PatientChat } from "../types"

const useOrganizationPatients = (filter = "") => {
  const { transaction } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const { senders, unreadList } = useUnreadMessages()

  const queryKey = messagesQueryKeys.orgPatients.list(currentOrganizationId, filter)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const searchFilter = filter ? `_ilike=${filter}&` : ""
      const commonSearchParams = `${searchFilter}active:not=false&_count=50&_elements=id,name,photo,active,telecom,address,identifier,generalPractitioner,managingOrganization&_sort=name&_page=${pageParam}`

      const bundleEntry = [
        {
          request: {
            method: "GET",
            url: `Organization/${currentOrganizationId}/Patient?${commonSearchParams}&_has:Communication:sender:category=message`,
          },
        },
        {
          request: {
            method: "GET",
            url: `Organization/${currentOrganizationId}/Patient?${commonSearchParams}&_has:Communication:recipient:category=message`,
          },
        },
        {
          request: {
            method: "GET",
            url: `Organization/${currentOrganizationId}/Patient?${commonSearchParams}&_revinclude=User:link:Patient`,
          },
        },
      ]

      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "batch",
        entry: bundleEntry,
      }

      const respBundle = (await transaction(reqBundle)) as Bundle

      const firstBundle = (respBundle as Bundle)?.entry?.[0]?.resource as Bundle
      const secondBundle = (respBundle as Bundle)?.entry?.[1]?.resource as Bundle
      const userBundle = (respBundle as Bundle)?.entry?.[2]?.resource as Bundle

      const patients = removeDuplicatePatients([
        ...(firstBundle ? getResources<Patient>(firstBundle, "Patient") : []),
        ...(secondBundle ? getResources<Patient>(secondBundle, "Patient") : []),
      ])

      const indexedUsers = getResourcesByTypeAsIndex<User>(userBundle, "User", ({ link }) => link?.[0]?.link?.id ?? "")

      const next =
        firstBundle?.link?.find(({ relation }) => relation === "next") ||
        secondBundle?.link?.find(({ relation }) => relation === "next")
          ? (pageParam as number) + 1
          : undefined

      const patientsWithLinkedUser = patients.map((patient) => ({ ...patient, linkedUser: indexedUsers[patient.id!] }))

      return { patients: patientsWithLinkedUser, next, total: respBundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter } },
  })

  const { patients, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.patients)
    const newPatientList = newData
      ?.filter((pat) => !senders?.some((sender) => sender.id === pat.id))
      .map((sender) => {
        return { sender, unreadCount: 0, unreadCommunications: [] } as PatientChat
      })

    const patients = unreadList?.concat(newPatientList as PatientChat[])

    const count = patients?.length ?? 0

    return {
      patients,
      count,
    }
  }, [data?.pages, senders, unreadList])

  return {
    patients,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

const removeDuplicatePatients = (patients: Patient[]) => {
  const uniquePatiens: Record<string, Patient> = {}

  patients.forEach((patient) => {
    uniquePatiens[patient.id ?? ""] = patient
  })

  return Object.values(uniquePatiens)
}

type PatientsQueryData = { patients: PatientWithLinkedUser[]; next: number | undefined; total: number }

export { useOrganizationPatients }
