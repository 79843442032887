import type { Condition } from "fhir"
import { useId } from "react"

import {
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { conditionClinicalStatusCodes } from "data"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useConditions, useCreateCondition, useUpdateCondition } from "../hooks"
import { ConditionForm } from "./ConditionForm"
import { conditionModelBuilder } from "./conditionModelBuilder"
import { conditionValidationSchema, getInitialValues, sanitize } from "./validations"

const ConditionsContainer = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, isNew, reset, add, edit } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { conditions, isLoading, hasNextPage, fetchNextPage } = useConditions({ patientId })
  const { createCondition } = useCreateCondition(reset)
  const { updateCondition } = useUpdateCondition(reset)

  const onSubmit = (condition: Condition) => {
    isNew ? createCondition(sanitize(condition)) : updateCondition(sanitize(condition))
  }

  const deactivate = (condition: Condition) => {
    const sanitizedCondition = sanitize({
      ...condition,
      verificationStatus: { text: "refuted", coding: [conditionClinicalStatusCodes[6]] },
    })
    updateCondition(sanitizedCondition)
  }
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No conditions found"
      hasData={!!conditions?.length}
      showSlide={showSlide}
      formTitle="Condition"
      formInitialValue={initialValue}
      validationSchema={conditionValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<ConditionForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appModules[ModulesId.CONDITIONS].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={conditions ?? []}
            itemModelBuilder={(item) =>
              conditionModelBuilder(
                item,
                () => edit(item),
                () => deactivate(item),
              )
            }
          />
        </InfiniteScroll>
      </div>
    </DataContainerSlideoverForm>
  )
}
export { ConditionsContainer }
