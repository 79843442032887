import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { QueryCache, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query"
import React, { type FC, Suspense, lazy, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { msalConfig } from "authConfig"
import { OffLineModal, useCheckNetworkContext } from "check-network"
import { type CustomError, LoadingView } from "commons"
import { ErrorContainer, NotFoundView, displayNotificationError } from "errors"
import { Home } from "home"
import { datadogLogs, registerErrorTrace } from "logger"
import { OrganizationContainer } from "organization"
import { AuthProvider, LoginProvider, Logout } from "security"
import { isAbortError } from "utils"

const App: FC = () => {
  const { isOnline, setIsOnline } = useCheckNetworkContext()

  useEffect(() => {
    window.addEventListener("unhandledrejection", eventListener)
    return () => {
      window.removeEventListener("unhandledrejection", eventListener)
    }
  }, [])

  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  const msalInstance = new PublicClientApplication(msalConfig)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: isOnline,
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        console.error(error, query)
        if ((error as CustomError).cause?.name !== "499") {
          const context = query?.meta?.context

          if ((error as CustomError).cause?.name !== "403") {
            displayNotificationError(
              registerErrorTrace(error as CustomError, { ...(context ? { data: context } : {}) }),
            )
          }
        } else {
          setIsOnline(false)
        }
      },
    }),
  })

  const showDevTool = process.env.NODE_ENV === "development" || window.location.search.includes("showDevTool")

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AuthProvider isOnline={isOnline} setIsOnline={setIsOnline}>
          <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <>
                  <ErrorBoundary
                    FallbackComponent={ErrorContainer}
                    onReset={reset}
                    onError={(error) => {
                      datadogLogs.logger.error(error.message, error)
                    }}
                  >
                    <Suspense fallback={<LoadingView />}>
                      <LoginProvider>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/logout" element={<Logout />} />
                          <Route path="/orgs/:orgId/*" element={<OrganizationContainer />} />
                          <Route path="*" element={<NotFoundView />} />
                        </Routes>
                      </LoginProvider>
                    </Suspense>
                  </ErrorBoundary>
                  {!isOnline && <OffLineModal reset={reset} />}
                </>
              )}
            </QueryErrorResetBoundary>
            {showDevTool ? (
              <React.Suspense fallback={null}>
                <ReactQueryDevtools />
              </React.Suspense>
            ) : null}
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </MsalProvider>
  )
}

const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools").then((d) => {
    return {
      default: () =>
        d.ReactQueryDevtools({
          buttonPosition: "bottom-left",
          position: "bottom",
          initialIsOpen: false,
        }),
    }
  }),
)

const eventListener = (event: PromiseRejectionEvent) => {
  if (isAbortError(event.reason)) {
    event.preventDefault()
    event.stopPropagation()
  }
}

export { App }
