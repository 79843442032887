import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import type { FC, PropsWithChildren } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import type { ProfileSection } from "../data"

const ProfileNav: FC<Props> = ({ sections }) => (
  <div className="min-w-1/4 flex flex-col items-center px-6 mx-4 mt-16 max-lg:hidden profile-nav">
    {sections.map(({ section, ...rest }) => (
      <NavButton {...rest} section={section} key={section} />
    ))}
  </div>
)

const NavButton: FC<NavProps> = ({ section, icon, label }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const activeLink = `nav_link_${searchParams.get("section")}`

  const isActive = activeLink === `nav_link_${section}`

  const onClick = () => {
    navigate(`?section=${section}`)
  }

  return (
    <div
      id={`nav_link_${section}`}
      className={classNames(
        "profile-link cursor-pointer m-1 w-full flex items-center p-2 pl-4 justify-start text-gray-500 hover:bg-gray-100 hover:text-primary",
        isActive && "bg-gray-100 text-primary border-l-2 border-primary",
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
      <span className="ml-3">{label}</span>
    </div>
  )
}

type Props = {
  sections: NavProps[]
}

export type NavProps = PropsWithChildren<{
  section: ProfileSection
  label: string
  icon: IconDefinition
}>

export { ProfileNav }
