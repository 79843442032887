import type { SizeProp } from "@fortawesome/fontawesome-svg-core"
import {
  faDownLeftAndUpRightToCenter,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneArrowDown,
  faScreencast,
  faVideo,
  faVideoSlash,
  faWarning,
  faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog } from "primereact/dialog"
import { Tooltip } from "primereact/tooltip"
import { useContext, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { toast } from "react-toastify"

import { Button, ModulesId } from "commons"
import { humanNameAsString } from "fhir"
import { usePatientContext } from "patients"
import { useLoginContext } from "security"

import { VideoCallContext } from "../context"
import { IdToAppendVideo, PatientStatus, ResponseStartVCNames, SettingsBtnsNames, SubscriberConnection } from "../data"
import { useParamsModule, useStartVideoCall, useVonageAPIIntegration } from "../hooks"
import type { TSettings, TSubscriberConnection } from "../types"
import { handleF5KeyFunc } from "../utils"
import { AlertBeforeUnload } from "./AlertBeforeUnload"
import { type TIconSettingBtn, type TSettingsBtnMode, SettingsBtns } from "./SettingsBtns"

const StartVideoCall = () => {
  const { patientRef, hasB2CUser, patient } = usePatientContext()
  const { loggedInPractitioner } = useLoginContext()
  const { connection, warningBeforeUnload, isInterviewStarted, setIsInterviewStarted } = useContext(VideoCallContext)
  const {
    initializeSession,
    stopStreaming,
    toggleAudio,
    toggleVideo,
    handlePictureInPicture,
    subscriberConnection,
    isPictureInPictureActive,
    setIsPictureInPictureActive,
    videoStates,
    subscriberSettings,
    shareScreen,
    endShareScreen,
  } = useVonageAPIIntegration()
  const { destroyedSession, setWarningBeforeUnload, setRejectCall, setDestroyedSession, patientStatus } =
    useContext(VideoCallContext)

  const { showModule } = useParamsModule()

  const handleInvite = () => showModule(ModulesId.PATIENT, { subview: "status" })

  useEffect(() => endShareScreen(!!destroyedSession), [destroyedSession, videoStates])

  const { startVideoCall, startIsPending, cancelVideoCall, finishVideoCall, cancelIsPending, finishIsPending } =
    useStartVideoCall({
      start(keysDicc) {
        if (keysDicc[ResponseStartVCNames.isBusy]?.value?.boolean)
          return toast.error(`${humanNameAsString(patient.name?.[0])} is busy in other video call`)

        initializeSession({
          apiKey: keysDicc[ResponseStartVCNames.applicationId]?.value?.string ?? "",
          sessionId: keysDicc[ResponseStartVCNames.sessionId]?.value?.string ?? "",
          token: keysDicc[ResponseStartVCNames.accessToken]?.value?.string ?? "",
        })

        setIsInterviewStarted(true)
      },
      cancel: () => warningBeforeUnload && location.reload(),
      finish() {
        if (warningBeforeUnload) location.reload()
        setWarningBeforeUnload(false)
      },
    })

  const handleStartVC = () => {
    loggedInPractitioner?.id &&
      patientRef?.id &&
      startVideoCall({ patientId: patientRef.id, practitionerId: loggedInPractitioner.id })
  }

  const handleCancelVC = () =>
    patientRef?.id &&
    loggedInPractitioner?.id &&
    cancelVideoCall({ patientId: patientRef.id, practitionerId: loggedInPractitioner.id })

  const handleEndVC = ({ destroyed, visible = false, reject }: TEndVC) => {
    if (isPictureInPictureActive) {
      document.exitPictureInPicture()
      setIsPictureInPictureActive(false)
    }

    !visible && setIsInterviewStarted(false)

    destroyed && setDestroyedSession(undefined)

    if (reject) {
      toast.error(`Call declined by ${humanNameAsString(patient.name?.[0])}`)
      setRejectCall(undefined)
    }

    videoStates?.subscriber &&
      !destroyed &&
      !reject &&
      loggedInPractitioner?.id &&
      finishVideoCall({ practitionerId: loggedInPractitioner.id })

    !videoStates?.subscriber && handleCancelVC()

    stopStreaming()
  }

  const tooltip = () => {
    if (!hasB2CUser)
      return {
        element: (
          <>
            <h3 className="text-sm">This patient hasn't been invited yet</h3>|
            <p onClick={handleInvite} className="text-blue-300 hover:underline cursor-pointer">
              Invite
            </p>
          </>
        ),
        disabled: !hasB2CUser,
      }

    if (patientStatus === PatientStatus.disconnected)
      return {
        element: <>This patient is {PatientStatus.disconnected}</>,
        disabled: false,
      }

    return { disabled: true }
  }

  return (
    <>
      <StartCallBtn
        tooltip={tooltip().element}
        startCall={handleStartVC}
        disabled={tooltip().disabled}
        loading={startIsPending || !connection}
      />
      <MeetingCall
        shareScreen={{
          onClick: shareScreen,
          onIt: !!videoStates?.shareScreen,
          subcriberOnIt: subscriberSettings?.shareScreen,
        }}
        beforeUnloadIsLoading={cancelIsPending || finishIsPending}
        hasSubscriber={!!videoStates?.subscriber}
        subscriberHasAudio={subscriberSettings.audio}
        onHide={handleEndVC}
        visible={isInterviewStarted && !isPictureInPictureActive}
        showSettings={!!videoStates?.publisher}
        subscriberConnection={subscriberConnection}
        vonageSettings={{ audio: toggleAudio, video: toggleVideo, pip: handlePictureInPicture }}
      />
    </>
  )
}

const StartCallBtn = ({ startCall, disabled, loading, tooltip }: TStartCallBtn) => (
  <>
    <Tooltip showOnDisabled disabled={disabled} autoHide={false} position="bottom" target=".start-btn-vCall">
      <span className="flex gap-2 cursor-default">{tooltip}</span>
    </Tooltip>
    <Button
      disabled={!disabled}
      label="Start video call"
      className="start-btn-vCall button-banner button-primary"
      onClick={startCall}
      loading={loading}
      font="font-normal"
    />
  </>
)

const shareScreenRoot = createRoot(document.getElementById("settingsShareScreenBtn")!)

const MeetingCall = ({
  onHide,
  visible,
  vonageSettings,
  showSettings = true,
  subscriberHasAudio,
  hasSubscriber,
  beforeUnloadIsLoading,
  subscriberConnection,
  shareScreen,
}: TMeetingCall) => {
  const initialSettings = { audio: false, video: false }
  const [settings, setSettings] = useState<TSettings>(initialSettings)
  const { audio, video, pip } = vonageSettings
  const { patient } = usePatientContext()
  const {
    destroyedSession,
    browserNotSupportedPIP,
    browserNotSupportedShareScreen,
    setWarningBeforeUnload,
    warningBeforeUnload,
    rejectCall,
  } = useContext(VideoCallContext)

  useEffect(() => {
    rejectCall && handleHide({ reject: !!rejectCall })
  }, [rejectCall])

  useEffect(() => {
    visible && destroyedSession && handleHide({ destroyed: !!destroyedSession })
  }, [destroyedSession])

  useEffect(() => {
    visible && handleF5KeyFunc({ listen: true, action: () => setWarningBeforeUnload(true) })
  }, [visible])

  const handleVideo = () => {
    video(settings.video)
    setSettings((prev) => ({ ...prev, video: !prev.video }))
  }

  const handlePip = () => pip()

  const handleAudio = () => {
    audio(settings.audio)
    setSettings((prev) => ({ ...prev, audio: !prev.audio }))
  }

  const handleHide = ({ destroyed, visible = false, reject }: TEndVC = {}) => {
    onHide({ destroyed, reject, visible })
    setSettings(initialSettings)
  }

  const proceedAlertBeforeUnload = () => {
    handleF5KeyFunc({ listen: false, action: () => setWarningBeforeUnload(true) })
    handleHide({ destroyed: !!destroyedSession, visible: true })
  }

  const subscriber = `${shareScreen?.subcriberOnIt && "hidden md:block"} absolute size-24 md:size-32 border-2 border-t-0 border-gray-600 right-0 top-0 z-10 overflow-hidden`

  const publisher = "grow w-full border-x-2 border-gray-600 h-max overflow-hidden flex self-start relative"

  const handleSettingsMode = (condition: boolean, mode: TSettingsBtnMode = "danger") => (condition ? mode : undefined)

  const browserNotAvaliable = "is not avaliable in current browser"

  const settingBtn: TIconSettingBtn[] = [
    {
      name: SettingsBtnsNames.endCall,
      mode: "danger",
      onClick: () => handleHide(),
      fontIcon: faPhoneArrowDown,
    },
    {
      name: SettingsBtnsNames.audio,
      mode: handleSettingsMode(settings.audio),
      onClick: handleAudio,
      fontIcon: !settings.audio ? faMicrophone : faMicrophoneSlash,
    },
    {
      name: SettingsBtnsNames.video,
      mode: handleSettingsMode(settings.video),
      onClick: handleVideo,
      fontIcon: !settings.video ? faVideo : faVideoSlash,
      disabled: shareScreen?.subcriberOnIt,
    },
    {
      name: browserNotSupportedShareScreen
        ? [SettingsBtnsNames.shareScreen, browserNotAvaliable].join(" ")
        : SettingsBtnsNames.shareScreen,
      onClick: shareScreen?.onClick,
      disabled: browserNotSupportedShareScreen || shareScreen?.subcriberOnIt || shareScreen?.onIt,
      fontIcon: browserNotSupportedShareScreen ? faWarning : faScreencast,
    },
    {
      name: browserNotSupportedPIP ? [SettingsBtnsNames.pip, browserNotAvaliable].join(" ") : SettingsBtnsNames.pip,
      onClick: handlePip,
      disabled: browserNotSupportedPIP || shareScreen?.subcriberOnIt || shareScreen?.onIt,
      fontIcon: browserNotSupportedPIP ? faWarning : faDownLeftAndUpRightToCenter,
    },
  ]

  useEffect(() => {
    shareScreenRoot.render(
      <SettingsBtns
        hide={!shareScreen?.onIt}
        settings={settingBtn}
        className={`w-fit fixed bottom-3 right-3 bg-gray-600 flex items-center justify-center p-3 gap-3 z-50 rounded-md`}
      />,
    )
  }, [shareScreen?.onIt, settings])

  return (
    <>
      <AlertBeforeUnload
        visible={warningBeforeUnload}
        proceed={proceedAlertBeforeUnload}
        proceedLoading={beforeUnloadIsLoading}
        cancel={() => setWarningBeforeUnload(false)}
      />
      <Dialog
        modal={!shareScreen?.onIt}
        onHide={() => ({})}
        closable={false}
        visible={visible}
        draggable={true}
        resizable={false}
        className={`${shareScreen?.onIt && "hidden"} rounded-t-lg size-5/6 top xl:max-w-[65%] 2xl:max-w-[50%]`}
        contentStyle={{ padding: "0px" }}
        contentClassName={`size-full flex bg-gray-700 flex-col justify-end items-center relative`}
        headerClassName="bg-gray-600 !p-3"
        header={<ModalHeader />}
      >
        <div id={IdToAppendVideo.shareScreen} className={`${!shareScreen?.subcriberOnIt ? "hidden" : publisher}`}>
          {hasSubscriber && !subscriberHasAudio && (
            <MicrophoneOff size="1x" className={`${shareScreen?.subcriberOnIt && "md:hidden"}`} />
          )}
        </div>
        <div
          id={IdToAppendVideo.subscriber}
          className={`${shareScreen?.subcriberOnIt ? subscriber : hasSubscriber ? `${publisher} duration-300` : "size-0 opacity-0"}`}
        >
          {hasSubscriber && !subscriberHasAudio && (
            <MicrophoneOff size={`${shareScreen?.subcriberOnIt ? "xs" : "1x"}`} />
          )}
        </div>
        <div
          id={IdToAppendVideo.publisher}
          className={`${shareScreen?.subcriberOnIt ? "hidden" : hasSubscriber ? subscriber : publisher} duration-300`}
        >
          {hasSubscriber && settings.audio && <MicrophoneOff size={`${hasSubscriber ? "xs" : "1x"}`} />}
        </div>
        <SubscriberDisconnected
          visible={subscriberConnection === SubscriberConnection.disconnected}
          headerName={humanNameAsString(patient.name?.[0])}
          onHide={() => handleHide({ destroyed: true })}
        />
        <SettingsBtns
          hide={!showSettings}
          settings={settingBtn}
          className={`w-full bg-gray-600 flex items-center justify-center p-3 gap-3 z-40`}
        />
      </Dialog>
    </>
  )
}

const ModalHeader = () => (
  <div className="text-white relative">
    <h2>TeleMedicine Call</h2>
  </div>
)

const MicrophoneOff = ({ size, className }: { size: SizeProp; className?: string }) => (
  <FontAwesomeIcon
    size={size}
    className={`absolute flex inset-0 z-10 ml-2 mt-3 font-semibold ${className}`}
    color="#4b5563"
    icon={faMicrophoneSlash}
  />
)

const SubscriberDisconnected = ({ visible, onHide, headerName }: TSubscriberDisconnected) => (
  <Dialog
    modal
    onHide={onHide}
    closable
    visible={visible}
    draggable={false}
    resizable={false}
    className="rounded-t-lg top xl:max-w-[65%] 2xl:max-w-[50%]"
    contentClassName="rounded-b-lg flex flex-col items-center px-8  w-8/9 h-8/9"
  >
    <FontAwesomeIcon icon={faWifiSlash} className="w-12 h-12" />
    <h1 className="text-2xl py-2 font-bold">{headerName} is disconnected</h1>
    <h3 className="text-sm text-gray-400 pt-2 pb-4">Please close and call again.</h3>
  </Dialog>
)

type TEndVC = { destroyed?: boolean; visible?: boolean; reject?: boolean }

type TStartCallBtn = {
  startCall: () => void
  disabled?: boolean
  loading: boolean
  tooltip?: JSX.Element
}

type TVonageSettings = {
  video: (state: boolean) => void | undefined
  audio: (state: boolean) => void | undefined
  pip: () => void
}

type TSubscriberDisconnected = {
  visible: boolean
  onHide: () => void
  headerName: string
}

type TMeetingCall = {
  onHide: ({ destroyed, visible, reject }: TEndVC) => void
  visible: boolean
  showSettings?: boolean
  vonageSettings: TVonageSettings
  hasSubscriber?: boolean
  subscriberHasAudio?: boolean
  beforeUnloadIsLoading?: boolean
  subscriberConnection?: TSubscriberConnection
  shareScreen?: { onClick: () => void; onIt: boolean; subcriberOnIt?: boolean }
}

export { StartVideoCall }
