import { faCalendarCheck, faStar, faUser } from "@fortawesome/pro-solid-svg-icons"
import type { AccountBETACreditCardArray } from "fhir"

import type { StackedListItemProps } from "commons/components/StackedListItem"
import { formatCreditCardNumber } from "utils"

import { defaultEditRemoveMenu } from "utils-components"
import { CreditCardImage } from "./components/CreditCardImage"

const creditCardModelBuilder = (
  creditCard: AccountBETACreditCardArray,
  isPreferred: boolean,
  isUpdating: boolean,
  isExpired: boolean,
  makePreferred?: () => void,
  edit?: () => void,
  remove?: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Number", value: formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type) }],
    },
    {
      lineItems: [
        { name: "Holder name", value: creditCard.cardHolderName ?? "Unspecified", icon: faUser },
        {
          name: "Expiration date",
          value: `${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`,
          icon: faCalendarCheck,
        },
        ...(isPreferred ? [{ name: "Preferred credit card", value: "Preferred", icon: faStar }] : []),
      ],
    },
  ],
  isLoading: isUpdating,
  onClick: isPreferred ? undefined : makePreferred,
  menu: defaultEditRemoveMenu(edit, remove),
  image: <CreditCardImage creditCard={creditCard} />,
  badge: isExpired ? { text: "expired", colorStyle: "red" } : undefined,
})

const isExpired = (creditCard: AccountBETACreditCardArray) =>
  new Date(parseInt(creditCard?.expirationYear as string), parseInt(creditCard?.expirationMonth as string), 1) <
  new Date()

export { creditCardModelBuilder, isExpired }
