import { useQuery } from "@tanstack/react-query"
import type { Parameters, ParametersParameterArrayArray } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationAvailability = (mkSku: string, medQuantity: number) => {
  const { operationRequest } = useClient()
  const { location } = useOrganizationContext()
  const queryKey = medsQueryKeys.medAvailability(mkSku, medQuantity)

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "medication",
            value: {
              string: mkSku,
            },
          },
        ],
      }

      const parameter: ParametersParameterArrayArray = await operationRequest({
        endpoint: "Location",
        method: "POST",
        operation: "get-inventory-item-availability",
        id: location?.id,
        parameters,
      })

      const medAvailability = parameter?.name === "availability" ? parameter.value?.decimal ?? 0 : 0

      return {
        medQuantityAvailable: medAvailability >= (medQuantity ?? 1),
        medAvailability,
      }
    },
    meta: { context: { queryKey, mkSku } },
    enabled: !["", "no-code"].includes(mkSku),
  })

  return {
    isLoadingAvailability: isLoading,
    isFetching,
    medAvailability: data?.medAvailability ?? 0,
    medQuantityAvailable: data?.medQuantityAvailable,
    refetchMedAvailability: refetch,
  }
}

export { useMedicationAvailability }
