import { useMutation } from "@tanstack/react-query"
import type { Parameters, ParametersParameterArrayArray } from "fhir"

import { useClient } from "api"

const useQRUrl = (onSuccess: (data?: QRUrlResponse) => void) => {
  const { read } = useClient()

  const { mutate: getQRUrl, isPending } = useMutation({
    mutationFn: async (qrId: string | undefined) => {
      const parameters = await read<Parameters>({
        endpoint: "QuestionnaireResponse",
        id: qrId,
        operation: "fillerlink",
      })

      if (!parameters) {
        throw new Error("Not found", { cause: { name: "404", message: "Not questionnaire URL found" } })
      }

      return parameters.parameter?.reduce((acc, parameter) => ({ ...acc, [parameter.id ?? ""]: parameter }), {
        link: undefined,
        relativeLink: undefined,
      } as QRUrlResponse)
    },
    onSuccess,
  })

  return { getQRUrl, isLoading: isPending }
}

type QRUrlResponse = {
  link: ParametersParameterArrayArray | undefined
  relativeLink: ParametersParameterArrayArray | undefined
}

export { useQRUrl }
