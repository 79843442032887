import type { Location } from "fhir"

import { FormContainer } from "commons"
import { useOrganizationContext } from "organization"

import { useUpdateLocation } from "../../hooks"
import { RegularHours } from "./RegularHours"
import { SpecialHours } from "./SpecialHours"
import type { OperationHours } from "./types"
import { getInitialValues, sanitize } from "./validations"

const OperatingHours = () => {
  const { location } = useOrganizationContext()

  const { updateLocation } = useUpdateLocation()

  const onSubmit = (data: OperationHours) => updateLocation(sanitize(location as Location, data))

  return (
    <FormContainer
      initialValue={getInitialValues(location as Location)}
      enableReinitialize
      onSubmit={onSubmit}
      saveLabel="Update"
      showCancel={false}
    >
      <LocationForm />
    </FormContainer>
  )
}

const LocationForm = () => (
  <div className="flex flex-col divide-y space-y-4">
    <div className="flex">
      <div className="relative w-1/5 xl:w-1/4">
        <span className="font-medium text-gray-900 sticky top-0">Regular Hours</span>
      </div>
      <RegularHours />
    </div>
    <div className="flex pt-4">
      <div className="relative w-1/5 xl:w-1/4">
        <span className="font-medium text-gray-900 sticky top-0">Special Hours</span>
      </div>
      <SpecialHours />
    </div>
  </div>
)

export { OperatingHours }
