import type { CodeableConcept, FamilyMemberHistory, FamilyMemberHistoryConditionArray, Reference } from "fhir"
import * as Yup from "yup"

const getFamilyHistoryConditionsInitialValues = (): FamilyMemberHistoryConditionArray => ({
  code: { coding: undefined },
  onset: { Age: { code: undefined, value: undefined } },
  note: [{ text: "" }],
})

const sanitizeCondition = ({ ...familyHistoryCondition }: FamilyMemberHistoryConditionArray) => {
  if (!familyHistoryCondition.note?.[0]?.text) {
    delete familyHistoryCondition.note
  }

  return familyHistoryCondition
}

const familyHistoryConditionValidationSchema = Yup.object({
  onset: Yup.object()
    .shape({
      Age: Yup.object().shape({
        value: Yup.number().nullable().min(1, "Age should be greater than 0"),
      }),
    })
    .nullable(),
})
  .nullable()
  .optional()

const getInitialValues = (patientRef: Reference, encounter?: Reference): FamilyMemberHistory => ({
  patient: patientRef,
  name: "",
  relationship: { coding: undefined },
  status: "completed",
  sex: { coding: [{ code: "unknown" }] },
  deceased: { boolean: false },
  condition: [],
  age: { Age: { value: undefined } },
  date: new Date().toISOString(),
  note: [{ text: "" }],
  encounter,
})

const familyHistoryValidationSchema = Yup.object().shape({
  patient: Yup.object().required("Patient is required"),
  name: Yup.string().required("Name is required"),
  age: Yup.object().shape({
    Age: Yup.object().shape({
      value: Yup.number().nullable().min(1, "Age should be greater than 0"),
    }),
  }),
  relationship: Yup.object().test(
    "test-first-item",
    "Relationship is required",
    (value: CodeableConcept | undefined) => value?.coding !== undefined,
  ),
  condition: Yup.array(familyHistoryConditionValidationSchema),
})

const sanitize = ({ ...familyHistory }: FamilyMemberHistory) => {
  if (!familyHistory.note?.[0]?.text) {
    delete familyHistory.note
  }
  if (!familyHistory.sex) familyHistory.sex = { coding: [{ code: "unknown", display: "Unknown" }] }
  if (!familyHistory.encounter) delete familyHistory.encounter

  return familyHistory
}

export {
  familyHistoryConditionValidationSchema,
  familyHistoryValidationSchema,
  getFamilyHistoryConditionsInitialValues,
  getInitialValues,
  sanitize,
  sanitizeCondition,
}
