import { useQuery } from "@tanstack/react-query"
import {
  type Bundle,
  type CarePlan,
  type Coding,
  type DiagnosticReport,
  type List,
  type MedicationKnowledge,
  type Observation,
  type ServiceRequest,
  getResource,
  getResources,
  isMedicationRequest,
  isQuestionnaireResponse,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import type { Supplement } from "commons/procedures"
import { getMedCodes } from "commons/utils"
import { getBasePrice, getCidIdentifier, getCommonCode } from "utils"

import { mcQueryKeys } from "../query-keys"
import { type OrganData, type TriadData, mcObsCategories } from "../types"

const usePatientPlan = (organizationId: string, patientId: string, planId: string) => {
  const { search, transaction } = useClient()
  const queryKey = mcQueryKeys.details(patientId, planId)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId,
        _include:
          "CarePlan:supporting-info:DiagnosticReport,CarePlan:supporting-info:ServiceRequest, CarePlan:supporting-info:List, DiagnosticReport:result, Observation:derived-from, ServiceRequest:supporting-info:DiagnosticReport",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const carePlan = getResource<CarePlan>(bundle, "CarePlan")
      const serviceRequest = getResource<ServiceRequest>(bundle, "ServiceRequest")
      const observations = getResources<Observation>(bundle, "Observation")
      const diagnosticReports = getResources(bundle, "DiagnosticReport")
      const notes = getResources<List>(bundle, "List")

      const medicationRequests = carePlan.contained?.filter(isMedicationRequest) ?? []

      const medCodes = medicationRequests.reduce<Coding[]>((acc, mr) => {
        const innerMK = mr.contained?.[0] as MedicationKnowledge
        return [
          ...acc,
          ...(innerMK ? (innerMK.code?.coding as Coding[]) : (mr.medication?.CodeableConcept?.coding as Coding[])),
        ]
      }, [])
      const medTokens = medCodes?.map((coding) => `${coding.system}|${coding.code}`)?.join(",") ?? ""
      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "batch",
        entry: [
          {
            request: {
              method: "GET",
              url: `MedicationKnowledge?code=${medTokens}`,
            },
          },
        ],
      }
      const resBundle = (await transaction(reqBundle)) as Bundle
      const medicationKnowledges = getResources<MedicationKnowledge>(
        resBundle?.entry?.[0]?.resource as Bundle,
        "MedicationKnowledge",
      )

      const mkCodes = getMedCodes({ meds: medicationKnowledges, withQty: true })

      const { billToPracticeOrInsuranceCIDs: chargeItemDefinitions } = await getChargeItemDefinitions(organizationId, {
        billToPracticeOrInsuranceCIDs: mkCodes,
      })
      const labDr = diagnosticReports.find((dr) =>
        (dr as DiagnosticReport)?.category?.find((c) => c?.coding?.[0]?.code === "LAB"),
      )

      return {
        carePlan,
        serviceRequest,
        labDr,
        observations,
        medicationRequests,
        medicationKnowledges,
        chargeItemDefinitions,
        notes,
      }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { triadsData, vitality, vitalityIndex, supplements, questionnaireResponseId } = useMemo(() => {
    const dataByCat =
      data?.observations.reduce<Record<string, Record<string, Observation>>>(
        (acc, o) => ({
          ...acc,
          [o.category?.[0]?.coding?.[0]?.code as string]: {
            ...acc[o.category?.[0]?.coding?.[0]?.code as string],
            [o.id as string]: o,
          },
        }),
        {},
      ) ?? {}

    const triadsData = Object.values(dataByCat[mcObsCategories.triad_risk] ?? {}).reduce<Array<TriadData>>(
      (trData, tRisk) => {
        const organData =
          tRisk.derivedFrom?.reduce<Array<OrganData>>((oData, ref) => {
            const organ = dataByCat[mcObsCategories.organ_risk][ref.id as string]
            const labData =
              organ.hasMember?.reduce<Array<Observation>>(
                (oLab, labRef) => [
                  ...oLab,
                  dataByCat[mcObsCategories.laboratory]?.[labRef.id as string] ??
                    dataByCat[mcObsCategories.vital_signs]?.[labRef.id as string],
                ],
                [],
              ) ?? []
            const newOrgan: OrganData = {
              id: organ.code.text as string,
              organ,
              labData,
            }

            return [...oData, newOrgan]
          }, []) ?? []

        const newTriad: TriadData = {
          id: tRisk.identifier?.[0]?.value as string,
          triad: tRisk,
          organs: organData,
        }
        return [...trData, newTriad]
      },
      [],
    )
    triadsData.sort(
      (a, b) =>
        (b.triad.value?.Quantity?.value &&
          a.triad.value?.Quantity?.value &&
          b.triad.value.Quantity.value - a.triad.value.Quantity.value) ??
        NaN,
    )

    const vitality = dataByCat[mcObsCategories.vitality_risk]
      ? Object.values(dataByCat[mcObsCategories.vitality_risk])
      : []
    const vitalityIndex = dataByCat[mcObsCategories.wellness_indicator]
      ? Object.values(dataByCat[mcObsCategories.wellness_indicator])?.[0]
      : undefined

    const supplements =
      data?.medicationRequests.reduce<Supplement[]>((acc, mr) => {
        const innerMK = mr.contained?.[0] as MedicationKnowledge
        const mk = data?.medicationKnowledges.find(
          (mk) => getCommonCode({ codes: mk.code?.coding }) === getCommonCode({ codes: innerMK.code?.coding }),
        )
        const supTriads =
          innerMK?.medicineClassification?.[0]?.classification?.reduce<string[]>(
            (acc, cc) => (cc.coding?.[0]?.code ? [...acc, cc.coding[0].code] : acc),
            [],
          ) ?? []

        return [
          ...acc,
          {
            triads: supTriads,
            mr,
            mk: mk ?? innerMK,
            price: getBasePrice(
              data?.chargeItemDefinitions?.[
                getCidIdentifier(getCommonCode({ codes: innerMK.code?.coding }), mr?.dispenseRequest?.quantity?.value)
              ]?.propertyGroup?.[0].priceComponent,
            )?.amount,
          },
        ]
      }, []) ?? []

    const questionnaireResponseId = data?.serviceRequest?.supportingInfo?.find(isQuestionnaireResponse)?.id

    return { triadsData, vitality, vitalityIndex, supplements, questionnaireResponseId }
  }, [
    data?.chargeItemDefinitions,
    data?.medicationKnowledges,
    data?.medicationRequests,
    data?.observations,
    data?.serviceRequest,
  ])

  const isOnlySurvey = useMemo(
    () => data?.carePlan?.category?.some((category) => category?.coding?.[0].code?.includes("mc-survey")) ?? false,
    [data?.carePlan?.category],
  )

  const hasAttachedPDFLabresult =
    (data?.labDr as DiagnosticReport)?.presentedForm?.[0]?.contentType === "application/pdf"
  const drUrl = (data?.labDr as DiagnosticReport)?.presentedForm?.[0]?.url

  return {
    carePlan: data?.carePlan,
    triadsData,
    vitality,
    vitalityIndex,
    supplements,
    notes: data?.notes,
    questionnaireResponseId,
    labsResultPDFInfo: { hasAttachedPDF: hasAttachedPDFLabresult, url: drUrl },
    isLoading,
    isOnlySurvey,
  }
}

export { usePatientPlan }
