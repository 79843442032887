import { useQuery } from "@tanstack/react-query"
import { type CarePlan, type Condition, getResource, getResources } from "fhir"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useCPManualCondition = (patientId: string, planId: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.manualConditions(patientId, planId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _include: "outcome-reference:Condition",
        _elements: "id",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const carePlan = getResource<CarePlan>(bundle, "CarePlan")
      const conditions = getResources<Condition>(bundle, "Condition")

      return {
        carePlan,
        conditions,
      }
    },
    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  return {
    isLoading,
    carePlan: data?.carePlan,
    conditions: data?.conditions,
  }
}

export { useCPManualCondition }
