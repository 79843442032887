import { useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"

import { InvoiceListContainer } from "./InvoiceListContainer"
import { InvoiceDetails } from "./InvoiceDetails"

import "@chartedhealth/payment-components/dist/index.css"

const InvoiceContainer = () => {
  const [searchParams] = useSearchParams()
  const { appSubModules } = useAppModuleContext()
  const { setActiveSubModule, activeSubModule } = useChartContext()

  useEffect(() => {
    let subModule = null

    if (searchParams.get("invoiceId")) {
      subModule = appSubModules["invoice"][ModulesId.INVOICE_DETAILS]
    } else if (searchParams.get("paymentId")) {
      subModule = appSubModules["invoice"][ModulesId.INVOICE_PAYMENT_DETAILS]
    }

    setActiveSubModule(subModule)
  }, [appSubModules, setActiveSubModule, searchParams])

  const renderView = useCallback(() => {
    if (activeSubModule) return <InvoiceDetails activeSubModule={activeSubModule} />

    return <InvoiceListContainer />
  }, [activeSubModule])

  return renderView()
}

export { InvoiceContainer }
