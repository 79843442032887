import { useContext } from "react"

import { ChartContext } from "../context"
import type { ChartContextProps } from "../types"

const useChartContext = <T>() => {
  const context = useContext(ChartContext)

  if (!context) {
    throw new Error("useChartContext must be used withing ChartProvider")
  }

  return context as ChartContextProps<T>
}

export { useChartContext }
