import { useQuery } from "@tanstack/react-query"
import {
  type ActivityDefinition,
  type Bundle,
  type ChargeItemDefinition,
  type Coding,
  type Coverage,
  type Medication,
  type MedicationAdministration,
  type MedicationKnowledge,
  type MedicationRequest,
  type Money,
  type PlanDefinition,
  type Procedure,
  type RequestGroup,
  type RequestGroupActionArrayActionArray,
  type ServiceRequest,
  codeableConceptAsString,
  getResources,
  isCarePlan,
  isChargeItemDefinition,
  isCoverage,
} from "fhir"
import pluralize from "pluralize"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { getSRCodes } from "commons/labs"
import { MEDICATIONS_REGULATIONS_CODE, useMedPricesQueryFunction } from "commons/meds"
import { getProcedureKind, ProcedureKind } from "commons/procedures"
import { getMedCodes } from "commons/utils"
import { BILLING_TYPES_CODES, MEDICATION_PRODUCT_TYPE, ServiceRequestCategory } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  convertIdentifiersToCodings,
  getBasePrice,
  getCidIdentifier,
  getCommonCode,
  getServiceRequestBillingType,
  isMrMedication,
  isMrProcedure,
  sumPrice,
} from "utils"

import { ordersQueryKeys } from "../query-keys"
import {
  type ActionGroupCode,
  type CpoeLaboratoryPanel,
  type CpoeRequest,
  type MedData,
  ACTION_GROUP_CODES,
  RG_BILLING_ACTION_CODES,
} from "../types"
import { checkIsActionType } from "../utils"

const useCpoeOrders = (organizationId: string, patientId: string, enabled?: boolean) => {
  const { operationRequest, transaction } = useClient()
  const queryKey = ordersQueryKeys.list(patientId)

  const getChargeItemDefinitions = useCIDQueryFunction()
  const getChargeItemDefinitionsWithMedFee = useMedPricesQueryFunction()

  const { data, isLoading, isFetching, isError, isRefetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const rg = await operationRequest<RequestGroup>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "dwim",
      })

      if (!rg || !rg.action?.[0].action) {
        return {
          medicationRequestActions: new Array<RequestGroupActionArrayActionArray>(),
          medicationRequests: { nutraMrs: [], rxMrs: [] },
          medicationKnowledges: [],
          chargeItemDefinitions: {} as {
            billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
            billToPatientCIDs: Record<string, ChargeItemDefinition>
          },
          serviceRequestActions: [],
          serviceRequests: {} as Record<string, ServiceRequest>,
          activityDefinition: undefined,
          requestGroup: undefined,
          labPlanDefinitions: {} as Record<string, Coding[]>,
          nutraceuticalActions: [],
          procedureActions: [],
          procedureMRs: [],
          procedureMAs: [],
          procedures: [],
        } as {
          medicationRequestActions: RequestGroupActionArrayActionArray[]
          nutraceuticalActions: RequestGroupActionArrayActionArray[]
          procedureActions: RequestGroupActionArrayActionArray[]
          medicationRequests: { nutraMrs: MedicationRequest[]; rxMrs: MedicationRequest[] }
          medicationKnowledges: MedicationKnowledge[]
          chargeItemDefinitions: {
            billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
            billToPatientCIDs: Record<string, ChargeItemDefinition>
          }
          serviceRequestActions: RequestGroupActionArrayActionArray[]
          serviceRequests: Record<string, ServiceRequest>
          activityDefinition: ActivityDefinition | undefined
          requestGroup: RequestGroup | undefined
          labPlanDefinitions: Record<string, Coding[]>
          procedureMRs: MedicationRequest[]
          procedureMAs: MedicationAdministration[]
          procedures: Procedure[]
        }
      }

      const medicationRequestActions = rg.action[0].action.filter(
        ({ resource, code }) =>
          resource?.resourceType === "MedicationRequest" &&
          checkIsActionType({ codeableConcept: code, type: ACTION_GROUP_CODES.PHARMA }),
      )

      // Fallback Rx to nutra temporally
      // Optim code is  checkIsActionType({ codeableConcept: code, type: "nutraceutical" }) && resource?.resourceType === "MedicationRequest"
      // When all RGs have the new structure use optim code ^
      const nutraceuticalActions = rg.action[0].action.filter(
        ({ resource, code }) =>
          checkIsActionType({ codeableConcept: code, type: ACTION_GROUP_CODES.NUTRA }) ||
          (!medicationRequestActions.length && resource?.resourceType === "MedicationRequest"),
      )

      const serviceRequestActions = rg.action[0].action.filter(
        ({ resource }) => resource?.resourceType === "ServiceRequest",
      )

      const procedureActions = rg.action[0].action.filter(({ resource }) => resource?.resourceType === "Procedure")

      const medActionIds = [...medicationRequestActions, ...nutraceuticalActions].map((req) => req.resource?.id)
      const activityDefinitionReference = rg.action[1].resource

      const serviceRequestsIds = serviceRequestActions.map((sr) => sr.resource?.id)
      const procedureRequestsIds = procedureActions.map((p) => p.resource?.id ?? "")

      const filters = new URLSearchParams({
        _query: "cpoe-medication-data",
        _patient: patientId,
        _activityDefinition: activityDefinitionReference?.id as string,
        _medicationRequests: medActionIds.join(","),
      })

      const srFilters = new URLSearchParams({
        _query: "cpoe-lab-data",
        _patient: patientId,
        _serviceRequests: serviceRequestsIds.join(","),
      })

      const procedureFilters = new URLSearchParams({
        _id: procedureRequestsIds.join(","),
        _revinclude: "MedicationAdministration:part-of",
        _include: "MedicationAdministration:request",
      })

      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: [
          {
            request: {
              method: "GET",
              url: `/MedicationRequest?${filters}`,
            },
          },
          {
            request: {
              method: "GET",
              url: `/ServiceRequest?${srFilters}`,
            },
          },
          {
            request: {
              method: "GET",
              url: `Patient/${patientId}/Procedure?${procedureFilters}`,
            },
          },
        ],
      }

      const respBundle = await transaction(reqBundle)

      const medBundle = (respBundle as Bundle).entry?.[0]?.resource as Bundle

      const medicationRequests = getResources<MedicationRequest>(medBundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(medBundle, "MedicationKnowledge")
      const activityDefinition = getResources<ActivityDefinition>(medBundle, "ActivityDefinition")?.[0]

      const labBundle = (respBundle as Bundle).entry?.[1]?.resource as Bundle
      const serviceRequests = getResources<ServiceRequest>(labBundle, "ServiceRequest")
      const labPlanDefinitions = getResources<PlanDefinition>(labBundle, "PlanDefinition")

      const procedureBundle = (respBundle as Bundle).entry?.[2]?.resource as Bundle
      const procedures = getResources<Procedure>(procedureBundle, "Procedure")
      const procedureMRs = getResources<MedicationRequest>(procedureBundle, "MedicationRequest")
      const procedureMAs = getResources<MedicationAdministration>(procedureBundle, "MedicationAdministration")

      const { labCodes, pds, srs } = getSRCodes({
        serviceRequestActions,
        serviceRequests,
        planDefinitions: labPlanDefinitions,
      })

      let codes = {
        ...labCodes,
        billToPracticeOrInsuranceCIDs: [
          ...labCodes.billToPracticeOrInsuranceCIDs,
          ...convertIdentifiersToCodings(procedures),
        ],
      }
      const { nutraMrs, rxMrs } = medicationRequests.reduce(
        (acc, mr) => {
          const nutra = nutraceuticalActions.find((action) => mr.id === action.resource?.id)

          if (nutra) {
            return { ...acc, nutraMrs: [...acc.nutraMrs, mr] }
          } else {
            return { ...acc, rxMrs: [...acc.rxMrs, mr] }
          }
        },
        {
          nutraMrs: [] as MedicationRequest[],
          rxMrs: [] as MedicationRequest[],
        },
      )
      const nutraMedCodes = getMedCodes({ meds: nutraMrs, withQty: true }) ?? []
      const rxMedCodes = getMedCodes({ meds: rxMrs, withQty: true }) ?? []

      if (nutraMedCodes?.length || rxMedCodes?.length)
        codes = {
          billToPracticeOrInsuranceCIDs: [...codes.billToPracticeOrInsuranceCIDs, ...nutraMedCodes, ...rxMedCodes],
          billToPatientCIDs: [...codes.billToPatientCIDs, ...nutraMedCodes, ...rxMedCodes],
        }

      let rxCodes
      if (rxMedCodes?.length) {
        rxCodes = {
          billToPracticeOrInsuranceCIDs: [...rxMedCodes],
          billToPatientCIDs: [...rxMedCodes],
        }
      }

      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, codes)
      const chargeItemDefinitionsWithMedFee = await getChargeItemDefinitionsWithMedFee(organizationId, rxCodes, true)

      return {
        medicationRequestActions,
        medicationRequests: { nutraMrs, rxMrs },
        medicationKnowledges,
        serviceRequestActions,
        serviceRequests: srs,
        chargeItemDefinitions: {
          billToPracticeOrInsuranceCIDs: {
            ...chargeItemDefinitions?.billToPracticeOrInsuranceCIDs,
            ...(chargeItemDefinitionsWithMedFee?.billToPracticeOrInsuranceCIDs as Record<string, ChargeItemDefinition>),
          },
          billToPatientCIDs: {
            ...chargeItemDefinitions?.billToPatientCIDs,
            ...(chargeItemDefinitionsWithMedFee?.billToPatientCIDs as Record<string, ChargeItemDefinition>),
          },
        },
        activityDefinition,
        requestGroup: rg,
        labPlanDefinitions: pds,
        nutraceuticalActions,
        procedureActions,
        procedureMRs,
        procedureMAs,
        procedures,
      }
    },
    refetchOnWindowFocus: false,
    //refetchOnMount: "always",
    enabled,
    meta: { context: { queryKey, patientId } },
  })

  // Link RG actions with original MR or SR
  const {
    cpoeRequests,
    activeRequests,
    activityDefinition,
    shippingMethods,
    isOnlyDfo,
    discounts,
    coveragesByType,
    isOnlyMedication,
  } = useMemo(() => {
    let cpoeRequests: CpoeRequest[] = []
    data?.chargeItemDefinitions

    // Add medication requests
    if (data?.medicationRequests?.rxMrs) {
      cpoeRequests = data?.medicationRequests?.rxMrs.reduce((prev, mr) => {
        const action = data.medicationRequestActions.find(
          (action) => mr.id === action.resource?.id,
        ) as RequestGroupActionArrayActionArray
        const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })
        const mk = data.medicationKnowledges.find((mk) => getCommonCode({ codes: mk.code?.coding }) === mrCode)

        if (mr) {
          return [
            ...prev,
            {
              ...getMRData(action, mr, mrCode, mk, data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs),
              type: ACTION_GROUP_CODES.PHARMA,
            },
          ]
        }

        return prev
      }, cpoeRequests)
    }

    // Add nutraceutical requests
    if (data?.medicationRequests?.nutraMrs) {
      cpoeRequests = data.medicationRequests?.nutraMrs.reduce((prev, mr) => {
        const action = data.nutraceuticalActions.find(
          (action) => mr.id === action.resource?.id,
        ) as RequestGroupActionArrayActionArray
        const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })
        const mk = data.medicationKnowledges.find((mk) => getCommonCode({ codes: mk.code?.coding }) === mrCode)

        if (mr) {
          return [
            ...prev,
            {
              ...getMRData(action, mr, mrCode, mk, data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs),
              type: ACTION_GROUP_CODES.NUTRA,
            },
          ]
        }

        return prev
      }, cpoeRequests)
    }

    // Add laboratory requests
    if (data?.serviceRequests) {
      const pds = data.labPlanDefinitions

      const srs = data.serviceRequests

      cpoeRequests = data.serviceRequestActions.reduce((prev, current) => {
        let combos = 0
        let panelsCount = 0
        const serviceRequest = srs?.[current.resource?.id as string]

        const srPanels =
          serviceRequest.basedOn
            ?.filter((basedOn) => basedOn.resourceType === "ServiceRequest")
            .map((sr) => srs[sr.id as string]) ?? []

        const billingType = getServiceRequestBillingType(serviceRequest)

        const { orderPrice, panels } = srPanels.reduce<{
          orderPrice: Money
          panels: CpoeLaboratoryPanel[]
        }>(
          (acc, panel) => {
            const pdCodes = pds[panel.instantiatesCanonical?.[0] as string]
            const cids =
              billingType === BILLING_TYPES_CODES.BILL_PATIENT
                ? data?.chargeItemDefinitions?.billToPatientCIDs
                : data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs

            const price = getBasePrice(
              cids?.[getCidIdentifier(getCommonCode({ codes: pdCodes }))]?.propertyGroup?.[0].priceComponent,
            )?.amount

            if (
              panel.category?.some(({ coding }) =>
                coding?.some(({ code }) => code === ServiceRequestCategory.LAB_ORDER_COMBO),
              )
            ) {
              combos++
            } else {
              panelsCount++
            }

            return {
              orderPrice: {
                currency: price?.currency ?? "USD",
                value: sumPrice(acc.orderPrice.value ?? 0, price?.value ?? 0).sum.toNumber(),
              },
              panels: [...acc.panels, { profile: panel, price }],
            }
          },
          { orderPrice: { value: 0 }, panels: [] },
        )

        const carePlanId = serviceRequest.basedOn?.find((ref) => isCarePlan(ref))?.id

        return [
          ...prev,
          {
            resource: current,
            display: serviceRequest.performer?.[0].display,
            laboratoryData: {
              serviceRequest,
              panels,
              panelsCount,
              billingType,
              combos,
              carePlanId,
            },
            type: ACTION_GROUP_CODES.LAB,
            unitPrice: orderPrice,
            quantity: 1,
          } as CpoeRequest,
        ]
      }, cpoeRequests)
    }

    // Add procedure requests
    if (data?.procedures) {
      const proc = data?.procedures.reduce<Record<string, Procedure>>((prev, cur) => {
        return { ...prev, [cur.id as string]: cur }
      }, {})

      cpoeRequests = data.procedureActions.reduce((prev, action) => {
        const procedure = proc[action.resource?.id as string]
        const mas = data.procedureMAs.filter((ma) => ma.partOf?.some((ref) => ref.id === action.resource?.id))
        const mrs = data.procedureMRs.filter((mr) => mas.some((ma) => ma.request?.id === mr.id))
        const procedureCode = convertIdentifiersToCodings([procedure])

        const isMassage = getProcedureKind(procedure) === ProcedureKind.massage

        const medsData = isMassage
          ? procedure.bodySite?.reduce((acc, cc) => {
              return [
                ...acc,
                {
                  code: cc,
                  id: cc.coding?.[1]?.code,
                },
              ]
            }, Array<MedData>())
          : mrs.reduce((acc, mr) => {
              const medData: MedData = {
                id: mr.id,
                code: mr.medication?.CodeableConcept,
              }
              return [...acc, medData]
            }, Array<MedData>())

        return [
          ...prev,
          {
            resource: {
              ...action,
              timing: { ...action.timing, dateTime: new Date().toISOString() },
            },
            display: codeableConceptAsString(procedure.code),
            procedureData: { medicationRequests: mrs, medicationAdministrations: mas, procedure, medsData },
            quantity: 1,
            unitPrice: getBasePrice(
              data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs?.[
                getCidIdentifier(getCommonCode({ codes: procedureCode }))
              ]?.propertyGroup?.[0].priceComponent,
            )?.amount ?? { value: 0, currency: "USD" },
            type: ACTION_GROUP_CODES.PROCEDURE,
          } as CpoeRequest,
        ]
      }, cpoeRequests)
    }

    const activeRequests = cpoeRequests?.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate") ?? []

    const shippingMethods = data?.requestGroup?.contained?.filter(
      (res) =>
        isChargeItemDefinition(res) &&
        res.code?.coding?.some((coding) =>
          ([SYSTEM_VALUES.SHIPPING_METHOD, SYSTEM_VALUES.SERVICE_FEE] as string[]).includes(coding.system as string),
        ),
    ) as ChargeItemDefinition[] | undefined

    const discounts = data?.requestGroup?.contained?.filter(
      (res) =>
        isChargeItemDefinition(res) && res.code?.coding?.some((coding) => coding.system === SYSTEM_VALUES.DISCOUNT),
    ) as ChargeItemDefinition[] | undefined

    const coverages = data?.requestGroup?.contained?.filter(
      (res) => isCoverage(res) && res.type?.coding?.some((coding) => coding.system === SYSTEM_VALUES.COVERAGE_TYPE),
    ) as Coverage[] | undefined
    const coveragesByType = coverages?.reduce(
      (acc, coverage) => {
        const billingAction = data?.requestGroup?.action
          ?.find(({ code }) => code?.[0]?.coding?.some(({ code }) => code === "configure-payment"))
          ?.action?.find((action) => action.resource?.localRef === coverage.id)
        if (billingAction?.code?.[0]?.coding?.some(({ code }) => code === RG_BILLING_ACTION_CODES.PHARMA)) {
          return { ...acc, [ACTION_GROUP_CODES.PHARMA]: coverage }
        }

        if (billingAction?.code?.[0]?.coding?.some(({ code }) => code === RG_BILLING_ACTION_CODES.NUTRA)) {
          return { ...acc, [ACTION_GROUP_CODES.NUTRA]: coverage }
        }

        return { ...acc }
      },
      {} as Record<ActionGroupCode, Coverage>,
    )

    const isOnlyDfo =
      cpoeRequests
        ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
        .every((med) => med.medicationData?.isDfo) ?? false

    const isOnlyMedication =
      cpoeRequests
        ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
        .every((med) => med.medicationData?.isMedication) ?? false

    return {
      cpoeRequests,
      activityDefinition: data?.activityDefinition,
      activeRequests,
      shippingMethods,
      isOnlyDfo,
      isOnlyMedication,
      discounts,
      coveragesByType,
    }
  }, [data])

  return {
    cpoeRequests,
    activeRequests,
    requestGroup: data?.requestGroup,
    chargeItemDefintions: data?.chargeItemDefinitions,
    activityDefinition,
    requestShippingMethods: shippingMethods,
    requestDiscounts: discounts,
    requestCoverage: coveragesByType,
    isLoading,
    isFetching,
    isError,
    isOnlyDfo,
    isOnlyMedication,
    isRefetching,
  }
}

const getMRData = (
  action: RequestGroupActionArrayActionArray,
  mr: MedicationRequest,
  mrCode: string,
  mk?: MedicationKnowledge,
  billToPracticeOrInsuranceCIDs?: Record<string, ChargeItemDefinition>,
) => {
  const isRX = mr.category?.[0]?.coding?.some(({ code }) => code === MEDICATION_PRODUCT_TYPE.RX)
  const doseForm = isRX ? mk?.doseForm?.coding?.[0]?.display : undefined
  const packaging = isRX ? mk?.packaging?.type?.coding?.[0]?.display : undefined
  const maxDaysSupplyLimit = mk?.regulatory?.find(
    (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DAYS_SUPPLY,
  )?.regulatoryCharacteristic?.[0]?.value?.quantity?.value
  const qty = mr.dispenseRequest?.quantity
  const strength = mk?.ingredient?.[0]?.strength?.numerator

  return {
    resource: {
      ...action,
      timing: { ...action.timing, dateTime: new Date().toISOString() },
    },
    display: `${
      (mr.contained?.[0] as Medication)?.code?.text ?? codeableConceptAsString(mr.medication?.CodeableConcept)
    } (${qty?.value} ${isRX ? qty?.unit : pluralize(qty?.unit ?? "", qty?.value ?? 1)} ${strength?.unit ? `${!isRX ? strength.value ?? "" : ""}${strength.unit}` : ""})`,
    medicationData: {
      manufacturer: mk?.manufacturer?.display ?? "Unspecified",
      medicationRequest: mr,
      dispenseRequest: mr.dispenseRequest,
      ...(isRX ? { maxDaysSupplyLimit } : {}),
      isDfo: mr.dispenseRequest?.initialFill?.isDfo,
      isProcedure: isMrProcedure(mr),
      isMedication: isMrMedication(mr),
      doseForm,
      packaging,
    },
    quantity: mr.dispenseRequest?.quantity?.value ?? 1,
    unitPrice: getBasePrice(
      billToPracticeOrInsuranceCIDs?.[getCidIdentifier(mrCode, mr?.dispenseRequest?.quantity?.value)]
        ?.propertyGroup?.[0].priceComponent,
    )?.amount ?? {
      currency: "USD",
      value: 0,
    },
  }
}

export { useCpoeOrders }
