import { faBuilding, faEnvelope, faLocationDot, faPhone } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAddress } from "fhir"
import type { FC } from "react"

import { InformationCard } from "commons"
import { usePractitionerOrganizations } from "organization"
import { useLoginContext } from "security"
import { strCapitalize } from "utils"

import { ProfileSection } from "../data"

const PractitionerOrgsInformation: FC = () => {
  const { loggedInPractitionerId } = useLoginContext()

  const { organizations } = usePractitionerOrganizations(loggedInPractitionerId)

  return (
    <InformationCard
      id={ProfileSection.ORGANIZATION}
      title="Organizations Information"
      className="profile-card-section p-4"
      data={{
        Organizations: (
          <>
            {!organizations?.length ? (
              "No organizations"
            ) : (
              <div className="flex flex-col space-y-2">
                {organizations.map(({ name, address, telecom }, index) => (
                  <div key={`${name}.${index}`} className="flex flex-wrap">
                    <div className="font-bold">
                      <FontAwesomeIcon icon={faBuilding} size="sm" className="mr-2" />
                      <span title="Organization">{name}</span>
                    </div>
                    <span className="mx-1">|</span>
                    <span title="Address">
                      <FontAwesomeIcon icon={faLocationDot} size="xs" className="mr-1" />
                      {getAddress(address)}
                    </span>
                    {telecom?.[0]?.value && (
                      <span title={strCapitalize(telecom[0].system as string)}>
                        <span className="mx-1">|</span>
                        <FontAwesomeIcon
                          icon={telecom[0].system === "email" ? faEnvelope : faPhone}
                          size="xs"
                          className="mr-1"
                        />
                        {telecom[0].value}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        ),
      }}
    />
  )
}

export { PractitionerOrgsInformation }
