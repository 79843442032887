import type { FC } from "react"

import {
  AutocompleteCodeableConceptField,
  DateField,
  InputField,
  SwitchField,
  TextAreaField,
  ValueSetIds,
} from "commons"

const MedicationStatementForm: FC<Props> = ({ isEditing }) => (
  <div className="relative p-fluid grid gap-4 mb-3 flex-1">
    <span className="flex justify-between space-x-6">
      <AutocompleteCodeableConceptField
        field="medication.CodeableConcept"
        label="Medication"
        valueSetId={ValueSetIds.RXNORM}
        disabled={isEditing}
        className="flex-1 min-w-80"
      />
      <SwitchField field="status" label="Currently Taken" trueValue={"active"} falseValue={"unknown"} />
    </span>
    <InputField field="dosage.[0].text" label="Dosing & Frequency" />
    <AutocompleteCodeableConceptField field="reasonCode[0]" label="Reason" valueSetId={ValueSetIds.ICD10} />
    <DateField
      field="effective.dateTime"
      label="Effective"
      stringFormatType="ISO_8601_DATETIME"
      className="grow mr-3"
      showTime={true}
    />
    <TextAreaField autoResize field="note[0].text" label="Note" />
  </div>
)

type Props = {
  isEditing?: boolean
}

export { MedicationStatementForm }
