import { ModulesId, SubModule } from "commons/Module"

import { LaboratoryOrderDetails } from "laboratory-orders"

class LaboratoryOrderDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.LABORATORY_ORDER_DETAILS
    this.parent = ModulesId.LABSORDER
    this.display = "Order Details"
    this.content = <LaboratoryOrderDetails />
    this.searchParam = "order"
  }
}

export { LaboratoryOrderDetailsSubModule }
