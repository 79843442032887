import { useSuspenseQuery } from "@tanstack/react-query"
import { type Organization, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { organizationQueryKeys } from "../query-keys"

const usePractitionerOrganizations = (practitionerId?: string) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.withPractitionerId(practitionerId)

  const filters = new URLSearchParams({
    type: "healthcare-provider",
    active: "true",
    _count: "1000",
    _sort: "-name",
  })

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) =>
      search({ endpoint: `Practitioner/${practitionerId}`, filters, operation: "allowed-organizations", signal }).then(
        (bundle) => getResources<Organization>(bundle),
      ),
    meta: { context: { queryKey, practitionerId } },
  })

  const organizations = useMemo(
    () => data.toSorted((org1, org2) => org1.name?.toLowerCase().localeCompare(org2.name?.toLowerCase() ?? "") ?? 0),
    [data],
  )

  return { organizations }
}

export { usePractitionerOrganizations }
