import { useQuery } from "@tanstack/react-query"
import { type CommunicationRequest, getResources } from "fhir"

import { format } from "date-fns"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { patientsQueryKeys } from "../query-keys"

const usePatientInvitation = (patientId: string) => {
  const { search } = useClient()
  const queryKey = patientsQueryKeys.invitations.list(patientId)

  const { data, isLoading, isPending } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        category: "invitation",
        occurrence: `ge${format(new Date(), formatsByTypes.ISO_8601_DATE)}`,
        _count: "1",
        _sort: "-createdAt",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CommunicationRequest`, filters, signal })
      const invitation = getResources<CommunicationRequest>(bundle)

      return invitation
    },
    meta: { context: { queryKey, patientId } },
  })

  return { invitation: data?.[0], isLoading: isLoading || isPending }
}

export { usePatientInvitation }
