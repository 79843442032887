import { useSuspenseQuery } from "@tanstack/react-query"
import {
  type Device,
  type HealthcareService,
  type Location,
  type Organization,
  type Reference,
  asReference,
  getResource,
  getResources,
  isLocation,
} from "fhir"

import { useClient } from "api"
import { datadogLogs } from "logger"
import { SYSTEM_VALUES } from "system-values"

import { organizationQueryKeys } from "../query-keys"

const useOrganization = (organizationId: string) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.currentOrganization.current(organizationId)

  const { data, isLoading, isPending } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: organizationId,
        _revinclude:
          "Location:organization:Organization, Device:organization:Organization, HealthcareService:organization:Organization",
      })

      const bundle = await search({ endpoint: "Organization", filters, signal })

      const organization = getResource<Organization>(bundle, "Organization")
      const locations = getResources<Location>(bundle, "Location")
      const devicesSources = getResources<Device>(bundle, "Device").filter(
        ({ identifier }) => identifier?.every(({ system }) => system !== SYSTEM_VALUES.STRIPE_READER) ?? true,
      )
      const healthcareServices = getResources<HealthcareService>(bundle, "HealthcareService")
      const healthcareServicesRefs = healthcareServices.map(asReference)

      const { location, rooms, roomsRefs } =
        locations?.reduce<{ location: Location; rooms: Location[]; roomsRefs: Reference[] }>(
          (prev, location) => {
            if (isLocation(location)) {
              if (location.physicalType?.coding?.[0]?.code === "ro") {
                return {
                  ...prev,
                  rooms: [...prev.rooms, location],
                  roomsRefs: [...prev.roomsRefs, asReference(location)],
                }
              } else {
                return { ...prev, location: location }
              }
            }

            return prev
          },
          { location: {}, rooms: [], roomsRefs: [] },
        ) ?? {}

      const { devices, devicesRefs, devicesByRoom } =
        devicesSources?.reduce<{
          devices: Device[]
          devicesRefs: Reference[]
          devicesByRoom: Record<string, Reference[]>
        }>(
          (prev, device) => {
            return {
              devices: [...prev.devices, device],
              devicesRefs: [...prev.devicesRefs, asReference(device)],
              devicesByRoom: {
                ...prev.devicesByRoom,
                ...(device.location?.id
                  ? { [device.location.id]: [...(prev.devicesByRoom[device.location.id] ?? []), asReference(device)] }
                  : {}),
              },
            }
          },
          { devices: [], devicesRefs: [], devicesByRoom: {} },
        ) ?? {}

      const roomsWithDevices = rooms.reduce<(Location & { devices: Reference[] })[]>(
        (acc, room) => [...acc, { ...room, devices: devicesByRoom[room.id as string] ?? [] }],
        [],
      )

      const isActive = organization?.active !== undefined ? organization.active : true

      if (!organization || !isActive) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: "Organization not found",
          },
        })
      }

      if (!location) {
        datadogLogs.logger.warn(`Organization ${organization.name} does not have an associated location`, { queryKey })
      }

      return {
        organization,
        location,
        rooms,
        roomsRefs,
        roomsWithDevices,
        devices,
        devicesRefs,
        devicesByRoom,
        healthcareServices,
        healthcareServicesRefs,
      }
    },
    meta: { context: { queryKey } },
  })

  return {
    organization: data.organization,
    location: data.location as Location,
    rooms: data.rooms ?? [],
    roomsRefs: data.roomsRefs ?? [],
    roomsWithDevices: data.roomsWithDevices ?? [],
    healthcareServices: data.healthcareServices ?? [],
    healthcareServicesRefs: data.healthcareServicesRefs ?? [],
    devices: data.devices ?? [],
    devicesRefs: data.devicesRefs ?? [],
    devicesByRoom: data.devicesByRoom ?? {},
    isLoading: isLoading || isPending,
  }
}

export { useOrganization }
