import type { Appointment } from "fhir"
import type { FC } from "react"
import { useParams } from "react-router-dom"

import { DialogFormContainer } from "commons"
import { useOrganizationContext } from "organization"

import type { AppointmentFormData } from "../types"
import { AppointmentForm } from "./AppointmentForm"
import { appointmentValidationSchema, initialValues } from "./validations"

const AppointmentFormOverlay: FC<Props> = ({ appointment, onSubmit, onHide, visible, isEditing }) => {
  const { patientId } = useParams()
  const { location } = useOrganizationContext()

  return (
    <DialogFormContainer
      title={`${isEditing ? "Update" : "Schedule"} Appointment`}
      showForm={!!visible}
      initialValue={initialValues(appointment, location)}
      useFormik
      validationSchema={appointmentValidationSchema}
      onSubmit={onSubmit}
      onCancel={onHide}
      enableReinitialize
      className="w-4/5 md:w-4/5 lg:w-4/5 xl:w-2/3"
      saveLabel={isEditing ? "Update" : "Schedule"}
    >
      <AppointmentForm hidePatientField={patientId !== undefined} isEditing={isEditing} />
    </DialogFormContainer>
  )
}

type Props = {
  visible?: boolean
  isEditing?: boolean
  appointment: Appointment
  onHide(): void
  onSubmit(appointment: AppointmentFormData): void
}

export { AppointmentFormOverlay }
