import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledge, MedicationRequest } from "fhir"
import type { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { type ReactNode, useId, useState } from "react"

import { Button, InfiniteScroll, MedicationKnowledgeDetails, ModulesId, SkeletonLoader } from "commons"
import type { MedicationRequestData } from "commons/meds"
import { useAppModuleContext } from "internals"

import type { MRActions } from "../../types"
import { MedicationRequestListItem } from "./MedicationRequestListItem"

const MedicationRequestList = ({
  data,
  hasNextPage,
  fetchNextPage,
  totalMR,
  className,
  itemWaitingForAction,
  customEmptyMessage,
  onAddMR,
  medicationRequestActions,
}: Props) => {
  const loaderKey = useId()
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { appModules } = useAppModuleContext()

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className={classNames("flex flex-col flex-1 overflow-hidden", className)}>
      {!totalMR || !data?.length ? (
        customEmptyMessage ?? (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon icon={appModules[ModulesId.MEDICATIONR].getIcon()} size="2x" className="text-slate-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No Nutraceutical Orders Found</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by prescribe new products</p>
            <Button
              icon={faPlus}
              label="Create Nutraceutical Prescription"
              className="mt-6 create-prescription-button"
              onClick={onAddMR}
            />
          </div>
        )
      ) : (
        <div className="flex flex-1 flex-col overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            {data?.map((medication) => (
              <MedicationRequestListItem
                key={`${medication.medicationRequestInfo.id}-${medication.medicationRequestInfo.status}`}
                medicationData={medication}
                medicationRequestActions={medicationRequestActions}
                isLoading={itemWaitingForAction === medication.medicationRequestInfo.id}
                onSelectMK={(mk) => setSelectedMK({ mk, mr: medication.medicationRequestInfo })}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
      />
    </div>
  )
}

type Props = {
  data?: MedicationRequestData[]
  totalMR: number
  medicationRequestActions: MRActions
  className?: string
  customEmptyMessage?: JSX.Element | ReactNode
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  itemWaitingForAction?: string
  hasNextPage: boolean
  fetchNextPage: () => void
  onAddMR(): void
}

export { MedicationRequestList }
