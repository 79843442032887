import { useInfiniteQuery } from "@tanstack/react-query"
import { compareDesc, parseISO } from "date-fns"
import {
  type CarePlan,
  type DiagnosticReport,
  type DocumentReference,
  type Money,
  type PlanDefinition,
  type Practitioner,
  type PractitionerRole,
  type ServiceRequest,
  type Specimen,
  type Task,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  isCarePlan,
} from "fhir"
import groupBy from "lodash/groupBy"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import {
  type LaboratoryOrder,
  type LaboratoryOrderCombo,
  type LaboratoryOrderPanel,
  LAB_TASK_CODE,
  getSRCodes,
  getStatus,
  revocableOrderStatus,
  revocableTaskStatus,
} from "commons/labs"
import { BILLING_TYPES_CODES, ServiceRequestCategory, formatsByTypes } from "data"
import {
  convertIdentifiersToCodings,
  formatDate,
  getBasePrice,
  getCidIdentifier,
  getCommonCode,
  getLabOrderIdentifier,
  getServiceRequestBillingType,
  mergeSort,
  sumPrice,
} from "utils"

import { labOrdersQueryKeys } from "../query-keys"

const useLaboratoryOrders = ({
  organizationId,
  patientId,
  statusFilter,
  searchText,
  enabled,
  encounter,
  isAdminUser,
}: LaboratoryOrdersArgs) => {
  const { search } = useClient()
  const queryKey = labOrdersQueryKeys.list(patientId, statusFilter, searchText, encounter)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    LaboratoryOrderQueryData,
    Error
  >({
    queryKey,
    enabled,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        _query: "laboratories-data",
        patient: patientId,
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-authored",
        _include: "based-on",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const { laboratoryRequests, ordersSplited, serviceRequests } = getResources<ServiceRequest>(
        bundle,
        "ServiceRequest",
      ).reduce(
        (prev, order) => {
          const categories = order.category

          if (
            categories?.some(({ coding }) => coding?.[0]?.code === ServiceRequestCategory.LAB_ORDER) &&
            categories?.every(({ coding }) => coding?.[0]?.code !== ServiceRequestCategory.LAB_ORDER_SPLIT)
          ) {
            return {
              ...prev,
              serviceRequests: [...prev.serviceRequests, order],
              laboratoryRequests: [...prev.laboratoryRequests, order],
            }
          }

          if (categories?.some(({ coding }) => coding?.[0]?.code === ServiceRequestCategory.LAB_ORDER_SPLIT)) {
            return {
              ...prev,
              serviceRequests: [...prev.serviceRequests, order],
              ordersSplited: {
                ...prev.ordersSplited,
                [order.replaces?.[0].id ?? ""]: [...(prev.ordersSplited?.[order.replaces?.[0].id ?? ""] ?? []), order],
              },
            }
          }

          return { ...prev, serviceRequests: [...prev.serviceRequests, order] }
        },
        {
          laboratoryRequests: [] as ServiceRequest[],
          ordersSplited: {} as Record<string, ServiceRequest[]>,
          serviceRequests: [] as ServiceRequest[],
        },
      )

      const revokedPlansRecord = getResources<CarePlan>(bundle, "CarePlan")
        .filter(({ status }) => status === "revoked")
        .reduce(
          (acc, plan) => {
            const planId = plan.id
            return { ...acc, ...(planId ? { [planId]: plan } : {}) }
          },
          {} as Record<string, CarePlan>,
        )

      const indexedTasks = getResourcesByTypeAsIndex<Task>(bundle, "Task", ({ focus }) => focus?.id)
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const specimens = getResourcesByTypeAsIndex<Specimen>(bundle, "Specimen")
      const practRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")
      const documentsByOrder = getResources<DocumentReference>(bundle, "DocumentReference").reduce<
        Record<string, string>
      >((prev, doc) => {
        const newDocOrderId = doc.context?.related?.[0]?.id
        const newDocAttachment = doc.content[0].attachment.url
        return !!newDocAttachment && !!newDocOrderId ? { ...prev, [newDocOrderId]: newDocAttachment } : { ...prev }
      }, {})

      const panels = serviceRequests.reduce<Record<string, ServiceRequest>>(
        (acc, sr) => ({ ...acc, [sr.id as string]: sr }),
        {},
      )
      const { labCodes } = getSRCodes({ serviceRequests, planDefinitions })

      const { billToPracticeOrInsuranceCIDs, billToPatientCIDs } = await getChargeItemDefinitions(
        organizationId,
        labCodes,
      )

      const defaultLaboratoryPanels = {
        laboratoryPanels: new Array<LaboratoryOrderPanel>(),
        totalPrice: { currency: "USD", value: 0 },
      }

      const indexedPractitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")

      const requesters = practRoles.reduce<Record<string, string>>((requesters, pr) => {
        if (pr.id && pr.practitioner?.id) {
          const pract = indexedPractitioners[pr.practitioner.id]

          if (pract) {
            return { ...requesters, [pr.id]: humanNameAsString(pract?.name?.[0]) }
          }
        }
        return requesters
      }, {})

      const getPanelData = (id: string, billingType: BILLING_TYPES_CODES) => {
        const panelInstantiateCanonical = panels[id]?.instantiatesCanonical?.[0] ?? ""
        const [pdUrl, pdVersion] = panelInstantiateCanonical.split("|")
        const planDefinition = planDefinitions.find((pd) => pd.url === pdUrl && pd.version === pdVersion)

        const codes = convertIdentifiersToCodings(planDefinition ? [planDefinition] : [])
        const cid =
          billingType === BILLING_TYPES_CODES.BILL_PATIENT
            ? billToPatientCIDs[getCidIdentifier(getCommonCode({ codes }))]
            : billToPracticeOrInsuranceCIDs[getCidIdentifier(getCommonCode({ codes }))]

        const cidPrice = getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount

        return {
          panel: {
            profile: panels[id],
            price: cidPrice,
            planDefinition,
          },
        }
      }

      const sortedDRs = mergeSort(diagnosticReports?.filter(({ issued }) => !!issued) ?? [], "issued", (a, b) =>
        compareDesc(new Date(a), new Date(b)),
      )

      const labOrders = laboratoryRequests.reduce((prev, labOrder) => {
        const orderComboId = labOrder.basedOn?.flatMap(({ id }) =>
          id && panels[id]?.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_COMBO ? id : [],
        )?.[0]
        let totalPrice: Money = { currency: undefined, value: 0 }
        let orderCombo: LaboratoryOrderCombo | undefined = undefined

        const billingType = getServiceRequestBillingType(labOrder)
        const orderIdentifier = getLabOrderIdentifier(labOrder, ordersSplited[labOrder.id ?? ""] ?? [])

        if (orderComboId) {
          const comboData = getPanelData(orderComboId, billingType)
          const comboPanels =
            comboData.panel.profile.basedOn?.reduce((acc, bo) => {
              const panel =
                bo.resourceType === "ServiceRequest" ? getPanelData(bo.id as string, billingType).panel : undefined
              return panel ? [...acc, panel] : [...acc]
            }, Array<LaboratoryOrderPanel>()) ?? Array<LaboratoryOrderPanel>()

          orderCombo = {
            laboratoryCombo: comboData.panel.profile,
            panels: comboPanels,
            price: comboData.panel.price,
          }

          totalPrice = { ...comboData.panel.price }
        }

        // Get extra panels from the order basedOn
        const orderPanelsId = labOrder.basedOn
          ?.filter(({ id }) => !orderCombo?.panels.some(({ profile }) => profile?.id === id))
          .flatMap(({ id }) =>
            id && panels[id]?.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL ? id : [],
          )

        const { laboratoryPanels, totalPrice: panelsPrice } =
          orderPanelsId?.reduce((prevPanels, id) => {
            const panelData = getPanelData(id, billingType)

            return {
              laboratoryPanels: [...prevPanels.laboratoryPanels, panelData.panel],
              totalPrice: {
                currency: panelData.panel.price?.currency ?? "USD",
                value: sumPrice(prevPanels.totalPrice.value, panelData.panel.price?.value ?? 0).sum.toNumber(),
              },
            }
          }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

        totalPrice.value = sumPrice(totalPrice.value ?? 0, panelsPrice.value).sum.toNumber()
        totalPrice.currency = totalPrice.currency ?? panelsPrice.currency

        const latestResult = sortedDRs.find(
          (dr) => dr.basedOn?.some(({ id }) => id === labOrder.id) && !!dr.presentedForm?.[0]?.url,
        )
        const presentedForm = latestResult?.presentedForm?.[0].url ?? ""

        const labOrderStatus = getStatus(labOrder)
        const orderRequisition =
          labOrderStatus?.code !== "revoked" ? documentsByOrder[labOrder.id as string] : undefined

        const orderLabTask = indexedTasks?.[labOrder.id as string]?.code?.coding?.some(
          ({ code }) => code === LAB_TASK_CODE,
        )
          ? indexedTasks?.[labOrder.id as string]
          : undefined

        const planBased = labOrder?.basedOn?.find(isCarePlan)?.id
        const activePlanBased = !!planBased && !revokedPlansRecord[planBased]?.id
        const isAdminRevocable =
          !!isAdminUser &&
          !!orderLabTask &&
          !activePlanBased &&
          revocableOrderStatus.includes(labOrderStatus?.code as string)

        const isInsuranceRevocable =
          billingType === BILLING_TYPES_CODES.INSURANCE && revocableOrderStatus.includes(labOrderStatus?.code as string)

        const revocable =
          !activePlanBased &&
          (labOrderStatus?.code === "draft" ||
            (!orderRequisition &&
              labOrderStatus?.code !== "revoked" &&
              revocableTaskStatus.includes(orderLabTask?.status as string)) ||
            isInsuranceRevocable)

        return [
          ...prev,
          {
            order: labOrder,
            orderIdentifier,
            panels: laboratoryPanels,
            panelsCount: laboratoryPanels?.length ?? 0,
            billingType: billingType,
            deletedPanels: [],
            price: totalPrice,
            requester: requesters[labOrder.requester?.id ?? ""] ?? "unspecified",
            bloodDrawnInOffice: !!labOrder.specimen?.[0],
            specimenDate: specimens?.[labOrder.specimen?.[0]?.id as string]?.receivedTime,
            presentedForm,
            revocable: isAdminRevocable || revocable,
            combo: orderCombo,
            requisition: orderRequisition,
            latestResult,
          },
        ]
      }, [] as LaboratoryOrder[])

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { labOrders: labOrders ?? [], next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { laboratoryOrders, count, orders } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.labOrders) ?? []
    const count = newData?.length

    const result = groupBy(newData, ({ order }) =>
      order?.authoredOn ? formatDate(parseISO(order?.authoredOn), formatsByTypes.LONG_DATE) : "unspecified",
    )

    return {
      laboratoryOrders: result,
      orders: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    laboratoryOrdersByDate: Object.entries(laboratoryOrders).map(([date, items = []]) => ({
      key: date,
      name: date,
      items,
    })),
    laboratoryOrders: orders,
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

interface LaboratoryOrdersArgs {
  organizationId: string
  patientId: string
  statusFilter: string[]
  searchText?: string
  enabled?: boolean
  encounter?: string
  isAdminUser?: boolean
}

type LaboratoryOrderQueryData = { labOrders: LaboratoryOrder[]; next: number | undefined; total: number }

export { useLaboratoryOrders }
