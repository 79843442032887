import type { SyntheticEvent } from "react"
import { type Patient, getFirstEmail, humanNameAsString } from "fhir"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { AvatarImage, AvatarListItem, Badge } from "commons"

const ContactListItem = ({ patient: { telecom, name, photo }, onClick, unread = 0, isSelected }: Props) => {
  const details = (
    <span title="Email" className="pl-2">
      <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
      <span>{getFirstEmail(telecom)}</span>
    </span>
  )

  return (
    <div className="flex flex-row justify-between cursor-pointer relative" onClick={onClick}>
      {isSelected && <span className="h-full w-1 bg-primary absolute left-0 z-10" />}
      <AvatarListItem
        avatarImg={
          <AvatarImage
            name={humanNameAsString(name?.[0])}
            photoUrl={photo?.[0]?.url}
            className="h-10 w-10 rounded-full"
          />
        }
        header={<span className="capitalize">{humanNameAsString(name?.[0])}</span>}
        subHeader={details}
      />
      {unread > 0 && !isSelected && (
        <span title="Unread" className="self-center px-2">
          <Badge text={unread.toString()} colorStyle="green"></Badge>
        </span>
      )}
    </div>
  )
}

type Props = {
  patient: Patient
  unread?: number
  isSelected?: boolean
  onClick(event: SyntheticEvent): void
}

export { ContactListItem }
