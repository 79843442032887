import type { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { faPills } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledgeDrugCharacteristicArray } from "fhir"
import { Carousel } from "primereact/carousel"
import { classNames } from "primereact/utils"
import { type ReactNode, useMemo } from "react"

import { AzureImage } from "commons"

import "./MedicationKnowledgeImage.css"

const MedicationKnowledgeImage = ({
  drugCharacteristic,
  className = "w-24 mr-1",
  showAll = false,
  fallbackIconSize = "4x",
  showFallback = true,
}: MKImageProps) => {
  const { images } = useMemo(() => {
    const images = drugCharacteristic?.reduce((images, { value }) => {
      const newImage = value?.attachment?.url && (
        <AzureImage
          key={value.attachment.id ?? value.attachment.url}
          url={value.attachment.url}
          alt="MKImg"
          fallbackIconNode={
            <FontAwesomeIcon
              icon={faPills}
              size={fallbackIconSize}
              className={classNames("rounded-md w-24", className)}
            />
          }
          imageClassName={!showAll ? className : ""}
          showPreview={showAll}
        />
      )

      return newImage ? [...images, newImage] : images
    }, [] as JSX.Element[])

    return { images }
  }, [drugCharacteristic, fallbackIconSize, showAll, className])

  const itemTemplate = (item: ReactNode) => item

  if (!images?.length)
    return showFallback ? <FontAwesomeIcon icon={faPills} size={fallbackIconSize} className="rounded-md w-24" /> : null

  return (
    (showAll && (
      <Carousel value={images} itemTemplate={itemTemplate} numVisible={1} numScroll={1} className={className} />
    )) ||
    (images?.find((img) => img.key?.toString().includes("front")) ?? images[0])
  )
}

type MKImageProps = {
  drugCharacteristic?: MedicationKnowledgeDrugCharacteristicArray[]
  className?: string
  showAll?: boolean
  fallbackIconSize?: SizeProp
  showFallback?: boolean
}

export { MedicationKnowledgeImage }
