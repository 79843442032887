import { useMutation } from "@tanstack/react-query"
import type { Parameters } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useUserHash = (onSuccess?: (data: string) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()

  const getUserHash = async (userId: string) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "userId",
          value: {
            string: userId,
          },
        },
      ],
    }

    const results = await operationRequest<Parameters>({
      endpoint: "",
      method: "POST",
      operation: "intercom",
      parameters,
    })
    return results.parameter?.find((param) => param.name === "hmac")?.value?.string
  }

  const {
    mutate: getIntercomHMac,
    isPending,
    data,
  } = useMutation({
    mutationFn: getUserHash,
    onError: (error: CustomError, variables, context) => {
      displayNotificationError(registerErrorTrace(error, { context, variables }))
    },
    onSuccess: (data) => {
      onSuccess?.(data as string)
    },
    onSettled,
  })

  return { getIntercomHMac, isPending, hmac: data }
}

export { useUserHash }
