import type { Reference } from "fhir"
import { createContext, useContext } from "react"

import type { EncounterData } from "../types"

const EncounterContext = createContext<State | undefined>(undefined)

EncounterContext.displayName = "EncounterContext"

const useEncounterContext = () => {
  const context = useContext(EncounterContext)

  if (!context) throw new Error("useEncounterContext must be used within a EncounterContextProvider")

  return context
}

export type State = {
  encounterData?: EncounterData
  planRefs?: Record<string, Reference>
  totalAllergies: number
  totalConditions: number
  totalEnteredLabs: number
  totalFH: number
  totalLabOrders: number
  totalMedStatements: number
  totalPlans: number
  totalProcedures: number
  totalQs: number
  totalSocialHistory: number
  totalVitals: number
}

export { EncounterContext, useEncounterContext }
