import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquarePollVertical } from "@fortawesome/pro-regular-svg-icons"
import { type IconDefinition, faSquarePlus, faSquareMinus } from "@fortawesome/pro-light-svg-icons"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"

const LaboratoryValueItem = ({ value, title, icon, className }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [needShowMore, setNeedShowMore] = useState(false)

  const valueRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const determineIsTruncated = () => {
      if (!valueRef.current) return false
      return valueRef.current.scrollWidth > valueRef.current.clientWidth
    }

    setNeedShowMore(determineIsTruncated())

    const resizeListener = () => setNeedShowMore(determineIsTruncated())
    window.addEventListener("resize", resizeListener)
    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return (
    <>
      <div className={classNames("mt-1 @md:mt-0 whitespace-normal flex w-full", className)} title={title ?? "Value"}>
        <FontAwesomeIcon
          icon={icon ?? faSquarePollVertical}
          className="mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
        />
        <span className={classNames({ truncate: !expanded })} ref={valueRef}>
          <span className="mr-1">{value}</span>
          {expanded && (
            <span
              className="text-slate-600 cursor-pointer w-6"
              title="Show less"
              onClick={() => setExpanded(!expanded)}
            >
              <FontAwesomeIcon icon={faSquareMinus} className="h-3.5 w-3.5 text-gray-400" />
            </span>
          )}
        </span>
        {needShowMore && !expanded && (
          <span
            className="text-slate-600 cursor-pointer ml-1 w-6"
            title="Show more"
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon icon={faSquarePlus} className="h-3.5 w-3.5 mr-2.5 text-gray-400" />
          </span>
        )}
      </div>
    </>
  )
}

type Props = {
  value?: string
  title?: string
  icon?: IconDefinition
  className?: string
}

export { LaboratoryValueItem }
