import { type FieldProps, ErrorMessage, Field } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import { classNames } from "primereact/utils"
import type { FC } from "react"

const SwitchField: FC<Props> = ({ field, label, className, trueValue = true, falseValue = false }) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className={classNames("field flex flex-col", className)}>
        {label && (
          <label htmlFor={name} className="text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <InputSwitch
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          className={classNames("min-h-[2.9rem] min-w-12", { "p-invalid": touched && error })}
          trueValue={trueValue}
          falseValue={falseValue}
        />
        <div className="flex items-start p-error h-2">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  rows?: number
  trueValue?: string | number | object
  falseValue?: string | number | object
}

export { SwitchField }
