import { faCalendar, faExternalLink, faPills, faSquareArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import type { CarePlan, Reference } from "fhir"

import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"

import type { StackedListItemProps } from "../../../components/StackedListItem"
import { getAssessmentStatusText } from "../../utils"

const assessmentItemModel = (
  carePlan: { assessment: CarePlan; medRefs?: Reference[] },
  viewAssessment: () => void,
  download: (assessmentId: string) => void,
  isLoadingUrl: boolean,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Title",
          value: carePlan.assessment.title ?? carePlan.assessment.author?.display ?? `Unnamed assessment`,
        },
      ],
    },
    {
      lineItems: [
        {
          name: "Created",
          value: carePlan.assessment.created
            ? formatDate(parseISO(carePlan.assessment.created as string), formatsByTypes.LONG_DATE)
            : "No date",
          icon: faCalendar,
        },
        ...(carePlan.medRefs?.length
          ? [
              {
                name: "Medications",
                value: carePlan.medRefs?.length?.toString(),
                icon: faPills,
              },
            ]
          : []),
      ],
    },
  ],
  badge: {
    ...getBadgeColor(carePlan.assessment.status === "revoked" ? "cancelled" : carePlan.assessment.status),
    text: getAssessmentStatusText(carePlan.assessment.status),
  },
  menu: [
    {
      label: `View assessment details`,
      icon: <FontAwesomeIcon icon={faExternalLink} />,
      command: viewAssessment,
    },
    {
      label: "Download report",
      icon: <FontAwesomeIcon icon={faSquareArrowDown} size="sm" />,
      command: () => {
        download(carePlan.assessment.id as string)
      },
      disabled: isLoadingUrl,
    },
  ],
})

export { assessmentItemModel }
