import { Button } from "primereact/button"
import { InputNumber } from "primereact/inputnumber"
import { faCheckDouble, faMoneyBillWave } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledge, Money } from "fhir"
import { useState } from "react"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"

import { getMoneyCurrencyAlt } from "utils"
import { MedicationKnowledgeImage, MedicationRequestItem } from "commons/meds"

const MedicationKnowledgeCard = ({
  medicationKnowledge,
  unitPrice,
  onAdd,
  isAlreadyAdded,
  isLoadingPrice,
  blockAdd,
  additionFailed,
  onClick,
}: Props) => {
  const [inputValue, setInputValue] = useState(1)
  const [addClicked, setAddClicked] = useState(false)
  const isWaitingForMedAddition = addClicked && !additionFailed && !isAlreadyAdded

  const productPrice = unitPrice

  return (
    <div className="border border-slate-300 rounded-lg p-3 xl:p-5 grow flex flex-col gap-3 lg:gap-4">
      <span className="flex m-auto items-center cursor-pointer h-full" onClick={onClick}>
        <MedicationKnowledgeImage drugCharacteristic={medicationKnowledge?.drugCharacteristic} className="h-28" />
      </span>
      <MedicationRequestItem
        medicationKnowledge={medicationKnowledge}
        className="mt-2 xl:mt-3 text-left justify-end"
        onClick={onClick}
      />
      <div className="product-list-action flex flex-col gap-2 grow justify-end">
        {isLoadingPrice ? (
          <span className="inline-flex mb-1">
            <FontAwesomeIcon icon={faMoneyBillWave} className="mr-1" />
            <Skeleton width="100%" />
          </span>
        ) : (
          <span title="Price" className="font-semibold text-lg">
            {productPrice?.value && getMoneyCurrencyAlt(productPrice?.currency)}
            {productPrice?.value?.toFixed(2) ?? "No price"}
          </span>
        )}

        <span className={classNames("flex gap-2 2xl:flex-col", isAlreadyAdded ? "justify-end" : "justify-between")}>
          {!isAlreadyAdded && (
            <InputNumber
              locale="en-US"
              showButtons
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              buttonLayout="horizontal"
              min={1}
              value={inputValue}
              allowEmpty={false}
              disabled={blockAdd || isLoadingPrice || isWaitingForMedAddition}
              onValueChange={(e) => {
                setInputValue(e.value ?? 1)
              }}
              className="input-text-center p-fluid w-[65%] 2xl:w-full"
            />
          )}
          <Button
            icon={isAlreadyAdded && <FontAwesomeIcon icon={faCheckDouble} className="mr-1 grow" />}
            label={isAlreadyAdded ? "Added" : "Add"}
            loading={isWaitingForMedAddition}
            className={classNames({
              "p-button-outlined cursor-default button-primary-outlined": isAlreadyAdded,
              "button-primary": !isAlreadyAdded,
              "2xl:mt-14": isAlreadyAdded,
            })}
            disabled={blockAdd || isLoadingPrice || isAlreadyAdded || isWaitingForMedAddition}
            onClick={() => {
              if (!isAlreadyAdded) {
                setAddClicked(true)
                onAdd(medicationKnowledge, inputValue)
              }
            }}
          />
        </span>
      </div>
    </div>
  )
}

type Props = {
  medicationKnowledge: MedicationKnowledge
  blockAdd?: boolean
  isAlreadyAdded?: boolean
  isAdding?: boolean
  isLoadingPrice?: boolean
  unitPrice?: Money
  additionFailed?: boolean
  onAdd(mk: MedicationKnowledge, unitsCount: number): void
  onClick(): void
}

export { MedicationKnowledgeCard }
