import {
  type CodeableConcept,
  type Patient,
  type PlanDefinition,
  type Reference,
  type ServiceRequest,
  codeableConceptAsString,
} from "fhir"
import * as Yup from "yup"

import { type LaboratoryOrder, getLabOrderInitialValues, getSanitizedOrderCoverage } from "commons/labs"
import { isCanonicalOfDrawFee } from "commons/utils"
import { BILLING_TYPES_CODES } from "data"
import { SYSTEM_VALUES } from "system-values"

const laboratoryOrderValidationSchema = Yup.object().shape({
  billingType: Yup.string().required("Billing type is required"),
  combo: Yup.object(),
  panels: Yup.array().test(
    "panels-added",
    "At least one panel is required. Ensure you already have one selected",
    (panels: { profile: ServiceRequest }[] | undefined, context) => {
      const noDrawPanels = panels?.filter(
        ({ profile: { instantiatesCanonical } }) => !isCanonicalOfDrawFee(instantiatesCanonical?.[0] as string),
      )
      return noDrawPanels?.length || context.parent.combo
    },
  ),
  order: Yup.object().shape({
    performer: Yup.array().of(
      Yup.object().test("test-reference", "Lab is required", (value: Reference) => value?.id !== undefined),
    ),
    requester: Yup.object().test(
      "test-reference",
      "Requester is required",
      (value: Reference) => value?.id !== undefined,
    ),
    reasonCode: Yup.array(),
    patientAddress: Yup.object().when("$billingType", {
      is: BILLING_TYPES_CODES.INSURANCE,
      then: Yup.object().nullable().required("Address is required"),
    }),
  }),
})

const getPanelInitialValues = (
  patient: Reference,
  performer: Reference[],
  practitionerRole?: Reference,
  insurance?: Reference[],
  isCombo = false,
): ServiceRequest => ({
  code: { coding: undefined },
  intent: "order",
  category: [
    {
      coding: [
        {
          system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
          code: isCombo ? "lab-order-combo" : "lab-order-panel",
          display: isCombo ? "Lab Order combo" : "Lab Order Test",
        },
      ],
      text: isCombo ? "Lab Order combo" : "Lab Order Test",
    },
  ],
  status: "draft",
  authoredOn: undefined,
  subject: patient,
  requester: practitionerRole,
  performer,
  insurance,
  basedOn: undefined,
  note: [{ text: "" }],
})

const getInitialValues = (
  patient: Patient,
  orderRequester?: Reference,
  encounter?: Reference,
  conditions?: CodeableConcept[],
): LaboratoryOrder => ({
  order: getLabOrderInitialValues({ patient, practitionerRoleRef: orderRequester, encounter, reasonCode: conditions }),
  orderIdentifier: "",
  panelsCount: 0,
  billingType: "",
  panels: [],
  deletedPanels: [],
  price: { currency: "USD", value: 0 },
  requester: "unspecified",
  bloodDrawnInOffice: false,
  specimenDate: undefined,
})

const sanitize = ({ ...labOrder }: LaboratoryOrder, orgRef: Reference) => {
  labOrder.order.authoredOn = new Date().toISOString()
  if (!labOrder.order.basedOn) delete labOrder.order.basedOn
  if (!labOrder.order.performer?.length || !labOrder.order.performer?.[0].id) delete labOrder.order.performer
  if (!labOrder.order.encounter) delete labOrder.order.encounter
  if (!labOrder.order.code?.coding) delete labOrder.order.code
  if (labOrder.order.note?.[0]?.text === "") delete labOrder.order.note
  if (!labOrder.order.requester?.id) {
    delete labOrder.order.requester
  }

  labOrder.order = { ...getSanitizedOrderCoverage(labOrder.order, labOrder.billingType, orgRef) }

  if (!labOrder.order.reasonCode?.length) {
    delete labOrder.order.reasonCode
  } else {
    labOrder.order.reasonCode = removeDuplicatedCCs(labOrder.order.reasonCode)
  }
  if (!labOrder.order.contained?.length) delete labOrder.order.contained

  return labOrder
}

const sanitizePanel = ({ ...panel }: ServiceRequest) => {
  panel.authoredOn = new Date().toISOString()
  if (!panel.basedOn) delete panel.basedOn
  if (!panel.performer?.length || !panel.performer?.[0].id) delete panel.performer
  if (!panel.encounter) delete panel.encounter
  if (!panel.code?.coding) delete panel.code
  if (!panel.note?.[0].text) delete panel.note

  if (!panel.insurance?.[0]?.id) delete panel.insurance

  return panel
}

const removeDuplicatedCCs = (codeableConpeptList: CodeableConcept[]) => {
  const { ccList } = codeableConpeptList.reduce(
    ({ codes, ccList }, cConcept) => {
      const conceptAsString = codeableConceptAsString(cConcept)

      if (!codes.includes(conceptAsString)) {
        return { codes: [...codes, conceptAsString], ccList: [...ccList, cConcept] }
      } else {
        return { codes: [...codes], ccList: [...ccList] }
      }
    },
    { codes: [] as string[], ccList: [] as CodeableConcept[] },
  )

  return ccList
}

const getSrData = ({
  patient,
  performer,
  planDefinition,
  practitionerRole,
  insurance,
  isCombo = false,
}: {
  patient: Reference
  performer: Reference[]
  planDefinition: PlanDefinition
  practitionerRole?: Reference
  insurance?: Reference[]
  isCombo?: boolean
}): ServiceRequest => {
  const result = getPanelInitialValues(patient, performer, practitionerRole, insurance, isCombo)

  const pdCc = planDefinition.identifier?.map((identifier) => ({
    code: identifier.value,
    display: planDefinition.title ?? "unknown",
    system: identifier.system,
  }))

  result.code = {
    coding: pdCc,
    text: planDefinition.title,
  }

  result.instantiatesCanonical = [`${planDefinition.url}|${planDefinition.version}`]

  return result
}

export {
  getInitialValues,
  getLabOrderInitialValues,
  getPanelInitialValues,
  getSrData,
  laboratoryOrderValidationSchema,
  sanitize,
  sanitizePanel,
}
