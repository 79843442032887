import type { Reference } from "fhir"
import { useState } from "react"

import { BILLING_TYPES_CODES } from "data"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"

import { CheckMultipleWithSlideoverFilter } from "../../../forms/CheckMultipleWithSlideoverFilter"
import { labTestModelBuilder } from "../../../forms/labFields/labTestModelBuilder"
import { useLaboratoryTests } from "../../../labs"
import { ModulesId } from "../../../Module"
import type { LaboratoryTest } from "../../../types"
import type { ComboDefinition, PanelItemDisplay } from "../../types"

const ExtraPanelSelection = ({
  onHide,
  extraPanels,
  onSave,
  selectedCombo,
  billingType,
  selectedPerformer,
  isReadonlyItem,
}: Props) => {
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const { appModules } = useAppModuleContext()
  const [searchFilter, setSearchFilter] = useState<string>()

  const { labTests, isLoading, hasNextPage, fetchNextPage } = useLaboratoryTests({
    organizationId: currentOrganizationId,
    catalogAuthorId: selectedPerformer?.id,
    searchText: searchFilter,
    count: 100,
    billingType: billingType,
    withPrice: billingType !== BILLING_TYPES_CODES.INSURANCE && !isExemptLabPayment,
  })

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE

  const inCombo = (url?: string, version?: string) =>
    selectedCombo?.canonicalPanels.includes(`${url}|${version}`) ?? false

  return (
    <CheckMultipleWithSlideoverFilter
      isVisible={true}
      onHide={() => onHide()}
      filterProps={{
        autoFocus: true,
        isLoading: isLoading,
        onTextFilter: setSearchFilter,
        placeholder: "Please type to search",
      }}
      icon={appModules[ModulesId.LABSORDER].getIcon()}
      initialCheckedValues={extraPanels}
      itemModel={(item) =>
        labTestModelBuilder(
          item,
          (item as PanelItemDisplay).isCombo ? item.planDefinition.title : undefined,
          !isExemptLabPayment && !isInsurance,
        )
      }
      queryProps={{ data: labTests, isFetching: isLoading, hasNextPage, fetchNextPage }}
      onSave={(newItems, deletedItems) => {
        onSave({ newPanels: newItems, deletedPanels: deletedItems })
      }}
      getItemId={({ planDefinition: { url, version } }) => `${url}|${version}`}
      getItemText={({ display }) => `${display}`}
      evaluateExtraCheckedCondition={({ planDefinition: { url, version } }, deletedItems) =>
        inCombo(url, version) && !deletedItems.some(({ id }) => id === selectedCombo?.canonical)
      }
      evaluateIsDisabled={({ planDefinition: { url, version, id } }, deletedItems) =>
        (inCombo(url, version) && !deletedItems.some(({ id }) => id === selectedCombo?.canonical)) ||
        (isReadonlyItem?.(id as string) ?? false)
      }
      title="Select panels"
    />
  )
}

type Props = {
  onHide(): void
  extraPanels: PanelItemDisplay[]
  onSave({
    newPanels,
    deletedPanels,
    deleteAll,
  }: {
    newPanels: LaboratoryTest[]
    deletedPanels: { id: string; index: number }[]
    deleteAll?: boolean
  }): void
  selectedCombo?: ComboDefinition
  billingType?: BILLING_TYPES_CODES
  selectedPerformer?: Reference
  isReadonlyItem?(itemId: string): boolean
}

export { ExtraPanelSelection }
