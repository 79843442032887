import type { FC } from "react"

import { LabComboDetails as ComboDetails } from "commons/labs"
import { getMoneyCurrencyAlt } from "utils"

import { useLabPanels } from "../../../hooks"
import type { LabCombo } from "../../../types"

const LabComboDetails: FC<Props> = ({ labCombo, onHide, showPrice, organizationId }) => {
  const { labPanels: tests, isLoadingLabPanels } = useLabPanels(
    organizationId,
    { comboPD: labCombo.combo.id },
    !!labCombo.combo.id,
  )

  return (
    <ComboDetails
      definition={labCombo.combo}
      tests={tests.flatMap((p) => ({ ...p, price: { value: p.price, currency: "USD" } }))}
      onHide={onHide}
      headerSlot={
        <div className="flex justify-end font-semibold text-gray-600 mb-4">
          Fee:
          <span className="ml-1">
            {getMoneyCurrencyAlt("USD")}
            {(labCombo?.fee ?? 0).toFixed(2)}
          </span>
        </div>
      }
      isLoadingPanels={isLoadingLabPanels}
      showPrice={showPrice}
      price={{ value: labCombo.price, currency: "USD" }}
    />
  )
}

type Props = {
  labCombo: LabCombo
  showPrice?: boolean
  organizationId: string
  onHide(): void
}

export { LabComboDetails }
