import { useInfiniteQuery } from "@tanstack/react-query"
import {
  type CodeableConcept,
  type Device,
  type HealthcareService,
  type Location,
  type Practitioner,
  type Reference,
  asReference,
  getResources,
  getResourcesByTypeAsIndex,
  isDevice,
  isLocation,
  isOrganization,
  isPlanDefinition,
  isPractitioner,
  isPractitionerRole,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { PD_ACTION_DYNAMIC_VALUE } from "data"

import { settingsQueryKeys } from "../../query-keys"
import { type AppointmentType, APPOINTMENT_TYPE_CHARACTERISTIC } from "../../types"

const useAppointmentTypes = ({
  enabled,
  filters,
  organizationId,
}: {
  enabled?: boolean
  organizationId: string
  filters?: { searchText?: string; room?: string; practitioner?: string; device?: string }
}) => {
  const { search } = useClient()
  const queryKey = settingsQueryKeys.appointment({ organizationId, ...filters })

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filterParams = new URLSearchParams({
        ...(filters?.searchText ? { name: filters?.searchText } : {}),
        _count: "50",
        _page: `${pageParam}`,
        _sort: "name",
        _include: "participant",
        organization: organizationId,
        active: "true",
      })

      const bundle = await search({ endpoint: "HealthcareService", filters: filterParams, signal })

      const appointmentTypes = getResources<HealthcareService>(bundle, "HealthcareService")
      const rooms = getResourcesByTypeAsIndex<Location>(bundle, "Location")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const devices = getResourcesByTypeAsIndex<Device>(bundle, "Device")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined
      const total = bundle.total ?? 0

      return { next, total, appointmentTypes, rooms, practitioners, devices }
    },
    meta: { context: { queryKey, ...filters } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    enabled,
  })

  const defaultData = {
    appointmentTypes: Array<HealthcareService>(),
    indexedRooms: {} as Record<string, Location>,
    indexedPractitioners: {} as Record<string, Practitioner>,
    indexedDevices: {} as Record<string, Device>,
  }

  const { appointmentTypes, appointmentTypeRefs } = useMemo(() => {
    const { appointmentTypes, indexedPractitioners, indexedRooms, indexedDevices } =
      data?.pages.reduce((acc, page) => {
        return {
          ...acc,
          appointmentTypes: [...acc.appointmentTypes, ...page.appointmentTypes],
          indexedRooms: { ...acc.indexedRooms, ...page.rooms },
          indexedPractitioners: { ...acc.indexedPractitioners, ...page.practitioners },
          indexedDevices: { ...acc.indexedDevices, ...page.devices },
        }
      }, defaultData) ?? defaultData

    const defaultTypeData = {
      practitioners: Array<Reference>(),
      rooms: Array<Reference>(),
      devices: Array<Reference>(),
    }
    const defaultCharacteristics = {
      telemedicine: false,
      patientCanBook: false,
      color: undefined as string | undefined,
    }

    const types = appointmentTypes.reduce((acc, apptType) => {
      const { practitioners, devices, rooms } =
        apptType.participant?.reduce((participants, participant) => {
          if (isPractitioner(participant)) {
            const part = indexedPractitioners[participant.id as string]
            return {
              ...participants,
              practitioners: [...participants.practitioners, ...(part ? [asReference(part)] : [])],
            }
          } else if (isLocation(participant)) {
            const part = indexedRooms[participant.id as string]
            return { ...participants, rooms: [...participants.rooms, ...(part ? [asReference(part)] : [])] }
          } else if (isDevice(participant)) {
            const part = indexedDevices[participant.id as string]
            return { ...participants, devices: [...participants.devices, ...(part ? [asReference(part)] : [])] }
          }
          return { ...participants }
        }, defaultTypeData) ?? defaultTypeData

      const { telemedicine, patientCanBook, color } =
        apptType.characteristic?.reduce((acc, char) => {
          if (char.coding?.some(({ code }) => code === APPOINTMENT_TYPE_CHARACTERISTIC.telemedicine))
            return { ...acc, telemedicine: true }
          if (char.coding?.some(({ code }) => code === APPOINTMENT_TYPE_CHARACTERISTIC.patientCanBook))
            return { ...acc, patientCanBook: true }
          if (char.coding?.some(({ system }) => system === APPOINTMENT_TYPE_CHARACTERISTIC.color))
            return { ...acc, color: char.coding?.[0]?.code }

          return { ...acc }
        }, defaultCharacteristics) ?? defaultCharacteristics

      const actions = isPlanDefinition(apptType.contained?.[0])
        ? apptType.contained?.[0]?.action?.reduce(
            (acc, act) => {
              if (act.code?.[0]?.coding?.some(({ code }) => code === "questionnaires")) {
                return {
                  ...acc,
                  withQuestionnaires: true,
                  questionnaires: act.action?.reduce((qs, { definition, description }) => {
                    return [
                      ...qs,
                      ...(definition?.canonical
                        ? [{ id: definition?.canonical, display: description, resourceType: "Questionnaire" }]
                        : []),
                    ]
                  }, Array<Reference>()),
                }
              }

              if (act.code?.[0]?.coding?.some(({ code }) => code === "labs")) {
                const reasonCodes: CodeableConcept[] = JSON.parse(
                  act.dynamicValue?.find(({ path }) => path === PD_ACTION_DYNAMIC_VALUE.REASON_CODE)?.expression
                    ?.expression ?? "[]",
                )

                const requester = act.participant?.find((part) =>
                  isPractitionerRole(part.r5_typeReference),
                )?.r5_typeReference

                const provider = act.participant?.find((part) =>
                  isOrganization(part.r5_typeReference),
                )?.r5_typeReference

                const combos = act.action?.reduce((combosAcc, { definition, description }) => {
                  return [
                    ...combosAcc,
                    ...(definition?.canonical
                      ? [{ id: definition.canonical, display: description, resourceType: "PlanDefinition" }]
                      : []),
                  ]
                }, Array<Reference>())

                return { ...acc, withLabs: true, icd10: reasonCodes, requester, provider, combos }
              }

              return acc
            },
            { withLabs: false, withQuestionnaires: false } as Pick<
              AppointmentType,
              "combos" | "questionnaires" | "withLabs" | "withQuestionnaires" | "icd10" | "provider" | "requester"
            >,
          )
        : undefined

      return [
        ...acc,
        {
          ...apptType,
          telemedicine,
          patientCanBook,
          duration: apptType.duration?.value,
          practitioners,
          devices,
          rooms,
          color,
          daysInAdvance: apptType?.notAvailable?.[0]?.timeInAdvance?.value,
          ...(actions ?? {}),
        },
      ]
    }, Array<AppointmentType>())

    return { appointmentTypes: types, appointmentTypeRefs: types.flatMap((t) => asReference(t)) }
  }, [data?.pages])

  return { appointmentTypes, appointmentTypeRefs, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage }
}

export { useAppointmentTypes }
