import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type ServiceRequest, codeableConceptAsString } from "fhir"
import type { FC } from "react"

import { Accordion } from "commons"
import type { LaboratoryOrderCombo, LaboratoryOrderPanel } from "commons/labs"

import { LaboratoryOrderPanelItem } from "./LaboratoryOrderPanelItem"

const LabOrderComboItem: FC<Props> = ({ combo, showPrice, isInsurance, onDelete }) => {
  const renderComboPanels = (combo: LaboratoryOrderCombo) => (
    <>
      {combo.panels.map((panel: LaboratoryOrderPanel) => (
        <LaboratoryOrderPanelItem
          key={panel.profile?.id ?? codeableConceptAsString(panel.profile.code)}
          panel={panel}
          isReadonly
          showPrice={false}
        />
      ))}
    </>
  )

  return (
    <Accordion
      data={[combo]}
      contentTemplate={renderComboPanels}
      headerTemplate={(item) => (
        <LaboratoryOrderPanelItem
          panel={{ profile: item.laboratoryCombo, price: item.price }}
          onDelete={() => onDelete?.(item.laboratoryCombo)}
          showPrice={showPrice}
          lastElementClass=""
          isInsurance={isInsurance}
        />
      )}
      headerClassName="paddingless"
      expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" className="mr-3" />}
      collapseIcon={<FontAwesomeIcon icon={faChevronUp} size="sm" className="mr-3" />}
      pt={{
        accordiontab: {
          headerAction: { className: "flex-row-reverse" },
        },
      }}
    />
  )
}

type Props = {
  combo: LaboratoryOrderCombo
  showPrice?: boolean
  isInsurance?: boolean
  onDelete?(comboSR: ServiceRequest): void
}

export { LabOrderComboItem }
