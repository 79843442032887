import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { consentsQueryKeys } from "../query-keys"
import type { AvailableConsents } from "../types"
import { isValidGenderConsent } from "../utils"

const useAvailableConsents = (patientId: string, gender?: string) => {
  const { search } = useClient()
  const { enabledConsents } = useOrganizationContext()

  const queryKey = consentsQueryKeys.availables(patientId)
  const consentCodings = useMemo(
    () => enabledConsents.filter(({ flag }) => isValidGenderConsent(flag, gender)).flatMap(({ coding }) => coding),
    [enabledConsents],
  )

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-consents",
        patient: patientId,
        categories: JSON.stringify(consentCodings),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Consent`, filters, signal })

      const availableConsents =
        bundle.entry?.reduce<AvailableConsents[]>((acc, entry) => [...acc, entry.resource as AvailableConsents], []) ??
        []

      const areAnyAvailable = availableConsents?.length > 0

      return { availableConsents, areAnyAvailable }
    },
    meta: { context: { queryKey } },
  })

  return {
    availableConsents: data?.availableConsents,
    areAnyConsentAvailable: data?.areAnyAvailable ?? false,
    isLoadingAC: isLoading,
  }
}

export { useAvailableConsents }
