import { useQuery } from "@tanstack/react-query"
import { type AllergyIntolerance, type CarePlan, getResource, getResources } from "fhir"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useCPManualAllergies = (patientId: string, planId: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.manualAllergies(patientId, planId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _include: "outcome-reference:AllergyIntolerance",
        _elements: "id",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const carePlan = getResource<CarePlan>(bundle, "CarePlan")
      const allergies = getResources<AllergyIntolerance>(bundle, "AllergyIntolerance")

      return {
        carePlan,
        allergies,
      }
    },
    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  return {
    isLoading,
    carePlan: data?.carePlan,
    allergies: data?.allergies,
  }
}

export { useCPManualAllergies }
