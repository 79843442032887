import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { coverageQueryKeys } from "../query-key"

const useDeleteRelatedPerson = (onSuccess: (deletedRpId: string | undefined) => void, onSettled: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (relatedPersonId: string) => remove("RelatedPerson", relatedPersonId)

  const { mutate: deleteRelatedPerson, isPending: isDeleting } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { relatedPersonId: id }))
    },
    onSuccess: async ({ id }) => {
      await queryClient.invalidateQueries({ queryKey: coverageQueryKeys.relatedPerson.all })
      onSuccess(id)
      displayNotificationSuccess("Related person deleted successfully!")
      // datadogLogs.logger.info("Related person deleted successfully!", { id })
    },
    onSettled,
  })

  return { deleteRelatedPerson, isDeleting }
}

export { useDeleteRelatedPerson }
