import { useInfiniteQuery } from "@tanstack/react-query"
import { type FamilyMemberHistory, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { familyHistoryQueryKeys } from "../query-keys"

const useFamilyHistory = (patientId: string, encounter?: string) => {
  const { search } = useClient()
  const queryKey = familyHistoryQueryKeys.history(patientId, encounter)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    FamilyHistoryQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        "status:not": "entered-in-error, cancelled",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/FamilyMemberHistory`, filters, signal })

      const familyMemberHistories = getResources(bundle) as FamilyMemberHistory[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { familyMemberHistories, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    throwOnError: true,
  })

  const { familyMemberHistories, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.familyMemberHistories)
    const count = newData?.length

    return {
      familyMemberHistories: newData,
      count,
    }
  }, [data?.pages])

  return {
    familyMemberHistories,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type FamilyHistoryQueryData = {
  familyMemberHistories: FamilyMemberHistory[]
  next: number | undefined
  total: number
}

export { useFamilyHistory }
