import { useContext } from "react"

import type { CustomError } from "commons"

import { AuthContext } from "../context/AuthProvider"

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider", {
      cause: { name: "404", message: "useAuth must be used within a AuthProvider" },
    } as CustomError)
  }

  return context
}

export { useAuth }
