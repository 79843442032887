import type {
  ActivityDefinition,
  Appointment,
  CarePlan,
  CarePlanActivityArray,
  CodeableConcept,
  Coding,
  Composition,
  Condition,
  MedicationKnowledge,
  MedicationRequest,
  Money,
  Observation,
  PlanDefinition,
  PlanDefinitionActionArrayActionArray,
  Reference,
  ServiceRequest,
  Task,
  TaskRestriction,
} from "fhir"

import type { QuestionnaireData } from "../types"

export type PlanData = {
  carePlan: CarePlan
  originalDefinitionToken?: string
  definitionToken: string
  planDefinition: PlanDefinition
  billingType?: string
  order?: ServiceRequest
  panels?: string[]
  combo?: string
  mailTasks?: Record<string, MailTaskData>
  appointment?: Appointment
  icd10?: CodeableConcept[]
  extraPlanDefinition?: PlanDefinition[]
  performer?: Reference
  mcActivity?: CarePlanActivityArray
  bloodDrawnInOffice?: boolean
  specimenDate?: string
  questionnairesCanonicals?: string[]
  questionnairesAnswered?: QuestionnaireData[]
  combos: ComboDefinition[]
  suggestedPanels?: PanelDefinition[]
  performers?: Reference[]
  algorithmActivity?: CarePlanActivityArray[]
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  nutraceutical?: MedicationRequest[]
  rx?: MedicationRequest[]
  planActivityDefinitions: Record<string, ActivityDefinition>
  requester?: Reference
}

export type TaskOutput = {
  label: string
  canonical?: string
  reusable?: boolean
  activityIndex?: number
  subactionValue?: Reference
}

export type MailTaskData = {
  taskId: string
  restriction?: TaskRestriction
  label: string
  activityIndex: number
  enabled: boolean
  isRequired: boolean
  daysBeforeToMail?: number
  taskOutput: TaskOutput[]
  showable?: boolean
  editable?: boolean
  isConsent?: boolean
}

export type Plan = {
  definitionToken: string
  definition: PlanDefinition
  combos: ComboDefinition[]
  panels: PanelDefinition[]
  performer?: Reference[]
  daysBeforeToMail?: number
  catalogs?: Record<string, Composition>
  algorithmActivity?: CarePlanActivityArray[]
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  nutraceutical?: MedicationKnowledge[]
  rx?: MedicationKnowledge[]
  nutraCodes?: string[]
  rxCodes?: string[]
}

export type ComboDefinition = {
  canonical: string
  definition: PlanDefinition
  canonicalPanels: string[]
  panels: PlanDefinition[]
  price?: Money
  performer: Reference
  promoCoding?: Coding
}

export type PanelDefinition = {
  definition: PlanDefinition
  performer: Reference
}

export type CarePlanHistory = {
  carePlan: CarePlan
  appointment?: Appointment
  reviewTasks?: Task[]
  candidateTask?: Task
}

export enum AppointmentAction {
  None,
  Complete = 1,
  Enroll = 2,
  CompleteAndEnroll = 3,
}

export type PanelItemDisplay = {
  isCombo?: boolean
  price?: Money
  display: string
  info?: string
  planDefinition: PlanDefinition
  panels?: PlanDefinition[]
}

export enum PLAN_SUBVIEWS {
  DETAILS = "plan-details",
  CONFIG = "plan-configuration",
  ENROLL = "plan-enrollment",
}

export enum PlanContext {
  MC = "mc",
  PLANS = "plans",
  MC_ORDERS = "mc-orders",
  MC_ASSESSMENTS = "mc-assessments",
}

export enum PLAN_ACTION_CODES {
  CONFIGURE_LABS = "request-lab-order",
  CONFIGURE_ALGORITHM = "request-algorithm",
  CONFIGURE_QUESTIONNAIRES = "send-questionnaire",
  CONFIGURE_EMAILS = "send-email",
  CONFIGURE_NUTRAS = "request-nutraceutical",
  CONFIGURE_RXS = "request-rx",
  CONFIGURE_COMBO = "configure-combo",
  CONFIGURE_PANELS = "configure-panel",
  CONFIGURE_PROCEDURE = "request-procedure",
  PROCEDURE_CANDIDATE = "candidate-procedure",
  CLINICAL_PROTOCOL = "define-clinical-protocol-plan",
  CONFIGURE_MC = "request-mc",
  SEND_CONSENT = "send-consent",
  MANUAL_CONDITION = "add-manual-condition",
  MANUAL_ALLERGY = "add-manual-allergyintolerance",
  MANUAL_MED_STATEMENT = "add-manual-medicationstatement",
}

export const PLAN_TYPES = {
  CLINICAL_PROTOCOL: "clinical-protocol",
  FOLLOW_UP: "follow-up",
}

export const TASK_CODES = {
  REVIEW: "review-plan-item",
  SEND_EMAIL: "send-scheduled-email",
  SEND_CONSENT: "send-scheduled-consent",
}

export enum PLAN_DETAILS_TABS {
  FORMS = "forms",
  INTAKE = "intake",
  LABS = "labs",
  NUTRACEUTICALS = "nutraceuticals",
  PHARMACEUTICALS = "pharmaceuticals",
  RECOMMENDATIONS = "recommendations",
  ASSESMENTS = "assessments",
}

export type Intake = {
  questionnairesData: QuestionnaireData
  conditions?: Condition[]
  observations?: Observation[]
}
