import { useQuery } from "@tanstack/react-query"
import { type Questionnaire, type QuestionnaireResponse, getResources } from "fhir"

import { useClient } from "api"
import { useMemo } from "react"

import { commonsQueryKeys } from "../query-keys"

const useQRsByCanonical = (
  patientId: string,
  canonicals?: string[],
  status: string[] = ["in-progress", "completed"],
  enabled: boolean = true,
) => {
  const { search } = useClient()
  const { data, isLoading, refetch } = useQuery({
    queryKey: commonsQueryKeys.qDataByCanonical(patientId, canonicals, status),
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        subject: patientId,
        ...(canonicals?.length ? { questionnaire: canonicals?.join(",") } : {}),
        status: status.join(","),
        _include: "questionnaire:Questionnaire",
        _count: "100",
      })

      const bundle = await search({ endpoint: "QuestionnaireResponse", filters, signal })
      const questionnaireResponse = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const questionnaire = getResources<Questionnaire>(bundle, "Questionnaire")

      return { questionnaireResponse, questionnaire, total: bundle.total }
    },
    enabled: !!canonicals?.length && enabled,
  })

  const questionnaireData = useMemo(
    () =>
      data?.questionnaire.reduce(
        (acc, q) => {
          const canonical = `${q.url}|${q.version}`
          const qResponse = data.questionnaireResponse.filter(({ questionnaire }) => questionnaire === canonical)
          return { ...acc, [canonical]: { questionnaire: q, qResponse } }
        },
        {} as Record<string, { questionnaire: Questionnaire; qResponse?: QuestionnaireResponse[] }>,
      ),
    [data],
  )

  return {
    isLoading,
    questionnaireData,
    qResponses: data?.questionnaireResponse,
    questionnaires: data?.questionnaire,
    refetch,
    total: data?.total ?? 0,
  }
}

export { useQRsByCanonical }
