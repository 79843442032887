import { ClientProvider, QuestionnaireResponseContainer } from "@chartedhealth/survey-components"
import type { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { useClient } from "api"
import { datadogLogs } from "logger"
import { useAuth } from "security"

import "@chartedhealth/survey-components/dist/index.css"

const SurveyContainer: FC<Props> = ({ qResponseId, onFinish, pickedQuestions }) => {
  const { user } = useAuth()
  const { search, update, operationRequest, read, patch } = useClient()
  const [searchParam] = useSearchParams()
  const qrId = qResponseId ?? searchParam.get("qrId") ?? ""

  return (
    <ClientProvider
      apm={datadogLogs.logger}
      loggedInUserResource={user?.linkedResource}
      search={search}
      operationRequest={operationRequest}
      read={read}
      patch={patch}
      update={update}
    >
      <QuestionnaireResponseContainer questionnaireId={qrId} onFinish={onFinish} pickedQuestions={pickedQuestions} />
    </ClientProvider>
  )
}

type Props = { qResponseId?: string; onFinish?(): void; pickedQuestions?: string[] }

export { SurveyContainer }
