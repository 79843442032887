import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Parameters } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { eprescribeQueryKeys } from "../query-keys"

const useHoldMrOrder = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const rescheduleOrder = async (id: string) => {
    return operationRequest<Parameters>({ endpoint: "ServiceRequest", method: "POST", operation: "reschedule", id })
  }

  const { mutate: holdMrOrder, isPending } = useMutation({
    mutationFn: rescheduleOrder,
    onError: (error: CustomError, serviceRequestId) => {
      displayNotificationError(registerErrorTrace(error, { serviceRequestId }))
    },
    onSuccess: async (_, serviceRequestId) => {
      await queryClient.invalidateQueries({
        queryKey: eprescribeQueryKeys.orderDetails.details(serviceRequestId),
        refetchType: "all",
      })
      displayNotificationSuccess("Order placed on hold successfully!")
      // datadogLogs.logger.info(`Order ${serviceRequestId} placed on hold successfully!`, { serviceRequestId })
    },
    onSettled: onSettled,
  })

  return { holdMrOrder, isHolding: isPending }
}

export { useHoldMrOrder }
