import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { ResourceObject } from "fhir"
import { type FieldArrayRenderProps, type FormikValues, ErrorMessage, FieldArray } from "formik"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type ReactNode, useState } from "react"

import { ConfirmDialog, SkeletonLoader } from "commons"
import { Chip } from "primereact/chip"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const ChipListFieldArray = <T extends ResourceObject | FormikValues>({
  field,
  getItemText,
  emptyDataMessage,
  confirmDeleteItemText,
  onRemoveItem,
  children,
  askDeleteConfirmation = true,
  className,
  disabled,
  isLoading,
  hideEmptyMessage,
  icon,
  itemLabel = "item",
}: Props<T>) => {
  const [deleteIndex, setDeleteIndex] = useState<number>()

  return (
    <FieldArray name={field}>
      {(props: FieldArrayRenderProps) => {
        const {
          remove,
          name,
          form: { getFieldMeta },
        } = props

        const fieldValue = getFieldMeta<T[] | undefined>(field).value
        const fError = getFieldMeta(field).error
        const fTouched = getFieldMeta(field).touched

        const removeItem = (index: number) => {
          Array.isArray(fieldValue) && fieldValue[index] && onRemoveItem?.(fieldValue[index], props)
          remove(index)
        }

        return (
          <div className={className}>
            {askDeleteConfirmation && (
              <ConfirmDialog
                confirmText={
                  confirmDeleteItemText ?? `Are you sure you want to delete this ${itemLabel.toLowerCase()}?`
                }
                visible={deleteIndex !== undefined}
                hideDialog={() => setDeleteIndex(undefined)}
                actionName="Delete"
                onConfirm={() => {
                  removeItem(deleteIndex as number)
                }}
              />
            )}
            {children?.(props)}
            {isLoading ? (
              <SkeletonLoader loaderType="two-lines" repeats={2} />
            ) : fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0 ? (
              <ul className="@container flex flex-wrap gap-x-3 gap-y-1 min-h-max pt-3 overflow-hidden w-full grow">
                {fieldValue.map((item: T, index: number) => (
                  <div
                    key={`chip-item-${index}-${item.id}`}
                    className={classNames({ "pb-4": fError?.[index] !== undefined })}
                  >
                    <Chip
                      label={getItemText(item)}
                      removable={!disabled}
                      onRemove={() => (askDeleteConfirmation ? setDeleteIndex(index) : removeItem(index))}
                      className={classNames("text-xs bg-white border border-gray-500 whitespace-nowrap", {
                        "pb-0": fError?.[index] !== undefined,
                      })}
                      pt={{ root: { className: "px-1.5" }, label: { className: "my-0.5" } }}
                      removeIcon={
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="hover:text-primary-hover hover:bg-slate-300/70 cursor-pointer rounded-full ml-1 py-0.5 px-1"
                        />
                      }
                    />
                    <ErrorMessage name={`${name}[${index}]`}>
                      {(msg) => typeof msg === "string" && <small className="p-error">{msg}</small>}
                    </ErrorMessage>
                  </div>
                ))}
              </ul>
            ) : (
              !hideEmptyMessage && (
                <div
                  className={classNames("flex flex-col items-center justify-center w-full min-h-max py-8 flex-1", {
                    "border rounded-md border-red-600 mt-0.5": fError && fTouched,
                  })}
                >
                  {icon && <FontAwesomeIcon icon={icon} size="2xl" className="text-slate-500 mb-2" />}
                  <p className="text-slate-500 text-xs">
                    {emptyDataMessage ?? `No ${pluralize(itemLabel.toLowerCase(), 2)} added yet`}
                  </p>
                </div>
              )
            )}
            <div className="flex items-start p-error h-4 mt-1">
              <ErrorMessage name={name}>
                {(msg) =>
                  typeof msg === "string" && (
                    <small id={`errorMessage.${field}`} className="p-error">
                      {msg}
                    </small>
                  )
                }
              </ErrorMessage>
            </div>
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props<T> = {
  field: string
  itemLabel?: string
  getItemText(item: T): string
  confirmDeleteItemText?: string
  children?(props: FieldArrayRenderProps): ReactNode
  onRemoveItem?(item: T, props: FieldArrayRenderProps): void
  className?: string
  askDeleteConfirmation?: boolean
  hideEmptyMessage?: boolean
  emptyDataMessage?: string
  disabled?: boolean
  isLoading?: boolean
  icon?: IconDefinition
}

export { ChipListFieldArray }
