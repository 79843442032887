import type { MedicationKnowledge } from "fhir"
import { useFormikContext } from "formik"
import { type FC, useCallback, useMemo, useState } from "react"

import { AutocompleteMedicationField, useMedicationAvailability } from "commons/meds"
import { MEDICATION_CATALOG } from "data"
import { getCommonCode } from "utils"

const MedicationField: FC<Props> = ({ field, label, mksOnInventoryCodes, validateDuplicate = true }) => {
  const [prevValue, setPrevValue] = useState<string>()

  const { getFieldMeta, errors } = useFormikContext()

  const {
    initialValue: fieldInitialValue,
    error: fieldError,
    value: fieldValue,
  } = useMemo(() => getFieldMeta(field), [errors])

  const sku = useMemo(
    () =>
      getCommonCode({
        codes: (fieldValue as MedicationKnowledge | undefined)?.code?.coding,
        fallback: "",
      }),
    [fieldValue],
  )
  const { refetchMedAvailability } = useMedicationAvailability(sku, 1)

  const validateField = useCallback(
    async (value: MedicationKnowledge) => {
      if (!value?.code?.coding?.[0]?.code) return `This ${label?.toLocaleLowerCase()} is required`
      if (validateDuplicate && fieldInitialValue !== value && prevValue !== value) {
        const { data } = await refetchMedAvailability()
        const mkOnInventory = data?.medAvailability
        if (mkOnInventory) return EXIST_MK_ERROR
        setPrevValue(sku)
        return undefined
      }

      // Check if it's duplicated if not then no errors
      return String(fieldError).localeCompare(EXIST_MK_ERROR) === 0 ? fieldError : undefined
    },
    [prevValue, fieldError, sku],
  )

  return (
    <AutocompleteMedicationField
      field={field}
      label={label}
      appendTo={null}
      panelClassName="max-w-min"
      excludeCodes={mksOnInventoryCodes}
      validate={validateField}
      category={MEDICATION_CATALOG.NUTRA}
    />
  )
}

const EXIST_MK_ERROR = "This medication is already on inventory"

type Props = {
  label?: string
  validateDuplicate?: boolean
  field: string
  mksOnInventoryCodes?: string[]
}

export { MedicationField }
