import type { MedicationKnowledge } from "fhir"
import { useReducer } from "react"

const reducer = (
  state: State,
  { type, payload }: { type: "setCatalog" | "setFilter" | "setMK"; payload: string | MedicationKnowledge | undefined },
): State => {
  switch (type) {
    case "setCatalog":
      return { ...state, selectedCatalog: payload as string }
    case "setFilter":
      return { ...state, searchFilter: payload as string }
    case "setMK":
      return { ...state, selectedMK: payload as MedicationKnowledge }
    default:
      return state
  }
}

const useMedCatalogReducerState = (catalog?: string) => {
  const [{ selectedCatalog, searchFilter, selectedMK }, dispatch] = useReducer(reducer, { selectedCatalog: catalog })

  const setSelectedCatalog = (catalog?: string) => dispatch({ type: "setCatalog", payload: catalog })

  const setSearchFilter = (searchFilter?: string) => dispatch({ type: "setFilter", payload: searchFilter })

  const setSelectedMK = (selectedMK?: MedicationKnowledge) => dispatch({ type: "setMK", payload: selectedMK })

  return {
    selectedCatalog,
    searchFilter,
    selectedMK,
    setSelectedCatalog,
    setSearchFilter,
    setSelectedMK,
  }
}

type State = {
  selectedCatalog?: string
  searchFilter?: string
  selectedMK?: MedicationKnowledge
}

export { useMedCatalogReducerState }
