import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"

import type { Contents } from "../types"
import { PrescriptionsContainer } from "./PrescriptionsContainer"
import { PrescriptionsOrdersContainer } from "./PrescriptionsOrdersContainer"
import { PrescriptionsRefillsContainer } from "./PrescriptionsRefillsContainer"

const EPrescribeContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { appSubModules } = useAppModuleContext()
  const { activeSubModule, setActiveSubModule } = useChartContext()

  const updateContent = (activeContent?: Contents) => {
    if (activeContent && activeContent !== "prescriptions") searchParams.set("subview", activeContent)
    else searchParams.delete("subview")

    setSearchParams(searchParams)
  }

  useEffect(() => {
    let subModule = null

    const subviewParam = searchParams.get("order")
      ? ModulesId.EPRESCRIBE_ORDER
      : searchParams.get("edit-order")
        ? ModulesId.EPRESCRIBE_ORDER_EDIT
        : searchParams.get("subview")

    if (subviewParam) {
      subModule = appSubModules.eprescribe[subviewParam]
    } else {
      searchParams.delete("subview")
      setSearchParams(searchParams)
    }

    setActiveSubModule(subModule)
  }, [searchParams, setActiveSubModule, setSearchParams, appSubModules])

  if (activeSubModule) {
    return activeSubModule.renderContent()
  }

  if (searchParams.get("subview") === "orders") {
    return <PrescriptionsOrdersContainer activeContent="orders" onUpdateContent={updateContent} />
  } else if (searchParams.get("subview") === "history") {
    return <PrescriptionsOrdersContainer activeContent="history" onUpdateContent={updateContent} />
  } else if (searchParams.get("subview") === "refills") {
    return <PrescriptionsRefillsContainer activeContent="refills" onUpdateContent={updateContent} />
  }

  return <PrescriptionsContainer activeContent="prescriptions" onUpdateContent={updateContent} />
}

export { EPrescribeContainer }
