import { format } from "date-fns/format"
import type { ServiceRequest } from "fhir"

import { formatsByTypes } from "data"

const getOrderDate = (order?: ServiceRequest) => {
  let dateString = order?.occurrence?.dateTime ?? order?.authoredOn
  if (!dateString) return "Unspecified"

  if (!dateString.includes("T")) dateString += "T00:00:00"
  return format(new Date(dateString), formatsByTypes.LONG_DATE)
}

export { getOrderDate }
