import { type FormikHelpers, Formik } from "formik"

import type { OrderFilter } from "../types"
import { OrdersFilters } from "./OrdersFilters"

const OrdersFiltersFormContiner = ({ initialValues, onSearch, onClearFilters }: Props) => (
  <Formik initialValues={initialValues} onSubmit={onSearch}>
    <OrdersFilters onClearFilters={onClearFilters} />
  </Formik>
)

type Props = {
  initialValues: OrderFilter
  onSearch(filters: OrderFilter, formikHelpers: FormikHelpers<OrderFilter>): void
  onClearFilters(): void
}

export { OrdersFiltersFormContiner }
