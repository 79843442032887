import { useQuery } from "@tanstack/react-query"
import { type Bundle, type Parameters, type Person, getResources } from "fhir"
import { useEffect, useState } from "react"

import { useClient } from "api"

import { patient360QueryKeys } from "../query-keys"
import type { PersonOption } from "../types"

const useCWSearch = (patientId: string) => {
  const { operationRequest } = useClient()
  const queryKey = patient360QueryKeys.cwSearch(patientId)
  const [personsSelection, setPersonsSelection] = useState<PersonOption[]>([])

  const { data, isLoading, error, isError } = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await operationRequest({
        endpoint: "Patient",
        method: "GET",
        operation: "cw-search",
        id: patientId,
      })

      const resultParameters = result as Parameters

      const enrolledBundle = resultParameters.parameter?.find(({ name }) => name === "enrolled")?.resource as Bundle
      const notEnrolledBundle = resultParameters.parameter?.find(({ name }) => name === "not-enrolled")
        ?.resource as Bundle

      const enrolledPersons = getResources<Person>(enrolledBundle, "Person")
      const notEnrolledPersons = getResources<Person>(notEnrolledBundle, "Person")

      return { enrolledPersons, notEnrolledPersons }
    },
    meta: { context: { queryKey } },
  })

  const updatePersonsSelection = (selectedPersons: PersonOption[]) => {
    const selectedSet = new Set(selectedPersons.map(({ id }) => id))
    setPersonsSelection((persons) => persons.map((person) => ({ ...person, selected: selectedSet.has(person.id) })))
  }

  useEffect(() => {
    if (data) {
      const { enrolledPersons, notEnrolledPersons } = data
      setPersonsSelection([
        ...(enrolledPersons?.map((person) => ({ ...person, selected: true })) ?? []),
        ...(notEnrolledPersons?.map((person) => ({ ...person, selected: false })) ?? []),
      ])
    }
  }, [data])

  if (isError) {
    throw error
  }

  return { persons: personsSelection, isLoading, updatePersonsSelection }
}

export { useCWSearch }
