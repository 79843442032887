import type { PlanDefinition } from "fhir"
import { useFormikContext } from "formik"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"

import { BILLING_TYPES_CODES } from "data"
import { getPDReasonCodes, mergeUniqueReasonCodes } from "utils"

import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { type FormFieldBaseProps, FormField } from "../../../forms/FormField"

import { ComboItem, LabComboDetails } from "../../../labs"
import type { ComboDefinition, PlanData } from "../../types"

const ComboSelection = ({ combos, isLoadingPrices, isExemptLabPayment, itemsClassName, ...formFieldProps }: Props) => {
  const [comboDetails, showComboDetails] = useState<ComboDefinition>()
  const [panelsToReplace, setPanelsToReplace] = useState<PlanDefinition[]>([])
  const [prevCombo, setPrevCombo] = useState<string>()

  const {
    values: { panels, combo, performer, billingType, icd10 },
    setFieldValue,
  } = useFormikContext<PlanData>()

  const planCombos = useMemo(
    () => (performer ? combos?.filter((c) => c.performer.id === performer.id) : []),
    [performer, combos],
  )

  const handleComboSelection = (comboItem: ComboDefinition) => {
    /* Get PrevCombo selected and filter panels to replace */
    const prevCPanels = combos?.find((c) => c.canonical === prevCombo)?.canonicalPanels
    const filteredPanels = panels?.filter(
      (p) =>
        !comboItem.canonicalPanels.includes(p) &&
        !planCombos?.some((c) => c.canonical === p) &&
        !prevCPanels?.includes(p),
    )

    setFieldValue("panels", filteredPanels)
  }

  const onComboChange = (comboItem: ComboDefinition) => {
    const replacePanels =
      panels && combo !== comboItem.canonical
        ? comboItem.panels.filter((pd) => panels.includes(`${pd.url}|${pd.version}`))
        : []
    if (replacePanels.length === 0) handleComboSelection(comboItem)
    setPanelsToReplace(replacePanels)
    setPrevCombo(combo)
    const comboIcd10 = getPDReasonCodes(comboItem.definition)

    setFieldValue("order.reasonCode", mergeUniqueReasonCodes(icd10, comboIcd10))

    setFieldValue("combo", combo !== comboItem.canonical ? comboItem.canonical : undefined)
  }

  const cancelComboSelection = () => {
    setFieldValue("combo", prevCombo)
    setPanelsToReplace([])
  }

  const confirmComboSelection = () => {
    handleComboSelection(planCombos?.find((c) => c.canonical === combo) as ComboDefinition)
    setPanelsToReplace([])
  }

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE

  return (
    <>
      {planCombos && planCombos.length > 0 && (
        <FormField field="combos" showInvalidState labelAlign="items-start" {...formFieldProps}>
          {planCombos?.map((comboItem, index) => {
            const { definition, panels, price, canonical } = comboItem
            const tests = panels.flatMap((pd) => ({ planDefinition: pd, display: pd.title ?? "" }))
            return (
              <ComboItem
                key={canonical ?? index}
                combo={{
                  combo: definition,
                  price,
                  laboratoryTests: tests,
                }}
                selectCombo={() => onComboChange(comboItem)}
                selected={combo?.includes(canonical) ?? false}
                isInsuranceOrder={isInsurance}
                hidePrice={isExemptLabPayment}
                disabled={!performer}
                className={classNames("p-1", itemsClassName)}
              />
            )
          })}
        </FormField>
      )}
      {!!comboDetails && (
        <LabComboDetails
          definition={comboDetails.definition}
          onHide={() => showComboDetails(undefined)}
          showPrice={!isExemptLabPayment}
          price={comboDetails.price}
          tests={comboDetails.panels.flatMap((p) => ({ planDefinition: p, display: p.title }))}
          styledPrice={classNames({ "line-through": isInsurance })}
        />
      )}

      <ConfirmDialog
        headerTitle="Confirm panel replacement"
        visible={panelsToReplace.length > 0}
        onConfirm={confirmComboSelection}
        hideDialog={cancelComboSelection}
        waitToFinish
        confirmElement={
          <div className="flex flex-col gap-2">
            <p className="font-semibold">
              The following {pluralize("panel", panelsToReplace.length)} will be replaced by the combo panels
            </p>
            {panelsToReplace.map((panel) => (
              <div key={panel.id} className="pl-2">
                {panel.title}
              </div>
            ))}
          </div>
        }
      />
    </>
  )
}

type Props = {
  combos?: ComboDefinition[]
  isLoadingPrices?: boolean
  isExemptLabPayment?: boolean
  itemsClassName?: string
} & Omit<FormFieldBaseProps, "field">

export { ComboSelection }
