import { faClipboardUser } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"
import { AssessmentsContainer } from "assessments"

class AssessmentsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.ASSESSMENTS
    this.display = "Assessments"
    this.parent = ModulesId.INTAKE
    this.icon = faClipboardUser
    this.content = <AssessmentsContainer />
  }
}

export { AssessmentsSubModule }
