import {
  type IconDefinition,
  faFaceExhaling,
  faHandBackPointRibbon,
  faHeartPulse,
  faRulerVertical,
  faStethoscope,
  faThermometer,
  faWeightScale,
} from "@fortawesome/pro-regular-svg-icons"

import type { ColData } from "./types"

const getVitalName = (key: keyof ColData) => {
  switch (key) {
    case "height":
      return "Height"
    case "weight":
      return "Weight"
    case "heartRate":
      return "Heart Rate"
    case "respiratoryRate":
      return "Respiratory Rate"
    case "oxygenSaturation":
      return "SPO2"
    case "temperature":
      return "Temperature"
    case "bloodPressureDiastolic":
      return " BP Diastolic"
    case "bloodPressureSystolic":
      return "BP Systolic"
  }
}

const VITAL_ICON_MAP: { [key: string]: IconDefinition } = {
  height: faRulerVertical,
  weight: faWeightScale,
  heartRate: faHeartPulse,
  oxygenSaturation: faHandBackPointRibbon,
  respiratoryRate: faFaceExhaling,
  temperature: faThermometer,
  bloodPressure: faStethoscope,
  /* Ionic codes for height and weight */
  "8302-2": faRulerVertical,
  "29463-7": faWeightScale,
}

export { getVitalName, VITAL_ICON_MAP }
