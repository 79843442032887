import { useInfiniteQuery } from "@tanstack/react-query"
import { type BundleEntryArray, type Observation, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { patient360QueryKeys } from "../query-keys"

const usePatientObservations = ({
  patientId,
  category,
  hasQuantity,
  encounter,
  loadCount = 20,
}: {
  patientId: string
  category: string
  encounter?: string
  hasQuantity?: boolean
  loadCount?: number
}) => {
  const { search } = useClient()
  const queryKey = patient360QueryKeys.observations.list(patientId, category, encounter, hasQuantity)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientObservationsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        category,
        "identifier:text": "patient360",
        _count: `${loadCount}`,
        _page: `${pageParam}`,
        _sort: "date",
        ...(encounter ? { encounter } : {}),
        ...(hasQuantity ? { "value-quantity:ne": "0" } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Observation`, filters, signal })

      const observationsEntries = bundle.entry ?? []
      const observations = getResources<Observation>(bundle)

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { observationsEntries, observations, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, category } },
  })

  const { observationsEntries, count, observations } = useMemo(() => {
    let count = 0
    const newData = data?.pages.reduce<PatientObservationsQueryData>(
      (prev, current) => {
        const newObservations = prev?.observationsEntries.concat(current.observationsEntries)
        count += current.observationsEntries.length
        const newObs = data.pages.flatMap(({ observations }) => observations)

        return { observationsEntries: newObservations, observations: newObs, next: current.next, total: current.total }
      },
      { observationsEntries: [], observations: [], next: 0, total: 0 },
    )

    return {
      observationsEntries: newData?.observationsEntries ?? [],
      observations: newData?.observations ?? [],
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    observationsEntries,
    observations,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadObservations: refetch,
  }
}

type PatientObservationsQueryData = {
  observationsEntries: BundleEntryArray[]
  observations: Observation[]
  next: number | undefined
  total: number
}

export { usePatientObservations }
