import type { Coding } from "fhir"

const proceduresQueryKeys = {
  all: ["patient/procedures"] as const,
  list: (patientId: string, textFilter?: string, statusFilter?: string[], encounter?: string, procedureId?: string) => [
    ...proceduresQueryKeys.all,
    patientId,
    ...(textFilter ? [textFilter] : []),
    ...(statusFilter ? [statusFilter] : []),
    ...(encounter ? [encounter] : []),
    ...(procedureId ? [procedureId] : []),
  ],
  algInventory: (medCodes: Coding[]) => ["algInventory", medCodes],
  medInventory: (catalogId?: string) => ["medicationInventory", catalogId],
  calculator: (patientId: string, pdId?: string) => ["procedureCalculator", patientId, pdId],
  catalogs: ["pelletCatalogs"],
  pd: {
    all: ["procedurePDFromPlan"] as const,
    pdAndQr: (carePlanId?: string | null) => [...proceduresQueryKeys.pd.all, carePlanId],
  },
  definitions: ["procedurePlanDefinitions"],
}

export { proceduresQueryKeys }
