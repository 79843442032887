import type { Observation, Reference } from "fhir"
import * as Yup from "yup"

import { referenceValidationSchema } from "utils"
import { SYSTEM_VALUES } from "system-values"

import type { Laboratory } from "../types"

const getObservationInitialValues = (
  patient: Reference,
  practitioner?: Reference,
  encounter?: Reference,
): Observation => ({
  code: { coding: undefined },
  category: [
    {
      coding: [
        {
          system: SYSTEM_VALUES.OBSERVATION_CATEGORY,
          code: "laboratory",
          display: "Laboratory",
        },
      ],
      text: "Laboratory",
    },
  ],
  status: "final",
  effective: { dateTime: undefined },
  interpretation: [{ coding: undefined }],
  subject: patient,
  performer: practitioner ? [practitioner] : undefined,
  value: { Quantity: { unit: undefined, value: undefined } },
  referenceRange: [{ low: { value: undefined }, high: { value: undefined } }],
  encounter,
})

const sanitizeObservation = ({ ...observation }: Observation) => {
  observation.issued = new Date()
  if (!observation.effective?.dateTime) delete observation.effective
  if (!observation.performer?.length) delete observation.performer
  if (!observation.interpretation?.[0]?.coding) delete observation.interpretation
  if (!observation.referenceRange?.[0].low?.value || !observation.referenceRange?.[0].high?.value)
    delete observation.referenceRange

  return observation
}

const getInitialValues = (patient: Reference, encounter?: Reference): Laboratory => ({
  dr: {
    code: {
      text: "Entered",
      coding: [
        {
          system: SYSTEM_VALUES.TEMPORARY_CODES,
          code: "entered",
          display: "Entered",
        },
      ],
    },
    status: "final",
    effective: { dateTime: undefined },
    issued: undefined,
    subject: patient,
    category: [{ coding: [{ code: "LAB", display: "Laboratory", system: SYSTEM_VALUES.V2_0074 }] }],
    performer: [{ display: undefined }],
    result: undefined,
    encounter,
  },
  results: [],
})

const sanitize = ({ ...lab }: Laboratory) => {
  lab.dr.issued = new Date()

  return lab
}

const validationSchema = Yup.object().shape({
  dr: Yup.object({
    effective: Yup.object().shape({
      dateTime: Yup.date().typeError("Effective is invalid").required("Effective is required"),
    }),
    subject: referenceValidationSchema.defined("Subject must be defined"),
    performer: Yup.array().of(Yup.object({ display: Yup.string().required("Performer is required") })),
  }),
  results: Yup.array().min(1, "At least one result should be provided"),
})

const isValidObservationLab = (lab: Observation) =>
  !!((lab.code.coding?.[0]?.code || lab.code.text) && typeof lab.value?.Quantity?.value === "number")

export {
  getInitialValues,
  sanitize,
  validationSchema,
  getObservationInitialValues,
  sanitizeObservation,
  isValidObservationLab,
}
