import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faIcons } from "@fortawesome/pro-regular-svg-icons"

export enum ModulesId {
  SUMMARY = "summary",
  PATIENT = "patient-information",
  INTAKE = "intake",
  MEDICATIONR = "nutraceuticals",
  LABSORDER = "labs",
  ENCOUNTER = "encounters",
  ENCOUNTER_CONFIG = "encounter-config",
  CALENDAR = "calendar",
  SURVEY = "survey",
  SURVEY_DETAILS = "survay-details",
  COMMUNICATION = "communication",
  MESSAGES = "messages",
  EMAIL = "email",
  VITALS = "vitals",
  LABS = "laboratory",
  CONDITIONS = "conditions",
  ALLERGY = "allergies",
  MEDICATIONE = "medicatione",
  FAMILY_HISTORY = "family-history",
  SOCIAL_HISTORY = "social-history",
  CREDIT_CARDS = "credit-cards",
  INVOICE = "invoice",
  INVOICE_DETAILS = "invoice-details",
  INVOICE_PAYMENT_DETAILS = "invoice-payment-details",
  MC = "mc",
  MC_DETAILS = "mc-details",
  MC_ASSESSTMENTS = "mc-assessment",
  MR_ORDER_HISTORY = "mr-order-history",
  MR_PENDING_ORDERS = "mr-pending-orders",
  MR_DRAFT = "mr-draft",
  MR_RESCHEDULE = "mr-reschedule",
  MEDICATION_ORDER_DETAILS = "medication-order-details",
  LABORATORY_ORDER_DETAILS = "laboratory-order-details",
  LABORATORY_ORDER_CONFIG = "laboratory-order-configuration",
  PATIENT_CONTACTS = "patient-contacts",
  PROCEDURES = "procedures",
  PROCEDURES_DETAILS = "procedures-details",
  PATIENT_COVERAGES = "patient-coverages",
  PATIENT_360 = "patient360",
  VITALS_360 = "vitals360",
  LABS_360 = "laboratory360",
  CONDITIONS_360 = "conditions360",
  ALLERGIES_360 = "allergies360",
  SOCIAL_HISTORY_360 = "socialhistory360",
  FAMILY_HISTORY_360 = "familyhistory360",
  MEDICATION_STATEMENTS_360 = "medicationstatements360",
  EPRESCRIBE = "eprescribe",
  EPRESCRIBE_DRAFT = "eprescribe-draft",
  EPRESCRIBE_ORDER = "eprescribe-order",
  EPRESCRIBE_NEW = "eprescribe-new",
  EPRESCRIBE_ORDER_EDIT = "eprescribe-order-edit",
  PLANS = "plans",
  PLAN_DETAILS = "plan-details",
  PLAN_CONFIG = "plan-configuration",
  PLAN_ENROLL = "plan-enrollment",
  CONSENT = "consent",
  DOSESPOT = "dosespot",
  PATIENT_DOCUMENTS = "patient-documents",
  TELEMEDICINE = "telemedicine",
  CHECKOUT = "checkout",
  CHECKOUT_CONFIG = "checkout-config",
  CHECKOUT_PREVIEW = "checkout-preview",
  CHECKOUT_INVENTORY = "inventory",
  ASSESSMENTS = "assessments",
}

enum ModuleRenderSideType {
  BOTH,
  KP,
  WORKSPACE,
  NONE,
  /* Used for modules that will render under specific conditions when an action triggers */
  DYNAMIC_WORKSPACE,
  DYNAMIC_WORKSPACE_WITH_KP,
}

class Module {
  protected id = ""
  protected display = ""
  protected displayForKP = ""
  protected parent = ""
  protected description = ""
  protected icon: IconDefinition = faIcons

  getId() {
    return this.id
  }

  getDisplay() {
    return this.display
  }

  getIcon() {
    return this.icon
  }

  getParent() {
    return this.parent
  }

  getDescription() {
    return this.description
  }

  getDisplayForKP() {
    return this.displayForKP || this.display
  }
}

class AppModule extends Module {
  protected hideBySetting = false
  protected renderSideType = ModuleRenderSideType.BOTH
  protected kpElement: JSX.Element | null = null
  protected workspaceElement: JSX.Element | null = null

  allowHideBySetting() {
    return this.hideBySetting
  }

  getRenderSideType() {
    return this.renderSideType
  }

  renderKP() {
    return this.kpElement
  }

  renderWorkspace() {
    return this.workspaceElement
  }
}

class SubModule extends Module {
  protected content: JSX.Element | null = null
  protected searchParam = "subview"

  constructor() {
    super()
    this.display = "Details"
  }

  renderContent() {
    return this.content
  }

  getSearchParam() {
    return this.searchParam
  }
}

export type SubModulesIndexTypes =
  | "intake"
  | "invoice"
  | "medicationRequest"
  | "patient"
  | "survey"
  | "patient360"
  | "mc"
  | "plan"
  | "eprescribe"
  | "laboratory"
  | "checkout"
  | "procedures"

export type AppSubModulesTypes = Record<SubModulesIndexTypes, Record<string, SubModule>>

export { AppModule, ModuleRenderSideType, SubModule }
