import { type ProcedureFormTypes, ProcedureFormKind } from "./types"
import { configItemValidationSchema, procedureTypeValidation, procedureValidationSchema } from "./utils/validations"

export const procedureStatus = [
  { code: "preparation", display: "Preparation" },
  { code: "in-progress", display: "In progress" },
  { code: "completed", display: "Completed" },
]

export enum ProcedureProductType {
  pellet = "pellet",
  botox = "botox",
}

export const procedureNoteHtmlTemplate = `
<div><br /></div>
<div
  style="
    border: 1px solid black;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
  "
>
  <div style="grid-column: span 4">
    <ul style="list-style: none; font-style: italic; padding: 0; margin: 0">
      <li style="margin-bottom: 0.25rem">Patient Name: {{patientName}}</li>
      <li style="margin-bottom: 0.25rem">Patient DOB: {{patientDOB}}</li>
      <li style="margin-bottom: 0.25rem">Provider: {{practitionerName}}</li>
      <li style="margin-bottom: 0.25rem">Procedure Date: {{currentDate}}</li>
    </ul>
  </div>
</div>

<br />

<h1 style="font-weight: 500; text-align: center">Procedure Note:</h1>

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Chief Complaint(s) - Subjective
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >{{conditions}}</td>
    </tr>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Current Medications
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
        {{medicationStatements}}
      </td>
    </tr>
  </tbody>
</table>

<br />

<h2 style="font-weight: 500; text-align: left">Physical Exam</h2>

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Height
      </th>
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Weight
      </th>
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        BMI
      </th>
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Temperature
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{patientHeight}}</td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{patientWeight}}</td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{bmi}}</td>
      <td
      style="
        border: 1px solid black;
        padding: 6px 1rem;
        height: 38px;
        max-width: 1px;
        text-align: center;
      "
    >{{patientTemperature}}</td>
    </tr>
  </tbody>
</table>
<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
        >
          Blood Pressure
      </th>
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Pulse
      </th>
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: center;
        "
      >
        Respiration
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{patientBloodPressure}}</td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{patientPulse}}</td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
          text-align: center;
        "
      >{{patientRespiration}}</td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          background-color: #bee9fe;
          width: 30%;
          max-width: 1px;
        "
      >
        General Appearance
      </td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Appears well, no acute distress. </p>
      </td>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          background-color: #bee9fe;
          width: 30%;
          max-width: 1px;
        "
      >
        Respiratory
      </td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>On visual observation, patient is in no respiratory distress.</p>
      </td>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          background-color: #bee9fe;
          width: 30%;
          max-width: 1px;
        "
      >
        Cardiovascular
      </td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Rhythm is regular. There is no peripheral edema, cyanosis or pallor. </p>
      </td>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          background-color: #bee9fe;
          width: 30%;
          max-width: 1px;
        "
      >
        Neurological
      </td>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Alert and oriented x 3.</p>
      </td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Assessment
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      Reviewed last labs, medical history and past notes available;
      </td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Plan
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Subcutaneous hormone pellet implant</p>
      <br/>
      <p>Medication doses and lot numbers below</p>
      <br/
      <p>Patient verbalizes understanding and agrees with plan of care.  After care instructions provided.</p>
      <br/
      <p>EVEXIAS Medical Centers staff is in compliance with the Minimum Standards for Safe Practice.</p>
      <br/
      </td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Procedure
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Informed consent filed, wound care, and follow-up instructions with {{patientFirstName}}. Patient name and dose verified.</p>
      <br/>
      <p>Insertion site was prepped with chloraprep. Local anesthetic of 1% Lidocaine and sodium bicarbonate was injected and infiltrated into insertion site. </p>
      <br/
      <p>3 mm incision was made using a sterile #11 blade. The hormone implants were inserted using a sterile trocar insertion tool. Site closed with steri-strip tape. Gauze pressure bandages applied.</p>
      <br/
      <p>{{patientFirstName}} tolerated procedure well with no adverse reactions. Patient voices understanding of post insertion instructions. </p>
      </td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Medications (Pellet Insertion)
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      ></td>
    </tr>
  </tbody>
</table>

<br />

<table style="border-collapse: collapse; margin: 15px 0; width: 100%">
  <tbody>
    <tr style="background-color: #bee9fe">
      <th
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          font-weight: 500;
          text-align: left;
        "
      >
        Follow-up
      </th>
    </tr>
    <tr>
      <td
        style="
          border: 1px solid black;
          padding: 6px 1rem;
          height: 38px;
          max-width: 1px;
        "
      >
      <p>Return to Office for next pellet round as scheduled</p>
      </td>
    </tr>
  </tbody>
</table>

<br />
<br />

<div style="display: flex; gap: 1rem">
  <table
    style="flex-grow: 1; border-collapse: collapse; margin: 15px 0; width: 100%"
  >
    <tbody>
      <tr style="background-color: #bee9fe">
        <th
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            font-weight: 500;
            text-align: left;
          "
        >
          Annotated by
        </th>
      </tr>
      <tr>
        <td
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            height: 38px;
            max-width: 1px;
          "
        ></td>
      </tr>
    </tbody>
  </table>
  <table
    style="flex-grow: 1; border-collapse: collapse; margin: 15px 0; width: 100%"
  >
    <tbody>
      <tr style="background-color: #bee9fe">
        <th
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            font-weight: 500;
            text-align: left;
          "
        >
          Practitioner Name
        </th>
      </tr>
      <tr>
        <td
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            height: 38px;
            max-width: 1px;
          "
        >{{practitionerName}}</td>
      </tr>
    </tbody>
  </table>
  <table
    style="flex-grow: 1; border-collapse: collapse; margin: 15px 0; width: 100%"
  >
    <tbody>
      <tr style="background-color: #bee9fe">
        <th
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            font-weight: 500;
            text-align: left;
          "
        >
          Practitioner Signature
        </th>
      </tr>
      <tr>
        <td
          style="
            border: 1px solid black;
            padding: 6px 1rem;
            height: 38px;
            max-width: 1px;
          "
        ></td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: left"><br /></div>
`

export const ProcedureForms: Record<string, ProcedureFormTypes> = {
  [ProcedureFormKind.main]: {
    type: ProcedureFormKind.main,
    title: "Configure procedure",
    validationSchema: procedureValidationSchema,
  },
  [ProcedureFormKind.calculator]: {
    type: ProcedureFormKind.calculator,
    title: "Dosage calculator",
    saveLabel: "Calculate",
    validationSchema: procedureTypeValidation,
  },
  [ProcedureFormKind.suggestedMeds]: {
    type: ProcedureFormKind.suggestedMeds,
    title: "Pellets recommendations",
    saveLabel: "Apply procedure",
  },
  [ProcedureFormKind.medication]: {
    type: ProcedureFormKind.medication,
    title: "Configure Medication",
    validationSchema: configItemValidationSchema,
    saveLabel: "Accept",
  },
  [ProcedureFormKind.massage]: {
    type: ProcedureFormKind.massage,
    title: "Configure Massage",
    validationSchema: configItemValidationSchema,
    saveLabel: "Accept",
  },
}
