import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import {
  faAngleLeft,
  faCakeCandles,
  faCircleUser,
  faLocationDot,
  faMobileScreen,
} from "@fortawesome/pro-regular-svg-icons"
import { faGrid } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { getFirstPhone, humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import { classNames } from "primereact/utils"
import { type FC, useMemo, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"

import { useAccountCreditCards } from "account"
import banner from "assets/banner.webp"
import { type ModuleWarningMessage, ChartViewContainer, ModuleHub } from "chart-view"
import { AvatarImage, AzureImage, LoadingView, ModulesId } from "commons"
import { StartVideoCall } from "communication"
import { VideoCallProvider } from "communication/context"
import { formatsByTypes } from "data"
import { EncounterActions } from "encounter"
import { useAppModuleContext } from "internals"
import { CPOEContextProvider, useCountCpoeOrders } from "orders"
import { CPOE_STEPS } from "orders/types"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import {
  calculateAge,
  getDosespotDisableWarningMessage,
  getHomeAddress,
  getHomeAddressIndex,
  getStringAddress,
} from "utils"

import { PatientContextProvider } from "../context/PatientProvider"
import { usePatient } from "../hooks"

enum SIDEBAR_CONTENTS {
  "tools",
  "orders",
}

const PatientView: FC = () => {
  const { patientId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { ordersCount } = useCountCpoeOrders(patientId as string)
  const [sideBar, setSideBar] = useState({
    visible: false,
    width: "28%",
    content: SIDEBAR_CONTENTS.tools,
  })

  const { patient, hasB2CIdentifier, isLoading, reloadPatient } = usePatient(patientId)

  const {
    branding,
    isStripeSetupComplete,
    hasRootStripeType,
    showStripeWarning,
    currentOrganizationId,
    hasDosespotSetup,
    hasPractitionerDosespotSetup,
    isAdmin,
    hasLifefileSystem,
    nutraCatalogs,
    rxCatalogs,
    isLoadingMedicationCatalogs,
    isLoadingLabsFacility,
    performerLabsEnabled,
  } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()

  const { organizationPractitionersInfo, isLoading: isLoadingPractitioners } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const {
    account,
    creditCards,
    isLoading: isLoadingAccount,
    defaultCreditCard,
  } = useAccountCreditCards(patientId as string)

  const hideSideBar = () => setSideBar({ ...sideBar, visible: false })

  const disabledWarningMessages = useMemo<Record<string, ModuleWarningMessage>>(() => {
    //const hasGP = isValidPractitioner(practitionersInfo, patient?.generalPractitioner?.[0]?.id)
    const hasGender = patient?.gender?.includes("male")
    const msg = "⚠️ Missing information:" + (!hasGender ? "\n- This patient doesn't have gender." : "")

    return {
      [ModulesId.DOSESPOT]: {
        isLoading: isLoadingPractitioners,
        message: getDosespotDisableWarningMessage(
          isAdmin,
          hasDosespotSetup,
          hasPractitionerDosespotSetup,
          getHomeAddressIndex(patient?.address) !== -1,
          organizationPractitionersInfo.filter(({ hasDosespotSetup }) => hasDosespotSetup),
        ),
      },
      [ModulesId.MEDICATIONR]: {
        isLoading: isLoadingPractitioners || isLoadingMedicationCatalogs,
        message: !nutraCatalogs.length
          ? "⚠️ Missing information:" +
            (!nutraCatalogs.length
              ? "\n- This organization is not able to prescribe Nutraceuticals medications. Active medication catalog is required."
              : "")
          : "",
      },
      [ModulesId.MC]: {
        isLoading: isLoadingPractitioners,
        message: !hasGender ? msg : "",
      },
      [ModulesId.PLANS]: {
        isLoading: isLoadingPractitioners,
        message: !hasGender ? msg : "",
      },
      [ModulesId.PROCEDURES]: {
        isLoading: isLoadingPractitioners,
        message: !hasGender ? msg : "",
      },
      [ModulesId.EPRESCRIBE]: {
        isLoading: isLoadingPractitioners || isLoadingMedicationCatalogs,
        message:
          !hasLifefileSystem || !rxCatalogs.length
            ? "⚠️ Missing information:" +
              (!hasLifefileSystem
                ? "\n- This organization is not able to prescribe Rx medications. Lifefile identifier is required."
                : "") +
              (!rxCatalogs.length
                ? "\n- This organization is not able to prescribe Rx medications. Active medication catalog is required."
                : "")
            : "",
      },
      [ModulesId.LABSORDER]: {
        isLoading: isLoadingLabsFacility,
        message: !performerLabsEnabled.length
          ? "⚠️ Missing information: \n- This organization is not able to request laboratory orders. Laboratory facility not available."
          : "",
      },
    }
  }, [
    organizationPractitionersInfo,
    patient?.gender,
    isLoadingPractitioners,
    isAdmin,
    hasDosespotSetup,
    hasPractitionerDosespotSetup,
    patient?.address,
    patient?.generalPractitioner,
    isLoadingMedicationCatalogs,
    isLoadingLabsFacility,
  ])

  if (isLoading || isLoadingAccount) {
    return <LoadingView />
  }

  const showCheckout = (step: string = CPOE_STEPS.SUMMARY) => {
    if (isStripeSetupComplete || hasRootStripeType) {
      const params = new URLSearchParams({
        ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
        view: "checkout",
        subview: step,
      })

      setSearchParams(params)
    } else showStripeWarning()
  }

  const { name, photo, birthDate, gender, telecom, address } = patient ?? {}
  const phone = getFirstPhone(telecom)
  const patientHomeAddress = getHomeAddress(address)

  const commonImageStyle = "absolute top-0 left-0 w-full h-full"
  const defaultBanner = <img src={banner} className={commonImageStyle} alt="" />

  const renderSidebarContent = () => {
    switch (sideBar.content) {
      case SIDEBAR_CONTENTS.tools:
        return <ModuleHub onItemClick={hideSideBar} useSideBarStyle disabledWarningMessages={disabledWarningMessages} />

      default:
        return <div>Not implemented yet</div>
    }
  }

  return (
    <PatientContextProvider
      patient={patient}
      hasB2CUser={hasB2CIdentifier}
      refreshPatientData={reloadPatient}
      account={account}
      creditCards={creditCards}
      preferedCreditCard={defaultCreditCard}
    >
      <CPOEContextProvider ordersCount={ordersCount} showCpoeOverlay={(step) => ordersCount > 0 && showCheckout(step)}>
        <div className="banner-container h-24 border-b drop-shadow-sm border-gray-200 patient-chart-guiding">
          <div className="flex relative h-full">
            <div className={classNames("banner", commonImageStyle)}>
              <AzureImage
                url={branding.banner as string}
                className={commonImageStyle}
                fallbackIconNode={defaultBanner}
              />
            </div>
            <div className="flex flex-1 justify-between py-4 pl-5 z-10 pr-8">
              <div className="flex items-center">
                <Link to={`/orgs/${currentOrganizationId}/patients`}>
                  <FontAwesomeIcon icon={faAngleLeft} size="2x" className="text-gray-400 mr-5" />
                </Link>
                <AvatarImage
                  name={humanNameAsString(name?.[0])}
                  photoUrl={photo?.[0]?.url}
                  className="w-16 h-16 rounded-full ring-2 ring-white bg-white"
                />
                <div className="pl-4">
                  <p className="text-2xl font-normal" title="Name">
                    {humanNameAsString(name?.[0])}
                  </p>
                  <p className="text-zinc-500 font-normal text-xs tracking-tight pt-2.5">
                    <span>
                      <FontAwesomeIcon icon={faCircleUser} className="text-sm" />
                      <span className="px-2" title="Age">
                        {calculateAge(birthDate)}
                      </span>
                      <span className="capitalize" title="Biological Sex">
                        {gender}
                      </span>
                    </span>

                    <span className="pl-4" title="Address">
                      <FontAwesomeIcon icon={faLocationDot} className="text-sm" />
                      <span className="px-2" title="Address">
                        {patientHomeAddress ? getStringAddress(patientHomeAddress) : "Unspecified home address"}
                      </span>
                    </span>

                    {birthDate && (
                      <span className="pl-4" title="Birthdate">
                        <FontAwesomeIcon icon={faCakeCandles} className="text-sm" />
                        <span className="px-2" title="Birth date">
                          {format(parseISO(birthDate), formatsByTypes.LONG_DATE)}
                        </span>
                      </span>
                    )}

                    {phone && (
                      <span className="pl-4" title="Phone">
                        <FontAwesomeIcon icon={faMobileScreen} className="text-sm" />
                        <span className="px-2" title="Phone">
                          {phone}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3 justify-end">
                {isModuleActive("encounters") && <EncounterActions />}
                {isModuleActive("telemedicine") && (
                  <VideoCallProvider>
                    <StartVideoCall />
                  </VideoCallProvider>
                )}
                <Button
                  icon={<FontAwesomeIcon icon={faShoppingCart} className="mr-1" />}
                  label="Checkout"
                  disabled={ordersCount === 0}
                  className="button-banner overflow-visible font-normal h-9"
                  onClick={() => showCheckout()}
                >
                  <span
                    className={classNames(
                      "border border-cyan-400 bg-white rounded-full h-5 w-5 flex justify-center items-center absolute -top-3 right-2 z-10 text-[12px] text-red-400 font-bold",
                      { "opacity-50": ordersCount === 0 },
                    )}
                  >
                    {ordersCount}
                  </span>
                </Button>
                <Button
                  icon={<FontAwesomeIcon icon={faGrid} className="text-xl" />}
                  className="button-banner ml-12 h-9"
                  style={{ width: "36px" }}
                  onClick={() => setSideBar({ width: "15%", content: SIDEBAR_CONTENTS.tools, visible: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <ChartViewContainer disabledWarningMessages={disabledWarningMessages} />
        <Sidebar
          visible={sideBar.visible}
          position="right"
          style={{ minWidth: sideBar.width, height: "calc(100% - 3.5rem)" }}
          onHide={hideSideBar}
          modal
          header={
            sideBar.content === SIDEBAR_CONTENTS.orders && (
              <h4 className="text-xl font-bold bg-white w-full">Checkout</h4>
            )
          }
          className="toolsmenu absolute bottom-0"
        >
          {renderSidebarContent()}
        </Sidebar>
      </CPOEContextProvider>
    </PatientContextProvider>
  )
}

export { PatientView }
