import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Task } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { tasksQueryKeys } from "../query-keys"

const useCreateTask = (hideForm: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newTask = async (task: Task) => create("Task", task)

  const { mutate: createTask, isPending: isAdding } = useMutation({
    mutationFn: newTask,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: tasksQueryKeys.all,
      })
      displayNotificationSuccess("Task created successfully!")
      // datadogLogs.logger.info("Task created successfully!", { task })
    },
    onSettled: hideForm,
  })

  return { createTask, isAdding }
}

export { useCreateTask }
