import { useQuery } from "@tanstack/react-query"
import { type Composition, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { labOrdersQueryKeys } from "../query-keys"

const useLaboratoryCatalogs = (laboratoryId?: string) => {
  const { search } = useClient()
  const queryKey = labOrdersQueryKeys.catalogs(laboratoryId)

  const { data, isLoading, isPending } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        author: laboratoryId ?? "",
        _elements: "id,title",
        _count: "100",
        _page: "1",
        _sort: "title",
      })

      const bundle = await search({ endpoint: "Composition", filters, signal })

      const catalogs = getResources<Composition>(bundle)

      return { catalogs, total: bundle?.total ?? 0 }
    },
    enabled: laboratoryId !== undefined,
    meta: { context: { queryKey, laboratoryId } },
  })

  const { laboratoryCatalogs, count } = useMemo(() => {
    const laboratoryCatalogs = data?.catalogs.reduce((prev, catalog) => {
      return [...prev, catalog.id as string]
    }, [] as string[])

    return { laboratoryCatalogs, count: laboratoryCatalogs?.length ?? 0 }
  }, [data?.catalogs])

  return {
    laboratoryCatalogs,
    count,
    isLoading: isLoading || isPending,
  }
}

export { useLaboratoryCatalogs }
