import { useInfiniteQuery } from "@tanstack/react-query"
import { type Appointment, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { patientAptsQueryKeys } from "../query-keys"

const usePatientAppointments = (patientId: string, appointmentId?: string) => {
  const { search } = useClient()
  const queryKey = patientAptsQueryKeys.list(patientId, appointmentId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    AppointmentsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _elements: "id,start,end,description,participant,appointmentType,status,comment,minutesDuration",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        ...(appointmentId ? { _id: appointmentId } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Appointment`, filters, signal })

      const appointments = getResources(bundle) as Appointment[]

      const newAppointments = appointments.map<Appointment>((appointment) => {
        return { ...appointment, ...{ start: appointment.start && new Date(appointment.start) } }
      })

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { appointments: newAppointments, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { appointments, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.appointments)
    const count = newData?.length

    return {
      appointments: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    appointments,
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type AppointmentsQueryData = {
  appointments: Appointment[]
  next: number | undefined
  total: number
}

export { usePatientAppointments }
