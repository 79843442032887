import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type CodeableConcept, type ServiceRequest, codeableConceptAsString } from "fhir"
import { type FieldArrayRenderProps, ErrorMessage, FieldArray, useFormikContext } from "formik"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useState } from "react"

import { AddFieldArrayItemButton } from "commons"
import { useBloodDrawnTests, useLoadProductsPrice } from "commons/hooks"
import { type LaboratoryOrder, type LaboratoryOrderPanel, type PanelInfo, getClasifiedBDPanels } from "commons/labs"
import type { LaboratoryTest } from "commons/types"
import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST } from "data"
import { useOrganizationContext } from "organization"

import { useLaboratoryCatalogs } from "../hooks"
import { LabOrderComboItem } from "./LabOrderComboItem"
import { LaboratoryOrderPanelItem } from "./LaboratoryOrderPanelItem"
import { LaboratoryOrderPanelSelection } from "./LaboratoryOrderPanelSelection"
import { getSrData, sanitizePanel } from "./validations"

const LaboratoryOrderPanels = ({ field }: Props) => {
  const { currentOrganizationId, isExemptLabPayment, labsWithSuppliedPhlebotomistEnabled } = useOrganizationContext()
  const [showPanelSelection, setShowPanelSelection] = useState(false)

  const {
    values: {
      order: { performer, subject, insurance },
      billingType,
      panels: includedPanels,
      combo,
      deletedPanels,
      bloodDrawnInOffice,
    },
    initialValues,
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE
  const isLabOrderWithLabWithSuppliedPhlebotomistEnabled = useMemo(
    () => labsWithSuppliedPhlebotomistEnabled.has(performer?.[0]?.id as string),
    [performer],
  )

  const showNoCatalogsWarning = () => {
    confirmDialog({
      message: "Selected laboratory has no tests available!",
      header: "Information",
      acceptLabel: "Accept",
      rejectClassName: "hidden",
      acceptClassName: "button-primary p-button-sm",
    })
  }

  const { count, isLoading } = useLaboratoryCatalogs(performer?.[0]?.id)
  const { bloodDrawnTests, isLoading: isLoadingBDTests } = useBloodDrawnTests(
    currentOrganizationId,
    DEFAULT_BLOOD_DRAWN_PANELS_LIST,
    billingType as BILLING_TYPES_CODES,
  )

  const isDBIOPanelActive = useCallback(
    (pIdOrCode: string) =>
      !bloodDrawnInOffice &&
      !!(
        bloodDrawnTests &&
        performer?.[0]?.id &&
        bloodDrawnTests[performer[0].id]?.some(
          ({ planDefinition }) =>
            planDefinition.id === pIdOrCode || planDefinition.identifier?.some(({ value }) => pIdOrCode === value),
        )
      ),
    [bloodDrawnTests, performer, bloodDrawnInOffice],
  )

  const getNewBDPanels = useCallback(
    () =>
      bloodDrawnTests?.[performer?.[0]?.id as string]?.reduce((prev, { planDefinition, questionnaires, price }) => {
        const profile = getSrData({
          patient: subject,
          performer: performer ?? [],
          planDefinition,
        })
        return [
          ...prev,
          {
            profile: sanitizePanel(profile),
            planDefinition,
            price,
            questionnaires,
          },
        ]
      }, Array<LaboratoryOrderPanel>()),
    [performer?.[0]?.id],
  )

  useEffect(() => {
    if (!isLoading && !count) showNoCatalogsWarning()
  }, [count, isLoading])

  useEffect(() => {
    if (
      (performer?.[0].id !== combo?.laboratoryCombo.performer?.[0]?.id && !!combo) ||
      performer?.[0].id !== initialValues.order.performer?.[0]?.id
    ) {
      const panelsTodelete = includedPanels.filter((p) => p.profile.id) ?? []
      const comboPanelsToDelete = combo?.panels.filter((p) => p.profile.id) ?? []
      setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...panelsTodelete, ...comboPanelsToDelete])
      setFieldValue("combo", undefined)
      const newBDPanels =
        !bloodDrawnInOffice || isLabOrderWithLabWithSuppliedPhlebotomistEnabled ? getNewBDPanels() : []
      setFieldValue("panels", [...(newBDPanels ?? [])])
    }
  }, [performer?.[0]?.id])

  // When blood drawn changes handle add/delete BloodDrawn panels automatically
  useEffect(() => {
    let panels = [...includedPanels]
    const { bdPanels: panelsToDelete, nobdPanels: updatedPanels } = getClasifiedBDPanels(panels)

    if (!bloodDrawnInOffice || isLabOrderWithLabWithSuppliedPhlebotomistEnabled) {
      if (performer?.[0]?.id && !isLoadingBDTests) {
        const newBDPanels = getNewBDPanels()
        panels = [...updatedPanels, ...(newBDPanels ?? [])]
        setFieldValue("deletedPanels", [...(deletedPanels ?? [])])
      }
    } else {
      const { bdPanels: comboPanelsToDelete } = getClasifiedBDPanels(combo?.panels)
      panels = updatedPanels
      setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...panelsToDelete, ...comboPanelsToDelete])
    }

    setFieldValue("panels", panels)
    setFieldValue("panelsCount", panels.length)
  }, [bloodDrawnInOffice, isLoadingBDTests])

  const { mapProductsPrice } = useLoadProductsPrice(
    currentOrganizationId,
    billingType ?? BILLING_TYPES_CODES.BILL_PATIENT,
    "panels",
    (data) => {
      setFieldValue("panels", data)
    },
  )

  useEffect(() => {
    if (!isExemptLabPayment) mapProductsPrice({ products: [...includedPanels] })
  }, [billingType])

  return (
    <>
      <FieldArray name={field}>
        {({ push, remove, name, form: { values, errors, touched, setFieldValue } }: FieldArrayRenderProps) => {
          const fError = errors[name]
          const fTouched = touched[name]

          const allowAdd =
            performer?.[0]?.id !== undefined && ((isInsurance && insurance?.[0]?.id !== undefined) || !isInsurance)

          const updatePanels = (newCheckedItems: LaboratoryTest[], deletedItems: { id: string; index: number }[]) => {
            for (const pd of newCheckedItems) {
              const profile = getSrData({
                patient: subject,
                performer: performer ?? [],
                planDefinition: pd.planDefinition,
              })
              push({
                profile: sanitizePanel(profile),
                price: pd.price,
                questionnaires: pd.questionnaires,
                planDefinition: pd.planDefinition,
              })
            }

            const getCannonical = ({ planDefinition, profile }: PanelInfo) =>
              planDefinition
                ? `${planDefinition.url}|${planDefinition.version}`
                : (profile?.instantiatesCanonical?.[0] as string)

            for (const { id } of deletedItems) {
              const index = values[field].findIndex((i: PanelInfo) => getCannonical?.(i) === id)
              if (index !== -1) {
                removePanel(index)
              }
            }

            setShowPanelSelection(false)
          }

          const totalPrice = (values.panels.reduce(
            (acc: number, panel: LaboratoryOrderPanel) => acc + (panel.price?.value ?? 0),
            0,
          ) + (values.combo?.price?.value ?? 0)) as number

          const removePanel = (index: number, sr?: ServiceRequest) => {
            const test = values[field]?.[index]?.profile ?? sr
            let comboPanels: LaboratoryOrderPanel[] = []

            if (test.id === combo?.laboratoryCombo.id) {
              comboPanels = combo?.panels.filter((panel) => !!panel.profile.id) ?? []
              setFieldValue("combo", undefined)
              setFieldValue("deletedPanels", [...values["deletedPanels"], ...comboPanels])
            } else if (test.id) {
              setFieldValue("deletedPanels", [...values["deletedPanels"], values[field][index]])
            }
            if (
              (test?.code as CodeableConcept)?.coding?.some(({ code }) =>
                DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string),
              )
            ) {
              setFieldValue("bloodDrawnInOffice", true)
            }

            if (index !== -1) {
              remove(index)
            }
          }

          return (
            <>
              <div className="field flex flex-col space-y-2">
                <AddFieldArrayItemButton
                  disabled={!allowAdd || !count}
                  onClick={() => setShowPanelSelection(true)}
                  label="Select Additional Testing"
                  className="pl-6"
                />

                {!values?.panels?.length && !values.combo ? (
                  <div
                    className={classNames("flex flex-col items-center justify-center w-full py-3", {
                      "border rounded-md border-red-600": fError && fTouched,
                    })}
                  >
                    <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                    <p className="text-slate-500 text-xs pt-1">No panels found</p>
                  </div>
                ) : (
                  <div
                    className={classNames("bg-white flex flex-col overflow-auto grow pl-12", {
                      "border rounded-md border-red-600": fError && fTouched,
                    })}
                  >
                    {values.panels.map((panel: LaboratoryOrderPanel, index: number) => {
                      const isReadonly = isDBIOPanelActive(panel.profile.code?.coding?.[0]?.code as string)
                      return (
                        <LaboratoryOrderPanelItem
                          key={panel.profile?.id ?? codeableConceptAsString(panel.profile.code)}
                          panel={panel}
                          onDelete={() => removePanel(index)}
                          isReadonly={isReadonly}
                          showPrice={!isExemptLabPayment}
                          className={classNames({ "pr-4": isReadonly })}
                          isInsurance={isInsurance}
                          lastElementClass="last:mb-6 border-b last:border-b-0"
                        />
                      )
                    })}
                    {values?.combo && (
                      <LabOrderComboItem
                        combo={values.combo}
                        showPrice={!isExemptLabPayment}
                        isInsurance={isInsurance}
                        onDelete={(sr) => removePanel(-1, sr)}
                      />
                    )}
                  </div>
                )}
                <div className="p-3 flex flex-row w-full pl-6">
                  <div className="flex-grow text-left text-sm text-gray-800">Total Price</div>
                  <div className="font-semibold text-gray-800 ">${totalPrice.toFixed(2)}</div>
                </div>

                <ErrorMessage name={name}>
                  {(msg) =>
                    typeof msg === "string" && (
                      <small id={`errorMessage.${name}`} className="p-error">
                        {msg}
                      </small>
                    )
                  }
                </ErrorMessage>
              </div>

              {showPanelSelection && (
                <LaboratoryOrderPanelSelection
                  billingType={billingType as BILLING_TYPES_CODES}
                  includedPanels={includedPanels}
                  laboratoryId={performer?.[0]?.id as string}
                  combo={combo}
                  onSave={updatePanels}
                  isPanelDisabled={isDBIOPanelActive}
                  onHide={() => setShowPanelSelection(false)}
                />
              )}
            </>
          )
        }}
      </FieldArray>
      <ConfirmDialog />
    </>
  )
}

type Props = {
  field: string
}

export { LaboratoryOrderPanels }
