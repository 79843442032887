import type { PropsWithChildren } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const DetailsContainer = ({ children, onClose, title }: PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <div className="px-4 sm:px-6 py-6 flex justify-between">
        <h2 className="text-lg font-semibold leading-6 text-gray-900">{title ?? "Details"}</h2>
        <span className="rounded-full hover:bg-gray-100 px-2 cursor-pointer" title="Close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>

      <div className="px-4 sm:px-6 space-y-6 pb-6">{children}</div>
    </div>
  )
}

type Props = {
  onClose(): void
  title?: string
}

export { DetailsContainer }
