import { useQuery } from "@tanstack/react-query"
import {
  type Bundle,
  type Coding,
  type MedicationKnowledge,
  type MedicationRequest,
  type ParametersParameterArrayValue,
  getResources,
  isCarePlan,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getCommonCode } from "utils"

import { getMedCodes } from "../../utils"
import { medsQueryKeys } from "../meds_query_keys"

const useDraftMedicationRequests = ({
  patientId,
  category,
  mrId,
  filterCarePlanOnes = false,
}: DraftMedicationRequestsArgs) => {
  const { search, transaction } = useClient()
  const queryKey = medsQueryKeys.draft.list(patientId, category)

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "draft",
        category,
        "doNotPerform:not": "true",
        ...(mrId ? { _id: mrId } : {}),
        _sort: "authoredon",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/MedicationRequest`, filters, signal })
      const mrs = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationRequests = filterCarePlanOnes ? mrs.filter((mr) => !mr?.basedOn?.some(isCarePlan)) : mrs

      const medCodes = getMedCodes({ meds: medicationRequests, withQty: true })
      const medTokens = medCodes
        ?.map((coding) => {
          const system = coding?.code
            ? (coding as Coding).system
            : (coding as ParametersParameterArrayValue).Coding?.system
          const code = coding?.code ? (coding as Coding).code : (coding as ParametersParameterArrayValue).Coding?.code
          return encodeURIComponent(`${system}|${code}`)
        })
        ?.join(",")
      if (medTokens) {
        const reqBundle: Bundle = {
          resourceType: "Bundle",
          type: "batch",
          entry: [
            {
              request: {
                method: "GET",
                url: `MedicationKnowledge?code=${medTokens}`,
              },
            },
          ],
        }
        const resBundle = (await transaction(reqBundle)) as Bundle
        const medicationKnowledges = getResources<MedicationKnowledge>(
          resBundle?.entry?.[0]?.resource as Bundle,
          "MedicationKnowledge",
        )

        return {
          medicationRequests,
          medicationKnowledges,
          medCodes,
        }
      }

      return { medicationRequests, medCodes, medicationKnowledges: [] }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { medicationKnowledges } = useMemo(() => {
    const medicationKnowledges = data?.medicationKnowledges.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )

    return { medicationKnowledges }
  }, [data?.medicationKnowledges])

  if (isError) {
    throw error
  }

  return {
    medicationRequests: data?.medicationRequests ?? [],
    medicationKnowledges,
    medCodes: data?.medCodes,
    isLoading,
    reloadMedications: refetch,
  }
}

interface DraftMedicationRequestsArgs {
  patientId: string
  category: string
  mrId?: string
  filterCarePlanOnes?: boolean
}

export { useDraftMedicationRequests }
