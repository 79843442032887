import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import { type Bundle, type Communication, type CommunicationRequest, getResources } from "fhir"
import { compareDesc, parseISO } from "date-fns"

import { useClient } from "api"

import { emailQueryKeys } from "../query-keys"

const useCommunicationRequests = (
  patientId: string,
  textFilter?: string,
  statusFilter?: string[],
  statusReasonFilter?: string[],
) => {
  const { transaction } = useClient()
  const queryKey = emailQueryKeys.list(patientId, textFilter, statusFilter, statusReasonFilter)

  const getCommunicationsRequests = async (pageParam: number) => {
    const communicationStatusReasonFilter = statusReasonFilter?.length
      ? `&_has:Communication:based-on:status-reason=${statusReasonFilter.join(",")}`
      : ""
    const communicationRequestStatusFilter = statusFilter?.length ? `status=${statusFilter.join(",")}` : ""

    const communicationRequestTextFilter = textFilter
      ? `_content=${textFilter.includes(" ") ? `"${textFilter}"` : textFilter}`
      : ""

    const bundleEntry = []

    if (communicationStatusReasonFilter || (!communicationRequestStatusFilter && !communicationStatusReasonFilter)) {
      bundleEntry.push({
        request: {
          method: "GET",
          url: `Patient/${patientId}/CommunicationRequest?_revinclude=Communication:based-on:CommunicationRequest${communicationStatusReasonFilter}&_count=20&_page=${pageParam}&_sort=-createdAt&${communicationRequestTextFilter}`,
        },
      })
    }

    if (communicationRequestStatusFilter || (!communicationRequestStatusFilter && !communicationStatusReasonFilter)) {
      bundleEntry.push({
        request: {
          method: "GET",
          url: `Patient/${patientId}/CommunicationRequest?${communicationRequestStatusFilter}&_count=20&_page=${pageParam}&_sort=-createdAt&${communicationRequestTextFilter}`,
        },
      })
    }

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "batch",
      entry: bundleEntry,
    }

    return transaction(bundle)
  }

  const { data, isLoading, isError, error, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery<CommunicationRequestsQueryData, Error>({
      queryKey,
      queryFn: async ({ pageParam = 1 }) => {
        const respBundle = await getCommunicationsRequests(pageParam as number)

        const firstBundle = (respBundle as Bundle)?.entry?.[0]?.resource as Bundle
        const secondBundle = (respBundle as Bundle)?.entry?.[1]?.resource as Bundle

        const communicationRequests = [
          ...(firstBundle ? getResources<CommunicationRequest>(firstBundle, "CommunicationRequest") : []),
          ...(secondBundle ? getResources<CommunicationRequest>(secondBundle, "CommunicationRequest") : []),
        ]

        const communications = [
          ...(firstBundle ? getResources<Communication>(firstBundle, "Communication") : []),
          ...(secondBundle ? getResources<Communication>(secondBundle, "Communication") : []),
        ]

        const next =
          firstBundle?.link?.find(({ relation }) => relation === "next") ||
          secondBundle?.link?.find(({ relation }) => relation === "next")
            ? (pageParam as number) + 1
            : undefined

        return { communicationRequests, communications, next, total: 20 }
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next,
      meta: { context: { queryKey, patientId } },
      refetchInterval: 30000,
      refetchIntervalInBackground: true,
    })

  const { communicationRequestsWithCommunication, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.communicationRequests).reverse() ?? []
    const count = newData?.length

    const communicationsByCR =
      data?.pages
        .flatMap((page) => page.communications)
        .reduce(
          (acc, communication) => ({ ...acc, [communication.basedOn?.[0].id ?? ""]: communication }),
          {} as Record<string, Communication>,
        ) ?? {}

    const communicationRequestsWithCommunication =
      data?.pages
        .flatMap((page) => page.communicationRequests)
        .map((communicationRequest) => ({
          communicationRequest,
          communication: communicationsByCR[communicationRequest.id ?? ""],
        }))
        .sort(({ communicationRequest: firstCR }, { communicationRequest: secondCR }) =>
          compareDesc(parseISO(firstCR.authoredOn ?? ""), parseISO(secondCR.authoredOn ?? "")),
        ) ?? []

    return {
      communicationRequestsWithCommunication,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    communicationRequestsWithCommunication,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type CommunicationRequestsQueryData = {
  communicationRequests: CommunicationRequest[]
  communications: Communication[]
  next: number | undefined
  total: number
}

export { useCommunicationRequests }
