import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { AllergyIntolerance } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { allergiesQueryKeys } from "../query-keys"

const useCreateAllergy = (onSettled: () => void, onSuccess?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newAllergy = async (allergy: AllergyIntolerance) => create("AllergyIntolerance", allergy)

  const { mutate: createAllergy, isPending: isAdding } = useMutation({
    mutationFn: newAllergy,
    onSuccess: async (_, { patient }) => {
      queryClient.refetchQueries({ queryKey: allergiesQueryKeys.list(patient.id as string), type: "all" })
      onSuccess?.()
      displayNotificationSuccess("Allergy created successfully!")
      // datadogLogs.logger.info(`Allergy of ${patient.display} created successfully!`)
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createAllergy, isAdding }
}

export { useCreateAllergy }
