import {
  type Appointment,
  type CodeableConcept,
  type Encounter,
  type EncounterParticipantArray,
  type Reference,
  asReference,
  isHealthcareService,
} from "fhir"
import * as Yup from "yup"

import { SYSTEM_VALUES } from "system-values"

const getInitialValues = ({
  patientRef,
  start,
  appointment,
  practirionerRoleRef,
}: {
  patientRef: Reference
  practirionerRoleRef: Reference
  start?: Date
  appointment?: Appointment
}): Encounter => {
  const type = appointment?.participant.find(({ actor }) => isHealthcareService(actor))

  return {
    status: "in-progress",
    class: {
      code: "AMB",
      system: SYSTEM_VALUES.V3_ACTENCOUNTERCODE,
      display: "Ambulatory",
    },
    period: {
      start: appointment?.start?.toISOString() ?? (start ? start.toISOString() : new Date().toISOString()),
    },
    subject: patientRef,
    reasonCode: [
      {
        coding: [{ code: "chief-complaint", display: "Chief complaint", system: SYSTEM_VALUES.ENCOUNTER_REASON_CODE }],
        text: "",
      },
    ],
    appointment: appointment?.id ? [asReference(appointment)] : undefined,
    participant: type?.actor?.id
      ? [{ r5_actor: type.actor }, { r5_actor: practirionerRoleRef }]
      : [{ r5_actor: undefined }, { r5_actor: practirionerRoleRef }],
  }
}

const sanitize = ({ ...encounter }: Encounter) => {
  if (encounter.type?.length === 0 || encounter.type?.[0].coding === undefined) encounter.type = undefined
  if (!encounter.reasonCode?.[0]?.text) delete encounter.reasonCode
  if (!encounter.participant?.[0]?.r5_actor?.id) delete encounter.participant

  return encounter
}

const validationSchema = Yup.object().shape({
  period: Yup.object().shape({
    start: Yup.string().required("Select start date"),
  }),
  participant: Yup.array()
    .of(
      Yup.object().test("test-first-type", "Type is required", (value: EncounterParticipantArray, context) => {
        if (context?.path === "participant[0]") {
          return !value?.r5_actor?.id
            ? context.createError({ message: "Type is required", path: "participant[0].actor" })
            : true
        }

        return true
      }),
    )
    .defined(),
  reasonCode: Yup.array()
    .of(
      Yup.object().test("test-first-code", "Please specify a value", (value: CodeableConcept, context) => {
        if (context?.path === "reasonCode[0]") {
          return !value?.text
            ? context.createError({ message: "Chief complaint is required", path: "reasonCode[0].text" })
            : true
        }

        return true
      }),
    )
    .defined(),
})

export { getInitialValues, sanitize, validationSchema }
