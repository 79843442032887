import { add } from "date-fns/add"
import {
  type ChargeItemDefinition,
  type CodeableConcept,
  type Dosage,
  type Duration,
  type MedicationDispense,
  type MedicationKnowledge,
  type MedicationKnowledgeAdministrationGuidelinesArray,
  type MedicationRequestMedication,
  type ParametersParameterArrayArray,
  type ParametersParameterArrayValue,
  type Patient,
  type PractitionerRole,
  type Quantity,
  type Reference,
  type Timing,
  asReference,
  codeableConceptAsString,
  isDevice,
  isPatient,
  isPractitioner,
} from "fhir"

import type { PractitionerInfo } from "commons/types"
import { ADMINISTRATION_GUIDELINE_DOSAGE_TYPE, MED_FEE_TYPE, mrCategoryCodes, unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  getBillToPatientFeePrice,
  getCidFeeIdentifier,
  getCommonCode,
  isRefrigeratedMedicationKnowledge,
  medicationKnowledgeRegulations,
} from "utils"

import { commonsDispenseInterval, dosageTimingRepeats, DOSE_RANGE_REGEX, treatmentFrequencies } from "./data"
import type { ActivityEventAgent, AgentData, MedicationRequestInfo } from "./types"

const getMRInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  patient: Patient,
  loggedInPractitionerRole: PractitionerRole,
  practitionersInfo: PractitionerInfo[],
  catalogAuthor: Reference,
  encounter?: Reference | null,
  requesterPractitionerRole?: Reference,
): MedicationRequestInfo => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0]?.dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
  } as Quantity
  const currentDate = new Date().toISOString()

  const mrCategory = [{ coding: [mrCategoryCodes.nutraceutical], text: mrCategoryCodes.nutraceutical.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)
  const regulations = medicationKnowledgeRegulations(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({ coding: [mrCategoryCodes.refrigerated], text: mrCategoryCodes.refrigerated.display })
  }

  if (regulations?.length) {
    mrCategory.push(
      ...regulations.map((code) => ({
        coding: [code],
        text: code.display ?? code.code,
      })),
    )
  }

  const patientGpRole =
    requesterPractitionerRole ??
    practitionersInfo.find(
      ({ practitioner, practitionerRole }) =>
        practitioner.id === patient.generalPractitioner?.[0]?.id && !!practitionerRole,
    )?.practitionerRole

  const requester = patientGpRole ? asReference(patientGpRole) : undefined

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    category: mrCategory,
    authoredOn: currentDate,
    subject: asReference(patient),
    encounter: encounter ?? undefined,
    requester,
    recorder: asReference(loggedInPractitionerRole),
    performer: asReference(patient),
    dosageInstruction: mkDefaultDosages,
    dispenseRequest: {
      initialFill: {
        quantity: defaultQuantity,
        duration: commonsDispenseInterval[1].value,
        isDfo: false,
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: commonsDispenseInterval[1].value,
      expectedSupplyDuration: commonsDispenseInterval[1].value,
      quantity: defaultQuantity,
      performer: catalogAuthor,
    },
    doseQuantity: "1",
    medicationUnit: medicationKnowledge.amount?.unit,
    treatmentFrequency: treatmentFrequencies[0].value,
    unitsByRecipient: medicationKnowledge.amount?.value,
    resourceType: "MedicationRequest",
  }
}

const getMedCommonCode = ({
  medication,
  medicationKnowledge,
}: {
  medicationKnowledge?: MedicationKnowledge
  medication?: MedicationRequestMedication
}) => getCommonCode({ codes: medication?.CodeableConcept?.coding ?? medicationKnowledge?.code?.coding })

const getAdministrationGuideline = (
  med?: { code?: CodeableConcept },
  medRecommendedDosage?: Record<string, Dosage[]>,
) => {
  const medCode = getCommonCode({ codes: med?.code?.coding })
  const dosage = medRecommendedDosage?.[medCode]

  return dosage
    ? ({
        dosage: [{ dosage, type: ADMINISTRATION_GUIDELINE_DOSAGE_TYPE }],
      } as MedicationKnowledgeAdministrationGuidelinesArray)
    : undefined
}

const getMKDisplayText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText =
    !!e?.packaging?.type?.coding?.[0]?.display &&
    !!e?.packaging?.quantity?.value &&
    `${e?.packaging?.type?.coding?.[0]?.display}`
  const strength = e?.ingredient?.length === 1 && e?.ingredient[0]?.strength?.numerator?.unit
  const doseForm = e?.doseForm?.coding?.[0]?.display

  const displayArray = [textCode, strength, doseForm, packagingText].filter(Boolean)

  return `${displayArray.join(" - ")}`
}

const sanitizeMR = ({ ...medicationReq }: MedicationRequestInfo, mrFromMK?: boolean) => {
  const currentDate = new Date().toISOString()
  if (!medicationReq.authoredOn) medicationReq.authoredOn = currentDate
  if (!medicationReq.dispenseRequest?.nextRefillDate)
    medicationReq.dispenseRequest = { ...medicationReq.dispenseRequest, ...{ nextRefillDate: currentDate } }

  const quantity = medicationReq.dispenseRequest?.initialFill?.quantity as Quantity
  const duration = medicationReq.dispenseRequest?.initialFill?.duration as Duration
  const interval = medicationReq.dispenseRequest.dispenseInterval?.value

  const repeats = medicationReq.dispenseRequest?.numberOfRepeatsAllowed

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    quantity,
    ...(repeats !== undefined ? { numberOfRepeatsAllowed: repeats } : undefined),
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: medicationReq.dispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      ...(!interval
        ? {
            end: add(
              medicationReq.dispenseRequest?.validityPeriod?.start
                ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
                : new Date(),
              { [`${duration.unit ?? "second"}s`]: (duration.value ?? 0) * ((repeats ?? 0) + 1) },
            ).toISOString(),
          }
        : undefined),
    },
  }

  const frequency = getTimingFrequency(medicationReq.treatmentFrequency)

  if ((mrFromMK && !medicationReq.dosageInstruction?.length) || !mrFromMK) {
    medicationReq.dosageInstruction = [
      {
        timing: { ...frequency },
        text: `Take ${medicationReq.doseQuantity} ${medicationReq.medicationUnit?.toLowerCase()} ${treatmentFrequencies
          .find((f) => f.value === medicationReq.treatmentFrequency)
          ?.label?.toLowerCase()}`,
      },
      ...(medicationReq.dosageInstruction?.slice(1) ?? []),
    ]

    const matches = medicationReq.doseQuantity?.match(DOSE_RANGE_REGEX)
    if (matches?.length === 2) {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Range: {
              low: { value: Number.parseInt(matches[0].replace("-", "")), unit: medicationReq.medicationUnit },
              high: { value: Number.parseInt(matches[1]), unit: medicationReq.medicationUnit },
            },
          },
        },
      ]
    } else {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Quantity: { value: Number.parseInt(medicationReq.doseQuantity ?? "1"), unit: medicationReq.medicationUnit },
          },
        },
      ]
    }
  }

  if (!medicationReq.encounter) delete medicationReq.encounter
  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  if (!medicationReq.note?.[0].text) delete medicationReq.note

  delete medicationReq.unitsByRecipient
  delete medicationReq.medicationUnit
  delete medicationReq.treatmentFrequency
  delete medicationReq.doseQuantity

  return medicationReq
}

const getTimingFrequency = (frequencyKeyValue?: string) => {
  const selectedfrequency = dosageTimingRepeats.find((option) => option.id === frequencyKeyValue)

  const frequency = { code: selectedfrequency?.code, repeat: selectedfrequency?.repeat }

  const defaultTimingFreq = {
    code: { coding: [{ code: "QD", system: SYSTEM_VALUES.V3_GTSABB }] },
    repeat: { periodUnit: unitOfTime[3].code, period: 0, frequency: 0 },
  } as Timing

  return frequency ?? defaultTimingFreq
}

// TODO: Check this code back with meds team to improve implementation
const hasInvalidMedicationDispense = (medicationDispenses?: MedicationDispense[]) =>
  Boolean(
    medicationDispenses?.some((md) => md.statusReason?.CodeableConcept?.coding?.[0]?.code === "Invalid Submission"),
  )

const getProductConfig = (product: ParametersParameterArrayValue) =>
  ({
    name: "config-details",
    part: [
      {
        name: "quantity",
        value: { decimal: product?.Quantity?.value },
      },
      ...(product?.Duration
        ? [
            {
              name: "frequency",
              value: { Quantity: product?.Duration },
            },
          ]
        : []),
    ],
  }) as ParametersParameterArrayArray

const getParamsSkuCodesByFee = (codes: ParametersParameterArrayValue[]) => {
  const skuCodes = codes?.filter(
    (code) =>
      (code as ParametersParameterArrayValue)?.Coding?.system === SYSTEM_VALUES.SKU ||
      (code as ParametersParameterArrayValue)?.Coding?.system === SYSTEM_VALUES.SKU_CA,
  )

  const uniqueIdentifiers = new Set<string>(...[skuCodes?.map((code) => `${code?.Coding?.code}`)])

  const { groupedItems } = Array.from(uniqueIdentifiers).reduce(
    (acc, item) => {
      const itemsWithSameSku = skuCodes.filter((code) => code?.Coding?.code === item)

      itemsWithSameSku.map((currentItem) => {
        const key = `${currentItem?.Coding?.code}`

        if (!acc.groupedItems[key]) {
          acc.groupedItems[key] = {
            name: "product-info",
            part: [
              {
                name: "code",
                value: {
                  Coding: currentItem.Coding,
                },
              },
              {
                name: "product-configs",
                part: [getProductConfig(currentItem)],
              },
            ],
          }
        } else {
          const currentProductConfigsPart = [...(acc.groupedItems[key]?.part?.[1].part ?? [])]
          acc.groupedItems[key].part![1].part = [...currentProductConfigsPart, getProductConfig(currentItem)]
        }
      })
      return acc
    },
    {
      groupedItems: {} as { [key: string]: ParametersParameterArrayArray },
    },
  )

  return [...Object.values(groupedItems)] as ParametersParameterArrayArray[]
}

const getIndexedCIDBySku = (cids?: ChargeItemDefinition[]) =>
  cids?.reduce<Record<string, ChargeItemDefinition[]>>(
    (acc, cid) => {
      const skuCode = getCommonCode({ codes: cid.code?.coding })

      return { ...acc, [skuCode]: [...(acc[skuCode] ?? []), cid] }
    },
    {} as { [key: string]: ChargeItemDefinition[] },
  ) ?? {}

const getFeeType = (cids: ChargeItemDefinition[]) =>
  cids?.some(
    (cid) =>
      cid?.useContext?.find((context) => context?.code?.code === "bill-patient-fee-type")?.value?.CodeableConcept
        ?.coding?.[0]?.code === "by-frequency",
  )
    ? MED_FEE_TYPE.ByFrequency
    : MED_FEE_TYPE.Fixed

const getMatchingChargeItemDefinitions = ({
  cids,
  match: { qty = 1, feeIdentifier },
}: {
  cids: ChargeItemDefinition[]
  match: { qty?: number; feeIdentifier?: string }
}) => {
  let effectiveCids = [...cids]

  effectiveCids = effectiveCids.filter((cid) => cid?.propertyGroup?.[0]?.priceComponent?.[0]?.factor === qty)

  if (feeIdentifier) {
    effectiveCids = effectiveCids.filter((cid) => {
      const feeType = getFeeType([cid])

      if (feeType === MED_FEE_TYPE.Fixed) {
        return feeType === feeIdentifier
      } else {
        return getCidFeeIdentifier(cid) === feeIdentifier
      }
    })
  }

  return effectiveCids
}

const getMedFee = (cids: ChargeItemDefinition[]) =>
  cids?.map((cid) => {
    return {
      value: getBillToPatientFeePrice(cid?.propertyGroup?.[0]?.priceComponent)?.amount?.value ?? 0,
      ...(getFeeType([cid]) === MED_FEE_TYPE.ByFrequency
        ? {
            duration: cid?.useContext?.find((context) => context?.code?.code === "dispense-frequency")?.value?.Quantity,
          }
        : {}),
    }
  })

const getActivityEventParticipants = (agent: ActivityEventAgent[] | undefined) =>
  agent?.reduce<AgentData[]>((agents, agent) => {
    let result: AgentData | undefined

    if (agent.who?.type === "Client") {
      result = { name: agent.who.display as string, type: "Client", requestor: agent.requestor ?? false }
    } else if (isPatient(agent.who) || isPractitioner(agent.who)) {
      result = {
        name: agent?.who?.display as string,
        type: agent.who?.type ?? agent.who?.resourceType ?? "Unspecified",
        requestor: agent.requestor ?? false,
      }
    } else if (isDevice(agent.who)) {
      result = {
        name: agent.who.display as string,
        type: codeableConceptAsString(agent.type, ""),
        requestor: agent.requestor ?? false,
      }
    }

    if (result) {
      if (!result.name) result.name = agent.name ?? (agent.who?.display as string)
      return [...agents, result]
    }
    return agents
  }, [])

export {
  getAdministrationGuideline,
  getFeeType,
  getIndexedCIDBySku,
  getMatchingChargeItemDefinitions,
  getMedCommonCode,
  getMedFee,
  getMKDisplayText,
  getMRInitialValues,
  getParamsSkuCodesByFee,
  hasInvalidMedicationDispense,
  sanitizeMR,
  getActivityEventParticipants,
}
