import { faEdit, faLinkHorizontal, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import type { ReactNode } from "react"

const InfoRow = ({ className, title, content, editable, onEdit, showLink, loading, spinnerLoading }: Props) => (
  <div
    className={classNames(
      "flex w-full items-center text-gray-400 text-sm border-b last:border-0 py-5 first:pt-0",
      className,
    )}
  >
    <p className="w-1/5 min-w-[5rem] font-semibold">{title}</p>
    <div className="w-5/6 flex justify-between whitespace-nowrap text-ellipsis overflow-hidden">
      {loading ? spinnerLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <Skeleton /> : content}

      {showLink && (
        <div>
          <FontAwesomeIcon
            icon={loading ? faSpinner : faLinkHorizontal}
            spin={loading}
            className="cursor-pointer ml-5"
          />
        </div>
      )}
      {editable && !loading && (
        <div className="text-right w-1/5 pr-2">
          <span className="hover:bg-gray-100 rounded-full p-2 cursor-pointer" title="Edit" onClick={onEdit}>
            <FontAwesomeIcon icon={faEdit} className="text-gray-600" />
          </span>
        </div>
      )}
    </div>
  </div>
)

type Props = {
  className?: string
  title: string
  content: string | ReactNode
  showLink?: boolean
  loading?: boolean
  spinnerLoading?: boolean
  editable?: boolean
  onEdit?(): void
}

export { InfoRow }
