import type { AllergyIntolerance } from "fhir"
import { useId } from "react"

import {
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { allergyClinicalStatusCodes } from "data"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useAllergies, useCreateAllergy, useUpdateAllergy } from "../hooks"
import { AllergiesForm } from "./AllergiesForm"
import { allergyModelBuilder } from "./allergyModelBuilder"
import { allergyValidationSchema, getInitialValues, sanitize } from "./validations"

const AllergiesContainer = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { initialValue, isNew, showSlide, reset, add, edit } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { allergies, isLoading, hasNextPage, fetchNextPage } = useAllergies({ patientId })
  const { createAllergy } = useCreateAllergy(reset)
  const { updateAllergy } = useUpdateAllergy(reset)

  const onSubmit = (allergy: AllergyIntolerance) => {
    isNew ? createAllergy(sanitize(allergy)) : updateAllergy(sanitize(allergy))
  }

  const deactivate = (allergy: AllergyIntolerance) => {
    const sanitizedAllergy = sanitize({
      ...allergy,
      verificationStatus: { text: "refuted", coding: [allergyClinicalStatusCodes[3]] },
    })
    updateAllergy(sanitizedAllergy)
  }
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      hasData={!!allergies?.length}
      showSlide={showSlide}
      formTitle="Allergy"
      formInitialValue={initialValue}
      validationSchema={allergyValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<AllergiesForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appModules[ModulesId.ALLERGY].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={allergies ?? []}
            itemModelBuilder={(item) =>
              allergyModelBuilder(
                item,
                () => edit(item),
                () => deactivate(item),
              )
            }
          />
        </InfiniteScroll>
      </div>
    </DataContainerSlideoverForm>
  )
}

export { AllergiesContainer }
