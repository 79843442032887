import { faCheckCircle, faGearCode, faSearch, faSpinner, faVials } from "@fortawesome/pro-regular-svg-icons"
import { faVialCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Reference } from "fhir"
import { type FieldArrayRenderProps, useFormikContext } from "formik"
import { TabPanel, TabView } from "primereact/tabview"
import { useState } from "react"

import { DropdownField, ICD10CodesField, InputField, ListFieldArray, NumberField, TabPanelHeader } from "commons"
import { LAB_FORM_TABS } from "commons/labs"
import type { TabPanelItem } from "commons/types"
import { genders } from "data"
import { useOrganizationContext } from "organization"
import { substractPrice, sumPrice } from "utils"

import { useCheckLabComboExists } from "../../../hooks/labs/useCheckLabComboExists"
import type { LabPanel, PDParams } from "../../../types"
import { labPanelModelBuilder } from "./labPanelModelBuilder"
import TestAutocomplete from "./TestAutocomplete"

const LabComboForm = ({ performerLabsOptions, isEditing, isLoadingData, editFee }: Props) => {
  const { currentOrganizationId } = useOrganizationContext()

  const {
    values: { fee, basePrice, laboratory, title, parentCombo },
    initialValues: { title: initialTitle },
    setFieldValue,
  } = useFormikContext<PDParams>()

  const { isDuplicateCombo, isChecking, isChecked, checkComboExists } = useCheckLabComboExists(title)

  const [activeTab, setActiveTab] = useState(LAB_FORM_TABS.TESTS_TAB.index)

  const validateField = async (value: string) => {
    if (!value) return "This field is required"
    if (initialTitle !== value) {
      const { data } = await checkComboExists()
      if (data?.isDuplicateCombo) return "This combo name is in use"
    }

    return undefined
  }

  const renderTitleValidationFeedback = () => (
    <>
      {isChecking && !isChecked && (
        <span className="text-slate-500">
          <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" size="sm" />
          <span className="ml-1 text-xs">Checking availability...</span>
        </span>
      )}
      {!isDuplicateCombo && isChecked && (
        <span className="text-primary">
          <FontAwesomeIcon icon={faCheckCircle} />
          <span className="ml-1 text-xs">Combo name available</span>
        </span>
      )}
    </>
  )

  const tabPanelItems: TabPanelItem[] = [
    {
      id: LAB_FORM_TABS.TESTS_TAB.key,
      visible: true,
      header: { icon: faVials, title: "Tests" },
      content: (
        <ListFieldArray
          field="tests"
          emptyDataMessage="Get started by adding a new test"
          itemModelBuilder={labPanelModelBuilder}
          onRemoveItem={({ price }) => setFieldValue("basePrice", substractPrice(basePrice, price).sub.toNumber())}
          askDeleteConfirmation={isEditing}
          disabled={isLoadingData || editFee || isEditing}
          isLoading={isLoadingData}
          className="field p-fluid flex-1"
          itemLabel="test"
          icon={faVialCircleCheck}
        >
          {({
            push,
            form: {
              values: { tests },
            },
          }: FieldArrayRenderProps) => (
            <TestAutocomplete
              placeholder="Add new lab test"
              className="mb-10"
              disabled={!laboratory?.id || editFee || isEditing}
              handleSelect={(item) => {
                if (
                  !(tests as LabPanel[] | undefined)?.some(
                    ({ planDefinition }) => planDefinition.id === item?.planDefinition?.id,
                  )
                ) {
                  setFieldValue("basePrice", sumPrice(basePrice, item?.price ?? 0).sum.toNumber())
                  push(item)
                }
              }}
              organizationId={currentOrganizationId}
              performerLab={laboratory?.id}
              unselectablePanels={tests}
            />
          )}
        </ListFieldArray>
      ),
    },
    {
      id: LAB_FORM_TABS.ICD10_TAB.key,
      visible: true,
      header: { icon: faGearCode, title: "Default ICD-10 Codes" },
      content: (
        <ICD10CodesField
          field="icd10"
          label=""
          hideEmptyMessage
          orgSuggestedConditions="defaultProcedureIcd10"
          horizontal
          icon={faSearch}
        />
      ),
    },
  ]

  return (
    <div className="divide-gray-200 divide-y pt-1.5">
      <div className="p-fluid grid gap-2">
        <InputField
          field="title"
          placeholder="Add a name"
          disabled={editFee}
          renderValidationFeedback={renderTitleValidationFeedback}
          className="mb-0 !important"
          validation={validateField}
        />
        <div className="p-fluid grid grid-cols-2 gap-2">
          <DropdownField
            optionLabel="display"
            optionValue=""
            field="laboratory"
            placeholder="Select Laboratory"
            options={performerLabsOptions}
            dataKey="id"
            disabled={isEditing || editFee || !!parentCombo}
          />
          <DropdownField
            placeholder="Select a gender"
            options={genders}
            optionLabel="label"
            optionValue="code"
            showClear
            field="gender"
            dataKey="code"
            disabled={isEditing || editFee}
          />
        </div>
        <TabView
          className="sticky-header flex flex-col flex-1 -w-12"
          panelContainerClassName="h-full pb-0 flex-1"
          activeIndex={activeTab}
          onTabChange={(e) => setActiveTab(e.index)}
          scrollable
          pt={{
            nextButton: {
              className: "w-auto h-auto top-[35%] shadow-none",
            },
            nextIcon: {
              className: "text-primary",
            },
            prevButton: {
              className: "w-auto h-auto top-[35%] shadow-none first:text-primary",
            },
            navContainer: {
              className: "mb-4",
            },
          }}
        >
          {tabPanelItems.map(({ id, header: { icon, title }, visible, content, contentClassName }) => (
            <TabPanel
              key={id}
              header={<TabPanelHeader icon={icon} title={title} />}
              visible={visible}
              contentClassName={contentClassName}
            >
              {content}
            </TabPanel>
          ))}
        </TabView>
      </div>
      <div className="flex justify-center flex-col flex-1 space-y-3 mt-6">
        <div className="pt-2 flex justify-between items-center text-sm mb-2">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="divide-gray-200 divide-y flex justify-center flex-col flex-1 space-y-3">
          <div className="pb-1 flex justify-between items-center">
            <span className="font-semibold text-sm">Fee</span>
            <NumberField field="fee" horizontal min={0} placeholder="$0.00" maxFractionDigits={2} showButtons={false} />
          </div>
          <div className="pt-2 flex justify-between">
            <span className="font-semibold text-sm">Total</span>
            <span className="font-semibold text-sm">${sumPrice(basePrice, fee ?? 0).sum.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  performerLabsOptions: Reference[]
  isEditing?: boolean
  isLoadingData?: boolean
  editFee?: boolean
}

export { LabComboForm }
