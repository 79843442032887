import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type Account, type AccountBETACreditCardArray, type Organization, asReference } from "fhir"

import { type CreditCardFormData, sanitize, useAuthorizeBasisTheorySession, useBasisTheoryInstance } from "account"
import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys, useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

const useCreateOrganizationAccount = (onSettled: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { currentOrganizationId } = useOrganizationContext()
  const { authorize } = useAuthorizeBasisTheorySession()

  const newOrganizationAccount = async ({
    organization,
    creditCard,
  }: {
    organization: Organization
    creditCard: CreditCardFormData
  }) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    const { container } = await authorize({ nonce, organizationId: currentOrganizationId })

    const token = await bt.tokens.create(
      {
        type: "card",
        data: {
          number: creditCard.number?.replace(/-/g, "") as string,
          cvv: creditCard.cvv as string,
          expiration_month: creditCard.expirationMonth as string,
          expiration_year: creditCard.expirationYear as string,
        },
        containers: [container],
      },
      { apiKey: sessionKey },
    )

    const sanitizedCreditCard = sanitize({ creditCard, isNew: true })

    const creditCardWithPCIToken: AccountBETACreditCardArray = { ...sanitizedCreditCard, pciToken: token.id }
    const account = {
      subject: [asReference(organization)],
      status: "active",
      creditCard: [creditCardWithPCIToken],
      defaultCreditCard: `${sanitizedCreditCard.type}|${sanitizedCreditCard.last4Digits}`,
    } as Account
    return await create(`Account?subject=${currentOrganizationId}`, account)
  }

  const { mutate: createOrgAccount, isPending: isAdding } = useMutation({
    mutationFn: newOrganizationAccount,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: organizationQueryKeys.account.current(currentOrganizationId),
        type: "all",
      })

      displayNotificationSuccess("Account created successfully")
      // datadogLogs.logger.info(`Account ${account?.id} created successfully`, { account })
    },
    onSettled: onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createOrgAccount, isAdding }
}
export { useCreateOrganizationAccount }
