import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { faDoNotEnter, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Observation, codeableConceptAsString } from "fhir"

import type { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const socialModel = (socialHistory: Observation, edit?: () => void, remove?: () => void): StackedListItemProps => ({
  leftData: [
    { lineItems: [{ name: "Code", value: codeableConceptAsString(socialHistory.code) }] },
    ...(socialHistory.effective?.dateTime
      ? [
          {
            lineItems: [
              {
                name: "Effective date",
                value: formatDate(new Date(socialHistory.effective.dateTime), formatsByTypes.LONG_DATETIME),
                icon: faCalendarDays,
              },
            ],
          },
        ]
      : []),
  ],
  menu: [
    ...(edit
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: edit,
          },
        ]
      : []),
    ...(remove
      ? [
          {
            label: "Deactivate",
            icon: <FontAwesomeIcon icon={faDoNotEnter} size="sm" className="mr-2" />,
            command: remove,
          },
        ]
      : []),
  ],
})

export { socialModel }
