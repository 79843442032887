import { useQuery } from "@tanstack/react-query"
import { type Reference, type RelatedPerson, asReference, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { coverageQueryKeys } from "../query-key"

const useRelatedPerson = (patientId: string) => {
  const { search } = useClient()
  const queryKey = coverageQueryKeys.relatedPerson.withPatientID(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const bundle = await search({ endpoint: `Patient/${patientId}/RelatedPerson`, signal })

      const relatedPerson = getResources<RelatedPerson>(bundle, "RelatedPerson")

      return { relatedPerson }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { personRefbyCode, prIds } = useMemo(() => {
    const personRefbyCode =
      data?.relatedPerson.reduce<Record<string, Reference>>(
        (acc, person) => ({ ...acc, [person.relationship?.[0].coding?.[0].display as string]: asReference(person) }),
        {},
      ) ?? {}
    const prIds = data?.relatedPerson.map((person) => person.id as string) ?? []

    return {
      personRefbyCode,
      prIds,
    }
  }, [data?.relatedPerson])

  return {
    relatedPeople: data?.relatedPerson ?? [],
    personRefbyCode,
    prIds,
    isLoading,
  }
}

export { useRelatedPerson }
