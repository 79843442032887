import { colorPalette } from "data"
import { type DropdownChangeEvent, type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import type { FC } from "react"

const ColorPicker: FC<Props> = ({ value, error, className, handleChange, ...dropdownProps }) => {
  const itemTemplate = (option: { label?: string; value: string } | undefined) => (
    <div
      className="rounded-full ring ring-slate-200 hover:ring-slate-400/80 transition-all ease-linear w-6 h-6"
      style={{ background: option?.value ? `${option.value}` : "" }}
      title={option?.label}
    />
  )

  const valueTemplate = (option?: { value: string; label?: string }) => (
    <div className="rounded-full w-5 h-5" style={{ background: option?.value ? `${option.value}` : "" }} />
  )

  return (
    <Dropdown
      {...dropdownProps}
      value={value}
      valueTemplate={valueTemplate}
      itemTemplate={itemTemplate}
      multiple={false}
      onChange={handleChange}
      optionValue="value"
      dataKey="value"
      options={colorPalette}
      className={classNames("p-inputtext-sm min-w-24", { "p-invalid": error }, className)}
      pt={{
        list: { className: "grid grid-flow-row grid-cols-3 gap-1" },
        itemLabel: { className: "flex justify-center" },
      }}
    />
  )
}

type Props = {
  value?: string
  error?: string
  handleChange(e: DropdownChangeEvent): void
} & DropdownProps

export { ColorPicker }
