import { faHouseBuilding, faHouseMedical, faMars, faVenus, faVials } from "@fortawesome/pro-regular-svg-icons"
import {
  faArchive,
  faCheckSquare,
  faCircleInfo,
  faClone,
  faCubes,
  faEdit,
  faEyeSlash,
  faPersonDotsFromLine,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type PlanDefinition, type Reference, codeableConceptAsString } from "fhir"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { type FC, useId, useMemo, useReducer, useState } from "react"

import { ConfirmDialog, FooterActions, InfiniteScroll, SkeletonLoader } from "commons"
import { LabComboTitle } from "commons/labs"
import { useOrganizationContext } from "organization"
import { getPDReasonCodes, sumPrice } from "utils"

import { useHideUnhidePD, useLabCombos, usePatchCombo } from "../../../hooks"
import type { AdvanceFilter, LabCombo, LabComboData, PDData } from "../../../types"
import { actionsBodyTemplate, statusBodyTemplate } from "../../../utils/templates"
import { mapPdUseContext } from "../utils"
import { LabComboDetails } from "./LabComboDetails"
import { LabComboFormContainer } from "./LabComboFormContainer"

const LabsCombos: FC<Props> = ({ filters, filter }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { labCombos, isLoadingLabCombos, hasNextPage, fetchNextPage } = useLabCombos(currentOrganizationId, {
    ...filters,
  })
  const [comboDetails, setComboDetails] = useState<LabCombo | undefined>(undefined)

  const { clone, combo, create, edit, isClone, showForm, reset, confirmArchive, archive, confirmActivate, activate } =
    useReducerState()

  const handleConfirmClose = () => {
    archive()
    activate()
  }

  const { patchCombo, isPending: isPatching } = usePatchCombo(currentOrganizationId, handleConfirmClose)
  const { hideOrUnhidePd } = useHideUnhidePD(currentOrganizationId)

  const labCombosData = useMemo(
    () =>
      labCombos.map((labCombo) => {
        const display = labCombo.combo.title ?? "no title"
        const status = labCombo.combo.status
        const performerLab = labCombo.performerLab
        const totalTests = labCombo.combo.action?.length ?? 0
        const { num1: price, num2: fee, sum: total } = sumPrice(labCombo.price, labCombo.fee)
        const { gender, organization, practice, rootId, practiceId } = mapPdUseContext(labCombo.combo.useContext)
        const totalIcd10 = getPDReasonCodes(labCombo.combo)?.length
        const isExcluded = !!labCombo.combo?.excludedOrganization?.map(({ id }) => id)?.includes(currentOrganizationId)

        return {
          comboData: {
            display,
            organization,
            practice,
            totalTests,
            gender,
            isExcluded,
            sku: "",
            pd: labCombo.combo,
            totalIcd10,
          },
          price: price.toNumber(),
          fee: fee.toNumber(),
          total: total.toFixed(2),
          performerLab,
          status,
          externalAction: [
            {
              label: "Show details",
              icon: <FontAwesomeIcon icon={faCircleInfo} />,
              command: () => setComboDetails(labCombo),
            },
            ...(labCombo.combo.status === "draft"
              ? [
                  {
                    label: "Activate",
                    icon: <FontAwesomeIcon icon={faCheckSquare} size="sm" />,
                    command: () => {
                      activate(labCombo.combo.id)
                    },
                  },
                ]
              : []),
            {
              label: "Clone",
              icon: <FontAwesomeIcon icon={faClone} size="sm" />,
              command: () => {
                clone(labCombo)
              },
            },
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
              command: () => edit(labCombo),
            },
            ...((practiceId === currentOrganizationId || rootId === currentOrganizationId) &&
            labCombo.combo.status !== "retired"
              ? [
                  {
                    label: "Archive",
                    icon: <FontAwesomeIcon icon={faArchive} size="sm" />,
                    command: () => {
                      archive(labCombo.combo.id)
                    },
                  },
                ]
              : []),
            ...(practiceId !== currentOrganizationId && rootId !== currentOrganizationId
              ? isExcluded
                ? [
                    {
                      label: "Unhide",
                      icon: <FontAwesomeIcon icon={faEyeSlash} size="sm" />,
                      command: () => {
                        hideOrUnhidePd({ id: labCombo.combo.id as string, op: "unhide-pd" })
                      },
                    },
                  ]
                : [
                    {
                      label: "Hide",
                      icon: <FontAwesomeIcon icon={faEyeSlash} size="sm" />,
                      command: () => {
                        hideOrUnhidePd({ id: labCombo.combo.id as string, op: "hide-pd" })
                      },
                    },
                  ]
              : []),
          ],
        }
      }),
    [labCombos],
  )

  const handleShowComboDetails = (labCombo?: LabCombo) => {
    setComboDetails(labCombo)
  }

  const comboDetailsBodyTemplate = ({ comboData }: { comboData: PDData & { totalIcd10?: number } }) => {
    const header = (
      <div
        title="Combo name"
        className="overflow-hidden text-ellipsis line-clamp-1 whitespace-normal break-words font-medium max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
      >
        <LabComboTitle combo={comboData.pd as PlanDefinition} />
      </div>
    )

    const details = (
      <>
        {comboData.organization ? (
          <span title="Organization root" className="flex items-center">
            <FontAwesomeIcon icon={faHouseBuilding} className="mr-1.5 text-gray-500" />
            {comboData.organization}
          </span>
        ) : (
          <span title="No organization root" className="flex items-center">
            <FontAwesomeIcon icon={faCubes} className="mr-1.5 text-gray-500" />
            <span>General Combo</span>
          </span>
        )}
        {comboData.practice && (
          <span title="Practice" className="flex items-center">
            <FontAwesomeIcon icon={faHouseMedical} className="mr-1.5 ml-1.5 text-gray-500" />
            {comboData.practice}
          </span>
        )}
        {!!comboData.totalTests && (
          <span title="Tests" className="flex items-center">
            <FontAwesomeIcon icon={faVials} className="mr-1.5 ml-1.5 text-gray-500" />
            {comboData.totalTests}
          </span>
        )}
        {!!comboData.totalIcd10 && (
          <span title="Reason Codes" className="flex items-center">
            <FontAwesomeIcon icon={faPersonDotsFromLine} className="mr-1.5 ml-1.5 text-gray-500" />
            {comboData.totalTests}
          </span>
        )}
        {comboData.gender && (
          <span title={codeableConceptAsString(comboData.gender)} className="flex items-center">
            <FontAwesomeIcon
              icon={comboData.gender.coding?.[0]?.code === "male" ? faMars : faVenus}
              className="mr-1.5 ml-1.5 text-gray-500"
            />
          </span>
        )}
      </>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                <div className="mt-2 flex">
                  <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2">
                    {details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const laboratoryBodyTemplate = ({ performerLab }: { performerLab: Reference }) => {
    const display = performerLab.display ?? "laboratory"
    const id = performerLab.id ?? ""

    return <div onClick={() => filter({ performerLabs: [id] })}>{display}</div>
  }

  const rowClassName = (labCombosData: LabComboData) => ({ "opacity-50": labCombosData.comboData.isExcluded })

  const addOptions = [
    {
      label: "Create new combo",
      icon: faPlus,
      command: () => create(),
    },
  ]

  return (
    <>
      <div className="flex flex-1 flex-col overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <DataTable value={labCombosData} loading={isLoadingLabCombos} rowClassName={rowClassName}>
            <Column field="comboData" body={comboDetailsBodyTemplate} headerClassName="bg-transparent" />
            <Column field="price" header="Price" bodyClassName="text-xs" headerClassName="default-header" />
            <Column field="fee" header="Fee" bodyClassName="text-xs" headerClassName="default-header" />
            <Column field="total" header="Total" bodyClassName="text-xs" headerClassName="default-header" />
            <Column
              field="performerLab"
              header="Laboratory"
              bodyClassName="text-xs underline cursor-pointer"
              headerClassName="default-header"
              body={laboratoryBodyTemplate}
            />
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              headerClassName="default-header"
              bodyClassName="text-xs"
            />
            <Column field="external_action" headerClassName="bg-transparent" body={actionsBodyTemplate} />
          </DataTable>
        </InfiniteScroll>
      </div>
      <FooterActions actions={addOptions} />
      {comboDetails && (
        <LabComboDetails
          labCombo={comboDetails}
          showPrice={true}
          organizationId={currentOrganizationId}
          onHide={() => handleShowComboDetails()}
        />
      )}
      {showForm && <LabComboFormContainer labCombo={combo} isClone={isClone} onHide={() => reset()} />}

      <ConfirmDialog
        visible={!!confirmArchive || !!confirmActivate || isPatching}
        isLoading={isPatching}
        hideDialog={handleConfirmClose}
        onConfirm={() =>
          patchCombo(
            confirmArchive
              ? {
                  planDefinition: { id: confirmArchive as string, status: "retired" },
                  message: "Lab combo archived successfully!",
                }
              : confirmActivate
                ? {
                    planDefinition: { id: confirmActivate as string, status: "active" },
                    message: "Lab combo activated successfully!",
                  }
                : { planDefinition: { id: "" } },
          )
        }
        waitToFinish
        confirmText="Accept"
        confirmElement={`Are you sure you want to ${confirmActivate ? "activate" : confirmArchive ? "archive" : "update"} this combo?`}
      />
    </>
  )
}

const initialState = {
  showForm: false,
  isClone: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "create" | "edit" | "clone" | "archive" | "activate"
    payload?: LabCombo | string | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "create":
      return { ...initialState, showForm: true, isClone: false, combo: undefined }
    case "edit":
      return { ...state, showForm: true, isClone: false, combo: payload as LabCombo }
    case "clone":
      return { ...state, showForm: true, isClone: true, combo: payload as LabCombo }
    case "archive":
      return { ...state, confirmArchive: payload as string | undefined }
    case "activate":
      return { ...state, confirmActivate: payload as string | undefined }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ isClone, showForm, combo, confirmArchive, confirmActivate }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const create = () => {
    dispatch({ type: "create" })
  }

  const edit = (payload: LabCombo) => {
    dispatch({ type: "edit", payload })
  }

  const clone = (payload: LabCombo) => {
    dispatch({ type: "clone", payload })
  }

  const archive = (payload?: string) => {
    dispatch({ type: "archive", payload })
  }

  const activate = (payload?: string) => {
    dispatch({ type: "activate", payload })
  }

  return {
    isClone,
    showForm,
    combo,
    confirmArchive,
    confirmActivate,
    reset,
    create,
    edit,
    clone,
    archive,
    activate,
  }
}

type State = {
  combo?: LabCombo
  showForm: boolean
  isClone: boolean
  confirmArchive: string | undefined
  confirmActivate: string | undefined
}

type Props = { filters: AdvanceFilter & { searchText?: string }; filter(filters: AdvanceFilter): void }

export { LabsCombos }
