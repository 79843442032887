import { type StackedListItemProps, DataContainerSlideoverForm, StackedListContainer, useCrudReducer } from "commons"
import { type Location, asReference, codeableConceptAsString } from "fhir"

import { useOrganizationContext } from "organization"
import { getBadgeColor } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { useCreateRoom, useDeleteRoom } from "../../hooks"
import { getRoomInitialValues } from "../validations"
import { RoomForm } from "./RoomForm"
import type { RoomData } from "../../types"

const Rooms = () => {
  const { roomsWithDevices, currentOrganization, location } = useOrganizationContext()
  const { showSlide, initialValue, deleteIndex, add, edit, setDeleteIndex, reset } = useCrudReducer({
    defaultEntity: getRoomInitialValues(asReference(currentOrganization), asReference(location as Location)),
  })

  const { createRoom } = useCreateRoom(reset)
  const { removeRoom, isDeleting } = useDeleteRoom()

  const onSubmit = (room: RoomData) => createRoom(room)

  return (
    <DataContainerSlideoverForm
      formTitle="Room"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={roomsWithDevices.length > 0}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      form={<RoomForm />}
      onSubmit={onSubmit}
      onCancel={reset}
      deleteSupport={{
        showDelete: !!deleteIndex,
        isDeleting,
        onConfirmDelete: () => removeRoom(deleteIndex as string),
        onCancelDelete: () => setDeleteIndex(undefined),
      }}
    >
      <div className="bg-white flex-1 overflow-y-auto">
        <StackedListContainer
          data={roomsWithDevices}
          itemPadding
          itemModelBuilder={(item) =>
            roomModel(
              item,
              () => edit(item),
              () => setDeleteIndex(item.id as string),
            )
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

const roomModel = (room: RoomData, onEdit: () => void, onDelete: () => void): StackedListItemProps => ({
  leftData: [
    { lineItems: [{ name: "Room name", value: room.name }] },
    { lineItems: [{ name: "Room description", value: room.description }] },
    {
      lineItems: [
        { name: "Room type", value: room.type?.[0] ? codeableConceptAsString(room.type?.[0]) : "Unknow type" },
      ],
    },
    ...(room.devices.length
      ? [
          {
            lineItems: room.devices.map((device) => ({ name: "Device", value: device.display })),
          },
        ]
      : []),
  ],
  badge: getBadgeColor(room.status as string),
  menu: defaultEditRemoveMenu(onEdit, onDelete),
})

export { Rooms }
