import {
  faClockRotateLeft,
  faFileInvoiceDollar,
  faHandHoldingBox,
  faPaperPlane,
  faPills,
  faSyncAlt,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { useCallback, useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useSendToPatient } from "commons/hooks"
import {
  MedicationCancelDialog,
  MedicationOrderListItem,
  MedicationRescheduleDialog,
  useCancelMrOrder,
  usePrescriptionMrOrder,
} from "commons/meds"
import type { MedicationRequestOrderData } from "commons/types"
import { MEDICATION_PRODUCT_TYPE } from "data"
import { usePatientContext } from "patients"

import { useHoldMrOrder, useRescheduleMrOrder } from "../../hooks"

const MedicationOrdersList = ({ mrOrderData, reloadOrders }: Props) => {
  const [params, setParams] = useSearchParams()
  const { patientId } = usePatientContext()
  const {
    selectedOrder,
    showReschedule,
    showCancel,
    cancelReason,
    rescheduleDate,
    selectOrder,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
  } = useReducerState()

  const handleOnHideCancel = () => {
    hideCancelDialog()
    setCancelFutureOrder("skip")
    setRefundItemsAsWell(false)
  }

  const { cancelMrOrder, isCancelling } = useCancelMrOrder({
    context: MEDICATION_PRODUCT_TYPE.NUTRA,
    onSuccess: reloadOrders,
    onSettled: handleOnHideCancel,
  })
  const { holdMrOrder, isHolding } = useHoldMrOrder(reloadOrders)
  const { rescheduleMrOrder, isRescheduling } = useRescheduleMrOrder(reloadOrders)
  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()
  const { sendOrderToPatient } = useSendToPatient()

  const [cancelFutureOrder, setCancelFutureOrder] = useState("skip")
  const [refundItemsAsWell, setRefundItemsAsWell] = useState(false)

  const goToOrderDetails = (orderId: string) => {
    params.append("order", orderId)
    setParams(params)
  }

  const confirmAction = (action: string, _: string, accept: () => void) => {
    confirmDialog({
      message: `Are you sure you want to ${action}?`,
      header: "Confirmation",
      acceptLabel: "Ok",
      rejectLabel: "Cancel",
      rejectClassName: "button-default p-button-sm",
      acceptClassName: "button-primary p-button-sm",
      accept: accept,
    })
  }

  const onHold = (order: MedicationRequestOrderData) => {
    confirmAction("hold on this order", order.serviceRequest.id as string, () => {
      selectOrder(order)
      holdMrOrder(order.serviceRequest.id as string)
    })
  }

  const onCancel = (order: MedicationRequestOrderData) => {
    selectOrder(order)
    showCancelDialog()
  }

  const onReschedule = (order: MedicationRequestOrderData, currentDate: Date) => {
    selectOrder(order)
    updateRescheduleDate(currentDate)
    showRescheduleDialog()
  }

  const onPrescription = (order: MedicationRequestOrderData) => {
    selectOrder(order)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string })
  }

  const onPrescriptionRefresh = (order: MedicationRequestOrderData) => {
    selectOrder(order)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string, forceRefresh: true })
  }

  const showInvoice = (order: MedicationRequestOrderData) => {
    params.delete("subview")
    params.delete("order")
    params.set("view", "invoice")
    params.set("invoiceId", order.invoices?.[0]?.id as string)
    setParams(params)
  }

  const sendToPatient = (order: MedicationRequestOrderData) => {
    confirmAction("send this order to the patient", order.serviceRequest.id as string, () => {
      selectOrder(order)
      sendOrderToPatient(order.invoices?.[0]?.id as string)
    })
  }

  const activeActions = useCallback(
    (mrOrderData: MedicationRequestOrderData) => {
      const rescheduleDateString =
        mrOrderData.serviceRequest.occurrence?.dateTime ??
        mrOrderData.serviceRequest.authoredOn ??
        new Date().toISOString()
      let rescheduleDate = new Date(rescheduleDateString)
      if (rescheduleDate < new Date()) rescheduleDate = new Date()

      const dropdownItems = [
        {
          label: "Prescription",
          icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
          command: () => onPrescription(mrOrderData),
        },
        {
          label: "Reschedule",
          icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
          command: () => onReschedule(mrOrderData, rescheduleDate),
        },
        {
          label: "Refresh Prescription",
          icon: <FontAwesomeIcon icon={faSyncAlt} size="sm" className="mr-2" />,
          command: () => onPrescriptionRefresh(mrOrderData),
        },
        {
          label: "Hold",
          icon: <FontAwesomeIcon icon={faHandHoldingBox} size="sm" className="mr-2" />,
          command: () => onHold(mrOrderData),
        },
        ...(mrOrderData.invoices?.[0]?.id
          ? [
              {
                label: "See Invoice",
                icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
                command: () => showInvoice(mrOrderData),
              },
            ]
          : []),
        {
          label: "Send to patient",
          icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
          command: () => sendToPatient(mrOrderData),
        },
        {
          label: "Cancel",
          icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
          command: () => onCancel(mrOrderData),
        },
      ]

      return mrOrderData.serviceRequest.status === "active"
        ? dropdownItems
        : dropdownItems.filter((item) => item.label === "Prescription" || item.label === "Show Details")
    },
    [onPrescription, onReschedule, onPrescriptionRefresh, onHold, showInvoice, onCancel, sendToPatient],
  )

  return (
    <>
      <div className="overflow-hidden bg-white">
        <ul className="divide-y divide-gray-200">
          {mrOrderData.map((orderData) => (
            <MedicationOrderListItem
              key={orderData.serviceRequest.id}
              mrOrderData={orderData}
              isLoading={
                selectedOrder?.serviceRequest.id === orderData.serviceRequest.id &&
                (isCancelling || isHolding || isRescheduling || isGettingPrescription)
              }
              dropdownMenuItems={activeActions}
              onItemClicked={() => goToOrderDetails(orderData.serviceRequest.id as string)}
            />
          ))}
        </ul>
      </div>

      <ConfirmDialog />

      <MedicationRescheduleDialog
        visible={showReschedule}
        rescheduleDate={rescheduleDate}
        onReschedule={() => {
          rescheduleMrOrder({ id: selectedOrder?.serviceRequest.id as string, newDate: rescheduleDate })
          hideRescheduleDialog()
        }}
        onHide={hideRescheduleDialog}
        updateRescheduleDate={updateRescheduleDate}
      />

      <MedicationCancelDialog
        invoice={selectedOrder?.invoices?.[0]}
        visible={showCancel}
        cancelFutureOrder={cancelFutureOrder}
        refundItemsAsWell={refundItemsAsWell}
        isCancelling={isCancelling}
        onHide={handleOnHideCancel}
        cancelReason={cancelReason}
        onConfirm={() =>
          cancelMrOrder({
            serviceRequestId: selectedOrder?.serviceRequest.id as string,
            patientId: patientId as string,
            cancelReason: cancelReason as string,
            cancel_mode: cancelFutureOrder as string,
            refundItemsAsWell: refundItemsAsWell,
          })
        }
        updateCancelFutureOrder={setCancelFutureOrder}
        updateRefundItemsAsWell={setRefundItemsAsWell}
        updateCancelReason={updateCancelReason}
      />
    </>
  )
}

const initialState: State = {
  showReschedule: false,
  rescheduleDate: new Date(),
  showCancel: false,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type:
      | "select-order"
      | "show-reschedule-dialog"
      | "hide-reschedule-dialog"
      | "update-reschedule-date"
      | "show-prescription"
      | "hide-prescription"
      | "show-cancel-dialog"
      | "hide-cancel-dialog"
      | "update-cancel-reason"
    payload?: string | Date | boolean | MedicationRequestOrderData
  },
) => {
  switch (type) {
    case "select-order":
      return { ...state, selectedOrder: payload as MedicationRequestOrderData }
    case "show-reschedule-dialog":
      return { ...state, showReschedule: true }
    case "hide-reschedule-dialog":
      return { ...state, showReschedule: false }
    case "update-reschedule-date":
      return { ...state, rescheduleDate: payload as Date }
    case "show-cancel-dialog":
      return { ...state, showCancel: true }
    case "hide-cancel-dialog":
      return { ...state, showCancel: false, cancelReason: undefined }
    case "update-cancel-reason":
      return { ...state, cancelReason: payload as string }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ selectedOrder, showReschedule, rescheduleDate, showCancel, cancelReason }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const selectOrder = (order: MedicationRequestOrderData) => dispatch({ type: "select-order", payload: order })
  const showRescheduleDialog = () => dispatch({ type: "show-reschedule-dialog" })
  const hideRescheduleDialog = () => dispatch({ type: "hide-reschedule-dialog" })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "update-reschedule-date", payload: newDate })
  const showCancelDialog = () => dispatch({ type: "show-cancel-dialog" })
  const hideCancelDialog = () => dispatch({ type: "hide-cancel-dialog" })
  const updateCancelReason = (reason: string) => dispatch({ type: "update-cancel-reason", payload: reason })

  return {
    selectedOrder,
    showReschedule,
    showCancel,
    rescheduleDate,
    cancelReason,
    updateCancelReason,
    selectOrder,
    showRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    hideRescheduleDialog,
    updateRescheduleDate,
  }
}

type State = {
  selectedOrder?: MedicationRequestOrderData
  showReschedule: boolean
  showCancel: boolean
  rescheduleDate: Date
  cancelReason?: string
}

type Props = {
  mrOrderData: MedicationRequestOrderData[]
  reloadOrders(): void
}

export { MedicationOrdersList }
