import {
  faCalendarCheck,
  faCalendarDays,
  faCalendarPen,
  faPills,
  faSquarePollHorizontal,
} from "@fortawesome/pro-regular-svg-icons"
import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { type ServiceRequest, codeableConceptAsString, isMedicationRequest } from "fhir"
import type { FC } from "react"

import { type StackedListItemProps, EmptyMessage, StackedListContainer } from "commons"
import { formatsByTypes } from "data"
import { SYSTEM_VALUES } from "system-values"

const OrderHistory: FC<Props> = ({ orders, showOrder }) => {
  return orders?.length ? (
    <StackedListContainer
      data={orders}
      className="h-full w-full overflow-y-auto"
      itemModelBuilder={(item) => modelBuilder(item, showOrder)}
    />
  ) : (
    <EmptyMessage
      icon={faCalendarPen}
      message="No edits made to this order"
      subMessage={false}
      className="h-full content-center"
    />
  )
}

const modelBuilder = (order: ServiceRequest, showOrder: (orderId: string) => void): StackedListItemProps => {
  const orderMRs = order.basedOn?.filter(isMedicationRequest)
  const lifeFileId = order.identifier?.find(({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER)
  const medOrderIdentifier = order.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)
  const statusReason = order.reasonCode?.flatMap((cc) => codeableConceptAsString(cc, "")).filter((status) => !!status)

  const head = (
    <span className="inline-flex space-x-1">
      <p title="Order number">{`${medOrderIdentifier?.value ?? "Unspecified number"}`}</p>
      {!!lifeFileId && <p title="LifeFile Reference">({lifeFileId.value})</p>}
    </span>
  )

  return {
    leftData: [
      {
        lineItems: [{ name: "Order number", component: head }],
      },
      {
        lineItems: [
          {
            name: "Authored On",
            icon: faCalendarDays,
            value: order.authoredOn ? format(parseISO(order.authoredOn), formatsByTypes.LONG_DATE) : "Unspecified",
          },
          {
            name: "Ocurrence",
            icon: faCalendarCheck,
            value: order.occurrence?.dateTime
              ? format(parseISO(order.occurrence.dateTime), formatsByTypes.LONG_DATE)
              : "Unspecified",
          },
          { name: "Medication Requests", icon: faPills, value: String(orderMRs?.length ?? 0) },
          ...(statusReason?.length
            ? [{ name: "Reason Codes", icon: faSquarePollHorizontal, value: statusReason.join(", ") }]
            : []),
        ],
      },
    ],
    onClick: () => showOrder(order.id as string),
  }
}

type Props = { orders?: ServiceRequest[]; showOrder: (orderId: string) => void }

export { OrderHistory }
