import { type DialogProps, Dialog } from "primereact/dialog"
import { type ReactNode, useEffect, useState } from "react"

import { Button } from "./Buttons"

const ConfirmDialog = ({
  onConfirm,
  hideDialog,
  visible,
  headerTitle = "Confirmation",
  actionName = "Accept",
  rejectAction = "Close",
  actionType = "button",
  confirmText = "Are you sure you want to do this?",
  confirmElement,
  isLoading,
  waitToFinish,
  ...props
}: Props) => {
  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (buttonClicked && !waitToFinish && !isLoading) hideDialog()
  }, [buttonClicked, isLoading])

  const renderModalFooter = () => (
    <>
      <Button label={rejectAction} buttonStyle="default" size="xl" className="mr-3" onClick={hideDialog} />
      <Button
        type={actionType}
        label={actionName}
        loading={isLoading}
        size="xl"
        className="m-0"
        onClick={() => {
          onConfirm?.()
          setButtonClicked(true)
          !waitToFinish && hideDialog()
        }}
      />
    </>
  )

  return (
    <Dialog
      header={headerTitle}
      closable={true}
      draggable={false}
      resizable={false}
      visible={visible}
      style={{ width: "25vw" }}
      onHide={hideDialog}
      footer={renderModalFooter}
      {...props}
    >
      {confirmElement ?? <span>{confirmText}</span>}
    </Dialog>
  )
}

type Props = Omit<DialogProps, "onHide"> & {
  visible?: boolean
  headerTitle?: string
  confirmText?: string
  confirmElement?: ReactNode
  actionName?: string
  rejectAction?: string
  actionType?: "button" | "submit"
  isLoading?: boolean
  waitToFinish?: boolean
  onConfirm?(): void
  hideDialog(): void
}

export { ConfirmDialog }
