import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { MedicationStatement } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { medicationStatementQueryKeys } from "../query-keys"

const useUpdateMedicationStatement = (onSettled: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newMedicationStatement = async (medication: MedicationStatement) =>
    update("MedicationStatement", medication.id as string, medication)

  const { mutate: updateMedicationStatement, isPending: isUpdating } = useMutation({
    mutationFn: newMedicationStatement,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({
          queryKey: medicationStatementQueryKeys.list(patientId),
          refetchType: "all",
        })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: medicationStatementQueryKeys.list(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Medication Statement updated successfully!")
      // datadogLogs.logger.info(`Medication ${medication.id} Statement updated successfully!`, { medication })
    },
    onSettled,
  })

  return { updateMedicationStatement, isUpdating }
}

export { useUpdateMedicationStatement }
