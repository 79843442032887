import { useFormikContext } from "formik"
import { useMountEffect } from "primereact/hooks"
import type { FC } from "react"

import { colorPalette } from "data"

import { ColorPicker } from "../components/ColorPicker"
import { type FormFieldBaseProps, FormField } from "./FormField"

const DropdownColor: FC<Props> = ({ autoAssign, field, ...fieldProps }) => {
  const { getFieldMeta, setFieldValue } = useFormikContext()

  useMountEffect(() => {
    if (autoAssign && !getFieldMeta(field)?.value) {
      setFieldValue(field, colorPalette[Math.floor(Math.random() * (colorPalette.length + 1) + 0)]?.value)
    }
  })

  return (
    <FormField field={field} {...fieldProps}>
      {({ field: { value, onChange }, meta: { error } }) => (
        <ColorPicker id={field} value={value} error={error} handleChange={onChange} className="w-fit" />
      )}
    </FormField>
  )
}

type Props = { autoAssign?: boolean } & FormFieldBaseProps

export { DropdownColor }
