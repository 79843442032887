import { type MedicationRequest, codeableConceptAsString } from "fhir"
import pluralize from "pluralize"

import type { StackedListItemProps } from "commons"
import { getBadgeColor } from "utils"

const medicationModelBuilder = (mr: MedicationRequest): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Medication",
          value: codeableConceptAsString(mr.medication?.CodeableConcept),
        },
      ],
    },
    {
      lineItems: [
        {
          name: "Quantity",
          value: `${mr.dispenseRequest?.quantity?.value} ${pluralize(
            mr.dispenseRequest?.quantity?.unit ?? "item",
            mr.dispenseRequest?.quantity?.value,
          )}`,
        },
      ],
    },
  ],
  badge: getBadgeColor(mr.status),
})

export { medicationModelBuilder }
