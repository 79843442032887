import { useQuery } from "@tanstack/react-query"
import {
  type ActivityDefinition,
  type Appointment,
  type CarePlan,
  type CarePlanActivityArray,
  type CodeableConcept,
  type Composition,
  type Money,
  type Organization,
  type PlanDefinition,
  type PlanDefinitionActionArrayActionArray,
  type Reference,
  type Task,
  asReference,
  getResources,
  getResourcesByTypeAsIndex,
  isTask,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { MC_ACTIVITY_TYPE, PD_ACTION_DYNAMIC_VALUE } from "data"
import { mergeSort } from "utils"

import { plansQueryKeys } from "../query-keys"
import {
  type ComboDefinition,
  type MailTaskData,
  type PanelDefinition,
  type PlanData,
  PLAN_ACTION_CODES,
  PLAN_TYPES,
  TASK_CODES,
} from "../types"
import { getActionCode, getComboDefinition, getMailTaskOutput, getPDPerformer, isPDType } from "../utils"

const useCarePlans = ({
  patientId,
  enableQuery = true,
  planId,
  searchText,
  statusFilter,
  encounter,
}: {
  patientId: string
  enableQuery?: boolean
  planId?: string
  searchText?: string
  statusFilter?: string[]
  intent?: string
  encounter?: string
}) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.list(patientId, planId, searchText, statusFilter, encounter)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    enabled: enableQuery,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(planId
          ? {
              _id: planId,
              _include:
                "outcome-reference:Appointment,activity-reference:Task,instantiates-canonical:PlanDefinition,activity-instantiates-canonical:PlanDefinition,PlanDefinition:definition:PlanDefinition,PlanDefinition:children-definition:PlanDefinition,PlanDefinition:children-definition:ActivityDefinition,PlanDefinition:catalogHeader,Composition:author",
            }
          : {
              _query: "patient-plans",
              patient: patientId,
              ...(encounter ? { encounter } : {}),
              ...(searchText ? { title: searchText } : {}),
              ...(statusFilter?.length ? { status: statusFilter.join(",") } : {}),
            }),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplans = getResources<CarePlan>(bundle, "CarePlan")
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const tasks = getResources<Task>(bundle, "Task")
      const appointments = getResourcesByTypeAsIndex<Appointment>(bundle, "Appointment")
      const catalogs = getResourcesByTypeAsIndex<Composition>(bundle, "Composition")
      const organizations = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")
      const activityDefs = getResourcesByTypeAsIndex<ActivityDefinition>(
        bundle,
        "ActivityDefinition",
        ({ url, version }) => `${url}|${version}`,
      )

      return {
        careplans,
        planDefinitions,
        tasks,
        appointments,
        organizations,
        catalogs,
        activityDefs,
        total: bundle.total,
      }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { plans, activePDs, activeCPCount } = useMemo(() => {
    const carePlans = data?.careplans
    const organizations = data?.organizations ?? {}
    const catalogs = data?.catalogs ?? {}
    const planActivityDefinitions = data?.activityDefs ?? {}

    const { planDefinitions } = data?.planDefinitions?.reduce<PD_Data>(
      (acc, pd) => {
        const token = `${pd.url}|${pd.version}`
        if ([PLAN_TYPES.FOLLOW_UP, PLAN_TYPES.CLINICAL_PROTOCOL].includes(pd.type?.coding?.[0]?.code as string)) {
          const fuToken = isPDType(pd, PLAN_TYPES.CLINICAL_PROTOCOL)
            ? pd.action?.find((action) => action.code?.[0]?.coding?.[0]?.code === "enroll-follow-up-plan")?.definition
                ?.canonical
            : undefined
          return {
            pdReplacementToken: !fuToken ? acc.pdReplacementToken : { ...acc.pdReplacementToken, [fuToken]: token },
            planDefinitions: { ...acc.planDefinitions, [token]: pd },
          }
        } else return { ...acc, planDefinitions: { ...acc.planDefinitions, [token]: pd } }
      },
      { planDefinitions: {}, pdReplacementToken: {} },
    ) ?? { planDefinitions: {}, pdReplacementToken: {} }

    const indexedTasks =
      data?.tasks?.reduce<Record<string, Task>>(
        (acc, task) =>
          [TASK_CODES.SEND_EMAIL, TASK_CODES.SEND_CONSENT].includes(task.code?.coding?.[0]?.code as string)
            ? { ...acc, [task.id as string]: task }
            : acc,
        {},
      ) ?? {}

    const init = {
      plans: [] as PlanData[],
      activePDs: {} as Record<string, string>,
      activeCPCount: 0,
    }

    const plansData =
      carePlans?.reduce((acc, carePlan) => {
        const pdToken = carePlan.instantiatesCanonical?.[0] as string
        const planDefinition = planDefinitions[pdToken]

        const appointmentId =
          carePlan.activity?.find((a) => a.outcomeReference?.[0]?.resourceType === "Appointment")?.outcomeReference?.[0]
            ?.id ?? ""
        const appointment = data?.appointments[appointmentId]

        const actions =
          planDefinition.action?.reduce(
            (acc, action) => {
              const code = getActionCode(action.code?.[0])
              if (code) return { ...acc, [code]: action }
              else return { ...acc }
            },
            {} as Record<string, PlanDefinitionActionArrayActionArray>,
          ) ?? {}

        const actionLab: PlanDefinitionActionArrayActionArray | undefined = actions[PLAN_ACTION_CODES.CONFIGURE_LABS]
        const actionAlgorithm = actions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]
        const algorithmActivity = actionAlgorithm?.action
          ?.filter(({ code }) => code?.[0]?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_MC))
          ?.reduce<CarePlanActivityArray[]>((acts, act) => {
            const activityPD = planDefinitions[act.definition?.canonical as string]

            return activityPD
              ? [
                  ...acts,
                  {
                    enabled: true,
                    outcomeCodeableConcept: act.code,
                    outcomeReference: activityPD
                      ? [
                          {
                            resourceType: "PlanDefinition",
                            id: activityPD.id,
                            display: activityPD.title ?? activityPD.name,
                          } as Reference,
                        ]
                      : [],
                    detail: {
                      status: "in-progress",
                      kind: activityPD.name?.includes("survey") ? MC_ACTIVITY_TYPE.MC_SURVEY : MC_ACTIVITY_TYPE.MC,
                      instantiatesCanonical: [act.definition?.canonical as string],
                    },
                  },
                ]
              : [...acts]
          }, [])

        const mailTasks =
          carePlan.status === "draft"
            ? carePlan.activity
                ?.filter(({ reference }) => isTask(reference))
                .reduce<Record<string, MailTaskData>>((accTasks, activity, index) => {
                  const task = indexedTasks[activity.reference?.id as string]

                  if (!task) return accTasks

                  const emailActionDef = planDefinition.action?.find(
                    (ac) =>
                      ([PLAN_ACTION_CODES.CONFIGURE_EMAILS, PLAN_ACTION_CODES.SEND_CONSENT] as string[]).includes(
                        ac.code?.[0]?.coding?.[0]?.code as string,
                      ) && ac.id === activity.actionId,
                  )

                  const isRequired = emailActionDef?.requiredBehavior === "must"
                  const daysBeforeToMail = emailActionDef?.dynamicValue?.find(
                    (v) => v.path === PD_ACTION_DYNAMIC_VALUE.DAYS_BEFORE_APPOINTEMENT,
                  )?.expression?.expression
                  const defaultDate =
                    emailActionDef?.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.DEFAULT_DATE)
                      ?.expression?.expression === "today"
                      ? new Date().toISOString()
                      : undefined
                  const editable =
                    emailActionDef?.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.NOT_EDITABLE)
                      ?.expression?.expression !== "true"
                  const showable =
                    emailActionDef?.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.NOT_SHOWABLE)
                      ?.expression?.expression !== "true"

                  const taskOutput = emailActionDef?.action?.length
                    ? emailActionDef.action.reduce((acc, act) => {
                        const output = getMailTaskOutput(carePlan, act)
                        return [...acc, ...(output ? [output] : [])]
                      }, Array<{ label: string; canonical?: string; reusable?: boolean }>())
                    : emailActionDef
                      ? [getMailTaskOutput(carePlan, emailActionDef)]
                      : []

                  const taskOutputLabels = taskOutput.flatMap(({ label }) => label)
                  const display = taskOutputLabels.join(" & ")

                  return task
                    ? {
                        ...accTasks,
                        [task.id as string]: {
                          taskId: task.id as string,
                          restriction: { period: { start: task.restriction?.period?.start ?? defaultDate } },
                          label: display,
                          taskOutput,
                          activityIndex: index,
                          enabled: activity.enabled ?? false,
                          isRequired,
                          daysBeforeToMail: daysBeforeToMail && parseInt(daysBeforeToMail),
                          editable,
                          showable,
                          isConsent: emailActionDef?.code?.some(({ coding }) =>
                            coding?.some(({ code }) => code === PLAN_ACTION_CODES.SEND_CONSENT),
                          ),
                        } as MailTaskData,
                      }
                    : accTasks
                }, {}) ?? {}
            : {}

        const planReasonCodes = actionLab?.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.REASON_CODE)
          ?.expression?.expression
        const icd10 = planReasonCodes ? (JSON.parse(planReasonCodes) as CodeableConcept[]) : undefined

        // GET lab activity to check wich combos should be allowed
        const allowedCombos = carePlan.activity?.find(({ actionId }) => actionId === actionLab?.id)?.detail
          ?.instantiatesCanonical

        const combos =
          actionLab?.action
            ?.find(
              (a) =>
                a.code?.some(({ coding }) => coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_COMBO)) ||
                a.title === "Configure Combo",
            )
            ?.action?.reduce<Array<ComboDefinition>>((combos, pdRef) => {
              const comboPanel = allowedCombos?.includes(pdRef.definition?.canonical as string)
                ? planDefinitions[pdRef.definition?.canonical as string]
                : undefined
              const comboDef = comboPanel
                ? getComboDefinition({
                    comboPanel,
                    panelDefinitions: planDefinitions,
                    catalogs,
                    cids: {},
                    organizations,
                  })
                : ({} as ComboDefinition)

              return comboPanel ? [...combos, comboDef] : combos
            }, []) ?? []

        const panels =
          actionLab?.action
            ?.find(
              (a) =>
                a.code?.some(({ coding }) => coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_PANELS)) ||
                a.title === "Configure Required Panels",
            )
            ?.action?.reduce<Array<PanelDefinition>>((tests, pdRef) => {
              const panel = planDefinitions[pdRef.definition?.canonical as string]
              const performer = panel ? getPDPerformer(panel, catalogs, organizations) : {}

              return panel ? [...tests, { definition: panel, performer: performer }] : tests
            }, []) ?? []

        /* Handle sort combos by price. Promo combos at end */
        const { withPromo, sortedOnes } = mergeSort(
          combos,
          "price",
          (a: Money | undefined, b: Money | undefined) => (a?.value ?? 0) - (b?.value ?? 0),
        ).reduce(
          (acc, comboDef) => {
            const isPromoCombo = !!comboDef.promoCoding
            if (isPromoCombo) {
              acc.withPromo.push(comboDef)
            } else {
              acc.sortedOnes.push(comboDef)
            }
            return acc
          },
          { withPromo: [] as ComboDefinition[], sortedOnes: [] as ComboDefinition[] },
        )

        const clinicalProtocol = actions?.[PLAN_ACTION_CODES.CLINICAL_PROTOCOL]?.definition?.canonical

        return {
          plans: [
            ...acc.plans,
            {
              carePlan,
              definitionToken: pdToken,
              planDefinition,
              mailTasks,
              appointment,
              icd10,
              combos: [...sortedOnes, ...withPromo],
              performers: [
                ...Object.values(organizations)
                  .filter(({ type }) =>
                    type?.some(({ coding }) => coding?.some(({ code }) => code === "facility-provider")),
                  )
                  .flatMap((o) => asReference(o)),
              ],
              algorithmActivity,
              configureActions: { ...actions },
              suggestedPanels: panels,
              planActivityDefinitions,
              originalDefinitionToken: clinicalProtocol,
            },
          ],
          activePDs: ["draft", "active"].includes(carePlan.status)
            ? {
                ...acc.activePDs,
                [pdToken]: carePlan.status,
                ...(clinicalProtocol ? { [clinicalProtocol]: carePlan.status } : {}),
              }
            : acc.activePDs,
          activeCPCount: acc.activeCPCount + (carePlan.status === "active" ? 1 : 0),
        }
      }, init) ?? init

    return plansData
  }, [data])

  return {
    plans,
    activePDs,
    activeCPCount,
    isLoading,
    isFetching,
    total: data?.total ?? plans.length,
  }
}

type PD_Data = {
  planDefinitions: Record<string, PlanDefinition>
  pdReplacementToken: Record<string, string>
}

export { useCarePlans }
