import type { Annotation, ServiceRequest } from "fhir"
import type { FC } from "react"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import type { QueryKey } from "@tanstack/react-query"

import { useCrudReducer, useUpdateOrder } from "../hooks"
import { DataContainerForm, TextAreaField } from "../forms"

const OrderNote: FC<Props> = ({
  orderId,
  orderNote,
  queriesToInvalidate,
  className,
  contentClassName,
  inputRows = 10,
  buttonClassName,
}) => {
  const {
    initialValue,
    reset,
    showSlide: showForm,
    edit,
  } = useCrudReducer({ defaultEntity: { id: orderId, note: orderNote } as Pick<ServiceRequest, "note" | "id"> })

  const { updateOrder } = useUpdateOrder(reset, queriesToInvalidate)

  const onSubmit = (data: Pick<ServiceRequest, "note" | "id">) => {
    if (data.note?.[0]?.text === orderNote?.[0]?.text) {
      reset()
    } else {
      if (!data.note?.[0]?.text) delete data.note
      updateOrder(data)
    }
  }

  return (
    <DataContainerForm
      form={<TextAreaField field="note[0].text" className="pt-1" rows={inputRows} autoResize autoFocus />}
      formInitialValue={{ ...initialValue, id: orderId }}
      onSubmit={onSubmit}
      onCancel={reset}
      enableReinitialize
      disableSave={false}
      showForm={showForm}
      hasData={!!orderNote?.[0]?.text}
      itemTitle="note"
      itemTitleNotFound="note"
      customEmptyAddButtonText="Add note"
      cancelButtonLabel="Cancel"
      className={className}
      onButtonAddClick={() => edit({ note: [...(orderNote ?? [])] })}
      subMessageDataNotFound="Get started by adding a new note"
      customAddButton={<></>}
    >
      {orderNote?.[0]?.text && (
        <div className={classNames("inline-flex w-full space-x-3 items-start", contentClassName)}>
          <p className="text-sm w-full whitespace-pre-line">{orderNote[0].text}</p>
          <FontAwesomeIcon
            icon={faEdit}
            className={classNames(
              "mt-1 text-lg text-gray-400 hover:text-primary-hover cursor-pointer",
              buttonClassName,
            )}
            onClick={() => edit({ note: [...orderNote] })}
          />
        </div>
      )}
    </DataContainerForm>
  )
}

type Props = {
  orderId: string
  orderNote: Annotation[] | undefined
  className?: string
  contentClassName?: string
  inputRows?: number
  queriesToInvalidate: QueryKey
  buttonClassName?: string
}

export { OrderNote }
