import { type Reference, type ResourceObject, asReferenceWithFallback } from "fhir"
import { AutoComplete } from "primereact/autocomplete"
import { Chip } from "primereact/chip"
import { type Dispatch, type ReactNode, type SetStateAction, useState } from "react"

import { SkeletonLoader } from "./SkeletonLoader"

const AutocompleteRefsWithChips = ({
  suggestionList,
  noItemFound = "No items found",
  filter,
  setFilter,
  selectedReference,
  setSelectedReference,
  onSearch,
  isLoading,
  itemTemplate,
}: ACProps) => {
  const [customFilter, setCustomFilter] = useState("")
  const [filteredRefs, setFilteredRefs] = useState(suggestionList)

  const search = () => {
    onSearch?.() ??
      setFilteredRefs(
        suggestionList?.filter(
          (ref) =>
            asReferenceWithFallback(ref, "")
              .display?.toLowerCase()
              ?.includes((filter ?? customFilter).toLowerCase()) &&
            !selectedReference.some((sRef) => sRef.id === ref.id),
        ),
      )
  }

  const updateFilter = (filter: string) => (setFilter ? setFilter(filter) : setCustomFilter(filter))

  const suggestions = onSearch ? suggestionList : filteredRefs

  return (
    <div className="flex flex-col">
      <AutoComplete
        className="p-inputtext-sm mb-3"
        inputClassName="flex-1"
        placeholder="Search"
        suggestions={suggestions}
        itemTemplate={(item) => (itemTemplate ? itemTemplate(item) : item?.display)}
        onChange={(e) => {
          if (typeof e.value === "string") {
            if (e.value === noItemFound) return
            updateFilter(e.value)
          } else {
            setSelectedReference((refs) => [...refs, asReferenceWithFallback(e.value, "")])
            updateFilter("")
          }
        }}
        completeMethod={search}
        value={filter ?? customFilter}
        showEmptyMessage
        emptyMessage={noItemFound}
        dropdownMode="blank"
        dropdown
      />
      <div className="flex flex-wrap gap-2 h-7">
        {isLoading && !selectedReference.length ? (
          <SkeletonLoader
            loaderType="plain-text"
            repeats={2}
            containerClassName="flex gap-2"
            skeletonItemClassName="w-24"
          />
        ) : (
          selectedReference.map((ref) => (
            <Chip
              key={ref.id}
              label={ref.display ?? ref.id}
              className="text-xs bg-white border border-gray-500 whitespace-nowrap"
              removable
              onRemove={() => setSelectedReference((refs) => refs.filter((r) => r.id !== ref.id))}
            />
          ))
        )}
      </div>
    </div>
  )
}

type ACProps = {
  isLoading?: boolean
  suggestionList?: Reference[] | ResourceObject[]
  noItemFound?: string
  selectedReference: Reference[]
  setSelectedReference: Dispatch<SetStateAction<Reference[]>>
  filter?: string
  setFilter?(filter: string): void
  onSearch?(): void
  itemTemplate?(item: Reference | ResourceObject): ReactNode
}

export { AutocompleteRefsWithChips }
