import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { type FC, useMemo, useState } from "react"
import type { Questionnaire, QuestionnaireResponse } from "fhir"

import { Button } from "../../components/Buttons"
import type { QuestionnaireData } from "../../types"

const PanelQuestionnairesModal: FC<Props> = ({ panels, onHide, onSave, waitToFinish, isSaving }) => {
  const questionnaires = useMemo(() => {
    const uniqueQuestionnaires = panels?.reduce((acc, panel) => {
      if (panel.questionnaires) {
        const panelUniqueQuestionnaires = panel.questionnaires.flatMap((q) => {
          const existingQResponse = panel.questionnairesResponses?.find(
            (qr) => qr?.questionnaire === `${q.url}|${q.version}`,
          )

          const response = existingQResponse
            ? q.item?.[0]?.answerOption?.findIndex(
                (ao) =>
                  JSON.stringify(ao.value ?? {}) ===
                  JSON.stringify(existingQResponse?.item?.[0]?.answer?.[0]?.value ?? {}),
              ) ?? 0
            : 0

          return acc.find((uQ) => uQ.questionnaire.id === q.id) ? [] : { questionnaire: q, response }
        })
        return [...acc, ...panelUniqueQuestionnaires]
      }
      return acc
    }, new Array<QuestionnaireData>())

    return uniqueQuestionnaires
  }, [panels])

  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData[]>(questionnaires)

  const handleSelect = (questionnaireIndex: number, asnswerOptionIndex: number) => {
    setQuestionnaireData([
      ...questionnaireData.slice(0, questionnaireIndex),
      { ...questionnaireData[questionnaireIndex], response: asnswerOptionIndex },
      ...questionnaireData.slice(questionnaireIndex + 1),
    ])
  }

  return (
    <Dialog
      appendTo={"self"}
      header="Please answer to the following questions:"
      modal
      visible={!!questionnaireData.length || waitToFinish || isSaving}
      onHide={onHide}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={
        <div className="pt-2">
          <Button label="Cancel" className="p-button button-default" onClick={onHide} disabled={isSaving} />
          <Button
            label="Save"
            className="p-button button-primary"
            onClick={() => onSave(questionnaireData)}
            loading={isSaving}
            disabled={isSaving}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        {questionnaireData?.map((qData, index) => (
          <QuestionnaireSelect
            qData={qData}
            onSelect={handleSelect}
            index={index}
            key={qData.questionnaire.id ?? index}
          />
        ))}
      </div>
    </Dialog>
  )
}

const QuestionnaireSelect = ({ index, qData, onSelect }: SelectProps) => {
  const { options, selectedAnswer, isCodingType } = useMemo(() => {
    const options = qData.questionnaire.item?.[0].answerOption?.map(
      (ao) => ao.value?.Coding ?? ao.value?.string ?? ao.value?.date ?? ao.value?.integer ?? ao.value?.time,
    )
    const selectedAnswer = qData.questionnaire.item?.[0]?.answerOption?.[qData.response ?? 0]?.value
    return { options, selectedAnswer, isCodingType: options?.every((op) => typeof op === "object") }
  }, [qData])

  const handleLocalSelect = (code: string | number) => {
    const answerIndex =
      qData.questionnaire.item?.[0]?.answerOption?.findIndex(
        (aw) =>
          aw.value?.Coding?.code === code ||
          aw.value?.date === code ||
          aw.value?.string === code ||
          aw.value?.integer === code ||
          aw.value?.time === code,
      ) ?? 0

    onSelect(index, answerIndex < 0 ? 0 : answerIndex)
  }

  return (
    <div className="flex flex-col gap-1">
      <label htmlFor={qData.questionnaire.id}>{qData.questionnaire.item?.[0]?.text}</label>
      <Dropdown
        id={qData.questionnaire.id}
        options={options}
        value={
          selectedAnswer?.Coding?.code ??
          selectedAnswer?.date ??
          selectedAnswer?.string ??
          selectedAnswer?.integer ??
          selectedAnswer?.time
        }
        onChange={(e) => handleLocalSelect(e.value)}
        optionLabel={isCodingType ? "display" : undefined}
        optionValue={isCodingType ? "code" : undefined}
      />
    </div>
  )
}

type SelectProps = {
  index: number
  qData: QuestionnaireData
  onSelect: (questionnaireIndex: number, answerOptionIndex: number) => void
}

type Props = {
  waitToFinish?: boolean
  isSaving?: boolean
  panels: { questionnaires?: Questionnaire[]; questionnairesResponses?: QuestionnaireResponse[] }[]
  onHide(): void
  onSave(questionnaireData: QuestionnaireData[]): void
}

export { PanelQuestionnairesModal }
