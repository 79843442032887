import { type FC, type PropsWithChildren, useMemo } from "react"
import { asReference, type Reference } from "fhir"

import { EncounterContext } from "../hooks/useEncounterContext"
import type { EncounterData } from "../types"

const EncounterDataProvider: FC<PropsWithChildren<Props>> = ({
  encounterData,
  totalAllergies,
  totalConditions,
  totalEnteredLabs,
  totalFH,
  totalLabOrders,
  totalMedStatements,
  totalPlans,
  totalProcedures,
  totalQs,
  totalSocialHistory,
  totalVitals,
  children,
}) => {
  const planRefs = useMemo(
    () =>
      encounterData?.plans?.reduce(
        (acc, { carePlan }) => ({ ...acc, [carePlan.id as string]: asReference(carePlan) }),
        {} as Record<string, Reference>,
      ),
    [encounterData],
  )

  return (
    <EncounterContext.Provider
      value={{
        encounterData,
        totalAllergies,
        totalConditions,
        totalEnteredLabs,
        totalFH,
        totalLabOrders,
        totalMedStatements,
        totalPlans,
        totalProcedures,
        totalQs,
        totalSocialHistory,
        totalVitals,
        planRefs,
      }}
    >
      {children}
    </EncounterContext.Provider>
  )
}

type Props = {
  encounterData?: EncounterData
  totalAllergies: number
  totalConditions: number
  totalEnteredLabs: number
  totalFH: number
  totalLabOrders: number
  totalMedStatements: number
  totalPlans: number
  totalProcedures: number
  totalQs: number
  totalSocialHistory: number
  totalVitals: number
}

export { EncounterDataProvider }
