import { useMutation } from "@tanstack/react-query"
import type { List, Parameters } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { patient360QueryKeys } from "../query-keys"
import type { PersonOption } from "../types"

const useCWSetup = (patientId: string, onSuccess?: () => void) => {
  const { operationRequest } = useClient()
  const queryKey = patient360QueryKeys.cwSetup(patientId)

  const cwSetup = ({ personsSelection, isNew }: { personsSelection: PersonOption[]; isNew: boolean }) => {
    const selectedList: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: personsSelection
        .filter(({ selected }) => selected)
        .map(({ id }) => ({ item: { resourceType: "Person", id } })),
    }

    const unselectedList: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: personsSelection
        .filter(({ selected }) => !selected)
        .map(({ id }) => ({ item: { resourceType: "Person", id } })),
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "selected",
          resource: selectedList,
        },
        {
          name: "not-selected",
          resource: unselectedList,
        },
        {
          name: "new",
          value: { boolean: isNew },
        },
      ],
    }
    return operationRequest({ endpoint: "Patient", method: "POST", operation: "cw-setup", id: patientId, parameters })
  }

  const { mutate: setup, isPending } = useMutation({
    mutationKey: queryKey,
    mutationFn: cwSetup,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: () => {
      onSuccess?.()
    },
  })

  return { setup, isLoading: isPending }
}

export { useCWSetup }
