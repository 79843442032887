import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { type HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { useEffect, useState } from "react"

import { useAuth } from "security"

type TUseSignalR = {
  url: string
}

export const useSignalR = ({ url }: TUseSignalR): HookReturn => {
  const [connection, setConnection] = useState<HubConnection | undefined>(undefined)
  const [connectionFailure, setConnectionFailure] = useState(false)
  const [retryAttemps, setRetryAttemps] = useState(0)

  const appInsights = useAppInsightsContext()
  const { user } = useAuth()
  const token = user?.token?.startsWith("Bearer ") ? user.token.slice(7) : undefined

  useEffect(() => {
    if (url && token) {
      const signalRConnection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: ({ previousRetryCount }) => {
            setRetryAttemps(previousRetryCount + 1)
            return 30000
          },
        })
        .configureLogging(LogLevel.None)
        .build()

      signalRConnection.onreconnected(() => {
        setRetryAttemps(0)
      })

      signalRConnection
        ?.start()
        .then(function () {
          setConnection(signalRConnection)
        })
        .catch(function (err) {
          setConnectionFailure(true)
          appInsights?.trackException(
            { exception: new Error(err), severityLevel: SeverityLevel.Error },
            {
              action: "signalRConnection start",
            },
          )
        })
    }
  }, [appInsights, url, token])

  const joinGroup = async (groupId: string) =>
    await connection
      ?.invoke("JoinGroup", groupId)
      .then(() => true)
      .catch(() => false)

  const leaveGroup = async (groupId: string) =>
    await connection
      ?.invoke("LeaveGroup", groupId)
      .then(() => true)
      .catch(() => false)

  return { connection, connectionFailure, retryAttemps, joinGroup, leaveGroup }
}

type HookReturn = {
  connection?: HubConnection
  connectionFailure: boolean
  retryAttemps: number
  joinGroup(groupId: string): Promise<boolean | undefined>
  leaveGroup(groupId: string): Promise<boolean | undefined>
}
