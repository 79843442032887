import { useFormikContext } from "formik"
import { asReference } from "fhir"
import { useMemo } from "react"

import {
  AutocompleteCodeableConceptField,
  AutocompleteRefsWithChips,
  FormField,
  InputField,
  ValueSetIds,
} from "commons"
import { useOrganizationContext } from "organization"

import type { RoomData } from "../../types"

const RoomForm = () => {
  const { devices: orgDevices } = useOrganizationContext()

  const {
    values: { devices, id },
    setFieldValue,
  } = useFormikContext<RoomData>()
  const availableDevices = useMemo(
    () =>
      orgDevices
        .filter((device) => !device.location?.id || device.location.id === id)
        .map((device) => asReference(device)),
    [orgDevices, devices],
  )

  return (
    <div className="flex flex-col space-y-4">
      <InputField field="name" label="Name" validation={(value) => (!value ? "Name is required" : undefined)} />
      <InputField field="description" label="Description" />
      <AutocompleteCodeableConceptField
        field="type[0]"
        label="Type"
        valueSetId={ValueSetIds.SERVICE_DELIVERY_LOCATION_TYPE}
        className="p-fluid"
      />
      <FormField field="devices" label="Devices">
        <AutocompleteRefsWithChips
          suggestionList={availableDevices}
          selectedReference={devices ?? []}
          setSelectedReference={(refs) => setFieldValue("devices", typeof refs === "function" && refs(devices ?? []))}
        />
      </FormField>
    </div>
  )
}

export { RoomForm }
