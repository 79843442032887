import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  type Bundle,
  type BundleEntryArray,
  type QuestionnaireResponse,
  type QuestionnaireResponseItemArrayItemArray,
  type Reference,
  type ServiceRequest,
  type Specimen,
  asReference,
  getResources,
} from "fhir"
import { v4 } from "uuid"

import { useClient } from "api"
import type { CustomError } from "commons"
import type {
  LaboratoryOrder,
  LaboratoryOrderCombo,
  LaboratoryOrderPanel,
  LaboratoryQuestionnaireData,
} from "commons/labs"
import { formatsByTypes, isLabOrder } from "data"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { displayNotificationSuccess, formatDate } from "utils"

import { labOrdersQueryKeys } from "../query-keys"

const useCreateLabOrder = (closeForm: () => void, onSuccess?: (order: ServiceRequest) => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const newLabOrder = async ({
    order,
    panels,
    deletedPanels,
    questionnaireData,
    specimenDate,
    bloodDrawnInOffice,
    combo,
    deletedComboId,
  }: LaboratoryOrder) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [],
    }

    // Specimen create entry
    const createSpecimenEntry = getCreateSpecimenEntry(order, bloodDrawnInOffice, specimenDate)
    if (createSpecimenEntry) {
      bundle.entry = [createSpecimenEntry]
    }

    // Specimen delete entry
    const deleteSpecimenEntry = getDeleteSpecimenEntry(order, bloodDrawnInOffice)
    if (deleteSpecimenEntry) {
      bundle.entry = [...(bundle.entry ?? []), deleteSpecimenEntry]
    }

    // QuestionnaireResponses create entries
    const qrCreateEntries = getPanelQrCreateEntries(order, panels, bloodDrawnInOffice, questionnaireData)
    if (qrCreateEntries) {
      bundle.entry = [...(bundle.entry ?? []), ...qrCreateEntries]
    }

    // QuestionnaireResponses delete entries (when BDIO is disabled but QRs present)
    const qrDeleteEntries = getPanelQrDeleteEntries(panels, bloodDrawnInOffice)
    if (qrDeleteEntries) {
      bundle.entry = [...(bundle.entry ?? []), ...qrDeleteEntries]
    }

    // Tests create entries
    const panelsCreateEntries = getCreatePanelEntries(order, panels, qrCreateEntries)
    bundle.entry = bundle.entry ? [...bundle.entry, ...panelsCreateEntries] : panelsCreateEntries

    // Deleted panels linked QRs entries
    const deletedQrsFromDeletedPanels = getDeletePanelQrEntries(deletedPanels)
    if (deletedQrsFromDeletedPanels) {
      bundle.entry = bundle.entry ? [...bundle.entry, ...deletedQrsFromDeletedPanels] : deletedQrsFromDeletedPanels
    }

    // Deleted panels entries, includes those from combo
    const deletedPanelsEntries = getDeletedPanelsEntries(deletedPanels)
    if (deletedPanelsEntries) {
      bundle.entry = bundle.entry ? [...bundle.entry, ...deletedPanelsEntries] : deletedPanelsEntries
    }

    // Combo panels create entries
    const comboPanelsCreateEntries = getCreatePanelEntries(order, combo?.panels ?? [], qrCreateEntries)
    if (comboPanelsCreateEntries && comboPanelsCreateEntries.length > 0) {
      bundle.entry = bundle.entry ? [...bundle.entry, ...comboPanelsCreateEntries] : comboPanelsCreateEntries
    }

    // Add panels to order basedOn
    order.basedOn = panelsCreateEntries?.reduce((prev, { fullUrl, resource }) => {
      const sr = resource as ServiceRequest
      if (sr.id) {
        const { id, resourceType } = sr
        return [...prev, { id, resourceType }]
      }

      return [...prev, { uri: fullUrl }]
    }, [] as Reference[])

    // Combo create entry
    if (combo) {
      const comboCreateEntry = getComboCreateEntry(combo, comboPanelsCreateEntries)
      bundle.entry = bundle.entry ? [...bundle.entry, comboCreateEntry] : [comboCreateEntry]

      const comboReference = combo.laboratoryCombo.id
        ? { id: combo.laboratoryCombo.id, resourceType: "ServiceRequest" }
        : { uri: comboCreateEntry.fullUrl }

      order.basedOn = [...order.basedOn, comboReference]
    }

    // Combo delete entry
    if (deletedComboId) {
      const deletedComboEntry = getDeletedComboEntry(deletedComboId)
      bundle.entry = bundle.entry ? [...bundle.entry, deletedComboEntry] : [deletedComboEntry]
    }

    // Add specimen reference to order
    if (createSpecimenEntry && !order.specimen?.[0]?.id) {
      order.specimen = [{ uri: createSpecimenEntry.fullUrl }]
    }

    if (deleteSpecimenEntry) {
      delete order.specimen
    }

    bundle.entry = [
      ...bundle.entry,
      {
        resource: { ...order, resourceType: "ServiceRequest" },
        request: {
          method: order.id ? "PUT" : "POST",
          url: `/ServiceRequest${order.id ? `/${order.id}` : ""}`,
        },
      },
    ]

    return transaction(bundle)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: newLabOrder,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({
          queryKey: labOrdersQueryKeys.withPatientID(context?.order.subject.id),
          type: context?.order.subject.id ? "all" : undefined,
        })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (data, { order }) => {
      await queryClient.invalidateQueries({
        queryKey: labOrdersQueryKeys.withPatientID(order.subject.id),
        refetchType: "active",
      })
      queryClient.refetchQueries({
        queryKey: order.subject.id ? ordersQueryKeys.count.withPatientId(order.subject.id) : ordersQueryKeys.count.all,
        type: order.subject.id ? "all" : undefined,
      })
      const srs = getResources<ServiceRequest>(data as Bundle, "ServiceRequest")
      const newOrder = srs.find((sr) => isLabOrder(sr))

      onSuccess?.(order?.id ? order : (newOrder as ServiceRequest))
      displayNotificationSuccess(`Laboratory order ${order?.id ? "updated" : "created"} successfully!`)
      // datadogLogs.logger.info(`Laboratory order ${order?.id ? "updated" : "created"} successfully!`, { id: order?.id })
    },
    onSettled: closeForm,
  })

  const getCreateSpecimenEntry = (
    order: ServiceRequest,
    bloodDrawnInOffice?: boolean,
    specimenDate?: string,
  ): BundleEntryArray | undefined => {
    if (bloodDrawnInOffice) {
      const specimenId = order.specimen?.[0]?.id
      return {
        ...(!specimenId ? { fullUrl: `urn:uuid:${v4()}` } : {}),
        request: {
          method: specimenId ? "PUT" : "POST",
          url: `Specimen${specimenId ? `/${specimenId}` : ""}`,
        },
        resource: {
          subject: order.subject,
          receivedTime: specimenDate,
          resourceType: "Specimen",
        } as Specimen,
      }
    }
  }

  const getDeleteSpecimenEntry = (
    order: ServiceRequest,
    bloodDrawnInOffice?: boolean,
  ): BundleEntryArray | undefined => {
    const specimenId = order.specimen?.[0]?.id
    if (!bloodDrawnInOffice && specimenId) {
      return {
        request: {
          method: "DELETE",
          url: `Specimen/${specimenId}`,
        },
      }
    }
  }

  const getPanelQrCreateEntries = (
    order: ServiceRequest,
    panels: LaboratoryOrderPanel[],
    bloodDrawnInOffice?: boolean,
    questionnaireData?: LaboratoryQuestionnaireData[],
  ): BundleEntryArray[] | undefined => {
    if (bloodDrawnInOffice && questionnaireData) {
      const existingQrs = panels.reduce((acc, panel) => {
        return [...acc, ...(panel.questionnairesResponses ?? [])]
      }, new Array<QuestionnaireResponse>())

      const qrEntries = questionnaireData.map((qData) => {
        const qItem = qData?.questionnaire.item?.[0]
        const qCanonical = `${qData.questionnaire.url}|${qData.questionnaire.version}`
        const qAnswer = qItem?.answerOption?.[qData?.response ?? 0].value ?? {}

        const qrResponseItem: QuestionnaireResponseItemArrayItemArray = {
          text: qItem?.text,
          answer: [
            {
              value: {
                ...qAnswer,
              },
            },
          ],
          linkId: qItem?.code?.[0].code as string,
        }

        const existingQr = existingQrs.find((qr) => qr.questionnaire === qCanonical)

        const updatedQr: QuestionnaireResponse = existingQr
          ? { ...existingQr, item: [qrResponseItem] }
          : {
              questionnaire: qCanonical,
              status: "completed",
              resourceType: "QuestionnaireResponse",
              item: [qrResponseItem],
              subject: order.subject,
              authored: formatDate(new Date(), formatsByTypes.ISO_8601_DATETIME),
            }

        return {
          ...(!existingQr ? { fullUrl: `urn:uuid:${v4()}` } : {}),
          request: {
            method: existingQr ? "PUT" : "POST",
            url: `QuestionnaireResponse${existingQr ? `/${existingQr.id}` : ""}`,
          },
          resource: updatedQr,
        }
      })

      return qrEntries
    }
  }

  const getPanelQrDeleteEntries = (
    panels: LaboratoryOrderPanel[],
    bloodDrawnInOffice?: boolean,
  ): BundleEntryArray[] | undefined => {
    if (!bloodDrawnInOffice) {
      return panels.reduce((acc, panel) => {
        const panelQrEntries = panel.questionnairesResponses?.map((qr) => ({
          request: {
            method: "DELETE",
            url: `QuestionnaireResponse/${qr.id}`,
          },
        }))
        return [...acc, ...(panelQrEntries ?? [])]
      }, new Array<BundleEntryArray>())
    }
  }

  const getCreatePanelEntries = (
    _: ServiceRequest,
    panels: LaboratoryOrderPanel[],
    panelQRsEntries?: BundleEntryArray[],
  ): BundleEntryArray[] => {
    const srPanelsEntry = panels.map((panel) => {
      const {
        profile: { id },
      } = panel

      // Create panel supporting info references array
      // Use created or updated QR entries or fallback to previously saved supportingInfo
      const panelSupportingInfo =
        panel.questionnaires?.flatMap((q) => {
          const qrEntry = panelQRsEntries?.find(
            (qre) => (qre.resource as QuestionnaireResponse).questionnaire === `${q.url}|${q.version}`,
          )

          if (!qrEntry) {
            return []
          }

          const qResponse = qrEntry?.resource as QuestionnaireResponse
          return qResponse?.id ? asReference(qResponse) : { uri: qrEntry?.fullUrl }
        }) ?? panel.profile.supportingInfo

      return {
        ...(!id ? { fullUrl: `urn:uuid:${v4()}` } : {}),
        request: {
          method: id ? "PUT" : "POST",
          url: `ServiceRequest${id ? `/${id}` : ""}`,
        },
        resource: {
          ...panel.profile,
          resourceType: "ServiceRequest",
          supportingInfo: panelSupportingInfo,
        },
      }
    })

    return srPanelsEntry
  }

  // Get entries to delete qr for panels to be deleted
  const getDeletePanelQrEntries = (deletedPanels?: LaboratoryOrderPanel[]) => {
    return deletedPanels?.reduce((acc, panel) => {
      const entries = panel.questionnairesResponses?.map((qr) => ({
        request: {
          method: "DELETE",
          url: `QuestionnaireResponse/${qr.id}`,
        },
      }))
      return [...acc, ...(entries ?? [])]
    }, new Array<BundleEntryArray>())
  }

  return { createLabOrder: mutate, isCreating: isPending }
}

const getDeletedPanelsEntries = (deletedPanels?: LaboratoryOrderPanel[]): BundleEntryArray[] | undefined => {
  return deletedPanels
    ?.filter((panel) => !!panel.profile.id)
    .map((panel) => {
      return {
        request: {
          method: "DELETE",
          url: `ServiceRequest/${panel.profile.id}`,
        },
      }
    }) as BundleEntryArray[]
}

const getComboCreateEntry = (
  combo: LaboratoryOrderCombo,
  createComboPanelEntries: BundleEntryArray[],
): BundleEntryArray => {
  combo.laboratoryCombo.basedOn = createComboPanelEntries?.reduce((prev, { fullUrl, resource }) => {
    const sr = resource as ServiceRequest
    if (sr.id) {
      const { id, resourceType } = sr
      return [...prev, { id, resourceType }]
    }

    return [...prev, { uri: fullUrl }]
  }, [] as Reference[])

  const id = combo.laboratoryCombo.id

  return {
    ...(!id ? { fullUrl: `urn:uuid:${v4()}` } : {}),
    request: {
      method: id ? "PUT" : "POST",
      url: `ServiceRequest${id ? `/${id}` : ""}`,
    },
    resource: {
      ...combo.laboratoryCombo,
      resourceType: "ServiceRequest",
    },
  }
}

const getDeletedComboEntry = (deletedComboId: string): BundleEntryArray => {
  return {
    request: {
      method: "DELETE",
      url: `ServiceRequest/${deletedComboId}`,
    },
  } as BundleEntryArray
}

export { useCreateLabOrder }
