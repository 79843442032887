import { useIsMutating } from "@tanstack/react-query"
import { type Patient, asReference } from "fhir"
import { type FormikHelpers, type FormikProps, Form, Formik } from "formik"

import { useChartContext } from "chart-view"
import { Button, EmailField, PhoneField, useUpdateUserEmail } from "commons"

import { usePatchPatient } from "../../hooks"
import { sanitizeTelecom } from "../validations"

const TelecomForm = ({ patient }: Props) => {
  const initialValues: Partial<Patient> = { telecom: patient.telecom }

  const isCheckingEmail = useIsMutating({ mutationKey: ["check-email-exists"], status: "pending" })

  const { navigateToOriginModule } = useChartContext()

  const { patchPatient, isPatching } = usePatchPatient(navigateToOriginModule)
  const { updateEmail, isPending } = useUpdateUserEmail(patient.id)

  const handleSubmit = (telecomData: Partial<Patient>, fomikHelpers: FormikHelpers<Partial<Patient>>) => {
    if (
      !!telecomData.telecom?.[0]?.value &&
      JSON.stringify(telecomData.telecom?.[0] ?? {}) !== JSON.stringify(patient.telecom?.[0] ?? {})
    ) {
      updateEmail({ email: telecomData.telecom?.[0]?.value, resourceId: patient.id as string, resourceType: "Patient" })
    }
    if (JSON.stringify(telecomData.telecom?.[1] ?? {}) !== JSON.stringify(patient.telecom?.[1] ?? {}))
      patchPatient({
        patientId: patient.id as string,
        patientData: { telecom: sanitizeTelecom(telecomData.telecom), meta: patient.meta },
      })
    fomikHelpers.setSubmitting(false)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ isSubmitting, isValid }: FormikProps<Partial<Patient>>) => (
        <Form className="flex flex-1 flex-col overflow-hidden divide-y">
          <fieldset className="relative p-fluid flex flex-1 flex-col overflow-y-auto grow px-3" id="telecom">
            <legend>Telecom</legend>
            <EmailField
              field="telecom[0].value"
              label="Email"
              validateRules
              initialValue={initialValues.telecom?.[0].value}
              validateDuplicate
              subject={asReference(patient)}
            />
            <PhoneField
              unmask
              field="telecom[1].value"
              className="mt-2"
              label="Phone"
              validation={(value) => (!value ? "Phone is required" : undefined)}
            />
          </fieldset>
          <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
            <Button
              type="submit"
              label="Update"
              size="lg"
              loading={isPatching || isPending || isSubmitting}
              disabled={!isValid || isCheckingEmail > 0}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

type Props = {
  patient: Patient
}

export { TelecomForm }
