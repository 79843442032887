import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { FamilyMemberHistory } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { familyHistoryQueryKeys } from "../query-keys"

const useUpdateFamilyHistory = (hideForm: () => void, patientId: string) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updatedFamilyHistory = async (familyHistory: FamilyMemberHistory) =>
    update("FamilyMemberHistory", familyHistory?.id as string, familyHistory)

  const { mutate: updateFamilyHistory, isPending: isUpdating } = useMutation({
    mutationFn: updatedFamilyHistory,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: familyHistoryQueryKeys.history(patientId), type: "all" })
      displayNotificationSuccess("Family history updated successfully!")
      // datadogLogs.logger.info("Family history updated successfully!", { familyHistory })
    },
    onSettled: hideForm,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: familyHistoryQueryKeys.history(patientId), type: "all" })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateFamilyHistory, isUpdating }
}

export { useUpdateFamilyHistory }
