import type { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  type IconDefinition,
  faBuildingShield,
  faCalendar,
  faMemoCircleCheck,
  faMessages,
  faTasks,
  faUserAlt,
  faUserGroup,
} from "@fortawesome/pro-light-svg-icons"
import { faBuilding, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getUnixTime } from "date-fns/getUnixTime"
import { parseISO } from "date-fns/parseISO"
import { getAddress } from "fhir"
import { classNames } from "primereact/utils"
import { type FC, useMemo } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { type ActionType, AppIconLogo, Badge, ModulesId, UserProfileMenu } from "commons"
import { intercomUpdate } from "intercom"
import { useAppModuleContext } from "internals"
import { useAuth } from "security"
import { strCapitalize } from "utils"

import { useOrganizationContext } from "../hooks"

const MainNavbar = () => {
  const { user } = useAuth()
  const {
    currentOrganizationId,
    unreadOrganizationMessagesCount,
    currentOrganization: org,
    loggedInPractitionerRoleRef,
    branding: { ehrUrl },
  } = useOrganizationContext()
  const { isModuleActive, activeWorkspaceModules } = useAppModuleContext()
  const navigate = useNavigate()

  const viewProfile = () => {
    navigate(`/orgs/${currentOrganizationId}/practitioner-profile/${user?.linkedResource?.id}`)
  }

  const navigationLinkItems: NavigationLinkItem[] = useMemo(
    () => [
      { path: "calendar", icon: faCalendar, hidden: !isModuleActive(ModulesId.CALENDAR) },
      { path: "patients", icon: faUserGroup },
      { path: "orders", icon: faMemoCircleCheck },
      { path: "tasks", icon: faTasks },
      { path: "administration", icon: faBuildingShield },
      {
        path: "messages",
        icon: faMessages,
        hidden: !isModuleActive(ModulesId.COMMUNICATION),
        count: unreadOrganizationMessagesCount,
      },
    ],
    [unreadOrganizationMessagesCount],
  )

  const userActions: ActionType[] = [{ label: "View Profile", icon: faUserAlt, command: viewProfile }]

  const visibleModules = activeWorkspaceModules.flatMap((module) => module.getDisplay())
  const company = {
    company_id: org.id as string,
    name: org.name,
    user_role: loggedInPractitionerRoleRef.display ?? (loggedInPractitionerRoleRef.id as string),
    accessible_modules: visibleModules.join(", "),
    ...(org.partOf ? { part_of: org.partOf.display ?? org.partOf.id } : {}),
    ...(org.meta?.createdAt ? { created_at: getUnixTime(parseISO(org.meta.createdAt as unknown as string)) } : {}),
  }

  if (user) {
    intercomUpdate({
      user_id: user?.b2cUserId,
      email: user?.email,
      company,
    })
  }

  return (
    <div className="flex bg-white border-y border-gray-200 drop-shadow-sm h-14 pt-1.5 pb-0.5 pl-3 pr-7">
      <nav className="flex flex-row flex-1 leading-6">
        <NavLink to="/" className="flex items-center h-full">
          <AppIconLogo className="mb-1" />
        </NavLink>
        <ul className="flex items-center ml-10 mt-0.5">
          {navigationLinkItems.map(({ path, icon, hidden, count }, index) => {
            if (hidden) return null
            return <SidebarLink key={index} icon={icon} to={path} count={count} />
          })}
        </ul>

        <div className="flex flex-1 justify-end gap-4">
          <div className="flex flex-col justify-center text-xs text-primary">
            <div title="Organization" className="inline-flex justify-end items-center font-bold text-right">
              {org.name}
              <FontAwesomeIcon icon={faBuilding} className="fa-fw ml-1" />
            </div>
            <div title="Address" className="inline-flex justify-end items-center text-right">
              {getAddress(org.address)}
              <FontAwesomeIcon icon={faLocationDot} className="fa-fw ml-1" />
            </div>
          </div>

          <div className="flex items-center justify-center cursor-pointer">
            <UserProfileMenu
              actions={userActions}
              avatarClassName="h-9 w-9"
              className="hover:ring-1 ring-primary-hover rounded-full"
              orgUrl={ehrUrl}
            />
          </div>
        </div>
      </nav>
    </div>
  )
}

const SidebarLink: FC<LinkProps> = ({ to, icon, count = 0 }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const basePath = `/orgs/${currentOrganizationId}/`

  return (
    <li className="h-full">
      <NavLink
        to={`${basePath}${to}`}
        className={({ isActive }) =>
          classNames("relative h-full flex items-center text-center px-6 border-b-[3px]", {
            "border-primary text-primary font-medium": isActive,
            "text-slate-500 border-transparent hover:bg-gray-50": !isActive,
          })
        }
      >
        <span className="link-item items-center justify-center text-xl">
          <FontAwesomeIcon icon={icon} className="fa-fw" />
        </span>
        <span className="pl-3 text-sm">{strCapitalize(to)}</span>
        {count > 0 && (
          <span title="Unread messages" className="absolute top-0 -right-3 px-2">
            <Badge text={count.toString()} colorStyle="green" />
          </span>
        )}
      </NavLink>
    </li>
  )
}

type LinkProps = {
  to: string
  icon: IconProp
  count?: number
}

type NavigationLinkItem = {
  path: string
  icon: IconDefinition
  hidden?: boolean
  count?: number
}

export { MainNavbar }
