import { useSuspenseQuery } from "@tanstack/react-query"
import { type Practitioner, getResources, isPractitioner, isUser } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { useAuth } from "security"
import { SYSTEM_VALUES } from "system-values"

import { practitionerQueryKeys } from "../query-keys"

const useLoggedInPractitioner = () => {
  const { read, search } = useClient()
  const { user, setLinkedResource } = useAuth()
  const queryKey = practitionerQueryKeys.loggedIn(user?.linkedResource?.id)

  const { data, isLoading, error, refetch } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      let practitioners: Practitioner[] = []
      let loggedPractitioner: Practitioner | undefined = undefined
      const practitionerFilters = new URLSearchParams({
        "active:not": "false",
      })

      if (isUser(user?.linkedUser)) {
        const filters = new URLSearchParams({
          id: user?.linkedUser.id ?? "",
          _elements: "id, resourceType, active, link",
          _include: "link:Practitioner",
        })

        const bundle = await search({ endpoint: "User", filters, signal })
        practitioners = getResources<Practitioner>(bundle, "Practitioner")

        if (!practitioners.length) {
          throw new Error("Unauthorized", {
            cause: {
              name: "401",
              message: `Not found a practitioner linked to logged in user ${
                user?.name ?? "unspecified name"
              } with email ${user?.email ?? "unspecified email"}`,
            },
          } as CustomError)
        } else if (practitioners.length > 1) {
          throw new Error("Forbidden", {
            cause: {
              name: "403",
              message: `Logged in user ${user?.name ?? "unspecified name"} with email ${
                user?.email ?? "unspecified email"
              } has several practitioners linked. This isn't allowed for data integrity and safety.`,
            },
          } as CustomError)
        }

        loggedPractitioner = await read<Practitioner>({
          endpoint: "Practitioner",
          id: practitioners[0].id,
          filters: practitionerFilters,
        })
      } else if (isPractitioner(user?.linkedUser)) {
        loggedPractitioner = await read<Practitioner>({
          endpoint: "Practitioner",
          id: user?.linkedUser.id,
          filters: practitionerFilters,
        })
      }

      if (!isPractitioner(loggedPractitioner)) {
        throw new Error("Unauthorized", {
          cause: {
            name: "401",
            message: `Not found a practitioner linked to logged in user ${
              user?.name ?? "unspecified name"
            } with email ${user?.email ?? "unspecified email"}`,
          },
        } as CustomError)
      }

      setLinkedResource(loggedPractitioner)

      const hasDosespotSetup =
        loggedPractitioner.identifier?.some(
          (identifier) => identifier.system === SYSTEM_VALUES.PRACTITIONER_DS_IDENTIFIER,
        ) ?? false

      return { practitioner: loggedPractitioner, hasDosespotSetup }
    },
    meta: { context: { queryKey, user } },
  })

  return {
    loggedInPractitioner: data?.practitioner,
    hasDosespotSetup: data?.hasDosespotSetup ?? false,
    isLoading,
    error,
    reloadPractitioner: refetch,
  }
}

export { useLoggedInPractitioner }
