import { type ChargeItemDefinition, isMedicationKnowledge } from "fhir"
import { useFormikContext } from "formik"
import { Skeleton } from "primereact/skeleton"
import type { FC } from "react"

import { useChargeItemDefinitions } from "commons"
import { getFeeType, useGetMedicationsProductPrices } from "commons/meds"
import { getMedCodes, getPriceByCode } from "commons/utils"
import { MED_FEE_TYPE } from "data"
import { useOrganizationContext } from "organization"
import { getMoneyCurrencyAlt } from "utils"

import type { MedicationRequestFormData } from "../../types"

const PrescriptionPrices = () => {
  const { values } = useFormikContext<MedicationRequestFormData>()
  const { medicationField, dispenseRequest } = values
  const { currentOrganizationId } = useOrganizationContext()
  const isMK = isMedicationKnowledge(medicationField)

  const medicationKnowledgeCodes = isMK
    ? getMedCodes({
        meds: [{ ...values, medication: { CodeableConcept: medicationField.code } }],
        withQty: true,
        useMedFrequency: true,
        referenceMedFrequency: dispenseRequest?.dispenseInterval,
      })
    : []

  const { chargeItemDefinitions: practiceChargeItemDefinitions, isLoading: isLoadingPracticePrice } =
    useChargeItemDefinitions({
      organizationId: currentOrganizationId,
      codes: {
        billToPracticeOrInsuranceCIDs: medicationKnowledgeCodes,
      },
    })

  const { chargeItemDefinitions: patientChargeItemDefinitions, isLoading: isLoadingPatientPrice } =
    useGetMedicationsProductPrices({
      organizationId: currentOrganizationId,
      codes: {
        billToPatientCIDs: medicationKnowledgeCodes,
      },
      useSpecificCodes: true,
    })

  const loadingPrices = isLoadingPatientPrice || isLoadingPracticePrice

  const mkCode = isMK ? medicationField.code?.coding : []
  const mkPracticePrice = getPriceByCode({
    chargeItemDefinitions:
      (practiceChargeItemDefinitions?.billToPracticeOrInsuranceCIDs as Record<string, ChargeItemDefinition>) ?? {},
    medCoding: mkCode,
    shippingAddressState: values?.dispenseRequest?.shippingAddress?.state,
    factor: dispenseRequest?.quantity?.value,
  })
  const mkPatientPrice = getPriceByCode({
    chargeItemDefinitions:
      (patientChargeItemDefinitions?.billToPatientCIDs as Record<string, ChargeItemDefinition>) ?? {},
    medCoding: mkCode,
    shippingAddressState: values?.dispenseRequest?.shippingAddress?.state,
    factor: dispenseRequest?.quantity?.value,
    useMedFee: true,
    ...(getFeeType(
      Object.values((patientChargeItemDefinitions?.billToPatientCIDs as Record<string, ChargeItemDefinition>) ?? {}),
    ) === MED_FEE_TYPE.ByFrequency
      ? { productFrequency: dispenseRequest?.dispenseInterval }
      : {}),
  })

  if (!medicationField) return null

  if (loadingPrices) return <PriceLoadingSkeleton />

  return (
    <div className="flex justify-end space-x-4">
      <Price label="Practice Price" price={mkPracticePrice} />
      <Price label="Patient Price" price={mkPatientPrice} />
    </div>
  )
}

const PriceLoadingSkeleton = () => (
  <div className="flex justify-end space-x-4">
    <Skeleton className="rounded-full" width="10rem" />
    <Skeleton className="rounded-full" width="10rem" />
  </div>
)

const Price: FC<{ label: string; price?: { value: number; currency: string } }> = ({ label, price }) =>
  price ? (
    <span className="flex space-x-2 text-sm">
      <span>{label}:</span>
      <strong>
        {getMoneyCurrencyAlt(price?.currency)}
        {price?.value.toFixed(2)}
      </strong>
    </span>
  ) : null

export { PrescriptionPrices }
