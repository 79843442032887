import type { Questionnaire } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { Chip } from "primereact/chip"

import { CardListItem } from "commons"

const QuestionnaireItem = ({ questionnaire: { title, version }, assign, isAssigning, isAssigned }: Props) => {
  const actionItem = isAssigning
    ? {
        icon: <FontAwesomeIcon icon={faSpinner} spin size="1x" title="Assign to patient" />,
      }
    : {
        icon: <FontAwesomeIcon icon={faUserPlus} size="1x" title="Assign to patient" />,
        command: assign,
      }

  const head = (
    <>
      <span className="font-bold mr-2" title="Title">
        {title ?? "Unspecified"}
      </span>
      {version && (
        <span className="font-semibold" title="Version">
          {version}
        </span>
      )}
      {isAssigned && <Chip label="assigned" className={"ml-2 custom-chip"} />}
    </>
  )

  return <CardListItem contentHeader={head} actionItem={!isAssigned ? actionItem : undefined} />
}

type Props = {
  questionnaire: Questionnaire
  assign(): void
  isAssigning: boolean
  isAssigned?: boolean
}

export { QuestionnaireItem }
