import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useStopMrOrder = () => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const stopMR = async (medicationRequestId: string) =>
    operationRequest({ endpoint: "MedicationRequest", method: "POST", operation: "stop", id: medicationRequestId })

  const { mutate: stopMedicationRequest, isPending: isStopping } = useMutation({
    mutationFn: stopMR,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: medsQueryKeys.medicationRequestList(patientId, "medication", ["active"]),
        refetchType: "all",
      })
      queryClient.refetchQueries({ queryKey: medsQueryKeys.draft.list(patientId, "medication") })
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      displayNotificationSuccess("Prescription stopped successfully!")
      // datadogLogs.logger.info("Prescription stopped successfully!", { patientId })
    },
  })

  return { stopMedicationRequest, isStopping }
}

export { useStopMrOrder }
