import { faPencil } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { CodeableConcept, Medication } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type FC, useState } from "react"

import { FormField } from "commons"
import { SYSTEM_VALUES } from "system-values"
import { getCodingBySystem } from "utils"

import type { ConfigurationItem } from "../../types"
import { DoseHandlerModal } from "./DoseHandlerModal"

const BodySitesAndDosageField: FC<Props> = ({ field, modalTitle, dosages, configurationItem, onUpdateDosage }) => {
  const [showModal, setShowModal] = useState(false)
  const onHide = () => setShowModal(false)
  const bodySites = configurationItem?.bodySite?.coding?.[0]?.code?.split("|") ?? []
  const baseDose = configurationItem?.medBaseDose?.value ?? 0
  const doseUnit = configurationItem?.medTotalDose?.unit ?? "mg"
  const batchs = (configurationItem.medicationAdministration?.contained as Medication[])?.reduce<
    { label: string; quantity: number; index: string }[]
  >(
    (acc, med, index) => [
      ...acc,
      {
        label: `B ${index + 1} (${(med.amount?.numerator?.value ?? 1) * baseDose}${doseUnit})`,
        index: `${index + 1}`,
        quantity: med.amount?.numerator?.value ?? 1,
      },
    ],
    [],
  )

  const dosagesBatch =
    getCodingBySystem(configurationItem?.bodySite, SYSTEM_VALUES.PROCEDURE_CONFIGURATION_BATCH)?.code?.split("|") ?? []

  return (
    <>
      <FormField
        field={field}
        validation={(value) => !(value as CodeableConcept)?.coding?.[0]?.code && "Administration site is required"}
        showInvalidState
      >
        <div
          className={classNames(
            "text-sm p-2 bg-gray-200 rounded inline-flex flex-1 items-center",
            dosages?.length ? "justify-between" : "justify-center",
          )}
        >
          <p>{`${bodySites.length} selected ${pluralize("point", bodySites.length)}`}</p>
          {!!dosages?.length && (
            <FontAwesomeIcon
              icon={faPencil}
              className="text-primary hover:text-primary-hover cursor-pointer"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </FormField>
      {!!dosages?.length && showModal && (
        <DoseHandlerModal
          key={dosages.join("|")}
          modalTitle={modalTitle}
          onHide={onHide}
          dosages={dosages}
          doseUnit={doseUnit}
          baseDose={baseDose}
          maxDosage={configurationItem?.medTotalDose?.value ?? 0}
          batchs={batchs}
          dosagesBatch={dosagesBatch}
          onUpdateDosage={(newDosage, batchSelected) => {
            onUpdateDosage(newDosage, batchSelected)
            onHide()
          }}
        />
      )}
    </>
  )
}

type Props = {
  field: string
  modalTitle?: string
  configurationItem: ConfigurationItem
  dosages: string[]
  onUpdateDosage(updatedDosage: number[], batchSelected: string[]): void
}

export { BodySitesAndDosageField }
