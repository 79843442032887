import type { Money } from "fhir"
import { type FieldProps, ErrorMessage, Field } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import { getMoneyCurrencyAlt } from "utils"

const MedicationRequestUnitsDropdown = ({
  field,
  validation,
  optionUnits,
  price,
  unitName,
  disabled,
  className,
}: Props) => {
  const currentPrice = useMemo(
    () => price && `(${getMoneyCurrencyAlt(price.currency)}${price.value?.toFixed(2)})`,
    [price?.value],
  )

  const itemTemplate = (item: number) => (
    <span>
      {item} {unitName ?? (item > 1 ? "units" : "unit")} {currentPrice}
    </span>
  )

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <div className={classNames("flex flex-col", className)}>
          <Dropdown
            id={name}
            name={name}
            options={optionUnits}
            valueTemplate={itemTemplate}
            value={value}
            className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
            onChange={onChange}
            disabled={disabled}
          />
          <ErrorMessage name={field}>
            {(msg) => <small className="p-error absolute -bottom-6 ">{msg}</small>}
          </ErrorMessage>
        </div>
      )}
    </Field>
  )
}

type Props = {
  price?: Money
  unitName?: string
  optionUnits: number[]
  className?: string
  field: string
  disabled?: boolean
  validation?(val: number): void
}

export { MedicationRequestUnitsDropdown }
