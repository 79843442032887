import type { ParametersParameterArrayArray, PlanDefinition, UsageContext } from "fhir"

import { getPDSkuValue } from "commons/utils"

import type { LabPDContext, LabPanel } from "../../types"

const mapPdUseContext = (useContext?: UsageContext[]) => {
  const defaultMap = {
    gender: undefined,
    organization: undefined,
    practice: undefined,
  } as LabPDContext

  return (
    useContext?.reduce((prev, context) => {
      if (context.code.code === "root") {
        return { ...prev, organization: context.value?.Reference?.display, rootId: context.value?.Reference?.id }
      }

      if (context.code.code === "practice") {
        return { ...prev, practice: context.value?.Reference?.display, practiceId: context.value?.Reference?.id }
      }

      if (context.code.code === "gender") {
        return {
          ...prev,
          gender: context.value?.CodeableConcept,
        }
      }

      return prev
    }, defaultMap) ?? defaultMap
  )
}

const mapPanelParams = (part?: ParametersParameterArrayArray[]) => {
  const initialValues = {
    planDefinition: {} as PlanDefinition,
    price: 0,
    fee: 0,
    display: "",
    performerLab: {},
    sku: "",
  }

  return (
    part?.reduce<LabPanel>((prevParam, param) => {
      if (param.name === "panel") {
        const pd = param.resource as PlanDefinition
        const sku = getPDSkuValue(pd.identifier) ?? "no sku code"

        return { ...prevParam, planDefinition: pd, display: pd.title ?? "no title", sku }
      }

      if (param.name === "base-price") {
        return { ...prevParam, price: param.value?.decimal ?? 0 }
      }

      if (param.name === "fee") {
        return { ...prevParam, fee: param?.value?.decimal ?? 0 }
      }

      if (param.name === "lab") {
        return { ...prevParam, performerLab: param?.value?.Reference ?? {} }
      }

      return prevParam
    }, initialValues) ?? initialValues
  )
}

export { mapPanelParams, mapPdUseContext }
