import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { FC } from "react"

import { ModulesId, StackedListItem } from "commons"
import { labOrderItemModelBuilder } from "commons/labs"
import { useChartContext } from "chart-view"

import { WrapUpSection } from "../components/details/WrapUpSection"
import type { EncounterData } from "../types"

const LabsSection: FC<Props> = ({ laboratoryOrders, total, icon }) => {
  const { showModule } = useChartContext()

  const goToOrderDetails = (order: string) => {
    showModule({ module: ModulesId.LABSORDER, moduleParams: { order } })
  }

  return (
    <WrapUpSection
      sectionTitle="Lab orders"
      isEmpty={!total}
      icon={icon}
      emptyMesage="No orders placed during this encounter"
      className="w-full self-end text-sm pb-2 z-50 divide-y"
    >
      {laboratoryOrders?.map((labData, index) => (
        <StackedListItem
          key={labData.order?.id ?? index}
          className="!pl-0 py-4"
          modelData={labOrderItemModelBuilder({
            data: { ...labData, order: { ...labData.order, occurrence: undefined }, billingType: "" },
            goToOrderDetails,
            showPrice: false,
            allowOnlyExternalAction: true,
          })}
        />
      ))}
    </WrapUpSection>
  )
}

type Props = {
  total?: number
  icon: IconDefinition
} & Pick<EncounterData, "laboratoryOrders">

export { LabsSection }
