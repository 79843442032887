import type { CarePlan, DiagnosticReport, Observation, ServiceRequest } from "fhir"

import type { DRData } from "commons/procedures"

export type TriadData = {
  id: string
  triad: Observation
  organs: OrganData[]
}

export type OrganData = {
  id: string
  organ: Observation
  labData: Observation[]
}

export enum mcObsCategories {
  laboratory = "laboratory",
  vitality_risk = "vitality-risk",
  organ_risk = "organ-risk",
  vital_signs = "vital-signs",
  suborgan_risk = "suborgan-risk",
  wellness_indicator = "wellness-indicator",
  triad_risk = "triad-risk",
}

export type CarePlanWithDR = {
  order: ServiceRequest
  plan?: CarePlan
  diagnosticReport?: DiagnosticReport
  qrId?: string
  results?: DRData[]
}

export enum MC_DETAILS_TAB {
  OVERVIEW = "Overview",
  LABS = "Labs",
  NUTRACEUTICALS = "Nutraceuticals",
  NOTES = "Notes",
  QUESTIONNAIRES = "Questionnaire",
}
