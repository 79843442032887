import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Account, AccountBETACreditCardArray, ResourceObject } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

import { creditCardQueryKeys } from "../query-keys"

const useUpdateCreditCard = (onSettled: () => void, onSuccess?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedCreditCard = async ({
    creditCard,
    account,
    creditCardList,
    index,
  }: {
    creditCard: AccountBETACreditCardArray
    account: Account
    creditCardList: AccountBETACreditCardArray[]
    index: number
  }) => {
    return patch(
      "Account",
      account.id as string,
      {
        creditCard: [...creditCardList.slice(0, index), creditCard, ...creditCardList.slice(index + 1)],
        meta: account.meta,
      } as ResourceObject,
    )
  }

  const { mutate: updateCreditCard, isPending: isUpdating } = useMutation({
    mutationFn: updatedCreditCard,
    onSuccess: async (_, { account: { subject, owner } }) => {
      if (subject?.[0]?.id)
        queryClient.refetchQueries({
          queryKey: subject[0].id ? creditCardQueryKeys.withPatientId(subject[0].id) : creditCardQueryKeys.all,
          type: "all",
        })
      if (owner?.id)
        queryClient.refetchQueries({ queryKey: organizationQueryKeys.account.current(owner.id), type: "all" })

      displayNotificationSuccess("Credit card updated successfully!")
      // datadogLogs.logger.info(`Credit card of ${owner}  updated successfully! `)
      onSuccess?.()
    },
    onSettled,
    onError: async (error: CustomError) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: creditCardQueryKeys.all, type: "all" })
      }
      displayNotificationError(registerErrorTrace(error))
    },
  })

  return { updateCreditCard, isUpdating }
}

export { useUpdateCreditCard }
