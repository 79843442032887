import type { Patient, ResourceObject } from "fhir"
import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { ContactPointSystem } from "data"
import { registerErrorTrace } from "logger"

const useUpdatePatientEmail = (onSettled?: (error: Error | null) => void) => {
  const { patch } = useClient()

  const updatePatient = async ({ patient, email }: { patient: Patient; email: string }) => {
    const newMailTelecom = [{ system: ContactPointSystem.email, use: "home", value: email }]
    const newTelecom = patient.telecom ?? newMailTelecom

    if (patient.telecom) {
      const index = newTelecom?.findIndex((t) => t.system === ContactPointSystem.email)
      newTelecom.splice(index, index >= 0 ? 1 : 0, ...newMailTelecom)
    }

    return patch("Patient", patient.id as string, { telecom: newTelecom, meta: patient.meta } as ResourceObject)
  }

  const { mutate: updatePatientEmail, isPending: isUpdating } = useMutation({
    mutationFn: updatePatient,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled: (_, error) => onSettled?.(error),
  })

  return { updatePatientEmail, isUpdating }
}

export { useUpdatePatientEmail }
