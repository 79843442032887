import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type FC, useMemo } from "react"

import { getBadgeColor } from "utils"

import { CardSelectionItem } from "../../../components/CardSelectionItem"
import { EmptyMessage } from "../../../components/EmptyMessage"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { PlanDefinitionDetails } from "../../../labs"
import { type Plan, PLAN_TYPES } from "../../types"

const PlanSelection: FC<Props> = ({
  planDefinitions,
  activePDs,
  isLoadingPDs,
  selectedPlan,
  emptyIcon,
  selectPlan,
  showConfig,
  showDetails,
  contextLabel,
}) => {
  const filteredPlans = useMemo(() => {
    return planDefinitions?.filter(
      (pd) =>
        pd.definition.type?.coding?.[0]?.code === PLAN_TYPES.CLINICAL_PROTOCOL && pd.definition.status !== "retired",
    )
  }, [planDefinitions])
  if (isLoadingPDs)
    return (
      <SkeletonLoader
        loaderType="min-card"
        repeats={3}
        skeletonSize="6rem"
        containerClassName="h-full gap-2 space-y-2 px-1 py-1 border-none"
      />
    )

  return (
    <div className="space-y-2 px-1 py-1 grow overflow-auto border-none">
      {!filteredPlans?.length ? (
        <div className="h-full flex">
          <EmptyMessage
            icon={emptyIcon}
            message={`There are no ${pluralize(contextLabel)} available to enroll at this time`}
            subMessage={false}
          />
        </div>
      ) : (
        filteredPlans?.map((plan) => {
          const activePDStatus = activePDs[plan.definitionToken]
          return (
            <CardSelectionItem
              key={plan.definition.id}
              selected={plan.definitionToken === selectedPlan?.definitionToken}
              disabled={!!activePDStatus}
              disabledBadgeProps={getBadgeColor(activePDStatus ?? "")}
              onSelect={() =>
                plan.definitionToken === selectedPlan?.definitionToken ? selectPlan(undefined) : selectPlan(plan)
              }
              mainText={plan.definition.title ?? "No title"}
              subTexts={[`By ${plan.definition.publisher}`]}
              footer={
                <PlanDefinitionDetails
                  combos={plan.combos}
                  panels={plan.panels}
                  nutraceuticals={plan.nutraceutical}
                  rx={plan.rx}
                  headerClassName={classNames("font-normal text-sm text-gray-500", { "opacity-60": !!activePDStatus })}
                />
              }
              externalAction={
                activePDStatus
                  ? {
                      title: activePDStatus === "active" ? "See Details" : `Configure ${contextLabel}`,
                      onClick: () =>
                        activePDStatus === "active"
                          ? showDetails(plan.definition.id as string, plan.definitionToken)
                          : showConfig(plan.definition.id as string, plan.definitionToken),
                    }
                  : undefined
              }
              clickableArea="card"
            />
          )
        })
      )}
    </div>
  )
}

type Props = {
  planDefinitions?: Plan[]
  activePDs: Record<string, string>
  isLoadingPDs: boolean
  selectedPlan?: Plan
  selectPlan: (plan?: Plan) => void
  emptyIcon: IconDefinition
  showConfig(planDefId: string, token?: string): void
  showDetails(planDefId: string, token?: string): void
  contextLabel: string
}

export { PlanSelection }
