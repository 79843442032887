import { faCalendarDays, faUserDoctor } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { type Composition } from "fhir"

import { type StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const assessmentsModelBuilder = (composition: Composition, edit?: () => void): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Title", value: composition.title ?? "Unspecified" }],
    },

    {
      lineItems: [
        {
          name: "Date",
          value: formatDate(new Date(composition.date), formatsByTypes.LONG_DATETIME),
          icon: faCalendarDays,
        },
        { name: "Author", value: composition.author[0].display ?? "No author", icon: faUserDoctor },
      ],
    },
  ],
  menu: [
    ...(edit
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: edit,
          },
        ]
      : []),
  ],
})

export { assessmentsModelBuilder }
