import * as Yup from "yup"
import type { Reference, Task } from "fhir"

import { taskPriorityCodes } from "data"
import type { ValueSetItem } from "value-set"

const getInitialValues = (
  organizationRef: Reference,
  defaultTaskCode: ValueSetItem,
  practitionerRoleRef?: Reference,
): Task => ({
  code: {
    coding: [defaultTaskCode],
  },
  intent: "unknown",
  status: "requested",
  for: undefined,
  authoredOn: undefined,
  requester: practitionerRoleRef,
  owner: organizationRef,
  description: "",
  priority: taskPriorityCodes[0].code,
  note: [
    {
      text: "",
      time: new Date().toISOString(),
    },
  ],
  restriction: { period: { start: "", end: "" } },
})

const taskValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  for: Yup.object().required("You must specify who's assigned the task"),
})

const sanitize = ({ ...task }: Task) => {
  task.authoredOn = new Date().toISOString()

  if (!task.restriction?.period?.start) {
    task.restriction = { period: { ...task.restriction?.period, start: new Date().toISOString() } }
  }

  if (!task.note?.[0]?.text) {
    delete task.note
  }
  if (!task.for?.id) delete task.for
  if (!task.description) delete task.description
  if (!task.requester?.id) delete task.requester
  if (!task.owner?.id) delete task.owner

  return task
}

export { taskValidationSchema, getInitialValues, sanitize }
