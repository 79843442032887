import { type IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { type FC } from "react"
import { isCarePlan } from "fhir"

import { StackedListContainer } from "commons"
import { medicationModel } from "medications-statement"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"
import type { State } from "../../hooks"

const IntakeMedicationsSection: FC<Props> = ({ medications, total, planRefs, icon, hideSpacer }) => (
  <WrapUpSection
    subSectionTitle="Medications Statements"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No medications registered during this encounter"
    className="flex flex-row gap-x-16 w-full text-sm"
    useSpacer={!hideSpacer}
  >
    <StackedListContainer
      data={medications ?? []}
      itemModelBuilder={(item) => {
        const cp = planRefs?.[item.basedOn?.find(isCarePlan)?.id as string]

        return {
          ...medicationModel(item),
          ...(cp
            ? {
                badge: {
                  text: cp.display ?? "Plan based",
                  colorStyle: "gray",
                  title: cp.display,
                  className: "truncate max-w-24 !block",
                },
              }
            : { badge: undefined }),
        }
      }}
      className="w-full"
      itemsClassName="!pl-0 py-4"
    />
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "medications"> &
  Pick<State, "planRefs">

export { IntakeMedicationsSection }
