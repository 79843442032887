import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { CarePlan, ResourceObject } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { labOrdersQueryKeys } from "laboratory-orders"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useActivateCarePlan = (
  onSuccess?: (planId: string, planResources: ResourceObject[]) => void,
  onSettled?: () => void,
) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const activate = async (planId: string) =>
    operationRequest({ endpoint: "CarePlan", method: "POST", operation: "activate", id: planId })

  const { mutate: activateCarePlan, isPending } = useMutation({
    mutationFn: activate,
    onError: (error: CustomError, planId) => {
      displayNotificationError(registerErrorTrace(error, { planId }))
    },
    onSuccess: async (data, planId) => {
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      await queryClient.invalidateQueries({
        queryKey: labOrdersQueryKeys.withPatientID(patientId),
        exact: false,
      })
      displayNotificationSuccess("Plan activated successfully!")
      // datadogLogs.logger.info(`Plan ${planId} activated successfully!`)
      /* Send all created resources associated with the CP */
      onSuccess?.(planId, ((data as CarePlan)?.contained as ResourceObject[]) ?? [])
    },
    onSettled,
  })

  return { activateCarePlan, isActivating: isPending }
}

export { useActivateCarePlan }
