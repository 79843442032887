import isEqual from "lodash/isEqual"

import { DialogFormContainer } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useOrganizationContext } from "organization"

import { useCreateAppointmentType } from "../../hooks"
import type { AppointmentType } from "../../types"
import AppointmentTypeForm from "./AppointmentTypeForm"
import { validationSchema } from "./validations"

const AppointmentTypeFormContainer = ({ initialValue, onHide }: Props) => {
  const isEditing = !!initialValue.id

  const { currentOrganizationId } = useOrganizationContext()

  const { createAppointmentType } = useCreateAppointmentType(currentOrganizationId, onHide)

  const onSubmit = (data: AppointmentType) => {
    if (isEqual(data, initialValue)) {
      onHide()
      return
    }

    createAppointmentType(data)
  }

  return (
    <ReplaceFormProvider>
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={onHide}
        showForm={true}
        title={isEditing ? "Edit Appointment Type" : "New Appointment Type"}
        saveLabel={isEditing ? "Update" : undefined}
        initialValue={{
          ...initialValue,
        }}
        validationSchema={validationSchema}
        useFormik
        className="h-[70dvh] 2xl:h-[62dvh]"
      >
        <AppointmentTypeForm isEditing={isEditing} />
      </DialogFormContainer>
    </ReplaceFormProvider>
  )
}

type Props = {
  initialValue: AppointmentType
  onHide(): void
}

export { AppointmentTypeFormContainer }
