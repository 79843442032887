import type {
  Annotation,
  CodeableConcept,
  Coding,
  DiagnosticReport,
  Dosage,
  Identifier,
  MedicationAdministration,
  MedicationKnowledge,
  MedicationRequest,
  Money,
  Observation,
  PlanDefinitionActionArrayActionArray,
  Procedure,
  Quantity,
  Reference,
} from "fhir"

import { SYSTEM_VALUES } from "system-values"
import type { ObjectSchema } from "yup"

export type ProcedureData = {
  procedure: Procedure
  configurationItem: ConfigurationItem[]
  performerName?: string
  deletedMedications: { mrId: string; maId: string }[]
  qrId?: string
  labData?: { [K: string]: string }
  selectedDR?: DRData
  requester?: Reference
  calcData?: CalcData
}

type CalcData = {
  observations?: Observation[]
  runAlgorithmCode?: Coding
  icd10?: CodeableConcept
}

export type ConfigurationItem = {
  medicationAdministration?: MedicationAdministration
  medicationRequest?: MedicationRequest
  zone?: Coding
  invData?: InventoryData[]
  medBaseDose?: Quantity
  medTotalDose?: Quantity
  bodySite?: CodeableConcept
  batches?: BatchData[]
}

export type BatchData = {
  label: string
  quantity: number
  index: string
}

export type InventoryData = {
  lotNumber: string
  expirationDate: string
  medCode: string
  quantity: number
}

export type CalculatorOutput = {
  suggestedMeds: MedicationKnowledge[]
  recommended: { dosage: Dosage; codeableConcept: CodeableConcept; relatedMedsIds: string[] }[]
  notes: Annotation[]
  supplements?: Supplement[]
}

export type ProcedureTypeData = {
  id: string
  cc: CodeableConcept
  canonical: string[]
  identifier?: Identifier[]
  action: PlanDefinitionActionArrayActionArray[]
  title?: string
  configCodes: string[]
  isAlgorithm: boolean
}

export type ObsData = {
  values: Record<string, string>
  data: Record<string, Observation>
}

export type DRData = {
  dr: DiagnosticReport
  observations: ObsData
}

export type BodyZones = "full-body" | "face"

export const bodyZonesCodes: Coding[] = [
  {
    code: "full-body",
    display: "Full Body",
    system: SYSTEM_VALUES.BODY_SITES,
  },
  {
    code: "face",
    display: "Face",
    system: SYSTEM_VALUES.BODY_SITES,
  },
]

export type ProcedureFormTypes = {
  type: ProcedureFormKind
  title: string
  saveLabel?: string
  closeLabel?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
}

export enum ProcedureFormKind {
  main,
  calculator,
  suggestedMeds,
  medication,
  massage,
}

export enum ProcedureKind {
  pellet,
  botox,
  massage,
}

export enum PROCEDURE_CONFIG {
  PELLET = "configure-medication-administration",
  BODY_SITE_COORDINATE_AND_DOSAGE = "configure-body-sites-coordinates-and-dosage",
  BODY_SITE_COORDINATE = "configure-body-sites-coordinates",
  BODY_SITE = "configure-body-sites",
}

export type ProcedureConfiguration = {
  code: string
  title: string
  selectionBehavior: "one-or-more" | "any" | "exactly-one" | "at-most-one" | "all" | "all-or-none"
  cardinalityBehavior: "single" | "multiple"
}

export type Supplement = {
  triads?: string[]
  mr: MedicationRequest
  mk: MedicationKnowledge
  price?: Money
}
