import OT, { type OTError } from "@opentok/client"
import { useEffect, useState } from "react"

import type { TSettings, TSignalEvent, TSubscriberConnection, TVonageSignalPractitioner } from "communication/types"
import { signal } from "communication/utils"

import { IdToAppendVideo, SubscriberConnection, VonageSignalPatient, VonageSignalPractitioner } from "../data"

const useVonageAPIIntegration = () => {
  const [videoStates, setvideoStates] = useState<TVideoStates | null>(null)
  const [subscriberSettings, setSubscriberSettings] = useState<TSettings>(initialSubscriberSettings)
  const [isPictureInPictureActive, setIsPictureInPictureActive] = useState(false)
  const [subscriberConnection, setSubscriberConnection] = useState<TSubscriberConnection>(
    SubscriberConnection.notInCall,
  )

  const handleSignal = (type: TVonageSignalPractitioner, data: string, funct = (error?: OTError) => error) =>
    videoStates?.session?.signal({ type, data }, funct)

  const handleSubscriberSettings = ({ key, value }: { key: keyof TSettings; value: boolean }) =>
    setSubscriberSettings((prev) => ({ ...prev, [key]: value }))

  const handleSetVideoElement = (id: string, element: HTMLVideoElement) =>
    document.getElementById(id)?.appendChild(element)
  const handleGetVideoElement = (id: string) =>
    document.getElementById(id)?.getElementsByTagName("video")[0] as HTMLVideoElement

  const createSubscribe = () => {
    videoStates &&
      videoStates.session.on("streamCreated", (event) => {
        const subscriber = videoStates.session.subscribe(event.stream, undefined, { insertDefaultUI: false })

        subscriber.on("videoElementCreated", (video) => {
          const shareScreenVideo = handleGetVideoElement(IdToAppendVideo.shareScreen)
          const subscriberVideo = handleGetVideoElement(IdToAppendVideo.subscriber)

          if (event.stream.videoType === "screen" && !shareScreenVideo) {
            ;(video.element as HTMLVideoElement).disablePictureInPicture = true
            !subscriberSettings.shareScreen && setSubscriberSettings((prev) => ({ ...prev, shareScreen: true }))
            video.element.className = "size-full object-contain aspect-video"
            handleSetVideoElement(IdToAppendVideo.shareScreen, video.element as HTMLVideoElement)
          }

          if (event.stream.videoType === "camera") {
            shareScreenVideo && shareScreenVideo.remove()
            setSubscriberSettings((prev) => ({ ...prev, shareScreen: false }))
            video.element.className = "size-full object-cover aspect-video"
            !subscriberVideo
              ? handleSetVideoElement(IdToAppendVideo.subscriber, video.element as HTMLVideoElement)
              : subscriberVideo?.replaceWith(video.element)
          }
        })

        subscriber?.on("connected", () => setSubscriberConnection(SubscriberConnection.connected))
        subscriber?.on("disconnected", () => setSubscriberConnection(SubscriberConnection.disconnected))

        setvideoStates({ ...videoStates, subscriber })
      })
  }

  const handlePictureInPicture = () => {
    const videoPublisherElement = handleGetVideoElement(IdToAppendVideo.publisher)
    const videoSubscriberElement = handleGetVideoElement(IdToAppendVideo.subscriber)

    const handleEnterPIP = async (video: HTMLVideoElement) => {
      if (video !== document.pictureInPictureElement) {
        setIsPictureInPictureActive(true)
        video.disablePictureInPicture = false
        await video.requestPictureInPicture()
        video.addEventListener("leavepictureinpicture", () => handleLeavePIP(video))
      }
    }

    const handleLeavePIP = (video: HTMLVideoElement) => {
      video.disablePictureInPicture = true
      setIsPictureInPictureActive(false)

      setTimeout(() => {
        handleSetVideoElement(IdToAppendVideo.publisher, videoPublisherElement)
        videoStates?.subscriber && handleSetVideoElement(IdToAppendVideo.subscriber, videoSubscriberElement)
      }, 100)
    }

    videoStates?.subscriber ? handleEnterPIP(videoSubscriberElement) : handleEnterPIP(videoPublisherElement)
  }

  const subscriberAudio = () =>
    videoStates?.session.on(signal(VonageSignalPatient.audioMute), (event) => {
      const customEvent = event as TSignalEvent
      handleSubscriberSettings({ key: "audio", value: JSON.parse(customEvent.data) })
    })

  const shareScreen = () => {
    if (videoStates) {
      const shareScreen = OT.initPublisher(
        IdToAppendVideo.shareScreen,
        { videoSource: "screen", insertMode: "append" },
        (err) => {
          if (err) return
          videoStates.session.publish(shareScreen),
            setvideoStates({
              ...videoStates,
              shareScreen,
            })
        },
      )
    }
  }

  const endShareScreen = (sessionDestroyed: boolean) => {
    videoStates?.shareScreen?.on("streamDestroyed", (event) => {
      if (event.reason === "mediaStopped" && videoStates.publisher?.stream && !sessionDestroyed) {
        const publisher = OT.initPublisher(IdToAppendVideo.publisher, {
          insertDefaultUI: false,
          publishAudio: videoStates.publisher.stream.hasAudio,
          publishVideo: videoStates.publisher.stream.hasVideo,
        })
        videoStates?.session.publish(publisher)
        setvideoStates({
          ...videoStates,
          shareScreen: undefined,
        })
      }
    })
  }

  useEffect(() => {
    createSubscribe()
    subscriberAudio()
  }, [videoStates, subscriberSettings])

  const initializeSession = ({ apiKey, sessionId, token }: TInitializeSession) => {
    const session = OT.initSession(apiKey, sessionId)
    // Create a publisher
    const publisher = OT.initPublisher(undefined, { insertDefaultUI: false })
    publisher.on("videoElementCreated", (event) => {
      event.element.className = "size-full object-cover aspect-video"
      ;(event.element as HTMLVideoElement).disablePictureInPicture = true
      handleSetVideoElement(IdToAppendVideo.publisher, event.element as HTMLVideoElement)
    })
    // Connect to the session
    session.connect(token, (error) => !error && session.publish(publisher))
    // Subscribing to stream
    setvideoStates({ session, publisher })
  }

  const stopStreaming = () => {
    if (videoStates) {
      videoStates.publisher.getVideoSource().track?.stop()
      videoStates.publisher.getAudioSource()?.stop()
      videoStates.publisher.destroy()
      videoStates.session.disconnect()
      setvideoStates(null)
      setSubscriberSettings(initialSubscriberSettings)
      setSubscriberConnection(SubscriberConnection.notInCall)
    }
  }

  // The following functions are used in functionlaity customization
  const toggleVideo = (state: boolean) => videoStates?.publisher.publishVideo(state)
  const toggleAudio = (state: boolean) => {
    handleSignal(VonageSignalPractitioner.audioMute, JSON.stringify(state))
    videoStates?.publisher.publishAudio(state)
  }
  const toggleAudioSubscribtion = (state: boolean) => videoStates?.subscriber?.subscribeToAudio(state)
  const toggleVideoSubscribtion = (state: boolean) => videoStates?.subscriber?.subscribeToVideo(state)

  return {
    stopStreaming,
    toggleVideo,
    toggleAudio,
    toggleAudioSubscribtion,
    toggleVideoSubscribtion,
    handlePictureInPicture,
    isPictureInPictureActive,
    setIsPictureInPictureActive,
    subscriberConnection,
    initializeSession,
    subscriberSettings,
    videoStates,
    shareScreen,
    endShareScreen,
  }
}

type TInitializeSession = { apiKey: string; sessionId: string; token: string }

type TVideoStates = {
  session: OT.Session
  publisher: OT.Publisher
  subscriber?: OT.Subscriber
  shareScreen?: OT.Publisher
}

const initialSubscriberSettings = {
  audio: true,
  video: true,
  shareScreen: false,
}

export { useVonageAPIIntegration }
