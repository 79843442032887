import { useFormikContext } from "formik"
import { Message } from "primereact/message"
import { classNames } from "primereact/utils"
import { type FC, type MouseEvent, useCallback } from "react"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"

import type { LaboratoryOrder } from "../types"

const LabNoPhoneNumberWarningMessage: FC<Props> = ({ message, actionText, visible }) => {
  const { showModule } = useChartContext<LaboratoryOrder>()
  const { values } = useFormikContext<LaboratoryOrder>()

  const navToTelecomSubview = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      showModule({
        module: ModulesId.PATIENT,
        moduleParams: { subview: "telecom" },
        keepOrigin: true,
        threshold: 2,
        moduleData: { form: { initialState: values } },
      })
    },
    [values],
  )

  return (
    <div className={classNames("sticky top-0 z-20 bg-white py-2", { hidden: !visible })}>
      <Message
        className="w-full"
        severity="warn"
        content={
          <div className="flex gap-2 text-sm">
            <span>{message}.</span>
            <button className="font-medium underline" onClick={navToTelecomSubview}>
              {actionText}
            </button>
          </div>
        }
      />
    </div>
  )
}

type Props = {
  message: string
  actionText: string
  visible: boolean
}

export { LabNoPhoneNumberWarningMessage }
