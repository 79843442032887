import { useInfiniteQuery } from "@tanstack/react-query"
import {
  type Communication,
  type Patient,
  type Practitioner,
  getResource,
  getResources,
  humanNameAsString,
  isPatient,
  isPractitioner,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getDateLabel } from "utils"

import { communicationQueryKeys } from "../query-keys"

const useCommunications = (patientId: string) => {
  const { search } = useClient()
  const queryKey = communicationQueryKeys.list(patientId)

  const {
    data,
    isLoading,
    isError,
    error,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<CommunicationQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress,completed",
        category: "message",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-sent",
        _include: "sender",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Communication`, filters, signal })

      const communications = getResources<Communication>(bundle, "Communication")
      const patient = getResource<Patient>(bundle, "Patient")
      const practitionerStaff = getResources<Practitioner>(bundle, "Practitioner")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { communications, next, total: bundle?.total ?? 0, patient, practitionerStaff }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    refetchIntervalInBackground: true,
    refetchInterval: 15000,
  })

  const { commsByDate, communications, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.communications).reverse() ?? []
    const patient = data?.pages.flatMap(({ patient }) => patient)?.[0]
    const practitionerStaff = data?.pages
      .flatMap(({ practitionerStaff }) => practitionerStaff)
      ?.reduce(
        (acc, pract) => {
          return { ...acc, [pract?.id as string]: pract }
        },
        {} as Record<string, Practitioner | undefined>,
      )

    const count = newData?.length

    const commsByDate = newData.reduce<Record<string, Communication[]>>((prev, comm) => {
      if (comm.sent) {
        const key = getDateLabel(comm.sent)
        const senderName = isPatient(comm.sender)
          ? humanNameAsString(patient?.name?.[0])
          : isPractitioner(comm.sender)
            ? humanNameAsString(practitionerStaff?.[comm.sender.id as string]?.name?.[0])
            : comm.sender?.display
        const updatedComm: Communication = { ...comm, sender: { ...comm.sender, display: senderName } }

        return { ...prev, [key]: [...(prev[key] ?? []), updatedComm] }
      }

      return prev
    }, {})

    return {
      commsByDate: commsByDate
        ? Array.from(Object.entries(commsByDate)).map(([date, communications]) => ({
            date,
            communications,
          }))
        : [],
      communications: newData,
      count,
    }
  }, [data?.pages])

  const reloadLatestCommunications = () => {
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    commsByDate,
    communications,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetching: isFetching || isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadLatestCommunications,
  }
}

type CommunicationQueryData = {
  communications: Communication[]
  next: number | undefined
  total: number
  patient: Patient
  practitionerStaff?: Practitioner[]
}

export { useCommunications }
