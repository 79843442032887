import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useClient } from "api"
import type { Encounter } from "fhir"

import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { encounterQueryKeys } from "../query-keys"

const useCreateEncounter = (patientId: string, closeForm: () => void, onSucces?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newEncounter = async (encounter: Encounter) => create("Encounter", encounter)

  const { mutate, isPending } = useMutation({
    mutationFn: newEncounter,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.details(patientId), refetchType: "all" })
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.list(patientId, "KP") })
      displayNotificationSuccess("Encounter created successfully!")
      // datadogLogs.logger.info("Encounter created successfully!", { patientId })
      onSucces?.()
    },
    onSettled: closeForm,
  })

  return { createEncounter: mutate, isCreating: isPending }
}

export { useCreateEncounter }
