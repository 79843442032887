import type { Bundle, MedicationRequestDispenseRequest } from "fhir"
import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { sanitizeDispenseRequest } from "../components/validations"

const useUpdateMedicationRequestsDispenseInterval = (onSuccess?: () => void) => {
  const { transaction } = useClient()

  const updateDispenseInterval = async (
    medicationRequests: {
      mrId: string
      dispenseRequest: MedicationRequestDispenseRequest
    }[],
  ) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "batch",
      entry: medicationRequests.map((mr) => ({
        request: {
          method: "PATCH",
          url: `MedicationRequest/${mr.mrId}`,
        },
        resource: { dispenseRequest: sanitizeDispenseRequest(mr.dispenseRequest), resourceType: "MedicationRequest" },
      })),
    }
    return transaction(bundle)
  }

  const { mutate: updateMRDispenseInterval, isPending: isUpdating } = useMutation({
    mutationFn: updateDispenseInterval,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: onSuccess,
  })

  return { updateMRDispenseInterval, isUpdating }
}

export { useUpdateMedicationRequestsDispenseInterval }
