import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"
import type { Contents } from "medication-requests/types"

import { MedicationNextRefills } from "./MedicationNextRefills"
import { MedicationOrdersContainer } from "./MedicationOrdersContainer"
import { MedicationRequestViewContainer } from "./medications/MedicationRequestViewContainer"

const MedicationRequestContainer = () => {
  const { appSubModules } = useAppModuleContext()
  const { activeSubModule, setActiveSubModule, hasGP } = useChartContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const updateContent = (activeContent?: Contents | string) => {
    if (activeContent && activeContent !== "medications") searchParams.set("subview", activeContent)
    else searchParams.delete("subview")

    setSearchParams(searchParams)
  }

  useEffect(() => {
    let subModule = null
    const subviewParam = searchParams.get("order") ? ModulesId.MEDICATION_ORDER_DETAILS : searchParams.get("subview")

    if (subviewParam) {
      subModule = appSubModules.medicationRequest[subviewParam]
    } else {
      searchParams.delete("subview")
      setSearchParams(searchParams)
    }

    setActiveSubModule(subModule)
  }, [searchParams, setActiveSubModule, setSearchParams, appSubModules])

  if (activeSubModule) {
    return activeSubModule.renderContent()
  }

  if (searchParams.get("subview") === "orders") {
    return <MedicationOrdersContainer activeContent="orders" onUpdateContent={updateContent} />
  } else if (searchParams.get("subview") === "history") {
    return <MedicationOrdersContainer activeContent="history" onUpdateContent={updateContent} />
  } else if (searchParams.get("subview") === "refills") {
    return <MedicationNextRefills activeContent="refills" onUpdateContent={updateContent} />
  }

  return (
    <MedicationRequestViewContainer
      activeContent="medications"
      onUpdateContent={updateContent}
      hasGP={hasGP}
      navToDraft={() => updateContent(ModulesId.MR_DRAFT)}
      navToReschedule={() => updateContent(ModulesId.MR_RESCHEDULE)}
    />
  )
}

export { MedicationRequestContainer }
