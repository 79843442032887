import { useState } from "react"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { openLinkInNewTab } from "utils"

import type { CustomError } from "../types"

const useShowSignedUrlDocument = (onSuccess: (url: string) => void = openLinkInNewTab) => {
  const { getSignedUrl } = useClient()
  const [loading, setLoading] = useState(false)

  const showDocument = async (url?: string) => {
    if (url) {
      const fullUrl = url.startsWith("/") ? url.slice(1) : url
      try {
        setLoading(true)
        const signedUrl = await getSignedUrl(fullUrl)
        onSuccess(signedUrl.url)
        return true
      } catch (error) {
        displayNotificationError(error as CustomError)
      } finally {
        setLoading(false)
      }
    } else {
      displayNotificationError(new Error("Wrong document url") as CustomError)
      return false
    }
  }

  return { showDocument, loading }
}

export { useShowSignedUrlDocument }
