const labOrdersQueryKey = {
  all: ["patient/laboratoryOrders"] as const,
  list: (patientId: string, statusFilter: string[], searchText?: string, encounter?: string) => [
    ...labOrdersQueryKey.all,
    patientId,
    statusFilter,
    searchText,
    encounter,
  ],
  details: (labOrderId: string) => ["laboratoryOrderDetails", labOrderId],
}

export { labOrdersQueryKey }
