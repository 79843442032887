import type { Address, Patient, RelatedPerson } from "fhir"

import { getHomeAddressIndex } from "utils"

const sanitizeAddress = ({ ...person }: Partial<Patient | RelatedPerson>) => {
  const sanitizedAddress = person.address?.filter(({ line }) => line?.[0] !== "")

  const homeAddressIndex = getHomeAddressIndex(sanitizedAddress)
  delete sanitizedAddress?.[homeAddressIndex]?.type

  if (sanitizedAddress?.length) {
    return { ...person, address: sanitizedAddress }
  }

  delete person.address

  return person
}

const sanitizeCustomAddress = (address: Address) => {
  const sanitizedAddress = { ...address }

  delete sanitizedAddress.type

  return sanitizedAddress
}

export { sanitizeAddress, sanitizeCustomAddress }
