import type { Reference } from "fhir"

import { SYSTEM_VALUES } from "system-values"

import type { RoomData } from "../types"

const getRoomInitialValues = (organization: Reference, parentLocation: Reference): RoomData => ({
  status: "active",
  managingOrganization: organization,
  partOf: parentLocation,
  physicalType: {
    coding: [{ code: "ro", display: "Room", system: SYSTEM_VALUES.LOCATION_PHYSICAL_TYPE }],
    text: "Room",
  },
  name: "",
  type: [],
  devices: [],
})

export { getRoomInitialValues }
