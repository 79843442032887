import { faMemo } from "@fortawesome/pro-regular-svg-icons"
import type { Annotation, List } from "fhir"

import { EmptyMessage } from "commons"

const NotesView = ({ notes }: Props) => {
  const { priorityNotes, normalNotes } =
    notes?.reduce<{ priorityNotes: Annotation[]; normalNotes: Annotation[] }>(
      (acc, { code, note }) => {
        if (code?.coding?.some(({ code }) => code === "priority-notes")) {
          return { ...acc, priorityNotes: [...acc.priorityNotes, ...(note ?? [])] }
        }
        return { ...acc, normalNotes: [...acc.normalNotes, ...(note ?? [])] }
      },
      { priorityNotes: [], normalNotes: [] },
    ) ?? {}

  const hasNotes = !!priorityNotes?.length || !!normalNotes?.length

  return (
    <div className="flex flex-col flex-1 p-6 overflow-hidden">
      {hasNotes ? (
        <ul className="overflow-y-auto list-disc list-inside">
          {priorityNotes?.map((note, index) => (
            <li key={index} className="font-bold text-sm">
              {note?.text}
            </li>
          ))}
          {normalNotes?.map((note, index) => (
            <li key={index} className="font-medium text-sm">
              {note?.text}
            </li>
          ))}
        </ul>
      ) : (
        <EmptyMessage icon={faMemo} message="No notes in plan" subMessage={false} />
      )}
    </div>
  )
}

type Props = {
  notes?: List[]
}

export { NotesView }
