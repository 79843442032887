import { faFileLines, faVenus, faMars } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { defaultOrgDataSettingCodes } from "data"
import type { Coding, SettingItemArray } from "fhir"
import type { FC } from "react"

import type { DefaultOrgDataIcd10 } from "../types"

const DefaultOrgData: FC<Props> = ({ settings }) => {
  const { defaultProcedureIcd10 } = settings.reduce<{ defaultProcedureIcd10: DefaultOrgDataIcd10 }>(
    (datas, data) => {
      if (!data.value?.string) {
        return datas
      }

      if (data.code?.code === defaultOrgDataSettingCodes.defaultProcedureIcd10) {
        return {
          ...datas,
          defaultProcedureIcd10: {
            ...(JSON.parse(data.value.string) as DefaultOrgDataIcd10),
            label: data.code.display ?? "No label",
          },
        }
      }

      return datas
    },
    { defaultProcedureIcd10: { label: "", male: [], female: [] } },
  )

  return (
    <div className="flex flex-col p-2 w-full">
      <p className="mb-1 text-lg">{defaultProcedureIcd10.label}</p>
      <div className="flex p-5">
        <div className="space-y-2 w-1/2">
          <p>
            <FontAwesomeIcon icon={faVenus} /> Female ICD-10 codes
          </p>
          <hr className="w-2/3" />
          {defaultProcedureIcd10.female.map((coding, index) => (
            <DefaultOrgDataItem key={index} {...{ ...coding }} />
          ))}
        </div>
        <div className="space-y-2 w-1/2">
          <p>
            <FontAwesomeIcon icon={faMars} /> Male ICD-10 codes
          </p>
          <hr className="w-2/3" />
          {defaultProcedureIcd10.male.map((coding, index) => (
            <DefaultOrgDataItem key={index} {...{ ...coding }} />
          ))}
        </div>
      </div>
    </div>
  )
}

const DefaultOrgDataItem: FC<Coding> = ({ code, display, system }) => (
  <div className="flex">
    <FontAwesomeIcon icon={faFileLines} className="mr-2 mt-1.5" />
    <div>
      <p>
        {display ?? "No display"} - <span title="Code">{code ?? "No code"}</span>
      </p>
      <p className="text-sm text-slate-400" title="System">
        {system ?? "No system"}
      </p>
    </div>
  </div>
)

type Props = {
  settings: SettingItemArray[]
}

export { DefaultOrgData }
