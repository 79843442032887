import {
  type IconDefinition,
  faCapsules,
  faChevronLeft,
  faClipboardListCheck,
  faClipboardPrescription,
  faPeriod,
  faProcedures,
  faSuitcaseMedical,
  faVials,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns-tz"
import { formatDate } from "date-fns/format"
import { type Coding, asReference } from "fhir"
import { type TabViewTabChangeEvent, TabPanel, TabView } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { MEDICATION_CATALOG, formatsByTypes } from "data"
import { useRunMC, useSavePlanLabs } from "mc"
import { useAppModuleContext } from "modules"
import { useOrganizationPractitioners } from "organization"
import { getBadgeColor, getLoincCode } from "utils"

import { ModulesId } from "../../../Module"
import { Badge } from "../../../components/Badge"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { getStatus, useLaboratoryOrder } from "../../../labs"
import type { TabPanelItem } from "../../../types"
import { useAlgorithmAssessmentPlanDetails, useCarePlanDetails, usePlanContext } from "../../hooks"
import { PLAN_ACTION_CODES, PLAN_DETAILS_TABS } from "../../types"
import { CPAssessmentsDetails } from "./CPAssessmentsDetails"
import { CPConsentsDetails } from "./CPConsentsDetails"
import { CPLabsDetails } from "./CPLabsDetails"
import { CPMedicationsDetails } from "./CPMedicationsDetails"
import { CPProcedureDetails } from "./CPProcedureDetails"
import { CPQuestionnaires } from "./CPQuestionnaires"
import { CPIntakesDetails } from "./intake/CPIntakesDetails"

const SelectedCPDetails = ({ selectedPlanId, showOrder, showProcedureCalculator, showAssessment }: Props) => {
  const { showModule } = useChartContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabId = searchParams.get("tabId")
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const {
    patientId,
    setCompleteDisabled,
    patient,
    currentOrganizationId,
    returnToListView,
    setReviewsEnabled,
    isPlanView,
  } = usePlanContext()
  const { appModules } = useAppModuleContext()
  const {
    carePlan,
    serviceRequest,
    questionnaires,
    appointment,
    procedure,
    isLoading,
    isProcedurePlan,
    isMCPlan,
    mcAlgorithmId,
    isOnlySurvey,
    mcPlanIds,
    nutraceuticals,
    rx,
    consents,
    intakes,
    reassessMedicationTask,
    candidateTask,
    configureActions,
    generatedCalculatorResult,
    generatedCarePlan,
    refetch: onSurveyHide,
    isRefetching: isUpdatingSurvey,
    observationDefinitions,
    extraResults,
    extraResultDRid,
  } = useCarePlanDetails(patientId, selectedPlanId)

  const shouldReassess = reassessMedicationTask?.status === "completed"
  const reassessNotes = reassessMedicationTask?.note

  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const { observations, isLoading: isLoadingLabResults } = useLaboratoryOrder(
    currentOrganizationId,
    serviceRequest?.id as string,
    patientId,
    organizationPractitionersInfo,
  )

  const {
    runAlgorithmCode,
    icd10,
    isLoading: isLoadingProcedureData,
  } = useAlgorithmAssessmentPlanDetails(mcAlgorithmId, isMCPlan)

  const { runMC, isEvaluating } = useRunMC((assessmentId) => {
    showModule({ module: ModulesId.MC, moduleParams: { mcPlanId: assessmentId } })
  })

  const availableExtraOD = useMemo(
    () =>
      observationDefinitions &&
      Object.values(observationDefinitions).reduce<Record<string, Coding[]>>(
        (acc, od) =>
          isLoadingLabResults || observations?.some((o) => getLoincCode(o.code.coding) === getLoincCode(od))
            ? acc
            : { ...acc, [getLoincCode(od)]: od },
        {},
      ),
    [observationDefinitions, observations],
  )

  const { savePlanLabs, isPending: isSaving } = useSavePlanLabs(() => {
    const planId = carePlan?.id ?? selectedPlanId
    if (!planId) return

    runMC({
      planId,
      patientRef: asReference(patient),
    })
  })

  const handleGenerateAssesment = () => {
    const patientPractRoleRef = organizationPractitionersInfo.find(
      ({ practitioner }) => practitioner.id === patient.generalPractitioner?.[0].id,
    )?.practitionerRoleRef

    if (patientPractRoleRef && runAlgorithmCode) {
      const planQrId = questionnaires?.[0]?.qResponse?.id ?? ""

      savePlanLabs({
        qrId: planQrId,
        observations: observations,
        performer: serviceRequest?.performer?.[0],
        patientRef: asReference(patient),
        requester: patientPractRoleRef,
        runAlgorithmCode: runAlgorithmCode as Coding,
        icd10,
      })
    }
  }

  const labStatus = serviceRequest && getStatus(serviceRequest)

  const {
    procedureRequired,
    procedureAction,
    allowEnterManualConditions,
    allowEnterManualAllergies,
    allowEnterManualMedStatements,
  } = useMemo(() => {
    const procedureAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]?.action?.find(({ code }) =>
      code?.[0]?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_PROCEDURE),
    )
    const allowEnterManualConditions = !!configureActions[PLAN_ACTION_CODES.MANUAL_CONDITION]
    const allowEnterManualAllergies = !!configureActions[PLAN_ACTION_CODES.MANUAL_ALLERGY]
    const allowEnterManualMedStatements = !!configureActions[PLAN_ACTION_CODES.MANUAL_MED_STATEMENT]

    return {
      procedureAction,
      procedureRequired: procedureAction?.requiredBehavior === "must",
      allowEnterManualConditions,
      allowEnterManualAllergies,
      allowEnterManualMedStatements,
    }
  }, [configureActions])
  const procedureActivityEnabled = useMemo(
    () =>
      carePlan?.activity?.find(({ outcomeCodeableConcept }) =>
        outcomeCodeableConcept?.[0]?.coding?.some(({ code }) => code === "procedure"),
      )?.enabled ?? false,
    [carePlan?.activity],
  )

  const tabPanelItems: TabPanelItem[] = [
    {
      id: PLAN_DETAILS_TABS.FORMS,
      visible: true,
      header: { icon: faClipboardListCheck, title: "Forms" },
      content: (
        <>
          {!!questionnaires?.length && (
            <section className="flex flex-col">
              <header className="text-gray-700">Questionnaires</header>
              <CPQuestionnaires
                questionnaires={questionnaires}
                isUpdatingSurvey={isUpdatingSurvey}
                isMCPlan={isMCPlan}
                onSurveyHide={onSurveyHide}
                className="!py-4 !pt-3"
              />
            </section>
          )}
          {!!consents?.length && (
            <section className="flex flex-col mt-2">
              <header className="pt-2 text-gray-700">Consents</header>
              <CPConsentsDetails consents={consents} />
            </section>
          )}
        </>
      ),
      contentClassName: "p-3 divide-y divide-gray-200",
    },
    {
      id: PLAN_DETAILS_TABS.INTAKE,
      visible:
        (!!Object.values(intakes ?? {}).length ||
          allowEnterManualConditions ||
          allowEnterManualAllergies ||
          allowEnterManualMedStatements) &&
        carePlan?.status === "active",
      header: { icon: faSuitcaseMedical, title: "Intake" },
      content: (
        <CPIntakesDetails
          carePlan={carePlan!}
          intakes={Object.values(intakes ?? {})}
          configureActions={configureActions}
          questionnaireData={questionnaires}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.LABS,
      visible: !!serviceRequest,
      header: { icon: faVials, title: "Labs" },
      content: (
        <CPLabsDetails
          carePlan={carePlan!}
          serviceRequest={serviceRequest}
          isOnlySurvey={isOnlySurvey}
          patientId={patientId}
          showOrder={showOrder}
          availableExtraOD={availableExtraOD}
          extraResults={extraResults}
          extraResultDRid={extraResultDRid}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.NUTRACEUTICALS,
      visible: !!nutraceuticals?.length,
      header: { icon: faCapsules, title: "Nutraceuticals" },
      content: (
        <CPMedicationsDetails
          medicationRequests={nutraceuticals}
          organizationId={currentOrganizationId}
          planId={selectedPlanId}
          category={MEDICATION_CATALOG.NUTRA}
          shouldResumeMedications={shouldReassess}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.PHARMACEUTICALS,
      visible: !!rx?.length,
      header: { icon: faClipboardPrescription, title: "Pharmaceuticals" },
      content: (
        <CPMedicationsDetails
          medicationRequests={rx}
          organizationId={currentOrganizationId}
          planId={selectedPlanId}
          category={MEDICATION_CATALOG.RX}
          shouldResumeMedications={shouldReassess}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.RECOMMENDATIONS,
      visible: isProcedurePlan,
      header: { icon: faProcedures, title: "Recommendations" },
      content: (
        <CPProcedureDetails
          carePlan={carePlan!}
          procedure={procedure}
          showProcedureCalculator={showProcedureCalculator}
          candidateTask={candidateTask}
          procedureAction={procedureAction}
          generatedCalculatorResult={generatedCalculatorResult}
          generatedCarePlan={generatedCarePlan}
        />
      ),
      contentClassName: "h-full",
    },
    {
      id: PLAN_DETAILS_TABS.ASSESMENTS,
      visible: isMCPlan,
      header: { icon: appModules[ModulesId.MC].getIcon(), title: "Assessments" },
      content: (
        <CPAssessmentsDetails
          patientId={patientId}
          mcAssessments={mcPlanIds?.length ? mcPlanIds : undefined}
          showAssessment={showAssessment}
          applyAssessment={carePlan?.status === "active" && runAlgorithmCode ? handleGenerateAssesment : undefined}
          isGeneratingAssessment={isSaving}
          isGeneratingAssessmentReport={isEvaluating}
          isLoading={isLoadingProcedureData || isLoadingLabResults}
          disabled={questionnaires?.[0]?.qResponse?.status !== "completed"}
        />
      ),
    },
  ]

  const handleTabChange = (e: TabViewTabChangeEvent) => {
    const tabId = tabPanelItems[e.index].id
    searchParams.set("tabId", tabId)
    setSearchParams(searchParams)
  }

  useLayoutEffect(() => {
    if (tabId) {
      const index = tabPanelItems.findIndex(({ id, visible }) => id === tabId && visible)
      setActiveTabIndex(Math.max(index, 0))
    }
  }, [tabId, tabPanelItems])

  useLayoutEffect(() => {
    if (isMCPlan && isPlanView) {
      showModule({
        module: ModulesId.MC,
        moduleParams: {
          subview: searchParams.get("subview") ?? ModulesId.PLAN_DETAILS,
          planId: selectedPlanId ?? "",
        },
      })
    }
  }, [isMCPlan, isPlanView])

  useEffect(() => {
    if (
      carePlan?.status !== "active" ||
      (labStatus && !["final-results-available", "completed"].includes(labStatus.code as string)) ||
      !questionnaires?.every(({ qResponse }) => qResponse?.status === "completed") ||
      (procedureRequired && procedureActivityEnabled && procedure?.status !== "completed") ||
      !consents?.every(({ status }) => status === "active") ||
      !rx.every(({ status, doNotPerform }) => ["active", "completed"].includes(status) || !!doNotPerform) ||
      !nutraceuticals.every(({ status, doNotPerform }) => ["active", "completed"].includes(status) || !!doNotPerform)
    ) {
      setCompleteDisabled(true)
    } else if (carePlan?.status === "active") {
      setCompleteDisabled(false)
    }
    setReviewsEnabled(
      (!labStatus || ["final-results-available", "completed"].includes(labStatus.code as string)) &&
        (!questionnaires || questionnaires.every(({ qResponse }) => qResponse?.status === "completed")),
    )
  }, [carePlan?.status, questionnaires, labStatus, procedure, consents, procedureRequired, procedureActivityEnabled])

  return (
    <div className="flex flex-col p-3 flex-1 min-w-0">
      {isLoading ? (
        <SkeletonLoader loaderType="two-lines" repeats={4} />
      ) : !carePlan ? (
        <span className="font-medium text-lg text-gray-700">Invalid plan id</span>
      ) : carePlan.status === "draft" ? (
        <span className="font-medium text-lg text-gray-700">This Plan has not yet been activated</span>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="flex items-baseline gap-2">
              <span onClick={returnToListView} className="cursor-pointer text-gray-900 h-6 w-6 text-center">
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <div className="flex flex-col">
                <h3 className="font-medium text-lg">{carePlan.title ?? "Treatment Plan Details"}</h3>
                <span className="text-gray-400 text-sm">
                  {appointment?.start || carePlan?.created
                    ? format(
                        new Date(appointment?.start ?? (carePlan?.created as string)),
                        formatsByTypes.FULL_DATETIME_WITH_TIMEZONE,
                      )
                    : "No date"}
                </span>
              </div>
            </div>
            <Badge
              {...getBadgeColor(
                carePlan.status === "revoked"
                  ? "cancelled"
                  : isMCPlan && carePlan.status === "active"
                    ? "pending"
                    : carePlan.status,
              )}
              className="h-min"
            />
          </div>
          <div className="flex flex-col grow overflow-y-auto flex-1">
            {shouldReassess && (
              <>
                <span className="font-medium text-sm text-gray-500 w-40 mt-3 mb-1">History</span>
                <ul className="flex flex-col gap-3 mb-3 w-full">
                  {reassessNotes?.map(({ text, author, time }, index) => {
                    const date = time && formatDate(time, formatsByTypes.SHORT_DATETIME)
                    const pract = author?.Reference?.display

                    return (
                      <li key={index} className="flex flex-col flex-1 ml-4 3xl:ml-2.5 relative">
                        <div className="flex space-x-1 text-gray-400 items-baseline">
                          <FontAwesomeIcon icon={faPeriod} className="text-xl" />
                          <p className="line-clamp-2 whitespace-normal text-sm">{`${text}${pract ? ` by ${pract}` : ""} ${date ? ` on ${date}` : ""}`}</p>
                        </div>
                        {reassessNotes.length > index + 1 && (
                          <span
                            className={classNames(
                              "border-l-2 absolute top-6 ml-1 3xl:ml-0.5 overflow-hidden",
                              text.length > 50 ? "h-10" : "h-6",
                            )}
                          />
                        )}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
            <TabView
              className="mt-3 sticky-header flex flex-col flex-1 -w-12"
              panelContainerClassName="h-full pb-0 flex-1"
              activeIndex={activeTabIndex}
              onTabChange={handleTabChange}
              scrollable
              pt={{
                nextButton: {
                  className: "w-auto h-auto top-[35%] shadow-none",
                },
                nextIcon: {
                  className: "text-primary",
                },
                prevButton: {
                  className: "w-auto h-auto top-[35%] shadow-none first:text-primary",
                },
              }}
            >
              {tabPanelItems.map(({ id, header: { icon, title }, visible, content, contentClassName }) => (
                <TabPanel
                  key={id}
                  header={<TabPanelHeader icon={icon} title={title} />}
                  visible={visible}
                  contentClassName={contentClassName}
                >
                  {content}
                </TabPanel>
              ))}
            </TabView>
          </div>
        </>
      )}
    </div>
  )
}

const TabPanelHeader: FC<{ icon: IconDefinition; title: string }> = ({ icon, title }) => (
  <div className="flex items-center text-primary gap-1">
    <FontAwesomeIcon icon={icon} />
    <span className="text-sm">{title}</span>
  </div>
)

type Props = {
  selectedPlanId?: string
  showOrder(srId: string): void
  showProcedureCalculator(planId?: string): void
  showAssessment(planId: string): void
}

export { SelectedCPDetails }
