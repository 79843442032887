import type { Duration, MedicationRequest } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import type { MedicationRequestData } from "commons/meds"
import { unitOfTime } from "data"

const durationTimings = [
  {
    code: "oneWeek",
    value: {
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    code: "oneMonth",
    value: {
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    code: "twoMonth",
    value: {
      period: 2,
      periodUnit: "mo",
    },
  },
  {
    code: "threeMonth",
    value: {
      period: 3,
      periodUnit: "mo",
    },
  },
  {
    code: "sixMonth",
    value: {
      period: 6,
      periodUnit: "mo",
    },
  },
]

const treatmentDurations = [
  {
    label: "For 1 week",
    value: {
      value: 1,
      code: unitOfTime[4].code,
      system: unitOfTime[4].system,
      unit: unitOfTime[4].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 2 weeks",
    value: {
      value: 2,
      code: unitOfTime[4].code,
      system: unitOfTime[4].system,
      unit: unitOfTime[4].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 3 weeks",
    value: {
      value: 3,
      code: unitOfTime[4].code,
      system: unitOfTime[4].system,
      unit: unitOfTime[4].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 1 month",
    value: {
      value: 1,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 2 month",
    value: {
      value: 2,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 3 month",
    value: {
      value: 3,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    } as Duration,
  },
  {
    label: "For 6 month",
    value: {
      value: 6,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    } as Duration,
  },
]

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "is-success"
    case "stopped":
      return "is-danger"
    case "completed":
      return "is-info"
    case "cancelled":
      return "is-warning"
    case "draft":
      return "is-warning"
    default:
      return ""
  }
}

const refreshMR = (updatedMR: MedicationRequest, medicationsList: MedicationRequestData[]) => {
  const index = medicationsList?.findIndex((mrData) => mrData.medicationRequestInfo.id === updatedMR.id)

  if (index !== -1) {
    const updatedMedData = {
      ...medicationsList[index],
      ...{ medicationRequestInfo: { ...medicationsList[index].medicationRequestInfo, ...updatedMR } },
    }
    medicationsList.splice(index, 1, updatedMedData)
  }
}

const discardMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to delete this prescription?",
    header: "Confirmation",
    acceptLabel: "Delete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const stopMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to stop this prescription?",
    header: "Confirmation",
    acceptLabel: "Stop",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const stopAndRenewMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to stop and renew this prescription?",
    header: "Confirmation",
    acceptLabel: "Stop & Renew",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const completeMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to mark as completed this prescription?",
    header: "Confirmation",
    acceptLabel: "Complete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

enum MedicationViews {
  medications = "medications",
  refills = "refills",
  history = "history",
  orders = "orders",
}

const medicationViewOptions = [
  { name: "Products", value: MedicationViews.medications },
  { name: "Scheduled Orders", value: MedicationViews.refills },
  { name: "Order History", value: MedicationViews.history },
  { name: "Pending Orders", value: MedicationViews.orders },
]

export {
  completeMR,
  discardMR,
  durationTimings,
  getStatusClass,
  medicationViewOptions,
  MedicationViews,
  refreshMR,
  stopAndRenewMR,
  stopMR,
  treatmentDurations,
}
