import { useInfiniteQuery } from "@tanstack/react-query"
import { type Questionnaire, type Reference, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { surveysQueryKeys } from "../query-keys"

const useQuestionnaire = () => {
  const { search } = useClient()
  const { partOfId, currentOrganizationId } = useOrganizationContext()
  const queryKey = surveysQueryKeys.all

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    QuestionnaireQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "100",
        _page: `${pageParam}`,
        "context:not": "labs",
        status: "active",
      })

      const bundle = await search({ endpoint: `Questionnaire`, filters, signal })

      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire").filter(
        (q) =>
          !q.useContext?.some((context) => context?.code?.code === "organization") ||
          q.useContext?.some(
            (context) =>
              context?.code?.code === "organization" &&
              context?.value?.Reference?.id === (partOfId ?? currentOrganizationId),
          ),
      )

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { questionnaires, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const { questionnaires, canonicalRefs, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.questionnaires)
    const count = newData?.length

    const canonicalQRefs = newData?.reduce((acc, q) => {
      const canonical = `${q.url}|${q.version}`
      const display = q.title

      return [...acc, { id: canonical, display, resourceType: q.resourceType }]
    }, Array<Reference>())

    return {
      questionnaires: newData,
      canonicalRefs: canonicalQRefs,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    questionnaires,
    canonicalRefs,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type QuestionnaireQueryData = { questionnaires: Questionnaire[]; next: number | undefined; total: number }

export { useQuestionnaire }
