import { useQuery } from "@tanstack/react-query"
import type { CodeableConcept, Coding } from "fhir"
import { useMemo } from "react"

import { organizationSettingId } from "data"

import { settingsQueryKeys } from "../../query-keys"
import { useSettingQueryFunction } from "./useSettings"

const useEnabledOrgConsents = (currentOrganizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsQueryKeys.consents(currentOrganizationId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const setting = await getSetting(currentOrganizationId, organizationSettingId.consents)

      return setting
    },
  })

  const enabledConsents = useMemo(
    () =>
      data?.items?.reduce((acc, item) => {
        return [
          ...acc,
          ...(item.value?.boolean && item.value?.Coding?.code ? [{ coding: item.value?.Coding, flag: item.flag }] : []),
        ]
      }, Array<{ coding: Coding; flag?: CodeableConcept[] }>()) ?? [],
    [data],
  )

  return { enabledConsents, isLoading }
}

export { useEnabledOrgConsents }
