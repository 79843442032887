import { useQuery } from "@tanstack/react-query"
import { type HealthcareService, type PlanDefinition, type Questionnaire, type Reference, getResources } from "fhir"
import { useMemo } from "react"
import type { Nullable } from "primereact/ts-helpers"

import { useClient } from "api"

import { practitionerAptsQueryKeys } from "../query-keys"
import { getQuestionnaireUrlFromCanonical } from "../utils"

const useAppointmentAvailableQuestionnaires = ({
  appointmentType,
  patientGender,
}: {
  appointmentType?: HealthcareService
  patientGender?: string
}) => {
  const { search } = useClient()
  const queryKey = practitionerAptsQueryKeys.questionnaires({
    appointmentTypeId: appointmentType?.id,
  })

  const availableQuestionnairesRefsRecord: Record<string, Reference> = useMemo(
    () =>
      (appointmentType?.contained?.[0] as Nullable<PlanDefinition>)?.action
        ?.find(({ code }) => code?.[0]?.coding?.[0]?.code === "questionnaires")
        ?.action?.reduce(
          (acc, action) => {
            if (action.definition?.canonical)
              return {
                ...acc,
                [getQuestionnaireUrlFromCanonical(action.definition.canonical)]: {
                  id: action.definition.canonical,
                  display: action.description,
                  resourceType: "Questionnaire",
                },
              }
            return acc
          },
          {} as Record<string, Reference>,
        ) ?? {},
    [appointmentType],
  )

  const availableQuestionnairesUrls = Object.keys(availableQuestionnairesRefsRecord)

  const { data, isLoading, isError, error } = useQuery<QuestionnaireQueryData, Error>({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
        ...(availableQuestionnairesUrls.length ? { url: `${availableQuestionnairesUrls?.join(",")}` } : {}),
      })

      const bundle = await search({ endpoint: `Questionnaire`, filters, signal })

      const questionnaires = getResources(bundle) as Questionnaire[]

      return { questionnaires }
    },
    meta: { context: { queryKey } },
    enabled: !!availableQuestionnairesUrls.length,
  })

  const { questionnaires } = useMemo(() => {
    if (!patientGender || patientGender === "unknown")
      return { questionnaires: data?.questionnaires?.map(({ url }) => availableQuestionnairesRefsRecord[url ?? ""]) }

    const filteredQuestionnairesByGender = data?.questionnaires
      ?.filter(({ useContext }) => {
        const questionnaireGender = useContext?.find(({ code }) => code?.code === "gender")?.value?.CodeableConcept
          ?.coding?.[0]?.code
        return !questionnaireGender || questionnaireGender === patientGender
      })
      ?.map(({ url }) => availableQuestionnairesRefsRecord[url ?? ""])

    return {
      questionnaires: filteredQuestionnairesByGender,
    }
  }, [data, patientGender])

  if (isError) {
    throw error
  }

  return {
    questionnaires,
    isLoading,
  }
}

type QuestionnaireQueryData = { questionnaires: Questionnaire[] }

export { useAppointmentAvailableQuestionnaires }
