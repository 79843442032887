import type { PaymentReconciliation } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { parseISO } from "date-fns"

import visa from "assets/visasm.png"
import masterCard from "assets/mastercard.png"
import americanExpress from "assets/amex.png"
import genericCard from "assets/gcard.png"
import dinersClub from "assets/dinersclub.png"
import discover from "assets/discover.png"
import jcb from "assets/jcb.png"
import { formatDate, getMoneyCurrencyAlt } from "utils"
import { ListItem } from "commons"
import { formatsByTypes } from "data"

const PaymentReconciliationListItem = ({ paymentReconciliation, onClick }: Props) => {
  const cards: Record<string, string> = {
    V: visa,
    MC: masterCard,
    DC: dinersClub,
    AE: americanExpress,
    D: discover,
    JCB: jcb,
    G: genericCard,
  }

  const head = <p title="Identifier">#{paymentReconciliation.paymentIdentifier?.value}</p>

  const details = (
    <>
      {paymentReconciliation.paymentDate && (
        <span title="Date" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {formatDate(parseISO(paymentReconciliation.paymentDate), formatsByTypes.LONG_DATE)}
        </span>
      )}
      <span title="Total Net" className="flex items-center pl-2">
        {paymentReconciliation?.paymentAmount?.value
          ? getMoneyCurrencyAlt(paymentReconciliation?.paymentAmount.currency) +
            paymentReconciliation.paymentAmount.value.toFixed(2)
          : "$0.00"}
      </span>
      {paymentReconciliation.creditCard && (
        <span className="flex items-center pl-2" title="Credit card">
          <img
            src={cards[paymentReconciliation.creditCard?.type as string]}
            alt={paymentReconciliation.creditCard?.type}
            width="48px"
            className="-mt-1 -ml-1"
          />
          <span>{paymentReconciliation.creditCard?.last4Digits}</span>
        </span>
      )}
    </>
  )

  return <ListItem header={head} subHeader={details} onContentClick={onClick} />
}

type Props = {
  paymentReconciliation: PaymentReconciliation
  onClick?(): void
}

export { PaymentReconciliationListItem }
