import { type FC, type PropsWithChildren, createContext } from "react"

const CPOEContext = createContext<CpoeContext | undefined>(undefined)
CPOEContext.displayName = "CPOEContext"

const CPOEContextProvider: FC<PropsWithChildren<CpoeContext>> = ({ children, ordersCount, showCpoeOverlay }) => (
  <CPOEContext.Provider
    value={{
      ordersCount,
      showCpoeOverlay,
    }}
  >
    {children}
  </CPOEContext.Provider>
)

type CpoeContext = {
  ordersCount: number
  showCpoeOverlay(step: string): void
}

export { CPOEContextProvider, CPOEContext }
