import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import type { BundleEntryArray } from "fhir"
import { useEffect, useMemo } from "react"

import { allergiesQueryKeys } from "allergies"
import { useClient } from "api"

import { patient360QueryKeys } from "../query-keys"

const usePatientAllergies = (patientId: string) => {
  const { search } = useClient()
  const queryClient = useQueryClient()
  const queryKey = patient360QueryKeys.allergies(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientAllergiesQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        "identifier:text": "patient360",
        _count: "20",
        _page: `${pageParam}`,
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/AllergyIntolerance`, filters, signal })

      const allergiesEntries = bundle.entry ?? []

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { allergiesEntries, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: allergiesQueryKeys.all })
  }, [data])

  const { allergiesEntries, count } = useMemo(() => {
    let count = 0
    const newData = data?.pages.reduce<{
      allergiesEntries: BundleEntryArray[]
      next: number | undefined
      total: number
    }>(
      (prev, current) => {
        const newObservations = prev?.allergiesEntries.concat(current.allergiesEntries)
        count += current.allergiesEntries.length

        return { allergiesEntries: newObservations, next: current.next, total: current.total }
      },
      { allergiesEntries: [], next: 0, total: 0 },
    )

    return {
      allergiesEntries: newData?.allergiesEntries ?? [],
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    allergiesEntries,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadObservations: refetch,
  }
}

type PatientAllergiesQueryData = {
  allergiesEntries: BundleEntryArray[]
  next: number | undefined
  total: number
}

export { usePatientAllergies }
