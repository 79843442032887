import { type QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import type { Procedure } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useUpdateProcedure = (onSettled: () => void, queryKey: QueryKey) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const patchOrder = async (procedure: Partial<Procedure>) => patch("Procedure", procedure.id as string, procedure)

  const { mutate: updateProcedure, isPending: isUpdating } = useMutation({
    mutationFn: patchOrder,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      })
      displayNotificationSuccess("Update applied successfully!")
      // datadogLogs.logger.info(`Condition on ${subject.id} updated successfully!`)
    },
    onSettled: onSettled,
  })

  return { updateProcedure, isUpdating }
}

export { useUpdateProcedure }
