import { type FC } from "react"
import type { Encounter } from "fhir"

import { usePatientContext } from "patients"
import { Button, StackedListItem } from "commons"
import { useOrganizationContext } from "organization"

import { EncounterWrapUp } from "./details/EncounterWrapUp"
import { EncounterFinishButton } from "./EncounterFinishButton"
import { encounterModelBuilder } from "./encounterModelBuilder"

const OpenEncounter: FC<Props> = ({ encounter, handleCloseModule }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { patientId, patientRef } = usePatientContext()

  return (
    <div className="flex flex-col overflow-hidden h-full space-y-4 relative">
      <StackedListItem modelData={encounterModelBuilder({ ...encounter, status: "" })} className="py-0 @container" />

      <EncounterWrapUp
        encounterId={encounter.id as string}
        patientId={patientId}
        patientRef={patientRef}
        organizationId={currentOrganizationId}
        className="grow overflow-y-auto"
      />

      <section className="flex flex-shrink-0 justify-end gap-3 px-4 py-4 bg-white border-t border-gray-200 h-fit">
        <Button label="Resume" buttonStyle="default" onClick={handleCloseModule} />
        <EncounterFinishButton
          encounter={encounter}
          patientId={patientId}
          patientRef={patientRef}
          showStopAction
          onEncounterFinished={handleCloseModule}
        />
      </section>
    </div>
  )
}

type Props = {
  encounter: Encounter
  handleCloseModule(): void
}

export { OpenEncounter }
