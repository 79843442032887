import { codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import { InputField, NumberField } from "commons"
import { commonsDispenseInterval } from "commons/meds"
import { MED_FEE_TYPE, MEDICATION_PRODUCT_TYPE } from "data"
import { sumPrice } from "utils"

import type { MEDICATIONS_SECTION_TYPE, MedItem } from "../../types"

const MedicationForm = ({ isEditing }: Props) => {
  const {
    setFieldValue,
    values: { fee, feeType, price: basePrice, referencePrices, mk },
  } = useFormikContext<MedItem>()

  const packaging = mk?.packaging?.type?.coding?.[0]?.display
  const doseForm = codeableConceptAsString(mk.doseForm)

  const isPharmaceutical = mk?.productType?.[0]?.coding?.[0]?.code === MEDICATION_PRODUCT_TYPE.RX
  const feeByFrequencyFieldVisible = useMemo(() => feeType === MED_FEE_TYPE.ByFrequency, [feeType])

  const renderFeeByFrequencyField = ({ start = 0, len }: { start?: number; len?: number }) =>
    commonsDispenseInterval.slice(start, len).map(({ label }, index) => (
      <div className="relative" key={label}>
        <NumberField
          field={`fee[${index + start}].value`}
          min={0}
          mode="currency"
          allowEmpty={false}
          validation={(value) =>
            value !== undefined && value !== null && value.toString() !== "0" ? false : "Fee is required"
          }
          showButtons={false}
        />
        <span className="absolute inset-y-0 right-2  text-gray-500 text-xs top-[0.625rem]">{label}</span>
      </div>
    ))

  return (
    <div className="pt-1.5">
      <div className="p-fluid grid gap-4">
        <InputField field="display" /* label="Medication" */ placeholder="Add a name" disabled={isEditing} />
      </div>
      {!!referencePrices.length && (
        <div className="flex flex-col gap-1 pt-2 pb-4 space-y-1 text-sm">
          <span className="font-semibold pb-1">{`${packaging} Price:`}</span>
          <div className="flex items-center divide-x-2 flex-wrap space-x-2">
            {referencePrices.map(({ qty, price }, index) => (
              <div key={qty} className={classNames({ "pl-2": !!index })}>
                {[`${qty} ${doseForm}`, `$${price?.toFixed(2)}`].join(" - ")}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-center flex-col flex-1 space-y-3 mt-4">
        <div className="pt-2 flex justify-between items-center text-sm mb-2">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="divide-gray-200 divide-y flex justify-center flex-col flex-1 space-y-3">
          <div className="pb-1 flex flex-col">
            <div className={classNames("grid grid-cols-2 gap-4", { "items-end": isPharmaceutical })}>
              <div className="flex flex-col">
                <span className="font-semibold text-sm">{feeByFrequencyFieldVisible ? "Fee by frequency" : "Fee"}</span>
                {isPharmaceutical && (
                  <div className="flex items-center">
                    <Checkbox
                      inputId="enableFeeByFrequency"
                      onChange={(e) => {
                        if (e.checked) {
                          setFieldValue("fee", [
                            ...commonsDispenseInterval.map((frequency) => ({ value: 0, duration: frequency.value })),
                          ])
                        } else {
                          setFieldValue("fee", [{ value: 0 }])
                        }
                        setFieldValue("feeType", e.checked ? MED_FEE_TYPE.ByFrequency : MED_FEE_TYPE.Fixed)
                      }}
                      checked={feeByFrequencyFieldVisible}
                    />
                    <label htmlFor="enableFeeByFrequency" className="ml-1 cursor-pointer text-sm">
                      Set fee by frequency
                    </label>
                  </div>
                )}
              </div>
              {feeByFrequencyFieldVisible ? (
                renderFeeByFrequencyField({ len: 1 })
              ) : (
                <NumberField
                  min={0}
                  field="fee[0].value"
                  mode="currency"
                  className="p-inputtext-sm w-full p-fluid"
                  allowEmpty={false}
                  showButtons={false}
                />
              )}
            </div>
            {feeByFrequencyFieldVisible && (
              <div className="grid grid-cols-2 gap-4 pt-4">{renderFeeByFrequencyField({ start: 1 })}</div>
            )}
          </div>
          {!feeByFrequencyFieldVisible && (
            <div className="pt-2 flex justify-between">
              <span className="font-semibold text-sm">Total</span>
              <span className="font-semibold text-sm">${sumPrice(basePrice, fee[0].value ?? 0).sum.toFixed(2)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = {
  isEditing?: boolean
  isLoadingData?: boolean
  medType: MEDICATIONS_SECTION_TYPE
}

export { MedicationForm }
