import type { FormikValues } from "formik"
import { type FC, useEffect, useId, useMemo } from "react"

import { useChartContext } from "chart-view"
import {
  DataContainerWithFormSlideoverDetail,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import {
  type PlanData,
  PLAN_SUBVIEWS,
  planItemModel,
  useCancelPlan,
  useCarePlans,
  usePlanContext,
} from "commons/care-plans"
import { MC_PUBLISHER } from "data"
import { useAppModuleContext } from "internals"

import { planStatusOptions } from "../data"

const PlanListContainer: FC = () => {
  const loaderKey = useId()
  const { appModules, isModuleActive } = useAppModuleContext()
  const chartContext = useChartContext()
  const { patientId, navigateToSubview, handleConfigurePlan } = usePlanContext()
  const { deleteIndex, reset, setDeleteIndex } = useStateReducer()

  const { plans, isLoading, isFetching } = useCarePlans({
    patientId,
    searchText: chartContext.searchData.filter,
    statusFilter: chartContext.searchData.selectedStatus,
  })

  const showDetails = (planId: string) => {
    navigateToSubview(PLAN_SUBVIEWS.DETAILS, planId)
  }

  const showConfig = (planId: string) => {
    handleConfigurePlan(planId)
  }

  const { cancelPlan, isCanceling } = useCancelPlan(() => {
    reset()
  })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const filteredPlans = useMemo(
    () =>
      plans.filter(
        ({ planDefinition, carePlan }) => planDefinition.publisher !== MC_PUBLISHER && carePlan.intent !== "order",
      ),
    [plans],
  )

  useEffect(() => {
    chartContext.setSearchData({
      placeholder: `Search Treatment Plans`,
      selectedStatus: ["draft", "active", "completed"],
      statusOptions: planStatusOptions,
      showSearch: true,
    })

    return () => {
      chartContext.setSearchData({
        showSearch: false,
      })
    }
  }, [])

  return isLoading ? (
    loader()
  ) : (
    <>
      <DataContainerWithFormSlideoverDetail
        showForm={false}
        hasData={filteredPlans.length > 0}
        customAddButtonText={`Enroll Patient in New Treatment Plan`}
        iconDataNotFound={appModules[ModulesId.PLANS].getIcon()}
        itemTitleNotFound="Treatment Plan"
        onButtonAddClick={() => navigateToSubview(PLAN_SUBVIEWS.ENROLL)}
        disabledAddButton={!chartContext.hasGP}
        form={<></>}
        onCancel={reset}
        deleteSupport={{
          confirmDeleteText: `Are you sure you want to cancel this treatment plan?`,
          deleteActionText: "Accept",
          showDelete: deleteIndex !== undefined || isCanceling,
          isDeleting: isCanceling,
          onCancelDelete: () => setDeleteIndex(undefined),
          onConfirmDelete: () => cancelPlan(deleteIndex as string),
        }}
        addButtonClassName="create-plan-button"
      >
        <div className="h-full overflow-auto px-3">
          <StackedListContainer
            data={filteredPlans}
            itemModelBuilder={(item) => {
              return planItemModel({
                plan: item,
                isLoading: isFetching,
                viewPlan: () => {
                  showDetails(item.carePlan.id as string)
                },
                editPlan: () => {
                  showConfig(item.carePlan.id as string)
                },
                cancelPlan: () => setDeleteIndex(item.carePlan.id),
                contextLabel: "treatment plan",
                showConfig: chartContext.hasGP && isModuleActive(ModulesId.LABS),
                lineItemClassname: "text-gray-500",
              })
            }}
          />
        </div>
      </DataContainerWithFormSlideoverDetail>
    </>
  )
}

const useStateReducer = () => {
  const { showSlide, initialValue, isNew, deleteIndex, add, hardReset, setDeleteIndex } = useCrudReducer<
    PlanData,
    FormikValues
  >({})

  return {
    showForm: showSlide,
    initialValue,
    isNew,
    deleteIndex,
    add,
    reset: hardReset,
    setDeleteIndex,
  }
}

export { PlanListContainer }
