import { faEye, faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Coverage, Reference } from "fhir"
import { type FieldProps, useFormikContext } from "formik"
import { Dialog } from "primereact/dialog"
import { type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC, useState } from "react"

import {
  CoverageDetail,
  CoverageForm,
  coverageValidationSchema,
  getCoverageDisplay,
  getCoverageInitialValues,
  sanitizeCoverage,
  useCoverages,
  useCreateCoverage,
} from "coverage"
import type { CoverageData } from "coverage/types"
import { usePatientContext } from "patients"
import { getStringAddress } from "utils"

import { Button } from "../../components/Buttons"
import { ReplaceFormProvider } from "../../context"
import { DialogFormContainer } from "../DialogFormContainer"
import { FormField } from "../FormField"

enum OperationType {
  "Details",
  "Edit",
}

const InsuranceField: FC<Props> = ({
  field,
  label,
  showEdit = true,
  horizontal,
  validation,
  className,
  labelClassName,
  ...dropdownProps
}) => {
  const { patientId } = usePatientContext()
  const { coverages } = useCoverages(patientId)
  const [itemOperation, setItemOperation] = useState<{ item: Coverage; operation: OperationType } | undefined>(
    undefined,
  )
  const [showAdd, setShowAdd] = useState(false)

  const itemTemplate = (item: CoverageData) => {
    return (
      <div className="flex justify-between items-center gap-1 max-w-xs lg:max-w-full guiding-insurance-item">
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap">
            {getCoverageDisplay(item)}
          </span>
          <span className="text-ellipsis text-sm overflow-hidden whitespace-nowrap text-gray-400">
            {getStringAddress(item.payor?.[0]?.address)}
          </span>
        </div>
        <div className="flex space-x-2 justify-end text-sm">
          <FontAwesomeIcon
            icon={faEye}
            title="Details"
            className="hover:text-primary-hover"
            onClick={(e) => {
              e.stopPropagation()
              setItemOperation({ item, operation: OperationType.Details })
            }}
          />

          {showEdit && (
            <FontAwesomeIcon
              icon={faPencil}
              title="Edit"
              className="hover:text-primary-hover"
              onClick={(e) => {
                e.stopPropagation()
                setItemOperation({ item, operation: OperationType.Edit })
              }}
            />
          )}
        </div>
      </div>
    )
  }

  const handleHideOperation = () => {
    setItemOperation(undefined)
    setShowAdd(false)
  }

  const { setFieldValue } = useFormikContext()

  const { createCoverage } = useCreateCoverage(handleHideOperation, (data) => {
    if (data.id) {
      setFieldValue(field, {
        id: data.id,
        display: getCoverageDisplay(data),
        resourceType: data.resourceType ?? "Coverage",
      } as Reference)
    }
  })

  const hanldeFormSubmit = (coverage: CoverageData) => {
    createCoverage(sanitizeCoverage(coverage))
  }

  return (
    <>
      <FormField
        field={field}
        label={label}
        validation={validation}
        horizontal={horizontal}
        className={className}
        labelClassName={labelClassName}
      >
        {({ field: { name, value }, form: { setFieldValue }, meta: { error, touched } }: FieldProps) => (
          <div className="flex w-full space-x-3">
            <Dropdown
              id={name}
              name={name}
              dataKey="id"
              options={coverages}
              optionLabel="payor.0.display"
              filterBy="payor.0.display"
              value={value}
              onChange={(e) => {
                setFieldValue(
                  name,
                  e.value
                    ? ({
                        id: e.value.id,
                        display: getCoverageDisplay(e.value),
                        resourceType: e.value.resourceType ?? "Coverage",
                      } as Reference)
                    : undefined,
                )
              }}
              itemTemplate={itemTemplate}
              className={classNames("p-inputtext-sm w-full", { "p-invalid": error && touched })}
              {...dropdownProps}
            />
            <Button
              buttonStyle="default"
              icon={faPlus}
              title="Add new insurance"
              onClick={() => {
                setShowAdd(true)
              }}
            />
          </div>
        )}
      </FormField>

      {itemOperation?.operation === OperationType.Details ? (
        <Dialog
          header="Coverage Details"
          visible
          closable={true}
          draggable={false}
          resizable={false}
          onHide={handleHideOperation}
          footer={
            <div className="mt-2">
              <Button label="Close" buttonStyle="default" size="xl" className="m-0" onClick={handleHideOperation} />
            </div>
          }
        >
          <CoverageDetail coverage={itemOperation.item} />
        </Dialog>
      ) : (
        (itemOperation?.operation === OperationType.Edit || showAdd) && (
          <ReplaceFormProvider>
            <DialogFormContainer
              title={showAdd ? "Add Coverage Information" : "Edit Coverage Information"}
              onSubmit={hanldeFormSubmit}
              initialValue={showAdd ? getCoverageInitialValues(patientId) : itemOperation?.item}
              showForm
              useFormik
              onCancel={handleHideOperation}
              validationSchema={coverageValidationSchema}
            >
              <CoverageForm />
            </DialogFormContainer>
          </ReplaceFormProvider>
        )
      )}
    </>
  )
}

type Props = {
  field: string
  label?: string
  validation?(value: Reference): string | undefined
  showEdit?: boolean
  horizontal?: boolean
  labelClassName?: string
} & DropdownProps

export { InsuranceField }
