import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Parameters } from "fhir"

import { useClient } from "api"
import { type AppointmentFormData, sanitize } from "appointments"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

import { patientAptsQueryKeys, practitionerAptsQueryKeys } from "../query-keys"

const useBookAppointment = (onSettled: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { currentOrganizationId } = useOrganizationContext()

  const book = async (appointment: AppointmentFormData) => {
    const { questionnaires, ...appointmentData } = appointment
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "appointment-resource",
          resource: sanitize(appointmentData),
        },
        ...(questionnaires?.length
          ? [
              {
                name: "actions",
                part: [
                  {
                    name: "questionnaires",
                    part: questionnaires.flatMap((canonical) => ({ name: "item", value: { canonical } })),
                  },
                ],
              },
            ]
          : []),
      ],
    }

    return operationRequest({
      endpoint: "Appointment",
      method: "POST",
      operation: "book",
      parameters,
    })
  }

  const { mutate: bookAppointment, isPending: isBooking } = useMutation({
    mutationFn: book,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientAptsQueryKeys.all })
      await queryClient.refetchQueries({
        queryKey: practitionerAptsQueryKeys.all(currentOrganizationId),
        type: "all",
      })
      displayNotificationSuccess("Appointment booked successfully!")
    },
    onSettled,
  })

  return { bookAppointment, isBooking }
}

export { useBookAppointment }
