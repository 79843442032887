import { type IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { codeableConceptAsString } from "fhir"
import { isValid } from "date-fns/isValid"
import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"

import { formatsByTypes } from "data"
import { LaboratoryValueItem } from "commons/labs"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"

const IntakeLabSection = ({ labs, total, icon, hideSpacer }: Props) => (
  <WrapUpSection
    subSectionTitle="Imported Labs"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No labs imported during this encounter"
    className="flex flex-row gap-x-16 w-full text-sm"
    useSpacer={!hideSpacer}
  >
    <div className="flex flex-col gap-6 pr-7 divide-y divide-dashed divide-gray-200 w-full">
      {labs?.map(({ id, code, effective, value }) => (
        <div key={id} className="inline-flex justify-between w-full items-center pt-6">
          <span className="truncate flex-1">{codeableConceptAsString(code)}</span>
          <span className="flex min-w-max items-center text-gray-400">
            {effective?.dateTime && isValid(effective.dateTime) && (
              <span>{format(parseISO(effective.dateTime), formatsByTypes.SHORT_DATETIME)}</span>
            )}
            {effective?.dateTime && value && <span className="mx-1 w-1 h-4 border-gray-200" />}
            {value?.string && <LaboratoryValueItem value={value?.string} className="items-center" />}
            {value?.Quantity && (
              <LaboratoryValueItem
                value={`${value.Quantity.value} ${value.Quantity.unit ?? ""}`}
                className="items-center"
              />
            )}
          </span>
        </div>
      ))}
    </div>
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "labs">

export { IntakeLabSection }
