import { faScannerGun } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarDays, faRotate } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import type { Invoice } from "fhir"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"

import { ListItem } from "commons"
import { formatsByTypes, invoiceStatusCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getMoneyCurrencyAlt } from "utils"

const InvoiceListItem = ({ invoice, className, showMenu, onClick }: Props) => {
  const dropdownItems = [
    {
      label: "Refresh",
      icon: <FontAwesomeIcon icon={faRotate} size="sm" className="mr-2" />,
      command: () => ({}),
    },
  ]

  const invoiceDate = invoice.date && format(parseISO(invoice.date), formatsByTypes.LONG_DATETIME_WITH_TIMEZONE)

  const itemsCount = invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length

  const price = invoice?.totalGross?.value
    ? getMoneyCurrencyAlt(invoice?.totalGross.currency) + invoice.totalGross.value.toFixed(2)
    : "$0.00"

  const head = (
    <>
      <p className="truncate font-medium" title="Invoice number">
        {`#${invoice.identifier?.find(({ system }) => system === SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value ?? "Unspecified number"}`}
      </p>
      <span title="Status">
        <Chip
          label={invoiceStatusCodes.find((status) => status.code === invoice.status)?.display}
          className={classNames("ml-2 custom-chip", {
            "is-danger": invoice.status === "cancelled",
            "is-warning": invoice.status === "entered-in-error",
            "is-success": invoice.status === "balanced",
            "is-info": invoice.status === "issued",
            "is-secondary": invoice.status === "draft",
          })}
        />
      </span>
    </>
  )

  const details = (
    <>
      {invoice.date && (
        <span title="Issued date" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {invoiceDate}
        </span>
      )}
      <span title="Total Net" className="pl-2">
        {price}
      </span>
      <span title="Items" className="flex items-center pl-2">
        <FontAwesomeIcon icon={faScannerGun} className="mr-1.5 text-gray-400" />
        {itemsCount}
      </span>
    </>
  )

  return (
    <ListItem
      header={head}
      subHeader={details}
      dropdownMenuItems={showMenu ? dropdownItems : undefined}
      onContentClick={onClick}
      className={className}
    />
  )
}

type Props = {
  invoice: Invoice
  className?: string
  showMenu?: boolean
  onClick?(): void
}

export { InvoiceListItem }
