import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type ListEntryArray, type Parameters, type ParametersParameterArrayArray, asReference } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"
import type { AppointmentType } from "../../types"

const useCreateAppointmentType = (organizationId: string, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const newAppointmentType = async ({
    id,
    name,
    combos,
    color,
    daysInAdvance,
    devices,
    duration,
    practitioners,
    rooms,
    telemedicine,
    patientCanBook,
    provider,
    questionnaires,
    requester,
    icd10,
    withLabs,
    withQuestionnaires,
  }: AppointmentType) => {
    const combosEntry = combos?.reduce((acc, { id }) => {
      return [...acc, { name: "item", value: { canonical: id } }]
    }, Array<ParametersParameterArrayArray>())

    const icd10Entry = icd10?.length
      ? { name: "diagnostic-codes", value: { string: JSON.stringify(icd10) } }
      : undefined

    const questionnairesEntry = questionnaires?.reduce((acc, { id }) => {
      return [...acc, { name: "item", value: { canonical: id } }]
    }, Array<ParametersParameterArrayArray>())

    const devicesEntry = devices?.reduce((acc, ref) => {
      return [...acc, { item: asReference(ref) }]
    }, Array<ListEntryArray>())

    const practitionersEntry = practitioners?.reduce((acc, ref) => {
      return [...acc, { item: asReference(ref) }]
    }, Array<ListEntryArray>())

    const roomsEntry = rooms?.reduce((acc, ref) => {
      return [...acc, { item: asReference(ref) }]
    }, Array<ListEntryArray>())

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "participants",
          resource: {
            resourceType: "List",
            mode: "Working",
            status: "Current",
            entry: [...(devicesEntry ?? []), ...(practitionersEntry ?? []), ...(roomsEntry ?? [])],
          },
        },
        {
          name: "name",
          value: {
            string: name,
          },
        },
        { name: "organization", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        {
          name: "telemedicine",
          value: {
            boolean: telemedicine,
          },
        },
        {
          name: "patient-can-book",
          value: {
            boolean: patientCanBook,
          },
        },
        ...(patientCanBook && daysInAdvance
          ? [
              {
                name: "days-in-advance",
                value: {
                  integer: daysInAdvance,
                },
              },
            ]
          : []),
        {
          name: "color",
          value: {
            string: color,
          },
        },

        { name: "duration-minutes", value: { integer: duration } },
        ...(withLabs || withQuestionnaires
          ? [
              {
                name: "actions",
                part: [
                  ...(withQuestionnaires
                    ? [
                        {
                          name: "questionnaires",
                          part: [...(questionnairesEntry ?? [])],
                        },
                      ]
                    : []),
                  ...(withLabs
                    ? [
                        {
                          name: "labs",
                          part: [
                            {
                              name: "provider",
                              value: { Reference: provider },
                            },
                            {
                              name: "practitioner",
                              value: { Reference: requester },
                            },
                            ...(icd10Entry ? [icd10Entry] : []),
                            ...(combosEntry ?? []),
                          ],
                        },
                      ]
                    : []),
                ],
              },
            ]
          : [{ name: "actions", part: [] }]),
      ],
    }

    return operationRequest({
      endpoint: `HealthcareService${id ? `/${id}` : ""}`,
      method: "POST",
      operation: id ? "edit" : "create",
      parameters,
    })
  }

  const { mutate: createAppointmentType, isPending } = useMutation({
    mutationFn: newAppointmentType,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { id }) => {
      await queryClient.refetchQueries({
        queryKey: settingsQueryKeys.appointment({ organizationId }),
      })
      displayNotificationSuccess(`Appointment type ${id ? "updated" : "created"} successfully!`)
      // datadogLogs.logger.info(`Appointment type on ${organizationId} created successfully!`, { laboratory })
    },
    onSettled,
  })

  return { createAppointmentType, isCreating: isPending }
}

export { useCreateAppointmentType }
