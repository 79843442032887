import { addDays } from "date-fns/addDays"
import { startOfDay } from "date-fns/startOfDay"
import type { Reference } from "fhir"
import { useFormikContext } from "formik"
import { type FC, useMemo } from "react"

import { HEALTH_GORILLA_VALUE_CHECK } from "data"
import { SYSTEM_VALUES } from "system-values"

import { PractitionerRoleDropdownField } from "../../../../forms"
import { DateField } from "../../../../forms/DateField"
import type { PractitionerInfo } from "../../../../types"
import type { PlanData } from "../../../types"
import { validatePlanRequester } from "../../validations"

const Appointment: FC<Props> = ({ practitionersInfo, configs: { requiresLabs, requiresRXs }, contentClassName }) => {
  const {
    values: { mailTasks, appointment },
    setFieldValue,
  } = useFormikContext<Partial<PlanData>>()

  const allowedPractitioners = useMemo(
    () =>
      practitionersInfo.filter(
        ({ practitioner: { identifier }, practitionerRole }) =>
          (requiresLabs ? identifier?.some(({ value }) => value?.includes(HEALTH_GORILLA_VALUE_CHECK)) : true) &&
          (requiresRXs
            ? practitionerRole?.identifier?.some(({ system }) => system === SYSTEM_VALUES.LIFEFILE_PRACTITIONER)
            : true),
      ),
    [practitionersInfo, requiresLabs, requiresRXs],
  )

  const onAppointmentChange = (value?: Date) => {
    if (value && mailTasks)
      Object.values(mailTasks)
        ?.filter(({ showable, editable }) => !!showable && !!editable)
        .forEach((task) => {
          let date = value && addDays(startOfDay(new Date(value)), -(task.daysBeforeToMail ?? 0))
          date = date >= new Date() ? date : new Date()
          setFieldValue(`mailTasks.${task.taskId}.restriction.period.start`, date.toISOString())
        })
  }

  return (
    <>
      <PractitionerRoleDropdownField
        field="requester"
        label="Practitioner"
        useFilter={false}
        options={allowedPractitioners}
        validate={(req: Reference | undefined) =>
          validatePlanRequester({
            value: req,
            practitionersInfo: allowedPractitioners,
            requiresLabs,
            requiresRX: requiresRXs,
            isRequired: true,
          })
        }
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
        className={contentClassName}
      />

      {!!appointment?.id && (
        <DateField
          label="Appointment date"
          field="appointment.start"
          validation={(value) => !value && "Appointment date is required"}
          placeholder="Select Date"
          minDate={new Date()}
          showTime
          onChange={onAppointmentChange}
          className={contentClassName}
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
        />
      )}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  configs: {
    requiresAlgorithm: boolean
    requiresLabs: boolean
    requiresEmails: boolean
    requiresNutras: boolean
    requiresRXs: boolean
  }
  contentClassName?: string
}

export { Appointment }
