import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type Bundle, type MedicationRequest, getResources } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useStopMedicationRequest = (onSettled?: () => void, refreshMedications?: (meds: MedicationRequest[]) => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const stopMedicationReq = async (medicationRequestIds: string[]) => {
    const bundleRequest: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: medicationRequestIds.map((id) => {
        return {
          request: {
            method: "POST",
            url: `MedicationRequest/${id}/$stop`,
          },
        }
      }),
    }

    return transaction<Bundle>(bundleRequest)
  }
  const { mutate: stopMedicationRequest, isPending } = useMutation({
    mutationFn: stopMedicationReq,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async (meds) => {
      const medications = getResources<MedicationRequest>(meds)
      refreshMedications?.(medications)
      await queryClient.refetchQueries({ queryKey: medsQueryKeys.medicationRequestList(patientId, "medication") })
      await queryClient.refetchQueries({ queryKey: medsQueryKeys.medicationRequestList(patientId, "nutraceutical") })
      displayNotificationSuccess("Prescription stopped successfully!")
      // datadogLogs.logger.info("Prescription stopped successfully!", { medications })
    },
    onSettled: onSettled,
  })

  return { stopMedicationRequest, isLoading: isPending }
}

export { useStopMedicationRequest }
