import { useQuery } from "@tanstack/react-query"
import { type Questionnaire, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useTestQuestionnaires = (questionnairesCanonicals?: string[]) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.testsQuestionnaires(questionnairesCanonicals)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        url: questionnairesCanonicals?.join(",").replaceAll(/([|])+[\d]+/g, "") ?? "",
        status: "active",
      })

      const bundle = await search({ endpoint: "Questionnaire", filters, signal })
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")

      return {
        questionnaires,
        total: bundle?.total,
      }
    },
    enabled: !!questionnairesCanonicals?.length,
    meta: { context: { queryKey, questionnairesCanonicals } },
  })

  const questionnaires = useMemo(() => {
    const qRecord =
      data?.questionnaires.reduce(
        (acc, q) => {
          const code = q.item?.map(({ code }) => code?.[0]?.code as string)
          return { ...acc, ...(code ? { [code.join("-")]: q } : {}) }
        },
        {} as Record<string, Questionnaire>,
      ) ?? {}

    return Object.values(qRecord) ?? []
  }, [data?.questionnaires])

  return {
    questionnaires,
    count: data?.total,
    isLoading,
  }
}

export { useTestQuestionnaires }
