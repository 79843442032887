import type { Coding, Composition } from "fhir"
import { type CheckboxChangeEvent, Checkbox } from "primereact/checkbox"
import { useEffect, useState } from "react"

import type { MedicationsAdvanceFilter } from "../../types"

const MedicationsFilters = ({ filters, catalogOptions, medicineClassificationOptions, onFilter }: Props) => {
  const [selectedMkCatalogs, setSelectedMkCatalogs] = useState(filters?.mkCatalogs ?? [])
  const [selectedMedsClassificationCodes, setSelectedMedsClassificationCodes] = useState(
    filters?.medsClassificationCodes ?? [],
  )

  useEffect(() => {
    if (!selectedMedsClassificationCodes?.length) {
      setSelectedMedsClassificationCodes(filters.medsClassificationCodes!)
    }
  }, [filters?.medsClassificationCodes])

  const handleChangeFilters = (field: string, value: boolean | string[]) =>
    field === "mkCatalogs"
      ? onFilter({ mkCatalogs: value as string[] })
      : onFilter({ medsClassificationCodes: value as string[] })

  const onSelectedMkCatalogsChange = (e: CheckboxChangeEvent) => {
    const currentSelectedCatalogs = [...selectedMkCatalogs]
    if (e.checked) currentSelectedCatalogs.push(e.value)
    else currentSelectedCatalogs.splice(currentSelectedCatalogs.indexOf(e.value), 1)

    setSelectedMkCatalogs(currentSelectedCatalogs)
    handleChangeFilters("mkCatalogs", currentSelectedCatalogs)
  }

  const onSelectedMedsClassificationCodesChange = (e: CheckboxChangeEvent) => {
    const currentSelectedMedsClassificationCodes = [...selectedMedsClassificationCodes]
    if (e.checked) currentSelectedMedsClassificationCodes.push(e.value)
    else currentSelectedMedsClassificationCodes.splice(currentSelectedMedsClassificationCodes.indexOf(e.value), 1)

    setSelectedMedsClassificationCodes(currentSelectedMedsClassificationCodes)
    handleChangeFilters("medsClassificationCodes", currentSelectedMedsClassificationCodes)
  }
  return (
    <>
      <div className="pt-6 pb-6 flex flex-col gap-3">
        <label className="text-sm text-gray-400">Medication Catalogs</label>
        {filters?.mkCatalogs &&
          catalogOptions.map((item) => (
            <div key={item.id} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedMkCatalogsChange(e)
                }}
                checked={selectedMkCatalogs?.includes(item.id!)}
                value={item.id}
              />
              <label className="p-checkbox-label">{item.title}</label>
            </div>
          ))}
      </div>
      {filters.medsClassificationCodes && (
        <div className="pt-6 pb-6 flex flex-col gap-3">
          <label className="text-sm text-gray-400">Medication Classifications</label>
          {medicineClassificationOptions?.map((item) => (
            <div key={item.code} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedMedsClassificationCodesChange(e)
                }}
                checked={selectedMedsClassificationCodes?.includes(item.code!)}
                value={item.code}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

type Props = {
  filters: MedicationsAdvanceFilter & { searchText?: string }
  catalogOptions: Composition[]
  medicineClassificationOptions?: Coding[]
  onFilter: (filters: MedicationsAdvanceFilter) => void
}

export { MedicationsFilters }
