import { useQuery } from "@tanstack/react-query"
import {
  type Composition,
  type Encounter,
  asReference,
  getResource,
  type HealthcareService,
  isHealthcareService,
} from "fhir"

import { useClient } from "api"

import { encounterQueryKeys } from "../query-keys"

const useOpenEncounter = (patientId: string) => {
  const { search } = useClient()
  const queryKey = encounterQueryKeys.details(patientId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress",
        _count: "1",
        _include: "actor:HealthcareService",
        _revinclude: "Composition:encounter:Encounter",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Encounter`, filters, signal })

      const encounter = getResource<Encounter>(bundle, "Encounter")
      const type = getResource<HealthcareService>(bundle, "HealthcareService")
      const assessment = getResource<Composition>(bundle, "Composition")

      return encounter
        ? {
            encounter: {
              ...encounter,
              participant: [
                ...(type?.id ? [{ r5_actor: asReference(type) }] : []),
                ...(encounter.participant?.filter((part) => !isHealthcareService(part.r5_actor)) ?? []),
              ],
            },
            assessment,
          }
        : null
    },
    meta: { context: { queryKey, patientId } },
  })

  return {
    openEncounter: data?.encounter,
    openEncounterAssessment: data?.assessment,
    openEncounterRef: data?.encounter ? asReference(data.encounter) : undefined,
    isLoading,
    reloadOpenEncounter: refetch,
  }
}

export { useOpenEncounter }
