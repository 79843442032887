import type { Identifier, Reference } from "fhir"
import { useMemo } from "react"

import { IDENTIFIER_CODE } from "data"

import type { LaboratoryFacility, PractitionerInfo } from "../../types"

const useAvailableLabs = (
  practitionersInfo: PractitionerInfo[],
  performerLabsEnabled?: LaboratoryFacility[],
  requesterPR?: Reference,
  organizationAnIdentifier?: Identifier,
) => {
  const requesterAccountNumbers = useMemo(
    () =>
      practitionersInfo
        .filter(({ practitionerRoleRef }) => practitionerRoleRef?.id === requesterPR?.id)
        ?.reduce((acc, info) => {
          const an =
            info.practitioner.identifier?.filter(({ type }) =>
              type?.coding?.some(({ code }) => code === IDENTIFIER_CODE.AN),
            ) ?? []
          return [...acc, ...an]
        }, Array<Identifier>()),
    [requesterPR, practitionersInfo],
  )

  const availableLabs = useMemo(
    () =>
      performerLabsEnabled?.filter(
        ({ ref: { id }, isRequiredANByOrg, isRequiredAN }) =>
          !isRequiredAN ||
          (isRequiredANByOrg && organizationAnIdentifier?.assigner?.id === id) ||
          !requesterPR?.id ||
          requesterAccountNumbers.flatMap(({ assigner }) => assigner?.id).includes(id),
      ),
    [performerLabsEnabled, requesterAccountNumbers],
  )

  const laboratoryRefs = useMemo(() => availableLabs?.map(({ ref }) => ref), [availableLabs])

  return { availableLabs, laboratoryRefs, requesterAccountNumbers }
}

export { useAvailableLabs }
