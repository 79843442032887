import { type FC, useState } from "react"
import { codeableConceptAsString } from "fhir"

import { CheckMultipleWithSlideoverFilter, ModulesId, labTestModelBuilder } from "commons"
import type { LaboratoryTest } from "commons/types"
import { type LaboratoryOrderCombo, type PanelInfo, useLaboratoryTests } from "commons/labs"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { useAppModuleContext } from "internals"

const LaboratoryOrderPanelSelection: FC<Props> = ({
  billingType,
  includedPanels,
  laboratoryId,
  combo,
  onSave,
  isPanelDisabled,
  onHide,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>()
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const { appModules } = useAppModuleContext()

  const isInsuranceLab = billingType === BILLING_TYPES_CODES.INSURANCE

  const { labTests, isLoading, hasNextPage, fetchNextPage } = useLaboratoryTests({
    organizationId: currentOrganizationId,
    catalogAuthorId: laboratoryId,
    searchText: searchFilter,
    billingType: billingType,
    withPrice: !isInsuranceLab && !isExemptLabPayment,
  })

  const inCombo = (url?: string, version?: string) =>
    combo?.panels.some((p) => p.profile.instantiatesCanonical?.[0] === `${url}|${version}`) ?? false

  const getCannonical = ({ planDefinition, profile }: PanelInfo) =>
    planDefinition ? `${planDefinition.url}|${planDefinition.version}` : (profile?.instantiatesCanonical?.[0] as string)

  return (
    <CheckMultipleWithSlideoverFilter
      isVisible={true}
      onHide={onHide}
      filterProps={{
        autoFocus: true,
        isLoading: isLoading,
        onTextFilter: setSearchFilter,
        placeholder: "Please type to search",
      }}
      icon={appModules[ModulesId.LABSORDER].getIcon()}
      initialCheckedValues={includedPanels}
      itemModel={(item) =>
        labTestModelBuilder(
          item,
          inCombo(item.planDefinition?.url, item.planDefinition?.version)
            ? codeableConceptAsString(combo?.laboratoryCombo.code)
            : undefined,
          !isExemptLabPayment && !isInsuranceLab,
        )
      }
      queryProps={{ data: labTests, isFetching: isLoading, hasNextPage, fetchNextPage }}
      onSave={onSave}
      getItemId={getCannonical}
      getItemText={(item) => item.display ?? codeableConceptAsString(item.profile?.code)}
      evaluateExtraCheckedCondition={(item, deletedItems) => {
        const cannonical = getCannonical(item).split("|")
        return (
          inCombo(cannonical[0], cannonical[1]) &&
          !deletedItems.some(({ id }) => id === combo?.laboratoryCombo.instantiatesCanonical?.[0])
        )
      }}
      evaluateIsDisabled={(item, deletedItems) => {
        const cannonical = getCannonical(item).split("|")
        const id = item.planDefinition?.id ?? item.profile?.code?.coding?.[0]?.code
        return (
          (inCombo(cannonical[0], cannonical[1]) &&
            !deletedItems.some(({ id }) => id === combo?.laboratoryCombo.instantiatesCanonical?.[0])) ||
          (isPanelDisabled ? isPanelDisabled(id as string) : false)
        )
      }}
      title="Search Additional Tests"
    />
  )
}

type Props = {
  laboratoryId: string
  onSave(newCheckedItems: LaboratoryTest[], deletedItems: { id: string; index: number }[]): void
  includedPanels?: PanelInfo[]
  billingType: BILLING_TYPES_CODES
  combo?: LaboratoryOrderCombo
  isPanelDisabled?(pId: string): boolean
  onHide(): void
}

export { LaboratoryOrderPanelSelection }
