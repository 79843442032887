import { useSuspenseQuery } from "@tanstack/react-query"
import {
  type Bundle,
  type Parameters,
  type Setting,
  type SettingDefinition,
  type SettingItemArray,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { organizationSettingId } from "data"

import { settingsQueryKeys } from "../../query-keys"

const useModulesVisibility = (organizationId: string) => {
  const { operationRequest } = useClient()
  const queryKey = settingsQueryKeys.modulesVisibility(organizationId)

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const parameters = {
        resourceType: "Parameters",
        parameter: [
          { name: "code", value: { code: organizationSettingId.ehrModules } },
          { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        ],
      } as Parameters

      const bundle = await operationRequest<Bundle>({
        endpoint: "Setting",
        method: "POST",
        operation: "resolve",
        parameters,
      })
      const setting = getResources<Setting>(bundle, "Setting")?.[0]
      const definition = getResources<SettingDefinition>(bundle, "SettingDefinition")[0]

      return { setting: setting as Setting | undefined, definition }
    },
    meta: { context: { queryKey, organizationId } },
  })

  const { modulesSettings } = useMemo(() => {
    let modulesSettings: Record<string, SettingItemArray>

    if (data?.setting?.item) {
      const modulesSettingDefinitions = (data?.definition?.item ?? []).reduce(
        (prev, item) => [
          ...prev,
          {
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
          },
        ],
        new Array<SettingItemArray>(),
      )

      const overrideModulesSettings = data.setting.item.reduce(
        (prev, item) => {
          return { ...prev, [item.code?.code as string]: item }
        },
        {} as Record<string, SettingItemArray>,
      )

      modulesSettings = modulesSettingDefinitions.reduce(
        (prev, item) => {
          const setting = overrideModulesSettings[item.code?.code as string]
          if (!setting) return { ...prev, [item.code?.code as string]: item }
          else return { ...prev, [item.code?.code as string]: setting }
        },
        {} as Record<string, SettingItemArray>,
      )
    } else {
      modulesSettings = (data?.definition?.item ?? []).reduce(
        (prev, item) => ({
          ...prev,
          [item.code?.code as string]: {
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
          },
        }),
        {},
      )
    }

    return { modulesSettings }
  }, [data])

  return {
    modulesSettings,
  }
}

export { useModulesVisibility }
