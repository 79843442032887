import { useQuery } from "@tanstack/react-query"
import { type CarePlan, type MedicationStatement, getResource, getResources } from "fhir"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useCPManualMedStatements = (patientId: string, planId: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.manualMedStatements(patientId, planId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _include: "outcome-reference:MedicationStatement",
        _elements: "id",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const carePlan = getResource<CarePlan>(bundle, "CarePlan")
      const medications = getResources<MedicationStatement>(bundle, "MedicationStatement")

      return {
        carePlan,
        medications,
      }
    },
    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  return {
    isLoading,
    carePlan: data?.carePlan,
    medications: data?.medications,
  }
}

export { useCPManualMedStatements }
