import type { Composition } from "fhir"
import { SelectButton } from "primereact/selectbutton"
import { type FC, useEffect, useMemo, useReducer, useState } from "react"

import { useOrganizationContext } from "organization"

import { SectionContainer } from "administration/utils/SectionContainer"
import { medicineClassificationCodings } from "../../data"
import { type MedicationsAdvanceFilter, MEDICATIONS_SECTION_TYPE } from "../../types"
import { MedicationsFilters } from "./MedicationsFilters"
import { MedicationsNutraceuticals } from "./MedicationsNutraceuticals"
import { MedicationsRxs } from "./MedicationsRxs"

const MedicationsContainer: FC<Props> = ({ isNutrasActive, isRxActive }) => {
  const allowedSections = [
    ...(isRxActive ? [{ name: "Pharmaceuticals", value: MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION }] : []),
    ...(isNutrasActive
      ? [{ name: "Nutraceuticals", value: MEDICATIONS_SECTION_TYPE.MEDICATIONS_NUTRACEUTICALS_SECTION }]
      : []),
  ]
  const [activeTab, setActiveSection] = useState(allowedSections[0]?.value ?? allowedSections[1]?.value)
  const { nutraCatalogs, rxCatalogs } = useOrganizationContext()

  const { catalogs, catalogIds, catalogsById } = useMemo(() => {
    const catalogs = activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? rxCatalogs : nutraCatalogs
    const catalogsById = catalogs.reduce<Record<string, Composition>>(
      (acc, catalog) => ({ ...acc, [catalog.id!]: catalog }),
      {},
    )
    const catalogIds = Object.keys(catalogsById)
    return { catalogs, catalogIds, catalogsById }
  }, [activeTab])

  /* Force set initial catalogs according with the ones available for org */
  useEffect(() => {
    if (catalogIds.length) filter({ mkCatalogs: catalogIds })
  }, [catalogIds])

  const medicineClassificationByCode = medicineClassificationCodings.flatMap((coding) => coding.code!)

  const { mkCatalogs, medsClassificationCodes, searchText, search, filter, reset } = useReducerState(
    catalogIds,
    medicineClassificationByCode,
  )

  return (
    <SectionContainer
      currentFilters={
        activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION
          ? { mkCatalogs, searchText, medsClassificationCodes }
          : { mkCatalogs, searchText }
      }
      onReset={reset}
      onSearch={search}
      searchText={searchText}
      placeholder={`Search ${activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? "pharmaceuticals" : "nutraceuticals"}`}
      filters={
        <MedicationsFilters
          filters={
            activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION
              ? { mkCatalogs, searchText, medsClassificationCodes }
              : { mkCatalogs, searchText }
          }
          onFilter={filter}
          catalogOptions={catalogs}
          medicineClassificationOptions={medicineClassificationCodings}
        />
      }
    >
      <SelectButton
        value={activeTab}
        options={allowedSections}
        optionValue="value"
        optionLabel="name"
        onChange={(e) => setActiveSection(e.value)}
        className="py-1.5 px-1.5 mb-3"
        allowEmpty={false}
      />

      <div className="flex flex-1 flex-col overflow-y-auto">
        {activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? (
          <MedicationsRxs
            filters={{ mkCatalogs, searchText, medsClassificationCodes }}
            filter={filter}
            catalogsById={catalogsById}
          />
        ) : (
          <MedicationsNutraceuticals filters={{ mkCatalogs, searchText }} filter={filter} catalogsById={catalogsById} />
        )}
      </div>
    </SectionContainer>
  )
}

const getInitialState = (catalogs?: string[] | undefined, medicineClassificationCodes?: string[]) => {
  return {
    searchText: "",
    mkCatalogs: catalogs,
    medsClassificationCodes: medicineClassificationCodes,
  } as State
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "search" | "filter"
    payload?: string | MedicationsAdvanceFilter
  },
) => {
  switch (type) {
    case "reset":
      return { ...state }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return {
        ...state,
        mkCatalogs: (payload as MedicationsAdvanceFilter)?.mkCatalogs,
        medsClassificationCodes: (payload as MedicationsAdvanceFilter)?.medsClassificationCodes,
      }
    default:
      return state
  }
}

const useReducerState = (catalogs?: string[], medicineClassificationCodes?: string[]) => {
  const state = getInitialState(catalogs, medicineClassificationCodes)
  const [{ searchText, mkCatalogs, medsClassificationCodes }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: MedicationsAdvanceFilter) => {
    dispatch({
      type: "filter",
      payload: {
        ...filters,
        mkCatalogs: filters.mkCatalogs?.length ? filters.mkCatalogs : catalogs,
        medsClassificationCodes: filters.medsClassificationCodes?.length
          ? filters.medsClassificationCodes
          : medicineClassificationCodes,
      },
    })
  }

  return {
    searchText,
    mkCatalogs,
    medsClassificationCodes,
    filter,
    search,
    reset,
  }
}

type State = {
  searchText?: string
} & MedicationsAdvanceFilter

type Props = {
  isNutrasActive: boolean
  isRxActive: boolean
}

export { MedicationsContainer }
