import { useQuery } from "@tanstack/react-query"
import { type Organization, asReference, getResources } from "fhir"
import { useEffect } from "react"

import { useClient } from "api"
import type { LaboratoryFacility } from "commons/types"
import { AN_NOT_REQUIRED_CODE, AN_PRACTICE_REQUIREMENTS_CODE, PRACTITIONER_AN_NON_REQUIRED_CODE } from "data"

import { organizationQueryKeys } from "../query-keys"

const useDiagnosticLaboratories = (
  filter?: string,
  onSettled?: (data?: LaboratoryFacility[], error?: Error | null) => void,
) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.diagnosticLabs(filter)

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _content: filter.includes(" ") ? `"${filter}"` : filter } : {}),
        _count: "100",
        _sort: "name",
        _page: "1",
        type: "facility-provider",
        active: "true",
      })

      const bundle = await search({ endpoint: "Organization", filters, signal })

      const diagnosticLabs = getResources(bundle) as Organization[]

      return diagnosticLabs.reduce<LaboratoryFacility[]>((prev, lab) => {
        const initialValues = { isRequiredANByOrg: false, isRequiredAN: true, skippedAnCheck: false }

        const { isRequiredANByOrg, isRequiredAN } =
          lab.type?.reduce((prev, { coding }) => {
            if (coding?.[0].code === AN_NOT_REQUIRED_CODE) {
              return { ...prev, skippedAnCheck: true, isRequiredAN: false, isRequiredANByOrg: false }
            }
            if (!prev.skippedAnCheck) {
              if (coding?.[0].code === AN_PRACTICE_REQUIREMENTS_CODE) {
                return { ...prev, isRequiredANByOrg: true }
              }

              if (coding?.[0].code === PRACTITIONER_AN_NON_REQUIRED_CODE) {
                return { ...prev, isRequiredAN: false }
              }
            }

            return prev
          }, initialValues) ?? initialValues

        return [...prev, { lab, ref: asReference(lab), isRequiredANByOrg, isRequiredAN }]
      }, [])
    },
    meta: { context: { queryKey, filter } },
  })

  useEffect(() => {
    onSettled?.(data, error)
  }, [data, error])

  return { diagnosticLabs: data, isLoading, isError }
}

export { useDiagnosticLaboratories }
