import type { Coding, DocumentReference } from "fhir"
import { useFormikContext } from "formik"
import type { FC } from "react"

import { DropdownField, FileUploaderField } from "commons"
import { MAX_FILE_SIZE } from "data"

import { patientDocumentsCategoryOptions } from "../data"

const PatientDocumentForm: FC = () => {
  const {
    isSubmitting,
    values: { category },
  } = useFormikContext<DocumentReference & { attachment?: File }>()

  return (
    <>
      <DropdownField
        label="Category"
        field="category[0].coding[0]"
        options={patientDocumentsCategoryOptions}
        optionLabel="label"
        optionValue="value"
        disabled={isSubmitting}
        validation={(val: Coding) => (val?.code ? undefined : "Category is required")}
      />

      <FileUploaderField
        field="attachment"
        autoupload={false}
        label="Document"
        maxFileSize={MAX_FILE_SIZE}
        className="button-primary"
        disabled={isSubmitting || !category?.[0]?.coding?.[0]?.code}
        validation={(val) => (val ? undefined : "Document is required")}
      />
    </>
  )
}

export { PatientDocumentForm }
