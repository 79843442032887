import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Patient } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { patientsQueryKeys } from "../query-keys"

const usePatchPatient = (onSettled?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatePatient = ({
    patientId,
    patientData,
  }: {
    patientId: string
    patientData: Partial<Patient>
    updatedAddressIndex?: number
  }) => patch("Patient", patientId, patientData)

  const {
    mutate: patchPatient,
    isPending: isPatching,
    context,
  } = useMutation({
    mutationFn: updatePatient,
    onMutate: async ({ patientId, patientData, updatedAddressIndex }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: patientsQueryKeys.details(patientId), fetchStatus: "fetching" })

      // Snapshot the previous value
      const oldPatient = queryClient.getQueryData(patientsQueryKeys.details(patientId)) as Patient

      // Optimistically update to the new value
      queryClient.setQueryData(patientsQueryKeys.details(patientId), (old: Patient) => ({ ...old, ...patientData }))

      // Return a context with the previous and new
      return { oldPatient, updatedAddressIndex }
    },
    onError: async (error: CustomError, _, context: PatchPatientContext) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause?.name)) {
        await queryClient.invalidateQueries({ queryKey: patientsQueryKeys.details(context?.oldPatient.id) })
      } else if (context?.oldPatient) {
        queryClient.setQueryData(patientsQueryKeys.details(context.oldPatient.id), context.oldPatient)
      }
      displayNotificationError(registerErrorTrace(error))
    },
    onSuccess: () => {
      displayNotificationSuccess("Patient updated successfully!")
      // datadogLogs.logger.info(`Patient ${patientId} updated successfully!`, { patientData })
    },
    onSettled: async (patient, _, variables) => {
      queryClient.refetchQueries({
        queryKey: patientsQueryKeys.details(patient?.id ?? variables.patientId),
        type: "all",
      })
      onSettled?.()
    },
  })

  return { patchPatient, isPatching, context }
}

export type PatchPatientContext = { oldPatient: Patient; updatedAddressIndex?: number } | undefined

export { usePatchPatient }
