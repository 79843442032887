import { useMemo } from "react"
import { type Patient, humanNameAsString } from "fhir"

const usePatientContacts = (patient: Patient | undefined) => {
  const contactsResume = useMemo<string | undefined>(() => {
    let contactString
    const CONTACTS_TO_SHOW = 3

    if (patient?.contact && patient.contact.length > 0) {
      const contacts = patient?.contact.slice(0, CONTACTS_TO_SHOW).map((contact) => humanNameAsString(contact.name))
      contactString = contacts.join(", ")
    }

    return contactString
  }, [patient])

  return { contactsResume }
}

export { usePatientContacts }
