import { faChartColumn, faListTimeline } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers } from "formik"
import { useState } from "react"

import { Button, FormContainer, InfiniteScroll, LoadingView } from "commons"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useCreateVitals, useVitals } from "../hooks"
import type { VitalsFormType } from "../types"
import { getVitals, initialValues, validationSchema } from "./validations"
import { VitalsForm } from "./VitalsForm"

const VitalsContainer = () => {
  const { patientId, patientRef } = usePatientContext()
  const [isChartView, setIsChartView] = useState(false)
  const { vitals, isLoading: isLoadingData, hasNextPage, totalData, fetchNextPage } = useVitals(patientId)
  const { loggedInPractitionerRoleRef } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { createVitals, isCreating } = useCreateVitals()

  const onSubmit = (values: VitalsFormType, formikHelpers?: FormikHelpers<VitalsFormType>) => {
    createVitals(getVitals(values, patientRef, loggedInPractitionerRoleRef, openEncounterRef))
    formikHelpers?.resetForm()
  }

  if (isLoadingData) return <LoadingView />

  return (
    <div className="flex flex-col flex-1 overflow-hidden w-full">
      <div title={`Show ${isChartView ? "table" : "chart"} view`} className="flex justify-end gap-3 w-full p-2">
        <Button
          icon={isChartView ? faListTimeline : faChartColumn}
          disabled={isLoadingData || !totalData}
          onClick={() => setIsChartView((prev) => !prev)}
          buttonStyle="default"
          label={`Show ${isChartView ? "table" : "chart"} view`}
        />
      </div>
      <div className="h-96 grow">
        <FormContainer
          title=""
          subTitle=""
          initialValue={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          customSaveButton={<Button label="Save" buttonStyle="primary" size="lg" type="submit" loading={isCreating} />}
          enableReinitialize
          innerContainerClassName="pb-6 overflow-auto grow ml-3"
          showCancel={false}
        >
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useScrollHorizontal={true}>
            <VitalsForm vitals={vitals} isTableEmpty={!totalData} showChart={isChartView} />
          </InfiniteScroll>
        </FormContainer>
      </div>
    </div>
  )
}

export { VitalsContainer }
