import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"

import { LaboratoryOrderList } from "./LaboratoryOrderList"

const LaboratoryOrderContainer = () => {
  const [searchParams] = useSearchParams()
  const { activeSubModule, setActiveSubModule } = useChartContext()
  const { appSubModules } = useAppModuleContext()

  useEffect(() => {
    if (searchParams.get("order")) {
      setActiveSubModule(appSubModules["laboratory"][ModulesId.LABORATORY_ORDER_DETAILS])
    }
  }, [appSubModules, searchParams, setActiveSubModule])

  return !activeSubModule ? <LaboratoryOrderList /> : activeSubModule.renderContent()
}

export { LaboratoryOrderContainer }
