import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { type FC, type PropsWithChildren, type ReactNode, Fragment, useState } from "react"

const GroupedList = <T,>({ className, groups, renderItem, renderDivider, renderEmptyState, collapsible }: Props<T>) => {
  const [collapsed, setCollapsed] = useState<Record<string, boolean>>({})

  if (!groups.length) {
    return (
      renderEmptyState?.() ?? (
        <div className="px-4 py-2">
          <p>No items found</p>
        </div>
      )
    )
  }

  const handleClick = (key: string) => {
    if (!collapsible) return
    setCollapsed((collapsed) => ({ ...collapsed, [key]: !collapsed[key] }))
  }

  return (
    <nav className="overflow-x-hidden flex-1">
      {groups.map(({ key, name, items, ...extraProps }) => (
        <div key={key} className={classNames("relative z-0", className)}>
          <DividerWithExpandIcon
            name={name}
            collapsible={collapsible}
            collapsed={collapsed[key]}
            onClick={() => handleClick(key)}
          >
            {renderDivider?.(name, extraProps)}
          </DividerWithExpandIcon>
          <div
            className={classNames("grid transition-[grid-template-rows] duration-300 ease-in-out", {
              "grid-rows-[0fr]": collapsed[key],
              "grid-rows-[1fr]": !collapsed[key],
            })}
          >
            <ul className="@container relative z-0 divide-y divide-gray-200 overflow-hidden">
              {items.map((item, index) => (
                <Fragment key={index}>{renderItem(item, extraProps)}</Fragment>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </nav>
  )
}

const DividerWithExpandIcon: FC<
  PropsWithChildren & { name: string; collapsible?: boolean; collapsed: boolean; onClick: () => void }
> = ({ collapsible, collapsed, children, name, onClick }) => (
  <div className={classNames("relative", { "cursor-pointer": collapsible })} role="button" onClick={onClick}>
    {children ?? <DefaultDivider name={name} />}
    {collapsible && (
      <FontAwesomeIcon
        icon={faChevronDown}
        size="sm"
        className={classNames("absolute right-0 top-1/2 -translate-y-1/2 p-4 z-10 text-gray-600 transition-all", {
          "rotate-180": collapsed,
          "bg-gray-50": !children,
          "bg-white": !!children,
        })}
      />
    )}
  </div>
)

const DefaultDivider = ({ name }: Pick<ListGroup, "name">) => (
  <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
    <h3>{name}</h3>
  </div>
)

type Props<T = unknown> = {
  className?: string
  groups: ListGroup<T>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderItem(item: T, extraProps?: { [key: string]: any }): ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderDivider?(name: string, extraProps?: { [key: string]: any }): ReactNode
  renderEmptyState?(): ReactNode
  collapsible?: boolean
}

export type ListGroup<T = unknown> = {
  key: string
  name: string
  items: T[]
}

export { GroupedList }
