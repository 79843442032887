import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { ServiceRequest } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons/types"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"

const useUpdateLabOrder = (onSettled?: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updateFn = async (laboratoryOrder: ServiceRequest) =>
    update("ServiceRequest", laboratoryOrder.id as string, laboratoryOrder)

  const { mutate, isPending } = useMutation({
    mutationFn: updateFn,
    onError: async (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled: async (_data, _error, { subject }) => {
      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.invoicePreview(subject.id as string),
      })
      onSettled?.()
    },
  })

  return { updateLabOrder: mutate, isUpdating: isPending }
}

export { useUpdateLabOrder }
