import { faCalendarDays } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { CardListItem } from "../../components/CardListItem"
import type { TrackingData } from "../types"

const TrackingCodeItem: FC<Props> = ({ trackingCode, className }) => {
  const head = (
    <>
      <div className="font-bold" title="Trackin code number">
        {`#${trackingCode.identifier}`}
      </div>
      <span title="Status">
        <Chip
          label={trackingCode.status.replace(/-/g, " ")}
          className={classNames("ml-2 custom-chip capitalize", {
            "is-danger": ["stopped", "on-hold", "cancelled"].includes(trackingCode.status),
            "is-warning": trackingCode.status === "entered-in-error",
            "is-success": trackingCode.status === "completed",
            "is-info": trackingCode.status === "in-progress",
          })}
        />
      </span>
    </>
  )

  const details = (
    <div className="inline-flex items-center divide-x divide-gray-400 text-xs">
      {trackingCode.datePrepared && (
        <>
          <span title="Given out date">
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
            {formatDate(new Date(trackingCode.datePrepared), formatsByTypes.LONG_DATE)}
          </span>
        </>
      )}
      {trackingCode.dateShipped && (
        <span title="Shipped on" className="ml-2 pl-2">
          <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
          {formatDate(new Date(trackingCode.dateShipped), formatsByTypes.LONG_DATE)}
        </span>
      )}
      <span title="Medications" className="ml-2 pl-2">
        {trackingCode.medications.join(", ")}
      </span>
    </div>
  )

  const googlingCode = () => {
    window.open(`https://www.google.com/search?q=${trackingCode.identifier}`, "_blank")
  }

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      className={classNames("cursor-pointer", className)}
      onContentCliked={googlingCode}
      lastElementClass="last:mb-0 last:border-b-0 border-b"
    />
  )
}

type Props = {
  trackingCode: TrackingData
  className?: string
}

export { TrackingCodeItem }
