import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { allergyModelBuilder } from "allergies"
import { StackedListContainer } from "commons"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"

const AllergiesSection = ({ allergies, total, icon, hideSpacer }: Props) => (
  <WrapUpSection
    subSectionTitle="Allergies"
    isEmpty={!total}
    emptyMesage="No allergies added during this encounter"
    icon={icon}
    className="flex flex-row gap-x-16 w-full text-sm"
    useSpacer={!hideSpacer}
  >
    <StackedListContainer
      data={allergies ?? []}
      itemModelBuilder={(item) => ({ ...allergyModelBuilder(item), badge: undefined })}
      className="w-full"
      itemsClassName="!pl-0 py-4"
    />
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "allergies">

export { AllergiesSection }
