import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import type { FormikValues } from "formik"
import { classNames } from "primereact/utils"
import { useId, useMemo, useState } from "react"

import { InfiniteScroll } from "../infinitescroll/InfiniteScroll.cjs"
import { EmptyMessage } from "./EmptyMessage"
import { SkeletonLoader } from "./SkeletonLoader"
import type { StackedListItemProps } from "./StackedListItem"
import { StackedListItemCheckeable } from "./StackedListItemCheckeable"

const StackedTimeLineCheckList = <T extends FormikValues>({
  isLoading,
  queryProps: { data, isFetching, hasNextPage, fetchNextPage },
  initialCheckedValues,
  initialCheckedIds,
  uncheckWhenDisabled,
  icon = faSearch,
  itemTitle = "data",
  getItemId,
  itemModel,
  onItemChecked,
  evaluateExtraCheckedCondition,
  evaluateIsDisabled,
}: Props<T>) => {
  const loaderKey = useId()
  const [unchecked, setUnchecked] = useState<{ id: string; index: number }[]>([])
  const [newCheckedItems, setNewCheckedItems] = useState<T[]>([])

  const initialCheckedItems = useMemo(
    () =>
      initialCheckedValues ?? data?.filter((item) => initialCheckedIds?.includes(getItemId?.(item) ?? item.id)) ?? [],
    [initialCheckedIds, initialCheckedValues, data, getItemId],
  )

  const checkedItems = useMemo(
    () =>
      [...initialCheckedItems, ...newCheckedItems].filter(
        (item) => !unchecked.some((uncheckedItem) => (getItemId?.(item) ?? item.id) === uncheckedItem.id),
      ),
    [newCheckedItems, initialCheckedItems, unchecked, getItemId],
  )

  const onCheckTriggered = (item: T, isChecked: boolean) => {
    const id: string = getItemId?.(item) ?? item.id
    const itemIndex = initialCheckedItems.findIndex((i) => (getItemId?.(i) ?? i.id) === id)

    if (isChecked) {
      if (itemIndex === -1) setNewCheckedItems([...newCheckedItems, item])

      // Remove item from items billToPatientCIDs to delete
      setUnchecked(unchecked.filter((i) => i.id !== id))
    } else {
      setNewCheckedItems((items) => items.filter((i) => (getItemId?.(i) ?? i.id) !== id))

      // Update checked and unchecked items
      setUnchecked([...unchecked, { id, index: itemIndex }])
    }
    onItemChecked?.(item, isChecked)
  }

  const loader = <SkeletonLoader repeats={4} loaderType="two-lines" containerClassName="px-5" key={loaderKey} />
  return isLoading ? (
    loader
  ) : !data?.length ? (
    <EmptyMessage icon={icon} itemTitle={itemTitle} subMessage={false} />
  ) : (
    <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage?.()} loader={loader}>
      <>
        {data.map((item, index) => {
          const id: string = getItemId?.(item) ?? item.id
          const isChecked = checkedItems.some((item) => (getItemId?.(item) ?? item.id) === id)
          const isDisabled = evaluateIsDisabled?.(item, index, unchecked) ?? false

          if (uncheckWhenDisabled && isChecked && isDisabled) onCheckTriggered(item, false)

          return (
            <div key={id} className="flex flex-col flex-1 gap-y-0.5">
              <div
                key={index}
                className={classNames(
                  "border-l-2 h-4 ml-1.5 3xl:ml-[0.450rem]",
                  isChecked ? (isDisabled ? "border-primary-hover/50" : "border-primary-hover") : "border-gray-300",
                )}
              />
              <StackedListItemCheckeable
                checked={isChecked || (evaluateExtraCheckedCondition?.(item, unchecked) ?? false)}
                modelData={itemModel(item)}
                onCheck={(checked) => onCheckTriggered(item, checked)}
                disabled={isFetching || isDisabled}
                modeAuto={false}
                shadowed={!isChecked}
                itemPadding="py-0.5"
              />
            </div>
          )
        })}
      </>
    </InfiniteScroll>
  )
}

type Props<T> = {
  queryProps: {
    data?: Array<T>
    isFetching: boolean
    hasNextPage?: boolean
    fetchNextPage?(): void
  }
  initialCheckedIds?: string[]
  initialCheckedValues?: Array<T>
  onItemChecked?(item: T, checked: boolean): void
  itemModel(item: T): StackedListItemProps
  getItemId?(item: T): string
  evaluateExtraCheckedCondition?(item: T, uncheckedItems: { id: string; index: number }[]): boolean
  evaluateIsDisabled?(item: T, index: number, uncheckedItems: { id: string; index: number }[]): boolean
  isLoading?: boolean
  icon?: IconDefinition
  itemTitle?: string
  uncheckWhenDisabled?: boolean
}

export { StackedTimeLineCheckList }
