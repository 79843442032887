import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { Fragment, type FC } from "react"
import { isCarePlan } from "fhir"

import { ModulesId, StackedListItem } from "commons"
import { proceduresQueryKeys, proceduresItemModel } from "procedures"
import { useChartContext } from "chart-view"
import { ProcedureNote } from "commons/procedures"

import { WrapUpSection } from "./WrapUpSection"
import { useEncounterContext } from "../../hooks"

const ProceduresSection: FC<Props> = ({ patientId, encounter, icon }) => {
  const { showModule } = useChartContext()

  const goToDetails = (procedureId: string) => {
    showModule({ module: ModulesId.PROCEDURES, moduleParams: { procedureId } })
  }

  const { encounterData, totalProcedures: total, planRefs } = useEncounterContext()

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No procedures activated during this encounter"
      className="flex flex-row gap-x-16 pb-2"
      useSpacer
      sectionTitle="Procedures"
    >
      <div className="w-full divide-y">
        {encounterData?.procedures?.map((procedureData, index) => {
          const itemNotes = procedureData.procedure.note?.filter(({ text }) => !!text)
          const cp = planRefs?.[procedureData.procedure.basedOn?.find(isCarePlan)?.id as string]
          return (
            <Fragment key={procedureData.procedure.id ?? index}>
              <StackedListItem
                className="!pl-0 py-4"
                modelData={{
                  ...proceduresItemModel(procedureData, () => goToDetails(procedureData.procedure.id as string)),
                  ...(cp
                    ? {
                        badge: {
                          text: cp.display ?? "Plan based",
                          colorStyle: "gray",
                          title: cp.display,
                          className: "truncate max-w-24 !block",
                        },
                      }
                    : { badge: undefined }),
                }}
              />
              {!!itemNotes?.length && !!procedureData.procedure.id && (
                <div className="flex flex-col text-sm mb-2 relative !border-t-0">
                  <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
                    Notes
                    <hr className="flex-1 ml-1" />
                  </span>

                  <ProcedureNote
                    procedureNote={procedureData.procedure.note}
                    procedureId={procedureData.procedure.id}
                    queriesToInvalidate={proceduresQueryKeys.list(patientId, undefined, undefined, encounter)}
                    contentClassName=""
                    inputRows={3}
                    buttonClassName="absolute -top-1 right-0 z-10 bg-white"
                  />
                </div>
              )}
            </Fragment>
          )
        })}
      </div>
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { ProceduresSection }
