import { TabPanel, TabView } from "primereact/tabview"
import type { FC } from "react"

import { SurveyContainer } from "surveys"

const SupportingInfo: FC<Props> = ({ questionnaireInfo }) => {
  if (!questionnaireInfo || !Object.keys(questionnaireInfo)?.length) return null
  return (
    <>
      {Object.keys(questionnaireInfo).length > 1 ? (
        <>
          <h2 className="text-gray-700">Sources</h2>
          <TabView className="mt-3 sticky-header flex flex-col flex-1" panelContainerClassName="h-full pb-0 flex-1">
            {Object.entries(questionnaireInfo).map(([key, { title, questionCodes }]) => (
              <TabPanel key={key} visible header={title} contentClassName="">
                <SurveyContainer qResponseId={key} pickedQuestions={questionCodes} />
              </TabPanel>
            ))}
          </TabView>
        </>
      ) : (
        <>
          {Object.entries(questionnaireInfo).map(([key, { questionCodes }]) => (
            <SurveyContainer key={key} qResponseId={key} pickedQuestions={questionCodes} />
          ))}
        </>
      )}
    </>
  )
}

type Props = {
  questionnaireInfo?: Record<string, { title?: string; questionCodes: string[] }>
}

export { SupportingInfo }
