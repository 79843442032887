import type { FC } from "react"

import { ModulesId } from "commons"
import { useAppModuleContext } from "modules"

import { WrapUpSection } from "./WrapUpSection"
import { VitalsSection } from "./VitalsSection"
import { AllergiesSection } from "./AllergiesSection"
import { ConditionsSection } from "./ConditionsSection"
import { FamilyHistorySection } from "./FamilyHistorySection"
import { SocialHistorySection } from "./SocialHistorySection"
import { IntakeMedicationsSection } from "./IntakeMedicationsSection"
import { IntakeLabSection } from "./IntakeLabSection"
import { useEncounterContext } from "encounter/hooks"

const IntakeWrapUp: FC<Props> = ({ hideSpacer }) => {
  const { appModules, appSubModules } = useAppModuleContext()

  const {
    encounterData,
    totalAllergies,
    totalConditions,
    totalEnteredLabs,
    totalFH,
    totalVitals,
    totalMedStatements,
    totalSocialHistory,
    planRefs,
  } = useEncounterContext()

  const noData =
    !totalAllergies &&
    !totalConditions &&
    !totalEnteredLabs &&
    !totalFH &&
    !totalMedStatements &&
    !totalSocialHistory &&
    !totalVitals

  const intakeData = encounterData?.intake

  return (
    <WrapUpSection sectionTitle="Intake" isEmpty={noData}>
      <VitalsSection
        icon={appSubModules[ModulesId.INTAKE]["vitals"]?.getIcon()}
        hideSpacer={hideSpacer}
        vitals={intakeData?.vitals}
        total={totalVitals}
      />
      <AllergiesSection
        allergies={intakeData?.allergies}
        total={totalAllergies}
        icon={appModules[ModulesId.ALLERGY]?.getIcon()}
        hideSpacer={hideSpacer}
      />
      <ConditionsSection
        conditions={intakeData?.conditions}
        total={totalConditions}
        icon={appModules[ModulesId.CONDITIONS]?.getIcon()}
        hideSpacer={hideSpacer}
      />
      <FamilyHistorySection
        familyHistory={intakeData?.familyHistory}
        total={totalFH}
        icon={appSubModules[ModulesId.INTAKE][ModulesId.FAMILY_HISTORY].getIcon()}
        hideSpacer={hideSpacer}
      />
      <SocialHistorySection
        socialHistory={intakeData?.socialHistory}
        total={totalSocialHistory}
        icon={appSubModules[ModulesId.INTAKE][ModulesId.SOCIAL_HISTORY].getIcon()}
        hideSpacer={hideSpacer}
      />
      <IntakeMedicationsSection
        medications={intakeData?.medications}
        total={totalMedStatements}
        icon={appSubModules[ModulesId.INTAKE][ModulesId.MEDICATIONE].getIcon()}
        hideSpacer={hideSpacer}
        planRefs={planRefs}
      />
      <IntakeLabSection
        labs={intakeData?.labs}
        total={totalEnteredLabs}
        icon={appModules[ModulesId.LABSORDER]?.getIcon()}
        hideSpacer={hideSpacer}
      />
    </WrapUpSection>
  )
}

type Props = {
  hideSpacer?: boolean
}

export default IntakeWrapUp
