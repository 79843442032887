import type { CarePlanActivityArray, Reference } from "fhir"
import { useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { type FC, useMemo } from "react"

import { HEALTH_GORILLA_VALUE_CHECK, MC_ACTIVITY_TYPE } from "data"

import { FormField, PractitionerRoleDropdownField } from "../../../../forms"
import type { PractitionerInfo } from "../../../../types"
import type { PlanData } from "../../../types"
import { validatePlanRequester } from "../../validations"

const Assessment: FC<Props> = ({
  practitionersInfo,
  configs: { requiresLabs, requiresRXs, requiresAlgorithm },
  contentClassName,
}) => {
  const {
    values: { mcActivity, algorithmActivity },
    setFieldValue,
  } = useFormikContext<Partial<PlanData>>()

  /* Handle filter valid activity */
  const algoritmActivities = useMemo(() => {
    return requiresAlgorithm
      ? !requiresLabs
        ? algorithmActivity?.filter(({ detail }) => detail?.kind === MC_ACTIVITY_TYPE.MC_SURVEY)
        : algorithmActivity
      : undefined
  }, [algorithmActivity, requiresAlgorithm])

  const allowedPractitioners = useMemo(
    () =>
      practitionersInfo.filter(({ practitioner: { identifier } }) =>
        requiresLabs ? identifier?.some(({ value }) => value?.includes(HEALTH_GORILLA_VALUE_CHECK)) : true,
      ),
    [practitionersInfo, requiresLabs, requiresRXs],
  )

  const isQuestionnareActivity = useMemo(
    () => mcActivity?.detail?.kind === MC_ACTIVITY_TYPE.MC_SURVEY,
    [mcActivity?.detail?.kind],
  )

  return (
    <>
      {!isQuestionnareActivity && (
        <PractitionerRoleDropdownField
          field="requester"
          label="Practitioner"
          useFilter={false}
          options={allowedPractitioners}
          validate={(req: Reference | undefined) =>
            validatePlanRequester({
              value: req,
              practitionersInfo: allowedPractitioners,
              requiresLabs,
              requiresRX: requiresRXs,
              isRequired: !isQuestionnareActivity,
            })
          }
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
          className={contentClassName}
        />
      )}

      {!!algoritmActivities?.length && (
        <FormField
          field="mcActivity"
          label="Assessment Type"
          validation={(value) =>
            !(value as CarePlanActivityArray | undefined)?.outcomeReference?.[0]
              ? "Select a valid algorithm"
              : undefined
          }
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
          className={contentClassName}
        >
          <Dropdown
            optionLabel="outcomeReference.0.display"
            options={algoritmActivities}
            onChange={(e) => setFieldValue("mcActivity", e.value)}
            value={mcActivity}
            className="p-inputtext-sm"
            dataKey="outcomeReference.0.id"
          />
        </FormField>
      )}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  configs: {
    requiresAlgorithm: boolean
    requiresLabs: boolean
    requiresEmails: boolean
    requiresNutras: boolean
    requiresRXs: boolean
  }
  contentClassName?: string
}

export { Assessment }
