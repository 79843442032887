import { useId } from "react"
import { asReference, type Composition } from "fhir"

import { usePatientContext } from "patients"
import { useOpenEncounter } from "encounter"
import { useLoggedInPractitioner } from "practitioner"
import {
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { AppSubModules } from "app-modules"

import { AssessmentForm } from "./AssessmentForm"
import { assessmentsModelBuilder } from "./assessmentsModelBuilder"
import { assessmentValidationSchema, getAssessmentInitialValues, sanitize } from "./validations"
import { useCreatePatientComposition, usePatientCompositions, useUpdatePatientComposition } from "../hooks"
import { assessmentsQueryKeys } from "../query-keys"

const AssessmentsContainer = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { loggedInPractitioner } = useLoggedInPractitioner()

  const { compositions, fetchNextPage, hasNextPage, isLoading } = usePatientCompositions(patientId)
  const { openEncounterRef, openEncounterAssessment, reloadOpenEncounter } = useOpenEncounter(patientId)

  const { reset, add, edit, showSlide, initialValue, isNew } = useCrudReducer({
    defaultEntity: getAssessmentInitialValues(asReference(loggedInPractitioner), patientRef, openEncounterRef),
  })

  const { createAssessment } = useCreatePatientComposition(
    reset,
    assessmentsQueryKeys.compositions(patientId),
    reloadOpenEncounter,
  )
  const { updateAssessment } = useUpdatePatientComposition(reset, assessmentsQueryKeys.compositions(patientId))

  const onSubmit = (composition: Composition) => {
    isNew ? createAssessment(sanitize(composition)) : updateAssessment(sanitize(composition))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No assessments found"
      hasData={!!compositions?.length}
      showSlide={showSlide}
      formTitle="Assessment"
      formInitialValue={initialValue}
      validationSchema={assessmentValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<AssessmentForm />}
      onButtonAddClick={add}
      iconDataNotFound={AppSubModules["intake"][ModulesId.ASSESSMENTS].getIcon()}
      disabledAddButton={!!openEncounterAssessment}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={compositions ?? []}
            itemModelBuilder={(item) => assessmentsModelBuilder(item, () => edit(item))}
          />
        </InfiniteScroll>
      </div>
    </DataContainerSlideoverForm>
  )
}

export { AssessmentsContainer }
