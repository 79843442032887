import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type Account, type Bundle, type Device, type Parameters, getResource } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization/query-keys"
import { displayNotificationSuccess } from "utils"

import type { CustomError } from "../../types"
import { readerQueryKeys } from "../query-keys"

const useRegisterReader = ({ orgId, onSuccess, onSettled }: RegisterReaderArgs) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()
  /* TODO: Delete default wpe-simulated code  when real values available*/
  const readerSetup = async ({
    readerLabel,
    registrationCode = "simulated-wpe",
  }: {
    readerLabel: string
    registrationCode: string
  }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "label",
          value: {
            string: readerLabel,
          },
        },
        {
          name: "registration-code",
          value: {
            string: registrationCode,
          },
        },
      ],
    }

    const resBundle = await operationRequest<Bundle>({
      endpoint: "Organization",
      method: "POST",
      operation: "register-reader",
      id: orgId,
      parameters,
    })
    const readerAccount = getResource<Account>(resBundle, "Account")
    const readerDevice = getResource<Device>(resBundle, "Device")

    return { readerAccount, readerDevice }
  }

  const {
    mutate: registerReader,
    isPending: isRegisteringReader,
    data,
  } = useMutation({
    mutationFn: readerSetup,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: readerQueryKeys.all(orgId), refetchType: "all" })
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKeys.account.current(orgId),
        refetchType: "all",
      })
      displayNotificationSuccess(`Reader has been registered successfully.`)
      // datadogLogs.logger.info(`Reader has been registered successfully.`, data.readerDevice)
      onSuccess?.(data)
    },
    onError: (error: CustomError, variables) => {
      displayNotificationError(registerErrorTrace(error, { variables }))
    },
    onSettled,
  })

  return { link: data, registerReader, isRegisteringReader }
}

interface RegisterReaderArgs {
  orgId: string
  onSuccess?: (data: { readerAccount: Account; readerDevice: Device }) => void
  onSettled?: () => void
}

export { useRegisterReader }
