import { useInfiniteQuery } from "@tanstack/react-query"
import { type Observation, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { socialHistoryQueryKeys } from "../query-keys"

const useSocialHistory = (patientId: string, encounter?: string) => {
  const { search } = useClient()
  const queryKey = socialHistoryQueryKeys.withPatientId(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    SocialHistoryQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        category: "social-history",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        "status:not": "cancelled",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Observation`, filters, signal })

      const socialHistory = getResources(bundle) as Observation[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { socialHistory, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { socialHistory, count, lastValue } = useMemo(() => {
    let count = 0
    const newData = data?.pages.reduce<{
      socialHistory: Observation[]
      next: number | undefined
      total: number
    }>(
      (prev, current) => {
        const newSocialHistory = prev?.socialHistory.concat(current.socialHistory)
        count += current.socialHistory.length

        return { socialHistory: newSocialHistory, next: current.next, total: current.total }
      },
      { socialHistory: [], next: 0, total: 0 },
    )

    return {
      socialHistory: newData?.socialHistory,
      lastValue: newData?.socialHistory[0]?.value,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    socialHistory,
    lastValue,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type SocialHistoryQueryData = { socialHistory: Observation[]; next: number | undefined; total: number }

export { useSocialHistory }
