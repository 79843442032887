import type { MedicationKnowledge } from "fhir"
import { useMemo } from "react"

import { getCommonCode } from "utils"

import type { Plan } from "../types"

const usePlanMedicationsDataBind = (planDefinitions: Plan[], medicationKnowledge: MedicationKnowledge[]) => {
  const { plans } = useMemo(() => {
    const plans = medicationKnowledge.length
      ? planDefinitions.reduce((acc, plan) => {
          const rx = medicationKnowledge.filter((mk) =>
            plan.rxCodes?.includes(getCommonCode({ codes: mk.code?.coding })),
          )
          const nutraceutical = medicationKnowledge.filter((mk) =>
            plan.nutraCodes?.includes(getCommonCode({ codes: mk.code?.coding })),
          )
          return [...acc, { ...plan, rx, nutraceutical }]
        }, Array<Plan>())
      : planDefinitions

    return { plans }
  }, [planDefinitions, medicationKnowledge])

  return { plans }
}

export { usePlanMedicationsDataBind }
