import { faCalendarDays, faDoNotEnter, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"

import { FileUrlDownloader, ListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import type { Laboratory } from "../../laboratory/types"

const LabListItem = ({ lab: { dr }, onDelete, onEdit }: Props) => {
  const fileUrl = dr.presentedForm?.[0]?.url

  const dropDownItems = [
    {
      label: "Edit",
      icon: <FontAwesomeIcon icon={faPencil} size="1x" className="mr-2" />,
      command: onEdit,
    },
    {
      label: "Deactivate",
      icon: <FontAwesomeIcon icon={faDoNotEnter} size="sm" className="mr-2" />,
      command: onDelete,
    },
  ]

  const Head = (
    <>
      {dr.code && (
        <p title="Code" className="mr-2 overflow-hidden text-ellipsis line-clamp-1 whitespace-normal break-words">
          {codeableConceptAsString(dr.code)}
        </p>
      )}
      <p title="Performer">{dr.performer?.[0]?.display ?? "Unspecified"}</p>
    </>
  )

  const Details = (
    <>
      {dr.issued && (
        <span title="Issued at" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {formatDate(new Date(dr.issued), formatsByTypes.LONG_DATETIME)}
        </span>
      )}
      {fileUrl && (
        <span title="Attached file" className="flex items-center pl-2">
          <FileUrlDownloader url={fileUrl} />
        </span>
      )}
    </>
  )

  return <ListItem header={Head} subHeader={Details} dropdownMenuItems={dropDownItems} />
}

type Props = {
  lab: Laboratory
  onDelete(): void
  onEdit(): void
}

export { LabListItem }
