import { type IconDefinition, faChartLineDown, faChartLineUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { CardListItem } from "commons"

const VitalsKnowledgeItem: FC<Props> = ({
  icon,
  label,
  itemContent,
  behavior,
  className = "border-solid border-b px-5 border-gray-200 last:border-none py-6",
  lastElementClassName,
}) => (
  <CardListItem
    className={classNames("first:pt-3 last:pb-3", className)}
    contentHeader={
      <div className="flex items-center text-slate-500">
        <div
          className={classNames("w-6 h-6 flex items-center justify-center mr-2 rounded-md text-lg p-3 text-white", {
            "bg-green-400": label.toLocaleLowerCase().includes("height"),
            "bg-orange-400": label.toLocaleLowerCase().includes("weight"),
            "bg-red-400": label.toLocaleLowerCase().includes("heart"),
            "bg-blue-400": label.toLocaleLowerCase().includes("respiratory"),
            "bg-purple-400": label.toLocaleLowerCase().includes("spo2"),
            "bg-pink-400": label.toLocaleLowerCase().includes("temperature"),
            "bg-slate-400": !label
              .match(/(height)*(weight)*(heart)*(respiratory)*(spo2)*(temperature)*/gi)
              ?.filter((matches) => matches !== "")?.length,
          })}
        >
          <FontAwesomeIcon className="fa-fw" icon={icon} />
        </div>
        {label && <span className={classNames("mr-2 font-bold", { italic: itemContent === "N/A" })}>{label}:</span>}
        <span className={classNames({ italic: itemContent === "N/A" })}>{itemContent}</span>
        {behavior !== undefined && behavior !== 0 && (
          <FontAwesomeIcon
            className={classNames("fa-fw ml-2", { "text-green-500": behavior > 0, "text-red-500": behavior < 0 })}
            icon={behavior > 0 ? faChartLineUp : faChartLineDown}
          />
        )}
      </div>
    }
    contentClassName="py-1 my-0.5"
    lastElementClass={lastElementClassName}
  />
)

type Props = {
  icon: IconDefinition
  label: string
  itemContent: string
  behavior?: number
  className?: string
  lastElementClassName?: string
}

export { VitalsKnowledgeItem }
