import { useFormikContext } from "formik"
import { useMemo } from "react"

import { usePatientContext } from "patients"

import { DissmisableFieldList } from "../../../forms/DissmisableFieldList"
import type { PlanData } from "../../types"
import { ReusableQRField } from "./ReusableQRField"

const OrderAndTaskInfo = ({ requiresEmails = true, contentClassName }: Props) => {
  const { patientId } = usePatientContext()
  const {
    values: { mailTasks, appointment },
    setFieldValue,
  } = useFormikContext<PlanData>()

  const taskFieldDefinition = useMemo(
    () =>
      mailTasks && requiresEmails
        ? Object.values(mailTasks)
            .filter((task) => !task.isRequired && !!task.editable && !!task.showable)
            .map((task) => ({
              field: `mailTasks.${task.taskId}`,
              label: task.label,
              itemData: task,
            }))
        : [],
    [mailTasks],
  )

  //Hidding this section at the moment
  // const emailComunications = useMemo(
  //   () =>
  //     mailTasks && requiresEmails
  //       ? Object.values(mailTasks)
  //           .filter((task) => !!task.showable && !!task.restriction?.period?.start)
  //           .flatMap((task) =>
  //             task.taskOutput.reduce((acc, { label, subactionValue }, index) => {
  //               if (!subactionValue)
  //                 return [
  //                   ...acc,
  //                   {
  //                     taskId: task.taskId,
  //                     index,
  //                     label,
  //                   },
  //                 ]
  //               return [...acc]
  //             }, Array<{ taskId: string; index: number; label: string }>()),
  //           )
  //       : [],
  //   [mailTasks],
  // )

  /* const consents = configureActions[PLAN_ACTION_CODES.SEND_CONSENT]?.output?.reduce((acc, subAction, index) => {
    const output = subAction.codeFilter?.[0]
    return [
      ...acc,
      ...(output?.code?.[0]?.code
        ? [
            {
              id: output.code[0]?.code,
              label: output.code?.[0]?.display ?? configureActions[PLAN_ACTION_CODES.SEND_CONSENT]?.description ?? "",
              index,
            },
          ]
        : []),
    ]
  }, Array<{ id: string; index: number; label: string }>()) */

  return (
    <>
      {mailTasks &&
        requiresEmails &&
        Object.values(mailTasks)
          .filter((task) => task.isRequired && !!task.editable && !!task.showable)
          .map((task) => (
            <ReusableQRField
              key={task.taskId}
              fieldPath={`mailTasks.${task.taskId}`}
              pickField="carePlan.activity"
              dateField={`mailTasks.${task.taskId}.restriction.period.start`}
              taskOutput={task.taskOutput}
              patientId={patientId}
              taskId={task.taskId}
              minDate={new Date()}
              maxDate={appointment?.start && new Date(appointment.start)}
              label={task.label}
              labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem] text-ellipsis whitespace-break-spaces line-clamp-1"
              className={contentClassName}
            />
          ))}
      {taskFieldDefinition.length > 0 && (
        <DissmisableFieldList
          label="Optional mails"
          addFieldLabel="Add mail"
          removeFieldLabel="Don't send this mail"
          containerClassName="flex flex-col space-y-3 pb-5"
          dismissButtonClassName="mt-1"
          emptyFieldsMessage="No optional mails added"
          fieldDefinitions={taskFieldDefinition}
          fieldIsInitialActive={(item) => item.itemData?.enabled ?? false}
          onFieldStatusChanged={(item, status) => !status && setFieldValue(item.field, undefined)}
          className={contentClassName}
        >
          {(itemField) => (
            <ReusableQRField
              fieldPath={itemField.field}
              pickField="carePlan.activity"
              dateField={itemField.field + ".restriction.period.start"}
              taskOutput={itemField.itemData?.taskOutput ?? []}
              patientId={patientId}
              taskId={itemField.itemData?.taskId ?? ""}
              minDate={new Date()}
              maxDate={appointment?.start && new Date(appointment.start)}
              label={itemField.itemData?.label ?? ""}
              labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem] text-ellipsis whitespace-break-spaces line-clamp-1"
            />
          )}
        </DissmisableFieldList>
      )}
      {/* {emailComunications.length > 0 && (
          <div className={classNames("inline-flex justify-between w-full items-center", contentClassName)}>
            <label className="text-sm font-medium text-gray-700 min-w-32 @lg:w-48 @xl:w-52 @2xl:w-60 3xl:min-w-60 2xl:min-w-52 xl:min-w-48 mr-5 mb-2">
              Email Communications
            </label>
            {emailComunications.map(({ taskId, label, index }) => (
              <CheckBoxField
                key={`${taskId}-${index}`}
                field={`mailTasks.${taskId}.taskOutputLabels[${index}]`}
                label={label}
                isOnlyInfo
                className="flex-1 w-full"
              />
            ))}
          </div>
      )} */}
      {/* {consents?.length && (
          <div className={classNames("inline-flex justify-between w-full items-center", contentClassName)}>
            <label className="text-sm font-medium text-gray-700 min-w-32 @lg:w-48 @xl:w-52 @2xl:w-60 3xl:min-w-60 2xl:min-w-52 xl:min-w-48 mr-5 mb-2">
              Consents
            </label>
            {consents.map(({ id, label, index }) => (
              <div key={`${id}-${index}`} className="flex items-center gap-1 flex-1">
                <Checkbox inputId={id} name={label} value={label} checked disabled />
                <label htmlFor={id} className="text-sm font-medium text-gray-700">
                  {label}
                </label>
              </div>
            ))}
          </div>
      )} */}
    </>
  )
}

type Props = {
  requiresEmails?: boolean
  contentClassName?: string
}

export { OrderAndTaskInfo }
