import type { Reference } from "fhir"
import { type AutoCompleteCompleteEvent, AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useState } from "react"

import { useQuestionnaire } from "surveys"

const QuestionnairesAutocomplete: FC<Props> = ({
  inputClassName,
  disabled,
  placeholder,
  error,
  className,
  handleSelect,
  appendTo = "self",
  panelClassName = "w-full",
}) => {
  const { canonicalRefs } = useQuestionnaire()
  const [value, setValue] = useState<string>()
  const [suggestionList, setSuggestionList] = useState<Reference[]>([])

  const searchItems = useCallback(
    (event: AutoCompleteCompleteEvent) => {
      let suggestions = Array<Reference>()
      if (!event.query.trim().length) {
        suggestions = [...(canonicalRefs ?? [])]
      } else {
        suggestions = (canonicalRefs ?? []).filter(({ display }) => {
          return display?.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }

      setSuggestionList(suggestions)
    },
    [canonicalRefs],
  )

  return (
    <div className={classNames("flex flex-col", className)}>
      <AutoComplete
        id={`questionnaires-autocomplete`}
        field="display"
        disabled={disabled}
        suggestions={suggestionList}
        delay={400}
        minLength={1}
        completeMethod={searchItems}
        forceSelection
        className={classNames("p-inputtext-sm", { "p-invalid": error }, inputClassName)}
        placeholder={placeholder}
        onChange={(e) => setValue(e.value)}
        onSelect={(e) => {
          handleSelect(e.value)
          setValue(undefined)
        }}
        appendTo={appendTo}
        panelClassName={panelClassName}
        value={value}
        dropdown
        dropdownMode="blank"
      />
    </div>
  )
}

type Props = {
  error?: unknown
  placeholder?: string
  disabled?: boolean
  inputClassName?: string
  className?: string
  appendTo?: HTMLElement | "self" | (() => HTMLElement) | null
  panelClassName?: string
  handleSelect(item?: Reference): void
}

export { QuestionnairesAutocomplete }
