import type { Reference } from "fhir"
import { useFormikContext } from "formik"

import { DropdownField, InputField, NumberField } from "commons"
import { sumPrice } from "utils"

import type { PDParams } from "../../../types"

const LabPanelForm = ({ performerLabsOptions, isEditing }: Props) => {
  const {
    values: { fee, basePrice },
  } = useFormikContext<PDParams>()

  return (
    <div className="divide-gray-200 divide-y pt-1.5">
      <div className="p-fluid grid gap-2">
        <InputField field="title" placeholder="Add a name" disabled={isEditing} />
        <DropdownField
          optionLabel="display"
          optionValue=""
          field="laboratory"
          placeholder="Select Laboratory"
          options={performerLabsOptions}
          dataKey="id"
          disabled={isEditing}
        />
      </div>
      <div className="flex justify-center flex-col flex-1 space-y-3 mt-6">
        <div className="pt-2 flex justify-between items-center text-sm mb-2">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="divide-gray-200 divide-y flex justify-center flex-col flex-1 space-y-3">
          <div className="pb-1 flex justify-between items-center">
            <span className="font-semibold text-sm">Fee</span>
            <NumberField
              field="fee"
              horizontal
              min={0}
              mode="currency"
              placeholder="$0.00"
              maxFractionDigits={2}
              showButtons={false}
            />
          </div>
          <div className="pt-2 flex justify-between">
            <span className="font-semibold text-sm">Total</span>
            <span className="font-semibold text-sm">${sumPrice(basePrice, fee ?? 0).sum.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  performerLabsOptions: Reference[]
  isEditing?: boolean
  isLoadingData?: boolean
}

export { LabPanelForm }
