import { useQuery } from "@tanstack/react-query"
import { type Location, getResources } from "fhir"

import { useClient } from "api"
import { SYSTEM_VALUES } from "system-values"

const useOrganizationLabLocation = (organizationId: string) => {
  const { search } = useClient()

  const queryKey = ["lab-facility-locations", organizationId]

  const { data, isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
        "identifier:text": SYSTEM_VALUES.EXTERNAL_IDENTIFIER,
        _elements: "id,name",
      })
      const bundleLocations = await search({ endpoint: `Organization/${organizationId}/Location`, filters, signal })
      const locations = getResources<Location>(bundleLocations, "Location")

      return { locations }
    },
    enabled: !!organizationId,
    meta: { context: { queryKey } },
  })

  return { locations: data?.locations ?? [], isLoading }
}

export { useOrganizationLabLocation }
