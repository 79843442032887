import type { AccountBETACreditCardArray } from "fhir"
import type { FC } from "react"

import { creditCardModelBuilder, isExpired } from "account"
import { StackedListItem } from "commons"

const PatientPreferedPaymentMethod: FC<Props> = ({ defaultCreditCard }) =>
  !defaultCreditCard ? (
    "No active credit card"
  ) : (
    <StackedListItem modelData={creditCardModelBuilder(defaultCreditCard, true, false, isExpired(defaultCreditCard))} />
  )

type Props = {
  defaultCreditCard?: AccountBETACreditCardArray
}

export { PatientPreferedPaymentMethod }
