import { type FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import type { AnyObject } from "yup/lib/object"

import { FormField, NumberField } from "commons"
import { commonsDispenseInterval } from "commons/meds"

import type { MedicationRequestFormData } from "../../types"

const DispenseRequestFields: FC<Props> = ({ onChange: onDispenseChange }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext<MedicationRequestFormData>()
  const dispenseIntervalValue = values.dispenseRequest?.dispenseInterval
  const isRefillsFieldDisabled = dispenseIntervalValue?.value === 0

  return (
    <div className="flex flex-1 justify-between items-start space-x-6 mb-1">
      <div className="flex flex-col w-full gap-2">
        <label className="text-sm font-medium text-gray-700">Dispense</label>
        <FormField field="dispenseRequest.dispenseInterval">
          {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
            <Dropdown
              options={commonsDispenseInterval}
              optionLabel="label"
              optionValue="value"
              id={name}
              name={name}
              value={value}
              onChange={(e) => {
                let repeatsAllowed = values.dispenseRequest?.numberOfRepeatsAllowed || 1
                if (!e.value?.value) repeatsAllowed = 0

                setFieldValue("dispenseRequest.numberOfRepeatsAllowed", repeatsAllowed)
                onChange(e)
                onDispenseChange?.(e.value)
              }}
              className={classNames("p-inputtext-sm small-trigger", {
                "p-invalid": touched && error,
              })}
              disabled={isSubmitting}
            />
          )}
        </FormField>
      </div>
      <NumberField
        field="dispenseRequest.numberOfRepeatsAllowed"
        label="Refills"
        className="w-full"
        min={0}
        disabled={isRefillsFieldDisabled || isSubmitting}
        onChange={onDispenseChange}
      />
    </div>
  )
}

type Props = {
  onChange?(value: AnyObject | number | null | undefined): void
}

export { DispenseRequestFields }
