import type { Coding } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useState } from "react"

import { getCommonCode } from "utils"

import { useMedicationAvailability } from "../hooks"

const DfoCheckbox: FC<Props> = ({
  initialChecked,
  disabled,
  label,
  labelClassName,
  medData,
  className,
  handleCheck,
}) => {
  const { isLoadingAvailability, medAvailability, medQuantityAvailable, refetchMedAvailability } =
    useMedicationAvailability(getCommonCode({ codes: medData.code }), medData.qty ?? 1)

  const [isDFO, setIsDFO] = useState(initialChecked)

  const onChange = async (val?: boolean) => {
    setIsDFO(val ?? false)
    if (val) {
      const { data } = await refetchMedAvailability()

      if (data?.medQuantityAvailable) {
        handleCheck(val)
      } else {
        handleCheck(false)
        setIsDFO(false)
      }
    } else handleCheck(false)
  }

  useEffect(() => {
    if (!isLoadingAvailability && !disabled) {
      if (!medQuantityAvailable) {
        setIsDFO(false)

        handleCheck(false)
      } else setIsDFO(initialChecked)
    }
  }, [medQuantityAvailable, initialChecked, isLoadingAvailability, disabled])

  return (
    <div className={classNames("flex flex-col", className)}>
      <span className="inline-flex items-center">
        <Checkbox
          checked={isDFO}
          trueValue={true}
          falseValue={false}
          onChange={(e) => onChange(e.checked)}
          disabled={disabled || isLoadingAvailability}
        />
        <span className={classNames("text-sm inline-flex items-center", labelClassName)} title="Dispense from office">
          {label}
        </span>
      </span>

      <>
        {isLoadingAvailability ? (
          <Skeleton shape="rectangle" className="max-w-xs" />
        ) : (
          <div className="flex w-full justify-between text-sm">
            <p className="text-primary">Available: {medAvailability}</p>
          </div>
        )}
      </>
    </div>
  )
}

type Props = {
  initialChecked: boolean
  disabled?: boolean
  label?: string
  labelClassName?: string
  availabilityUnit?: string
  handleCheck(val: boolean | undefined): void
  medData: { code?: Coding[]; qty?: number }
  className?: string
}

export { DfoCheckbox }
