import { type FC, useState } from "react"
import type { Reference } from "fhir"

import { Button } from "commons"
import { EncounterConfig, useOpenEncounter } from "encounter"

const StartEncounterAction: FC<Props> = ({ label, className, patientId, patientRef, appointmentId }) => {
  const { openEncounterRef, isLoading } = useOpenEncounter(patientId)
  const [startClicked, setStartClicked] = useState(false)

  if (openEncounterRef?.id) return null

  return (
    <>
      <Button
        label={label}
        loading={isLoading}
        buttonStyle="text"
        className={className}
        disabled={startClicked}
        onClick={() => setStartClicked(true)}
      />
      <EncounterConfig
        visible={startClicked}
        patientId={patientId}
        patientRef={patientRef}
        appointmentId={appointmentId}
        onHide={() => setStartClicked(false)}
      />
    </>
  )
}

type Props = {
  label?: string
  className?: string
  patientId: string
  appointmentId: string
  patientRef: Reference
}

export { StartEncounterAction }
