import type { Address, Patient } from "fhir"
import { Form, Formik } from "formik"
import { Button } from "primereact/button"
import { useMemo } from "react"

import { AddressField, addressTypes, sanitizeAddress } from "commons"
import { emptyAddress } from "data"
import { getHomeAddressIndex, isSameAddress } from "utils"

import { addressValidationSchema } from "../validations"

const AddressForm = ({
  patient,
  isUpdating,
  initialValues,
  isNoEffectiveAddress = false,
  handleUpdateAddress,
  handleCancel,
  addressIndex,
  actionLabel = "Update",
}: Props) => {
  const address = [...(patient?.address ?? [])]

  const addIndex =
    addressIndex ??
    (!!initialValues && !isSameAddress(emptyAddress, initialValues)
      ? findAddressIndex(initialValues, address)
      : getHomeAddressIndex(address))

  const initialFormValues = initialValues ?? (addressIndex !== -1 ? address[addIndex] ?? emptyAddress : emptyAddress)

  const handleSubmit = (addressData: Address) => {
    const index = addIndex !== -1 ? addIndex : address.length ? address.length + 1 : 0

    address.splice(index, 1, addressData)
    handleUpdateAddress?.(sanitizeAddress({ ...patient, address }) as Patient)
  }

  const validAddressTypes = useMemo(
    () => addressTypes.filter(({ code }) => !address.some(({ type }) => type === code)),
    [address],
  )

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={addressValidationSchema()}
      enableReinitialize
    >
      <Form className="h-full overflow-y-auto">
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-3" id="address">
          <legend>Address</legend>
          <AddressField showTypeUseField={!isNoEffectiveAddress} validAddressTypes={validAddressTypes} />
        </fieldset>
        <div className="mt-10 mr-3 flex justify-end">
          {!!handleCancel && (
            <Button
              type="button"
              label="Close"
              className="button-default mr-1"
              disabled={isUpdating}
              onClick={handleCancel}
            />
          )}
          <Button type="submit" label={actionLabel} className="button-primary" loading={isUpdating} />
        </div>
      </Form>
    </Formik>
  )
}

const findAddressIndex = (address: Address, addresses: Address[]) =>
  addresses.findIndex((ad) => isSameAddress(ad, address))

type Props = {
  patient: Patient
  isUpdating?: boolean
  initialValues?: Address
  actionLabel?: string
  addressIndex?: number
  isNoEffectiveAddress?: boolean
  handleUpdateAddress: (patient: Partial<Patient>) => void
  handleCancel?: () => void
}

export { AddressForm }
