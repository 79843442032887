import { type Patient, asReference } from "fhir"
import { useCallback, useMemo } from "react"

import { MC_PUBLISHER } from "data"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"

import { useEnrollPlan } from "./useEnrollPlan"
import { usePlanDefinitions } from "./usePlanDefinitions"

const useAutoEnroll = (
  patient: Patient,
  activePDs: Record<string, string>,
  onEnrollSuccess: (cpId: string) => void,
) => {
  const { currentOrganizationId, partOfId, loggedInPractitionerRoleRef } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patient.id as string)

  const { planDefinitions, isLoading: isLoadingPDs } = usePlanDefinitions({
    patientGender: patient.gender?.includes("male") ? patient.gender : undefined,
    organizationId: currentOrganizationId,
    orgPartOfId: partOfId ?? currentOrganizationId,
  })

  const { enrollPlan, isEnrolling } = useEnrollPlan((cpId: string) => onEnrollSuccess(cpId))

  const planToEnroll = useMemo(
    () =>
      planDefinitions?.find(
        ({ definition, definitionToken }) =>
          definition.publisher === MC_PUBLISHER &&
          (!activePDs[definitionToken] || definition.topic?.find((t) => t?.coding?.[0]?.code === "open-enroll")),
      ),
    [planDefinitions, activePDs],
  )

  const enroll = useCallback(
    () =>
      enrollPlan({
        planId: planToEnroll?.definition?.id as string,
        author: loggedInPractitionerRoleRef,
        patient: asReference(patient),
        encounter: openEncounterRef,
      }),
    [planToEnroll, loggedInPractitionerRoleRef, patient, openEncounterRef],
  )

  return {
    isLoadingPDs,
    isEnrolling,
    somePlanToEnroll: !!planToEnroll,
    enroll,
  }
}

export { useAutoEnroll }
