import { ModulesId } from "commons/Module"
import type { PlanDefinitionActionArrayActionArray } from "fhir"
import { useAppModuleContext } from "internals"

import { PLAN_ACTION_CODES } from "../types"

const useRequestedConfigs = (configureActions: Record<string, PlanDefinitionActionArrayActionArray>) => {
  const { isModuleActive } = useAppModuleContext()

  return Object.keys(configureActions).reduce(
    (acc, key) => {
      switch (key) {
        case PLAN_ACTION_CODES.CONFIGURE_ALGORITHM:
          if (isModuleActive(ModulesId.MC)) {
            const requiresProcedure = configureActions[key]?.action?.some(({ code }) =>
              code?.[0]?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_PROCEDURE),
            )

            const requiresMC = configureActions[key]?.action?.some(({ code }) =>
              code?.[0]?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_MC),
            )

            if (requiresProcedure) return { ...acc, requiresProcedure }
            else return { ...acc, requiresAlgorithm: !!requiresMC }
          }
          break
        case PLAN_ACTION_CODES.CONFIGURE_EMAILS:
          return { ...acc, requiresEmails: true }
        case PLAN_ACTION_CODES.CONFIGURE_LABS:
          if (isModuleActive(ModulesId.LABS)) return { ...acc, requiresLabs: true }
          break
        case PLAN_ACTION_CODES.CONFIGURE_NUTRAS:
          if (isModuleActive(ModulesId.MEDICATIONR)) return { ...acc, requiresNutras: true }
          break
        case PLAN_ACTION_CODES.CONFIGURE_RXS:
          if (isModuleActive(ModulesId.MEDICATIONE)) return { ...acc, requiresRXs: true }
          break
        case PLAN_ACTION_CODES.CONFIGURE_QUESTIONNAIRES:
          if (isModuleActive(ModulesId.SURVEY)) return { ...acc, requiresSurveys: true }
          break
      }
      return { ...acc }
    },
    {
      requiresAlgorithm: false,
      requiresLabs: false,
      requiresSurveys: false,
      requiresEmails: false,
      requiresNutras: false,
      requiresRXs: false,
      requiresProcedure: false,
    },
  )
}

export { useRequestedConfigs }
