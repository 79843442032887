import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { isCarePlan, type Annotation, type ServiceRequest } from "fhir"
import type { FC } from "react"
import { classNames } from "primereact/utils"

import { strCapitalize } from "utils"
import { ModulesId } from "commons"
import { laboratoryOrderStatusCodes } from "data"
import { useChartContext } from "chart-view"
import { getStatus } from "commons/labs"
import { labOrdersQueryKeys } from "laboratory-orders"

import { WrapUpSection } from "./WrapUpSection"
import { OrderItem } from "./OrderItem"
import { type EditProps, renderNotes } from "./utils"
import { useEncounterContext } from "encounter/hooks"

const LabsSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()

  const orderStatus = laboratoryOrderStatusCodes.map(({ code }) => code)

  const goToOrderDetails = (order: string) => {
    showModule({ module: ModulesId.LABSORDER, moduleParams: { order } })
  }

  const { encounterData, totalLabOrders: total, planRefs } = useEncounterContext()

  const renderDetails = ({
    panels,
    combo,
    notes,
    className,
    editProps,
  }: {
    panels: { profile: ServiceRequest }[]
    combo?: { profile: ServiceRequest; panels: { profile: ServiceRequest }[] }
    notes?: Annotation[]
    className?: string
    editProps?: EditProps
  }) => (
    <>
      {combo && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
            {combo.profile.code?.coding?.[0].display}
            <hr className="flex-1 ml-1" />
          </span>
          <div className="py-1 grid grid-flow-row-dense gap-1 @lg:grid-cols-2">
            {combo.panels.map(({ profile }) => (
              <p key={profile.id} className="truncate">
                {profile.code?.coding?.[0]?.display}
              </p>
            ))}
          </div>
        </div>
      )}
      {panels.length && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
            Additional tests
            <hr className="flex-1 ml-1" />
          </span>
          <div className="py-1 grid grid-flow-row-dense gap-1 @lg:grid-cols-2">
            {panels.map(({ profile }) => (
              <p key={profile.id} className="truncate">
                {profile.code?.coding?.[0]?.display}
              </p>
            ))}
          </div>
        </div>
      )}

      {renderNotes({ notes, className, editProps })}
    </>
  )

  return (
    <WrapUpSection
      sectionTitle="Lab orders"
      isEmpty={!total}
      icon={icon}
      emptyMesage="No orders placed during this encounter"
      className="w-full self-end pb-2 gap-6"
    >
      {encounterData?.laboratoryOrders?.map(({ order: sr, orderIdentifier, panels, combo }) => {
        const cp = planRefs?.[sr.basedOn?.find(isCarePlan)?.id as string]
        return (
          <OrderItem
            key={sr?.id}
            requester={sr?.requester}
            status={getStatus(sr)?.display}
            title={`${strCapitalize(sr?.performer?.[0]?.display as string)} ${sr?.status !== "draft" ? `(${orderIdentifier})` : ""}`}
            date={sr?.authoredOn ? new Date(sr.authoredOn) : undefined}
            seeOrderDetails={() => goToOrderDetails(sr?.id as string)}
            className="first:mt-6"
            badge={
              cp?.display
                ? {
                    text: cp.display,
                    colorStyle: "gray",
                    title: cp.display,
                    className: "truncate max-w-24 !block",
                  }
                : undefined
            }
          >
            {renderDetails({
              panels,
              combo: combo && { profile: combo.laboratoryCombo, panels: combo.panels },
              notes: sr.note,
              className: "text-sm pr-2 @container",
              editProps: sr?.id
                ? {
                    orderId: sr.id,
                    queriesToInvalidate: labOrdersQueryKeys.list(patientId, orderStatus, undefined, encounterId),
                  }
                : undefined,
            })}
          </OrderItem>
        )
      })}
    </WrapUpSection>
  )
}

type Props = {
  encounterId: string
  patientId: string
  icon: IconDefinition
}

export { LabsSection }
