import { useInfiniteQuery } from "@tanstack/react-query"
import { type Composition, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { assessmentsQueryKeys } from "../query-keys"

const usePatientCompositions = (patientId: string) => {
  const { search } = useClient()
  const queryKey = assessmentsQueryKeys.compositions(patientId)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    CompositionQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        patient: patientId,
      })

      const bundle = await search({ endpoint: "Composition", filters, signal })

      const compositions = getResources(bundle) as Composition[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { compositions, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    throwOnError: true,
  })

  const { compositions, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.compositions)
    const count = newData?.length

    return {
      compositions: newData,
      count,
    }
  }, [data?.pages])

  return {
    compositions,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type CompositionQueryData = {
  compositions: Composition[]
  next: number | undefined
  total: number
}

export { usePatientCompositions }
