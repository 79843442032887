import { ScheduleAppointmentProvider } from "../context"
import { AppointmentCalendarSection } from "./AppointmentCalendarSection"
import { AppointmentParticipantsSection } from "./AppointmentParticipantsSection"
import { AppointmentQuestionnairesSection } from "./AppointmentQuestionnairesSection"
import { AppointmentSlotsSection } from "./AppointmentSlotsSection"

const AppointmentForm = ({ hidePatientField, isEditing, hideExtraParticipantFields }: Props) => (
  <ScheduleAppointmentProvider>
    <div className="relative p-fluid grid grid-cols-3 gap-6 mb-3 p-2">
      <AppointmentCalendarSection hidePatientField={hidePatientField} isEditing={isEditing} />
      <AppointmentSlotsSection />
      <div className="flex flex-col gap-6">
        <AppointmentParticipantsSection hideExtraParticipantFields={hideExtraParticipantFields} isEditing={isEditing} />
        <AppointmentQuestionnairesSection />
      </div>
    </div>
  </ScheduleAppointmentProvider>
)

type Props = {
  hidePatientField?: boolean
  isEditing?: boolean
  hideExtraParticipantFields?: boolean
}

export { AppointmentForm }
