import type { Reference } from "fhir"

import { SkeletonLoader } from "commons"

import { useDetectedIssue } from "../hooks"

const MedicationKnowledgeContraindications = ({ contraindications }: Props) => {
  const { detectedIssues, isLoading } = useDetectedIssue(contraindications.map(({ id }) => id).join(", "))

  if (isLoading) return <SkeletonLoader loaderType="plain-text" repeats={6} />

  return (
    <div>
      {detectedIssues.map((issue, index) => (
        <p key={index} className="text-justify">
          {issue.detail}
        </p>
      ))}
    </div>
  )
}

type Props = {
  contraindications: Reference[]
}

export { MedicationKnowledgeContraindications }
