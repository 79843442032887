import type { Reference } from "fhir"
import { type FieldArrayRenderProps, useFormikContext } from "formik"
import type { FC } from "react"

import { CheckBoxField, ChipListFieldArray, DropdownColor, DropdownField, InputField, NumberField } from "commons"
import { appointmentMinutesDuration, unitOfTime } from "data"

import type { AppointmentType } from "../../types"
import { DataAutocomplete } from "./DataAutocomplete"
import { QuestionnairesAutocomplete } from "./QuestionairesAutocomplete"
import { Dropdown } from "primereact/dropdown"

const AppointmentTypeForm: FC<Props> = ({ isEditing }) => {
  const {
    values: { patientCanBook /* withLabs, requester */ },
    setFieldValue,
  } = useFormikContext<AppointmentType>()

  /* const { performerLabsEnabled, organizationAnIdentifier, currentOrganizationId } = useOrganizationContext()

  const { organizationPractitionersInfo: practitioners } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
    filterByIdentifier: HEALTH_GORILLA_VALUE_CHECK,
  })

  const { laboratoryRefs } = useAvailableLabs(
    practitioners ?? [],
    performerLabsEnabled,
    requester,
    organizationAnIdentifier,
  ) */

  return (
    <div className="pt-1.5 p-fluid grid gap-5 pb-5">
      <fieldset className="flex flex-wrap flex-1 gap-3">
        <InputField
          field="name"
          label="Type"
          placeholder="Name this type"
          validation={(val) => !val && "Type is required"}
          className="flex-1"
        />
        <DropdownColor
          field="color"
          label="Color"
          autoAssign
          validation={(val) => !val && "Please pick a color"}
          className="flex-1"
        />
      </fieldset>
      <fieldset className="w-full inline-flex gap-3">
        <CheckBoxField field="telemedicine" label="Telemedicine" />
        <CheckBoxField field="patientCanBook" label="Patient can book?" />
      </fieldset>
      <fieldset className="flex items-center flex-1 gap-3">
        <DropdownField
          field="duration"
          options={appointmentMinutesDuration}
          label="Duration"
          showDefaultValue
          dataKey="value"
          optionValue="value"
          className="flex-1"
        />
        <span className="field flex items-center flex-1 gap-3">
          <NumberField
            field="daysInAdvance"
            label="Time in advance"
            min={1}
            allowEmpty={false}
            showButtons={false}
            className="w-1/2"
            disabled={!patientCanBook}
          />
          <Dropdown
            options={[unitOfTime[3]]}
            value={unitOfTime[3].code}
            dataKey="code"
            optionValue="code"
            optionLabel="display"
            className="p-inputtext-sm w-1/2 h-10 mt-2.5"
            disabled={!patientCanBook}
          />
        </span>
      </fieldset>

      {/* <div className="flex flex-col gap-3">
          <fieldset className="flex justify-between flex-1">
            <label className="text-gray-700 text-sm font-medium">Labs</label>
            <CheckBoxField field="withLabs" label="Activate Labs" />
          </fieldset>
          <fieldset className="grid grid-cols-2 gap-4">
            <PractitionerRoleDropdownField
              field="requester"
              label="Practitioner"
              options={practitioners ?? []}
              useFilter={false}
              disabled={!withLabs}
            />
            <ReferenceDropdownField
              field="provider"
              label="Laboratory"
              options={laboratoryRefs}
              showFilter={false}
              assignDefaultValue={false}
              disabled={!withLabs}
            />
          </fieldset>
          <fieldset>
            <div className="inline-flex items-center gap-1 text-sm font-light w-full">
              <label htmlFor="icd10" className="text-gray-500">
                ICD-10
              </label>
              <hr className="flex-1" />
            </div>
            <ICD10CodesField
              field="icd10"
              disabled={!withLabs}
              label=""
              orgSuggestedConditions="defaultProcedureIcd10"
            />
          </fieldset>

          <fieldset>
            <div className="inline-flex items-center gap-1 text-sm font-light w-full mb-3">
              <label htmlFor="combos" className="text-gray-500">
                Combos
              </label>
              <hr className="flex-1" />
            </div>
            <AppointmentCombos
              disabled={!withLabs}
              className="grid grid-flow-row @xl:grid-cols-2 @2xl:grid-cols-3 gap-2"
            />
          </fieldset>
        </div> */}
      <ChipListFieldArray
        field="practitioners"
        itemLabel="practitioner"
        getItemText={(item: Reference) => item.display ?? ""}
        askDeleteConfirmation={isEditing}
        hideEmptyMessage
      >
        {({
          push,
          form: {
            values: { practitioners },
          },
        }: FieldArrayRenderProps) => (
          <DataAutocomplete
            dataToHandle="practitioners"
            handleSelect={(item) => {
              if (!(practitioners as Reference[] | undefined)?.some(({ id }) => item?.id === id)) push(item)
            }}
            placeholder="Type to add practitioners"
            appendTo={null}
            panelClassName="max-w-min"
            labelClassName="mb-5"
          />
        )}
      </ChipListFieldArray>
      <ChipListFieldArray
        field="rooms"
        itemLabel="room"
        getItemText={(item: Reference) => item.display ?? ""}
        askDeleteConfirmation={isEditing}
        hideEmptyMessage
      >
        {({
          push,
          form: {
            values: { rooms },
          },
        }: FieldArrayRenderProps) => (
          <DataAutocomplete
            dataToHandle="rooms"
            handleSelect={(item) => {
              if (!(rooms as Reference[] | undefined)?.some(({ id }) => item?.id === id)) push(item)
            }}
            placeholder="Type to add rooms"
            appendTo={null}
            panelClassName="max-w-min"
            labelClassName="mb-5"
          />
        )}
      </ChipListFieldArray>
      <ChipListFieldArray
        field="devices"
        itemLabel="device"
        getItemText={(item: Reference) => item.display ?? ""}
        askDeleteConfirmation={isEditing}
        hideEmptyMessage
      >
        {({
          push,
          form: {
            values: { devices },
          },
        }: FieldArrayRenderProps) => (
          <DataAutocomplete
            dataToHandle="devices"
            handleSelect={(item) => {
              if (!(devices as Reference[] | undefined)?.some(({ id }) => item?.id === id)) push(item)
            }}
            placeholder="Type to add devices"
            appendTo={null}
            panelClassName="max-w-min"
            labelClassName="mb-5"
          />
        )}
      </ChipListFieldArray>

      <fieldset className="flex flex-col gap-2">
        <legend className="text-gray-700 text-sm font-medium">Questionnaires</legend>
        <small className="text-gray-500 text-sm mb-3">Select questionnaires to send</small>
        <ChipListFieldArray
          field="questionnaires"
          itemLabel="questionnaire"
          getItemText={(item: Reference) => item.display ?? ""}
          askDeleteConfirmation={isEditing}
          hideEmptyMessage
          onRemoveItem={(
            _,
            {
              form: {
                values: { questionnaires },
              },
            },
          ) => setFieldValue("withQuestionnaires", (questionnaires?.length ?? 0) > 1)}
        >
          {({
            push,
            form: {
              values: { questionnaires },
            },
          }: FieldArrayRenderProps) => (
            <QuestionnairesAutocomplete
              handleSelect={(item) => {
                setFieldValue("withQuestionnaires", true)
                if (!(questionnaires as Reference[] | undefined)?.some(({ id }) => item?.id === id)) push(item)
              }}
              placeholder="Type to add questionnaires"
              appendTo={null}
              panelClassName="max-w-min"
            />
          )}
        </ChipListFieldArray>
      </fieldset>
    </div>
  )
}

type Props = {
  isEditing?: boolean
}

export default AppointmentTypeForm
