import { add } from "date-fns"
import {
  type Address,
  type Coding,
  type Dosage,
  type Duration,
  type Medication,
  type MedicationKnowledge,
  type MedicationKnowledgeAdministrationGuidelinesArray,
  type Patient,
  type PractitionerRole,
  type Quantity,
  type Reference,
  type Timing,
  asReference,
  isMedication,
  isMedicationKnowledge,
} from "fhir"

import type { PractitionerInfo } from "commons"
import {
  type MedicationRequestData,
  commonsDispenseInterval,
  getAdministrationGuideline,
  getMKDisplayText,
  MEDICATIONS_REGULATIONS_CODE,
} from "commons/meds"
import { mrCategoryCodes, UNIT_DAYS } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getAddressByType, getPatientDefaultPractitioner, unitToDays } from "utils"

import { dosageTimingRepeats, durationCodeOptions } from "../data"
import type {
  MedicationDosage,
  MedicationFormData,
  MedicationIngredientFormData,
  MedicationRequestFormData,
  MedicationRequestInfo,
} from "../types"
// eslint-disable-next-line import/no-cycle
import { sanitizeDosage, serializeDosage } from "./formatters"

const getInitialValues = ({
  patient,
  practitionersInfo,
  loggedInPractitionerRole,
  encounter,
  medRecommendedDosage,
  medicationKnowledge,
  pharmacy,
  requester,
  fallbackShippingAddress,
  planId,
}: {
  patient: Patient
  loggedInPractitionerRole: PractitionerRole
  practitionersInfo: PractitionerInfo[]
  encounter?: Reference | null
  medicationKnowledge?: MedicationKnowledge
  medRecommendedDosage?: Record<string, Dosage[]>
  pharmacy?: Reference
  requester?: Reference
  fallbackShippingAddress?: Address
  planId?: string
}): MedicationRequestFormData => {
  const currentDate = new Date().toISOString()

  const requesterPR = requester ?? getPatientDefaultPractitioner(practitionersInfo, patient, loggedInPractitionerRole)

  const defaultQuantity =
    medicationKnowledge &&
    ({
      value: 1,
      code: medicationKnowledge.packaging?.type?.coding?.[0].code,
      unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
      system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
    } as Quantity)

  const { value: _, ...prescriptionQuantity } = defaultQuantity ?? {}

  const dosageInstruction =
    medicationKnowledge && getAdministrationGuideline(medicationKnowledge, medRecommendedDosage)?.dosage?.[0]?.dosage

  const { intendedRoute } = medicationKnowledge ?? {}
  const dosages =
    dosageInstruction?.map((dosage) =>
      serializeDosage(
        dosage,
        intendedRoute,
        isMedicationKnowledge(medicationKnowledge) ? getDosageInitialValues(medicationKnowledge) : undefined,
      ),
    ) ?? []

  const dispenseInterval = calculateDosageDispenseInterval(dosages)
  return {
    medicationField: medicationKnowledge && {
      ...medicationKnowledge,
      textDisplayedInField: getMKDisplayText(medicationKnowledge),
    },
    medication: medicationKnowledge ? { CodeableConcept: medicationKnowledge.code } : undefined,
    category: [{ coding: [mrCategoryCodes.medication], text: mrCategoryCodes.medication.display }],
    status: "draft",
    intent: "order",
    authoredOn: currentDate,
    subject: asReference(patient),
    encounter: encounter ?? undefined,
    requester: requesterPR,
    recorder: asReference(loggedInPractitionerRole),
    performer: asReference(patient),
    dosageInstruction,
    dispenseRequest: {
      initialFill: {
        quantity: undefined,
        duration: undefined,
      },
      nextRefillDate: undefined,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: dispenseInterval ?? {
        value: 0,
        code: durationCodeOptions[5].value.code,
        system: durationCodeOptions[5].value.system,
        unit: durationCodeOptions[5].value.display.toLowerCase(),
      },
      expectedSupplyDuration: undefined,
      quantity: defaultQuantity,
      performer: pharmacy,
      shippingAddress: getAddressByType("postal", patient.address) ?? fallbackShippingAddress,
    },
    dosages: !dosageInstruction ? [getDosageInitialValues()] : dosages,
    prescriptionQuantity: Object.keys(prescriptionQuantity).length ? prescriptionQuantity : undefined,
    resourceType: "MedicationRequest",
    basedOn: planId ? [{ id: planId, resourceType: "CarePlan" }] : undefined,
    medicationKnowledge,
  }
}

const getDosageInitialValues = (mk?: MedicationKnowledge): MedicationDosage => {
  const dosageQuantity = mk?.administrationGuidelines?.[0]?.dosage?.[0]?.dosage?.[0]?.doseAndRate?.[0]?.dose?.Quantity
  const { code, system, unit } = dosageQuantity ?? {}

  return {
    doseQuantity: "",
    medicationQuantity: dosageQuantity && { code, system, unit },
    treatmentFrequency: "",
    treatmentRoute: undefined,
    instructionText: "",
    prescriptionDuration: undefined,
    prescriptionDurationUnit: undefined,
  }
}

const getTimingFrequency = ({
  frequencyKeyValue,
  duration,
  durationUnit,
}: {
  frequencyKeyValue?: string
  duration?: string
  durationUnit?: Coding
}) => {
  const selectedfrequency = dosageTimingRepeats.find((option) => option.id === frequencyKeyValue)

  const frequency = {
    code: selectedfrequency?.code,
    repeat: { ...selectedfrequency?.repeat, duration: parseInt(duration ?? "0"), durationUnit: durationUnit?.code },
  }

  const defaultTimingFreq = {
    code: { coding: [{ code: "QD", system: SYSTEM_VALUES.V3_GTSABB }] },
    repeat: { periodUnit: durationCodeOptions[3].value.code, ...frequency.repeat },
  } as Timing

  return frequency ?? defaultTimingFreq
}

const getMedicationIngredientInitialValues = (): MedicationIngredientFormData => ({
  name: "",
  unit: "",
  value: undefined,
})

const getRenewedMR = ({ ...medicationRequest }: MedicationRequestInfo) => {
  medicationRequest.priorPrescription = asReference(medicationRequest)
  const newDate = new Date().toISOString()
  medicationRequest.authoredOn = newDate
  medicationRequest.status = "draft"

  const duration = medicationRequest.dispenseRequest?.initialFill?.duration
  const interval = medicationRequest.dispenseRequest?.dispenseInterval?.value

  medicationRequest.dispenseRequest = {
    ...medicationRequest.dispenseRequest,
    nextRefillDate: newDate,
    validityPeriod: {
      start: newDate,
      ...(!interval
        ? {
            end: add(new Date(newDate), {
              [`${duration?.unit ?? "second"}s`]:
                (duration?.value ?? 0) * ((medicationRequest.dispenseRequest?.numberOfRepeatsAllowed ?? 0) + 1),
            }).toISOString(),
          }
        : {}),
    },
  }

  delete medicationRequest.id
  delete medicationRequest.prescriptionQuantity
  delete medicationRequest.dosages
  delete medicationRequest.administrationGuideline

  return medicationRequest
}

const getMedicationInitialValues = (medication: Medication | string = ""): MedicationFormData => {
  const isMedicationType = isMedication(medication)
  const medicationCodeText = isMedicationType ? medication.code?.text : medication
  const medicationFormCoding = isMedicationType ? medication.form?.coding : undefined
  const medicationIngredient = isMedicationType ? medication.ingredient : undefined
  const medicationAmountValue = isMedicationType ? medication.amount?.numerator?.value : undefined
  const medicationAmountUnit = isMedicationType ? medication.amount?.numerator?.unit : undefined

  return {
    resourceType: "Medication",
    code: { text: medicationCodeText },
    status: "active",
    form: { coding: medicationFormCoding },
    ingredient: medicationIngredient,
    newIngredient: null,
    amount: { numerator: { value: medicationAmountValue, unit: medicationAmountUnit } },
  }
}

const getTreatmentFrequency = (dosage?: Dosage) => {
  let treatmentFrequency = "oncePerDay"
  if (dosage?.timing?.repeat?.period === 1) {
    if (dosage?.timing?.repeat?.periodUnit === "d") {
      switch (dosage?.timing?.repeat.frequency) {
        case 2:
          treatmentFrequency = "twicePerDay"
          break
        case 3:
          treatmentFrequency = "threePerDay"
          break
        case 4:
          treatmentFrequency = "fourPerDay"
          break
      }
      switch (dosage?.timing?.repeat.when?.[0]) {
        case "MORN":
          treatmentFrequency = "everyMorning"
          break
        case "NIGHT":
          treatmentFrequency = "everyNight"
          break
      }
      return treatmentFrequency
    }
    if (dosage?.timing?.repeat?.periodUnit === "wk") {
      treatmentFrequency = "oncePerWeek"
      switch (dosage?.timing?.repeat.frequency) {
        case 2:
          treatmentFrequency = "twicePerWeek"
          break
        case 3:
          treatmentFrequency = "threePerWeek"
          break
        case 4:
          treatmentFrequency = "fourPerWeek"
          break
        case 5:
          treatmentFrequency = "fivePerWeek"
          break
        case 8:
          treatmentFrequency = "eightPerWeek"
          break
      }
      return treatmentFrequency
    }
    if (dosage?.timing?.repeat?.periodUnit === "mo") {
      treatmentFrequency = "oncePerMonth"
      switch (dosage?.timing?.repeat.frequency) {
        case 5:
          treatmentFrequency = "fivePerMonth"
          break
        case 7:
          treatmentFrequency = "sevenPerMonth"
          break
        case 15:
          treatmentFrequency = "fifteenPerMonth"
          break
        case 25:
          treatmentFrequency = "twentyFivePerMonth"
          break
      }
      return treatmentFrequency
    }
  }
  if (
    dosage?.timing?.repeat?.period === 2 &&
    dosage?.timing?.repeat?.periodUnit === "d" &&
    dosage?.timing?.repeat.frequency === 1
  ) {
    return "everyOtherDay"
  }
}

const calculateTotalDoseQuantity = (dosages: MedicationDosage[]) =>
  parseFloat(dosages.reduce((acc, dosage) => acc + calculateDoseQuantity(dosage), 0).toFixed(2))

const calculateAmountQuantity = (totalDoseQty: number, amount?: Quantity) => {
  const amountValue = amount?.value ?? 1
  return Math.ceil(totalDoseQty / amountValue)
}

const calculateDoseQuantity = (orientedDosage: MedicationDosage) => {
  const dosage = sanitizeDosage(orientedDosage)
  const doseQuantity = dosage?.doseAndRate?.[0]?.dose?.Quantity?.value ?? 0
  const dosageRepeats = dosage?.timing?.repeat?.frequencyMax ?? dosage?.timing?.repeat?.frequency ?? 1
  const dosagePeriod = dosage?.timing?.repeat?.periodMax ?? dosage?.timing?.repeat?.period ?? 1
  const dosagePeriodUnit = dosage?.timing?.repeat?.periodUnit ?? "d"
  const dosageDuration = dosage?.timing?.repeat?.duration ?? 1
  const dosageDurationUnit = dosage?.timing?.repeat?.durationUnit ?? "wk"

  const units =
    (doseQuantity * dosageRepeats * dosageDuration * unitToDays(dosageDurationUnit)) /
    (dosagePeriod * unitToDays(dosagePeriodUnit))

  return units
}

const calculateDosageDispenseInterval = (dosages: MedicationDosage[]) => {
  const totalDays = dosages.reduce(
    (prev, { doseTiming }) => prev + (doseTiming?.repeat?.duration ?? 0) * unitToDays(doseTiming?.repeat?.durationUnit),
    0,
  )
  const totalMonths = Math.floor(totalDays / UNIT_DAYS.MONTH)

  return (
    commonsDispenseInterval.find(({ value: { value } }) => totalMonths <= (value ?? 0))?.value ??
    commonsDispenseInterval.at(-1)?.value
  )
}

const getGuidelineLabel = (guideline: MedicationKnowledgeAdministrationGuidelinesArray) =>
  (guideline.dosage?.flatMap(({ dosage }) => dosage?.map(({ text }) => text ?? "") ?? []) ?? []).join(", ")

const getMedicationFormData = ({
  medicationKnowledge,
  medicationRequestInfo,
}: MedicationRequestData): MedicationRequestFormData => {
  return {
    ...medicationRequestInfo,
    medicationKnowledge,
    medicationField: {
      ...(medicationKnowledge ?? (medicationRequestInfo.contained?.[0] as Medication)),
      textDisplayedInField: medicationKnowledge
        ? getMKDisplayText(medicationKnowledge)
        : (medicationRequestInfo.contained?.[0] as Medication)?.code?.text,
    },
    prescriptionQuantity: { ...medicationRequestInfo.dispenseRequest?.quantity },
  }
}

const getMedicationKnowledgeSku = (mk: MedicationKnowledge) =>
  mk.code?.coding?.find(({ system }) => system === SYSTEM_VALUES.SKU)?.code

const getDispenseFrequency = (interval: Duration | undefined) => {
  const dispense =
    interval &&
    commonsDispenseInterval.find(
      ({ value: { code, system, unit, value } }) =>
        interval?.system === system && interval?.code === code && interval?.value === value && interval?.unit === unit,
    )

  return dispense
}

const getMKDispenseLimit = (mk?: MedicationKnowledge) => {
  const maxDispense = mk?.regulatory?.find(
    (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DISPENSE,
  )?.maxDispense?.quantity?.value
  const amount = mk?.amount?.value

  return maxDispense && amount ? Math.ceil(maxDispense / amount) : undefined
}

const getPractitionersInfoWithNPIAndLifefileId = (practitionersInfo: PractitionerInfo[]) => {
  const practionersInfoWithValidLifefileId = practitionersInfo.filter(({ practitionerRole, practitioner }) => {
    const practNPIIdentifier = practitioner?.identifier?.find(({ system }) => system === SYSTEM_VALUES.NPI)
    const hasNPIIdentifier = !!practNPIIdentifier?.value
    const hasLifefileId = Boolean(
      practitionerRole?.identifier?.some(({ system }) => system === SYSTEM_VALUES.LIFEFILE_PRACTITIONER),
    )

    return hasNPIIdentifier && hasLifefileId
  })

  return practionersInfoWithValidLifefileId
}

export {
  calculateAmountQuantity,
  calculateDosageDispenseInterval,
  calculateDoseQuantity,
  calculateTotalDoseQuantity,
  getDispenseFrequency,
  getDosageInitialValues,
  getGuidelineLabel,
  getInitialValues,
  getMedicationFormData,
  getMedicationIngredientInitialValues,
  getMedicationInitialValues,
  getMedicationKnowledgeSku,
  getMKDispenseLimit,
  getPractitionersInfoWithNPIAndLifefileId,
  getRenewedMR,
  getTimingFrequency,
  getTreatmentFrequency,
}
