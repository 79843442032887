import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationUnreadMessagesCount = (currentOrganizationId: string) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.unreadMessagesCount

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress",
        category: "message",
        _count: "0",
        _elements: "id",
      })

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Communication`, filters, signal })

      return { count: bundle.total ?? 0 }
    },
    meta: { context: { queryKey } },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  })

  return {
    unreadOrganizationMessagesCount: data?.count ?? 0,
    isLoading,
  }
}

export { useOrganizationUnreadMessagesCount }
