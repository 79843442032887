import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type FormikProps, type FormikTouched, type FormikValues, Form, Formik, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from "react"

import { Button } from "../components/Buttons"
import { useReplaceFormContext } from "../hooks"

import type { FieldErrorType } from "../types"
import { getFieldError } from "../utils"
import type { FormContainerProps } from "./types"

const FormContainerContext = createContext<FormContainerContextProps<FormikValues> | undefined>(undefined)
FormContainerContext.displayName = "FormContainerContext"

const FormContainer = <T extends FormikValues>({
  children,
  onSubmit,
  mutable,
  cancelButtonLabel,
  closeButton,
  onClose,
  showCloseIcon = true,
  initialTouched,
  ...props
}: FormContainerProps<T>) => {
  const [submitHandler, setSubmitHandler] = useState<(data: T) => boolean | Promise<boolean>>()
  const [formState, setFormState] = useState<UpdateableFormProps<T>>(props)

  const context = useReplaceFormContext<T>()

  const renderReplacementContent = useMemo(
    () => context?.replacementContent?.showForm ?? false,
    [context?.replacementContent?.showForm],
  )

  const onCloseReplacementContent = useCallback(() => {
    context?.toogleShowReplacementContent?.()
    context?.replacementContent?.onCancel()
  }, [context])

  const onCloseForm = useCallback(
    () => (renderReplacementContent ? onCloseReplacementContent() : onClose?.() ?? props.onCancel?.()),
    [renderReplacementContent, onCloseReplacementContent],
  )

  const effectiveProps = mutable ? { ...formState } : { ...formState, ...props }

  const updateSubmitHandler = (onSubmit: (data: T) => boolean | Promise<boolean>) => setSubmitHandler(() => onSubmit)

  const renderContent = useCallback(
    (replacement: boolean = false, hide?: boolean) => {
      const {
        onSubmit: onFormSubmit,
        customSaveButton,
        initialValue,
        saveLabel = "Save",
        validationSchema,
        children: formChildren,
        onCancel,
        title,
        hideButtonsDivider,
        subTitle,
        headerClassName = "px-4 sm:px-6 py-6",
        innerContainerClassName = "px-4 sm:px-6 space-y-6 pb-6",
        showCancel = true,
        showSave = true,
        disableSave,
        footerClassName,
        className,
        ...formikProps
      } = replacement
        ? context?.replacementContent ?? { ...effectiveProps, children, onSubmit }
        : { ...effectiveProps, children, onSubmit }

      return (
        <Formik
          key={`formik-${replacement ? "replacement" : "default"}-instance`}
          initialValues={initialValue as T}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
          {...formikProps}
        >
          {(formikProps: FormikProps<T>) => {
            const setNestedTouched = (fields: FormikValues) => {
              return Object.keys(fields).reduce((acc, key) => {
                if (typeof fields[key] === "object" && fields[key] !== null) {
                  acc[key] = setNestedTouched(fields[key])
                } else {
                  acc[key] = true
                }
                return acc
              }, {} as FormikTouched<FormikValues>)
            }

            const validate = async () => {
              const fieldsTouched = setNestedTouched(formikProps.values)
              formikProps.setTouched(fieldsTouched as FormikTouched<T>)
              const validationResult = await formikProps.validateForm()

              return validationResult
            }

            return (
              <Form
                className={classNames("divide-gray-200 flex flex-col h-full grow", className, {
                  "divide-y": !hideButtonsDivider,
                  hidden: hide,
                })}
                aria-autocomplete="none"
                autoComplete="off"
              >
                <div className="flex flex-1 flex-col overflow-hidden">
                  {(title || subTitle) && (
                    <div className={headerClassName}>
                      {title && (
                        <div className="flex flex-1 justify-between">
                          {!replacement ? (
                            <h6 className="font-semibold leading-6">{title}</h6>
                          ) : (
                            <div className="inline-flex space-x-3 font-semibold items-center">
                              <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="cursor-pointer hover:text-primary-hover"
                                onClick={onCloseForm}
                              />
                              <h6 className="font-semibold leading-6">{title}</h6>
                            </div>
                          )}
                          {showCloseIcon && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="lg"
                              title="Close"
                              className="hover:bg-primary-hover/10 rounded-full p-1 px-1.5 text-primary focus:outline-primary cursor-pointer"
                              onClick={onCloseForm}
                            />
                          )}
                        </div>
                      )}
                      {subTitle && <p className="text-gray-300 text-sm mt-1">{subTitle}</p>}
                    </div>
                  )}
                  <div className="flex flex-1 flex-col overflow-y-auto">
                    <div className={innerContainerClassName}>
                      {typeof formChildren === "function" ? formChildren(formikProps) : formChildren}
                    </div>
                  </div>
                </div>
                <div className={classNames("flex flex-shrink-0 justify-end gap-3 px-4 py-4", footerClassName)}>
                  {closeButton}
                  {showCancel && (
                    <Button
                      label={cancelButtonLabel ?? "Close"}
                      buttonStyle="default"
                      size="lg"
                      disabled={formikProps.isSubmitting}
                      onClick={onCancel}
                    />
                  )}
                  {customSaveButton
                    ? typeof customSaveButton === "function"
                      ? customSaveButton({
                          validate,
                          isSubmitting: formikProps.isSubmitting,
                          values: formikProps.values as T,
                        })
                      : customSaveButton
                    : showSave && (
                        <Button
                          label={saveLabel}
                          size="lg"
                          loading={formikProps.isSubmitting}
                          disabled={formikProps.isSubmitting || disableSave}
                          onClick={async () => {
                            const continueSubmitting = (await submitHandler?.(formikProps.values)) ?? true
                            continueSubmitting && formikProps.submitForm()
                          }}
                        />
                      )}
                </div>
                <ScrollToError />
              </Form>
            )
          }}
        </Formik>
      )
    },
    [effectiveProps, context?.replacementContent],
  )

  return (
    <FormContainerProvider<T>
      mutable={mutable ?? false}
      handleSubmit={updateSubmitHandler}
      restoreForm={() => {
        setSubmitHandler(undefined)
        setFormState(props)
      }}
      updateFormState={(newState: UpdateableFormProps<T>) => setFormState({ ...formState, ...newState })}
    >
      {renderReplacementContent && renderContent(true)}
      {renderContent(false, renderReplacementContent)}
    </FormContainerProvider>
  )
}

const ScrollToError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating && errors) {
      const fields = Object.entries(errors) as FieldErrorType[]

      if (fields.length > 0) {
        const fieldError = getFieldError(fields)

        const selector = `[name^='${fieldError}'], [id^='errorMessage.${fieldError}']`
        const errorElement = document.querySelector(selector) as HTMLElement

        if (errorElement) errorElement.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [errors, isSubmitting, isValidating])
  return null
}

const FormContainerProvider = <T extends FormikValues>({
  children,
  ...rest
}: PropsWithChildren<FormContainerContextProps<T>>) => {
  return <FormContainerContext.Provider value={{ ...rest }}>{children}</FormContainerContext.Provider>
}

type FormContainerContextProps<T extends FormikValues> = {
  mutable: boolean
  handleSubmit(onSubmit: (data: T) => boolean | Promise<boolean>): void
  restoreForm(): void
  updateFormState(newState: UpdateableFormProps<T>): void
}

type UpdateableFormProps<T extends FormikValues> = Omit<
  FormContainerProps<T>,
  "onSubmit" | "children" | "initialValue" | "initialTouched"
> & {
  initialValue?: FormikValues
}

export { FormContainer, FormContainerContext }
