import { useQuery } from "@tanstack/react-query"

import { displayNotificationError } from "errors"

import type { CustomError } from "../types"

const useSmartyAddressAutoCompletion = (
  searchParams?: { searchText?: string; selected?: string },
  showAutoComplete = true,
) => {
  const referer = window.location.origin
  const host = "us-autocomplete-pro.api.smarty.com"
  const params = new URLSearchParams({
    key: window.VITE_APP_SMARTY_API_KEY,
    ...(searchParams?.searchText ? { search: searchParams.searchText } : {}),
    ...(searchParams?.selected ? { selected: searchParams.selected } : {}),
    source: "all",
  })

  const { uri, headers } = {
    headers: {
      host,
      referer,
    },
    uri: `https://${host}/lookup?${params.toString()}`,
  }

  const { refetch, isLoading, isFetching, data } = useQuery<SmartyAutoCompleteQueryData | undefined>({
    queryKey: ["smarty/addressAutoCompletion", searchParams, showAutoComplete],
    queryFn: async () => {
      const response = await fetch(uri, {
        headers,
      })

      if (response.ok) {
        const data: SmartyAutoCompleteQueryData = await response.json()
        return data
      }

      displayNotificationError({
        cause: { name: "Smarty Address AutoCompletion Error", message: "Error fetching address suggestions." },
      } as CustomError)
    },
    enabled: !!searchParams?.searchText && showAutoComplete,
  })

  return {
    autoComplete: refetch,
    suggestions: data?.suggestions ?? [],
    isAutoCompleting: isLoading || isFetching,
  }
}

export interface SmartyAutoCompleteQueryData {
  suggestions: SuggestionsEntity[]
}

export interface SuggestionsEntity {
  street_line: string
  secondary: string
  city: string
  state: string
  zipcode: string
  entries: number
  source: string
}

export { useSmartyAddressAutoCompletion }
