import { useBasisTheory } from "@basis-theory/basis-theory-react"

const btApiKey = window.VITE_APP_BASIS_THEORY_API_KEY

const useBasisTheoryInstance = () => {
  const { bt, error } = useBasisTheory(btApiKey, { elements: true, disableTelemetry: true })

  if (error) {
    throw new Error(error.message)
  }

  return { bt }
}

export { useBasisTheoryInstance }
