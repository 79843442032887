import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  type Bundle,
  type CarePlan,
  type CarePlanActivityArray,
  type Observation,
  type Reference,
  asBundleEntry,
  asBundleEntryArray,
  asDeleteBundleEntryArray,
  getBundleEntryReference,
} from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess, getCodingBySystem } from "utils"

import type { CustomError } from "../../types"
import { plansQueryKeys } from "../query-keys"

const useUpdateAdditionalLabData = (onSuccess?: () => void, onSettled?: () => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const newLabData = async ({
    carePlan,
    patientRef,
    newObs,
    oldObs,
    drId,
  }: {
    carePlan: CarePlan
    patientRef: Reference
    newObs: Observation[]
    oldObs?: Observation[]
    drId?: string
  }) => {
    const obsEntries = asBundleEntryArray(newObs)
    const deleteObsEntries = asDeleteBundleEntryArray(oldObs)
    const drEntry = asBundleEntry({
      id: drId,
      resourceType: "DiagnosticReport",
      category: [
        {
          coding: [
            {
              system: SYSTEM_VALUES.V2_0074,
              code: "LAB",
              display: "Laboratory",
            },
          ],
          text: "Laboratory",
        },
      ],
      subject: patientRef,
      code: {
        text: "Entered",
        coding: [
          {
            system: SYSTEM_VALUES.TEMPORARY_CODES,
            code: "entered",
            display: "Entered",
          },
        ],
      },
      status: "final",
      issued: new Date(),
      result: obsEntries.map((entry) => getBundleEntryReference(entry)),
    })
    const activity =
      carePlan.activity?.reduce<CarePlanActivityArray[]>((acc, activity) => {
        if (getCodingBySystem(activity.outcomeCodeableConcept, SYSTEM_VALUES.CARE_PLAN_OUTCOME)?.code === "lab-order") {
          const refs = activity.outcomeReference?.filter((r) => r.id !== drId) ?? []
          return [...acc, { ...activity, outcomeReference: [...refs, getBundleEntryReference(drEntry)] }]
        } else return [...acc, activity]
      }, []) ?? []
    const cpEntry = asBundleEntry(carePlan, { activity })

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [...obsEntries, ...deleteObsEntries, drEntry, cpEntry],
    }
    return transaction(bundle)
  }

  const { mutateAsync: updateLabData, isPending } = useMutation({
    mutationFn: newLabData,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { carePlan }) => {
      await queryClient.invalidateQueries({
        queryKey: plansQueryKeys.details(carePlan?.subject?.id as string, carePlan?.id),
      })
      displayNotificationSuccess("Additional Lab Data updated successfully!")
      onSuccess?.()
    },
    onSettled,
  })

  return { updateLabData, isPending }
}

export { useUpdateAdditionalLabData }
