import { type IconDefinition, faMemoCircleInfo, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { datadogRum } from "datadog"
import { format } from "date-fns/format"
import { getFirstEmail, humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"

import { formatsByTypes } from "data"
import { intercomInit, intercomTrackEvent, intercomUpdate } from "intercom"
import { useAuth, useLoginContext, useUserHash } from "security"

import { AvatarImage } from "./AvatarImage"

const UserProfileMenu = ({ actions, avatarClassName = "h-20 w-20", className, orgUrl }: Props) => {
  const { logout, user } = useAuth()

  const { loggedInPractitioner, loggedInPractitionerId } = useLoginContext()
  const { name, photo, telecom, gender } = loggedInPractitioner ?? {}
  const [userHmac, setUserHmac] = useState<string>()

  const op = useRef<OverlayPanel>(null)
  const email = getFirstEmail(telecom)

  const logoutAction: ActionType = { label: "Logout", icon: faSignOut, command: logout }
  const seePolicyAction: ActionType = {
    label: "Policy & Terms",
    icon: faMemoCircleInfo,
    command: () => window.open("https://www.chartedhealth.com/156-2/", "_blank", "noreferrer"),
  }

  const { getIntercomHMac } = useUserHash((hmac) => {
    setUserHmac(hmac)

    if (user) {
      intercomInit({
        userName: user.name,
        userId: user.b2cUserId,
        email: user.email,
        userHash: hmac,
        sessionReplayLink: datadogRum.getSessionReplayLink(),
        orgUrl,
      })
      intercomTrackEvent({
        user_id: user.b2cUserId,
        email: user.email,
        event: "session-started",
        metadata: {
          session: {
            url: datadogRum.getSessionReplayLink() ?? "not available",
            value: format(new Date(), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
          },
        },
      })
    }
  })

  useEffect(() => {
    if (user?.b2cUserId && orgUrl) getIntercomHMac(user.b2cUserId)
  }, [user?.b2cUserId, orgUrl])

  return (
    <div className={classNames("w-full relative", className)} onClick={(event) => op?.current?.toggle(event)}>
      <div className="flex ease-in-out items-center">
        <span className="sr-only">Open user menu</span>

        <AvatarImage
          name={humanNameAsString(name?.[0])}
          photoUrl={photo?.[0]?.url}
          className={classNames("rounded-full", avatarClassName)}
          onImageLoaded={
            userHmac
              ? (url) => {
                  if (user) {
                    intercomUpdate({
                      user_id: user?.b2cUserId,
                      email,
                      avatar: { type: "avatar", image_url: url },
                      practitioner_id: loggedInPractitionerId,
                      name: humanNameAsString(name?.[0]),
                      gender,
                    })
                  }
                }
              : undefined
          }
        />
      </div>

      <OverlayPanel ref={op} dismissable className="w-auto h-auto z-20">
        <div className="flex flex-col">
          <div className="flex items-center">
            <AvatarImage
              name={humanNameAsString(name?.[0])}
              photoUrl={photo?.[0]?.url}
              className="rounded-full h-12 w-12"
            />
            <div className="ml-3">
              <p className="font-semibold">{user?.name}</p>
              <p className="text-gray-500">{user?.email}</p>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <hr className="my-3" />
            {[...(actions ?? []), seePolicyAction, logoutAction].map(({ command, icon, label }) => (
              <Button
                key={label}
                onClick={() => {
                  op.current?.hide()
                  setTimeout(() => command(), 200)
                }}
                className="text-xs h-8 button-default"
              >
                <div className="flex justify-center items-center flex-grow font-semibold">
                  <FontAwesomeIcon icon={icon} className="mr-2" />
                  <span>{label}</span>
                </div>
              </Button>
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  )
}

export type ActionType = { label: string; icon: IconDefinition; command(): void }

type Props = {
  avatarClassName?: string
  actions?: ActionType[]
  className?: string
  orgUrl?: string
}

export { UserProfileMenu }
