import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { type Appointment, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { getAppointmentType } from "appointments/utils"
import { patientAptsQueryKeys } from "../query-keys"

const useScheduledAppointment = (patientId: string) => {
  const { search } = useClient()
  const queryKey = patientAptsQueryKeys.scheduled(patientId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "booked,pending,proposed",
        date: `gt${format(new Date(), formatsByTypes.ISO_8601_DATE)}`,
        _elements: "id,start,end,description,participant",
        _count: "1",
        _sort: "date",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Appointment`, filters, signal })
      const scheduledAppointment = getResources<Appointment>(bundle)[0]

      return { scheduledAppointment }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { scheduledAppointment } = useMemo(() => {
    const scheduledAppointment = data?.scheduledAppointment
    if (scheduledAppointment) {
      const appointmentType = getAppointmentType(scheduledAppointment)
      const start = scheduledAppointment?.start
        ? formatDate(new Date(scheduledAppointment.start.toString()), formatsByTypes.LONG_DATETIME)
        : "No date"
      return { scheduledAppointment: `${appointmentType} - ${start}` }
    } else return { scheduledAppointment: undefined }
  }, [data?.scheduledAppointment])

  return {
    scheduledAppointment: scheduledAppointment,
    appointmentsLoading: isLoading,
    reloadScheduledAppointments: refetch,
  }
}

export { useScheduledAppointment }
