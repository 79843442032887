import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Bundle, MedicationRequest } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useCreateMRs = (onSettled: () => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newMRs = async (mrs: MedicationRequest[]) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: mrs.map((mr) => ({
        request: {
          method: "POST",
          url: "MedicationRequest",
        },
        resource: mr,
      })),
    }
    return await transaction<Bundle>(bundle)
  }

  const { mutate: createMRs, isPending } = useMutation({
    mutationFn: newMRs,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      await queryClient.invalidateQueries({
        queryKey: medsQueryKeys.draft.list(patientId, "nutraceutical"),
        refetchType: "all",
      })
      displayNotificationSuccess("Prescription added successfully!")
      // datadogLogs.logger.info("Prescription added successfully!", { mrs })
    },
    onSettled,
  })

  return { createMRs, isCreating: isPending }
}

export { useCreateMRs }
