import type { FC } from "react"
import { type Encounter, isHealthcareService, isLocation, isPatient, isPractitioner, type Reference } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faCalendarDay, faLaptopMedical, faUser, faUserDoctor } from "@fortawesome/pro-regular-svg-icons"
import { format } from "date-fns/format"
import { useNavigate } from "react-router-dom"

import { getAppointmentType, useAppointment } from "appointments"
import { formatsByTypes } from "data"
import { DialogFormContainer, SkeletonLoader } from "commons"
import { useOrganizationContext } from "organization"

import { getInitialValues, sanitize, validationSchema } from "./validations"
import { EncounterForm } from "./EncounterForm"
import { useCreateEncounter } from "../hooks"

const EncounterConfig: FC<Props> = ({ patientId, patientRef, visible = false, onHide, appointmentId }) => {
  const { loggedInPractitionerRoleRef, currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()

  const redirectToPatient = () => {
    navigate(`/orgs/${currentOrganizationId}/patients/${patientId}`)
  }

  const { appointment, isLoading } = useAppointment(patientId, appointmentId ?? undefined)

  const { createEncounter } = useCreateEncounter(patientId, onHide, redirectToPatient)

  const handleStartEncounter = (encounter: Encounter) => {
    createEncounter(sanitize(encounter))
  }

  const appointmentData = appointmentId ? appointment : undefined

  if (isLoading) return <SkeletonLoader loaderType="form-two-cols" />

  return (
    <DialogFormContainer
      initialValue={getInitialValues({
        patientRef,
        appointment: appointmentData,
        practirionerRoleRef: loggedInPractitionerRoleRef,
      })}
      onSubmit={handleStartEncounter}
      enableReinitialize
      onCancel={onHide}
      title="Configure encounter"
      validationSchema={validationSchema}
      saveLabel="Start"
      showForm={visible}
      className="min-w-96 lg:!w-fit"
      appendTo={undefined}
      supportingInfoClassName="w-fit"
      supportingInfo={
        appointmentData && (
          <div className="right-menu min-w-[18rem] w-1/3 content-start overflow-y-auto h-full self-end">
            <div className="bg-white flex-1 justify-around flex flex-col mb-5 pl-3">
              <div className="items-center flex justify-start bg-slate-100 font-medium p-2">
                <FontAwesomeIcon icon={faCalendarDay} size="1x" className="mr-1" />
                <span>Appointment</span>
              </div>
              <div className="text-gray-500 font-normal text-sm tracking-tight pl-3 pt-2.5 mb-2 flex flex-col">
                <span className="flex">
                  Type: <p className="ml-1">{getAppointmentType(appointmentData, appointmentData.description)}</p>
                </span>
                {appointmentData.start && (
                  <span className="flex">
                    Start:
                    <p className="ml-1">{format(appointmentData.start, formatsByTypes.LONG_DATETIME)}</p>
                  </span>
                )}
                {appointmentData.end && (
                  <span className="flex">
                    End:
                    <p className="ml-1">{format(appointmentData.end, formatsByTypes.LONG_DATETIME)}</p>
                  </span>
                )}
                {appointmentData.text && (
                  <span>
                    Notes:
                    <p className="ml-1 line-clamp-6 text-ellipsis">{appointmentData.text?.div}</p>
                  </span>
                )}
              </div>
              <div className="bg-slate-100 justify-start font-medium p-2 mb-2">Participants</div>
              {!!appointmentData.participant.length && (
                <div className="flex flex-col gap-1 pl-3 text-gray-500">
                  {appointmentData.participant
                    .filter(({ actor }) => !!actor?.display && !isHealthcareService(actor))
                    .map(({ actor }, index) => (
                      <span
                        key={`${actor?.id}_${index}`}
                        title={actor?.resourceType}
                        className="text-sm flex items-center"
                      >
                        <FontAwesomeIcon
                          icon={
                            isLocation(actor)
                              ? faBuilding
                              : isPractitioner(actor)
                                ? faUserDoctor
                                : isPatient(actor)
                                  ? faUser
                                  : faLaptopMedical
                          }
                          size="sm"
                          className="mr-1"
                        />
                        {actor?.display}
                      </span>
                    ))}
                </div>
              )}
            </div>
          </div>
        )
      }
    >
      <EncounterForm className="w-full pl-2" />
    </DialogFormContainer>
  )
}

type Props = {
  visible?: boolean
  patientId: string
  patientRef: Reference
  appointmentId?: string
  onHide(): void
}

export { EncounterConfig }
