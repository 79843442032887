import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

const useDeleteDevice = (onSuccess?: () => void, onSettled?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const deleteDevice = async (deviceId: string) => remove("Device", deviceId)

  const { mutate: removeDevice, isPending: isDeleting } = useMutation({
    mutationFn: deleteDevice,
    onError: (error: CustomError, deviceId) => {
      displayNotificationError(registerErrorTrace(error, { deviceId }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.currentOrganization.all })
      displayNotificationSuccess("Device deleted successfully!")
      // datadogLogs.logger.info("Device deleted successfully!")
      onSuccess?.()
    },
    onSettled,
  })

  return { removeDevice, isDeleting }
}

export { useDeleteDevice }
