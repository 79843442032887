import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { StackedListContainer } from "commons"
import { PLAN_SUBVIEWS, planItemModel } from "commons/care-plans"

import { WrapUpSection } from "./WrapUpSection"
import { useEncounterContext } from "../../hooks"

const PlansSection: FC<Props> = ({ icon, hideSpacer }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { encounterData, totalPlans: total } = useEncounterContext()

  const showPlanDetails = (id: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: "plans",
      subview: PLAN_SUBVIEWS.DETAILS,
      planId: id,
    })
    setSearchParams(params)
  }

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No treatment plans activated during this encounter"
      sectionTitle="Treatment Plans"
      className="flex flex-row gap-x-16 w-full text-sm"
      useSpacer={!hideSpacer}
    >
      <StackedListContainer
        data={encounterData?.plans ?? []}
        itemModelBuilder={(item) => ({
          ...planItemModel({
            plan: item,
            isLoading: false,
            viewPlan: () => showPlanDetails(item.carePlan.id as string),
            contextLabel: "Treatment Plans",
            showConfig: false,
          }),
          badge: undefined,
        })}
        className="w-full"
        itemsClassName="!pl-0 py-4"
      />
    </WrapUpSection>
  )
}

type Props = {
  icon: IconDefinition
  hideSpacer?: boolean
}

export { PlansSection }
