import { type Bundle, type Coverage, getResources } from "fhir"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { useClient } from "api"

import { coverageQueryKeys } from "../query-key"

const useRPLinkedCoverages = (patientId: string, relatedPeople: string[]) => {
  const { transaction } = useClient()
  const queryKey = coverageQueryKeys.rpLinkeds(patientId, relatedPeople)

  const { data, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _elements: "subscriber",
        subscriber: relatedPeople.join(","),
      })

      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: [
          {
            request: {
              method: "GET",
              url: `Patient/${patientId}/Coverage?${filters}`,
            },
          },
        ],
      }
      const respBundle = await transaction(reqBundle)
      const bundle = (respBundle as Bundle).entry?.[0]?.resource as Bundle

      const coverages = getResources<Coverage>(bundle, "Coverage")

      return { coverages }
    },
    enabled: relatedPeople.length > 0,
    meta: { context: { queryKey, patientId } },
  })

  const rpLinkedCoverages = useMemo(() => {
    return data?.coverages.reduce<string[]>((acc, coverage) => [...acc, coverage.subscriber?.id as string], []) ?? []
  }, [data?.coverages])

  return {
    isLoadingRPLink: isFetching,
    rpLinkedCoverages,
  }
}

export { useRPLinkedCoverages }
