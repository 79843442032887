import { faDiagnoses, faFileImport } from "@fortawesome/pro-regular-svg-icons"
import { type Bundle, type BundleEntryArray, codeableConceptAsString } from "fhir"
import { Column } from "primereact/column"
import type { DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { useEffect, useId, useMemo, useState } from "react"

import { type SkeletonColumn, EmptyMessage, FooterActions, InfiniteScroll, SkeletonLoader } from "commons"
import { usePatientContext } from "patients"
import { strCapitalize } from "utils"

import { usePatientMedicationStatements, usePreviewPatientInfo, useTransferPatientInfo } from "../hooks"
import { ObservationsTable } from "./ObservationsTable"
import { Patient360ImportDataDialog } from "./Patient360ImportDataDialog"

const Patient360MedicationStatements = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const { medicationStatementsEntries, isLoading, hasNextPage, fetchNextPage, reloadObservations } =
    usePatientMedicationStatements(patientId)
  const [showPreviewDialog, setShowPreviewDialog] = useState(false)
  const [previewMedicationStatementsBundle, setPreviewMedicationStatementsBundle] = useState<Bundle>()
  const previewMedicationStatementsEntries = useMemo(
    () => previewMedicationStatementsBundle?.entry ?? [],
    [previewMedicationStatementsBundle],
  )
  const hasPreviewObservations = previewMedicationStatementsEntries.length > 0

  const [selectedEntries, setSelectedEntries] = useState<BundleEntryArray[]>([])

  const handleSelectionChange = (e: DataTableSelectionMultipleChangeEvent<BundleEntryArray[]>) => {
    const observationsEntries: BundleEntryArray[] = e.value
    setSelectedEntries(observationsEntries)
  }

  const { previewPatientInfo: previewMedicationStatement, isLoadingPreview } = usePreviewPatientInfo(
    "medication-statements",
    (bundle) => {
      setPreviewMedicationStatementsBundle(bundle)
      setShowPreviewDialog(true)
    },
  )
  const { transferPatient, isTransfering } = useTransferPatientInfo(() => {
    reloadObservations?.()
    setShowPreviewDialog(false)
  })

  const handlePreview = () => {
    previewMedicationStatement({ patId: patientId })
  }

  const handleTransfer = () => {
    transferPatient({ ...(previewMedicationStatementsBundle as Bundle), entry: selectedEntries })
  }

  const handleClosePreviewDialog = () => {
    setShowPreviewDialog(false)
  }

  useEffect(() => {
    setSelectedEntries(previewMedicationStatementsEntries)
  }, [previewMedicationStatementsEntries])

  const columnsDef: SkeletonColumn[] = [{ header: "Medication", headerStyle: { minWidth: "5rem" } }]

  const hasUsableAddress = useMemo(() => {
    return patient.address?.some(({ use }) => !!use) ?? false
  }, [patient.address])

  if (isLoading) {
    return <SkeletonLoader repeats={4} containerClassName="pl-5 py-3" loaderType="table" columns={columnsDef} />
  }

  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      containerClassName="pl-5"
      loaderType="table"
      columns={columnsDef.map(({ headerStyle, body }) => ({
        header: undefined,
        headerStyle: headerStyle,
        headerClass: "hidden",
        body: body,
      }))}
    />
  )

  const addOptions = [
    {
      icon: faFileImport,
      label: "Import medication statements",
      command: () => handlePreview(),
      loading: isLoadingPreview,
      disabled: !hasUsableAddress,
    },
  ]

  return (
    <>
      {!medicationStatementsEntries?.length ? (
        <div className="h-full w-full flex items-center justify-center">
          <EmptyMessage
            icon={faDiagnoses}
            message="No medication statements found"
            subMessage={
              !hasUsableAddress
                ? "Get started by linking addresses to search"
                : "Get started by importing patient medication statements"
            }
            action={handlePreview}
            actionText="Import medication statements"
            actionIcon={faFileImport}
            isLoading={isLoadingPreview}
            disabled={!hasUsableAddress}
          />
        </div>
      ) : (
        <>
          <div className="h-full overflow-auto">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
              <ObservationsTable
                tableDataKey="resource.identifier.0.value"
                observationsEntries={medicationStatementsEntries ?? []}
                onSelectionChange={handleSelectionChange}
              >
                <Column
                  field="resource.medication"
                  header="Medication"
                  body={({ resource: { medication } }) => (
                    <p title="medication" className="font-bold mr-2">
                      {strCapitalize(codeableConceptAsString(medication?.CodeableConcept))}
                    </p>
                  )}
                  headerStyle={{ minWidth: "8rem" }}
                />
              </ObservationsTable>
            </InfiniteScroll>
          </div>
          {!showPreviewDialog && <FooterActions actions={addOptions} />}
        </>
      )}
      <Patient360ImportDataDialog
        showDialog={showPreviewDialog}
        handleCloseDialog={handleClosePreviewDialog}
        header="Medication Statements Preview"
        isLoading={isTransfering}
        actionDisabled={isTransfering || !hasPreviewObservations}
        handleDialogAction={handleTransfer}
        totalRecords={previewMedicationStatementsEntries.length}
      >
        <ObservationsTable
          tableDataKey="resource.identifier.0.value"
          observationsEntries={previewMedicationStatementsEntries}
          selectedEntries={selectedEntries}
          onSelectionChange={handleSelectionChange}
        >
          <Column
            field="resource.medication"
            header="Medication"
            body={({ resource: { medication } }) => (
              <p title="medication" className="font-bold mr-2">
                {strCapitalize(codeableConceptAsString(medication?.CodeableConcept))}
              </p>
            )}
            headerStyle={{ minWidth: "8rem" }}
          />
        </ObservationsTable>
      </Patient360ImportDataDialog>
    </>
  )
}

export { Patient360MedicationStatements }
