import { useInfiniteQuery } from "@tanstack/react-query"
import { type Invoice, type Patient, type ServiceRequest, type Task, getResources, isServiceRequest } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { ServiceRequestCategory, srCategoryCodes } from "data"
import { formatDate, getInvoicesInfo } from "utils"

import type { Order } from "../types"
import { getCategory, getOrderDetail } from "../utils"

const useOrganizationOrders = (
  organizationId: string,
  category?: string,
  patientId?: string,
  status?: string,
  authored?: Date,
  occurrence?: Date,
  searchText?: string,
) => {
  const { search } = useClient()
  const queryKey = ["org-orders", organizationId, category, patientId, status, searchText, authored, occurrence]

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<OrdersQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const statusFieldFilter = category === srCategoryCodes["lab-order"].code ? "orderDetail" : "status"

      const filters = new URLSearchParams({
        "subject:Patient.organization": organizationId,
        ...(searchText
          ? {
              _filter: `subject:Patient.termsearch eq ${searchText} or identifier eq ${searchText}`,
            }
          : {}),
        // ...(requester ? { "requester:text": requester } : {}),
        category: getCategory(category),
        ...(getOrderDetail(category) ? { orderDetail: getOrderDetail(category) } : {}),
        ...(status ? { [statusFieldFilter]: status } : {}),
        ...(patientId ? { subject: patientId } : {}),
        ...(authored ? { authored: formatDate(authored) } : {}),
        ...(occurrence ? { occurrence: formatDate(occurrence) } : {}),
        _count: "40",
        _page: `${pageParam}`,
        _sort: "-_authored",
        "reasonCode:not": "invalid-submission",
        _revinclude: "Task:focus",
        _include: "Task:depends-on:Task, Task:focus:Invoice, subject",
      })

      const bundle = await search({ endpoint: "ServiceRequest", filters, signal })

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const replacedOrderIds = serviceRequests.reduce(
        (acc, { replaces }) => [
          ...acc,
          ...(replaces ? replaces.filter(isServiceRequest).flatMap(({ id }) => id as string) : []),
        ],
        Array<string>(),
      )

      const replacedOrdersBundle = await search({
        endpoint: "ServiceRequest",
        filters: new URLSearchParams({
          id: replacedOrderIds.join(", "),
          _revinclude: "Task:focus",
          _include: "Task:depends-on:Task, Task:focus:Invoice",
        }),
      })

      const replacedOrders = getResources<ServiceRequest>(replacedOrdersBundle, "ServiceRequest")
      const replacedOrdersTasks = getResources<Task>(replacedOrdersBundle, "Task")
      const replacedOrdersInvoices = getResources<Invoice>(replacedOrdersBundle, "Invoice")

      const patients = getResources<Patient>(bundle, "Patient")

      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        serviceRequests,
        patients,
        next,
        total: bundle?.total ?? 0,
        tasks,
        invoices,
        replacedOrders,
        replacedOrdersInvoices,
        replacedOrdersTasks,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const { orders } = useMemo(() => {
    // Remove splitted lab orders until supported
    const srs =
      data?.pages
        .flatMap((page) => page.serviceRequests)
        ?.filter(({ category }) =>
          category?.every(({ coding }) => coding?.[0]?.code !== ServiceRequestCategory.LAB_ORDER_SPLIT),
        ) ?? []
    const indexedPatients =
      data?.pages
        ?.flatMap((page) => page.patients)
        .reduce<Record<string, Patient>>((acc, patient) => ({ ...acc, [patient.id as string]: patient }), {}) ?? {}
    const tasks = [
      ...(data?.pages?.flatMap((page) => page.tasks) ?? []),
      ...(data?.pages.flatMap((page) => page.replacedOrdersTasks) ?? []),
    ]

    const indexedInvoices =
      [
        ...(data?.pages?.flatMap((page) => page.invoices) ?? []),
        ...(data?.pages.flatMap((page) => page.replacedOrdersInvoices) ?? []),
      ].reduce<Record<string, Invoice>>((acc, invoice) => ({ ...acc, [invoice.id as string]: invoice }), {}) ?? {}

    const { taskByFocus, taskById } = tasks.reduce(
      (acc, task) => {
        const taskFocus = task.focus?.id as string

        return {
          ...acc,
          taskByFocus: { ...acc.taskByFocus, [taskFocus]: task },
          taskById: { ...acc.taskById, [task.id as string]: task },
        }
      },
      {
        taskByFocus: {} as Record<string, Task>,
        taskById: {} as Record<string, Task>,
      },
    )

    const orders = srs.reduce<Order[]>((acc, serviceRequest) => {
      const orderTask: Task | undefined = taskByFocus[serviceRequest.id as string]
      const invoiceTask: Task | undefined = taskById[orderTask?.dependsOn?.[0]?.id as string]

      // Handle replaced orders task searching fro edited orders with multiple invoices
      const replacedOrderTask: Task | undefined = taskByFocus[serviceRequest.replaces?.[0]?.id as string]
      const replacedInvoiceTask = taskById[replacedOrderTask?.dependsOn?.[0]?.id as string]

      const invoices: Invoice[] = [
        ...(indexedInvoices[invoiceTask?.focus?.id as string]
          ? [indexedInvoices[invoiceTask?.focus?.id as string]]
          : []),
        ...(indexedInvoices?.[replacedInvoiceTask?.focus?.id as string]
          ? [indexedInvoices[replacedInvoiceTask?.focus?.id as string]]
          : []),
      ]

      const { totalPrice } = getInvoicesInfo(invoices)

      return [
        ...acc,
        {
          serviceRequest,
          subject: {
            ...indexedPatients[serviceRequest.subject.id as string],
          },
          invoices,
          price: totalPrice && { ...totalPrice, value: totalPrice.value.toNumber() },
        },
      ]
    }, [])

    return {
      orders,
    }
  }, [data?.pages])

  return {
    orders,
    isLoading,
    count: orders.length,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type OrdersQueryData = {
  serviceRequests: ServiceRequest[]
  patients: Patient[]
  tasks: Task[]
  invoices: Invoice[]
  replacedOrders: ServiceRequest[]
  replacedOrdersTasks: Task[]
  replacedOrdersInvoices: Invoice[]
  next: number | undefined
  total: number
}

export { useOrganizationOrders }
