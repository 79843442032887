import type { Attachment } from "fhir"
import { type FieldProps, ErrorMessage, Field } from "formik"
import { FileUpload } from "primereact/fileupload"
import { classNames } from "primereact/utils"
import { type FC, useRef } from "react"

import { useAzureContainer } from "../hooks"

const ONE_MB = 1048576 // in bytes

const FileUploaderField: FC<Props> = ({
  field,
  autoupload = true,
  azureContainer,
  label,
  accept = "application/pdf",
  className,
  disabled,
  maxFileSize,
  labelClassName = "font-medium text-gray-700",
  validation,
}) => {
  const fileInput = useRef<FileUpload>(null)
  const clearFiles = () => fileInput.current?.clear()
  const { uploadFile } = useAzureContainer(azureContainer ?? "")

  const handleFileSelect =
    (name: string, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
    (event: { files: File[] }) => {
      const file = event.files[0]
      const { name: fileName, size: fileSize, type: fileType } = file
      if (autoupload)
        uploadFile({ file }).then((url) => {
          setFieldValue(name, { url, size: fileSize, title: fileName, contentType: fileType } as Attachment)
        })
      else setFieldValue(name, file)
    }

  return (
    <Field name={field} validation={validation}>
      {({
        field: { name },
        meta: { touched, error },
        form: { setFieldValue, setFieldError, setFieldTouched },
      }: FieldProps) => {
        const addValidationError = (f: File) => {
          if (maxFileSize && f.size > maxFileSize) {
            setFieldError(name, `Invalid file. Max file size is ${Math.floor(maxFileSize / ONE_MB)}MB.`)
          }

          setFieldTouched(name, true, false)
        }

        return (
          <div className={classNames("field flex flex-col relative", className)}>
            {label && (
              <label htmlFor={name} className={classNames("text-sm mb-2", labelClassName)}>
                {label}
              </label>
            )}
            <FileUpload
              id={name}
              name={name}
              accept={accept}
              maxFileSize={maxFileSize}
              disabled={disabled}
              customUpload
              ref={fileInput}
              uploadHandler={clearFiles}
              onSelect={handleFileSelect(name, setFieldValue)}
              onValidationFail={addValidationError}
              mode="basic"
              className={classNames("p-button-sm", { "p-invalid": touched && error })}
            />

            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  accept?: "application/pdf" | "image/*"
  className?: string
  disabled?: boolean
  maxFileSize?: number
  labelClassName?: string
  validation?: (val: File | Attachment | undefined) => string | undefined
} & OptionalUpload

type OptionalUpload =
  | {
      autoupload: true
      azureContainer: string
    }
  | { autoupload?: false; azureContainer?: string }

export { FileUploaderField }
