import type { Observation, QuestionnaireResponse } from "fhir"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"

import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { DropdownField } from "../../../forms/DropdownField"
import { useProcedureCalculator } from "../../hooks"
import type { ProcedureData, ProcedureTypeData } from "../../types"
import { getObservationInitialValue } from "../../utils/transformers"
import { QuestionnaireAndLabDataForm } from "./QuestionnaireAndLabDataForm"

const Calculator = ({ procTypes, selectedProcedureType, selectedQR, setDisableSave }: Props) => {
  const { loggedInPractitionerRoleRef } = useOrganizationContext()
  const { patientId, patientRef } = usePatientContext()

  const {
    setFieldValue,
    values: { labData, selectedDR },
  } = useFormikContext<ProcedureData>()
  const [selectedProcType, setSelectedProcType] = useState<ProcedureTypeData | undefined>(selectedProcedureType)

  const {
    isLoading,
    questionnaire,
    questionnaireResponses,
    observationRequestData,
    observationData,
    runAlgorithmCode,
    icd10,
    requiredOD,
    reloadData,
  } = useProcedureCalculator(patientId, selectedProcType?.id)

  const setProcType = (typeCanonical: string) => {
    const typeCode = procTypes.find((p) => p.canonical[0] === typeCanonical[0]) as ProcedureTypeData
    setFieldValue("procedure.code", typeCode.cc)
    setFieldValue("procedure.instantiatesCanonical", typeCode.canonical)
    setFieldValue("procedure.identifier", typeCode.identifier)

    setSelectedProcType(typeCode)
  }

  useEffect(() => {
    const obs = labData
      ? Object.entries(labData).reduce<Observation[]>((acc, [key, value]) => {
          if (!value) return acc
          const ivalue =
            selectedDR?.observations?.data?.[key] &&
            `${
              selectedDR?.observations?.data?.[key]?.value?.Quantity?.value ??
              selectedDR?.observations?.data?.[key]?.value?.string
            }`
          return [
            ...acc,
            ivalue === value
              ? (selectedDR?.observations?.data?.[key] as Observation)
              : getObservationInitialValue(
                  patientRef,
                  observationRequestData[key]?.code.coding ?? [],
                  value,
                  loggedInPractitionerRoleRef,
                ),
          ]
        }, [])
      : []
    setFieldValue("calcData", {
      observations: obs,
      runAlgorithmCode,
      icd10,
    })
  }, [selectedDR, labData, runAlgorithmCode, icd10])

  useEffect(() => {
    setDisableSave(!selectedProcType || isLoading)
  }, [isLoading, selectedProcType])

  return !procTypes.length ? (
    <SkeletonLoader repeats={4} loaderType="one-line" />
  ) : (
    <>
      <DropdownField
        field="procedure.instantiatesCanonical"
        label="Type"
        options={procTypes}
        optionLabel="cc.text"
        optionValue="canonical"
        handleChange={(e) => setProcType(e.value)}
        disabled={!!selectedProcedureType}
      />
      {selectedProcType && (
        <QuestionnaireAndLabDataForm
          {...{
            isLoading,
            questionnaire,
            questionnaireResponses,
            observationRequestData: Object.values(observationRequestData),
            observationData,
            selectedQR,
            reloadData,
            requiredOD,
          }}
        />
      )}
    </>
  )
}

type Props = {
  procTypes: ProcedureTypeData[]
  selectedProcedureType?: ProcedureTypeData
  selectedQR?: QuestionnaireResponse
  setDisableSave(state: boolean): void
}

export { Calculator }
