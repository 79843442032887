import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons"
import {
  type ChargeItemDefinition,
  type Medication,
  type MedicationKnowledge,
  type MedicationRequest,
  isCarePlan,
} from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Message } from "primereact/message"
import { useEffect, useId, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  type StackedListItemProps,
  AddFieldArrayItemButton,
  Button,
  MedicationKnowledgeDetails,
  MenuStyles,
  SkeletonLoader,
  StackedListContainer,
  useChargeItemDefinitions,
  useCrudReducer,
} from "commons"
import {
  type MedicationRequestData,
  getFeeType,
  getMKDisplayText,
  useDraftMedicationRequests,
  useGetMedicationsProductPrices,
} from "commons/meds"
import { getPriceByCode } from "commons/utils"
import { MED_FEE_TYPE } from "data"
import { useOpenEncounter } from "encounter"
import { CPOE_STEPS, useCPOEContext, useUpdateAllCpoeRequests } from "orders"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import {
  useCreateMedicationRequest,
  useDeleteMedicationRequest,
  useMedicationRequestDataBind,
  useUpdateMedicationRequest,
} from "../hooks"
import type { MedicationRequestFormData } from "../types"
import { sanitize } from "../utils/formatters"
import { getInitialValues } from "../utils/transformers"
import { PrescriptionFormContainer } from "./form"
import { discardMR } from "./helpers"
import { prescriptionItemModel } from "./prescriptions/prescriptionItemModel"

const PrescriptionsDraftContainer = () => {
  const { patientId, patient } = usePatientContext()
  const { currentModuleData } = useChartContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const { currentOrganizationId, loggedInPractitionerRole } = useOrganizationContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const mrId = searchParams.get("mrId") as string
  const {
    medicationRequests: mrs,
    medicationKnowledges,
    medCodes,
    isLoading,
  } = useDraftMedicationRequests({ patientId, category: "medication", mrId })
  const planId = currentModuleData?.extraData?.planId
  const medicationRequests = mrs.filter((mr) =>
    planId ? mr?.basedOn?.some((ref) => isCarePlan(ref) && ref.id === planId) : !mr?.basedOn?.some(isCarePlan),
  )

  const { chargeItemDefinitions: practiceChargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { chargeItemDefinitions: patientChargeItemDefinitions } = useGetMedicationsProductPrices({
    organizationId: currentOrganizationId,
    codes: {
      billToPatientCIDs: medCodes,
    },
    useSpecificCodes: true,
  })

  const { medicationRequestData: mrDataWithoutPrices } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: practiceChargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
  })

  const medicationRequestData = mrDataWithoutPrices.map((item) => ({
    ...item,
    patientPrice: getPriceByCode({
      chargeItemDefinitions:
        (patientChargeItemDefinitions?.billToPatientCIDs as Record<string, ChargeItemDefinition>) ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      useMedFee: true,
      ...(getFeeType(
        Object.values((patientChargeItemDefinitions?.billToPatientCIDs as Record<string, ChargeItemDefinition>) ?? {}),
      ) === MED_FEE_TYPE.ByFrequency
        ? { productFrequency: item.medicationRequestInfo.dispenseRequest?.dispenseInterval }
        : {}),
    }),
    practicePrice: getPriceByCode({
      chargeItemDefinitions:
        (practiceChargeItemDefinitions?.billToPracticeOrInsuranceCIDs as Record<string, ChargeItemDefinition>) ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
    }),
  }))

  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const {
    initialValue,
    showSlide: showForm,
    isNew,
    add,
    reset,
    edit,
    editIndex,
  } = useCrudReducer({
    defaultEntity: getInitialValues({
      patient,
      loggedInPractitionerRole: loggedInPractitionerRole,
      practitionersInfo: organizationPractitionersInfo,
      encounter: openEncounterRef,
      planId,
    }),
  })
  const { deleteMedicationRequest } = useDeleteMedicationRequest()

  const cpoeCtx = useCPOEContext()
  const { isStripeSetupComplete, hasRootStripeType, showStripeWarning } = useOrganizationContext()
  const { updateAllCpoeRequests, isUpdatingAllRequests } = useUpdateAllCpoeRequests(patientId, () =>
    cpoeCtx.showCpoeOverlay(CPOE_STEPS.FINISH),
  )

  const { createMedicationRequest, isAdding } = useCreateMedicationRequest(reset)
  const { updateMedicationRequest, isUpdating } = useUpdateMedicationRequest(reset)

  const isFormVisible = !medicationRequests?.length || showForm || isAdding || isUpdating
  const isEditing = editIndex !== undefined
  const isAddButtonVisible = !isFormVisible && !isEditing
  const isBeginCheckoutButtonDisabled = isFormVisible || isEditing
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  useEffect(() => {
    mrId && medicationRequestData?.length && editItem(medicationRequestData[0])
  }, [mrId, medicationRequestData])

  const navToPrescriptions = () => {
    searchParams.delete("subview")
    mrId && searchParams.delete("mrId")
    setSearchParams(searchParams)
  }

  const editItem = (mrData: MedicationRequestData) =>
    edit({
      ...mrData?.medicationRequestInfo,
      medicationField: {
        ...(mrData?.medicationKnowledge ?? (mrData?.medicationRequestInfo.contained?.[0] as Medication)),
        textDisplayedInField: mrData?.medicationKnowledge
          ? getMKDisplayText(mrData?.medicationKnowledge)
          : (mrData?.medicationRequestInfo.contained?.[0] as Medication)?.code?.text,
      },
    })

  const remove = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
    })
  }

  const handleSubmit = (values: MedicationRequestFormData) => {
    const isAdding = isNew || !medicationRequests?.length
    isAdding
      ? createMedicationRequest(sanitize({ mr: values }))
      : updateMedicationRequest(sanitize({ mr: { ...values, encounter: openEncounterRef } }))
  }

  const handleCancel = () => {
    reset()
    if (medicationRequestData?.length === 0 || mrId) {
      navToPrescriptions()
    }
  }

  const handleBeginCheckout = () => {
    if (isStripeSetupComplete || hasRootStripeType) {
      updateAllCpoeRequests(medicationRequestData.map(({ medicationRequestInfo }) => medicationRequestInfo))
    } else showStripeWarning()
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  return (
    <>
      {planId && (
        <div className="flex justify-center">
          <Message text="New prescription will be linked to the Plan" />
        </div>
      )}
      {isFormVisible ? (
        <PrescriptionFormContainer
          initialValues={initialValue}
          isEditing={!(isNew || !medicationRequests?.length)}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      ) : (
        <div className="flex flex-col h-full space-y-4">
          {isAddButtonVisible && <AddFieldArrayItemButton className="border-none justify-end px-8" onClick={add} />}
          <div className="flex-1 pb-12 overflow-auto px-4">
            <StackedListContainer
              data={medicationRequestData}
              keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
              withoutDivider
              itemModelBuilder={(item) =>
                itemModel({
                  mrData: item,
                  edit: () => editItem(item),
                  discard: remove,
                  preview: () => setSelectedMK({ mk: item.medicationKnowledge, mr: item.medicationRequestInfo }),
                })
              }
            />
          </div>
          {medicationRequests?.length > 0 && (
            <div className="flex flex-shrink-0 justify-end px-4 py-6 border-t">
              <Button label="Cancel" buttonStyle="default" size="lg" className="mr-3" onClick={navToPrescriptions} />
              <Button
                label="Begin Checkout"
                icon={faShoppingCart}
                iconClassName="h-4 w-4"
                size="lg"
                onClick={handleBeginCheckout}
                loading={isUpdatingAllRequests}
                disabled={isBeginCheckoutButtonDisabled}
              />
            </div>
          )}
        </div>
      )}
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
      />
      <ConfirmDialog />
    </>
  )
}

const itemModel = ({
  mrData,
  edit,
  discard,
  preview,
}: {
  mrData: MedicationRequestData
  edit?: (mrData: MedicationRequestFormData) => void
  discard?: (mrId: string) => void
  preview?: (mrData: MedicationRequestData) => void
}): StackedListItemProps => ({
  ...prescriptionItemModel({
    mrData,
    edit,
    discard,
    preview,
    showStatusBadge: false,
  }),
  menuStyle: MenuStyles.ActionItems,
  itemClassName: "border rounded-xl px-4",
})

export { PrescriptionsDraftContainer }
