import type { Reference } from "fhir"
import { useSearchParams } from "react-router-dom"
import type { FormikContextType } from "formik"
import { useMemo } from "react"

import type { LaboratoryOrder } from "../types"
import type { PlanData } from "../../care-plans"
import { useSafeLabContext } from "./useSafeLabContext"

const useGetLabPerformer = (): Reference | undefined => {
  const formikPlanData = useSafeLabContext<PlanData>()
  const formikLabOrder = useSafeLabContext<LaboratoryOrder>()
  const [searchParams] = useSearchParams()

  let labContext = null

  const labPerformer = useMemo(() => {
    let labPerformer
    if (formikPlanData || formikLabOrder) {
      const view = searchParams.get("view")

      if (view === "labs") {
        labContext = formikLabOrder as FormikContextType<LaboratoryOrder>
        const {
          values: {
            order: { performer },
          },
        } = labContext

        labPerformer = performer?.[0]
      } else if (view === "mc" || view === "plans") {
        labContext = formikPlanData as FormikContextType<PlanData>
        const {
          values: { performer },
        } = labContext

        labPerformer = performer
      }
      return labPerformer
    }
  }, [labContext])

  return labPerformer
}

export { useGetLabPerformer }
