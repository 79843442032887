import { useQuery, useQueryClient } from "@tanstack/react-query"
import { isAfter } from "date-fns/isAfter"
import {
  type CodeableConcept,
  type Coding,
  type DiagnosticReport,
  type Observation,
  type ObservationDefinition,
  type Organization,
  type PlanDefinition,
  type Questionnaire,
  type QuestionnaireResponse,
  type Reference,
  asReference,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getLoincCode } from "utils"

import { proceduresQueryKeys } from "../query-keys"
import type { DRData, ObsData } from "../types"

const useProcedureCalculator = (patientId: string, pdId?: string) => {
  const { search } = useClient()

  const queryKey = proceduresQueryKeys.calculator(patientId, pdId)

  const {
    data,
    isLoading,
    refetch: reloadProcedure,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _query: "procedure-calculator",
        patient: patientId,
        _id: pdId as string,
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters })
      const planDefinition = getResource<PlanDefinition>(bundle, "PlanDefinition")
      const questionnaire = getResources<Questionnaire>(bundle, "Questionnaire")?.[0]
      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const observationDefinitions = getResources<ObservationDefinition>(bundle, "ObservationDefinition")
      const observations = getResourcesByTypeAsIndex<Observation>(bundle, "Observation")
      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const organizations = getResources<Organization>(bundle, "Organization")

      return {
        planDefinition,
        questionnaire,
        questionnaireResponses,
        observationDefinitions,
        observations,
        diagnosticReports,
        organizations,
      }
    },
    enabled: pdId !== undefined,
    refetchOnWindowFocus: false,
    meta: { context: { queryKey } },
  })

  const {
    observationRequestData,
    observationData,
    questionnaireResponses,
    runAlgorithmCode,
    icd10,
    performers,
    requiredOD,
  } = useMemo(() => {
    const initialVal = { values: {}, data: {} } as ObsData
    let savedDRs: Record<string, DiagnosticReport | undefined> = {}
    const drs =
      data?.diagnosticReports.reduce<DRData[]>((acc, report) => {
        const drBasedOn = report.basedOn?.[0]?.id
        const prevDRSaved = savedDRs[drBasedOn as string]
        const isNewResult =
          prevDRSaved?.issued && report.issued && isAfter(new Date(report.issued), new Date(prevDRSaved.issued))
        if (drBasedOn && (report.status === "final" || !prevDRSaved || isNewResult)) {
          const obs =
            report.result?.reduce<ObsData>(
              (obs, ref) =>
                data?.observations[ref.id as string]
                  ? {
                      values: {
                        ...obs.values,
                        [getLoincCode(data?.observations[ref.id as string]?.code.coding)]: `${
                          data?.observations[ref.id as string]?.value?.Quantity?.value ??
                          data?.observations[ref.id as string]?.value?.string ??
                          ""
                        }`,
                      },
                      data: {
                        ...obs.data,
                        [getLoincCode(data?.observations[ref.id as string]?.code.coding)]:
                          data?.observations[ref.id as string],
                      },
                    }
                  : obs,
              initialVal,
            ) ?? initialVal

          savedDRs = { ...savedDRs, [drBasedOn]: report }

          return [...acc, { dr: report, observations: obs }]
        } else return [...acc]
      }, []) ?? []

    const ods =
      data?.observationDefinitions.reduce<Record<string, ObservationDefinition>>((acc, od) => {
        const code = getLoincCode(od.code.coding)
        return acc[code] || code === "N/A" ? acc : { ...acc, [code]: od }
      }, {}) ?? {}

    const qrs =
      data?.questionnaireResponses.reduce<Record<string, QuestionnaireResponse>>(
        (acc, qr) => ({ ...acc, [qr.id as string]: qr }),
        {},
      ) ?? {}

    const algAction = data?.planDefinition?.action?.find((a) => a.code?.[0]?.coding?.[0]?.code === "run-algorithm")
    const runAlgorithmCode = algAction?.input?.[0]?.codeFilter?.[0]?.code?.[0] as Coding

    const actionLab = data?.planDefinition.action?.find((a) => a.code?.[0]?.coding?.[0]?.code === "request-lab-order")

    const planReasonCodes = actionLab?.dynamicValue?.find((v) => v.path === "reasonCode")?.expression?.expression
    const icd10 = planReasonCodes ? (JSON.parse(planReasonCodes) as CodeableConcept) : undefined

    const performers = data?.organizations.reduce<Reference[]>((acc, o) => [...acc, asReference(o)], [])
    const requiredOD =
      actionLab?.action?.[0]?.action?.reduce<Coding[]>(
        (acc, action) =>
          action.requiredBehavior === "must" ? [...acc, action.input?.[0]?.codeFilter?.[0]?.code?.[0] as Coding] : acc,
        [],
      ) ?? []

    return {
      observationRequestData: ods,
      observationData: drs,
      questionnaireResponses: qrs,
      runAlgorithmCode,
      icd10,
      performers,
      requiredOD,
    }
  }, [
    data?.diagnosticReports,
    data?.observationDefinitions,
    data?.observations,
    data?.organizations,
    data?.planDefinition.action,
    data?.questionnaireResponses,
  ])

  const queryClient = useQueryClient()
  const refetch = () => {
    queryClient.invalidateQueries({ queryKey: proceduresQueryKeys.pd.all })
    reloadProcedure()
  }

  return {
    isLoading: isLoading,
    planDefinition: data?.planDefinition,
    questionnaire: data?.questionnaire,
    questionnaireResponses,
    observationRequestData,
    observationData,
    runAlgorithmCode,
    icd10,
    performers,
    requiredOD,
    reloadData: refetch,
  }
}

export { useProcedureCalculator }
