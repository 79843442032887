import type { PropsWithChildren, ReactNode } from "react"
import type { FormikValues } from "formik"

import { type SearchWithFiltersProps, SearchWithFilters } from "./SearchWithFilters"
import { SkeletonLoader } from "./SkeletonLoader"
import { type SlideoverProps, Slideover } from "./Slideover"

const SlideoverWithFilter = <T extends FormikValues>({
  width = "35%",
  height = "calc(100% - 9.5rem)",
  position = "right",
  dismissable = false,
  childrenContainerClassName = "flex flex-col flex-1 overflow-hidden",
  cancelLabel = "Cancel",
  acceptLabel = "Accept",
  filterProps,
  isLoading,
  customFilter,
  children,
  headerExtraContents,
  ...slideProps
}: PropsWithChildren<SlideoverWithFilterProps<T>>) => {
  return (
    <Slideover
      {...{ width, height, position, dismissable, childrenContainerClassName, cancelLabel, acceptLabel }}
      {...slideProps}
    >
      <div className="flex flex-col flex-1 divide-y divide-gray-200 overflow-hidden">
        <div className="px-6 pb-6 pt-1 flex flex-col space-y-5">
          {filterProps ? <SearchWithFilters {...filterProps} /> : customFilter}
          {headerExtraContents}
        </div>
        {filterProps?.isLoading || isLoading ? (
          <div className="divide-y divide-gray-200 overflow-y-auto px-6">
            <SkeletonLoader repeats={4} loaderType="two-lines" />
          </div>
        ) : (
          children
        )}
      </div>
    </Slideover>
  )
}

type SlideoverWithFilterProps<T> = Omit<SlideoverProps, "title"> & {
  title: string
  filterProps?: SearchWithFiltersProps<T>
  isLoading?: boolean
  customFilter?: ReactNode
  headerExtraContents?: ReactNode
}

export { SlideoverWithFilter }
