import { codeableConceptAsString } from "fhir"

import { getMoneyCurrencyAlt } from "utils"

import type { StackedListItemProps } from "../../components/StackedListItem"
import type { PanelInfo } from "../../labs/types"

const SERVICE_ID_SYSTEM = "service-id"

const labTestModelBuilder = (item: PanelInfo, inCombo?: string, showPrices = true): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Test",
          value:
            (item.display ??
              item?.planDefinition?.title ??
              item?.planDefinition?.name ??
              codeableConceptAsString(item.profile?.code)) +
            " - " +
            (item.planDefinition?.identifier?.find((id) => id.system?.includes(SERVICE_ID_SYSTEM))?.value ?? " "),
        },
      ],
    },
  ],
  rightData: [
    {
      lineItems: [
        ...(inCombo
          ? [
              {
                name: "Included in combo",
                value: inCombo,
              },
            ]
          : showPrices
            ? [
                {
                  name: "Price",
                  value: item?.price
                    ? `${getMoneyCurrencyAlt(item.price.currency)}${item.price.value?.toFixed(2)}`
                    : "No price",
                },
              ]
            : []),
      ],
    },
  ],
})

export { labTestModelBuilder }
