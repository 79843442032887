import { type QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import { type Composition } from "fhir"

import { useClient } from "api"
import { type CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useCreatePatientComposition = (onSettled: () => void, queryToInvalidate?: QueryKey, onSuccess?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newPatientComposition = async (composition: Composition) => create("Composition", composition)

  const { mutate: createAssessment, isPending: isAdding } = useMutation({
    mutationFn: newPatientComposition,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryToInvalidate })
      displayNotificationSuccess("Assessment created successfully!")
      onSuccess?.()
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createAssessment, isAdding }
}

export { useCreatePatientComposition }
