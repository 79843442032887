import { useQuery } from "@tanstack/react-query"
import { type Patient, asReference, getResources } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { patientsQueryKeys } from "../query-keys"

const usePatientsRefs = ({ filter, patientId }: { filter?: string; patientId?: string }) => {
  const { search } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = patientsQueryKeys.refs(currentOrganizationId, filter ?? patientId)

  const { data, isLoading, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _count: "100",
        _elements: "id,identifier,name,telecom,birthDate,gender",
        _sort: "name",
        ...(patientId && !filter ? { _id: patientId } : {}),
      })

      if (filter !== "") {
        const splittedFilter = filter?.split(" ")
        splittedFilter?.forEach((name) => {
          filters.append("termsearch", name)
        })
      }

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Patient`, filters, signal })

      const patients = getResources(bundle) as Patient[]

      return { patients, patientRefs: patients.map((p) => asReference(p)) }
    },
    meta: { context: { queryKey, filter } },
    enabled: !!filter || !!patientId,
  })

  return {
    patients: data?.patients,
    patientRefs: data?.patientRefs,
    isLoading: isLoading || isPending,
    isError,
    refetch,
  }
}

export { usePatientsRefs }
