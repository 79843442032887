import { useInfiniteQuery } from "@tanstack/react-query"
import { type MedicationStatement, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { medicationStatementQueryKeys } from "../query-keys"

const useMedicationsStatement = ({
  patientId,
  encounter,
  enabled,
}: {
  patientId: string
  encounter?: string
  enabled?: boolean
}) => {
  const { search } = useClient()
  const queryKey = medicationStatementQueryKeys.list(patientId, encounter)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    MedicationsStatementQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _sort: "-effective,-.effective.period.start",
        _page: `${pageParam}`,
        "status:not": "entered-in-error",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/MedicationStatement`, filters, signal })

      const medicationsStatement = getResources(bundle) as MedicationStatement[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { medicationsStatement, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    enabled,
  })

  const { medications: medicationsStatement, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.medicationsStatement)
    const count = newData?.length

    return {
      medications: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    medications: medicationsStatement,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type MedicationsStatementQueryData = {
  medicationsStatement: MedicationStatement[]
  next: number | undefined
  total: number
}

export { useMedicationsStatement }
