import { format, parseISO } from "date-fns"
import type { Practitioner } from "fhir"
import { humanNameAsString } from "fhir/utils"

import { AvatarImage, BirthdateField, GenderField, InformationCard, InputField, ProfileImageField } from "commons"
import { formatsByTypes } from "data"
import { useLoginContext } from "security"

import { ProfileSection } from "../data"
import { useUpdatePractitioner } from "../hooks"
import { practitionerValidationSchema, sanitize } from "./validations"

const BasicInformation = () => {
  const { loggedInPractitioner, loggedInPractitionerId, reloadPractitioner } = useLoginContext()
  const { patchPractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const initialValues = {
    id: loggedInPractitionerId,
    photo: loggedInPractitioner.photo,
    name: loggedInPractitioner.name,
    birthDate: loggedInPractitioner.birthDate,
    gender: loggedInPractitioner.gender,
  }

  const { photo, name, birthDate, gender } = initialValues
  const formattedBirthday = birthDate ? format(parseISO(birthDate), formatsByTypes.LONG_DATE) : "Unknown"

  const onSubmit = async (practitionerData: Partial<Practitioner>) => {
    patchPractitioner(sanitize({ id: loggedInPractitionerId, ...practitionerData }))
  }

  return (
    <InformationCard
      id={ProfileSection.BASIC}
      title="Basic Information"
      className="profile-card-section p-4"
      data={{
        Photo: (
          <div className="relative w-12 h-12 lg:w-16 lg:h-16 rounded-full overflow-hidden">
            <AvatarImage
              photoUrl={photo?.[0]?.url}
              name={humanNameAsString(name?.[0])}
              className="w-12 h-12 lg:w-16 lg:h-16"
            />
          </div>
        ),
        Name: humanNameAsString(name?.[0]),
        Birthdate: formattedBirthday,
        "Biological Sex": gender ?? "Unknown",
      }}
      initialValue={initialValues}
      validationSchema={practitionerValidationSchema}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
    >
      <ProfileImageField />
      <div className="flex flex-col flex-1 gap-2">
        <InputField field="name[0].given[0]" inputClassName="h-10" label="First Name" />
        <InputField field="name[0].given[1]" label="Middle Name" />
        <InputField field="name[0].family" inputClassName="h-10" label="Last Name" />
        <div className="flex flex-wrap flex-1 space-x-2">
          <InputField field="name[0].prefix[0]" label="Prefix" className="flex-1" />
          <InputField field="name[0].suffix[0]" label="Sufix" className="flex-1" />
        </div>
        <BirthdateField field="birthDate" label="Birthdate" />
        <GenderField field="gender" label="Biological Sex" />
      </div>
    </InformationCard>
  )
}

export { BasicInformation }
