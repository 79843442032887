import { type FC } from "react"
import { classNames } from "primereact/utils"

import { DateField, TextAreaField } from "commons"

import { AutocompleteEncounterTypes } from "./AutocompleteEncounterTypes"

const EncounterForm: FC<Props> = ({ className }) => (
  <div className={classNames("space-y-3", className)}>
    <AutocompleteEncounterTypes field="participant[0].r5_actor" label="Type" />
    <DateField field="period.start" label="Start" stringFormatType="ISO_8601_DATETIME" showTime />
    <TextAreaField field="reasonCode[0].text" label="Chief complaint" />
  </div>
)

type Props = {
  className?: string
}

export { EncounterForm }
