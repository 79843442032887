import type { Address } from "fhir"
import type { FormikHelpers, FormikValues } from "formik"
import { useRef } from "react"

import { displayNotificationError } from "errors"
import { getStringAddress } from "utils"
import { useValueSet } from "value-set"

import { type CustomError, ValueSetIds } from "../types"
import { useSmartyAddressVerificationContext } from "./useSmartyAddressVerificationContext"

const warningCodes = ["A", "B", "U", "I", "K", "L", "M", "N", "R", "T", "V"]

const errorCodes = ["C", "J", "W"]

const overrideCodes = ["F"]

const useSmartyAddressVerification = () => {
  const { codes: availableStates } = useValueSet({ valueSetId: ValueSetIds.CONTINENTAL_USA_STATES })
  const addressRef = useRef<AddressBody>()

  const { updateAddressVerificationInfo, addressVerificationInfo } = useSmartyAddressVerificationContext()

  const checkAddressQuery = async (address: Address) => {
    addressRef.current = {
      street: address.line?.[0],
      secondary: address.line?.[1],
      city: address.city,
      state: address.state,
      zipcode: address.postalCode,
    }

    const searchParams = new URLSearchParams({ ...addressRef.current, match: "enhanced" })

    const response = await fetch(
      `https://us-street.api.smarty.com/street-address?key=${window.VITE_APP_SMARTY_API_KEY}&${searchParams.toString()}`,
      {
        headers: {
          Host: "us-street.api.smarty.com",
          "Content-type": "application/json",
        },
      },
    )

    if (response.ok) {
      const data: AddressVerificationResponse = await response.json()
      return data[0]
    }

    displayNotificationError({
      cause: {
        name: "Smarty Address Verification Error",
        message: "Error trying to retrive verification address data from smarty api.",
      },
    } as CustomError)
  }

  const validateAddress = async (
    results: AddressVerificationResult,
    formikHelpers?: FormikHelpers<FormikValues>,
    onAcceptedAddress?: () => void,
  ) => {
    formikHelpers?.setSubmitting(true)
    updateAddressVerificationInfo(undefined)

    const { components, analysis } = results

    const recommendedAddress = buildRecommendedAddress(components)

    const footNoteCodes = analysis.footnotes?.split(VALIDATION_CODES_SEPARATOR.char)

    const itHasWarnings = footNoteCodes?.some((code) => warningCodes.includes(code))
    const itHasErrors = footNoteCodes?.some((code) => errorCodes.includes(code))
    const itIsAvailableToOverride = footNoteCodes?.some((code) => overrideCodes.includes(code))

    const isValidAddress =
      (analysis.dpv_match_code === "Y" || analysis.dpv_match_code === "S") &&
      !itHasWarnings &&
      !itHasErrors &&
      !itIsAvailableToOverride

    const hasPostalOrNonPostalMatch = ["non-postal-match", "postal-match"].includes(analysis.enhanced_match as string)

    if (isValidAddress || hasPostalOrNonPostalMatch) {
      updateAddressVerificationInfo({ isValidAddress: true })
      onAcceptedAddress?.()
      return
    }

    const isValidWithWarnings = analysis.dpv_match_code === "D" || itHasWarnings
    const isOverrideAddress = analysis.dpv_match_code === "N" || itIsAvailableToOverride
    const isInvalidAddress = itHasErrors && !isOverrideAddress

    if (isValidWithWarnings) {
      updateAddressVerificationInfo({
        isValidAddress: true,
        isValidWithWarnings: true,
      })

      switch (analysis.dpv_match_code) {
        case "D":
          addFeedback({
            feedback: {
              display:
                "Address is valid, but we found **Address Line 2** information that is missing from your submission.",
              fix: ["Add the missing Address Line 2 information."],
            },
            type: "warning",
            shouldPromptAutoCompleteOnUse: true,
          })
          break
      }

      footNoteCodes?.forEach((code) => {
        switch (code) {
          case "A":
            addFeedback({
              feedback: {
                display: "The address was found to have a different **Zip Code** than the one submitted.",
                fix: [`The correct zip code is ${components.zipcode}.`],
              },
              type: "warning",
            })
            break
          case "B":
          case "U":
            {
              const state =
                availableStates?.find(({ code }) => code === components.state_abbreviation)?.display ??
                components.state_abbreviation

              addFeedback({
                feedback: {
                  display: "Incorrect spelling found in the **City** or **State** submitted.",
                  fix: [`The correct city name is ${components.city_name}.`, `The correct state is ${state}.`],
                },
                type: "warning",
              })
            }
            break
          case "I":
            addFeedback({
              feedback: {
                display:
                  "More than one **ZIP Code** was found to satisfy the address as submitted. The submitted address did not contain sufficiently complete or correct data to determine a single **ZIP Code**.",
                fix: ["Please verify the accuracy of the address."],
              },
              type: "warning",
            })
            break
          case "K":
          case "L":
            addFeedback({
              feedback: {
                display:
                  "An address component (i.e., directional or suffix only) was added, changed, or deleted in order to achieve a match.",
                fix: ["Use the recommended address shown."],
              },
              type: "warning",
            })
            break
          case "M":
            addFeedback({
              feedback: {
                display:
                  "The address submitted was found to have a different **Address Line 1** than the one submitted.",
                fix: [`The correct Address Line 1 is ${recommendedAddress.address?.line?.[0]}.`],
              },
              type: "warning",
            })
            break
          case "N":
            addFeedback({
              feedback: {
                display: "The delivery address was standardized with fixed abbreviations.",
                fix: ["Use the recommended address shown."],
              },
              type: "warning",
            })
            break
          case "R":
            addFeedback({
              feedback: {
                display:
                  "The delivery address is not yet matchable, but the US Postal Service Early Warning System file indicates that a match will be available soon.",
                fix: [`Careful using these address for shipping.`],
              },
              type: "warning",
            })
            break
          case "T":
            addFeedback({
              feedback: {
                display:
                  "Multiple matches found due to magnet street syndrome. The **Address Line 1** components (pre-directional, primary street name, post-directional, and suffix) did not exactly match those of the record.",
                fix: ["Please verify the accuracy of the address."],
              },
              type: "warning",
            })
            break
          case "V":
            addFeedback({
              feedback: {
                display:
                  "The **City** and **State** in the submitted address could not be verified as corresponding to the given **ZIP Code**.",
                fix: ["Please verify the accuracy of the address."],
              },
              type: "warning",
            })
            break
        }
      })

      updateAddressVerificationInfo((prev) => ({
        ...prev,
        recommendedAddress: recommendedAddress,
      }))
    }

    if (isOverrideAddress) {
      updateAddressVerificationInfo({ isValidAddress: false, shouldAllowOverride: true })
      addFeedback({
        feedback: {
          display: "No records of this address where found. Still want to use this address?",
        },
        type: "error",
        shouldAllowOverride: true,
      })
    }

    if (isInvalidAddress) {
      updateAddressVerificationInfo({ isValidAddress: false })

      switch (analysis.dpv_match_code) {
        case "N":
          addFeedback({
            feedback: {
              display: "The address submitted was not found in the USPS database.",
              fix: ["Please verify the address and try again."],
            },
            type: "error",
          })
          break
        case undefined:
          addFeedback({
            feedback: {
              display: "The address submitted is not valid.",
              fix: ["Please verify the address and try again."],
            },
            type: "error",
          })
          break
      }

      footNoteCodes?.forEach((code) => {
        switch (code) {
          case "C#":
            addFeedback({
              feedback: {
                display:
                  "TThe **ZIP Code** in the submitted address could not be found because neither a valid **City** and **State**, nor a valid **ZIP Code** was present.",
                fix: ["Please verify the accuracy of the address and try again."],
              },
              type: "error",
            })
            break
          case "J#":
            addFeedback({
              feedback: {
                display: "The submitted address contained two addresses.",
                fix: ["Please verify the address and try again."],
              },
              type: "warning",
            })
            break
          case "W#":
            addFeedback({
              feedback: {
                display:
                  "The USPS does not provide street delivery service for this **ZIP Code**. The USPS requires the use of a PO Box, General Delivery, or Postmaster for delivery within this **ZIP Code**.",
                fix: ["Please verify the address and try again."],
              },
              type: "error",
            })
            break
        }
      })
    }

    formikHelpers?.setSubmitting(false)
  }

  const buildRecommendedAddress = (
    verifiedAddress: AddressVerificationComponents,
  ): { display: string; address: Address } => {
    const whiteSpace = " "

    let addressLine1 = ""
    let addressLine2 = ""

    if (verifiedAddress.primary_number) {
      addressLine1 += verifiedAddress.primary_number + whiteSpace
    }

    if (verifiedAddress.street_predirection) {
      addressLine1 += verifiedAddress.street_predirection + whiteSpace
    }

    if (verifiedAddress.street_name) {
      addressLine1 += verifiedAddress.street_name + whiteSpace
    }

    if (verifiedAddress.street_suffix) {
      addressLine1 += verifiedAddress.street_suffix + whiteSpace
    }

    if (verifiedAddress.street_postdirection) {
      addressLine1 += verifiedAddress.street_postdirection + whiteSpace
    }

    if (verifiedAddress.secondary_designator) {
      addressLine2 += verifiedAddress.secondary_designator + whiteSpace
    }

    if (verifiedAddress.secondary_number) {
      addressLine2 += verifiedAddress.secondary_number + whiteSpace
    }

    if (verifiedAddress.extra_secondary_designator) {
      addressLine2 += verifiedAddress.extra_secondary_designator + whiteSpace
    }

    if (verifiedAddress.extra_secondary_number) {
      addressLine2 += verifiedAddress.extra_secondary_number + whiteSpace
    }

    const address = {
      line: [addressLine1, addressLine2],
      city: verifiedAddress.city_name,
      state: verifiedAddress.state_abbreviation,
      postalCode: verifiedAddress.zipcode,
    }

    return {
      display: getStringAddress(address),
      address,
    }
  }

  const addFeedback = ({
    feedback,
    type,
    shouldPromptAutoCompleteOnUse,
  }: {
    feedback: AddressVerificationFeedback
    type: "warning" | "error"
    shouldPromptAutoCompleteOnUse?: boolean
    shouldAllowOverride?: boolean
  }) => {
    updateAddressVerificationInfo((prev) => ({
      ...prev,
      ...(type === "warning" ? { warnings: [...(prev?.warnings ?? []), feedback] } : {}),
      ...(type === "error" ? { errors: [...(prev?.errors ?? []), feedback] } : {}),
      ...(shouldPromptAutoCompleteOnUse ? { shouldPromptAutoCompleteOnUse: true } : {}),
    }))
  }

  const clearVerificationInfo = () => updateAddressVerificationInfo(undefined)

  const checkAddress = async (
    address: Address,
    formikHelpers?: FormikHelpers<FormikValues>,
    onAcceptedAddress?: () => void,
  ) =>
    addressVerificationInfo?.shouldBypassValidation
      ? onAcceptedAddress?.()
      : await checkAddressQuery(address)
          .then(async (result) => await validateAddress(result!, formikHelpers, onAcceptedAddress))
          .catch(() => {
            formikHelpers?.setSubmitting(false)
            displayNotificationError({
              cause: {
                name: "Smarty Address Verification Error",
                message: "Error trying to verify submitted address.",
              },
            } as CustomError)
          })

  return { checkAddress, clearVerificationInfo }
}

type AddressBody = {
  street?: string
  secondary?: string
  city?: string
  state?: string
  zipcode?: string
}

type AddressVerificationResponse = AddressVerificationResult[]

type AddressVerificationResult = {
  input_id: string
  input_index: number
  candidate_index: number
  addressee: string
  delivery_line_1: string
  delivery_line_2: string
  last_line: string
  delivery_point_barcode: string
  smarty_key: string
  components: AddressVerificationComponents
  metadata: AddressVerificationMetadata
  analysis: AddressVerificationAnalysis
}

type AddressVerificationComponents = {
  urbanization: string
  primary_number: string
  street_name: string
  street_predirection: string
  street_postdirection: string
  street_suffix: string
  secondary_number: string
  secondary_designator: string
  extra_secondary_number: string
  extra_secondary_designator: string
  pmb_designator: string
  pmb_number: string
  city_name: string
  default_city_name: string
  state_abbreviation: string
  zipcode: string
  plus4_code: string
  delivery_point: string
  delivery_point_check_digit: string
}

type AddressVerificationMetadata = {
  record_type?: "F" | "G" | "H" | "P" | "R" | "S" | ""
  zip_type?: "Unique" | "Military" | "POBox" | "Standard"
  county_fips?: string
  county_name?: string
  ews_match?: "true" | ""
  carrier_route?: string
  congressional_district?: string
  building_default_indicator?: "Y" | "N"
  rdi?: "Residential" | "Commercial" | "Unknown"
  elot_sequence?: string
  elot_sort?: "A" | "D" | ""
  latitude?: number
  longitude?: number
  coordinate_license?: number
  precision?: "Unknown" | "Zip5" | "Zip6" | "Zip7" | "Zip8" | "Zip9" | "Street" | "Parcel" | "Rooftop"
  time_zone?:
    | "Alaska"
    | "Atlantic"
    | "Central"
    | "Eastern"
    | "Hawaii"
    | "Mountain"
    | "None"
    | "Pacific"
    | "Samoa"
    | "UTC+9"
    | "UTC+10"
    | "UTC+11"
    | "UTC+12"
  utc_offset?: number
  dst?: "true" | ""
}

type AddressVerificationAnalysis = {
  dpv_match_code: "Y" | "N" | "S" | "D" | "" | null
  dpv_footnotes: string | null
  dpv_cmra: "Y" | "N" | "" | null
  dpv_vacant: "Y" | "N" | "" | null
  dpv_no_stat: "Y" | "N" | "" | null
  active: "Y" | null
  footnotes: string | null
  lacslink_code: "A" | "00" | "09" | "14" | "92" | "" | null
  lacslink_indicator: "Y" | "S" | "N" | "F" | "" | null
  suitelink_match: "true" | "false" | null
  enhanced_match: string | null
}

type AddressVerificationFeedback = {
  display: string
  fix?: string[]
}

export type AddressVerificationInfo = {
  isValidAddress?: boolean
  isValidWithWarnings?: boolean
  recommendedAddress?: {
    display: string
    address: Address
  }
  shouldPromptAutoCompleteOnUse?: boolean
  errors?: AddressVerificationFeedback[]
  warnings?: AddressVerificationFeedback[]
  shouldAllowOverride?: boolean
  shouldBypassValidation?: boolean
}

enum VALIDATION_CODES_SEPARATOR {
  char = "#",
}

export { useSmartyAddressVerification }
