import type {
  Coding,
  Invoice,
  Medication,
  MedicationKnowledge,
  MedicationKnowledgeAdministrationGuidelinesArray,
  MedicationRequest,
  Quantity,
  Timing,
} from "fhir"

import type { CustomInvoiceData } from "orders"

export type Contents = "prescriptions" | "history" | "refills" | "orders"

export type MedicationRequestInfo = MedicationRequest & {
  prescriptionQuantity?: Quantity
  dosages?: MedicationDosage[]
  administrationGuideline?: MedicationKnowledgeAdministrationGuidelinesArray
}

export type MedicationRequestFormData = MedicationRequestInfo & {
  medicationKnowledge?: MedicationKnowledge
  prescriptionSig?: string
  medicationField?: (MedicationKnowledge | Medication) & { textDisplayedInField?: string }
  suggestion?: string
  structured?: boolean
}

export type MesureValue = {
  code?: string
  unit?: string
  system?: string
}

export type RouteValue = {
  code?: string
  display?: string
  system?: string
}

export type MedicationFormData = Medication & { newIngredient?: MedicationIngredientFormData | null }

export type MedicationIngredientFormData = {
  name?: string
  value?: number
  unit?: string
}

export type MedicationDosage = {
  doseQuantity?: string
  medicationQuantity?: Quantity
  treatmentFrequency?: string
  treatmentRoute?: Coding
  prescriptionDuration?: string
  prescriptionDurationUnit?: Coding
  instructionText?: string
  doseTiming?: Timing
}

export type EditOrderData = {
  orderId: string
  medicationRequests: MedicationRequest[]
  transient: boolean
  markedToDelete?: string[]
}

export enum EDIT_ORDER_STEPS {
  CONFIG = "config",
  PREVIEW = "preview",
}

export type InvoiceData = { invoice: Invoice } & CustomInvoiceData
