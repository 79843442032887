import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { FormikHelpers } from "formik"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { type StackedListItemProps, MenuStyles, SkeletonLoader, StackedListContainer, useCrudReducer } from "commons"
import { type MedicationRequestData, MedicationOrderDetailHeader } from "commons/meds"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useMrOrderContext } from "../../hooks"
import type { MedicationRequestFormData } from "../../types"
import { getInitialValues, sanitize } from "../../utils"
import { PrescriptionFormContainer } from "../form"
import { prescriptionItemModel } from "../prescriptions/prescriptionItemModel"
import { MedicationOrderEditFooter } from "./MedicationOrderEditFooter"
import { OrderShippingAddress } from "./OrderShippingAddress"

const MedicationOrderEditConfig: FC = () => {
  const { patientId, patient } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const { loggedInPractitionerRole, organizationPractitionersInfo } = useOrganizationContext()
  const {
    medicationRequestData,
    invoice,
    serviceRequest,
    medicationRequestDataWithPrice,
    isLoading,
    updateMedicationRequest,
    editedMedicationRequests,
    orderShippingAddress,
    markedToDeleteMeds,
    updateOrderShippingAddress,
    handleDeleteMed,
    handleRestoreMed,
  } = useMrOrderContext()

  const {
    initialValue,
    showSlide: showForm,
    reset,
    edit,
  } = useCrudReducer({
    defaultEntity: getInitialValues({
      patient,
      loggedInPractitionerRole: loggedInPractitionerRole,
      practitionersInfo: organizationPractitionersInfo,
      encounter: openEncounterRef,
    }),
  })

  const handleSubmit = (
    mrData: MedicationRequestFormData,
    formikHelpers?: FormikHelpers<MedicationRequestFormData>,
  ) => {
    updateMedicationRequest(sanitize({ mr: mrData, resetRefillDate: true }))
    formikHelpers?.setSubmitting(false)
    reset()
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {isLoading ? (
        <SkeletonLoader loaderType="two-lines" repeats={3} extraLine />
      ) : (
        <div className="@container flex flex-col gap-4 overflow-y-hidden grow text-gray-600">
          <MedicationOrderDetailHeader serviceRequest={serviceRequest} invoices={invoice && [invoice]} hiddenActions />
          {showForm ? (
            <PrescriptionFormContainer
              initialValues={initialValue}
              isEditing
              onCancel={reset}
              onSubmit={handleSubmit}
              editAddressHidden
            />
          ) : (
            <div className="grow min-h-0 flex flex-col p-2">
              <label className="font-semibold mb-2 pr-1 p-4 ">Medications:</label>
              {!medicationRequestData?.length ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                  <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications requested</p>
                </div>
              ) : (
                <div className="flex flex-col h-full grow min-h-0 space-y-4 px-4">
                  <div className="flex-1 flex flex-col gap-6 pb-6 overflow-auto">
                    <StackedListContainer
                      data={medicationRequestDataWithPrice}
                      keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
                      withoutDivider
                      itemModelBuilder={(item) => {
                        const editedMedReqInfo = editedMedicationRequests.get(item.medicationRequestInfo.id ?? "")
                        const updatedData = editedMedReqInfo
                          ? {
                              ...item,
                              medicationRequestInfo: { ...item.medicationRequestInfo, ...editedMedReqInfo },
                            }
                          : item
                        const markedToDelete = markedToDeleteMeds?.includes(item.medicationRequestInfo.id ?? "")

                        return itemModel({
                          mrData: updatedData,
                          ...(!markedToDelete
                            ? {
                                edit,
                                ...(medicationRequestDataWithPrice.length - (markedToDeleteMeds?.length ?? 0) > 1
                                  ? { discard: handleDeleteMed }
                                  : {}),
                              }
                            : { restore: handleRestoreMed }),
                          edited: !!editedMedReqInfo,
                          markedToDelete,
                        })
                      }}
                    />
                    <OrderShippingAddress value={orderShippingAddress} onChange={updateOrderShippingAddress} />
                  </div>
                  <MedicationOrderEditFooter />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const itemModel = ({
  mrData,
  edited,
  edit,
  discard,
  markedToDelete,
  restore,
}: {
  mrData: MedicationRequestData
  edited?: boolean
  edit?: (mrData: MedicationRequestFormData) => void
  markedToDelete?: boolean
  discard?: (mrId: string) => void
  restore?: (mrId: string) => void
}): StackedListItemProps => ({
  ...prescriptionItemModel({
    mrData,
    edit,
    showStatusBadge: false,
    allowEdit: true,
    discard,
    restore,
  }),
  menuStyle: MenuStyles.ActionItems,
  itemClassName: classNames("border rounded-xl px-4", { "opacity-60 hover:bg-transparent": markedToDelete }),
  ...(markedToDelete
    ? {
        badge: { text: "Deleted", colorStyle: "gray" },
      }
    : edited
      ? {
          badge: { text: "Edited", colorStyle: "yellow" },
        }
      : {}),
})

export { MedicationOrderEditConfig }
