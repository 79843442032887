import { useMutation } from "@tanstack/react-query"
import { type CodeableConcept, type Parameters, codeableConceptAsString } from "fhir"
import { useRef } from "react"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"

const usePrescriptionValidator = () => {
  const { operationRequest } = useClient()
  const { patientId } = usePatientContext()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const checkPrescription = async ({ mkCode }: { mkCode?: CodeableConcept }) => {
    timeoutRef?.current && clearTimeout(timeoutRef?.current)

    if (!mkCode) return { isValid: false, message: "" }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "medication",
          value: {
            CodeableConcept: mkCode,
          },
        },
      ],
    }

    await new Promise((resolve) => {
      timeoutRef.current = setTimeout(resolve, 300)
    })

    const results = await operationRequest<Parameters>({
      endpoint: `Patient/${patientId}/medications`,
      method: "POST",
      operation: "validate",
      parameters,
    })

    return {
      isValid: results.parameter?.find((param) => param.name === "is-valid")?.value?.boolean ?? false,
      message: results.parameter?.find((param) => param.name === "reason")?.value?.string,
      lastChecked: mkCode,
    }
  }

  const {
    mutateAsync: validatePrescription,
    isPending,
    isSuccess,
    data,
  } = useMutation({
    mutationKey: ["validate-medication"],
    mutationFn: checkPrescription,
    onError: (error: CustomError, { mkCode }) => {
      displayNotificationError(registerErrorTrace(error, { prescription: codeableConceptAsString(mkCode) }))
    },
  })

  return {
    validatePrescription,
    isCheckingPrescription: isPending,
    hasBeenChecked: isSuccess,
    lastChecked: data?.lastChecked,
  }
}

export { usePrescriptionValidator }
