import { useSearchParams } from "react-router-dom"

import { ModulesId } from "commons"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"

import { useCreateMedicationRequest } from "../hooks"
import type { MedicationRequestFormData } from "../types"
import { sanitize } from "../utils/formatters"
import { getInitialValues } from "../utils/transformers"
import { PrescriptionFormContainer } from "./form"

const PrescriptionsNewContainer = () => {
  const { patientId, patient } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const { currentOrganizationId, loggedInPractitionerRole } = useOrganizationContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const initialValue = getInitialValues({
    patient,
    loggedInPractitionerRole,
    practitionersInfo: organizationPractitionersInfo,
    encounter: openEncounterRef,
  })

  const navToPrescriptions = () => {
    searchParams.delete("subview")
    setSearchParams(searchParams)
  }

  const navToPrescriptionsDraft = () => {
    searchParams.set("subview", ModulesId.EPRESCRIBE_DRAFT)
    setSearchParams(searchParams)
  }

  const { createMedicationRequest } = useCreateMedicationRequest(navToPrescriptionsDraft)

  const handleSubmit = (values: MedicationRequestFormData) => {
    createMedicationRequest(sanitize({ mr: values }))
  }

  return (
    <PrescriptionFormContainer initialValues={initialValue} onCancel={navToPrescriptions} onSubmit={handleSubmit} />
  )
}

export { PrescriptionsNewContainer }
