import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import type { BundleEntryArray } from "fhir"
import { useEffect, useMemo } from "react"

import { useClient } from "api"
import { conditionsQueryKeys } from "conditions"

import { patient360QueryKeys } from "../query-keys"

const usePatientConditions = (patientId: string) => {
  const { search } = useClient()
  const queryClient = useQueryClient()
  const queryKey = patient360QueryKeys.conditions(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientConditionsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        "identifier:text": "patient360",
        _count: "20",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Condition`, filters, signal })

      const conditionsEntries = bundle.entry ?? []

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { conditionsEntries, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: conditionsQueryKeys.all })
  }, [data])

  const { conditionsEntries, count } = useMemo(() => {
    let count = 0
    const newData = data?.pages.reduce<{
      conditionsEntries: BundleEntryArray[]
      next: number | undefined
      total: number
    }>(
      (prev, current) => {
        const newObservations = prev?.conditionsEntries.concat(current.conditionsEntries)
        count += current.conditionsEntries.length
        return {
          conditionsEntries: newObservations,
          next: current.next,
          total: current.total,
        }
      },
      { conditionsEntries: [], next: 0, total: 0 },
    )

    return {
      conditionsEntries: newData?.conditionsEntries ?? [],
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    conditionsEntries,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadObservations: refetch,
  }
}

type PatientConditionsQueryData = {
  conditionsEntries: BundleEntryArray[]
  next: number | undefined
  total: number
}

export { usePatientConditions }
