import { type MedicationKnowledge, codeableConceptAsString } from "fhir"

import type { StackedListItemProps } from "commons"

const pelletModel = ({
  medicationKnowledge,
}: {
  medicationKnowledge: MedicationKnowledge
  inStock?: boolean
}): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Product", value: codeableConceptAsString(medicationKnowledge?.code) }],
    },
    {
      lineItems: [{ name: "Manufacturer", value: medicationKnowledge.manufacturer?.display }],
    },
  ],
})

export { pelletModel }
