import { faEnvelope, faPencil } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDistance } from "date-fns"
import { type Patient, humanNameAsString } from "fhir"
import { type FormikProps, Form, Formik } from "formik"
import { Button } from "primereact/button"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import { type FC, useId, useState } from "react"

import { CardListItem, ConfirmDialog, EmailField, SkeletonLoader } from "commons"

import { useInvitePatient, usePatientContext, usePatientInvitation, useUpdatePatientEmail } from "../../hooks"

const StatusForm: FC<Props> = ({ patient }) => {
  const [editMail, toggleEditMail] = useState(false)
  const [showConfimDialog, setShowConfirmDialog] = useState(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const { hasB2CUser } = usePatientContext()

  const [inviteDisabled, setInviteDisabled] = useState(false)

  const { invitation, isLoading: isLoadingInvitation } = usePatientInvitation(patient?.id as string)
  const { invitePatient, isInviting } = useInvitePatient(patient?.id as string, humanNameAsString(patient?.name?.[0]))

  const getMail = () => patient?.telecom?.find(({ system }) => system === "email")?.value

  const [patientEmail, setPatientEmail] = useState(getMail())

  const onMailUpdateSettled = (error: Error | null) => {
    if (!error) {
      setPatientEmail(getMail() as string)
      invitePatient(undefined, { onSuccess: () => setInviteDisabled(true) })
      setTimeout(() => setInviteDisabled(false), 30000)
    }
  }

  const { updatePatientEmail, isUpdating: isUpdatingEmail } = useUpdatePatientEmail(onMailUpdateSettled)

  const sendInvitation = (email: string) => {
    if (email !== patientEmail && patient) {
      updatePatientEmail({ patient, email })
    } else {
      invitePatient(undefined, { onSuccess: () => setInviteDisabled(true) })
      setTimeout(() => setInviteDisabled(false), 30000)
    }
  }
  const handleInvitationDialog = (email: string) => {
    setEmail(email)
    setShowConfirmDialog(true)
  }
  const hideDialog = () => {
    setPatientEmail(undefined)
    setShowConfirmDialog(false)
  }

  const name = humanNameAsString(patient.name?.[0])

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="plain-text" />

  if (isLoadingInvitation) return loader()

  if (hasB2CUser)
    return (
      <div className="p-4">
        <Chip className="custom-chip is-success" label="Active" />
      </div>
    )

  const revoked = invitation?.status === "revoked"

  const initialValues: FormType = { email: patient.telecom?.[0].value }

  const renderForm = ({ setFieldValue }: FormikProps<FormType>) => (
    <Form className="flex flex-1 flex-col overflow-hidden divide-y h-full">
      <fieldset className="relative p-fluid flex flex-1 flex-col overflow-y-auto grow px-3" id="status">
        <legend>Status</legend>
        {invitation?.status === "active" ? (
          <div className="py-4">
            <Chip className="custom-chip is-info" label="Sending invitation email" />
          </div>
        ) : (
          invitation?.status === "completed" && (
            <>
              <div className="py-4">
                <Chip className="custom-chip is-warning" label="Invitation pending to accept" />
              </div>
              <span className="text-sm">
                The link expires in{" "}
                {invitation?.occurrence?.dateTime &&
                  formatDistance(new Date(invitation.occurrence.dateTime), new Date())}
                .
              </span>
            </>
          )
        )}
        <div className="my-4 text-sm">
          Pick an email to {!invitation?.status ? "invite" : "re-invite"} {name} to Patient Portal
        </div>

        <CardListItem
          className={classNames("cursor-pointer border-none hover:bg-slate-50", { "bg-slate-100": !editMail })}
          rowImg={<FontAwesomeIcon icon={faEnvelope} className="mx-1" />}
          contentHeader={<div className="font-semibold mr-2">{patient.telecom?.[0].value}</div>}
          onContentCliked={() => {
            toggleEditMail(false)
            setFieldValue("email", patient.telecom?.[0].value)
          }}
        />
        <CardListItem
          className={classNames("cursor-pointer border-none hover:bg-slate-50", { "bg-slate-100": editMail })}
          rowImg={<FontAwesomeIcon icon={faPencil} className="mx-1" />}
          contentHeader={<div className="font-semibold mr-2">Use another email</div>}
          onContentCliked={() => {
            toggleEditMail(true)
            setFieldValue("email", "")
          }}
        />
        {editMail && (
          <EmailField
            field="email"
            label="Email"
            initialValue={patient.telecom?.[0].value}
            validateDuplicate={true}
            validateRules={true}
            className="pl-10 pt-3"
          />
        )}
      </fieldset>
      <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
        <Button
          type="submit"
          label={
            revoked
              ? "Retry"
              : !invitation?.status
                ? "Send invitation"
                : inviteDisabled
                  ? "Processing..."
                  : "Resend invitation"
          }
          disabled={!patient.telecom?.[0].value || inviteDisabled}
          loading={isInviting || isUpdatingEmail}
          tooltip={
            inviteDisabled
              ? "Please allow some time while the invitation is sent"
              : "Patient needs to have an email to send an invitation."
          }
          tooltipOptions={{
            disabled: !!patient.telecom?.[0].value || !inviteDisabled,
            position: "left",
            showOnDisabled: true,
          }}
          className="button-primary py-2 px-3 ring-primary hover:ring-primary-hover text-sm font-normal"
        />
      </div>
    </Form>
  )

  return (
    <div className="h-full overflow-hidden relative">
      {revoked ? (
        <Chip className="custom-chip is-danger" label="Error sending email" />
      ) : (
        (invitation?.status !== "active" && invitation?.status !== "completed") ?? (
          <Chip className="custom-chip is-secondary" label="Not invited" />
        )
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={({ email }) => {
          email && handleInvitationDialog(email)
        }}
        enableReinitialize
      >
        {renderForm}
      </Formik>
      <ConfirmDialog
        confirmText={`Do you want to invite ${name} to Patient Portal`}
        onConfirm={() => sendInvitation(email ?? "")}
        hideDialog={hideDialog}
        visible={showConfimDialog || isInviting || isUpdatingEmail}
        isLoading={isInviting || isUpdatingEmail}
      />
    </div>
  )
}

type FormType = {
  email?: string
}

type Props = {
  patient: Patient
}

export { StatusForm }
