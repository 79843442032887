import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type CodeableConcept, type Coding, type Patient, codeableConceptAsString } from "fhir"
import { type FieldValidator, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useEffect, useMemo, useState } from "react"
import type { AnyObject } from "yup/lib/types"

import { useConditions } from "conditions/hooks"
import type { OrgDataSettingCode } from "data"
import { useOrganizationContext } from "organization"
import { defaultEditRemoveMenu } from "utils-components"

import { AddFieldArrayItemButton } from "../../components/Buttons"
import { StackedListContainer } from "../../components/StackedListContainer"
import type { StackedListItemProps } from "../../components/StackedListItem"
import { useDefaultOrgData, useReplaceFormContext } from "../../hooks"
import { MenuStyles } from "../../types"
import { FormField } from "../FormField"
import { ICD10CodesSelection } from "./ICD10CodesSelection"

const ICD10CodesField: FC<Props> = ({
  field,
  label = "ICD-10",
  showLabel = true,
  readOnly,
  showSuggestedPatientConditions,
  orgSuggestedConditions,
  containerClassName,
  className,
  labelClassName,
  horizontal,
  patient,
  validate,
  avoidReplacement,
  hideEmptyMessage,
  icon,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext<AnyObject>()
  const [showSlide, setShowSlide] = useState(false)
  const { currentOrganizationId } = useOrganizationContext()
  const { id: patientId, gender } = patient ?? {}

  const { conditions, isLoading: isLoadingPatientConditions } = useConditions({
    patientId: patientId as string,
    enabled: !!showSuggestedPatientConditions && !!patientId,
  })
  const { defaultData, isLoading: isLoadingOrgConditions } = useDefaultOrgData(
    currentOrganizationId,
    !!orgSuggestedConditions,
  )

  const orgICD10Codes = useMemo(
    () =>
      orgSuggestedConditions
        ? (defaultData[orgSuggestedConditions]?.[gender ?? "male"] as Coding[] | undefined)?.reduce(
            (prevData, coding) => {
              return { ...prevData, ...{ items: coding.code ? [...prevData.items, coding] : [...prevData.items] } }
            },
            { category: "Organization favorites", items: [] as Coding[] },
          )
        : undefined,
    [orgSuggestedConditions, defaultData, gender],
  )

  const patientConditionsICD10Codes = useMemo(
    () =>
      showSuggestedPatientConditions
        ? conditions?.reduce(
            (prevData, cc) => {
              return {
                ...prevData,
                ...{
                  items: [
                    ...prevData.items,
                    ...(cc.code?.coding?.filter(({ code, system }) => !!code && system?.includes("icd-10")) ?? []),
                  ],
                },
              }
            },
            { category: "Patient conditions", items: [] as Coding[] },
          )
        : undefined,
    [conditions, showSuggestedPatientConditions],
  )

  const suggestions = useMemo(() => {
    const suggestions = [] as { category: string; items: Coding[] }[]
    if (orgICD10Codes?.items?.length) suggestions.push(orgICD10Codes)
    if (patientConditionsICD10Codes?.items.length) suggestions.push(patientConditionsICD10Codes)
    return suggestions.length ? suggestions : undefined
  }, [orgICD10Codes, patientConditionsICD10Codes])

  const fieldValue: CodeableConcept[] | undefined = useMemo(() => {
    const icd10Field = field.split(".")
    if (icd10Field.length > 1) return values?.[icd10Field[0]]?.[icd10Field[1]]
    else if (icd10Field.length > 2) return values?.[icd10Field[0]]?.[icd10Field[1]]?.[icd10Field[2]]
    else return values?.[icd10Field[0]]
  }, [values])

  const currentCodes = useMemo(() => fieldValue?.flatMap(({ coding }) => coding?.[0] ?? []) ?? [], [fieldValue])

  const deleteItem = useCallback(
    (index: number) => {
      const updatedCurrentCodes = currentCodes?.toSpliced(index, 1)
      setFieldValue(
        field,
        updatedCurrentCodes.flatMap((coding) => ({ coding: [coding] })),
      )
    },
    [currentCodes],
  )

  const onSubmit = useCallback(
    ({ newCodes, deletedCodes }: { newCodes: Coding[]; deletedCodes: { id: string; index: number }[] }) => {
      const codesAfterDelete = currentCodes.filter(
        (_, currentIndex) => !deletedCodes.some(({ index }) => index === currentIndex),
      )
      setFieldValue(
        field,
        [...codesAfterDelete, ...newCodes].flatMap((coding) => ({ coding: [coding] })),
      )
    },
    [currentCodes],
  )

  const formReplaceContext = useReplaceFormContext<{
    newCodes: Coding[]
    deletedCodes: { id: string; index: number }[]
  }>()

  const onReset = () => {
    setShowSlide(false)
    formReplaceContext?.setShowReplacementContent?.(false)
  }

  const replacementFormProps = useMemo(
    () => ({
      title: label || "ICD-10",
      showForm: showSlide,
      useFormik: true,
      initialValue: { newCodes: currentCodes, deletedCodes: Array<{ id: string; index: number }>() },
      children: (
        <ICD10CodesSelection
          selectedCodes={currentCodes}
          suggestions={
            suggestions
              ? { data: suggestions, isLoading: isLoadingPatientConditions || isLoadingOrgConditions }
              : undefined
          }
          onHide={({ save, newCodes, deletedCodes }) => {
            save && newCodes && deletedCodes && onSubmit({ newCodes, deletedCodes })
            onReset()
          }}
          saveLabel="Update"
          showSlide={showSlide}
          displayMode="inline"
        />
      ),
      showCloseIcon: false,
      footerClassName: "hidden",
      innerContainerClassName: "overflow-y-hidden h-full",
      onCancel: onReset,
      onSubmit: onSubmit,
    }),
    [showSlide, currentCodes, suggestions, isLoadingPatientConditions, isLoadingOrgConditions, onSubmit],
  )

  useEffect(() => {
    if (!avoidReplacement) {
      if (showSlide) formReplaceContext?.setReplacementContent?.(replacementFormProps)
      else formReplaceContext?.setShowReplacementContent?.(false)
    }
  }, [replacementFormProps])

  return (
    <>
      <FormField
        field={field}
        label={showLabel ? label : undefined}
        horizontal={horizontal}
        labelClassName={labelClassName}
        className={classNames("w-full @container", className)}
        containerClassName={containerClassName}
        showInvalidState
        validation={validate}
      >
        {!readOnly && (
          <AddFieldArrayItemButton
            className="py-3"
            label="Select ICD-10 Codes"
            disabled={disabled}
            onClick={() => setShowSlide(true)}
          />
        )}
        {currentCodes?.length ? (
          <StackedListContainer
            itemsClassName={classNames("py-3", { "opacity-60": disabled })}
            data={currentCodes}
            itemModelBuilder={(item, index) => itemModel(item, index, readOnly ? undefined : deleteItem)}
          />
        ) : (
          !hideEmptyMessage && (
            <div className="flex flex-col items-center justify-center py-5">
              {icon && <FontAwesomeIcon icon={icon} size="lg" className="text-slate-500" />}
              <p className="text-slate-500 text-xs pt-1">No {label ? label.toLowerCase() : "icd-10 codes"} added yet</p>
            </div>
          )
        )}
      </FormField>

      {(!formReplaceContext || !!avoidReplacement) && (
        <ICD10CodesSelection
          selectedCodes={currentCodes ?? []}
          suggestions={
            suggestions
              ? { data: suggestions, isLoading: isLoadingPatientConditions || isLoadingOrgConditions }
              : undefined
          }
          onHide={({ save, newCodes, deletedCodes }) => {
            save && newCodes && deletedCodes && onSubmit({ newCodes, deletedCodes })
            onReset()
          }}
          showSlide={showSlide}
        />
      )}
    </>
  )
}

const itemModel = (coding: Coding, index: number, onDelete?: (index: number) => void): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Code",
          value: coding?.code
            ? `${codeableConceptAsString({ coding: [coding] })} - ${coding?.code}`
            : codeableConceptAsString({ coding: [coding] }),
        },
      ],
    },
  ],
  menu: onDelete && defaultEditRemoveMenu(undefined, () => onDelete(index)),
  menuStyle: MenuStyles.ActionItems,
})

type Props = {
  field: string
  label?: string
  showLabel?: boolean
  readOnly?: boolean
  disabled?: boolean
  className?: string
  askForDeleteConfirmation?: boolean
  orgSuggestedConditions?: OrgDataSettingCode
  validate?: FieldValidator
  containerClassName?: string
  labelClassName?: string
  horizontal?: boolean
  avoidReplacement?: boolean
  hideEmptyMessage?: boolean
  icon?: IconDefinition
} & ConditinonalPatientConditions

type ConditinonalPatientConditions =
  | { showSuggestedPatientConditions: true; patient: Patient }
  | {
      showSuggestedPatientConditions?: false
      patient?: Patient
    }

export { ICD10CodesField }
