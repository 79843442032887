import { useQuery } from "@tanstack/react-query"
import type { Coding } from "fhir"

import { proceduresQueryKeys } from "../query-keys"
import type { InventoryData } from "../types"
import { useMEInventoryQueryFunction } from "./useMEInventory"

const useAlgorithmMKInventory = (catalogId: string, medCodes: Coding[], enabled: boolean) => {
  const queryKey = proceduresQueryKeys.algInventory(medCodes)
  const getMEInventory = useMEInventoryQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { inventoryData } = await getMEInventory(catalogId)

      const inventory =
        medCodes?.reduce<Record<string, InventoryData[]>>(
          (acc, coding) =>
            inventoryData.has(coding.code as string)
              ? { ...acc, [coding.code as string]: inventoryData.get(coding.code as string) as InventoryData[] }
              : acc,
          {},
        ) ?? {}

      return { inventory }
    },
    enabled,
    refetchOnWindowFocus: true,
    meta: { context: { queryKey, catalogId } },
  })

  return {
    isLoading,
    inventory: data?.inventory ?? {},
  }
}

export { useAlgorithmMKInventory }
