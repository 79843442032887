import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type Bundle, type BundleEntryArray, type Device, asBundleEntry, asDeleteBundleEntry } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys, useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

const useDeleteRoom = (onSuccess?: () => void, onSettled?: () => void) => {
  const { devicesByRoom } = useOrganizationContext()
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const deleteRoom = async (roomId: string) => {
    const clearDevices =
      devicesByRoom[roomId]?.reduce<BundleEntryArray[]>(
        (acc, { display, ...device }) => [
          ...acc,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          asBundleEntry(device as Device, { location: null as any }, "PATCH"),
        ],
        [],
      ) ?? []

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [asDeleteBundleEntry({ id: roomId, resourceType: "Location" }), ...clearDevices],
    }
    return transaction(bundle)
  }

  const { mutate: removeRoom, isPending: isDeleting } = useMutation({
    mutationFn: deleteRoom,
    onError: (error: CustomError, roomId) => {
      displayNotificationError(registerErrorTrace(error, { roomId }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.currentOrganization.all })
      displayNotificationSuccess("Room deleted successfully!")
      // datadogLogs.logger.info("Room deleted successfully!", { data })
      onSuccess?.()
    },
    onSettled,
  })

  return { removeRoom, isDeleting }
}

export { useDeleteRoom }
