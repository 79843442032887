import { type FieldProps, Field, ErrorMessage, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useMemo, useReducer } from "react"
import { Dropdown } from "primereact/dropdown"
import type { Patient } from "fhir"
import { format, parseISO, parse, isValid } from "date-fns"

const MAX_POSIBLE_AGE = 150

const BirthdateField: FC<Props> = ({ field, label, className, horizontal }) => {
  const { values, setFieldValue } = useFormikContext<Patient>()
  const { months, days, year, month, day, updateYear, updateMonth, updateDay } = useStateReducer(values.birthDate ?? "")

  const years = useMemo(() => {
    const minYear = new Date().getFullYear() - MAX_POSIBLE_AGE
    const result = Array.from(new Array(MAX_POSIBLE_AGE + 1), (_, i) => ({
      code: i + minYear,
      label: (i + minYear).toString(),
    }))
    return result.sort((a, b) => b.code - a.code)
  }, [])

  const updateFieldValue = (newYear: number, newMonth: number, newDay: number) => {
    const newDate = parse(`${newMonth}-${newDay}-${newYear}`, "M-d-yyyy", new Date())
    if (!isValid(newDate)) {
      setFieldValue(field, "")
    } else {
      setFieldValue(field, format(newDate, "yyyy-MM-dd"))
    }
  }

  return (
    <Field name={field}>
      {({ field: { name }, meta: { touched } }: FieldProps) => (
        <div className={classNames("field flex flex-col relative", className)}>
          {label && (
            <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
              {label}
            </label>
          )}
          <div className="flex justify-between gap-4">
            <Dropdown
              optionLabel="label"
              optionValue="code"
              value={month}
              options={months}
              className={classNames("flex-1 p-inputtext-sm", {
                "p-invalid": touched && !month,
                horizontal: horizontal,
              })}
              onChange={(e) => {
                updateMonth(e.value)
                updateFieldValue(year, e.value, day)
              }}
              placeholder="Month"
            />
            <Dropdown
              options={days}
              value={day}
              onChange={(e) => {
                updateDay(e.value)
                updateFieldValue(year, month, e.value)
              }}
              className={classNames("flex-1 p-inputtext-sm", {
                "p-invalid": touched && !day,
                horizontal: horizontal,
              })}
              optionLabel="label"
              optionValue="code"
              placeholder="Day"
            />
            <Dropdown
              options={years}
              value={year}
              onChange={(e) => {
                updateYear(e.value)
                updateFieldValue(e.value, month, day)
              }}
              className={classNames("flex-1 p-inputtext-sm", {
                "p-invalid": touched && !year,
                horizontal: horizontal,
              })}
              optionLabel="label"
              optionValue="code"
              placeholder="Year"
            />
          </div>

          <div className="flex items-start p-error h-2 mt-1">
            <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      )}
    </Field>
  )
}

const getMonths = (year: number) => {
  const monthList = Array.from({ length: 12 }, (_, i) => ({
    code: i + 1,
    label: new Date(0, i).toLocaleString("en-US", { month: "long" }),
  }))

  const currentDate = new Date()

  if (year === currentDate.getFullYear()) {
    return monthList.filter((month) => month.code <= currentDate.getMonth() + 1)
  }

  return monthList
}

const getDays = (year: number, month: number) => {
  let totalDays = new Date(2000, 1, 0).getDate()

  if (year && month) {
    totalDays = new Date(year, month, 0).getDate()
  } else if (month) {
    totalDays = new Date(new Date().getFullYear(), month, 0).getDate()
  }

  return Array.from({ length: totalDays }, (_, i) => {
    return { code: i + 1, label: (i + 1).toString() }
  })
}

const getInitialState = (year: number, month: number, day: number): State => {
  return {
    months: getMonths(year),
    days: getDays(year, month),
    year,
    month,
    day,
  }
}

const reducer = (state: State, { type, payload }: { type: string; payload: Option[] | number }) => {
  switch (type) {
    case "changeYear": {
      const year = payload as number
      const newDayYear = state.day > getDays(year, state.month).length ? NaN : state.day
      return { ...state, months: getMonths(year), year, days: getDays(year, state.month), day: newDayYear }
    }
    case "changeMonth": {
      const month = payload as number
      const newDayMonth = state.day > getDays(state.year, month).length ? NaN : state.day
      return { ...state, days: getDays(state.year, month), month, day: newDayMonth }
    }
    case "changeDay":
      return { ...state, day: payload as number }
    default:
      return state
  }
}

const useStateReducer = (dateString: string) => {
  const initialDate = parseISO(dateString)
  const [{ months, days, year, month, day }, dispatch] = useReducer(
    reducer,
    getInitialState(initialDate.getFullYear(), initialDate.getMonth() + 1, initialDate.getDate()),
  )

  const updateYear = (year: number) => dispatch({ type: "changeYear", payload: year })
  const updateMonth = (month: number) => dispatch({ type: "changeMonth", payload: month })
  const updateDay = (day: number) => dispatch({ type: "changeDay", payload: day })

  return { months, days, year, month, day, updateYear, updateMonth, updateDay }
}

type State = {
  months: Option[]
  days: Option[]
  year: number
  month: number
  day: number
}

type Option = {
  code: number
  label: string
}

type Props = {
  field: string
  label?: string
  className?: string
  disabled?: boolean
  horizontal?: boolean
}

export { BirthdateField }
