import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Account, ResourceObject } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { creditCardQueryKeys } from "../query-keys"

const useUpdateDefaultCreditCard = ({ onSettled, onSuccess }: UpdateDefaultCreditCardArgs) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedAccount = async ({ creditCardId, account }: { creditCardId: string; account: Account }) => {
    return patch(
      "Account",
      account.id as string,
      {
        defaultCreditCard: creditCardId,
        meta: account.meta,
      } as ResourceObject,
    )
  }
  const { mutate: updateDefaultCreditCard, isPending: isUpdating } = useMutation({
    mutationFn: updatedAccount,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: creditCardQueryKeys.all })
      onSuccess?.()
    },
    onSettled,
    onError: async (error: CustomError) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: creditCardQueryKeys.all })
      }
      displayNotificationError(registerErrorTrace(error))
    },
  })

  return { updateDefaultCreditCard, isUpdating }
}

interface UpdateDefaultCreditCardArgs {
  onSettled?: () => void
  onSuccess?: () => void
}

export { useUpdateDefaultCreditCard }
