import { faFileImport, faHeartPulse } from "@fortawesome/pro-regular-svg-icons"
import { type Bundle, type BundleEntryArray, type Observation, codeableConceptAsString } from "fhir"
import { Column } from "primereact/column"
import type { DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { useEffect, useId, useMemo, useState } from "react"

import { type SkeletonColumn, EmptyMessage, FooterActions, InfiniteScroll, SkeletonLoader } from "commons"
import { vitalSignCodes } from "data"
import { usePatientContext } from "patients"
import { VITAL_CODE } from "vitals"

import { usePatientObservations, usePreviewPatientInfo, useTransferPatientInfo } from "../hooks"
import { ObservationsTable } from "./ObservationsTable"
import { Patient360ImportDataDialog } from "./Patient360ImportDataDialog"

const Patient360VitalsObservations = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const { observationsEntries, isLoading, hasNextPage, fetchNextPage, reloadObservations } = usePatientObservations({
    patientId,
    category: VITAL_CODE,
  })
  const [showPreviewDialog, setShowPreviewDialog] = useState(false)
  const [previewObservationsBundle, setPreviewObservationsBundle] = useState<Bundle>()
  const previewObservationsEntries = useMemo(() => previewObservationsBundle?.entry ?? [], [previewObservationsBundle])
  const hasPreviewObservations = previewObservationsEntries.length > 0

  const [selectedEntries, setSelectedEntries] = useState<BundleEntryArray[]>([])

  const handleSelectionChange = (e: DataTableSelectionMultipleChangeEvent<BundleEntryArray[]>) => {
    const observationsEntries: BundleEntryArray[] = e.value
    setSelectedEntries(observationsEntries)
  }

  const { previewPatientInfo: previewVitals, isLoadingPreview } = usePreviewPatientInfo("vitals", (bundle) => {
    setPreviewObservationsBundle(bundle)
    setShowPreviewDialog(true)
  })
  const { transferPatient, isTransfering } = useTransferPatientInfo(() => {
    reloadObservations?.()
    setShowPreviewDialog(false)
  })

  const handlePreview = () => {
    previewVitals({ patId: patientId })
  }

  const handleTransfer = () => {
    transferPatient({ ...(previewObservationsBundle as Bundle), entry: selectedEntries })
  }

  const handleClosePreviewDialog = () => {
    setShowPreviewDialog(false)
  }

  useEffect(() => {
    setSelectedEntries(previewObservationsEntries)
  }, [previewObservationsEntries])

  const columnsDef: SkeletonColumn[] = [
    { header: "Code", headerStyle: { minWidth: "5rem" } },
    { header: "Value", headerStyle: { minWidth: "8rem" } },
  ]

  const hasUsableAddress = useMemo(() => {
    return patient.address?.some(({ use }) => !!use) ?? false
  }, [patient.address])

  if (isLoading) {
    return <SkeletonLoader repeats={4} containerClassName="pl-5 py-3" loaderType="table" columns={columnsDef} />
  }

  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      containerClassName="pl-5"
      loaderType="table"
      columns={columnsDef.map(({ headerStyle, body }) => ({
        header: undefined,
        headerStyle: headerStyle,
        headerClass: "hidden",
        body: body,
      }))}
    />
  )

  const valueBodyTemplate = ({ value, component, code }: Observation) => {
    const hasQuantity = value?.Quantity?.value && value.Quantity.unit

    if (hasQuantity) {
      const isBPVital = codeableConceptAsString(code) === codeableConceptAsString(vitalSignCodes.bloodPressure)

      if (!isBPVital) {
        const quantity = value?.Quantity
        return quantity ? `${quantity?.value} ${quantity?.unit}` : ""
      }

      const sistolicQuantity = component?.[0].value?.Quantity
      const diastolicQuantity = component?.[1].value?.Quantity

      const sistolicDisplay = sistolicQuantity?.value
        ? `Sistolic: ${sistolicQuantity?.value} ${sistolicQuantity?.unit}`
        : ""
      const diastolicDisplay = diastolicQuantity?.value
        ? `Diastolic: ${diastolicQuantity?.value} ${diastolicQuantity?.unit}`
        : ""

      return `${sistolicDisplay} ${diastolicDisplay}`
    }

    return value?.string ?? value?.integer ?? "unspecified"
  }

  const addOptions = [
    {
      icon: faFileImport,
      label: "Import vitals",
      command: () => handlePreview(),
      loading: isLoadingPreview,
      disabled: !hasUsableAddress,
    },
  ]

  return (
    <>
      {!observationsEntries?.length ? (
        <div className="h-full w-full flex items-center justify-center">
          <EmptyMessage
            icon={faHeartPulse}
            message="No vitals found"
            subMessage={
              !hasUsableAddress
                ? "Get started by linking addresses to search"
                : "Get started by importing patient vitals"
            }
            action={handlePreview}
            actionText="Import vitals"
            actionIcon={faFileImport}
            isLoading={isLoadingPreview}
            disabled={!hasUsableAddress}
          />
        </div>
      ) : (
        <>
          <div className="h-full overflow-auto">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
              <ObservationsTable
                tableDataKey="resource.identifier.0.value"
                observationsEntries={observationsEntries ?? []}
                onSelectionChange={handleSelectionChange}
              >
                <Column
                  field="resource.code"
                  header="Code"
                  body={({ resource: { code } }) => (
                    <p title="Code" className="font-bold mr-2">
                      {codeableConceptAsString(code)}
                    </p>
                  )}
                  headerStyle={{ minWidth: "8rem" }}
                />
                <Column
                  field="resource.value.Quantity"
                  header="Value"
                  body={(rowData) => valueBodyTemplate(rowData.resource)}
                  headerStyle={{ minWidth: "8rem" }}
                />
              </ObservationsTable>
            </InfiniteScroll>
          </div>
          {!showPreviewDialog && <FooterActions actions={addOptions} />}
        </>
      )}

      <Patient360ImportDataDialog
        showDialog={showPreviewDialog}
        handleCloseDialog={handleClosePreviewDialog}
        header="Vitals Preview"
        isLoading={isTransfering}
        actionDisabled={isTransfering || !hasPreviewObservations}
        handleDialogAction={handleTransfer}
        totalRecords={previewObservationsEntries.length}
      >
        <ObservationsTable
          tableDataKey="resource.identifier.0.value"
          observationsEntries={previewObservationsEntries}
          selectedEntries={selectedEntries}
          onSelectionChange={handleSelectionChange}
        >
          <Column
            field="resource.code"
            header="Code"
            body={({ resource: { code } }) => (
              <p title="Code" className="font-bold mr-2">
                {codeableConceptAsString(code)}
              </p>
            )}
            headerStyle={{ minWidth: "8rem" }}
          />
          <Column
            field="resource.value.Quantity"
            header="Value"
            body={(rowData) => valueBodyTemplate(rowData.resource)}
            headerStyle={{ minWidth: "8rem" }}
          />
        </ObservationsTable>
      </Patient360ImportDataDialog>
    </>
  )
}

export { Patient360VitalsObservations }
