import { useQuery } from "@tanstack/react-query"
import { type Device, type Location, getResources, getResourcesByTypeAsIndex } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { SYSTEM_VALUES } from "system-values"

import { readerQueryKeys } from "../query-keys"

const useReaders = (organizationId: string, readerIds?: string[], enabled?: boolean) => {
  const { search } = useClient()
  const queryKey = readerQueryKeys.ids(organizationId, readerIds ?? [])

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
        identifier: `${SYSTEM_VALUES.STRIPE_READER}|`,
        _include: "location",
        ...(readerIds?.length ? { _id: readerIds.join(",") } : {}),
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/Device`, filters, signal })

      const orgReaders = getResources<Device>(bundle, "Device")
      const readerLocations = getResourcesByTypeAsIndex<Location>(bundle, "Location")

      return { orgReaders, readerLocations }
    },
    enabled: !!organizationId && enabled !== false,
    throwOnError: true,
    meta: { context: { queryKey, organizationId } },
  })

  const locationsByReader = useMemo(() => {
    return data?.orgReaders.reduce(
      (acc, { id, location }) => {
        const readerLocation = data.readerLocations?.[location?.id as string]
        return readerLocation && id ? { ...acc, [id]: readerLocation } : { ...acc }
      },
      {} as Record<string, Location>,
    )
  }, [data?.orgReaders, data?.readerLocations])

  return { readers: data?.orgReaders, locationsByReader, isLoading }
}

export { useReaders }
