import { type MedicationKnowledge, codeableConceptAsString } from "fhir"
import type { FC } from "react"

const MedicationItemDetail: FC<Props> = ({ mk }) => {
  return (
    <li className="whitespace-nowrap flex flex-col">
      <span className="font-normal text-sm text-slate-500 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">
        {codeableConceptAsString(mk.code)}
      </span>
      {mk.manufacturer?.display && <span className="font-light text-gray-400 text-sm">{mk.manufacturer?.display}</span>}
      {mk.packaging && (
        <span className="font-light text-gray-400 text-sm">{`Packaging: ${mk.packaging?.quantity?.value ?? "unspecified"} ${mk.packaging?.quantity?.unit ?? "unspecified"}`}</span>
      )}
    </li>
  )
}

type Props = { mk: MedicationKnowledge }

export { MedicationItemDetail }
