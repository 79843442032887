import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { isCarePlan, type Annotation, type MedicationKnowledge, type MedicationRequest } from "fhir"
import { type FC, useState } from "react"
import { classNames } from "primereact/utils"

import { useOrganizationContext } from "organization"
import { MedicationRequestListItem } from "medication-requests"
import { ModulesId, useChargeItemDefinitions } from "commons"
import {
  MedicationKnowledgeDetails,
  type MedicationRequestData,
  medsQueryKeys,
  useMedicationRequestDataBind,
  useMrOrdersResources,
} from "commons/meds"
import { useChartContext } from "chart-view"
import { SYSTEM_VALUES } from "system-values"

import { WrapUpSection } from "./WrapUpSection"
import { OrderItem } from "./OrderItem"
import { type EditProps, renderNotes } from "./utils"
import { useEncounterContext } from "../../hooks"

const NutrasSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()
  const { currentOrganizationId: organizationId } = useOrganizationContext()

  const [previewItem, setPreviewItem] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { planRefs } = useEncounterContext()

  const { mrOrderData, medCodes, medicationKnowledges, medicationRequests, medicationDispenses, isLoading, total } =
    useMrOrdersResources({
      patientId,
      subcategory: "nutraceutical-order",
      encounter: encounterId,
      statusFilter: ["active", "completed"],
    })

  const goToOrderDetails = (order: string) => {
    showModule({
      module: ModulesId.MEDICATIONR,
      moduleParams: { order },
    })
  }

  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId,
    codes: {
      billToPatientCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: chargeItemDefinitions?.billToPatientCIDs ?? {},
    serviceRequests: mrOrderData?.flatMap(({ serviceRequest }) => serviceRequest),
    medicationDispenses,
  })

  const renderDetails = ({
    notes,
    className,
    medicationRequestData,
    editProps,
  }: {
    medicationRequestData?: MedicationRequestData[]
    notes?: Annotation[]
    className?: string
    editProps?: EditProps
  }) => (
    <>
      {!!medicationRequestData?.length && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          {medicationRequestData.map((item) => {
            const cp = planRefs?.[item.medicationRequestInfo.basedOn?.find(isCarePlan)?.id as string]
            return (
              <MedicationRequestListItem
                key={`${item.medicationRequestInfo.id}-${item.medicationRequestInfo.status}`}
                medicationData={item}
                showActions={false}
                imageClassName="w-24 h-24"
                itemClassName="!pl-0 !text-gray-400"
                showPrice={false}
                showDispenseInfo={false}
                onSelectMK={(mk) => setPreviewItem({ mk, mr: item.medicationRequestInfo })}
                badge={
                  cp
                    ? {
                        text: cp.display ?? "Plan based",
                        colorStyle: "gray",
                        title: cp.display,
                        className: "truncate max-w-24 !block",
                      }
                    : { text: "", className: "hidden" }
                }
              />
            )
          })}
        </div>
      )}
      {renderNotes({ notes, className, editProps })}
    </>
  )

  return (
    <WrapUpSection
      sectionTitle="Nutraceuticals"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No nutraceutical's orders placed during this encounter"
      className="w-full self-end pb-2 gap-6"
    >
      {mrOrderData?.map(({ serviceRequest: sr, invoices }) => {
        const medOrderIdentifier = sr?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)

        return (
          <OrderItem
            key={sr?.id}
            requester={sr?.requester}
            status={sr?.status}
            invoice={invoices?.[0]}
            title={medOrderIdentifier?.value ?? "Unspecified number"}
            date={sr?.authoredOn ? new Date(sr.authoredOn) : undefined}
            seeOrderDetails={() => goToOrderDetails(sr?.id as string)}
            className="first:mt-6"
          >
            {renderDetails({
              notes: sr?.note,
              medicationRequestData: medicationRequestData.filter(
                ({ serviceRequest }) => serviceRequest?.id === sr?.id,
              ),
              className: "text-sm pr-2",
              editProps: sr?.id
                ? {
                    orderId: sr.id,
                    queriesToInvalidate: medsQueryKeys.ordersResources(
                      patientId,
                      "nutraceutical-order",
                      ["active", "completed"],
                      undefined,
                      encounterId,
                    ),
                  }
                : undefined,
            })}
          </OrderItem>
        )
      })}
      <MedicationKnowledgeDetails
        selectedMK={previewItem?.mk}
        onHide={() => setPreviewItem(undefined)}
        mr={previewItem?.mr}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { NutrasSection }
