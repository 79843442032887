import { useMutation } from "@tanstack/react-query"
import type { Bundle, MedicationRequest, Parameters } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"

import type { EditOrderData } from "../types"

const useUpdateMrOrder = () => {
  const { operationRequest } = useClient()
  const { patientId } = usePatientContext()

  const parameters = ({ orderId, medicationRequests, transient, markedToDelete }: EditOrderData): Parameters => {
    const { editedMeds, removedMeds } = medicationRequests.reduce(
      (acc, mr) => {
        if (markedToDelete?.includes(mr.id as string)) {
          return { ...acc, removedMeds: [...acc.removedMeds, mr] }
        }
        return { ...acc, editedMeds: [...acc.editedMeds, mr] }
      },
      { editedMeds: Array<MedicationRequest>(), removedMeds: Array<MedicationRequest>() },
    )
    return {
      resourceType: "Parameters",
      parameter: [
        { name: "order", value: { Reference: { resourceType: "ServiceRequest", id: orderId } } },
        { name: "transient", value: { boolean: transient } },
        ...(editedMeds.length
          ? [
              {
                name: "edited-meds",
                part: editedMeds.map((mr) => ({
                  name: "med",
                  resource: mr,
                })),
              },
            ]
          : []),
        ...(removedMeds.length
          ? [
              {
                name: "removed-meds",
                part: removedMeds.map((mr) => ({
                  name: "med",
                  resource: mr,
                })),
              },
            ]
          : []),
      ],
    }
  }

  const update = async (data: EditOrderData) =>
    operationRequest<Bundle>({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "POST",
      operation: "edit",
      parameters: parameters(data),
    })

  const { mutate: updateOrder, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    // onSettled: (_, error, { transient, orderId }) => {
    //   if (!error && !transient) datadogLogs.logger.info(`Order ${orderId} updated successfully!`)
    // },
  })

  return { updateOrder, isUpdating }
}

export { useUpdateMrOrder }
