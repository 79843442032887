import { useMutation } from "@tanstack/react-query"
import { type Bundle, type Parameters, type Patient, getResource } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import type { PatientFormData } from "../types"

const useCreatePatient = (hideForm: () => void, onCreateSuccess: (data: Patient) => void) => {
  const { operationRequest } = useClient()

  const parameters = ({ patient, consents, invite }: PatientFormData): Parameters => ({
    resourceType: "Parameters",
    parameter: [
      {
        name: "consents",
        part: consents.map((Coding) => ({
          name: "category",
          value: {
            Coding,
          },
        })),
      },
      { name: "patient", resource: { resourceType: "Patient", ...patient } },
      {
        name: "invite",
        value: {
          boolean: invite,
        },
      },
    ],
  })

  const newPatient = async (data: PatientFormData) =>
    operationRequest<Bundle>({ endpoint: "Patient", method: "POST", operation: "create", parameters: parameters(data) })

  const { mutate: createPatient, isPending: isAdding } = useMutation({
    mutationFn: newPatient,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: (bundle) => {
      const patient = getResource<Patient>(bundle as Bundle, "Patient")
      onCreateSuccess(patient)
      displayNotificationSuccess("Patient created successfully!")
      // datadogLogs.logger.info(`Patient ${patient.id} created successfully!`, patient)
    },
    onSettled: hideForm,
  })

  return { createPatient, isAdding }
}

export { useCreatePatient }
