import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import type { Coding } from "fhir"
import { type FC, useState } from "react"

import { useValueSet } from "value-set"

import type { StackedListItemProps } from "../../components/StackedListItem"
import { getRestrictedLabPerformer, useGetLabPerformer } from "../../labs"
import { type SuggestionProps, ValueSetIds } from "../../types"
import { CheckMultipleWithFilter } from "../CheckMultipleWithFilter"
import { CheckMultipleWithSlideoverFilter } from "../CheckMultipleWithSlideoverFilter"

const ICD10CodesSelection: FC<Props> = ({
  selectedCodes,
  showSlide,
  suggestions,
  onHide,
  displayMode = "slideover",
  saveLabel,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>()
  const { codes, isFetching } = useValueSet({
    valueSetId: ValueSetIds.ICD10,
    filter: searchFilter,
    enabled: !!searchFilter,
  })
  const labPerformer = useGetLabPerformer()
  const maxSelectedItemsAllowed = getRestrictedLabPerformer(labPerformer?.id as string)?.maxAmount ?? undefined

  return displayMode === "inline" ? (
    <CheckMultipleWithFilter
      onHide={() => onHide({ save: false })}
      filterProps={{
        autoFocus: true,
        isLoading: isFetching,
        onTextFilter: setSearchFilter,
        placeholder: "Please type to search",
      }}
      acceptLabel={saveLabel}
      icon={faSearch}
      emptyLabel="No ICD-10 Codes Selected"
      initialCheckedValues={selectedCodes}
      itemModel={itemModel}
      queryProps={{ data: codes, isFetching }}
      onSave={(newCodes, deletedCodes) => onHide({ save: true, newCodes, deletedCodes })}
      getItemId={({ code }) => code as string}
      getItemText={({ code }) => `${code}`}
      verifyExistInData
      suggestionProps={suggestions}
      maxSelectedItemsAllowed={maxSelectedItemsAllowed}
    />
  ) : (
    <CheckMultipleWithSlideoverFilter
      isVisible={showSlide}
      onHide={() => onHide({ save: false })}
      acceptLabel={saveLabel}
      filterProps={{
        autoFocus: true,
        isLoading: isFetching,
        onTextFilter: setSearchFilter,
        placeholder: "Please type to search",
      }}
      icon={faSearch}
      emptyLabel="No ICD-10 Codes Selected"
      initialCheckedValues={selectedCodes}
      itemModel={itemModel}
      queryProps={{ data: codes, isFetching }}
      onSave={(newCodes, deletedCodes) => onHide({ save: true, newCodes, deletedCodes })}
      getItemId={({ code }) => code as string}
      getItemText={({ code }) => `${code}`}
      verifyExistInData
      suggestionProps={suggestions}
      title="Select ICD-10 Codes"
      maxSelectedItemsAllowed={maxSelectedItemsAllowed}
    />
  )
}

const itemModel = (code: Coding): StackedListItemProps => ({
  leftData: [{ lineItems: [{ name: "Code", value: `${code.display} - ${code.code}` }] }],
})

type Props = {
  showSlide: boolean
  selectedCodes: Coding[]
  suggestions?: SuggestionProps<Coding>
  onHide({
    save,
    newCodes,
    deletedCodes,
  }: {
    save?: boolean
    newCodes?: Coding[]
    deletedCodes?: { id: string; index: number }[]
  }): void
  displayMode?: "slideover" | "inline"
  saveLabel?: string
}

export { ICD10CodesSelection }
