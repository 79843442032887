import { useSuspenseQuery } from "@tanstack/react-query"
import { type Appointment, getResource } from "fhir"
import { parseISO } from "date-fns/parseISO"

import { useClient } from "api"

import { patientAptsQueryKeys } from "../query-keys"

const useAppointment = (patientId: string, appointmentId?: string) => {
  const { search } = useClient()
  const queryKey = patientAptsQueryKeys.list(patientId, appointmentId)

  const { data, isLoading, isError, error } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _elements: "id,start,end,description,participant,appointmentType,status,comment,minutesDuration",
        _count: "1",
        ...(appointmentId ? { _id: appointmentId } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Appointment`, filters, signal })

      const appointment = getResource<Appointment>(bundle, "Appointment")

      const newAppointment = appointment && {
        ...appointment,
        ...{
          start: appointment.start && parseISO(appointment.start.toString()),
          end: appointment.end && parseISO(appointment.end.toString()),
        },
      }

      return { appointment: newAppointment, total: bundle?.total ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
  })

  if (isError) {
    throw error
  }

  return {
    appointment: data?.appointment,
    isLoading,
    total: data.total,
  }
}

export { useAppointment }
