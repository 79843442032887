import type { Annotation } from "fhir"
import { classNames } from "primereact/utils"
import type { QueryKey } from "@tanstack/react-query"

import { OrderNote } from "commons"

const renderNotes = ({
  notes,
  className,
  editProps,
}: {
  notes?: Annotation[]
  className?: string
  editProps?: EditProps
}) => {
  const itemNotes = notes?.filter(({ text }) => !!text)
  return (
    <>
      {!!itemNotes?.length && (
        <div className={classNames("flex flex-col text-sm mb-2 relative", className)}>
          <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
            Notes
            <hr className="flex-1 ml-1" />
          </span>

          {editProps ? (
            <OrderNote
              orderNote={itemNotes}
              orderId={editProps.orderId}
              queriesToInvalidate={editProps.queriesToInvalidate}
              contentClassName={className}
              inputRows={3}
              buttonClassName="absolute -top-1 right-0 z-10 bg-white"
            />
          ) : (
            itemNotes.map(({ text }, index) => <p key={index}>{text}</p>)
          )}
        </div>
      )}
    </>
  )
}

export type EditProps = { orderId: string; queriesToInvalidate: QueryKey }

export { renderNotes }
