import { useMutation } from "@tanstack/react-query"
import {
  type Bundle,
  type BundleEntryArray,
  type CarePlan,
  type CodeableConcept,
  type Coding,
  type DiagnosticReport,
  type Observation,
  type Reference,
  type ServiceRequest,
  asReference,
  getResource,
} from "fhir"
import { v4 } from "uuid"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"

import type { CalculatorOutput } from "../types"
import { generateCalculatorResultFromCarePlan } from "../utils"

const useEvalCalculator = (
  onSuccess?: ({ suggestedMeds, recommended, notes }: CalculatorOutput) => void,
  onSettled?: () => void,
) => {
  const { transaction, operationRequest } = useClient()

  const calc = async ({
    qrId,
    observations,
    patientRef,
    requester,
    runAlgorithmCode,
    icd10,
  }: {
    qrId: string
    observations: Observation[]
    patientRef: Reference
    requester: Reference
    runAlgorithmCode: Coding
    icd10?: CodeableConcept
  }) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [],
    }
    const drUrl = `urn:uuid:${v4()}`

    if (observations.length > 0) {
      const obData = observations.reduce<{ entries: Array<BundleEntryArray>; references: Array<Reference> }>(
        (acc, ob) => {
          if (ob.id) {
            return { entries: acc.entries, references: [...acc.references, asReference(ob)] }
          } else {
            const obUrl = `urn:uuid:${v4()}`
            const obEntry: BundleEntryArray = {
              fullUrl: obUrl,
              request: {
                method: "POST",
                url: "Observation",
              },
              resource: ob,
            }
            return { entries: [...acc.entries, obEntry], references: [...acc.references, { uri: obUrl }] }
          }
        },
        { entries: [], references: [] },
      )
      bundle.entry?.push(...obData.entries)

      const dr: DiagnosticReport = {
        category: [
          {
            coding: [
              {
                system: SYSTEM_VALUES.V2_0074,
                code: "LAB",
                display: "Laboratory",
              },
            ],
            text: "Laboratory",
          },
        ],
        subject: patientRef,
        code: {
          text: "Entered",
          coding: [
            {
              system: SYSTEM_VALUES.TEMPORARY_CODES,
              code: "entered",
              display: "Entered",
            },
          ],
        },
        status: "final",
        issued: new Date(),
        result: obData.references,
      }
      bundle.entry?.push({
        fullUrl: drUrl,
        request: {
          method: "POST",
          url: "DiagnosticReport",
        },
        resource: dr,
      })
    }

    const sr: ServiceRequest = {
      resourceType: "ServiceRequest",
      status: "active",
      intent: "plan",
      category: [
        {
          coding: [
            {
              system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
              code: "algorithm-order",
              display: "Algorithm Order",
            },
          ],
          text: "Algorithm Order",
        },
      ],
      code: { coding: [runAlgorithmCode] },
      subject: patientRef,
      requester,
      reasonCode: icd10 ? [icd10] : [],
      supportingInfo: [
        {
          resourceType: "QuestionnaireResponse",
          id: qrId,
        },
        ...(observations.length > 0
          ? [
              {
                uri: drUrl,
              },
            ]
          : []),
      ],
    }
    bundle.entry?.push({
      request: {
        method: "POST",
        url: "ServiceRequest",
      },
      resource: sr,
    })

    const respBundle = await transaction<Bundle>(bundle)
    const serviceRequest = getResource<ServiceRequest>(respBundle, "ServiceRequest")

    const algorithmBundle = await operationRequest<Bundle>({
      endpoint: "ServiceRequest",
      method: "GET",
      operation: "eval",
      id: serviceRequest.id,
    })
    const cp = getResource<CarePlan>(algorithmBundle, "CarePlan")
    const calculatorResult = generateCalculatorResultFromCarePlan(cp)

    return calculatorResult
  }

  const { mutateAsync: evalCalculator, isPending } = useMutation({
    mutationFn: calc,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess,
    onSettled,
  })

  return { evalCalculator, isEvaluating: isPending }
}

export { useEvalCalculator }
