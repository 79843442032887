import { labOrdersQueryKey } from "commons/labs"

const labOrdersQueryKeys = {
  withPatientID: (patientId?: string) => [...labOrdersQueryKeys.all, patientId],
  observations: (patientId: string, searchText?: string) => ["patient/lab-order-observations", searchText, patientId],
  catalogs: (laboratoryId?: string) => ["patient/laboratoryCatalogs", laboratoryId],
  ...labOrdersQueryKey,
}

export { labOrdersQueryKeys }
