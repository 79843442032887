import type { FC, PropsWithChildren } from "react"

import { type State, PrescriptionSigContext } from "../../../hooks/usePrescriptionSigContext"

const PrescriptionSigProvider: FC<PropsWithChildren<State>> = ({
  children,
  medRecommendedDosage,
  getAdministrationGuideline,
}) => {
  return (
    <PrescriptionSigContext.Provider value={{ medRecommendedDosage, getAdministrationGuideline }}>
      {children}
    </PrescriptionSigContext.Provider>
  )
}

export { PrescriptionSigProvider, PrescriptionSigContext }
