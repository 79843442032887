import { faCalendarDays, faSearch } from "@fortawesome/pro-regular-svg-icons"
import {
  faClockRotateLeft,
  faFileInvoiceDollar,
  faHandHoldingBox,
  faPaperPlane,
  faPencil,
  faPills,
  faSyncAlt,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { type MedicationKnowledge, type MedicationRequest, codeableConceptAsString } from "fhir"
import { Chip } from "primereact/chip"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { TabPanel, TabView } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  MedicationKnowledgeDetails,
  OrderNote,
  SkeletonLoader,
  useChargeItemDefinitions,
  useSendToPatient,
} from "commons"
import {
  ActivityHistory,
  MedicationCancelDialog,
  MedicationOrderDetailHeader,
  MedicationOrderTaskListItem,
  MedicationRequestItem,
  MedicationRescheduleDialog,
  medsQueryKeys,
  useGetMedicationsProductPrices,
  useMrOrderDetails,
  usePrescriptionMrOrder,
  useCancelMrOrder,
} from "commons/meds"
import { BILLING_TYPES_CODES, formatsByTypes, MEDICATION_PRODUCT_TYPE } from "data"
import { InvoiceListItem } from "invoices"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getMoneyCurrencyAlt, getStringAddress } from "utils"

import { useHoldMrOrder, useMedicationRequestDataBind, useRescheduleMrOrder } from "../../hooks"
import { getStatusClass } from "../helpers"
import { OrderHistory } from "./OrderHistory"

const MedicationOrderDetails = () => {
  const { patientId } = usePatientContext()
  const { currentOrganizationId } = useOrganizationContext()
  const {
    rescheduleDialogVisible,
    cancelDialogVisible,
    rescheduleDate,
    cancelReason,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
  } = useStateReducer()
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const [params, setParams] = useSearchParams()

  const orderId = params.get("order")

  const [cancelFutureOrder, setCancelFutureOrder] = useState("skip")
  const [refundItemsAsWell, setRefundItemsAsWell] = useState(false)

  const handleOnHideCancel = () => {
    hideCancelDialog()
    setCancelFutureOrder("skip")
    setRefundItemsAsWell(false)
  }

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medCodes,
    medicationDispenses,
    tasks,
    invoice,
    invoices,
    isLoading,
    missingInfoMessages,
    billingTypeCode,
    provenances,
    isEditable,
    hasInvalidMD,
    dispenseTrackCodes,
    history,
    medicationActivityHstory,
  } = useMrOrderDetails(patientId, orderId as string)
  const getChargeItemDefinitions =
    billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT ? useGetMedicationsProductPrices : useChargeItemDefinitions

  const { chargeItemDefinitions } = getChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? { billToPatientCIDs: medCodes }
        : { billToPracticeOrInsuranceCIDs: medCodes }),
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs:
      billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? chargeItemDefinitions?.billToPatientCIDs ?? {}
        : chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  const { cancelMrOrder, isCancelling } = useCancelMrOrder({
    context: MEDICATION_PRODUCT_TYPE.RX,
    onSettled: hideCancelDialog,
  })
  const { holdMrOrder, isHolding } = useHoldMrOrder()
  const { rescheduleMrOrder, isRescheduling } = useRescheduleMrOrder()
  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()
  const { sendOrderToPatient } = useSendToPatient()

  const { setAlertBannerMessage } = useChartContext()

  const confirmAction = (action: string, accept: () => void) => {
    confirmDialog({
      message: `Are you sure you want to ${action}?`,
      header: "Confirmation",
      acceptLabel: "Ok",
      rejectLabel: "Cancel",
      rejectClassName: "button-default p-button-sm",
      acceptClassName: "button-primary p-button-sm",
      accept: accept,
    })
  }

  const showInvoice = useCallback(
    (invoiceId: string) => {
      params.delete("subview")
      params.delete("order")
      params.set("view", "invoice")
      params.set("invoiceId", invoiceId)
      setParams(params)
    },
    [params, setParams],
  )

  const goToEditOrder = useCallback(
    (orderId: string) => {
      params.delete("subview")
      params.delete("order")
      params.append("edit-order", orderId)
      setParams(params)
    },
    [params],
  )

  const showOrder = useCallback(
    (orderId: string) => {
      params.delete("subview")
      params.delete("order")
      params.append("order", orderId)
      setParams(params)
    },
    [params],
  )

  const activeActions = useMemo(() => {
    const rescheduleDateString =
      serviceRequest?.occurrence?.dateTime ?? serviceRequest?.authoredOn ?? new Date().toISOString()
    let rescheduleDate = new Date(rescheduleDateString)
    if (rescheduleDate < new Date()) rescheduleDate = new Date()

    const dropdownItems = [
      {
        label: "Prescription",
        icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
        command: () => getPrescription({ serviceRequestId: orderId as string }),
        loading: isGettingPrescription,
      },
      {
        label: "Reschedule",
        icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
        disabled: invoice?.status === "balanced",
        command: () => {
          updateRescheduleDate(rescheduleDate)
          showRescheduleDialog()
        },
        loading: isRescheduling,
      },
      {
        label: "Edit",
        icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
        disabled: !orderId || !serviceRequest?.id || !invoice?.id || !isEditable,
        command: () => goToEditOrder(orderId as string),
      },
      {
        label: "Refresh Prescription",
        icon: <FontAwesomeIcon icon={faSyncAlt} size="sm" className="mr-2" />,
        command: () => getPrescription({ serviceRequestId: orderId as string, forceRefresh: true }),
      },
      {
        label: "Hold",
        icon: <FontAwesomeIcon icon={faHandHoldingBox} size="sm" className="mr-2" />,
        disabled: invoice?.status === "balanced",
        command: () => confirmAction("hold on this order", () => holdMrOrder(orderId as string)),
      },
      ...(invoice
        ? [
            {
              label: "See Invoice",
              icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
              command: () => showInvoice(invoice.id ?? ""),
            },
            {
              label: "Send to patient",
              icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
              command: () =>
                confirmAction("send this order to the patient", () => sendOrderToPatient(invoice?.id ?? "")),
            },
          ]
        : []),
      {
        label: "Cancel",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
        command: showCancelDialog,
      },
    ]

    switch (serviceRequest?.status) {
      case "active":
        return dropdownItems
      case "completed":
        return dropdownItems.filter(
          (item) => item.label === "Prescription" || item.label === "Edit" || (item.label === "Cancel" && hasInvalidMD),
        )
      default:
        return dropdownItems.filter((item) => item.label === "Prescription")
    }
  }, [
    orderId,
    serviceRequest,
    holdMrOrder,
    getPrescription,
    showCancelDialog,
    showRescheduleDialog,
    updateRescheduleDate,
    showInvoice,
    sendOrderToPatient,
    invoice,
    isRescheduling,
    isGettingPrescription,
  ])

  useEffect(() => {
    setAlertBannerMessage(
      missingInfoMessages && missingInfoMessages.length > 0 && (
        <div className="flex space-x-10 items-center justify-center">
          <div className="flex flex-col">
            {missingInfoMessages.map((msg, index) => (
              <span key={index}>{msg}</span>
            ))}
          </div>
          <div className="flex flex-row justify-end">
            <button className="underline ml-4" onClick={() => showInvoice(invoice?.id ?? "")}>
              See invoice
            </button>
          </div>
        </div>
      ),
    )

    return () => {
      setAlertBannerMessage()
    }
  }, [missingInfoMessages, setAlertBannerMessage, showInvoice])

  useEffect(() => {
    // Close all dialogs when order status changes from 'active'
    if (serviceRequest?.status !== "active") {
      hideCancelDialog()
      hideRescheduleDialog()
    }
  }, [serviceRequest?.status])

  const shippingAddress = useMemo(
    () => medicationRequests?.[0]?.dispenseRequest?.shippingAddress,
    [medicationRequests?.[0]],
  )

  return (
    <div className="flex flex-col h-full p-3 overflow-hidden">
      {isLoading ? (
        <>
          <SkeletonLoader loaderType="two-lines" repeats={3} extraLine />
        </>
      ) : (
        <div className="@container flex flex-col flex-1 gap-4 pb-6 overflow-y-scroll text-gray-600">
          <MedicationOrderDetailHeader
            className="flex flex-col gap-1 border-b pb-3"
            serviceRequest={serviceRequest}
            invoices={invoices}
            activeActions={activeActions}
            menuIsLoading={isCancelling || isHolding}
          />
          <TabView className="flex flex-col h-full" panelContainerClassName="grow">
            <TabPanel
              header={<span className="text-sm">Details</span>}
              contentClassName="flex flex-col gap-4 overflow-y-scroll p-1 pt-4"
            >
              <div className="flex flex-col p-2 border rounded-lg">
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <label className="font-semibold m-2">Medications:</label>
                  </div>
                </div>
                {!medicationRequestData?.length ? (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                    <p className="text-md text-slate-400 p-4 place-self-center">No medications requested</p>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 @7xl:grid-cols-2 gap-2 2xl:gap-3 pb-4 text-sm content-start">
                    {medicationRequestData?.map((medicationData) => {
                      const dosageInstructions = medicationData.medicationRequestInfo.dosageInstruction
                      const lastMedicationDispense = medicationData.medicationDispense?.at(-1)
                      const pharmacyStatus =
                        lastMedicationDispense?.statusReason?.CodeableConcept &&
                        codeableConceptAsString(lastMedicationDispense?.statusReason?.CodeableConcept)

                      const medProvenance = provenances?.[medicationData.medicationRequestInfo.id as string]
                      const trackingCode = dispenseTrackCodes.filter(
                        (td) => td.mrId === (medicationData.medicationRequestInfo.id as string),
                      )
                      return (
                        <div
                          key={medicationData.medicationRequestInfo.id}
                          className={classNames(
                            "grid grid-flow-col grid-cols-4 gap-2 lg:gap-4 pt-4",
                            medicationRequestData.indexOf(medicationData) !== 0 && "border-t",
                          )}
                        >
                          <div className="flex col-span-3">
                            <MedicationRequestItem
                              medicationRequest={medicationData.medicationRequestInfo}
                              medicationKnowledge={medicationData.medicationKnowledge ?? {}}
                              showPackagingType
                              amount={
                                medicationData.medicationRequestInfo.dispenseRequest?.quantity?.value ??
                                medicationData.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
                                1
                              }
                              className="ml-3"
                              onClick={() =>
                                setSelectedMK({
                                  mk: medicationData.medicationKnowledge,
                                  mr: medicationData.medicationRequestInfo,
                                })
                              }
                            />
                          </div>
                          <div className="flex flex-col gap-2 row-start-1 col-span-1 justify-start items-end">
                            {!!medicationData.productPrice?.value && (
                              <span title="Price" className="font-semibold text-lg">
                                {getMoneyCurrencyAlt(medicationData.productPrice.currency)}
                                {medicationData.productPrice.value.toFixed(2)}
                              </span>
                            )}
                            {pharmacyStatus && (
                              <div
                                title="Pharmacy Status"
                                className="font-bold text-sm text-ellipsis text-gray-500 -mt-1"
                              >
                                {pharmacyStatus}
                              </div>
                            )}
                            <span title="Status">
                              <Chip
                                label={medicationData.medicationRequestInfo.status}
                                className={`custom-chip default capitalize text-sm ${getStatusClass(
                                  medicationData.medicationRequestInfo.status,
                                )}`}
                              />
                            </span>
                          </div>
                          <div className="row-start-2 col-start-1 col-span-4 ml-3">
                            {dosageInstructions?.length && (
                              <div title="Instructions" className="gap-x-4 ">
                                {dosageInstructions?.map((instruction, index) => (
                                  <p key={instruction.id ?? index}>{instruction.text}</p>
                                ))}
                              </div>
                            )}
                          </div>
                          {medProvenance?.length ? (
                            <div className="row-start-3 col-start-1 col-span-4 flex flex-col gap-1 ml-3">
                              <span className="text-gray-900 font-medium">Dispense History:</span>
                              <div className="flex flex-col">
                                {medProvenance.map(({ provenance, trackingCode }, index) => {
                                  const activityName = codeableConceptAsString(provenance.activity)
                                  return (
                                    <div
                                      className="flex mt-2 items-center"
                                      title="Activity"
                                      key={provenance.id ?? index}
                                    >
                                      <span>{activityName}</span>
                                      {!!provenance.occurred?.dateTime && (
                                        <div className="flex items-center ml-2 pl-2 border-l" title="Ocurred">
                                          <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mx-1" />
                                          {formatDate(parseISO(provenance.occurred.dateTime), formatsByTypes.LONG_DATE)}
                                        </div>
                                      )}
                                      {activityName === "Shipped" && !!trackingCode && (
                                        <span
                                          key={index}
                                          className="border-l font-semibold ml-2 pl-2"
                                          title="Tracking Number"
                                        >
                                          #{trackingCode}
                                        </span>
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          ) : trackingCode.length ? (
                            <div className="row-start-3 col-start-1 col-span-4 flex flex-col gap-1 ml-3">
                              <span className="text-gray-900 font-medium">Dispense History:</span>
                              <div className="flex flex-col">
                                {trackingCode.map(({ identifier, status, dateShipped }, index) => {
                                  return (
                                    <div className="flex mt-2 items-center" title="Activity" key={index}>
                                      <span>{status}</span>
                                      {!!dateShipped && (
                                        <div className="flex items-center ml-2 pl-2 border-l" title="Ocurred">
                                          <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mx-1" />
                                          {formatDate(parseISO(dateShipped), formatsByTypes.LONG_DATE)}
                                        </div>
                                      )}
                                      {!!identifier && (
                                        <span
                                          key={index}
                                          className="border-l font-semibold ml-2 pl-2"
                                          title="Tracking Number"
                                        >
                                          #{identifier}
                                        </span>
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>

              <div className="flex flex-col pr-1 border rounded-lg p-4">
                <label className="font-semibold ml-2 mb-3">Shipping address:</label>
                <p className="text-sm pl-2">{getStringAddress(shippingAddress)}</p>
              </div>

              {!!tasks?.length && (
                <div className="flex flex-col pr-1 border rounded-lg p-4">
                  <label className="font-semibold ml-2">Tasks:</label>
                  <ul>{tasks?.map((task) => <MedicationOrderTaskListItem key={task.id} task={task} />)}</ul>
                </div>
              )}

              {!!invoices?.length && (
                <div className="flex flex-col pr-1 border p-4 rounded-lg">
                  <label className="font-semibold ml-2">Invoice: </label>
                  <ul>
                    {invoices.map((inv, index) => (
                      <InvoiceListItem
                        key={inv.id ?? index}
                        invoice={inv}
                        onClick={() => showInvoice(inv.id ?? "")}
                        className="last:mb-0"
                      />
                    ))}
                  </ul>
                </div>
              )}
            </TabPanel>
            <TabPanel contentClassName="h-full flex flex-1 p-4" header={<span className="text-sm">Activity</span>}>
              <ActivityHistory activityRecordsByMR={medicationActivityHstory} />
            </TabPanel>
            {!!history?.length && (
              <TabPanel contentClassName="h-full flex flex-1 p-4" header={<span className="text-sm">Edits</span>}>
                <OrderHistory orders={history} showOrder={showOrder} />
              </TabPanel>
            )}
            {!!serviceRequest?.id && (
              <TabPanel contentClassName="flex flex-1" header={<span className="text-sm">Notes</span>}>
                <OrderNote
                  orderId={serviceRequest.id}
                  queriesToInvalidate={medsQueryKeys.orderDetails.details(serviceRequest.id)}
                  orderNote={serviceRequest.note}
                  className="mt-6"
                  contentClassName="text-gray-400 pt-6 px-3"
                />
              </TabPanel>
            )}
          </TabView>
        </div>
      )}

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />

      <ConfirmDialog />

      <MedicationRescheduleDialog
        visible={rescheduleDialogVisible}
        rescheduleDate={rescheduleDate}
        onReschedule={() => {
          rescheduleMrOrder({ id: orderId as string, newDate: rescheduleDate })
          hideRescheduleDialog()
        }}
        onHide={hideRescheduleDialog}
        updateRescheduleDate={updateRescheduleDate}
      />

      <MedicationCancelDialog
        invoice={invoice}
        visible={cancelDialogVisible}
        cancelFutureOrder={cancelFutureOrder}
        refundItemsAsWell={refundItemsAsWell}
        isCancelling={isCancelling}
        onHide={handleOnHideCancel}
        cancelReason={cancelReason}
        onConfirm={() =>
          cancelMrOrder({
            serviceRequestId: orderId as string,
            patientId: patientId as string,
            cancelReason: cancelReason as string,
            cancel_mode: cancelFutureOrder as string,
            refundItemsAsWell: refundItemsAsWell,
          })
        }
        updateCancelFutureOrder={setCancelFutureOrder}
        updateRefundItemsAsWell={setRefundItemsAsWell}
        updateCancelReason={updateCancelReason}
      />
    </div>
  )
}

const INITIAL_STATE: State = {
  rescheduleDialogVisible: false,
  rescheduleDate: new Date(),
  cancelDialogVisible: false,
}

const reducer = (
  state: State,
  { type, payload }: { type: string; payload?: boolean | Date | string | MedicationKnowledge },
) => {
  switch (type) {
    case "updateRescheduleDialogVisible":
      return { ...state, rescheduleDialogVisible: payload as boolean }
    case "updateCancelDialogVisible":
      return {
        ...state,
        cancelDialogVisible: payload as boolean,
        cancelReason: payload !== false ? state.cancelReason : undefined,
      }
    case "updateRescheduleDate":
      return { ...state, rescheduleDate: payload as Date }
    case "updateCancelReason":
      return { ...state, cancelReason: payload as string }
    case "updateSelectedMK":
      return { ...state, selectedMK: payload as MedicationKnowledge | undefined }
    default:
      return state
  }
}

const useStateReducer = () => {
  const [{ rescheduleDialogVisible, cancelDialogVisible, cancelReason, selectedMK, rescheduleDate }, dispatch] =
    useReducer(reducer, INITIAL_STATE)

  const showRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: true })
  const hideRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: false })
  const showCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: true })
  const hideCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: false })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "updateRescheduleDate", payload: newDate })
  const updateCancelReason = (reason: string) => dispatch({ type: "updateCancelReason", payload: reason })
  const updateSelectedMK = (mk?: MedicationKnowledge) => dispatch({ type: "updateSelectedMK", payload: mk })

  return {
    rescheduleDialogVisible,
    cancelDialogVisible,
    cancelReason,
    selectedMK,
    rescheduleDate,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
    updateSelectedMK,
  }
}

type State = {
  selectedMK?: MedicationKnowledge
  rescheduleDialogVisible: boolean
  rescheduleDate: Date
  cancelDialogVisible: boolean
  cancelReason?: string
}

export { MedicationOrderDetails }
