import { format, parseISO } from "date-fns"
import { type FieldProps, ErrorMessage, Field } from "formik"
import { Calendar } from "primereact/calendar"
import type { FormEvent } from "primereact/ts-helpers"
import { classNames } from "primereact/utils"
import { type FC, useState } from "react"

import { formatsByTypes } from "data"

import type { FormatTypes } from "../types"

const PeriodField: FC<Props> = ({
  field,
  className,
  dateFormat,
  showTime,
  timeOnly,
  stringFormatType,
  view = "date",
  minDate,
  maxDate,
  selectionMode = "single",
  readOnlyInput,
  horizontal,
  inputClassName,
}) => {
  const [start, setStart] = useState<Date>()
  const [end, setEnd] = useState<Date>()

  return (
    <>
      <Field name={`${field}.start`}>
        {({ field: { name, onChange, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
          <div
            className={classNames(
              "field space-y-2 relative",
              horizontal ? "inline-flex justify-between" : "flex flex-col",
              className,
            )}
          >
            <label htmlFor={name} className="text-sm font-medium text-gray-700 mt-3">
              Start Date
            </label>

            <Calendar
              id={name}
              name={name}
              value={stringFormatType && value ? parseISO(value) : value}
              onChange={(e: FormEvent<Date> | FormEvent<Date[]> | FormEvent<(Date | null)[]>) => {
                if (stringFormatType) {
                  if (e.value) {
                    setFieldValue(name, format(e.value as Date, formatsByTypes[stringFormatType]))
                  }
                } else {
                  onChange(e)
                }
                setStart(e.value as Date)
              }}
              minDate={minDate}
              maxDate={end}
              showIcon
              showTime={showTime}
              timeOnly={timeOnly}
              dateFormat={dateFormat}
              selectionMode={selectionMode}
              view={view}
              readOnlyInput={readOnlyInput}
              className={classNames(
                "p-inputtext-sm",
                "p-inputtext-sm",
                {
                  "p-invalid": touched && error,
                  horizontal: horizontal,
                },
                inputClassName,
              )}
            />
            <ErrorMessage name={name}>
              {(msg) => <small className="p-error absolute -bottom-6 ">{msg}</small>}
            </ErrorMessage>
          </div>
        )}
      </Field>
      <Field name={`${field}.end`}>
        {({ field: { name, onChange, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
          <div
            className={classNames(
              "field space-y-2 relative",
              horizontal ? "inline-flex justify-between" : "flex flex-col",
              className,
            )}
          >
            <label htmlFor={name} className="text-sm font-medium text-gray-700">
              End Date
            </label>

            <Calendar
              id={name}
              name={name}
              value={stringFormatType && value ? parseISO(value) : value}
              onChange={(e: FormEvent<Date> | FormEvent<Date[]> | FormEvent<(Date | null)[]>) => {
                if (stringFormatType) {
                  if (e.value) {
                    setFieldValue(name, format(e.value as Date, formatsByTypes[stringFormatType]))
                  }
                } else {
                  onChange(e)
                }
                setEnd(e.value as Date)
              }}
              minDate={start}
              maxDate={maxDate}
              showIcon
              showTime={showTime}
              timeOnly={timeOnly}
              dateFormat={dateFormat}
              selectionMode={selectionMode}
              view={view}
              readOnlyInput={readOnlyInput}
              className={classNames(
                "p-inputtext-sm",
                "p-inputtext-sm",
                {
                  "p-invalid": touched && error,
                  horizontal: horizontal,
                },
                inputClassName,
              )}
            />
            <ErrorMessage name={name}>
              {(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}
            </ErrorMessage>
          </div>
        )}
      </Field>
    </>
  )
}

type Props = {
  field: string
  stringFormatType?: FormatTypes
  className?: string
  dateFormat?: string
  showTime?: boolean
  view?: "date" | "month" | "year"
  timeOnly?: boolean
  minDate?: Date
  maxDate?: Date
  selectionMode?: "multiple" | "range" | "single"
  readOnlyInput?: boolean
  horizontal?: boolean
  inputClassName?: string
}

export { PeriodField }
