import isEqual from "lodash/isEqual"
import { useMemo, useState } from "react"

import { DialogFormContainer, SplitButton } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useOrganizationContext } from "organization"

import { useCreateCombo, useEditCombo, useLabPanels } from "../../../hooks"
import type { LabCombo, PDParams } from "../../../types"
import { mapPdUseContext } from "../utils"
import { comboValidationSchema, getComboInitialValues, sanitizeCombo } from "../validations"
import { LabComboForm } from "./LabComboForm"

const LabComboFormContainer = ({ labCombo, isClone, onHide }: Props) => {
  const { currentOrganization, currentOrganizationId, performerLabsEnabled, isLoadingLabsFacility } =
    useOrganizationContext()

  /* const [formHelpers, setFormHelpers] = useState<FormikHelpers<Partial<ComboParams>>>() */

  const [confirmStatus, setConfirmStatus] = useState<ConfirmStatus>(defaultConfirmStatus)

  const isEditing = !!labCombo?.combo.id && !isClone

  const { labPanels: tests, isLoadingLabPanels } = useLabPanels(
    currentOrganizationId,
    { comboPD: labCombo?.combo.id },
    isEditing || isClone,
  )
  const initialValue = useMemo(() => {
    if (labCombo && tests.length) {
      return getComboInitialValues(currentOrganization, isClone, { ...labCombo, tests })
    } else {
      return getComboInitialValues(currentOrganization, isClone, labCombo)
    }
  }, [tests])

  const { createCombo } = useCreateCombo(() => {
    setConfirmStatus(defaultConfirmStatus)
    onHide()
  })

  const { editCombo } = useEditCombo(() => {
    setConfirmStatus(defaultConfirmStatus)
    onHide()
  })

  const onSubmit = (data: PDParams) => {
    if (isEqual(data, initialValue)) {
      onHide()
      return
    }
    if (!confirmStatus.confirm && confirmStatus.show) {
      /* setFormHelpers(formHelpers) */
    } else {
      if (isEditing) {
        editCombo(sanitizeCombo(data))
      } else {
        createCombo(sanitizeCombo({ ...data, status: confirmStatus.status }))
      }
    }
  }
  const { rootId, practiceId } = mapPdUseContext(labCombo?.combo?.useContext)
  const onlyAllowEditFee = isEditing && !(rootId === currentOrganizationId || practiceId === currentOrganizationId)

  return (
    <ReplaceFormProvider>
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={onHide}
        showForm={true}
        title={isEditing ? "Edit Combo" : "New Combo"}
        saveLabel={isEditing ? "Update" : undefined}
        initialValue={{
          ...initialValue,
        }}
        validationSchema={comboValidationSchema}
        useFormik
        customSaveButton={
          isEditing
            ? undefined
            : ({ validate, isSubmitting }) => {
                return (
                  <SplitButton
                    type="submit"
                    actions={[
                      {
                        label: "Save & Activate",
                        description: "Saves the new combo and activates it",
                        onSelectClick: async () => {
                          const errors = await validate()
                          if (!Object.keys(errors).length)
                            setConfirmStatus({ confirm: true, status: "active", show: false })
                        },
                      },
                      { label: "Save as Draft", description: "Saves the new combo as draft" },
                    ]}
                    autoUpdateMainAction
                    loading={isSubmitting}
                    disabled={isLoadingLabPanels || isLoadingLabsFacility || isSubmitting}
                  />
                )
              }
        }
        isLoadingData={isLoadingLabPanels || isLoadingLabsFacility}
        className="h-[70dvh] 2xl:h-[62dvh]"
      >
        <LabComboForm
          performerLabsOptions={performerLabsEnabled.map(({ ref }) => ref)}
          isEditing={isEditing}
          isLoadingData={isLoadingLabPanels}
          editFee={onlyAllowEditFee}
        />
      </DialogFormContainer>
      {/* <ConfirmDialog
        acceptLabel="Accept"
        rejectLabel="Close"
        acceptClassName="button-primary text-sm"
        rejectClassName="button-default text-sm"
        acceptIcon={isCreating ? "pi pi-spinner pi-spin" : undefined}
        accept={() => {
          setConfirmStatus({ confirm: true, status: "active", show: true })
          formHelpers?.submitForm()
        }}
        reject={() => {
          formHelpers?.setSubmitting(false)
          setConfirmStatus(defaultConfirmStatus)
        }}
        visible={confirmStatus.show}
        header="Confirm"
        message={
          <div className="felx flex-col">
            <p>Are you sure you want to activate this combo?</p>
            <p>If you accept this combo will be available to use in orders from now</p>
          </div>
        }
      /> */}
    </ReplaceFormProvider>
  )
}

const defaultConfirmStatus: ConfirmStatus = {
  confirm: false,
  status: "draft",
  show: false,
}

type ConfirmStatus = { show: boolean; confirm: boolean; status: "active" | "draft" }

type Props = {
  labCombo?: LabCombo
  isClone: boolean
  onHide(): void
}

export { LabComboFormContainer }
