import { ClientProvider } from "@chartedhealth/payment-components"

import { useBasisTheoryInstance } from "account"
import { useOrganizationContext } from "organization"
import { useClient } from "api"
import { useAuth } from "security"
import { datadogLogs } from "logger"
import { useMemo } from "react"
import type { SubModule } from "commons"

const InvoiceDetails = ({ activeSubModule }: IProps) => {
  const { user } = useAuth()

  const { search, getSignedUrl, operationRequest, read, patch } = useClient()
  const { isAdmin } = useOrganizationContext()
  const { bt } = useBasisTheoryInstance()

  const loggedInUserResource = useMemo(() => ({ ...user?.linkedResource, isAdmin }), [isAdmin, user?.linkedResource])

  return (
    <ClientProvider
      apm={datadogLogs.logger}
      bt={bt}
      loggedInUserResource={loggedInUserResource}
      search={search}
      getSignedUrl={getSignedUrl}
      operationRequest={operationRequest}
      read={read}
      patch={patch}
    >
      {activeSubModule.renderContent()}
    </ClientProvider>
  )
}

type IProps = {
  activeSubModule: SubModule
}

export { InvoiceDetails }
