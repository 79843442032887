import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { type FC, type ReactElement, Children, useState } from "react"

const MasterDetailSection: FC<Props> = ({ selectedSectionId, onSectionChanged, children }) => {
  const getChildId = (child: ReactElement<ItemProps>) => child?.props?.id ?? ""

  const [currentSection, setCurrentSection] = useState<string>(
    getChildId(children.find((child) => !!child.props.id) as ReactElement<ItemProps>),
  )
  const sectionId = selectedSectionId ?? currentSection
  const onChanged = onSectionChanged ?? setCurrentSection

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="w-72 xl:w-96 pl-8 pt-8 border-r overflow-y-auto">
        <ul>
          {Children.map(children, (child) =>
            child.props.title ? (
              <li
                className={classNames(
                  "p-3 text-gray-500 cursor-pointer border-l-2 hover:text-primary hover:bg-gray-100",
                  getChildId(child) === sectionId ? "text-primary border-primary bg-gray-50" : " border-transparent",
                )}
                onClick={() => onChanged(getChildId(child))}
              >
                {child.props.icon && <FontAwesomeIcon icon={child.props.icon} className="mr-2" />}
                {child.props.title}
              </li>
            ) : null,
          )}
        </ul>
      </div>
      <div className="flex flex-col flex-1 pl-8 pt-8 pr-3 overflow-hidden relative">
        {Children.map(children, (child) =>
          child.props.title ? (
            getChildId(child) === sectionId ? (
              <div className="flex flex-col flex-1 overflow-hidden">
                <div className="h-12 flex flex-col justify-start gap-1 mb-4">
                  <h3 className="text-lg">{child.props.title}</h3>
                  {child.props.description && <h6 className="text-sm text-gray-400">{child.props.description}</h6>}
                </div>
                {child}
              </div>
            ) : null
          ) : (
            child
          ),
        )}
      </div>
    </div>
  )
}

const SectionItem: FC<ItemProps> = ({ children, className }) => (
  <div className={classNames("flex flex-1", className)}>{children}</div>
)

export type Props = {
  selectedSectionId?: string
  onSectionChanged?(section: string): void
  sectionToUrlParam?: string
  children: ReactElement<ItemProps>[]
}

export type ItemProps = {
  id?: string
  icon?: IconDefinition
  title: string
  description?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
}

export { MasterDetailSection, SectionItem }
