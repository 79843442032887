import { type FC, useCallback, useState } from "react"
import { AutoComplete, type AutoCompleteCompleteEvent } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import type { Reference } from "fhir"

import { useOrganizationContext } from "organization"
import { FormField } from "commons"
import type { FormFieldProps } from "commons/forms/FormField"

const AutocompleteEncounterTypes: FC<Props> = ({ field, disabled, handleSelect, ...fieldProps }) => {
  const [suggestionList, setSuggestionList] = useState<Reference[]>([])

  const { healthcareServicesRefs } = useOrganizationContext()

  const searchItems = useCallback(
    (event: AutoCompleteCompleteEvent) => {
      let suggestions = Array<Reference>()
      if (!event.query.trim().length) {
        suggestions = [...(healthcareServicesRefs ?? [])]
      } else {
        suggestions = (healthcareServicesRefs ?? []).filter(({ display }) => {
          return display?.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }

      setSuggestionList(suggestions)
    },
    [healthcareServicesRefs],
  )

  return (
    <FormField field={field} showInvalidState={false} {...fieldProps}>
      {({ field: { value, name, onChange }, form: { setFieldValue }, meta: { error, touched } }) => (
        <AutoComplete
          id={name}
          value={value}
          onChange={onChange}
          className={classNames("p-inputtext-sm", { "p-invalid": error && touched })}
          dropdown
          dropdownMode="blank"
          disabled={disabled}
          suggestions={suggestionList}
          delay={400}
          minLength={1}
          completeMethod={searchItems}
          forceSelection
          onSelect={(e) => {
            setFieldValue(name, e.value)
            handleSelect?.(e.value)
          }}
          field="display"
        />
      )}
    </FormField>
  )
}

type Props = { disabled?: boolean; handleSelect?: (item?: Reference) => void } & Omit<FormFieldProps, "children">

export { AutocompleteEncounterTypes }
