import { type QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import { type Composition } from "fhir"

import { useClient } from "api"
import { type CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useUpdatePatientComposition = (hideForm: () => void, queryKey?: QueryKey) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedPatientComposition = async (composition: Partial<Composition>) =>
    patch("Composition", composition?.id as string, composition)

  const { mutate: updateAssessment, isPending: isUpdating } = useMutation({
    mutationFn: updatedPatientComposition,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
      displayNotificationSuccess("Assessment updated successfully!")
    },
    onSettled: hideForm,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateAssessment, isUpdating }
}

export { useUpdatePatientComposition }
