import type { CodeableConcept, Coding } from "fhir"
import { type FieldProps, ErrorMessage, Field } from "formik"
import { type DropdownChangeEvent, type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import type { FC } from "react"

const CodeableConceptField: FC<Props> = ({
  field,
  codes,
  label,
  className,
  autoSelect,
  horizontal,
  inputClassName,
  readOnly,
  disabled,
  validation,
  handleChange,
  ...dropdownProps
}) => {
  const firstOption = autoSelect ? codes[0] : undefined

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <div
          className={classNames(
            "field space-y-2 relative",
            horizontal ? "inline-flex justify-between" : "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}
          <Dropdown
            id={name}
            name={name}
            optionLabel="display"
            options={codes}
            readOnly={readOnly}
            disabled={disabled}
            onChange={(e) => {
              handleChange?.(e)
              setFieldValue(name, { coding: [e.value], text: e.value.display } as CodeableConcept)
            }}
            value={value?.coding?.[0] ?? firstOption}
            className={classNames(
              "p-inputtext-sm",
              {
                "p-invalid": touched && error,
                horizontal: horizontal,
              },
              inputClassName,
            )}
            {...dropdownProps}
          />
          <div className="flex items-start p-error h-2 mt-1">
            <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  codes: Coding[]
  label?: string
  className?: string
  autoSelect?: boolean
  horizontal?: boolean
  inputClassName?: string
  readOnly?: boolean
  disabled?: boolean
  validation?(code: CodeableConcept): void
  handleChange?(e: DropdownChangeEvent): void
} & DropdownProps

export { CodeableConceptField }
