import {
  type IconDefinition,
  faCalendarClock,
  faCalendarDays,
  faEnvelopes,
  faFileCircleQuestion,
  faFlask,
  faFolderMedical,
  faHospitalUser,
  faListCheck,
  faMemoCircleInfo,
  faMessages,
  faPenField,
  faPills,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { SettingItemArray } from "fhir"
import { InputSwitch } from "primereact/inputswitch"

const BooleanSetting = ({ setting, isDisabled, onChange }: Props) => {
  const iconMap: Record<string, IconDefinition> = {
    "nutraceuticals-enabled": faPills,
    "summary-enabled": faMemoCircleInfo,
    "intake-enabled": faPenField,
    "labs-enabled": faFlask,
    "survey-enabled": faFileCircleQuestion,
    "plans-enabled": faListCheck,
    "communication-enabled": faMessages,
    "email-enabled": faEnvelopes,
    "encounters-enabled": faCalendarClock,
    "calendar-enabled": faCalendarDays,
    "patient360-enabled": faHospitalUser,
  }

  const settingIcon = iconMap[setting?.code?.code as string] ?? faFolderMedical

  return (
    <div className="border-b p-3 pr-0 flex items-center last:border-b-0">
      <div className="flex items-center flex-1">
        <FontAwesomeIcon icon={settingIcon} size="2x" className="w-8 text-gray-400" />
        <span className="capitalize ml-2">
          {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
        </span>
      </div>
      <span className="pr-3 ">
        <InputSwitch
          className="button-primary"
          disabled={isDisabled}
          checked={setting.value?.boolean ?? false}
          onChange={(e) => {
            onChange(setting.code?.code as string, { ...setting, value: { ...setting.value, boolean: e.value } })
          }}
        />
      </span>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { BooleanSetting }
