import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"
import type { FC, PropsWithChildren } from "react"

import { EmptyMessage, SkeletonLoader } from "commons"

const WrapUpSection: FC<PropsWithChildren<Props>> = ({
  sectionTitle,
  subSectionTitle,
  className,
  isLoading,
  isEmpty,
  emptyMesage,
  icon = faSearch,
  children,
  showEmptyMessage = false,
  containerClassName,
  useSpacer,
}) =>
  !isLoading && isEmpty && !showEmptyMessage ? null : (
    <div className={classNames("flex flex-col pt-6 last:pb-2", containerClassName)}>
      {sectionTitle && <span className="text-gray-900 sticky top-0 bg-white z-20">{sectionTitle}</span>}
      {subSectionTitle && (
        <div className="inline-flex space-x-2 items-center sticky top-5 bg-white z-10">
          <span className="text-gray-500 font-light whitespace-nowrap">{subSectionTitle}</span>
          <hr className="bg-gray-100 w-full h-0.5 rounded-full" />
        </div>
      )}
      {isLoading ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" />
      ) : isEmpty ? (
        <EmptyMessage message={emptyMesage} icon={icon} subMessage={false} className="py-2" />
      ) : (
        <div className={classNames("text-sm", className)}>
          {useSpacer && <span className="w-64 lg:w-80" />}
          {children}
        </div>
      )}
    </div>
  )

type Props = {
  sectionTitle?: string
  subSectionTitle?: string
  className?: string
  isLoading?: boolean
  isEmpty?: boolean
  emptyMesage?: string
  icon?: IconDefinition
  showEmptyMessage?: boolean
  containerClassName?: string
  useSpacer?: boolean
}

export { WrapUpSection }
