import { useSuspenseQuery } from "@tanstack/react-query"
import { type Bundle, type Organization, type PractitionerRole, getResources, getResourcesGrouped } from "fhir"

import { useClient } from "api"
import { PRACTITIONER_ROLE } from "data"

import { organizationQueryKeys } from "../query-keys"

const usePractitionerRole = (practitionerId: string, currentOrganization: Organization) => {
  const { operationRequest, search } = useClient()
  const queryKey = organizationQueryKeys.practitionerRole(practitionerId, currentOrganization.id as string)

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        "active:not": "false",
        practId: practitionerId,
        orgId: currentOrganization.id as string,
      })
      const bundle = await operationRequest<Bundle>({
        endpoint: "PractitionerRole",
        method: "GET",
        operation: "find-closest",
        filters,
      })

      const practitionerRoles = getResourcesGrouped<PractitionerRole>(
        bundle,
        "PractitionerRole",
        ({ code }) => code?.[0]?.coding?.[0]?.code as string,
      )

      const adminOrgs =
        practitionerRoles[PRACTITIONER_ROLE.ADMIN]?.reduce<string[]>(
          (acc, pr) => (pr.active ? [...acc, pr.organization?.id as string] : acc),
          [],
        ) ?? []
      const orgFilters = new URLSearchParams({
        _id: adminOrgs.join(","),
        type: "organization-root",
        "active:not": "false",
      })

      const bundleOrgs = adminOrgs.length
        ? await search({ endpoint: "Organization", filters: orgFilters })
        : ({} as Bundle)
      const organizations = getResources<Organization>(bundleOrgs, "Organization")
      const rootOrgId = organizations.find(
        ({ id }) => id === currentOrganization.id || id === currentOrganization.partOf?.id,
      )?.id

      const getPR = (type: PRACTITIONER_ROLE) =>
        practitionerRoles?.[type]?.find((pr) => pr.active && pr.organization?.id === currentOrganization.id) ??
        practitionerRoles?.[type]?.find((pr) => pr.active)

      const practitionerRolesIndexedByCode = Object.values(PRACTITIONER_ROLE).reduce<
        Record<PRACTITIONER_ROLE, PractitionerRole>
      >(
        (acc, role) => {
          const pr =
            role === PRACTITIONER_ROLE.ROOT_ADMIN
              ? practitionerRoles?.[PRACTITIONER_ROLE.ADMIN]?.find(
                  (pr) => pr.active && pr.organization?.id === rootOrgId,
                )
              : getPR(role)
          return pr ? { ...acc, [role]: pr } : acc
        },
        {} as Record<PRACTITIONER_ROLE, PractitionerRole>,
      )

      const isAdmin = !!practitionerRolesIndexedByCode.admin
      const isRootAdmin = !!practitionerRolesIndexedByCode["root-admin"]
      const isNonAdmin = !!practitionerRolesIndexedByCode["non-admin"]
      const isPractitioner = !!practitionerRolesIndexedByCode.practitioner
      const isStaff = !!practitionerRolesIndexedByCode.staff

      const prs = Object.values(practitionerRolesIndexedByCode)

      if (!prs?.length) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `This practitioner hasn't a active PractitionerRole in ${currentOrganization?.name} organization`,
          },
        })
      }

      return {
        practitionerRoles: prs,
        practitionerRolesIndexedByCode,
        isAdmin,
        isRootAdmin,
        isNonAdmin,
        isPractitioner,
        isStaff,
      }
    },
    meta: { context: { queryKey, practitionerId, currentOrganizationId: currentOrganization.id } },
  })

  return {
    isAdmin: !!data?.isAdmin,
    isNonAdmin: !!data?.isNonAdmin,
    isPractitioner: !!data?.isPractitioner,
    isStaff: !!data?.isStaff,
    practitionerRolesIndexedByCode: data?.practitionerRolesIndexedByCode,
    practitionerRoles: data?.practitionerRoles,
  }
}

export { usePractitionerRole }
