import { type FC, useEffect } from "react"

import { useOpenEncounter } from "encounter/hooks"
import { usePatientContext } from "patients"
import { useChartContext } from "chart-view"
import { SkeletonLoader } from "commons"

import { OpenEncounter } from "./OpenEncounter"

const EncounterContainer: FC = () => {
  const { patientId } = usePatientContext()
  const { openEncounter, isLoading } = useOpenEncounter(patientId)

  const { activeSubModule, closeModule } = useChartContext()

  useEffect(() => {
    if (!activeSubModule && !openEncounter) {
      closeModule()
    }
  }, [activeSubModule, openEncounter])

  if (isLoading) return <SkeletonLoader repeats={2} loaderType="two-lines" />

  if (openEncounter) return <OpenEncounter encounter={openEncounter} handleCloseModule={closeModule} />

  return activeSubModule?.renderContent()
}

export { EncounterContainer }
