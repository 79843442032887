import { useMutation } from "@tanstack/react-query"
import type { Parameters, ParametersParameterArrayArray } from "fhir"
import { useState } from "react"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { Dicc } from "communication/utils"
import type { TKeys } from "../types"

type TVideoCall = {
  patientId: string
  practitionerId: string
}

type TUseVideoCall = {
  start: (keysDicc: KeysDiccType) => void
  cancel?: () => void
  finish?: () => void
}

export type THJoinVideoCall = {
  sessionId: string
  patientId: string
}

export type THRejectVideoCall = {
  sessionId?: string
  patientId: string
  practitionerId: string
}

type TParameters = {
  sessionId?: string
  patientId?: string
  practitionerId?: string
  resourceType?: "Parameters"
}

type TSetP = keyof Omit<TParameters, "resourceType">
type TSetPKey = { [key in TSetP]?: string }

const endpoint = "Practitioner"

const operation = (op: "start" | "cancel" | "finish") => `${op}-video-call`

const onError = (error: CustomError, context: unknown) => displayNotificationError(registerErrorTrace(error, context))

const setParameter = (obj: TSetPKey) => ({
  name: Object.entries(obj).map(([key, value]) => value && key)[0],
  value: { string: Object.values(obj)[0] },
})

const parameters = ({ sessionId, practitionerId, patientId, resourceType = "Parameters" }: TParameters): Parameters => {
  const parameter = []
  sessionId && parameter.push(setParameter({ sessionId }))
  practitionerId && parameter.push(setParameter({ practitionerId }))
  patientId && parameter.push(setParameter({ patientId }))
  return { parameter, resourceType }
}

const useStartVideoCall = ({ start, cancel, finish }: TUseVideoCall) => {
  const { operationRequest } = useClient()
  const [vcParams, setVCParams] = useState<KeysDiccType | null>(null)

  const handleStartVideoCall = async ({ patientId, practitionerId }: TVideoCall) => {
    const keys: TKeys = await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("start"),
      id: practitionerId,
      parameters: parameters({ patientId }),
    })
    const buildDicc = Dicc<ParametersParameterArrayArray[]>(keys.parameter)
    setVCParams(buildDicc)
    return buildDicc
  }

  const { mutate: startVideoCall, isPending: startIsPending } = useMutation({
    mutationFn: handleStartVideoCall,
    onError,
    onSuccess: (keysDicc) => start?.(keysDicc),
  })

  const handleRejectVideoCall = async ({ practitionerId, patientId, sessionId }: THRejectVideoCall) =>
    await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("cancel"),
      id: practitionerId,
      parameters: parameters({ sessionId: vcParams?.sessionId?.value?.string ?? sessionId, patientId }),
    })

  const { mutate: cancelVideoCall, isPending: cancelIsPending } = useMutation({
    mutationFn: handleRejectVideoCall,
    onError,
    onSuccess: () => cancel?.(),
  })

  const handleFinishVideoCall = async ({ practitionerId }: { practitionerId: string }) =>
    await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("finish"),
      id: practitionerId,
      parameters: parameters({ sessionId: vcParams?.sessionId?.value?.string }),
    })

  const { mutate: finishVideoCall, isPending: finishIsPending } = useMutation({
    mutationFn: handleFinishVideoCall,
    onError,
    onSuccess: () => finish?.(),
  })

  return {
    startVideoCall,
    startIsPending,
    cancelVideoCall,
    cancelIsPending,
    finishVideoCall,
    finishIsPending,
    vcParams,
  }
}

type KeysDiccType = {
  sessionId?: ParametersParameterArrayArray
  accessToken?: ParametersParameterArrayArray
  applicationId?: ParametersParameterArrayArray
  isBusy?: ParametersParameterArrayArray
}

export { useStartVideoCall }
