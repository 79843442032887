import { type Coverage, type Organization, asReference } from "fhir"

import type { InsuranceProviderInfo } from "./types"

const getCoverageDisplay = (coverage: Coverage) =>
  `${coverage?.payor?.[0]?.display}-${coverage?.type?.coding?.[0]?.display}`

const getInsuranceProvidersInfo = (orgs: Organization[] = []) =>
  orgs.reduce<InsuranceProviderInfo[]>(
    (acc, item) => [...acc, { ...asReference(item), address: item.address?.[0] }],
    [],
  )

export { getCoverageDisplay, getInsuranceProvidersInfo }
