import { type FieldProps, Field, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import { InputMask } from "primereact/inputmask"

const PhoneField: FC<Props> = ({
  field,
  label,
  mask,
  unmask,
  horizontal,
  floatLabel,
  className,
  inputClassName,
  validation,
  disabled,
  country = "US",
}) => {
  const selectedCountry = phoneCountryData.find((pdata) => pdata.country === country)

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal && "inline-flex justify-between",
            floatLabel && "float-label relative block mt-1",
            !horizontal && !floatLabel && "flex flex-col",
            className,
          )}
        >
          {label && !floatLabel && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}

          <div className={classNames("relative flex items-center", { inputClassName })}>
            <InputMask
              type="tel"
              mask={mask ? mask : selectedCountry?.mask}
              unmask={unmask}
              id={name}
              name={name}
              onChange={onChange}
              value={value}
              disabled={disabled}
              className={classNames("p-inputtext-sm", { "p-invalid": touched && error, horizontal: horizontal })}
            />
          </div>
          {label && floatLabel && (
            <label htmlFor={name} className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">
              {label}
            </label>
          )}

          <div className="flex items-start p-error h-2 mt-1">
            <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      )}
    </Field>
  )
}

const phoneCountryData = [
  {
    country: "US",
    prefix: "1",
    mask: "+1 (999) 999-9999",
  },
]

type Props = {
  field: string
  label?: string
  mask?: string
  unmask?: boolean
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
  inputClassName?: string
  validation?(value: string): void
  disabled?: boolean
  fieldName?: string
  country?: string
}

export { PhoneField }
