import type { CodeableConcept, Coding } from "fhir"
import { type FieldArrayRenderProps, FieldArray, useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { type FC, useCallback, useMemo } from "react"

import { StackedListItemCheckeable } from "commons/components/StackedListItemCheckeable"
import { isValidGenderConsent } from "consent"
import { useOrganizationContext } from "organization"

import type { PatientFormData } from "../types"

const PatientConsents: FC<Props> = ({ field, label }) => {
  const { enabledConsents } = useOrganizationContext()
  const {
    values: {
      consents: includedConsents,
      patient: { gender },
    },
    setFieldValue,
  } = useFormikContext<PatientFormData>()

  const isValidGender = useCallback(
    (flags?: CodeableConcept[]) => {
      return isValidGenderConsent(flags, gender)
    },
    [gender],
  )

  const validConsents = useMemo(() => enabledConsents.filter(({ flag }) => isValidGender(flag)), [isValidGender])

  if (!enabledConsents.length) return null

  return (
    <FieldArray name={field}>
      {({ push, remove }: FieldArrayRenderProps) => {
        const onSelectConsent = (consent: Coding, checked: boolean) => {
          if (checked) push(consent)
          else remove(includedConsents.findIndex(({ code }) => code === consent.code))
        }

        return (
          <div className="field flex flex-col space-y-2 px-3 pb-3 pt-2 -mb-4">
            <div className="inline-flex justify-between flex-1 items-center">
              <span className="font-medium text-gray-900 text-lg">{label}</span>
              <fieldset className="inline-flex space-x-2 items-center text-sm text-gray-900">
                <label htmlFor="select_all_consents">Select all</label>
                <Checkbox
                  name="select_all_consents"
                  title={!gender ? "Select biological sex" : "Select all"}
                  checked={includedConsents.length === validConsents.length}
                  onChange={(ev) => {
                    ev.checked
                    setFieldValue(field, ev.checked ? validConsents.flatMap(({ coding }) => coding) : [])
                  }}
                  disabled={!gender}
                />
              </fieldset>
            </div>
            <ul className="@container divide-y divide-gray-200">
              {enabledConsents
                .filter(({ flag }) => isValidGender(flag))
                .map(({ coding: consent }) => (
                  <StackedListItemCheckeable
                    key={consent.code}
                    checked={includedConsents.some((c) => c.code === consent.code)}
                    modelData={{
                      leftData: [{ lineItems: [{ name: "Consent", value: consent.display ?? consent.code ?? "N/A" }] }],
                    }}
                    onCheck={(checked) => onSelectConsent(consent, checked)}
                  />
                ))}
            </ul>
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props = {
  field: string
  label: string
}

export { PatientConsents }
