import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class MCModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.MC
    this.display = "Metabolic Code"
    this.icon = faEhrMC
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

const faEhrMC: IconDefinition = {
  prefix: "fas",
  iconName: "0",
  icon: [
    64,
    64,
    [],
    "e001",
    "m32.136 3.1162c-5.735 0.0114-9.7177 4.9245-12.148 9.5796-3.7914 6.6376-7.5827 13.275-11.374 19.913 6.6175-5.8986 15.203-10.431 24.291-10.101 6.6199 0.29583 12.526 5.5111 13.824 11.978 1.2378-9.6588-0.52965-20.32-7.0954-27.839-1.8261-2.0209-4.677-3.4999-7.4976-3.5305zm15.994 16.779c1.7576 8.8121 1.3203 18.631-3.584 26.404-3.5203 5.4764-10.776 8.1534-16.989 6.1199 7.693 5.9222 17.855 9.7217 27.594 7.5654 4.7019-1.1145 8.365-5.6688 7.8249-10.585-0.1666-2.9124-1.2506-5.6972-2.683-8.2104 1.6882-0.9809-0.49896 0.67577-0.58832-1.0305-3.8581-6.7544-7.7162-13.509-11.574-20.263zm-26.256 6.6166c-9.0852 3.7608-17.469 10.849-20.495 20.469-1.2907 4.4916 0.51736 9.8592 4.8849 11.961 5.026 2.7032 10.864 1.7304 16.333 1.9181h16.595c-8.4942-2.9224-16.783-8.2644-20.894-16.505-2.7854-5.8969-1.4191-13.451 3.575-17.843z",
  ],
}

export { MCModule }
