import type { ChargeItemDefinition } from "fhir"
import { useMemo } from "react"

const useShippingsAndDiscounts = ({
  selectedDiscounts,
  selectedShippings,
  nutraDiscounts,
  nutraShippings,
  medsDiscounts,
  medsShippings,
  labsDiscounts,
}: Props) => {
  const { nutraDiscount, labsDiscount, medsDiscount } = useMemo(() => {
    const initialDiscounts = { nutraDiscount: undefined, medsDiscount: undefined, labsDiscount: undefined }
    return (
      selectedDiscounts?.reduce(
        (acc, cid) => {
          if (nutraDiscounts?.some(({ id }) => cid.id === id)) {
            acc.nutraDiscount = cid
          } else if (medsDiscounts?.some(({ id }) => cid.id === id)) {
            acc.medsDiscount = cid
          } else if (labsDiscounts?.some(({ id }) => cid.id === id)) {
            acc.labsDiscount = cid
          }
          return acc
        },
        initialDiscounts as {
          nutraDiscount: ChargeItemDefinition | undefined
          medsDiscount: ChargeItemDefinition | undefined
          labsDiscount: ChargeItemDefinition | undefined
        },
      ) ?? initialDiscounts
    )
  }, [labsDiscounts, medsDiscounts, nutraDiscounts, selectedDiscounts])

  const { medsShippingMethod, nutraShippingMethod } = useMemo(() => {
    const initialShippings = { medsShippingMethod: undefined, nutraShippingMethod: undefined }

    return (
      selectedShippings?.reduce(
        (acc, cid) => {
          if (nutraShippings?.some(({ id }) => cid.id === id)) {
            acc.nutraShippingMethod = cid
          } else if (medsShippings?.some(({ id }) => cid.id === id)) {
            acc.medsShippingMethod = cid
          }
          return acc
        },
        initialShippings as {
          nutraShippingMethod: ChargeItemDefinition | undefined
          medsShippingMethod: ChargeItemDefinition | undefined
        },
      ) ?? initialShippings
    )
  }, [selectedShippings, nutraShippings, medsShippings])

  return { nutraDiscount, medsDiscount, labsDiscount, medsShippingMethod, nutraShippingMethod }
}

type Props = {
  selectedShippings?: ChargeItemDefinition[]
  selectedDiscounts?: ChargeItemDefinition[]
  labsDiscounts?: ChargeItemDefinition[]
  medsDiscounts?: ChargeItemDefinition[]
  nutraDiscounts?: ChargeItemDefinition[]
  medsShippings?: ChargeItemDefinition[]
  nutraShippings?: ChargeItemDefinition[]
}

export { useShippingsAndDiscounts }
