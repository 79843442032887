import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import type { FC, PropsWithChildren } from "react"

import { ModulesId, NumberField } from "commons"
import { vitalSignCodes, vitalSignUnits } from "data"
import { useAppModuleContext } from "internals"

import type { ColData, VitalsFormType } from "../types"
import { getVitalName } from "../utils"
import { VitalChart } from "./VitalChart"

const VitalsForm: FC<Props> = ({ vitals, isTableEmpty, showChart }) => {
  const { appModules } = useAppModuleContext()

  return (
    <div
      className={classNames("relative p-fluid flex flex-col flex-1 gap-3 grow min-w-full", {
        "w-max": !showChart,
      })}
    >
      <div className="sticky left-0 top-6 lg:top-7 bg-white z-10 w-[14rem] 2xl:w-[14.2rem] h-6" />
      {isTableEmpty && (
        <div className="text-center absolute top-[50%] w-full flex flex-col justify-center pl-[25%]">
          <FontAwesomeIcon icon={appModules[ModulesId.VITALS].getIcon()} size="2x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-slate-700">No vitals found</h3>
          <p className="mt-2 text-sm text-slate-500">Get started by creating new vitals</p>
        </div>
      )}
      {Object.keys(vitalSignCodes).flatMap(
        (key, i) =>
          key !== "bloodPressure" && (
            <VitalItem
              key={key}
              vital={key as keyof ColData}
              label={getVitalName(key as keyof ColData)}
              field={key as keyof VitalsFormType}
            >
              {showChart ? (
                <VitalChart
                  vitalName={key as keyof ColData}
                  vitalRecords={vitals?.[key as keyof ColData]}
                  className="h-28 lg:min-w-[50rem]"
                />
              ) : (
                vitals?.[key as keyof ColData]?.map(({ date, value }, index) => (
                  <div
                    key={`${date}_${index}`}
                    className="flex flex-col p-2 text-gray-500 w-28 justify-center items-center"
                  >
                    {i === 0 && <span className="p-2 absolute -top-10 whitespace-nowrap">{date}</span>}
                    <span>{value?.value ? `${value.value} ${value.unit}` : " "}</span>
                  </div>
                ))
              )}
            </VitalItem>
          ),
      )}
    </div>
  )
}

const VitalItem: FC<PropsWithChildren<VitalItemType>> = ({ label, field, vital, className, children }) => {
  const { values } = useFormikContext<VitalsFormType>()
  return (
    <div className={classNames("grid grid-flow-col-dense w-full h-ful mt-3 relative", className)}>
      <div
        className={classNames(
          "flex flex-row items-center px-4 border-2 border-slate-200 rounded-lg p-2 md:p-3 lg:p-4 col-span-1 w-56 sticky left-0 bg-white z-50",
          { "border-slate-500": !!values[field] },
        )}
      >
        <NumberField
          label={label}
          className="w-full"
          inputClassName="slashed"
          labelClassName={!values[field] ? "text-gray-400/80" : undefined}
          field={field}
          placeholder={`0.00 ${vitalSignUnits[vital as keyof typeof vitalSignUnits]?.toUpperCase()}`}
          suffix={` ${vitalSignUnits[vital as keyof typeof vitalSignUnits]?.toUpperCase()}`}
          showButtons={false}
          minFractionDigits={2}
          maxFractionDigits={2}
        />
      </div>

      <div className="col-span-4 h-full scroll-smooth ml-3 grid grid-flow-col gap-3">{children}</div>
    </div>
  )
}

type VitalItemType = {
  label: string
  field: keyof VitalsFormType
  vital: keyof ColData
  className?: string
}

type Props = {
  vitals?: ColData
  isTableEmpty?: boolean
  showChart?: boolean
}

export { VitalsForm }
