import {
  faCalendarCheck,
  faCalendarDays,
  faCancel,
  faFileCircleCheck,
  faFileInvoiceDollar,
  faFlaskVial,
  faPencil,
  faPrint,
  faTrash,
  faUser,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import { isCarePlan } from "fhir"
import type { MenuItem } from "primereact/menuitem"

import { MenuStyles } from "commons/types"
import { formatsByTypes } from "data"
import { formatDate, getBadgeColor, getMoneyCurrencyAlt, strCapitalize } from "utils"

import type { StackedListItemProps } from "../../components/StackedListItem"
import type { LaboratoryOrder } from "../types"
import { getStatus } from "../utils"

const labOrderItemModelBuilder = ({
  data,
  goToOrderDetails,
  edit,
  revoke,
  showDoc,
  checkIsLoading,
  showPrice = true,
  release,
  allowOnlyExternalAction,
}: LabOrderItemModelBuilderArgs): StackedListItemProps => {
  const labOrderStatus = getStatus(data.order)
  const isDraftOrder = labOrderStatus?.code === "draft"
  const orderBillingTypeDisplay = data?.billingType?.replace("bill-", "")
  const planBased = data.order?.basedOn?.some(isCarePlan) ?? false

  const getMenuItems = () => {
    const menuItems: MenuItem[] =
      labOrderStatus?.code !== "revoked"
        ? [
            ...(showDoc
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
                    label: "View results",
                    disabled: !data.order.id || !data.presentedForm,
                    command: () => data.order.id && data.presentedForm && showDoc(data.order.id, data.presentedForm),
                  },
                  {
                    icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
                    label: "View requisition",
                    disabled: !data.order.id || !data.requisition,
                    command: () => data.order.id && data.requisition && showDoc(data.order.id, data.requisition),
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-1" />,
                    label: "Edit",
                    command: () => edit(data),
                    disabled: !isDraftOrder || planBased,
                  },
                ]
              : []),
            ...(data.presentedForm && data.latestResult?.id
              ? [
                  {
                    label: "Release to Patient",
                    icon: <FontAwesomeIcon icon={faFileCircleCheck} size="sm" className="mr-2" />,
                    disabled: !!data.latestResult?.released,
                    command: () => data.order.id && release?.(data.order.id, data.latestResult?.id as string),
                  },
                ]
              : []),
            ...(revoke
              ? [
                  {
                    icon: (
                      <FontAwesomeIcon
                        icon={isDraftOrder || labOrderStatus?.code === "on-hold" ? faTrash : faCancel}
                        size="sm"
                        className="mr-1"
                      />
                    ),
                    label: isDraftOrder || labOrderStatus?.code === "on-hold" ? "Discard" : "Cancel",
                    disabled: !data.revocable,
                    command: () => revoke(data),
                  },
                ]
              : []),
          ]
        : []

    return menuItems
  }

  return {
    leftData: [
      {
        lineItems: [
          {
            name: "Laboratory",
            value: `${strCapitalize(data.order.performer?.[0]?.display as string)} ${!isDraftOrder ? `(${data.orderIdentifier})` : ""}`,
          },
        ],
      },
      {
        lineItems: [
          {
            name: "Authored on",
            value: data.order.authoredOn
              ? formatDate(parseISO(data.order.authoredOn), formatsByTypes.LONG_DATE)
              : "Unspecified",
            icon: faCalendarDays,
          },
          {
            name: "Occurrence",
            icon: faCalendarCheck,
            value: data.order.occurrence?.dateTime
              ? formatDate(parseISO(data.order.occurrence.dateTime), formatsByTypes.LONG_DATE)
              : "Unspecified",
          },
          ...(data.combo ? [{ name: "Combos", value: "1", icon: faFlaskVial }] : []),
          ...(data.panelsCount ? [{ name: "Tests", value: data.panelsCount.toString(), icon: faVials }] : []),
          ...(orderBillingTypeDisplay
            ? [
                {
                  name: "Bill to",
                  value: strCapitalize(orderBillingTypeDisplay),
                  icon: faFileInvoiceDollar,
                },
              ]
            : []),
          { name: "Requester", value: data.requester, icon: faUser },
        ],
      },
    ],
    ...(showPrice
      ? {
          rightData: [
            {
              lineItems: [
                {
                  name: "Price",
                  value: `${getMoneyCurrencyAlt(data.price.currency)}${data.price.value?.toFixed(2)} ${
                    data.price.currency
                  }`,
                },
              ],
            },
          ],
        }
      : {}),
    badge: getBadgeColor(labOrderStatus?.code === "revoked" ? "cancelled" : labOrderStatus?.display ?? "unspecified"),
    menu: getMenuItems(),
    onClick: () => goToOrderDetails(data.order.id as string),
    menuStyle: allowOnlyExternalAction ? MenuStyles.ExternalAction : MenuStyles.Dropdown,
    isLoading: checkIsLoading?.(data.order.id as string),
  }
}

interface LabOrderItemModelBuilderArgs {
  data: LaboratoryOrder
  goToOrderDetails: (value: string) => void
  edit?: (value: LaboratoryOrder) => void
  revoke?: (value: LaboratoryOrder) => void
  showDoc?: (orderId: string, url: string) => void
  checkIsLoading?: (orderId: string) => boolean
  showPrice?: boolean
  release?: (orderId: string, drId: string) => void
  allowOnlyExternalAction?: boolean
}

export { labOrderItemModelBuilder }
