import { EditorField, InputField } from "commons"

const AssessmentForm = () => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <div className="flex justify-between space-x-6">
      <InputField field="title" label="Title" className="flex-1" />
    </div>
    <div className="flex justify-between space-x-6">
      <EditorField field="section[0].text.div" label="Notes" className="flex-1" inputClassName="h-max min-h-96" />
    </div>
  </div>
)

export { AssessmentForm }
