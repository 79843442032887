import type { Account, AccountBETACreditCardArray, Patient } from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { useEffect, useId, useMemo, useReducer } from "react"

import {
  type CreditCardFormData,
  getInitialValues,
  useAccountCreditCards,
  useCreateCreditCard,
  useDeleteCreditCard,
  useUpdateCreditCard,
  useUpdateDefaultCreditCard,
} from "account"
import { AddFieldArrayItemButton, Button, SkeletonLoader } from "commons"
import { useOrganizationContext } from "organization"
import { getAddressByType } from "utils"

import { useCPOERequestsContext } from "../hooks"
import { OrderCreditCardListItem } from "./OrderCreditCardListItem"
import { OrderCreditCardFormContainer } from "./OrderCreditcardFormContainer"

const OrderCreditCardContainer = ({ isPracticeCC, defaultCreditCard }: Props) => {
  const loaderKey = useId()

  const { patient, patientId, readerAccount, setReaderAccount, setIsProcessingActions } = useCPOERequestsContext()
  const { currentOrganizationCCAccount } = useOrganizationContext()
  const orgCC = currentOrganizationCCAccount?.creditCard?.[0]

  const { creditCards, isLoading, account: patientAccount } = useAccountCreditCards(patientId)

  const {
    formVisible,
    selectedCreditCard,
    selectCreditCard,
    editCreditCardIndex,
    isNew,
    add,
    edit,
    hideForm,
    setDeleteIndex,
    deleteCreditCardIndex,
  } = useStateReducer(defaultCreditCard)

  const { updateDefaultCreditCard, isUpdating: isUpdatingDefaultCC } = useUpdateDefaultCreditCard({
    onSettled: () => setIsProcessingActions(false),
    onSuccess: () => setReaderAccount(undefined),
  })
  const { createCreditCard, isAdding } = useCreateCreditCard(() => {
    setIsProcessingActions(false)
    hideForm()
  })
  const { updateCreditCard, isUpdating } = useUpdateCreditCard(() => {
    setIsProcessingActions(false)
    hideForm()
  })
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => {
    setDeleteIndex()
  })

  const getCcId = (cc: AccountBETACreditCardArray) => (cc ? `${cc.type}|${cc.last4Digits}` : "")

  const ccOptions = useMemo(() => creditCards.map((cc) => ({ ...cc, id: getCcId(cc) })), [creditCards])

  const onSelect = (cc: AccountBETACreditCardArray) => {
    selectCreditCard(cc)
    updateDefaultCreditCard({ creditCardId: getCcId(cc), account: patientAccount as Account })
    setIsProcessingActions(true)
  }

  const onSubmit = (creditCard: CreditCardFormData) => {
    if (isNew) {
      createCreditCard({
        creditCard,
        account: patientAccount as Account,
        creditCardList: creditCards,
        patientId,
      })

      if (creditCard) {
        onSelect(creditCard)
      }
    } else {
      updateCreditCard({
        creditCard,
        account: patientAccount as Account,
        creditCardList: creditCards,
        index: editCreditCardIndex,
      })
    }
    setIsProcessingActions(true)
  }
  useEffect(() => {
    if (readerAccount) selectCreditCard(undefined)
  }, [readerAccount])

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />
  if (isLoading) return loader()

  return (
    <>
      <div className="inline-flex items-center justify-between w-full px-5 text-sm">
        <h6 className="font-medium text-gray-700">Credit Cards</h6>
      </div>
      <div className="mt-5 pl-6 pr-2 flex flex-wrap gap-3">
        {isPracticeCC ? (
          <OrderCreditCardListItem
            onDelete={() => {}}
            selected={true}
            index={0}
            onEdit={() => {}}
            onSelect={() => {}}
            creditCard={orgCC!}
            disabled
            isLoading={isUpdating}
          />
        ) : (
          <>
            {ccOptions.map((cc, index) => (
              <OrderCreditCardListItem
                key={`${cc.id} ${cc.expirationMonth}-${cc.expirationYear}`}
                index={index}
                creditCard={cc}
                selected={selectedCreditCard ? cc.id === getCcId(selectedCreditCard) : false}
                onSelect={onSelect}
                onEdit={edit}
                onDelete={setDeleteIndex}
                disabled={isUpdatingDefaultCC || isAdding}
                isLoading={editCreditCardIndex === index && isUpdating}
              />
            ))}
            <AddFieldArrayItemButton label="Add credit card" onClick={add} className="pl-0 w-full" loading={isAdding} />
          </>
        )}
      </div>

      <OrderCreditCardFormContainer
        creditCard={isNew ? getInitialValues(patient as Patient) : creditCards[editCreditCardIndex]}
        onSubmit={onSubmit}
        onCancel={hideForm}
        isEditing={!isNew}
        showForm={formVisible}
        shippingAddress={getAddressByType("postal", patient.address)}
      />
      <ConfirmDialog />
      <Dialog
        header="Confirmation"
        closable={false}
        draggable={false}
        visible={deleteCreditCardIndex !== undefined}
        style={{ width: "25vw" }}
        onHide={() => ({})}
        footer={
          <div className="flex flex-shrink-0 justify-end w-full">
            <Button
              label="Cancel"
              size="xl"
              buttonStyle="default"
              disabled={isDeleting}
              onClick={() => setDeleteIndex()}
            />
            <Button
              label="Delete"
              buttonStyle="primary"
              size="xl"
              loading={isDeleting}
              onClick={() =>
                removeCreditCard({
                  index: deleteCreditCardIndex as number,
                  accountId: patientAccount?.id as string,
                  creditCardList: creditCards,
                  defaultCreditCardId: defaultCreditCard ? getCcId(defaultCreditCard) : undefined,
                  patientId,
                })
              }
            />
          </div>
        }
      >
        <span>Are you sure you want to delete this credit card?</span>
      </Dialog>
    </>
  )
}

const getInitialState = (defaultCreditCard: AccountBETACreditCardArray | undefined): State => ({
  formVisible: false,
  selectedCreditCard: defaultCreditCard,
  isNew: true,
  editCreditCardIndex: 0,
})

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: string
    payload?: boolean | AccountBETACreditCardArray | number | undefined
  },
) => {
  switch (type) {
    case "add":
      return { ...state, formVisible: true, isNew: true }
    case "edit":
      return {
        ...state,
        formVisible: true,
        isNew: false,
        editCreditCardIndex: payload as number,
      }
    case "hideForm":
      return { ...state, formVisible: false }
    case "selectCreditCard":
      return { ...state, selectedCreditCard: payload as AccountBETACreditCardArray | undefined }
    case "setDeleteIndex":
      return { ...state, deleteCreditCardIndex: payload as number }
    default:
      return state
  }
}

const useStateReducer = (defaultCreditCard: AccountBETACreditCardArray | undefined) => {
  const [{ formVisible, isNew, selectedCreditCard, editCreditCardIndex, deleteCreditCardIndex }, dispatch] = useReducer(
    reducer,
    getInitialState(defaultCreditCard),
  )

  const add = () => dispatch({ type: "add" })
  const edit = (index: number) => dispatch({ type: "edit", payload: index })
  const selectCreditCard = (cc: AccountBETACreditCardArray | undefined) =>
    dispatch({ type: "selectCreditCard", payload: cc })
  const hideForm = () => dispatch({ type: "hideForm" })
  const setDeleteIndex = (index?: number) => dispatch({ type: "setDeleteIndex", payload: index })

  return {
    formVisible,
    isNew,
    selectedCreditCard,
    selectCreditCard,
    editCreditCardIndex,
    add,
    edit,
    hideForm,
    deleteCreditCardIndex,
    setDeleteIndex,
  }
}

type State = {
  formVisible: boolean
  selectedCreditCard: AccountBETACreditCardArray | undefined
  isNew: boolean
  editCreditCardIndex: number
  deleteCreditCardIndex?: number
}

type Props = {
  defaultCreditCard: AccountBETACreditCardArray | undefined
  isPracticeCC: boolean
}

export { OrderCreditCardContainer }
