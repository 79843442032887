import type {
  CodeableConcept,
  Coding,
  HealthcareService,
  InventoryItem,
  MedicationKnowledge,
  PlanDefinition,
  Quantity,
  Reference,
  Location,
} from "fhir"

import type { MED_FEE_TYPE } from "data"

export type ThemeDefinition = {
  primaryColor?: string
  secondaryColor?: string
  photoBgColor?: string
  iconLogo?: string
  banner?: string
}

export type SettingDefinitionItem = {
  label: string
  settingDefinitionId: string
}

export type DefaultOrgDataIcd10 = {
  label: string
  male: Coding[]
  female: Coding[]
}

export enum LABS_SECTION_TYPE {
  LABS_COMBOS_SECTION = "labs-combs-section",
  LABS_TESTS_SECTION = "labs-tests-section",
}

export type LabAdvanceFilter = {
  orgs?: string[]
  showArchived?: boolean
  performerLabs?: string[]
}

export type MedicationsAdvanceFilter = {
  mkCatalogs?: string[]
  medsClassificationCodes?: string[]
}

export enum MEDICATIONS_SECTION_TYPE {
  MEDICATIONS_RX_SECTION = "medications-rx-section",
  MEDICATIONS_NUTRACEUTICALS_SECTION = "medications-nutraceuticals-section",
}
export type AdvanceFilter = {
  orgs?: string[]
  archived?: boolean
  performerLabs?: string[]
  hidden?: boolean
}

export type LabCombo = {
  combo: PlanDefinition
  price: number
  fee: number
  tests: LabPanel[]
  performerLab: Reference
}

export type LabPanel = {
  planDefinition: PlanDefinition
  display: string
  sku: string
  price: number
  fee: number
  performerLab: Reference
}

export type LabComboData = {
  comboData: PDData
  price: number
  fee: number
  total: string
  performerLab: Reference
  status: string
  externalAction: { label: string; icon: JSX.Element; command: () => void }[]
}

export type PDData = {
  display: string
  totalTests: number
  sku: string
  isExcluded?: boolean
  pd?: PlanDefinition
} & LabPDContext

export type PDParams = {
  id?: string
  organization: Reference
  title: string
  basePrice: number
  fee?: number
  laboratory: Reference
  panels: Reference[]
  parentCombo?: Reference
  gender?: "male" | "female"
  tests?: LabPanel[]
  status: "active" | "draft"
  icd10?: CodeableConcept[]
}
export type MedParams = {
  organization: Reference
} & MedItem

export type LabPDContext = {
  gender?: CodeableConcept
  organization?: string
  practice?: string
  rootId?: string
  practiceId?: string
}

export type MedItem = {
  id?: string
  mk: MedicationKnowledge
  display: string
  sku: string
  price: number
  fee: {
    value: number
    duration?: Quantity
  }[]
  feeType?: MED_FEE_TYPE
  catalog: string
  practice?: string
  referencePrices: ReferencePriceItem[]
  inventory?: InventoryData
}

type InventoryData = {
  item: InventoryItem
  qty: Quantity
  location: Reference
}

export type ReferencePriceItem = {
  qty: number
  price?: number
}

export type AppointmentType = Omit<HealthcareService, "duration"> & {
  practitioners?: Reference[]
  rooms?: Reference[]
  devices?: Reference[]
  telemedicine?: boolean
  patientCanBook?: boolean
  daysInAdvance?: number
  withLabs?: boolean
  withQuestionnaires?: boolean
  provider?: Reference
  requester?: Reference
  combos?: Reference[]
  icd10?: CodeableConcept[]
  duration?: number
  questionnaires?: Reference[]
  color?: string
}

export const APPOINTMENT_TYPE_CHARACTERISTIC = {
  telemedicine: "telemedicine",
  patientCanBook: "patient-can-book",
  color: "http://chartedhealth.com/fhir/healthcare-service-characteristic/color",
}

export type RoomData = Location & {
  devices: Reference[]
}
