import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { CarePlan, Parameters, Reference } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import type { CustomError } from "../../types"
import { plansQueryKeys } from "../query-keys"

const useEnrollPlan = (onSuccess?: (cpId: string) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const enroll = async ({
    planId,
    patient,
    author,
    encounter,
  }: {
    planId: string
    patient: Reference
    author: Reference
    encounter?: Reference
  }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "patientId",
          value: { Reference: patient },
        },
        {
          name: "author",
          value: { Reference: author },
        },
        ...(encounter
          ? [
              {
                name: "encounterId",
                value: { Reference: encounter },
              },
            ]
          : []),
      ],
    }
    return operationRequest({ endpoint: "PlanDefinition", method: "POST", operation: "enroll", id: planId, parameters })
  }

  const { mutate: enrollPlan, isPending } = useMutation({
    mutationFn: enroll,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (data) => {
      const isMCPlan = (data as CarePlan)?.instantiatesCanonical?.includes("mc")

      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.all })
      if (isMCPlan) {
        displayNotificationSuccess("Plan enrolled successfully!")
        // datadogLogs.logger.info(`Plan ${(data as CarePlan)?.id} enrolled successfully!`)
      }
      onSuccess?.(data.id as string)
    },
    onSettled,
  })

  return { enrollPlan, isEnrolling: isPending }
}

export { useEnrollPlan }
