import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MenuItem } from "primereact/menuitem"

const defaultEditRemoveMenu = (onEdit?: () => void, onDelete?: () => void): MenuItem[] => [
  ...(onEdit
    ? [
        {
          label: "Edit",
          icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
          command: onEdit,
        },
      ]
    : []),
  ...(onDelete
    ? [
        {
          label: "Delete",
          icon: <FontAwesomeIcon icon={faTrashCan} size="sm" />,
          command: onDelete,
        },
      ]
    : []),
]

export { defaultEditRemoveMenu }
