import { useFormikContext } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import { type FC, useCallback, useEffect, useMemo } from "react"

import { useOrganizationContext } from "organization"

import type { AppointmentFormData } from "../types"
import { useAppointmentAvailableQuestionnaires, useScheduleAppointmentContext } from "../hooks"

const AppointmentQuestionnairesSection: FC = () => {
  const {
    values: { selectedSlot, participant, questionnaires },
    isSubmitting,
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const { selectedPatientGender } = useScheduleAppointmentContext()

  const { healthcareServices } = useOrganizationContext()

  const appointmentType = useMemo(
    () => healthcareServices.find(({ id }) => participant[5]?.actor?.id === id),
    [participant, healthcareServices],
  )

  const { questionnaires: availableQuestionnaires } = useAppointmentAvailableQuestionnaires({
    appointmentType,
    patientGender: selectedPatientGender,
  })
  const availableQuestionnairesCanonicals = useMemo(
    () => availableQuestionnaires?.map(({ id }) => id),
    [availableQuestionnaires],
  )

  const disabledFields = !selectedSlot || isSubmitting || !appointmentType

  const handleSelectQuestionnaire = useCallback(
    (canonical: string | undefined, checked: boolean) => {
      if (!checked)
        setFieldValue(
          "questionnaires",
          questionnaires?.filter((q) => q !== canonical),
        )
      else if (!questionnaires?.includes(canonical as string))
        setFieldValue("questionnaires", [...(questionnaires ?? []), canonical])
    },
    [questionnaires],
  )

  useEffect(() => {
    setFieldValue(
      "questionnaires",
      questionnaires?.filter((q) => availableQuestionnairesCanonicals?.includes(q)),
    )
  }, [availableQuestionnairesCanonicals])

  if (!availableQuestionnaires?.length) return null

  return (
    <fieldset
      className="flex flex-col gap-2 disabled:opacity-40 disabled:pointer-events-none"
      disabled={disabledFields}
    >
      <legend className="inline-flex items-center whitespace-nowrap font-medium text-gray-400 w-full mb-3">
        Required questionnaires <hr className="ml-1 w-full border" />
      </legend>
      {availableQuestionnaires?.map(({ id, display }, index) => (
        <div className="field inline-flex items-center gap-2" key={`q-${index}-${id}`}>
          <label htmlFor={`q-${index}-${id}`} className="text-sm font-medium text-gray-700">
            {display}
          </label>

          <InputSwitch
            id={`q-${index}-${id}`}
            checked={questionnaires?.includes(id as string) ?? false}
            disabled={disabledFields}
            className="min-h-[2.8rem] min-w-12"
            onChange={({ value }) => handleSelectQuestionnaire(id, value)}
          />
        </div>
      ))}
    </fieldset>
  )
}

export { AppointmentQuestionnairesSection }
