import { type FC } from "react"
import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { socialModel } from "social-history"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"

const SocialHistorySection: FC<Props> = ({ socialHistory, total, icon, hideSpacer }) => (
  <WrapUpSection
    subSectionTitle="Social History"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No history registered during this encounter"
    className="flex flex-row gap-x-16 w-full text-sm"
    useSpacer={!hideSpacer}
  >
    <StackedListContainer
      data={socialHistory ?? []}
      itemModelBuilder={(item) => ({ ...socialModel(item), badge: undefined })}
      className="w-full"
      itemsClassName="!pl-0 py-4"
    />
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "socialHistory">

export { SocialHistorySection }
