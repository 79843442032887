import { add } from "date-fns"
import {
  type CodeableConcept,
  type Dosage,
  type DosageDoseAndRateArrayDose,
  type Duration,
  type Medication,
  type MedicationIngredientArray,
  type MedicationRequest,
  asReference,
  isMedication,
  isMedicationKnowledge,
} from "fhir"
import { cloneDeep } from "lodash"
import pluralize from "pluralize"

import { mrCategoryCodes, unitOfTime } from "data"
import { isRefrigeratedMedicationKnowledge } from "utils"

import { SYSTEM_VALUES } from "system-values"
import { durationCodeOptions, prescriptionFrequencies } from "../data"
import type {
  MedicationDosage,
  MedicationFormData,
  MedicationIngredientFormData,
  MedicationRequestFormData,
} from "../types"
// eslint-disable-next-line import/no-cycle
import { getTimingFrequency, getTreatmentFrequency } from "./transformers"

const sanitize = ({
  mr,
  fromMK,
  resetRefillDate,
}: {
  mr: MedicationRequestFormData
  fromMK?: boolean
  resetRefillDate?: boolean
}): MedicationRequest => {
  const medicationReq = cloneDeep(mr)

  delete medicationReq?.medicationField?.textDisplayedInField

  if (medicationReq.medicationField) {
    const { code: medicationCode } = medicationReq.medicationField
    medicationReq.medication = {
      CodeableConcept: medicationCode,
    }
  }

  if (isMedication(medicationReq.medicationField)) {
    medicationReq.contained = [medicationReq.medicationField]

    if (!medicationReq.category?.some(({ coding }) => coding?.[0]?.code === mrCategoryCodes["write-in"].code)) {
      medicationReq.category?.push({ coding: [mrCategoryCodes["write-in"]], text: mrCategoryCodes["write-in"].display })
    }
    const { id: medicationId } = medicationReq.medicationField
    if (medicationId)
      medicationReq.medication = {
        Reference: { localRef: medicationId, resourceType: "Medication" },
      }
  } else if (isMedicationKnowledge(medicationReq.medicationField)) {
    const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationReq.medicationField)

    if (
      isMKRefrigerated &&
      !medicationReq.category?.some(({ coding }) => coding?.[0]?.code === mrCategoryCodes.refrigerated.code)
    ) {
      medicationReq.category?.push({
        coding: [mrCategoryCodes.refrigerated],
        text: mrCategoryCodes.refrigerated.display,
      })
    }
  }

  const currentDate = new Date().toISOString()
  if (!medicationReq.authoredOn) medicationReq.authoredOn = currentDate
  if (!medicationReq.dispenseRequest?.nextRefillDate || resetRefillDate)
    medicationReq.dispenseRequest = {
      ...medicationReq.dispenseRequest,
      nextRefillDate: currentDate,
    }

  const repeats = medicationReq.dispenseRequest?.numberOfRepeatsAllowed
  const { code, unit, system } = medicationReq.prescriptionQuantity ?? {}

  const interval = medicationReq.dispenseRequest.dispenseInterval?.value
  const duration = {
    ...medicationReq.dispenseRequest.dispenseInterval,
    value: Math.max(interval!, 1) * Math.max(repeats!, 1),
  } as Duration

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    initialFill: {
      quantity: { code, unit, system, ...medicationReq.dispenseRequest.quantity },
      duration: duration,
    },
    performer: medicationReq.dispenseRequest.performer && asReference(medicationReq.dispenseRequest.performer),
    quantity: { code, unit, system, ...medicationReq.dispenseRequest.quantity },
    numberOfRepeatsAllowed: repeats,
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: medicationReq.dispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      end: add(
        medicationReq.dispenseRequest?.validityPeriod?.start
          ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
          : new Date(),
        { [`${duration.unit ?? "second"}s`]: duration.value! },
      ).toISOString(),
    },
  }

  if (!medicationReq.dosageInstruction?.length || medicationReq.dosages?.length)
    medicationReq.dosageInstruction = medicationReq.dosages?.map((dosage) =>
      sanitizeDosage(dosage, medicationReq.structured),
    )

  if (!medicationReq.encounter) delete medicationReq.encounter
  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  if (!medicationReq.note?.[0].text) delete medicationReq.note
  if (!medicationReq.dispenseRequest.shippingAddress && !fromMK) delete medicationReq.dispenseRequest.shippingAddress

  if (!fromMK) {
    delete medicationReq.medicationField
    delete medicationReq.prescriptionQuantity
    delete medicationReq.dosages
    delete medicationReq.administrationGuideline
    delete medicationReq.medicationKnowledge
  }

  delete medicationReq?.structured

  return medicationReq
}

const sanitizeDosage = (dosage: MedicationDosage, isStructured: boolean = false): Dosage => {
  const frequency = getTimingFrequency({
    frequencyKeyValue: dosage.treatmentFrequency,
    duration: dosage.prescriptionDuration,
    durationUnit: dosage.prescriptionDurationUnit,
  })

  let doseValue: DosageDoseAndRateArrayDose

  const isRangeDose = dosage.doseQuantity?.includes("-")

  if (isRangeDose) {
    const rangeBaseQuantity = {
      unit: dosage.medicationQuantity?.unit,
      system: dosage.medicationQuantity?.system,
      code: dosage.medicationQuantity?.code,
    }
    const [low, high] = String(dosage.doseQuantity).split("-")

    doseValue = {
      Range: {
        low: { ...rangeBaseQuantity, value: parseInt(low) },
        high: { ...rangeBaseQuantity, value: parseInt(high) },
      },
    }
  } else {
    doseValue = {
      Quantity: {
        value: dosage.doseQuantity ? parseFloat(dosage.doseQuantity) : 0,
        unit: dosage.medicationQuantity?.unit,
        system: dosage.medicationQuantity?.system,
        code: dosage.medicationQuantity?.code,
      },
    }
  }

  return {
    timing: { ...dosage.doseTiming, ...frequency },
    route: { coding: dosage.treatmentRoute ? [dosage.treatmentRoute] : [] },
    text: isStructured ? generateInstructionTextFromDosage(dosage) : dosage.instructionText,
    doseAndRate: [{ dose: doseValue }],
  }
}

const generateInstructionTextFromDosage = (dosage: MedicationDosage) => {
  const quantityDisplay = `${dosage.doseQuantity} ${dosage.medicationQuantity?.unit?.toLowerCase()}`
  const frecuencyDisplay = prescriptionFrequencies
    .find((f) => f.value === dosage.treatmentFrequency)
    ?.label?.toLowerCase()
  const route = dosage.treatmentRoute?.display?.toLowerCase()
  const durationUnitDisplay = unitOfTime
    .find(({ code }) => code === dosage.prescriptionDurationUnit?.code)
    ?.display.toLowerCase()
  const duration = `${parseInt(dosage.prescriptionDuration ?? "0")} ${pluralize(durationUnitDisplay ?? "", parseInt(dosage.prescriptionDuration ?? "0"))}`

  return `Take ${quantityDisplay} ${frecuencyDisplay} by ${route} for ${duration}`
}

const serializeDosage = (
  dosage: Dosage,
  intendedRoute?: CodeableConcept[],
  initialMedicationDosage?: MedicationDosage,
): MedicationDosage => {
  const dosageQuantity = dosage?.doseAndRate?.[0]?.dose?.Quantity
  const { code, system, unit } = dosageQuantity ?? {}
  const medicationQuantity = initialMedicationDosage?.medicationQuantity ?? (dosageQuantity && { code, system, unit })

  const doseQuantity = dosage.doseAndRate?.[0]?.dose?.Quantity?.value?.toString()
  const treatmentFrequency = getTreatmentFrequency(dosage)
  const doseTiming = dosage?.timing
  const prescriptionDuration = dosage?.timing?.repeat?.duration?.toString()
  const prescriptionDurationUnit = durationCodeOptions.find(
    (option) => option?.value?.code === dosage?.timing?.repeat?.durationUnit,
  )?.value

  return {
    doseQuantity,
    medicationQuantity,
    treatmentRoute: intendedRoute?.[0]?.coding?.[0],
    treatmentFrequency,
    prescriptionDuration,
    prescriptionDurationUnit,
    instructionText: dosage.text,
    doseTiming,
  }
}

const sanitizeMedicationIngredient = (data: MedicationIngredientFormData): MedicationIngredientArray => {
  const { name, unit, value } = data

  return {
    item: {
      CodeableConcept: {
        coding: [
          {
            code: name?.toLocaleLowerCase(),
            display: name,
            system: SYSTEM_VALUES.VS_INGREDIENT,
          },
        ],
        text: name,
      },
    },
    strength: { numerator: { unit, value } },
  }
}

const sanitizeMedication = (data: MedicationFormData): Medication => {
  delete data.newIngredient

  return data
}

export {
  generateInstructionTextFromDosage,
  sanitize,
  sanitizeDosage,
  sanitizeMedication,
  sanitizeMedicationIngredient,
  serializeDosage,
}
