import { type Practitioner, humanNameAsString } from "fhir"
import { type FieldProps, type FieldValidator, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC, useEffect } from "react"

import type { PractitionerInfo } from "../types"
import { type FormFieldBaseProps, FormField } from "./FormField"

const PractitionerRoleDropdownField: FC<Props> = ({
  field,
  options,
  label,
  className,
  filterBy = "practitioner.name.0.given.0,practitioner.name.0.family",
  disabled,
  useFilter = true,
  autoSelectWhenOne = true,
  validate,
  ...formFieldProps
}) => {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (autoSelectWhenOne && !disabled && options.length === 1) {
      options[0]?.practitionerRoleRef && setFieldValue(field, options[0]?.practitionerRoleRef)
    }
  }, [])

  return (
    <FormField field={field} validation={validate} label={label} className={className} {...formFieldProps}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <Dropdown
          id={name}
          name={name}
          dataKey="practitionerRoleRef"
          optionLabel="practitioner.name.0.family"
          optionValue="practitionerRoleRef"
          itemTemplate={itemPRTemplate}
          valueTemplate={itemPRTemplate}
          options={options}
          filter={useFilter}
          filterBy={filterBy}
          resetFilterOnHide={true}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
        />
      )}
    </FormField>
  )
}

const itemPRTemplate = (option: { practitioner: Practitioner }) => {
  return <>{option?.practitioner.name?.[0]?.text ?? humanNameAsString(option?.practitioner.name?.[0])}</>
}

type Props = {
  field: string
  options: PractitionerInfo[]
  label?: string
  className?: string
  filterBy?: string
  disabled?: boolean
  useFilter?: boolean
  autoSelectWhenOne?: boolean
  validate?: FieldValidator
} & FormFieldBaseProps

export { PractitionerRoleDropdownField }
