import { faScannerGun } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarDays, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Invoice } from "fhir"

import type { StackedListItemProps } from "commons"
import { formatsByTypes, invoiceStatusCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { formatDate, getBadgeColor, getMoneyCurrencyAlt } from "utils"

const invoiceModelBuilder = ({
  invoice,
  showInvoice,
  showAsExternal,
  sendToPatientInvoice,
}: InvoiceModelBuilderArgs): StackedListItemProps => {
  const identifier = invoice.identifier?.find(({ system }) => system === SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value
  const invoiceDate = invoice.date && formatDate(new Date(invoice.date), formatsByTypes.SHORT_DATE)
  const itemsCount = invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length
  const price = invoice?.totalGross?.value
    ? getMoneyCurrencyAlt(invoice?.totalGross.currency) + invoice.totalGross.value.toFixed(2)
    : "$0.00"

  return {
    leftData: [
      {
        lineItems: [{ name: "Invoice number", value: identifier ? `#${identifier}` : "Unspecified number" }],
      },
      {
        lineItems: [
          { name: "Issued date", value: invoiceDate, icon: faCalendarDays },
          { name: "Items", value: `${itemsCount}`, icon: faScannerGun },
          { name: "Total Net", value: `${price}` },
        ],
      },
    ],
    menu: [
      {
        label: "Send to patient",
        icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
        command: sendToPatientInvoice,
      },
    ],
    onClick: !showAsExternal ? showInvoice : undefined,
    badge: {
      ...getBadgeColor(invoice.status),
      text: invoiceStatusCodes.find((status) => status.code === invoice.status)?.display ?? "Unknown",
    },
  }
}

interface InvoiceModelBuilderArgs {
  invoice: Invoice
  showInvoice?: () => void
  showAsExternal?: boolean
  sendToPatientInvoice?: () => void
}

export { invoiceModelBuilder }
