import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Invoice } from "fhir"
import { Dialog } from "primereact/dialog"
import { InputSwitch, type InputSwitchChangeEvent } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import type { FC } from "react"

import { Button } from "../../components/Buttons"

const MedicationCancelDialog: FC<Props> = ({
  cancelFutureOrder,
  refundItemsAsWell = false,
  cancelReason,
  invoice,
  visible,
  isCancelling,
  updateCancelFutureOrder,
  updateRefundItemsAsWell,
  onConfirm,
  onHide,
  updateCancelReason,
}) => {
  const actions: MedicationCancelDialogActionsItemProps[] = [
    {
      name: "status",
      label: "Cancel all future orders",
      description: "Enabled these to cancel all future orders",
      checked: cancelFutureOrder === "stop",
      onChange: () => updateCancelFutureOrder(cancelFutureOrder === "stop" ? "skip" : "stop"),
    },
    {
      name: "refund",
      label: "Refund items as well",
      description: "Enabled these to refund items linked to these order",
      checked: refundItemsAsWell,
      onChange: (e) => updateRefundItemsAsWell(e.value),
    },
  ]

  return (
    <Dialog
      closable={true}
      header="Cancel order"
      visible={visible || isCancelling}
      draggable={false}
      dismissableMask={true}
      style={{ width: "35vw" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button
            label="Cancel order"
            className="button-primary w-full"
            disabled={!cancelReason}
            loading={isCancelling}
            onClick={onConfirm}
          />
        </div>
      }
    >
      {invoice?.status === "balanced" && (
        <div className="text-orange-500 pb-4 flex items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
          <div className="pl-2 text-sm">
            <div className="leading-4">The order you are trying to cancel is paid</div>
            <div className="leading-4">Invoice identifier: {invoice?.identifier?.[0].value}</div>
          </div>
        </div>
      )}
      <label>Please provide a reason</label>
      <InputText
        className="w-full mt-2"
        value={cancelReason}
        onChange={(e) => updateCancelReason(e.target.value as string)}
      />
      <MedicationCancelDialogActionsContainer actions={actions} />
    </Dialog>
  )
}

const MedicationCancelDialogActionsContainer: FC<MedicationCancelDialogActionsContainerProps> = ({ actions }) => {
  return (
    <div className="mt-2 divide-y divide-gray-200">
      {actions.map((action) => (
        <MedicationCancelDialogActionsItem key={action.name} {...action} />
      ))}
    </div>
  )
}

const MedicationCancelDialogActionsItem: FC<MedicationCancelDialogActionsItemProps> = ({
  name,
  label,
  description,
  checked,
  onChange,
  visible = true,
}) => {
  return (
    visible && (
      <div className="flex items-center justify-between py-4">
        <div className="flex flex-col">
          <label className="text-gray-700 text-sm">{label}</label>
          <span className="text-xs text-gray-500">{description ?? ""}</span>
        </div>
        <InputSwitch name={name} checked={checked} onChange={onChange} />
      </div>
    )
  )
}

type MedicationCancelDialogActionsContainerProps = {
  actions: MedicationCancelDialogActionsItemProps[]
}

type MedicationCancelDialogActionsItemProps = {
  name: string
  label: string
  description?: string
  checked: boolean
  onChange(e: InputSwitchChangeEvent): void
  visible?: boolean
}

type Props = {
  isCancelling?: boolean
  visible: boolean
  cancelFutureOrder?: string
  refundItemsAsWell?: boolean
  invoice?: Invoice
  cancelReason?: string
  updateCancelFutureOrder(mode: "stop" | "skip"): void
  updateRefundItemsAsWell(refund: boolean): void
  updateCancelReason(reason: string): void
  onHide(): void
  onConfirm(): void
}

export { MedicationCancelDialog }
