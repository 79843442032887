import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import type { FormikValues } from "formik"
import { type FC, useCallback, useId, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  DataContainerWithFormSlideoverDetail,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import {
  type PlanData,
  PLAN_SUBVIEWS,
  PlanContext,
  planItemModel,
  useAutoEnroll,
  useCancelPlan,
  useCarePlans,
  usePlanContext,
} from "commons/care-plans"
import { getContextLabel } from "commons/care-plans/utils"
import { MC_PUBLISHER } from "data"
import { useAppModuleContext } from "internals"
import { strCapitalize } from "utils"

const PlanListContainer: FC<Props> = ({ searchText, statusFilter }) => {
  const loaderKey = useId()
  const { appModules } = useAppModuleContext()
  const [searchParams] = useSearchParams()
  const { patientId, patient, navigateToSubview, handleConfigurePlan } = usePlanContext()
  const { deleteIndex, reset, setDeleteIndex } = useStateReducer()
  const { hasGP } = useChartContext()

  const { plans, isLoading, isFetching, activePDs } = useCarePlans({
    patientId,
    searchText,
    statusFilter,
  })

  const showDetails = (planId: string) => {
    navigateToSubview(PLAN_SUBVIEWS.DETAILS, planId)
  }

  const showConfig = (planId: string) => {
    handleConfigurePlan(planId)
  }

  const { cancelPlan, isCanceling } = useCancelPlan(() => {
    reset()
  })

  const { isEnrolling, somePlanToEnroll, enroll } = useAutoEnroll(patient, activePDs, showConfig)

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const filteredPlans = useMemo(
    () =>
      plans.filter(
        ({ planDefinition, carePlan }) => planDefinition.publisher === MC_PUBLISHER && carePlan.intent === "plan",
      ),
    [plans],
  )

  const contextLabel = useMemo(
    () => getContextLabel((searchParams.get("context") ?? PlanContext.MC_ORDERS) as PlanContext),
    [searchParams],
  )

  const draftPlan = useMemo(() => plans.find(({ carePlan }) => carePlan.status === "draft"), [plans])

  const addButtonAction = useCallback(() => {
    if (somePlanToEnroll) enroll()
    else if (draftPlan) showConfig(draftPlan.carePlan.id as string)
  }, [somePlanToEnroll, draftPlan])

  return isLoading || isEnrolling ? (
    loader()
  ) : (
    <>
      <DataContainerWithFormSlideoverDetail
        showForm={false}
        hasData={filteredPlans.length > 0}
        customAddIcon={draftPlan && !somePlanToEnroll ? faPencil : undefined}
        customAddButtonText={`${!somePlanToEnroll && draftPlan ? "Edit" : "Create New"} ${strCapitalize(contextLabel)}`}
        iconDataNotFound={appModules[ModulesId.PLANS].getIcon()}
        itemTitleNotFound={contextLabel}
        customAddButton={!somePlanToEnroll ? <></> : undefined}
        customEmptyAddButton={!somePlanToEnroll ? <></> : undefined}
        onButtonAddClick={addButtonAction}
        disabledAddButton={!hasGP}
        form={<></>}
        onCancel={reset}
        deleteSupport={{
          confirmDeleteText: `Are you sure you want to cancel this ${contextLabel}?`,
          deleteActionText: "Accept",
          showDelete: deleteIndex !== undefined || isCanceling,
          isDeleting: isCanceling,
          onCancelDelete: () => setDeleteIndex(undefined),
          onConfirmDelete: () => cancelPlan(deleteIndex as string),
        }}
        addButtonClassName="create-order-button"
      >
        <div className="h-full overflow-auto">
          <StackedListContainer
            data={filteredPlans}
            itemModelBuilder={(item) => {
              return planItemModel({
                plan: item,
                isLoading: isFetching,
                viewPlan: () => {
                  showDetails(item.carePlan.id as string)
                },
                editPlan: () => {
                  showConfig(item.carePlan.id as string)
                },
                cancelPlan: () => setDeleteIndex(item.carePlan.id),
                contextLabel,
                showConfig: hasGP,
              })
            }}
          />
        </div>
      </DataContainerWithFormSlideoverDetail>
    </>
  )
}

const useStateReducer = () => {
  const { showSlide, initialValue, isNew, deleteIndex, add, hardReset, setDeleteIndex } = useCrudReducer<
    PlanData,
    FormikValues
  >({})

  return {
    showForm: showSlide,
    initialValue,
    isNew,
    deleteIndex,
    add,
    reset: hardReset,
    setDeleteIndex,
  }
}

type Props = {
  searchText?: string
  statusFilter?: string[]
}

export { PlanListContainer }
