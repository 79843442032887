import { type Attachment, type Person, humanNameAsString } from "fhir"
import { useFormikContext } from "formik"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"
import { type FC, useMemo, useState } from "react"

import { azureContainer } from "data"

import { AvatarCrop } from "../components/AvatarCrop"
import { AvatarImage } from "../components/AvatarImage"

const ProfileImageField: FC<Props> = ({ fieldParent, className, imageClassName = "size-48 rounded" }) => {
  const [showAvatarCrop, setShowAvatarCrop] = useState(false)
  const fieldPath = fieldParent ? `${fieldParent}.photo[0].url` : "photo[0].url"

  const { values, getFieldMeta, setFieldValue } = useFormikContext<Person & { photo?: Attachment[] }>()

  const updatePhoto = (url: string) => {
    setFieldValue(fieldPath, url)
  }

  const { name, id } = useMemo(
    () => (fieldParent ? (getFieldMeta(fieldParent)?.value as Person) : values),
    [values, getFieldMeta],
  )
  const photoUrl = useMemo(() => getFieldMeta(fieldPath)?.value as string | undefined, [values, getFieldMeta])

  return (
    <>
      <div className={classNames("flex gap-6 items-center justify-between", className)}>
        <AvatarImage photoUrl={photoUrl} name={humanNameAsString(name?.[0])} className={imageClassName} />
        <div className="flex flex-col gap-2 justify-center">
          <Button
            type="button"
            label="Change avatar"
            className="p-button-sm button-primary h-8"
            onClick={() => setShowAvatarCrop(true)}
          />
          <Button
            type="button"
            label="Delete avatar"
            className="p-button-sm p-button-outlined h-8"
            onClick={() => updatePhoto("")}
          />
        </div>
      </div>
      <AvatarCrop
        visible={showAvatarCrop}
        onHide={() => setShowAvatarCrop(false)}
        container={azureContainer.photos}
        userId={id as string}
        onUpload={updatePhoto}
      />
    </>
  )
}

type Props = {
  fieldParent?: string
  className?: string
  imageClassName?: string
}

export { ProfileImageField }
