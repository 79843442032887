import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { MedicationRequest, PGSequence } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess } from "utils"

import { eprescribeQueryKeys } from "../query-keys"

const useCreateMedicationRequest = (hideForm?: () => void) => {
  const { create, operationRequest } = useClient()
  const queryClient = useQueryClient()

  const newMedicationRequest = async (medicationReq: MedicationRequest) => {
    const sequence = await operationRequest<PGSequence>({
      endpoint: "PGSequence",
      method: "POST",
      operation: "",
      id: "medicationrequest_number_seq",
    })

    const mrIdentifier = [
      ...(medicationReq.identifier ?? []),
      { value: sequence.value.toString(), system: SYSTEM_VALUES.PUBLIC_MEDICATION },
    ]

    return create("MedicationRequest", { ...medicationReq, identifier: mrIdentifier })
  }

  const { mutate: createMedicationRequest, isPending: isAdding } = useMutation({
    mutationFn: newMedicationRequest,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { subject }) => {
      await queryClient.invalidateQueries({
        queryKey: subject.id ? medsQueryKeys.medicationRequestList(subject.id, "medication") : eprescribeQueryKeys.all,
        refetchType: subject.id ? "all" : undefined,
      })
      queryClient.refetchQueries({
        queryKey: subject.id ? medsQueryKeys.draft.list(subject.id, "medication") : eprescribeQueryKeys.draft.all,
      })
      queryClient.refetchQueries({
        queryKey: subject.id ? ordersQueryKeys.count.withPatientId(subject.id) : ordersQueryKeys.count.all,
        type: subject.id ? "all" : undefined,
      })

      displayNotificationSuccess("Prescription added successfully!")
      // datadogLogs.logger.info("Prescription added successfully!", { subject })
    },
    onSettled: () => {
      hideForm?.()
    },
  })

  return { createMedicationRequest, isAdding }
}

export { useCreateMedicationRequest }
