import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { CodeableConcept, Patient, PlanDefinitionActionArrayActionArray, Reference } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { ReferenceDropdownField, ShippingAddressField, TextAreaField } from "../../../forms"
import { addressTypes } from "../../../forms/data"
import { ICD10CodesField } from "../../../forms/ICD10Codes"
import { BillingField, BloodDrawField } from "../../../forms/labFields"
import { useLoadProductsPrice } from "../../../hooks"
import {
  getDefaultLabAddress,
  getRestrictedLabPerformer,
  useAvailableLabs,
  useLaboratoryLocations,
} from "../../../labs"
import type { PractitionerInfo } from "../../../types"
import { isCanonicalOfDrawFee } from "../../../utils"
import { type PlanData, PLAN_ACTION_CODES, PlanContext } from "../../types"
import { ComboSelection } from "./ComboSelection"
import { ExtraPanelField } from "./ExtraPanelField"

const InsuranceLabOrderWithoutPatientPhoneNumberMessage = "Patient phone number required"

const LabsFormSection: FC<Props> = ({ configureActions, practitionersInfo, patient }) => {
  const [params] = useSearchParams()
  const {
    laboratoryLocation,
    currentOrganizationId,
    isExemptLabPayment,
    performerLabsEnabled,
    organizationAnIdentifier,
    isUnrestrictedPerformer,
  } = useOrganizationContext()
  const {
    patient: { address },
  } = usePatientContext()

  const {
    values: { billingType, combos, performer, performers, combo, requester, order },
    setFieldTouched,
    setFieldValue,
    registerField,
  } = useFormikContext<PlanData>()

  const physicalOrAnyValidAddress = getDefaultLabAddress(order?.patientAddress ? [order.patientAddress] : address)

  const labAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_LABS]

  const { requiredLabOder } = useMemo(() => {
    const subActionsRequiredBehavior = labAction.action?.reduce(
      (acc, act) => {
        const code = act.code?.[0]?.coding?.[0]?.code
        const required = act.requiredBehavior === "must"
        return { ...acc, [code as string]: required }
      },
      {} as Record<string, boolean>,
    )

    return { requiredLabOder: labAction?.requiredBehavior === "must", subActionsRequiredBehavior }
  }, [labAction])

  useEffect(() => {
    const validateRefs = (value?: Reference, message: string = "This field is required") => (!value?.id ? message : "")
    if (requiredLabOder) {
      registerField("performer", { validate: (v) => validateRefs(v, "Lab is required") })
      registerField("panels", {
        validate: (v?: string[]) => {
          const noDrawPanels = v?.filter((pdCanonical) => !isCanonicalOfDrawFee(pdCanonical))

          return !noDrawPanels?.length && !combo ? "At least one panel is required" : ""
        },
      })
    }
  }, [requiredLabOder, combo])

  const { laboratoryRefs } = useAvailableLabs(
    practitionersInfo,
    performerLabsEnabled.filter(({ ref }) => performers?.some(({ id }) => id === ref.id)),
    requester,
    organizationAnIdentifier,
  )

  const { mapProductsPrice, isPending } = useLoadProductsPrice(
    currentOrganizationId,
    billingType ?? BILLING_TYPES_CODES.BILL_PATIENT,
    "combo",
    (data) => setFieldValue("combos", data),
  )

  const { updateLabLocation } = useLaboratoryLocations(laboratoryLocation, (locationRefs) =>
    setFieldValue("order.locationReference", locationRefs),
  )

  useEffect(() => {
    setFieldTouched("order.reasonCode", true)
    if (!isExemptLabPayment) mapProductsPrice({ products: combos })
  }, [billingType, setFieldTouched])

  const isMCView = useMemo(() => params.get("view") === PlanContext.MC, [params])

  const showOnlyInsuranceBilling = useMemo(
    () =>
      Array.from<string>(
        JSON.parse(window.VITE_APP_STATES_WITH_RESTRICTED_BILLING?.replace(/'/g, '"') ?? "[]"),
      )?.includes(physicalOrAnyValidAddress?.state as string),
    [physicalOrAnyValidAddress?.state, window.VITE_APP_STATES_WITH_RESTRICTED_BILLING],
  )

  const isInsuranceLabOrderWithoutPatientPhoneNumber = useMemo(
    () =>
      billingType === BILLING_TYPES_CODES.INSURANCE &&
      !patient.telecom?.find(({ system }) => system === "phone")?.value,
    [billingType, patient.telecom],
  )

  return (
    <div className="flex flex-col col-span-6 gap-6">
      <div className="grid grid-cols-2 gap-x-6">
        <ReferenceDropdownField
          field="performer"
          label="Laboratory"
          placeholder="Select Laboratory"
          options={laboratoryRefs}
          showFilter={false}
          handleChange={(newPerRef) => updateLabLocation(newPerRef?.id)}
          assignDefaultValue={requiredLabOder && !isMCView}
          isClearable={!requiredLabOder}
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
          className="flex-1 w-full"
        />
        <BloodDrawField
          checkboxClassName="border-t-0 border-none items-start"
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
          performerId={performer?.id}
        />
      </div>

      <SectionName sectionName="Address" />
      <ShippingAddressField
        fieldPath="order.patientAddress"
        showLabel={false}
        defaultAddressType={addressTypes.find(({ code }) => code === "physical")}
        hideOrganizationAddress
        className="flex-col-reverse"
      />

      <SectionName sectionName="Bill to" />
      <div className="grid grid-cols-2 gap-4">
        <BillingField
          showBillingLabel={false}
          className="pl-4"
          insuranceField="order.insurance[0]"
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
          insuranceValidation={(value) =>
            !value?.id
              ? "Insurance Plan is required"
              : isInsuranceLabOrderWithoutPatientPhoneNumber
                ? InsuranceLabOrderWithoutPatientPhoneNumberMessage
                : ""
          }
          radioButtonLabelClassName="text-sm text-gray-500 font-medium leading-[1.0625rem]"
          hideNoInsuranceOptions={!isUnrestrictedPerformer(performer?.id as string) && showOnlyInsuranceBilling}
        />
      </div>

      <SectionName sectionName={`${performer?.display ? `${performer.display} ` : ""}Combos`} />
      <div className="grid grid-cols-2 gap-4">
        {combos.length && performer?.id ? (
          <ComboSelection
            className={classNames("mt-2")}
            containerClassName={classNames("gap-6 overflow-y-auto")}
            combos={combos}
            isLoadingPrices={isPending}
            isExemptLabPayment={isExemptLabPayment}
            labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
          />
        ) : (
          <div className="flex flex-col items-center justify-center col-span-2 py-3">
            <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
            <p className="text-slate-500 text-xs pt-1">No combos found</p>
          </div>
        )}
      </div>

      <SectionName sectionName="Additional Tests" />
      <ExtraPanelField
        plan={{ combos, performer }}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
        className={classNames({ "mt-4": !performer })}
        containerClassName="text-sm"
      />

      <SectionName sectionName="ICD-10" />
      <ICD10CodesField
        field="order.reasonCode"
        showLabel={false}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
        showSuggestedPatientConditions
        orgSuggestedConditions="defaultProcedureIcd10"
        validate={(value?: CodeableConcept[]) => {
          const restrictedLabPerformer = getRestrictedLabPerformer(performer?.id as string)
          return (
            (billingType === BILLING_TYPES_CODES.INSURANCE && !value?.length
              ? "Specify at least one condition"
              : undefined) ||
            (restrictedLabPerformer && performer
              ? value?.length && value?.length > restrictedLabPerformer.maxAmount
                ? `You have surpassed the maximum of allowed ICD-10 codes selectable with ${performer.display} (${restrictedLabPerformer.maxAmount})`
                : undefined
              : undefined)
          )
        }}
        patient={patient}
        icon={faSearch}
      />
      <SectionName sectionName="Notes" />
      <TextAreaField field="order.note[0].text" rows={2} autoResize />
    </div>
  )
}

const SectionName = ({ sectionName }: { sectionName: string }) => (
  <div className="relative border-t">
    <span className="font-medium text-sm bg-white text-gray-500 pr-2 absolute top-0 -translate-y-1/2">
      {sectionName}
    </span>
  </div>
)

type Props = {
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  practitionersInfo: PractitionerInfo[]
  patient: Patient
}

export { LabsFormSection }
