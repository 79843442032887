import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCheck, faPencil, faBuilding, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { faArrowsRotateReverse } from "@fortawesome/pro-duotone-svg-icons"
import type { FC } from "react"
import type { AccountBETACreditCardArray } from "fhir"

import {
  type StackedListItemProps,
  ConfirmDialog,
  DataContainerSlideoverForm,
  StackedListContainer,
  useCrudReducer,
  ModulesId,
} from "commons"
import { useOrganizationContext, useCreateOrganizationAccount } from "organization"
import { formatCreditCardNumber } from "utils"
import {
  useCreateCreditCard,
  useUpdateCreditCard,
  getInitialValues,
  creditCardValidationSchema,
  CreditCardForm,
  CreditCardImage,
  useDeleteCreditCard,
} from "account"
import { useAppModuleContext } from "internals"

const PaymentMethod: FC = () => {
  const { currentOrganization, currentOrganizationCCAccount, currentOrganizationId } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const creditCards = currentOrganizationCCAccount?.creditCard ?? []
  const creditCardId = creditCards[0] && `${creditCards[0].type}|${creditCards[0].last4Digits}`

  const { showSlide, initialValue, isNew, deleteIndex, reset, add, edit, setDeleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(currentOrganization),
  })

  const { createCreditCard } = useCreateCreditCard(reset)
  const { updateCreditCard, isUpdating } = useUpdateCreditCard(reset)
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => setDeleteIndex())
  const { createOrgAccount } = useCreateOrganizationAccount(reset)

  const onSubmit = (creditCard: AccountBETACreditCardArray) => {
    if (!currentOrganizationCCAccount) {
      createOrgAccount({ organization: currentOrganization, creditCard })
      return
    }

    isNew
      ? createCreditCard({
          creditCard,
          account: currentOrganizationCCAccount,
          creditCardList: creditCards,
          organizationId: currentOrganizationId,
        })
      : updateCreditCard({
          creditCard,
          account: currentOrganizationCCAccount,
          creditCardList: [],
          index: 0,
        })
  }

  return (
    <DataContainerSlideoverForm
      hasData={!!creditCards?.length}
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      formTitle={"Credit Card"}
      formInitialValue={initialValue}
      validationSchema={creditCardValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<CreditCardForm isEditing={!isNew} shippingAddress={currentOrganization.address?.[0]} />}
      customAddButtonText={creditCards?.length ? "Replace credit card" : "Add new credit card"}
      customAddIcon={faArrowsRotateReverse}
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["patient"][ModulesId.CREDIT_CARDS].getIcon()}
      subMessageDataNotFound="Get started by adding a new Credit Card"
    >
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={creditCards}
          itemModelBuilder={(item, index) =>
            modelBuilder(
              item,
              isUpdating,
              isExpired(item),
              () => edit(item),
              () => setDeleteIndex(index),
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this credit card?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() =>
          removeCreditCard({
            index: deleteIndex as number,
            accountId: currentOrganizationCCAccount?.id as string,
            creditCardList: creditCards,
            defaultCreditCardId: creditCardId,
            organizationId: currentOrganizationId,
          })
        }
        hideDialog={() => setDeleteIndex()}
      />
    </DataContainerSlideoverForm>
  )
}

const isExpired = (creditCard: AccountBETACreditCardArray) =>
  new Date(parseInt(creditCard?.expirationYear as string), parseInt(creditCard?.expirationMonth as string), 1) <
  new Date()

const modelBuilder = (
  creditCard: AccountBETACreditCardArray,
  isUpdating: boolean,
  isExpired: boolean,
  edit: () => void,
  remove: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Number", value: formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type) }],
    },
    {
      lineItems: [
        { name: "Holder name", value: creditCard.cardHolderName ?? "Unspecified", icon: faBuilding },
        {
          name: "Expiration date",
          value: `${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`,
          icon: faCalendarCheck,
        },
      ],
    },
  ],
  isLoading: isUpdating,
  menu: [
    {
      label: "Edit billing address",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      command: edit,
    },
    { label: "Delete", icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />, command: remove },
  ],
  image: <CreditCardImage creditCard={creditCard} />,
  badge: isExpired ? { text: "expired", colorStyle: "red" } : undefined,
})

export { PaymentMethod }
