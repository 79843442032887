import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { faGenderless, faMars, faVenus } from "@fortawesome/pro-regular-svg-icons"
import { BigNumber } from "bignumber.js"
import { hsl } from "color-convert"
import { getTypeInfo, types } from "credit-card-type"
import { format as dateFnFormat, format, isToday, isYesterday } from "date-fns"
import {
  type Address,
  type ChargeItemDefinition,
  type ChargeItemDefinitionPropertyGroupArrayPriceComponentArray,
  type CodeableConcept,
  type Coding,
  type Coverage,
  type Duration,
  type Identifier,
  type Invoice,
  type MedicationKnowledge,
  type MedicationRequest,
  type Patient,
  type PlanDefinition,
  type PractitionerRole,
  type Quantity,
  type Reference,
  type ServiceRequest,
  asReference,
  humanNameAsString,
  isCoverage,
} from "fhir"
import isArray from "lodash/isArray"
import { createElement } from "react"
import { toast } from "react-toastify"
import { v4 } from "uuid"
import * as Yup from "yup"
import type { AnyObject } from "yup/lib/types"

import type { BadgeProps } from "commons/components/Badge"
import { NotificationConfirm } from "commons/components/NotificationConfirm"
import { NotificationSuccess } from "commons/components/NotificationSuccess"
import { NotificationWarningUpdateAvailable } from "commons/components/NotificationWarningUpdateAvailable"
import type { CustomError, PractitionerInfo } from "commons/types"
import {
  ADULT_SIGNATURE_REQUIRED,
  BILLING_TYPES_CODES,
  ContactPointSystem,
  LAB_REFERENCE_ID_CODE,
  PRACTITIONER_ROLE,
  UNIT_DAYS,
  formatsByTypes,
  mrCategoryCodes,
} from "data"
import { displayNotificationError } from "errors"
import { SYSTEM_VALUES } from "system-values"

const formatDate = (date: Date, format = "yyyy-MM-dd") => dateFnFormat(date, format)

const strCapitalize = (str: string) => (str ? `${str[0].toUpperCase()}${str.substring(1)}` : "")

const isISOdate = (str: string) => {
  const ISOdateRegex = "^-?[0-9]{4}(-(0[1-9]|1[0-2])(-(0[0-9]|[1-2][0-9]|3[0-1]))?)?$"
  const matches = str.match(ISOdateRegex)
  return matches && matches.length > 0
}

function calculateAge(birthday: string | undefined) {
  if (!birthday) {
    return "unknown age"
  }

  const date = new Date(birthday)
  const ageDifMs = Date.now() - date.getTime()
  const ageDate = new Date(ageDifMs)

  return `${Math.abs(ageDate.getUTCFullYear() - 1970)}yo`
}

const getAddressSchema = (parentFieldName?: string) => {
  const parentFullFieldName = parentFieldName ? parentFieldName + "." : ""

  return Yup.object().shape({
    line: Yup.array()
      .of(
        Yup.string().test("test-address-lines", "First address line is required", (value, context) =>
          context?.path === `${parentFullFieldName}line[0]` ? value !== undefined && value !== "" : true,
        ),
      )
      .min(1, ({ min }) => `At least ${min} address line is required`),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("ZIP is required"),
  })
}

const humanNameSchema = (fieldPath = "") =>
  Yup.object().shape({
    given: Yup.array()
      .of(
        Yup.string().test("test-first-name", "First name is required", (value, context) => {
          const relativePath = fieldPath ? `${fieldPath}.` : ""

          return context?.path === `${relativePath}name[0].given[0]` ? value !== undefined && value !== "" : true
        }),
      )
      .min(1, "Name is required"),
    family: Yup.string().required("Last name is required"),
  })

const telecomSchema = (requiredSystems: string[] = []) =>
  Yup.object().shape({
    system: Yup.string()
      .oneOf(
        [
          ContactPointSystem.email,
          ContactPointSystem.fax,
          ContactPointSystem.other,
          ContactPointSystem.pager,
          ContactPointSystem.phone,
          ContactPointSystem.sms,
          ContactPointSystem.url,
        ],
        "Invalid value",
      )
      .required("Specify telecom system"),
    value: Yup.string().when("system", (system: string, schema) => {
      const errorTextMessage = `${strCapitalize(system)} is required`

      return system === ContactPointSystem.email || requiredSystems.includes(system)
        ? schema.required(errorTextMessage)
        : schema
    }),
  })

const convertCCType = (type?: string) => {
  switch (type) {
    case "AE":
      return types.AMERICAN_EXPRESS
    case "V":
      return types.VISA
    case "MC":
      return types.MASTERCARD
    case "D":
      return types.DISCOVER
    case "DC":
      return types.DINERS_CLUB
    case "JCB":
      return types.JCB
    default:
      return "unsuported"
  }
}

const formatCreditCardNumber = (cardNumber: string, type?: string, asMask = false) => {
  const card = getTypeInfo(convertCCType(type))

  if (card && cardNumber?.length > 0) {
    const expectedCardLength = card?.lengths?.[0]
    if (cardNumber.length < expectedCardLength) {
      cardNumber = `${`${asMask ? "9" : "X"}`.repeat(expectedCardLength - cardNumber.length)}${cardNumber}`
    }

    const offsets = ([] as number[]).concat(0, card.gaps, cardNumber.length)
    const components = [] as string[]

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i]
      const end = Math.min(offsets[i + 1], cardNumber.length)
      components.push(cardNumber.substring(start, end))
    }

    return components.join("-")
  }

  return cardNumber
}

const displayNotificationSuccess = (message: string, autoClose: number | false = 2500) => {
  toast.success(createElement(NotificationSuccess, { message }), { autoClose })
}

const displayConfirmNotification = (
  header: string,
  message: string,
  onConfirm: () => void,
  autoClose: number | false = 5000,
) => {
  toast.info(
    ({ closeToast }) =>
      createElement(NotificationConfirm, {
        header,
        message,
        onConfirm: () => {
          onConfirm()
          closeToast?.()
        },
      }),
    { autoClose },
  )
}

const displayNotificationWarningUpdateAvailable = (message: string) => {
  const toastId = "new-available-update-toast"

  toast.warn(
    ({ closeToast }) =>
      createElement(NotificationWarningUpdateAvailable, {
        message,
        onClose: closeToast,
      }),
    {
      autoClose: false,
      draggable: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      icon: false,
      toastId: toastId,
      closeOnClick: false,
    },
  )
}

const getMoneyCurrencyAlt = (currency?: string) => {
  switch (currency) {
    case "USD":
      return "$"
    case "EUR":
      return "€"

    default:
      return "$"
  }
}

const PRICE_SUB_SYSTEM = "sku"
const PRICE_SUB_SYSTEM_PROP61 = "sku-ca"
const priceSubSystems = [PRICE_SUB_SYSTEM_PROP61]
const lineInvoiceTypes = {
  TAX: "tax",
  FEE: "surcharge",
  DISCOUNT: "discount",
  BASE: "base",
  INFORMATIONAL: "informational",
}

const getCommonCode = ({
  codes,
  shippingAddressState,
  fallback = "no-code",
}: {
  codes: Coding[] | undefined
  shippingAddressState?: string
  fallback?: string
}) => {
  const priceSubSystemStateSuffix = shippingAddressState ? `-${shippingAddressState.toLowerCase()}` : null

  const priceSubSystemForState = priceSubSystemStateSuffix
    ? priceSubSystems.find((subSystem) => subSystem.endsWith(priceSubSystemStateSuffix))
    : null

  const effectivePriceSubSystem = priceSubSystemForState || PRICE_SUB_SYSTEM
  const effectiveCode =
    codes?.find(({ system }) => system?.includes(effectivePriceSubSystem))?.code ||
    codes?.find(({ system }) => system?.includes(PRICE_SUB_SYSTEM))?.code

  return effectiveCode ?? fallback
}

const getCommonCodeForAllSku = ({ codes, fallback = "no-code" }: { codes: Coding[] | undefined; fallback?: string }) =>
  codes
    ?.filter(
      ({ system }) =>
        priceSubSystems.some((priceSubSystem) => system?.includes(priceSubSystem)) ||
        system?.includes(PRICE_SUB_SYSTEM),
    )
    ?.reduce((acc, { code }) => [...acc, ...(code ? [code] : [])], Array<string>()) ?? [fallback]

const getBasePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.BASE)
}

const getFeePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.FEE)
}

const getBillToPatientFeePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find(
    (price) =>
      price.type === lineInvoiceTypes.INFORMATIONAL && price.code?.coding?.some((c) => c.code === "bill-patient"),
  )
}

const getDiscountPrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.DISCOUNT)
}

const getTaxPrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.TAX)
}

const cidSort = (arr: ChargeItemDefinition[]) =>
  arr.sort((a, b) =>
    (getFeePrice(a.propertyGroup?.[0].priceComponent)?.amount?.value ?? 0) <
    (getFeePrice(b.propertyGroup?.[0].priceComponent)?.amount?.value ?? 0)
      ? -1
      : 1,
  )

const getDefaultAvatar = (name: string, bg: string) => {
  return `${window.VITE_APP_AVATAR_SERVICE_URL}/9.x/initials/svg?seed=${name}&backgroundColor=${bg}`
}

const getHomeAddressIndex = (address: Address[] | undefined) =>
  address?.findIndex(({ use, type }) => use === "home" && (type === undefined || type === "home")) ?? -1

const getHomeAddress = (address: Address[] | undefined) =>
  address?.find(({ use, type }) => use === "home" && type === undefined)

const getAddressByType = (addressType: "postal" | "physical", address: Address[] | undefined) =>
  address?.find(({ use, type }) => use === "home" && type === addressType)

const getShippingAddressIndex = (address: Address[] | undefined) =>
  address?.findIndex(({ use, type }) => use === "home" && type === "postal") ?? -1

const getAddressByTypeIndex = ({
  addressType,
  address,
}: {
  addressType: string | undefined
  address: Address[] | undefined
}) => address?.findIndex(({ use, type }) => use === "home" && type === addressType) ?? -1

const getStringAddressByType = ({
  address,
  addressType,
  addressUse = "home",
}: {
  address: Address[] | undefined
  addressType?: string
  addressUse?: "home" | "other" | "temp"
}) => {
  const index = address?.findIndex(({ use, type }) => use === addressUse && type === addressType) ?? -1
  return getStringAddress(address?.[index])
}

const getStringAddress = (address?: Address) => {
  if (!address) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const areAddressesSimilars = (originalAddress: Address, compareAddress: Address) => {
  const originalStringAddress = getStringAddress(originalAddress).replace(/[ ,]/g, "").toLowerCase()
  const compareStringAddress = getStringAddress(compareAddress).replace(/[ ,]/g, "").toLowerCase()

  return originalStringAddress === compareStringAddress
}

const isSameAddress = (originalAddress?: Address, compareAddress?: Address) =>
  !!originalAddress &&
  !!compareAddress &&
  originalAddress?.line?.[0] === compareAddress?.line?.[0] &&
  originalAddress?.line?.[1] === compareAddress?.line?.[1] &&
  originalAddress?.postalCode === compareAddress?.postalCode &&
  originalAddress?.city === compareAddress?.city &&
  originalAddress?.state === compareAddress?.state &&
  originalAddress?.use === compareAddress?.use

const getBadgeColor = (text: string): BadgeProps => {
  switch (text.toLowerCase()) {
    case "completed":
    case "resolved":
    case "active":
    case "balanced":
    case "delivered":
    case "open":
    case "click":
    case "final results available":
      return { text: strCapitalize(text), colorStyle: "green" }

    case "stopped":
    case "not-done":
    case "recurrence":
    case "cancelled":
    case "entered-in-error":
    case "revoked":
    case "dropped":
    case "spam":
    case "unsubscribe":
    case "on-hold":
    case "stat":
      return { text: text === "revoked" ? "cancelled" : strCapitalize(text), colorStyle: "red" }
    case "draft":
    case "bounce":
    case "deferred":
    case "requisition pending":
    case "payment pending":
    case "asap":
      return { text: strCapitalize(text), colorStyle: "yellow" }
    case "ready":
    case "issued":
    case "in-progress":
    case "processed":
    case "preliminary results":
    case "urgent":
      return { text: strCapitalize(text), colorStyle: "blue" }
    case "requisition available":
      return { text: strCapitalize(text), colorStyle: "orange" }
    case "retired":
    case "refunded":
      return { text: "Archived", colorStyle: "gray" }
    default:
      return { text: strCapitalize(text), colorStyle: "gray" }
  }
}

const isMrProcedure = (mr: MedicationRequest) =>
  mr.category?.some(({ coding }) => coding?.[0].code === mrCategoryCodes["procedure"].code) ?? false

const isMrMedication = (mr: MedicationRequest) =>
  mr.category?.some(({ coding }) => coding?.[0].code === mrCategoryCodes["medication"].code) ?? false

const convertIdentifiersToCodings = (resourceList: { identifier?: Identifier[] }[]) => {
  const codes =
    resourceList?.reduce<Coding[]>((acc, pd) => {
      const newCodes = pd.identifier?.reduce<Coding[]>(
        (prev, id) => [...prev, { system: id.system, code: id.value }],
        [],
      )
      return newCodes ? [...acc, ...newCodes] : acc
    }, []) ?? []
  return codes
}

const getCidIdentifier = (code: string, factor = 1, frequencyIdentifier?: string) =>
  frequencyIdentifier ? `${code}-${factor}-${frequencyIdentifier}` : `${code}-${factor}`

const getCidFeeIdentifier = (cid: ChargeItemDefinition) => {
  const frequency = cid?.useContext?.find((context) => context?.code?.code === "dispense-frequency")?.value?.Quantity

  return getFeeIdentifier(frequency)
}

const getFeeIdentifier = (frequency?: Duration | Quantity) =>
  frequency ? `${frequency?.value}${frequency?.code}` : "fixed"

const hasMedAutoship = (medicationRequests?: MedicationRequest[]) =>
  medicationRequests?.some((mr) => (mr.dispenseRequest?.dispenseInterval?.value as number) > 0) ?? false

const getIndexedCID = (cids?: ChargeItemDefinition[], useFrequencyIdentifier = false) =>
  cids?.reduce<Record<string, ChargeItemDefinition>>((prev, cid) => {
    return {
      ...prev,
      [getCidIdentifier(
        getCommonCode({ codes: cid.code?.coding }),
        cid?.propertyGroup?.[0]?.priceComponent?.[0]?.factor,
        useFrequencyIdentifier ? getCidFeeIdentifier(cid) : undefined,
      )]: cid,
    }
  }, {}) ?? {}

const getDosespotDisableWarningMessage = (
  isAdmin: boolean,
  hasDosespotSetup: boolean,
  hasPractitionerDosespotSetup: boolean,
  hasPatientAddress: boolean,
  dosespotPractitioner: PractitionerInfo[],
) => {
  let message = "⚠️ Missing information:"
  let hasMissingInfo = false

  if (!hasDosespotSetup) {
    hasMissingInfo = true
    message += "\n- Current organization doesn't have a valid Dosespot identifier setup."
  }

  if (!hasPatientAddress) {
    hasMissingInfo = true
    message += "\n- This patient doesn't have an address added yet."
  }

  if (isAdmin && !dosespotPractitioner.length) {
    hasMissingInfo = true
    message += "\n- Has not found any practitioner in this organization that has a valid Dosespot identifier setup."
  }

  if (!isAdmin && !hasPractitionerDosespotSetup) {
    hasMissingInfo = true
    message += "\n- You don't have a valid Dosespot identifier setup."
  }

  return hasMissingInfo ? message : ""
}

const isAbortError = (reason: unknown): reason is DOMException =>
  reason instanceof DOMException && reason.name === "AbortError"

const hslToHex = (hslColor: string) => {
  if (new RegExp(/^\d+deg \d+% \d+%$/g).test(hslColor)) {
    const values = hslColor
      .replace("%", "")
      .split(" ")
      .map((v) => parseInt(v))
    return hsl.hex([values[0], values[1], values[2]])
  }
  return ""
}

const isSafariBrowser = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const openLinkInNewTab = (url?: string, onError?: (error: unknown) => void) => {
  if (url)
    try {
      const tab = window.open()
      if (tab) {
        tab.location.href = url
        tab.focus()
        return
      }
      /* Fallback download file */
      const link = document.createElement("a")
      link.download = url
      link.href = url
      link.target = "_blank"
      link.classList.add("hidden")
      document.body.appendChild(link)
      link.click()

      setTimeout(() => {
        document.body.removeChild(link)
      }, 2000)
    } catch (error) {
      onError?.(error)
    }
  else {
    displayNotificationError(new Error("Wrong document url!") as CustomError)
  }
}

// Replace placeholders in the template with data values
const populateTemplate = (template: string, data: Record<string, string>) => {
  return Object.entries(data).reduce(
    (template, [key, value]) => template.replace(new RegExp(`{{${key}}}`, "g"), value),
    template,
  )
}

const IsNetworkError = (message: string) => {
  const errorMessages = new Set([
    "Failed to fetch", // Chrome
    "NetworkError when attempting to fetch resource.", // Firefox
    "The Internet connection appears to be offline.", // Safari 16
    "Load failed", // Safari 17+
    "Network request failed", // `cross-fetch`
    "fetch failed", // Undici (Node.js)
  ])

  return errorMessages.has(message)
}
const areAddressesEquals = (firstAddress: Address, secondAddress: Address) =>
  JSON.stringify(firstAddress) === JSON.stringify(secondAddress)

const isRefrigeratedMedicationKnowledge = (medicationKnowledge: MedicationKnowledge) =>
  medicationKnowledge.drugCharacteristic?.some(({ type }) => type?.coding?.[0].code === "refrigerated")

const medicationKnowledgeRegulations = (medicationKnowledge: MedicationKnowledge) =>
  medicationKnowledge.regulatory?.reduce<Coding[]>((prev, { substitution }) => {
    if (substitution?.[0]?.type.coding?.[0]?.code) {
      const coding = substitution?.[0]?.type.coding[0]

      return [...prev, coding]
    }

    return prev
  }, [])

const getPatientDefaultPractitioner = (
  practitionersInfo: PractitionerInfo[],
  patient: Patient,
  loggedInPractitionerRole: PractitionerRole,
) => {
  const generalPractitionerRole = practitionersInfo.find(
    ({ practitioner }) => practitioner.id === patient.generalPractitioner?.[0]?.id,
  )?.practitionerRole

  let performer: Reference | undefined = generalPractitionerRole
    ? { ...asReference(generalPractitionerRole), display: generalPractitionerRole.practitioner?.display }
    : undefined

  if (isPRPractitioner(loggedInPractitionerRole)) {
    const pInfo = practitionersInfo.find((pinfo) => pinfo.practitionerRole?.id === loggedInPractitionerRole.id)

    performer = {
      ...asReference(loggedInPractitionerRole),
      display: humanNameAsString(pInfo?.practitioner.name?.[0], loggedInPractitionerRole.practitioner?.display),
    }
  }

  return performer
}

const referenceValidationSchema = Yup.object().shape({
  display: Yup.string().defined(({ path }) => `${strCapitalize(path.split("[0]")?.[0])} is required`),
  resourceType: Yup.string().defined("Invalid data provided"),
})

const codeableConceptValidationSchema = Yup.object().shape({
  coding: Yup.array().defined(({ path }) => `${strCapitalize(path.split("[0]")?.[0])} is required`),
  text: Yup.string().optional(),
})

const getDateLabel = (dateStr: string, formatting?: string) => {
  const date = new Date(dateStr)

  return isToday(date)
    ? "Today"
    : isYesterday(date)
      ? "Yesterday"
      : format(date, formatting ?? formatsByTypes.ISO_8601_DATE)
}

const isPRPractitioner = (pr: PractitionerRole) =>
  pr.code?.some(({ coding }) => coding?.[0]?.code === PRACTITIONER_ROLE.PRACTITIONER)

const getPractitionerInfo = (practId: string, practitionersInfo: PractitionerInfo[]) =>
  practitionersInfo.find(({ practitioner }) => practId === practitioner.id)

const sanitizeURL = (url: string) => {
  const splittedUrl = url.split("https://")
  const sanitizedUrl = splittedUrl[0].concat(splittedUrl[1]?.replaceAll("//", "/") ?? "")
  return sanitizedUrl.startsWith("/") ? sanitizedUrl.slice(1) : sanitizedUrl
}

const mergeSort = <T extends AnyObject>(
  list: T[],
  propToCompare: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFunction?: (a: any, b: any) => number,
): T[] => {
  if (list.length <= 1) {
    return list
  }

  const mid = Math.floor(list.length / 2)
  const leftHalf = list.slice(0, mid)
  const rightHalf = list.slice(mid)

  const sortedLeftHalf = mergeSort(leftHalf, propToCompare, compareFunction)
  const sortedRightHalf = mergeSort(rightHalf, propToCompare, compareFunction)

  return merge(sortedLeftHalf, sortedRightHalf, propToCompare, compareFunction)
}

const merge = <T extends AnyObject>(
  left: T[],
  right: T[],
  propToCompare: string, // Do not use a chained prop(x.y) here. If you need that level of deep use it with compareFunction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFunction?: (a: any, b: any) => number,
): T[] => {
  const merged: T[] = []
  let leftIndex = 0
  let rightIndex = 0

  while (leftIndex < left.length && rightIndex < right.length) {
    if (
      compareFunction
        ? compareFunction(left[leftIndex]?.[propToCompare] ?? 0, right[rightIndex]?.[propToCompare] ?? 0) <= 0
        : (left[leftIndex]?.[propToCompare] ?? 0) < (right[rightIndex]?.[propToCompare] ?? 0)
    ) {
      merged.push(left[leftIndex])
      leftIndex++
    } else {
      merged.push(right[rightIndex])
      rightIndex++
    }
  }

  while (leftIndex < left.length) {
    merged.push(left[leftIndex])
    leftIndex++
  }

  while (rightIndex < right.length) {
    merged.push(right[rightIndex])
    rightIndex++
  }

  return merged
}

const getServiceRequestBillingType = (sr?: ServiceRequest): BILLING_TYPES_CODES => {
  const billingType = sr?.insurance?.[0].localRef
    ? isCoverage(sr?.contained?.[0]) && sr?.contained?.[0]?.payor?.[0]?.resourceType === "Patient"
      ? BILLING_TYPES_CODES.BILL_PATIENT
      : BILLING_TYPES_CODES.BILL_PRACTICE
    : BILLING_TYPES_CODES.INSURANCE

  return billingType
}

const getOrderType = (serviceRequest?: ServiceRequest) =>
  serviceRequest?.orderDetail?.reduce((acc, { coding }) => {
    const typeCode = coding?.find(({ system }) => system === SYSTEM_VALUES.ORDER_DETAIL_TYPE)?.code
    return (typeCode !== ADULT_SIGNATURE_REQUIRED ? typeCode : undefined) ?? acc
  }, "")

const getLoincCode = (codes?: Coding[]) =>
  codes?.find(({ system }) => system === SYSTEM_VALUES.LOINC)?.code ?? "no code"

const getGenderIcon = (sex: string): IconDefinition => {
  switch (sex) {
    case "Female":
      return faVenus
    case "Male":
      return faMars
    default:
      return faGenderless
  }
}

const getLabOrderIdentifier = (orderRoot: ServiceRequest, ordersSplitted: ServiceRequest[]) => {
  let identifiers = "unavailable ID"

  const orderID = orderRoot.identifier?.find(({ type }) =>
    type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE),
  )?.value

  const splittedIdentifers = ordersSplitted.reduce<string[]>((prev, { identifier }) => {
    const id = identifier?.find(({ type }) => type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE))?.value

    if (id) {
      return [...prev, id]
    }

    return prev
  }, [])

  if (orderID !== undefined || splittedIdentifers.length) {
    identifiers = orderID !== undefined ? [orderID, ...splittedIdentifers].join(", ") : splittedIdentifers.join(", ")
  }

  return identifiers
}

const sumPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sum = bNum1.plus(bNum2)

  return { num1: bNum1, num2: bNum2, sum }
}

const substractPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sub = bNum1.minus(bNum2)

  return { num1: bNum1, num2: bNum2, sub }
}

const multiplyPrice = (price: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(price)
  const bNum2 = new BigNumber(num2)

  return bNum1.multipliedBy(bNum2)
}

const getCoverage = (billingType: BILLING_TYPES_CODES, patientRef: Reference, orgRef?: Reference) => {
  const coverage: Coverage = {
    id: v4(),
    type: {
      coding: [
        {
          code: billingType,
          system: SYSTEM_VALUES.COVERAGE_TYPE,
        },
      ],
    },
    beneficiary: patientRef,
    status: "active",
    payor: [...(billingType === BILLING_TYPES_CODES.BILL_PRACTICE ? [orgRef ?? patientRef] : [patientRef])],
    resourceType: "Coverage",
  }

  return coverage
}

const restrictedICD10LabPerformers = [
  { ids: ["1c4bfefb-99b0-4943-a1d0-add2650da5c2", "cb618bd3-a5b2-4b5f-84d1-bf12b82a3fc9"], maxAmount: 10 },
]

export const getCodeableConceptBySystem = (codeableConcepts: CodeableConcept[] = [], system: string) =>
  codeableConcepts.find((cc) => cc.coding?.some((c) => c.system === system))

export const getCodingBySystem = (codeableConcept: CodeableConcept | CodeableConcept[] = [], system: string) => {
  if (codeableConcept) {
    const cc = Array.isArray(codeableConcept) ? codeableConcept : [codeableConcept]
    const codings = cc.flatMap((cc) => cc.coding)
    return codings?.find((c) => c?.system === system)
  }
}

export const getBillingTypeCode = (mr?: MedicationRequest) =>
  mr?.insurance?.[0]?.localRef
    ? (mr?.contained?.find((resource) => (resource as Coverage)?.id === mr?.insurance?.[0]?.localRef) as Coverage)?.type
        ?.coding?.[0]?.code
    : undefined

export const getIdentifierBySystem = (identifiers: Identifier[] = [], system: string) =>
  identifiers.find(({ system: IdentifierSystem }) => IdentifierSystem === system)

const getMRStatus = (medication: MedicationRequest) =>
  medication.doNotPerform ? "suspended" : medication.status === "on-hold" ? "scheduled" : medication.status

const unitToDays = (unitCode?: string) => {
  return unitCode?.toLowerCase() === "h"
    ? 1 / 24
    : unitCode?.toLowerCase() === "wk"
      ? UNIT_DAYS.WEEK
      : unitCode?.toLowerCase() === "mo"
        ? UNIT_DAYS.MONTH
        : unitCode?.toLowerCase() === "a"
          ? UNIT_DAYS.YEAR
          : 1
}

const isPoBoxAddress = (address?: Address) =>
  /\b(?:[Pp]\.?\s*[Oo]\.?|post\s+office)(\s+)?(?:[Bb]ox|[0-9]*)?\b/g.test(address?.line?.[0] ?? "")

const hasPractitonerRoleCode = (code: PRACTITIONER_ROLE) => (practRole: PractitionerRole) =>
  practRole.code?.some(({ coding }) => coding?.[0].code === code)

const isAdminPractRole = hasPractitonerRoleCode(PRACTITIONER_ROLE.ADMIN)

const isNonAdminPractRole = hasPractitonerRoleCode(PRACTITIONER_ROLE.NON_ADMIN)

const isPractitionerPractRole = hasPractitonerRoleCode(PRACTITIONER_ROLE.PRACTITIONER)

const isStaffPractRole = hasPractitonerRoleCode(PRACTITIONER_ROLE.STAFF)

const getQuantity = (quantity?: Quantity | Quantity[]) =>
  quantity
    ? isArray(quantity)
      ? `${quantity.map((q) => q?.value ?? "N/A").join(" / ")} ${quantity[0]?.unit ?? ""}`
      : `${quantity?.value}  ${quantity?.unit ?? ""}`
    : "N/A"

const getInvoicesInfo = (invoices?: Invoice[]) =>
  invoices?.reduce(
    (acc, { totalGross, identifier }) => {
      const id = getIdentifierBySystem(identifier, SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value

      return {
        ...acc,
        totalPrice: {
          value: sumPrice(acc.totalPrice.value, totalGross?.value ?? 0).sum,
          currency: totalGross?.currency ?? acc.totalPrice.currency,
        },
        identifier: [...acc.identifier, ...(id ? [id] : [])],
      }
    },
    { totalPrice: { currency: "USD", value: new BigNumber(0) }, identifier: Array<string>() },
  ) ?? { totalPrice: undefined, identifier: Array<string>() }

const getPDReasonCodes = (planDefinition: PlanDefinition) => {
  const reasonCodesExpression = planDefinition.action
    ?.find(({ code }) => code?.some(({ coding }) => coding?.[0]?.code === "default-icd10-codes"))
    ?.dynamicValue?.find(({ path }) => path === "reasonCode")?.expression?.expression
  const icd10: CodeableConcept[] | undefined = reasonCodesExpression && JSON.parse(reasonCodesExpression)

  return icd10
}

const mergeUniqueReasonCodes = (
  prevReasonCodes: CodeableConcept[] | CodeableConcept | undefined,
  newReasonCodes: CodeableConcept[] | undefined,
) => {
  if (!prevReasonCodes && !newReasonCodes) return undefined

  if (prevReasonCodes && !Array.isArray(prevReasonCodes)) prevReasonCodes = [prevReasonCodes]

  const uniqueIndexedReasonCodes = [...(prevReasonCodes ?? []), ...(newReasonCodes ?? [])].reduce<
    Record<string, CodeableConcept>
  >((acc, cc) => {
    const code = cc.coding?.[0]?.code ?? ""
    if (!acc[code]) acc[code] = cc
    return acc
  }, {})

  return Object.values(uniqueIndexedReasonCodes)
}

export {
  IsNetworkError,
  areAddressesEquals,
  areAddressesSimilars,
  calculateAge,
  cidSort,
  codeableConceptValidationSchema,
  convertCCType,
  convertIdentifiersToCodings,
  displayConfirmNotification,
  displayNotificationSuccess,
  displayNotificationWarningUpdateAvailable,
  formatCreditCardNumber,
  formatDate,
  getAddressByType,
  getAddressByTypeIndex,
  getAddressSchema,
  getBadgeColor,
  getBasePrice,
  getBillToPatientFeePrice,
  getCidFeeIdentifier,
  getCidIdentifier,
  getCommonCode,
  getCommonCodeForAllSku,
  getCoverage,
  getDateLabel,
  getDefaultAvatar,
  getDiscountPrice,
  getDosespotDisableWarningMessage,
  getFeeIdentifier,
  getFeePrice,
  getGenderIcon,
  getHomeAddress,
  getHomeAddressIndex,
  getIndexedCID,
  getInvoicesInfo,
  getLabOrderIdentifier,
  getLoincCode,
  getMRStatus,
  getMoneyCurrencyAlt,
  getOrderType,
  getPDReasonCodes,
  getPatientDefaultPractitioner,
  getPractitionerInfo,
  getQuantity,
  getServiceRequestBillingType,
  getShippingAddressIndex,
  getStringAddress,
  getStringAddressByType,
  getTaxPrice,
  hasMedAutoship,
  hslToHex,
  humanNameSchema,
  isAbortError,
  isAdminPractRole,
  isISOdate,
  isMrMedication,
  isMrProcedure,
  isNonAdminPractRole,
  isPRPractitioner,
  isPoBoxAddress,
  isPractitionerPractRole,
  isRefrigeratedMedicationKnowledge,
  isSafariBrowser,
  isSameAddress,
  isStaffPractRole,
  lineInvoiceTypes,
  medicationKnowledgeRegulations,
  mergeSort,
  mergeUniqueReasonCodes,
  multiplyPrice,
  openLinkInNewTab,
  populateTemplate,
  referenceValidationSchema,
  restrictedICD10LabPerformers,
  sanitizeURL,
  strCapitalize,
  substractPrice,
  sumPrice,
  telecomSchema,
  unitToDays,
}
