import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faAddressBook, faFileInvoiceDollar, faIdCard } from "@fortawesome/pro-regular-svg-icons"
import { faFilter, faPills } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import type { MenuItemCommandEvent } from "primereact/menuitem"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useId, useReducer, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { GroupedList, InfiniteScroll, SearchInput, SkeletonLoader } from "commons"
import { ModulesId } from "commons/Module"
import { useAppModuleContext } from "internals"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"

import { usePatients } from "../hooks"
import type { FilterProps } from "../types"
import { PatientEmptyState } from "./PatientEmptyState"
import { PatientFilters } from "./PatientFilters"
import { PatientListItem } from "./PatientListItem"

const PatientList = ({ showForm }: Props) => {
  const navigate = useNavigate()
  const { searchText, gender, practitioner, search, filter, reset } = useReducerState()
  const { currentOrganizationId } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()

  const { patientGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } = usePatients(
    searchText,
    gender,
    practitioner,
  )

  const overlayFilter = useRef<OverlayPanel>(null)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  const { organizationPractitionerRefs } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const navigateAndStopPropagation = (path: string) => (e: MenuItemCommandEvent) => {
    e.originalEvent.stopPropagation()
    navigate(path)
  }

  return (
    <>
      <div className="px-6 pt-3 border-b drop-shadow patients-list-guiding">
        <h6 className="font-medium text-lg">Patients</h6>
        <p className="text-sm text-gray-500">
          Showing {count} patients of {total} found
        </p>
        <div className="flex py-4 w-full h-16 justify-between">
          <div className="flex">
            <SearchInput isLoading={isLoading || isFetchingNextPage} search={search} />

            <Button
              icon={
                <FontAwesomeIcon
                  icon={faFilter}
                  className={classNames(!practitioner && !gender ? "text-slate-400" : "text-primary")}
                />
              }
              className="p-button-sm p-3 button-default ml-3"
              onClick={(e) => overlayFilter?.current?.toggle(e)}
            />
            <OverlayPanel
              ref={overlayFilter}
              showCloseIcon={false}
              dismissable
              id="overlay_panel"
              className="form-panel"
              breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
              style={{ width: "25vw" }}
            >
              <PatientFilters
                initialValues={{ gender, practitioner }}
                practitioners={organizationPractitionerRefs}
                onSearch={(filters) => {
                  filter(filters)
                  overlayFilter?.current?.hide()
                }}
                onClearFilters={() => {
                  reset()
                  overlayFilter?.current?.hide()
                }}
              />
            </OverlayPanel>
          </div>
          <span
            title={
              organizationPractitionerRefs?.length === 0 ? "No practitioners defined for current organization" : ""
            }
          >
            <Button
              label="Create New Patient"
              className="button-primary text-xs h-full "
              onClick={showForm}
              disabled={organizationPractitionerRefs?.length === 0}
            />
          </span>
        </div>
      </div>

      {isLoading ? (
        loader()
      ) : !patientGroups?.length ? (
        <PatientEmptyState onAdd={organizationPractitionerRefs?.length ? showForm : undefined} />
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <GroupedList
              className="grow"
              groups={patientGroups}
              renderItem={(patient) => (
                <PatientListItem
                  patient={patient}
                  onClick={() =>
                    navigate(`/orgs/${currentOrganizationId}/patients/${patient.id}?kp=${ModulesId.PATIENT}`)
                  }
                  dropdownItems={[
                    {
                      label: "View Patient",
                      icon: <FontAwesomeIcon icon={faIdCard} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(`/orgs/${currentOrganizationId}/patients/${patient.id}`),
                    },
                    {
                      label: "View Contact Information",
                      icon: <FontAwesomeIcon icon={faAddressBook} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(
                        `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.PATIENT}&subview=contact`,
                      ),
                    },
                    {
                      label: "View Patient Invoices",
                      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(
                        `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.INVOICE}`,
                      ),
                    },
                    ...(isModuleActive(ModulesId.MEDICATIONR)
                      ? [
                          {
                            label: "Nutraceuticals",
                            icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
                            command: navigateAndStopPropagation(
                              `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.MEDICATIONR}`,
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
              )}
              renderEmptyState={() => (
                <div className="flex flex-col items-center justify-center pt-10">
                  <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                  <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
    </>
  )
}

const initialState = {
  searchText: "",
  practitioner: undefined,
  gender: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload?: string | FilterProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, gender, practitioner }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, gender, practitioner, filter, search, reset }
}

type Props = {
  showForm(): void
}

export { PatientList }
