import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { FC } from "react"
import { useSearchParams } from "react-router-dom"
import { isCarePlan } from "fhir"

import { StackedListContainer } from "commons"
import { surveyModelBuilder } from "surveys"

import { WrapUpSection } from "./WrapUpSection"
import { useEncounterContext } from "encounter/hooks"

/* TODO: See how register encounter info when assign a Q or fill a QR */
const QuestionnairesSection: FC<Props> = ({ icon, hideSpacer }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const showSelectedQR = (id: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: "survey",
      qrId: id,
    })
    setSearchParams(params)
  }

  const { encounterData, totalQs: total, planRefs } = useEncounterContext()

  return (
    <WrapUpSection
      sectionTitle="Questionnaires"
      isEmpty={!total}
      icon={icon}
      emptyMesage="No questionnaires assigned in this encounter"
      className="flex flex-row gap-x-16 w-full text-sm"
      useSpacer={!hideSpacer}
    >
      <StackedListContainer
        data={encounterData?.questionnaires ?? []}
        itemModelBuilder={(item) => {
          const cp = planRefs?.[item.qResponse?.basedOn?.find(isCarePlan)?.id as string]
          return {
            ...surveyModelBuilder({
              item,
              onClick: item.qResponse?.id ? () => showSelectedQR(item.qResponse?.id as string) : undefined,
              showMenuActions: false,
            }),
            ...(cp
              ? {
                  badge: {
                    text: cp.display ?? "Plan based",
                    colorStyle: "gray",
                    title: cp.display,
                    className: "truncate max-w-24 !block",
                  },
                }
              : { badge: undefined }),
          }
        }}
        className="w-full"
        itemsClassName="!pl-0 py-4"
      />
    </WrapUpSection>
  )
}

type Props = {
  icon: IconDefinition
  hideSpacer?: boolean
}

export { QuestionnairesSection }
