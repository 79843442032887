import { useInfiniteQuery } from "@tanstack/react-query"
import { type DiagnosticReport, type Observation, getResources, isDiagnosticReport } from "fhir"
import { getResourcesByTypeAsIndex } from "fhir/utils"
import { useMemo } from "react"

import { useClient } from "api"

import { laboratoryQueryKeys } from "../query-keys"
import type { Laboratory } from "../types"

const useLabs = (patientId: string, encounter?: string) => {
  const { search } = useClient()
  const queryKey = laboratoryQueryKeys.list(patientId, encounter)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    LabsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _include: "result",
        _sort: "-issued",
        "status:not": "cancelled",
        code: "entered,imported",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/DiagnosticReport`, filters, signal })

      const drs = getResources<DiagnosticReport>(bundle).filter((res) => isDiagnosticReport(res))
      const observations = getResourcesByTypeAsIndex<Observation>(bundle, "Observation")

      const labs = drs.reduce((prev, dr) => {
        const results =
          dr.result?.reduce((prevResults, { id }) => {
            if (id) {
              return [...prevResults, observations[id]]
            }

            return prevResults
          }, [] as Observation[]) ?? []

        return [...prev, { dr, results }]
      }, [] as Laboratory[])

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { labs, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { labs, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.labs)
    const count = newData

    return {
      labs: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    labs,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type LabsQueryData = { labs: Laboratory[]; next: number | undefined; total: number }

export { useLabs }
