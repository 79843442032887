import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Bundle, Communication } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { communicationQueryKeys } from "../query-keys"

const useReadCommunications = () => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const completeCommunications = async (messages: Communication[]) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "batch",
      entry: messages.map((msg) => ({
        request: {
          method: "PATCH",
          url: `Communication/${msg.id}`,
        },
        resource: { status: "completed", resourceType: "Communication" },
      })),
    }
    return transaction(bundle)
  }

  const { mutate: readCommunications, isPending } = useMutation({
    mutationFn: completeCommunications,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: communicationQueryKeys.all })
    },
  })

  return { readCommunications, isPending }
}
export { useReadCommunications }
