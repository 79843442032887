import { faComments, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Communication, type Patient, type Reference, asReference } from "fhir"
import { classNames } from "primereact/utils"
import { type FC, Fragment, useCallback, useEffect, useMemo, useRef } from "react"

import { BubbleMessage, ChatInput, EmptyMessage, InfiniteScroll } from "commons"
import { useOrganizationPractitioners } from "organization"
import { useLoggedInPractitioner } from "practitioner"
import { SYSTEM_VALUES } from "system-values"
import { getDateLabel } from "utils"

import { useCommunications, useReadCommunications, useSendCommunication } from "../hooks"

const ChatContainer: FC<Props> = ({ patient, patientId, patientRef, unreadMessages, unreadedCount }) => {
  const refBottom = useRef<HTMLDivElement | null>(null)
  const refUnreaded = useRef<HTMLDivElement | null>(null)
  const { loggedInPractitioner } = useLoggedInPractitioner()
  const user = asReference(loggedInPractitioner)
  const { organizationPractitionersInfo } = useOrganizationPractitioners({
    organizationId: patient.managingOrganization?.id as string,
  })
  const { count, commsByDate, isLoading, hasNextPage, fetchNextPage } = useCommunications(patient?.id as string)
  const { readCommunications } = useReadCommunications()
  const { sendCommunication } = useSendCommunication()

  const firstUnreadMessage = useMemo(() => unreadMessages?.at(-1), [unreadMessages])

  const { senderPhotos } = useMemo(() => {
    const initialMap = new Map<string, string>()
    if (patient.photo?.[0]?.url) initialMap.set(patientId, patient.photo[0].url)
    const senderPhotos =
      organizationPractitionersInfo?.reduce((acc, pract) => {
        if (pract.practitioner.photo?.[0]?.url)
          acc.set(pract.practitioner.id as string, pract.practitioner.photo[0].url)
        return acc
      }, initialMap) ?? initialMap
    return {
      senderPhotos,
    }
  }, [patient.photo, patientId, organizationPractitionersInfo])

  const readAllUnreaded = useCallback(() => {
    unreadMessages.length && readCommunications(unreadMessages)
  }, [readCommunications, unreadMessages])

  const scrollHook = () => {
    if (refBottom.current && refBottom.current.getBoundingClientRect().bottom <= window.innerHeight) {
      setTimeout(() => {
        refUnreaded.current = null
        readAllUnreaded()
      }, 5000)
    }
  }

  useEffect(() => {
    if (refUnreaded.current && unreadedCount > 0) {
      refUnreaded.current.scrollIntoView({ block: "end" })
      refUnreaded.current = null
    } else {
      refBottom.current?.scrollIntoView({ block: "end" })
      refBottom.current = null
    }
  }, [isLoading, unreadedCount, commsByDate])

  useEffect(() => {
    if (!isLoading) scrollHook()
  }, [isLoading])
  const addMessage = (msg: Communication) => {
    if (!commsByDate.length) {
      const currentDate = new Date().toISOString()
      commsByDate.push({ date: getDateLabel(currentDate), communications: [{ ...msg, status: "preparation" }] })
    } else commsByDate[commsByDate.length - 1].communications.push({ ...msg, status: "preparation" })

    sendCommunication(msg)
  }

  const loader = () => (
    <div key={patient.id} className="text-center m-auto">
      <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-slate-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">Loading messages....</h3>
    </div>
  )

  return (
    <div className="h-full relative grid grid-rows-6 px-3 pb-2 overflow-hidden">
      <div className="flex flex-col overflow-auto pb-3 row-span-full">
        {isLoading ? (
          loader()
        ) : count === 0 && !commsByDate.length ? (
          <EmptyMessage icon={faComments} message="No messages found" />
        ) : (
          <div className="h-full overflow-auto" onScroll={scrollHook}>
            <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} isReverse={true}>
              {commsByDate.map(({ date, communications }, index) => (
                <div key={date} className="w-full relative pt-8 pb-3">
                  <div
                    className={classNames("chat-divider absolute top-0 flex justify-center h-full w-full", {
                      "border-t": index !== 0,
                    })}
                  >
                    <span className="sticky top-4 max-h-[2rem] -translate-y-4 bg-white py-1 px-2 rounded-full border text-slate-400 text-sm">
                      {date}
                    </span>
                  </div>
                  {communications.map(({ sender, payload, sent, status, id }, index) => {
                    const previousMssg = communications[index - 1] ?? undefined
                    const nextMssg = communications[index + 1] ?? undefined
                    const isFirstUnread = firstUnreadMessage?.id === id

                    return (
                      <Fragment key={id}>
                        {!!isFirstUnread && <UnreadedCountTag unreadedCount={unreadedCount} />}
                        <div key={id + "message"} ref={isFirstUnread ? refUnreaded : undefined}>
                          <BubbleMessage
                            name={sender?.display as string}
                            date={sent ? new Date(sent) : undefined}
                            grouped={previousMssg?.sender?.id === sender?.id}
                            own={sender?.id === user.id}
                            message={payload?.[0]?.content?.string ?? ""}
                            className={classNames({ "bubble-thread-close": nextMssg?.sender?.id !== sender?.id })}
                            thread_close={nextMssg?.sender?.id !== sender?.id}
                            sended={status === "in-progress"}
                            received={status === "completed"}
                            avatar={senderPhotos.get(sender?.id as string)}
                          />
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ))}
            </InfiniteScroll>
            <div ref={refBottom} />
          </div>
        )}
        <div className="w-full mt-2 row-span-1 px-1">
          <ChatInput
            isLoading={isLoading}
            sendMessage={(mssg) =>
              addMessage({
                status: "in-progress",
                sent: new Date().toISOString(),
                sender: user,
                payload: [{ content: { string: mssg } }],
                recipient: [patientRef],
                category: [
                  {
                    coding: [
                      {
                        system: SYSTEM_VALUES.COMMUNICATION_CATEGORY,
                        code: "message",
                      },
                    ],
                  },
                ],
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

const UnreadedCountTag = ({ unreadedCount }: { unreadedCount: number }) => (
  <>
    {unreadedCount > 0 ? (
      <div className="py-4 flex justify-center items-center w-full">
        <div className="bg-white px-2 py-1 rounded-full border text-sm text-blue-500/70 font-bold flex my-3 mx-auto w-fit whitespace-nowrap">
          {unreadedCount} new messages
        </div>
      </div>
    ) : null}
  </>
)

type Props = {
  patient: Patient
  patientId: string
  unreadMessages: Communication[]
  unreadedCount: number
  patientRef: Reference
}
export { ChatContainer }
