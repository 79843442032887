import { useQuery } from "@tanstack/react-query"
import { type Communication, type Patient, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import {} from "commons"
import { useOrganizationContext } from "organization"

import { messagesQueryKeys } from "../query-keys"
import type { PatientChat } from "../types"

const useUnreadMessages = (patientId?: string) => {
  const { search } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = messagesQueryKeys.unread

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress",
        category: "message",
        ...(patientId ? { sender: patientId } : {}),
        _include: "sender",
        _sort: "-sent",
      })

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Communication`, filters, signal })

      const communications = getResources(bundle) as Communication[]
      const patients = getResources<Patient>(bundle, "Patient")

      return { communications, patients, count: communications.length }
    },
    meta: { context: { queryKey } },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  })

  const { communications, senders, unreadList } = useMemo(() => {
    const messages = data?.communications
    const patients = data?.patients

    const unreadList =
      patients?.map((sender) => {
        const unreadCommunications = messages?.filter((comm) => comm.sender?.id === sender.id)
        const unreadCount = unreadCommunications?.length ?? 0

        return {
          sender,
          unreadCount,
          unreadCommunications,
        } as PatientChat
      }) ?? []

    return {
      communications: messages ?? [],
      senders: patients ?? [],
      unreadList: unreadList,
    }
  }, [data])

  return {
    messages: communications ?? [],
    senders: senders ?? [],
    unreadList: unreadList,
    isLoading,
    reloadUnreadCommunications: refetch,
  }
}

export { useUnreadMessages }
