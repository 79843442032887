import { SelectButton } from "primereact/selectbutton"
import { startTransition } from "react"

import { useChartContext } from "chart-view"
import { SearchWithStatus, useCrudReducer } from "commons"
import { useMedReducerState } from "commons/meds"
import { medicationRequestStatus } from "data"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"

import { prescriptionViewOptions } from "../data"
import { useUpdateMedicationRequest } from "../hooks"
import type { Contents, MedicationRequestFormData } from "../types"
import { sanitize } from "../utils/formatters"
import { PrescriptionFormContainer } from "./form"
import { PrescriptionList } from "./prescriptions/PrescriptionList"

const PrescriptionsContainer = ({ activeContent, onUpdateContent }: Props) => {
  const { patientId } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useMedReducerState()
  const chartContext = useChartContext()
  const {
    initialValue,
    showSlide: showForm,
    edit: editMR,
    reset,
  } = useCrudReducer({ defaultEntity: {} as MedicationRequestFormData })

  const { updateMedicationRequest } = useUpdateMedicationRequest(reset)

  const edit = (mr: MedicationRequestFormData) => {
    editMR(mr)
    chartContext.setSearchData({ showSearch: false })
  }

  const handleCancel = () => {
    reset()
  }

  const handleSubmit = (values: MedicationRequestFormData) => {
    updateMedicationRequest(sanitize({ mr: { ...values, encounter: openEncounterRef } }))
  }

  return showForm ? (
    <PrescriptionFormContainer initialValues={initialValue} isEditing onCancel={handleCancel} onSubmit={handleSubmit} />
  ) : (
    <>
      <div className="inline-flex justify-between h-12 w-full my-3 px-2">
        <SelectButton
          value={activeContent}
          options={prescriptionViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search prescriptions"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
        />
      </div>
      <PrescriptionList searchFilter={searchFilter} statusFilter={statusFilter} onEdit={edit} />
    </>
  )
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { PrescriptionsContainer }
