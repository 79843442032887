import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { RequestGroup, RequestGroupActionArrayActionArray, ResourceObject } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"

import { ordersQueryKeys } from "../query-keys"

const useUpdateAllCpoeRequests = (patientId: string, onSuccess: () => void, onSettled?: () => void) => {
  const { patch, operationRequest } = useClient()
  const queryClient = useQueryClient()

  const updatedRequestGroup = async (toActivate: ResourceObject[]) => {
    const requestGroup = await operationRequest<RequestGroup>({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "POST",
      operation: "dwim",
    })
    const resourceIds = new Set(toActivate.map(({ id }) => id))

    const updatedRGAction: RequestGroupActionArrayActionArray[] =
      requestGroup?.action?.[0].action?.map((action) => {
        const shouldActivate = resourceIds.has(action.resource?.id)
        const newCode = shouldActivate
          ? {
              coding: [
                {
                  system: SYSTEM_VALUES.CPOE_ACTION,
                  code: "activate",
                  display: "Activate",
                },
              ],
            }
          : {
              coding: [
                {
                  system: SYSTEM_VALUES.CPOE_ACTION,
                  code: "draft",
                  display: "Draft",
                },
              ],
            }

        return {
          ...action,
          code: [
            newCode,
            ...(action.code?.filter(
              (cc) => !cc.coding?.some((coding) => coding.system === SYSTEM_VALUES.CPOE_ACTION),
            ) ?? []),
          ],
        }
      }) ?? []

    const newRequestsAction = { ...requestGroup?.action?.[0], action: [...updatedRGAction] }

    return patch(
      "RequestGroup",
      requestGroup.id as string,
      {
        action: [newRequestsAction, ...(requestGroup?.action?.slice(1) as RequestGroupActionArrayActionArray[])],
        meta: requestGroup.meta,
      } as ResourceObject,
    )
  }
  const { mutate: updateAllCpoeRequests, isPending: isUpdatingAllRequests } = useMutation({
    mutationFn: updatedRequestGroup,
    onSettled: onSettled,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId) })
      await queryClient.refetchQueries({ queryKey: ordersQueryKeys.list(patientId) })
      onSuccess()
    },
    onError: (error: CustomError) => {
      displayNotificationError(registerErrorTrace(error, { patientId }))
    },
  })

  return { updateAllCpoeRequests, isUpdatingAllRequests }
}

export { useUpdateAllCpoeRequests }
